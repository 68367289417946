import { Button, FieldAutoArray, FieldBox, Icon, Input } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { type ITaskActionComponentProps } from './types';

const Row = styled.div<{ error: boolean }>`
  display: flex;
  border: ${({ error }) => (error ? '1px solid red' : '1px solid transparent')};
  border-radius: 2px;
  align-items: center;
  margin-bottom: 10px;
`;

const Col = styled.div<{ flex?: number }>`
  flex: ${({ flex }) => (flex === undefined ? 1 : flex)};
  flex-basis: auto;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

export const TriggerEventActionEditor = (_props: ITaskActionComponentProps) => (
  <>
    <Input.Box name="data.event_name" label="Event name" required />
    <FieldBox name="data.args" label="Arguments">
      <FieldAutoArray
        name="data.args"
        sortable
        isPresent={(v) => v.name && v.value}
        render={({ name: itemName, handleProps, error, onRemove }) => (
          <Row error={!!error}>
            <Col flex={0} {...(handleProps || {})}>
              <Icon name="drag-handle" />
            </Col>
            <Col>
              <Input name={`${itemName}.name`} placeholder="Name" compact />
            </Col>
            <Col>
              <Input name={`${itemName}.value`} placeholder="Value" compact />
            </Col>
            <Col flex={0}>
              <Button
                icon="delete"
                iconButton
                size="xs"
                type="danger"
                importance="tertiary"
                onClick={onRemove}
              />
            </Col>
          </Row>
        )}
      />
    </FieldBox>
  </>
);
