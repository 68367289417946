import 'antd/es/tree/style/css.js';

import { borderRadius, Icon, palette, spacing } from '@mortgagehippo/ds';
import {
  FALLBACK_TASK_GROUP_KEY,
  FieldType,
  type IArrayField,
  type IDataGatheringTask,
  type IEntityField,
  type IField,
  type IGroup,
  type IPanel,
  isFallbackTaskGroup,
  type ISwitchArrayField,
  type ISwitchArrayFieldCase,
  type ITaskGroup,
  TaskType,
} from '@mortgagehippo/tasks';
import { UnreachableCaseError } from '@mortgagehippo/util';
import Tree from 'antd/es/tree';
import { compact, flatten, pull } from 'lodash-es';
import { type ReactElement, type ReactNode, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { type DeepPartial } from 'ts-essentials';

import { type IEditorTask } from './types';
import { getTaskNodeKey } from './util';
import { type BlueprintErrors } from './validator';

export interface IBlueprintEditorNavigatorTreeProps {
  selected: string;
  onChange: (selected: string) => void;
  taskGroups: ITaskGroup<IEditorTask>[];
  errors?: BlueprintErrors;
  showErrorNodes?: boolean;
  showErrorIcons?: boolean;
  hideValidNodes?: boolean;
}

const StyledTree = styled(Tree)`
  && {
    li .ant-tree-node-content-wrapper {
      height: auto;
      border-radius: ${borderRadius(1)};
      padding: 0 ${spacing(1)};

      &.ant-tree-node-selected {
        background: ${palette('primary600')};
        color: ${palette('white')};
      }
    }
  }
`;

const TreeTag = styled('span')<{
  type?: 'task_group' | 'task' | 'panel' | 'group' | 'field' | 'error';
}>`
  ${(p) => {
    let background;
    let color;

    switch (p.type) {
      case 'task_group': {
        background = '#E1E4DD';
        color = '#727E63';
        break;
      }
      case 'task': {
        background = '#fff1e5';
        color = '#a56227';
        break;
      }
      case 'panel': {
        background = '#f6fbd0';
        color = '#697321';
        break;
      }
      case 'group': {
        background = '#E5F0FF';
        color = '#30578D';
        break;
      }
      case 'field': {
        background = '#F2E5FF';
        color = '#5D308D';
        break;
      }
      case 'error': {
        background = '#ffeaea';
        color = '#c80101';
        break;
      }
      default: {
        background = '#f5f5f5';
        color = '#666';
      }
    }
    return css`
      text-transform: uppercase;
      font-size: 0.8em;
      background: ${background};
      color: ${color};
      padding: 0.1em 0.3em;
      border-radius: 2px;
    `;
  }}
`;

const ErrorIcon = styled(Icon)`
  margin-left: ${spacing(2)};
  vertical-align: middle;
`;

export const BlueprintEditorNavigatorTree = (props: IBlueprintEditorNavigatorTreeProps) => {
  const { taskGroups, errors, selected, onChange, showErrorNodes, showErrorIcons, hideValidNodes } =
    props;

  const [expanded, setExpanded] = useState<any[]>([selected || '-']);

  const handleSelect = useCallback(
    (selectedKeys: any) => {
      const nextSelected = selectedKeys?.[0] || '';
      onChange(nextSelected === '-' ? '' : nextSelected);
    },
    [onChange]
  );

  const handleExpand = useCallback(
    (expandedKeys: string[], { node, expanded: isExpanded }: any) => {
      let selectedKeys = [...expandedKeys];

      const collapseSubNodes = (treeNode: any) => {
        if (treeNode.key && treeNode.key !== selected) {
          pull(selectedKeys, treeNode.key);
        } else {
          // do not collapse as child is selected
          selectedKeys = expandedKeys;
          return;
        }

        if (treeNode.props?.children) {
          treeNode.props.children.forEach((subNode: any) => {
            collapseSubNodes(subNode);
          });
        }
      };

      // remove this node's subNodes key
      if (!isExpanded) {
        let okToCollapseSelf = true;
        const subNodes: any[] = node.getNodeChildren();
        for (let ix = 0; ix < subNodes.length; ix += 1) {
          if (subNodes[ix].key && subNodes[ix].key === selected) {
            selectedKeys = expandedKeys;
            okToCollapseSelf = false;
            break;
          }
          collapseSubNodes(subNodes[ix]);
        }

        if (okToCollapseSelf) {
          // remove this node's key
          selectedKeys = pull(selectedKeys, node.props.eventKey);
        } else {
          selectedKeys.push(node.props.eventKey);
        }
      }

      setExpanded(selectedKeys);
    },
    [selected]
  );

  useEffect(() => {
    if (!expanded.includes(selected)) {
      setExpanded([...expanded, selected]);
    }
  }, [selected, expanded]);

  const children: any = taskGroups.map((group, i) =>
    createTaskGroupNode(
      group,
      `json.task_groups.items[${i}]`,
      errors,
      showErrorNodes,
      showErrorIcons,
      hideValidNodes
    )
  );

  return (
    <StyledTree
      defaultExpandedKeys={[!selected ? '-' : selected]}
      defaultSelectedKeys={[!selected ? '-' : selected]}
      selectedKeys={[!selected ? '-' : selected]}
      expandedKeys={expanded}
      defaultExpandParent
      onSelect={handleSelect}
      onExpand={handleExpand}
      showLine
      autoExpandParent
    >
      <Tree.TreeNode title="Blueprint" key="-">
        {children}
      </Tree.TreeNode>
    </StyledTree>
  );
};

function createTaskGroupNode(
  group: ITaskGroup<IEditorTask>,
  key: string,
  allErrors?: BlueprintErrors,
  showErrorNodes?: boolean,
  showIconOnError?: boolean,
  hideIfValid?: boolean
): ReactNode {
  const title = group.label || group.key || 'Unnamed';
  const { tasks } = group;

  const children = compact(
    (tasks || []).map((task) =>
      createTaskNode(
        task,
        getTaskNodeKey(task.index),
        allErrors,
        showErrorNodes,
        showIconOnError,
        hideIfValid
      )
    )
  );

  // we don't want the fallback group to be editable
  const isFallback = isFallbackTaskGroup(group);

  const groupErrors = allErrors?.[key] || [];
  const hasErrors = groupErrors.length > 0;

  if (!hasErrors && hideIfValid && children.length === 0) {
    return null;
  }

  const errorChildren: any = showErrorNodes
    ? groupErrors.map((err, i) => createErrorNode(err, `${key}.error[${i}]`))
    : [];

  return (
    <Tree.TreeNode
      title={
        <>
          <TreeTag type="task_group">Task Group</TreeTag> {title}
          {showIconOnError && hasErrors ? <ErrorIcon name="error" color="danger600" /> : null}
        </>
      }
      key={isFallback ? FALLBACK_TASK_GROUP_KEY : key}
    >
      {errorChildren}
      {children}
    </Tree.TreeNode>
  );
}

function createTaskNode(
  task: Partial<IEditorTask>,
  key: string,
  allErrors?: BlueprintErrors,
  showErrorNodes?: boolean,
  showIconOnError?: boolean,
  hideIfValid?: boolean
): ReactNode {
  const { typeName } = task || { typeName: undefined };
  const title = task.meta?.title || task.key || 'Unnamed';

  switch (typeName) {
    case TaskType.DataGatheringTask: {
      return createDataGatheringNode(
        task as IDataGatheringTask,
        key,
        allErrors,
        showErrorNodes,
        showIconOnError,
        hideIfValid
      );
    }
    case TaskType.AssetsTask:
    case TaskType.ChooseAgentTask:
    case TaskType.CreditPullTask:
    case TaskType.DocumentSubmissionTask:
    case TaskType.DocutechDisclosuresTask:
    case TaskType.DocusignTask:
    case TaskType.HellosignTask:
    case TaskType.InformationTask:
    case TaskType.InviteApplicantsTask:
    case TaskType.EmployersTask:
    case TaskType.EncompassDisclosuresTask:
    case TaskType.PaymentTask:
    case TaskType.PricingTask:
    case TaskType.SendDocumentTask:
    case TaskType.SignDisclosuresTask:
    case TaskType.SubmitApplicationTask:
    case TaskType.VOIECascadeTask:
    case undefined: {
      const taskErrors = allErrors?.[key] || [];
      const hasErrors = taskErrors.length > 0;

      if (!hasErrors && hideIfValid) {
        return null;
      }

      const errorChildren = showErrorNodes
        ? taskErrors.map((err, i) => createErrorNode(err, `${key}.error[${i}]`))
        : [];

      return (
        <Tree.TreeNode
          title={
            <>
              <TreeTag type="task">Task</TreeTag> {title}
              {showIconOnError && hasErrors ? <ErrorIcon name="error" color="danger600" /> : null}
            </>
          }
          key={key}
        >
          {errorChildren}
        </Tree.TreeNode>
      );
    }
    case TaskType.PreQualificationTask:
    case TaskType.SnapdocsClosingTask:
      return null;
    default: {
      throw new UnreachableCaseError(typeName);
    }
  }
}

function createDataGatheringNode(
  task: IDataGatheringTask,
  key: string,
  allErrors?: BlueprintErrors,
  showErrorNodes?: boolean,
  showIconOnError?: boolean,
  hideIfValid?: boolean
) {
  const data = task.data || {};
  const panels = data.panels || [];
  const title = task.meta?.title || task.key || 'Unnamed';

  const children = compact(
    panels.map((panel, i) =>
      createPanelNode(panel, `${key}.data.panels[${i}]`, allErrors, showErrorNodes, hideIfValid)
    )
  );

  const taskErrors = allErrors?.[key] || [];
  const hasErrors = taskErrors.length > 0;

  if (!hasErrors && hideIfValid && children.length === 0) {
    return null;
  }

  const errorChildren: any = showErrorNodes
    ? taskErrors.map((err, i) => createErrorNode(err, `${key}.error[${i}]`))
    : [];

  return (
    <Tree.TreeNode
      title={
        <>
          <TreeTag type="task">Task</TreeTag> {title}
          {showIconOnError && hasErrors ? <ErrorIcon name="error" color="danger600" /> : null}
        </>
      }
      key={key}
    >
      {errorChildren}
      {children}
    </Tree.TreeNode>
  );
}

function createPanelNode(
  panel: DeepPartial<IPanel>,
  key: string,
  allErrors?: BlueprintErrors,
  showErrorNodes?: boolean,
  hideIfValid?: boolean
) {
  const title = panel.title || '';
  const groups = panel.groups || [];

  const children = groups.map((group, i) => createGroupNode(group, `${key}.groups[${i}]`));

  const panelErrors = allErrors?.[key] || [];
  const hasErrors = panelErrors.length > 0;

  /*
   * TODO: check errors for panel children, right now we are ignoring anything
   * below this level
   */
  if (!hasErrors && hideIfValid) {
    return null;
  }

  const errorChildren: any = showErrorNodes
    ? panelErrors.map((err, i) => createErrorNode(err, `${key}.error[${i}]`))
    : [];

  return (
    <Tree.TreeNode
      title={
        <>
          <TreeTag type="panel">Panel</TreeTag> {title}
        </>
      }
      key={key}
    >
      {errorChildren}
      {children}
    </Tree.TreeNode>
  );
}

function createGroupNode(group: DeepPartial<IGroup>, key: string) {
  const title = group.title || '';
  const fields = group.fields || [];
  const children = fields.map((field, i) => createFieldNode(field, `${key}.fields[${i}]`));

  return (
    <Tree.TreeNode
      title={
        <>
          <TreeTag type="group">Group</TreeTag> {title}
        </>
      }
      key={key}
    >
      {children}
    </Tree.TreeNode>
  );
}

function createFieldNode(field: DeepPartial<IField>, key: string) {
  const children: ReactElement[] = [];

  let tagTitle = 'Field';

  if (field.type_name === FieldType.ARRAY) {
    const f = field as IArrayField;
    const groups = f.groups || [];
    tagTitle = 'Array';
    groups.forEach((group, i) => {
      children.push(createGroupNode(group, `${key}.groups[${i}]`));
    });
  }

  if (field.type_name === FieldType.SWITCH_ARRAY) {
    const f = field as ISwitchArrayField;
    const groups: any[] = f.commonGroups || [];
    const cases = f.cases || [];
    tagTitle = 'Switch Array';

    groups.forEach((group, i) => {
      children.push(createGroupNode(group, `${key}.commonGroups[${i}]`));
    });

    cases.forEach((switchCase, i) => {
      children.push(createSwitchCaseNode(switchCase, `${key}.cases[${i}]`));
    });
  }

  if (field.type_name === FieldType.ENTITY) {
    const f = field as IEntityField;
    const groups = f.groups || [];
    groups.forEach((group, i) => {
      children.push(createGroupNode(group, `${key}.groups[${i}]`));
    });
  }

  const title = field.title || field.key || 'Unnamed';
  return (
    <Tree.TreeNode
      title={
        <>
          <TreeTag type="field">{tagTitle}</TreeTag> {title}
        </>
      }
      key={key}
    >
      {children}
    </Tree.TreeNode>
  );
}

function createSwitchCaseNode(field: DeepPartial<ISwitchArrayFieldCase>, key: string) {
  const title = field.title || 'Unnamed';
  const groups = (field.groups || []) as IGroup[];
  const children = flatten(groups.map((group, i) => createGroupNode(group, `${key}.groups[${i}]`)));

  return (
    <Tree.TreeNode
      title={
        <>
          <TreeTag>Variant</TreeTag> {title}
        </>
      }
      key={key}
    >
      {children}
    </Tree.TreeNode>
  );
}

function createErrorNode(message: string, key: string) {
  return (
    <Tree.TreeNode
      title={
        <>
          <TreeTag type="error">Error</TreeTag> {message}
        </>
      }
      key={key}
    />
  );
}
