import { Tabs } from '@mortgagehippo/ds';

import { ExternalMilestoneKeysTable } from './external-milestone-keys-table';
import { LenderMilestonesSettings } from './lender-milestones-settings';
import { LenderMilestonesTable } from './lender-milestones-table';
import { usePartnerVerticals } from './use-partner-verticals';

interface ILenderMilestonesPageContentProps {
  partnerId: string;
  loading: boolean;
}

export const LenderMilestonesPageContent = (props: ILenderMilestonesPageContentProps) => {
  const { partnerId, loading: partnerLoading } = props;

  const [{ verticals }, verticalsLoading] = usePartnerVerticals(partnerId, !partnerId);

  const loading = partnerLoading || verticalsLoading;

  return (
    <Tabs>
      <Tabs.Tab id="lender-milestones" label="Lender Milestones">
        <LenderMilestonesTable partnerId={partnerId} verticals={verticals} />
      </Tabs.Tab>
      <Tabs.Tab id="external-milestone-keys" label="External Milestone Keys">
        <ExternalMilestoneKeysTable partnerId={partnerId} verticals={verticals} loading={loading} />
      </Tabs.Tab>
      <Tabs.Tab id="lender-milestones-settings" label="Settings">
        <LenderMilestonesSettings partnerId={partnerId} loading={loading} />
      </Tabs.Tab>
    </Tabs>
  );
};
