import { Input, type ISelectOption, Select, Title, YesNo } from '@mortgagehippo/ds';

enum DesktopUnderwriterFileTypes {
  fannie_mae_32 = 'fannie_mae_32',
  mismo_34 = 'mismo_34',
}

const fileOptions: ISelectOption[] = [
  {
    label: 'Fannie Mae File 3.2',
    value: DesktopUnderwriterFileTypes.fannie_mae_32,
  },
  {
    label: 'MISMO File 3.4',
    value: DesktopUnderwriterFileTypes.mismo_34,
  },
];

export const DesktopUnderwriterFields = () => (
  <>
    <Input.Box name="login_account_identifier" label="Login Account Identifier" required />
    <Input.Box name="login_account_password" label="Login Account Password" required />
    <Select.Box name="file_type" label="File type to be used" options={fileOptions} required />
    <YesNo.Box name="production_mode" label="Production Mode" required />
    <Title level={3}>Credit</Title>
    <Input.Box name="credit.name" label="Name" required />
    <Input.Box name="credit.account_number" label="Account Number" required />
    <Input.Box name="credit.password" label="Password" required />
    <YesNo.Box
      name="credit.credit_reference_number"
      label="Send Credit Reference Number?"
      required
    />
    <Title level={3}>Government</Title>
    <Input.Box name="government.lender_identifier" label="FHA Lender Identifier" />
    <Input.Box name="government.sponsor_identifier" label="FHA Sponsor Identifier" />
  </>
);
