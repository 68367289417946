import {
  CheckboxList,
  type FormSubmitHandler,
  type IChoiceOption,
  Input,
  ModalForm,
  TextArea,
} from '@mortgagehippo/ds';

interface IAuthRoleModalProps {
  title: string;
  initialValues?: any;
  permissionOptions: IChoiceOption[];
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
  isOpen: boolean;
  loading: boolean;
}

const defaultInitialValues = {};
export const AuthRoleModal = (props: IAuthRoleModalProps) => {
  const { permissionOptions, initialValues = defaultInitialValues, ...rest } = props;

  return (
    <ModalForm initialValues={initialValues} {...rest} okButtonLabel="Save">
      <Input.Box name="name" label="Name" required />
      <TextArea.Box name="description" label="Description" rows={1} autosize autosizeMaxRows={6} />
      <CheckboxList.Box
        label="Permissions"
        name="permissions"
        options={permissionOptions}
        allowSelectAll
        required
      />
    </ModalForm>
  );
};
