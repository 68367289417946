import { useModalConfirm } from '@mortgagehippo/ds';

export const useDiscardUnsavedChanges = () => {
  const [confirm, modalDialog] = useModalConfirm(
    'You have unsaved changes. Are you sure you want to navigate away without saving the changes?',
    {
      type: 'warning',
      cancelButtonLabel: 'Leave without saving',
      okButtonLabel: 'Stay',
      disableOverlayClickClose: true,
    }
  );

  const shouldDiscardUnsavedChanges = async (unsavedChangesCount: number) =>
    new Promise((resolve: (value: boolean) => void) => {
      if (unsavedChangesCount > 0) {
        confirm({
          onCancel: () => {
            resolve(true);
          },
          onConfirm: () => {
            resolve(false);
          },
        });
      } else {
        resolve(true);
      }
    });

  return [shouldDiscardUnsavedChanges, modalDialog] as const;
};
