/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation CreateLandingPage($siteId: ID!, $data: LandingPageInput!) {\n    createLandingPage(siteId: $siteId, data: $data) {\n      ...LandingPageFragment\n    }\n  }\n": types.CreateLandingPageDocument,
    "\n  mutation CreatePartner($data: CreatePartnerInput!) {\n    createPartner(data: $data)\n  }\n": types.CreatePartnerDocument,
    "\n  mutation ScheduleRepeatableServiceJob($data: ScheduleRepeatableServiceJobInput!) {\n    scheduleRepeatableServiceJob(data: $data) {\n      id\n    }\n  }\n": types.ScheduleRepeatableServiceJobDocument,
    "\n  mutation ScheduleServiceJob($data: ScheduleServiceJobInput!) {\n    scheduleServiceJob(data: $data) {\n      id\n    }\n  }\n": types.ScheduleServiceJobDocument,
    "\n  mutation DeleteLandingPage($landingPageId: ID!) {\n    deleteLandingPage(landingPageId: $landingPageId) {\n      ...LandingPageFragment\n    }\n  }\n": types.DeleteLandingPageDocument,
    "\n  mutation UpdateLandingPage($landingPageId: ID!, $data: LandingPageInput!) {\n    updateLandingPage(landingPageId: $landingPageId, data: $data) {\n      ...LandingPageFragment\n    }\n  }\n": types.UpdateLandingPageDocument,
    "\n  mutation UpdatePartner($partnerId: ID!, $data: BasicPartnerInput!) {\n    updatePartner(partnerId: $partnerId, data: $data)\n  }\n": types.UpdatePartnerDocument,
    "\n  fragment NotificationProfileFragment on NotificationProfile {\n    id\n    name\n    typeName\n    data {\n      mediums\n    }\n    rawData\n    parentType\n    parentId\n    createdAt\n    updatedAt\n    args\n  }\n": types.NotificationProfileFragmentFragmentDoc,
    "\n  mutation CreateNotificationProfile(\n    $parentType: ParentType!\n    $parentId: ID!\n    $data: CreateNotificationProfileInput!\n  ) {\n    createNotificationProfile(parentType: $parentType, parentId: $parentId, data: $data) {\n      ...NotificationProfileFragment\n    }\n  }\n": types.CreateNotificationProfileDocument,
    "\n  mutation DeleteNotificationProfile($profileId: ID!) {\n    deleteNotificationProfile(profileId: $profileId) {\n      ...NotificationProfileFragment\n    }\n  }\n": types.DeleteNotificationProfileDocument,
    "\n  query PartnerNotificationProfile($partnerId: ID!, $profileId: ID!) {\n    partner(id: $partnerId) {\n      id\n      notificationProfile(id: $profileId) {\n        ...NotificationProfileFragment\n      }\n    }\n  }\n": types.PartnerNotificationProfileDocument,
    "\n  query SiteNotificationProfile($siteId: ID!, $profileId: ID!) {\n    site(id: $siteId) {\n      id\n      notificationProfile(id: $profileId) {\n        ...NotificationProfileFragment\n      }\n    }\n  }\n": types.SiteNotificationProfileDocument,
    "\n  mutation UpdateNotificationProfile($profileId: ID!, $data: UpdateNotificationProfileInput!) {\n    updateNotificationProfile(profileId: $profileId, data: $data) {\n      ...NotificationProfileFragment\n    }\n  }\n": types.UpdateNotificationProfileDocument,
    "\n  query PartnerCascadeConfigurations(\n    $partnerId: ID!\n    $cursor: String\n    $perPage: Int!\n    $orderBy: JSON\n  ) {\n    partner(id: $partnerId) {\n      id\n      cascadeConfigurations(options: { perPage: $perPage, cursor: $cursor, orderBy: $orderBy }) {\n        items {\n          id\n          name\n          cascadeType\n          cascadeOwnerType\n          version\n          partnerId\n          siteId\n          createdAt\n          actions {\n            id\n            actionType\n            position\n            startCondition\n            options\n            timeout\n          }\n        }\n        total\n        nextCursor\n        previousCursor\n      }\n    }\n  }\n": types.PartnerCascadeConfigurationsDocument,
    "\n  query SiteCascadeConfigurations(\n    $partnerId: ID!\n    $siteId: ID!\n    $cursor: String\n    $perPage: Int!\n    $orderBy: JSON\n  ) {\n    partner(id: $partnerId) {\n      id\n      site(id: $siteId) {\n        id\n        cascadeConfigurations(options: { perPage: $perPage, cursor: $cursor, orderBy: $orderBy }) {\n          items {\n            id\n            name\n            cascadeType\n            cascadeOwnerType\n            version\n            partnerId\n            siteId\n            createdAt\n            actions {\n              id\n              actionType\n              position\n              startCondition\n              options\n              timeout\n            }\n          }\n          total\n          nextCursor\n          previousCursor\n        }\n      }\n    }\n  }\n": types.SiteCascadeConfigurationsDocument,
    "\n  mutation SaveCascadeConfiguration($input: SaveCascadeConfigurationInput!) {\n    saveCascadeConfiguration(input: $input) {\n      id\n    }\n  }\n": types.SaveCascadeConfigurationDocument,
    "\n  fragment CredentialFragment on Credential {\n    typeName\n    partnerId\n    siteId\n    inherited\n    overwrittenBy {\n      id\n      name\n    }\n    updatedAt\n    data\n  }\n": types.CredentialFragmentFragmentDoc,
    "\n  query PartnerCredentials($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      credentials {\n        ...CredentialFragment\n      }\n    }\n  }\n": types.PartnerCredentialsDocument,
    "\n  query PartnerCredential($partnerId: ID!, $credentialType: CredentialsType!) {\n    partner(id: $partnerId) {\n      id\n      credential(credentialType: $credentialType) {\n        ...CredentialFragment\n      }\n    }\n  }\n": types.PartnerCredentialDocument,
    "\n  query SiteCredentials($siteId: ID!) {\n    site(id: $siteId) {\n      id\n      credentials {\n        ...CredentialFragment\n      }\n    }\n  }\n": types.SiteCredentialsDocument,
    "\n  query SiteCredential($siteId: ID!, $credentialType: CredentialsType!) {\n    site(id: $siteId) {\n      id\n      credential(credentialType: $credentialType) {\n        ...CredentialFragment\n      }\n    }\n  }\n": types.SiteCredentialDocument,
    "\n  mutation SaveCredential(\n    $partnerId: ID!\n    $siteId: ID\n    $credentialType: CredentialsType!\n    $data: JSON!\n  ) {\n    saveCredential(\n      partnerId: $partnerId\n      siteId: $siteId\n      credentialType: $credentialType\n      data: $data\n    )\n  }\n": types.SaveCredentialDocument,
    "\n  mutation DeleteCredential($partnerId: ID!, $siteId: ID, $credentialType: CredentialsType!) {\n    deleteCredential(partnerId: $partnerId, siteId: $siteId, credentialType: $credentialType)\n  }\n": types.DeleteCredentialDocument,
    "\n  fragment EncompassWebhookFragment on EncompassWebhook {\n    subscriptionId\n    endpoint\n    signingkey\n    filters\n    events\n    resource\n  }\n": types.EncompassWebhookFragmentFragmentDoc,
    "\n  query PartnerEncompassWebhooks($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      encompassWebhooks {\n        ...EncompassWebhookFragment\n      }\n    }\n  }\n": types.PartnerEncompassWebhooksDocument,
    "\n  query SitePartnerWebhooks($siteId: ID!) {\n    site(id: $siteId) {\n      id\n      encompassWebhooks {\n        ...EncompassWebhookFragment\n      }\n    }\n  }\n": types.SitePartnerWebhooksDocument,
    "\n  fragment SnapdocsSubscriptionFragment on SnapdocsSubscription {\n    id\n    description\n    webhookUrl\n    events\n  }\n": types.SnapdocsSubscriptionFragmentFragmentDoc,
    "\n  query PartnerSnapdocsSubscriptions($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      snapdocsSubscriptions {\n        ...SnapdocsSubscriptionFragment\n      }\n    }\n  }\n": types.PartnerSnapdocsSubscriptionsDocument,
    "\n  query SiteSnapdocsSubscriptions($siteId: ID!) {\n    site(id: $siteId) {\n      id\n      snapdocsSubscriptions {\n        ...SnapdocsSubscriptionFragment\n      }\n    }\n  }\n": types.SiteSnapdocsSubscriptionsDocument,
    "\n  fragment TotalExpertCustomFieldsFragment on TotalExpertCustomFieldResult {\n    items {\n      id\n      systemName\n      dataType\n      relatedEntity {\n        type\n        subtype\n      }\n      displayName\n    }\n    itemsCount\n    nextPage\n    pageCount\n  }\n": types.TotalExpertCustomFieldsFragmentFragmentDoc,
    "\n  query PartnerTotalExpertCustomFields($partnerId: ID!, $page: Int!, $perPage: Int!) {\n    partner(id: $partnerId) {\n      totalExpertCustomFields(options: { page: $page, perPage: $perPage }) {\n        ...TotalExpertCustomFieldsFragment\n      }\n    }\n  }\n": types.PartnerTotalExpertCustomFieldsDocument,
    "\n  query SiteTotalExpertCustomFields($siteId: ID!, $page: Int!, $perPage: Int!) {\n    site(id: $siteId) {\n      totalExpertCustomFields(options: { page: $page, perPage: $perPage }) {\n        ...TotalExpertCustomFieldsFragment\n      }\n    }\n  }\n": types.SiteTotalExpertCustomFieldsDocument,
    "\n  mutation CreateEncompassWebhook(\n    $parentType: ParentType!\n    $parentId: ID!\n    $endpoint: String!\n    $resource: String!\n    $events: JSON!\n    $filters: JSON\n  ) {\n    createEncompassWebhook(\n      parentType: $parentType\n      parentId: $parentId\n      endpoint: $endpoint\n      resource: $resource\n      events: $events\n      filters: $filters\n    ) {\n      statusCode\n      statusDescription\n    }\n  }\n": types.CreateEncompassWebhookDocument,
    "\n  mutation DeleteEncompassWebhook($parentType: ParentType!, $parentId: ID!, $id: ID!) {\n    deleteEncompassWebhook(parentType: $parentType, parentId: $parentId, id: $id) {\n      statusCode\n      statusDescription\n    }\n  }\n": types.DeleteEncompassWebhookDocument,
    "\n  mutation CreateSnapdocsSubscription($data: CreateSnapdocsSubscriptionInput!) {\n    snapdocs {\n      createSubscription(data: $data)\n    }\n  }\n": types.CreateSnapdocsSubscriptionDocument,
    "\n  mutation DeletenapdocsSubscription($id: ID!, $pathSlug: String!, $partnerId: ID!, $siteId: ID) {\n    snapdocs {\n      deleteSubscription(id: $id, pathSlug: $pathSlug, partnerId: $partnerId, siteId: $siteId)\n    }\n  }\n": types.DeletenapdocsSubscriptionDocument,
    "\n  query CustomizationLanguages {\n    customizationLanguages {\n      value: id\n      label: name\n    }\n  }\n": types.CustomizationLanguagesDocument,
    "\n  fragment CustomizationResourceFragment on CustomizationResource {\n    id\n    key\n    type\n    namespace\n  }\n": types.CustomizationResourceFragmentFragmentDoc,
    "\n  fragment CustomizationValueFragment on CustomizationValue {\n    id\n    key\n    type\n    namespace\n    value\n    language\n    source\n  }\n": types.CustomizationValueFragmentFragmentDoc,
    "\n  query CustomizationEditor(\n    $projectId: String!\n    $partnerId: Int!\n    $domain: String\n    $type: String\n    $language: String\n    $namespace: String\n    $key: String\n  ) {\n    customizationResources(projectId: $projectId, type: $type, namespace: $namespace, key: $key) {\n      ...CustomizationResourceFragment\n    }\n    customizationValues(\n      projectId: $projectId\n      partnerId: $partnerId\n      domain: $domain\n      type: $type\n      language: $language\n      namespace: $namespace\n    ) {\n      ...CustomizationValueFragment\n    }\n  }\n": types.CustomizationEditorDocument,
    "\n  query CustomizationEditorNamespaces($projectId: String!) {\n    customizationNamespaces(projectId: $projectId)\n  }\n": types.CustomizationEditorNamespacesDocument,
    "\n  mutation CreateCustomizationResourceUploadUrl(\n    $resourceId: ID!\n    $data: CreateCustomizationResourceUploadUrlInput!\n  ) {\n    createCustomizationResourceUploadUrl(resourceId: $resourceId, data: $data) {\n      uploadUrl\n      downloadUrl\n    }\n  }\n": types.CreateCustomizationResourceUploadUrlDocument,
    "\n  mutation SetCustomizationValues($data: [SetCustomizationValueInput!]!) {\n    setCustomizationValues(data: $data) {\n      ...CustomizationValueFragment\n    }\n  }\n": types.SetCustomizationValuesDocument,
    "\n  mutation DeleteCustomizationValue($id: ID!) {\n    deleteCustomizationValue(id: $id) {\n      ...CustomizationValueFragment\n    }\n  }\n": types.DeleteCustomizationValueDocument,
    "\n      mutation CreateCustomizationUploadUrl($filename: String!) {\n        createCustomizationUploadUrl(filename: $filename) {\n          uploadUrl\n          downloadUrl\n        }\n      }\n    ": types.CreateCustomizationUploadUrlDocument,
    "\n  mutation CreatePageComponentResourceUploadUrl($pageComponentId: ID!, $filename: String!) {\n    createPageComponentResourceUploadUrl(pageComponentId: $pageComponentId, filename: $filename) {\n      uploadUrl\n      downloadUrl\n    }\n  }\n": types.CreatePageComponentResourceUploadUrlDocument,
    "\n  mutation CreatePageComponent($landingPageId: ID!, $type: PageComponentType!) {\n    createPageComponent(landingPageId: $landingPageId, type: $type) {\n      ...PageComponentFragment\n    }\n  }\n": types.CreatePageComponentDocument,
    "\n  mutation DeletePageComponent($pageComponentId: ID!) {\n    deletePageComponent(pageComponentId: $pageComponentId) {\n      ...PageComponentFragment\n    }\n  }\n": types.DeletePageComponentDocument,
    "\n  mutation MovePageComponentDown($pageComponentId: ID!) {\n    movePageComponentDown(pageComponentId: $pageComponentId) {\n      ...PageComponentFragment\n    }\n  }\n": types.MovePageComponentDownDocument,
    "\n  mutation MovePageComponentUp($pageComponentId: ID!) {\n    movePageComponentUp(pageComponentId: $pageComponentId) {\n      ...PageComponentFragment\n    }\n  }\n": types.MovePageComponentUpDocument,
    "\n  query AgentPageComponentData($siteId: ID!, $pageComponentId: ID!) {\n    site(id: $siteId) {\n      id\n      agentLandingPage {\n        id\n        pageComponent(id: $pageComponentId) {\n          ...PageComponentFragment\n          customizationValues {\n            ...CustomizationValueFragment\n          }\n        }\n      }\n    }\n  }\n": types.AgentPageComponentDataDocument,
    "\n  query PageComponentData($siteId: ID!, $landingPageId: ID!, $pageComponentId: ID!) {\n    site(id: $siteId) {\n      id\n      landingPage(id: $landingPageId) {\n        id\n        pageComponent(id: $pageComponentId) {\n          ...PageComponentFragment\n          customizationValues {\n            ...CustomizationValueFragment\n          }\n        }\n      }\n    }\n  }\n": types.PageComponentDataDocument,
    "\n  mutation UpdatePageComponent(\n    $pageComponentId: ID!\n    $config: JSON!\n    $resources: [UpdatePageComponentResourcesInput!]!\n  ) {\n    updatePageComponent(pageComponentId: $pageComponentId, config: $config, resources: $resources) {\n      ...PageComponentFragment\n      customizationValues {\n        ...CustomizationValueFragment\n      }\n    }\n  }\n": types.UpdatePageComponentDocument,
    "\n  fragment LandingPageFragment on LandingPage {\n    id\n    title\n    path\n    type\n    siteId\n    createdAt\n    updatedAt\n  }\n": types.LandingPageFragmentFragmentDoc,
    "\n  fragment PageComponentFragment on PageComponent {\n    id\n    typeName\n    landingPageId\n    config\n    resources\n    position\n    createdAt\n    updatedAt\n  }\n": types.PageComponentFragmentFragmentDoc,
    "\n  query LandingPageCustomizations($projectId: String!, $partnerId: Int!, $domain: String) {\n    customizationValues(projectId: $projectId, partnerId: $partnerId, domain: $domain) {\n      ...CustomizationValueFragment\n    }\n  }\n": types.LandingPageCustomizationsDocument,
    "\n  query PartnerPreQualificationExpirationDays($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      preQualificationExpirationDays\n    }\n  }\n": types.PartnerPreQualificationExpirationDaysDocument,
    "\n  query SitePreQualificationExpirationDays($partnerId: ID!, $siteId: ID!) {\n    partner(id: $partnerId) {\n      id\n      site(id: $siteId) {\n        preQualificationExpirationDays\n      }\n    }\n  }\n": types.SitePreQualificationExpirationDaysDocument,
    "\n  mutation SetPreQualificationExpirationDays($partnerId: ID, $siteId: ID, $days: Int!) {\n    setPreQualificationExpirationDays(partnerId: $partnerId, siteId: $siteId, days: $days)\n  }\n": types.SetPreQualificationExpirationDaysDocument,
    "\n  mutation ResetPreQualificationExpirationDays($siteId: ID!) {\n    resetPreQualificationExpirationDays(siteId: $siteId)\n  }\n": types.ResetPreQualificationExpirationDaysDocument,
    "\n  query PartnerNotificationAlternateAgents(\n    $partnerId: ID!\n    $notificationType: String!\n    $orderBy: JSON\n  ) {\n    partner(id: $partnerId) {\n      id\n      notificationAlternateAgents(notificationType: $notificationType, orderBy: $orderBy) {\n        agents {\n          id\n          name\n          avatarUrl\n          email\n        }\n      }\n    }\n  }\n": types.PartnerNotificationAlternateAgentsDocument,
    "\n  query SiteNotificationAlternateAgents(\n    $partnerId: ID!\n    $notificationType: String!\n    $siteId: ID!\n    $orderBy: JSON\n  ) {\n    partner(id: $partnerId) {\n      id\n      site(id: $siteId) {\n        id\n        notificationAlternateAgents(notificationType: $notificationType, orderBy: $orderBy) {\n          agents {\n            id\n            name\n            avatarUrl\n            email\n          }\n          partnerInheritanceDisabled\n        }\n      }\n    }\n  }\n": types.SiteNotificationAlternateAgentsDocument,
    "\n  query QPartnerAllAgents(\n    $partnerId: ID!\n    $searchCriteria: [SearchCriteria!]\n    $paginationOptions: PaginationOptions\n  ) {\n    partner(id: $partnerId) {\n      id\n      agents(criteria: $searchCriteria, options: $paginationOptions) {\n        items {\n          id\n          name\n          avatarUrl\n          email\n        }\n      }\n    }\n  }\n": types.QPartnerAllAgentsDocument,
    "\n  query QSiteAllAgents(\n    $partnerId: ID!\n    $siteId: ID!\n    $searchCriteria: [SearchCriteria!]\n    $paginationOptions: PaginationOptions\n  ) {\n    partner(id: $partnerId) {\n      id\n      site(id: $siteId) {\n        id\n        agents(criteria: $searchCriteria, options: $paginationOptions) {\n          items {\n            id\n            name\n            avatarUrl\n            email\n          }\n        }\n      }\n    }\n  }\n": types.QSiteAllAgentsDocument,
    "\n  mutation NotificationAlternateAgentSetPartnerInheritance(\n    $siteId: ID!\n    $notificationType: String!\n    $enabled: Boolean!\n  ) {\n    notificationAlternateAgentSetPartnerInheritance(\n      siteId: $siteId\n      notificationType: $notificationType\n      enabled: $enabled\n    )\n  }\n": types.NotificationAlternateAgentSetPartnerInheritanceDocument,
    "\n  mutation AddNotificationAlternateAgent(\n    $agentId: ID!\n    $notificationType: String!\n    $partnerId: ID!\n    $siteId: ID\n  ) {\n    addNotificationAlternateAgent(\n      agentId: $agentId\n      notificationType: $notificationType\n      partnerId: $partnerId\n      siteId: $siteId\n    )\n  }\n": types.AddNotificationAlternateAgentDocument,
    "\n  mutation RemoveNotificationAlternateAgent(\n    $agentId: ID!\n    $notificationType: String!\n    $partnerId: ID!\n    $siteId: ID\n  ) {\n    removeNotificationAlternateAgent(\n      agentId: $agentId\n      notificationType: $notificationType\n      partnerId: $partnerId\n      siteId: $siteId\n    )\n  }\n": types.RemoveNotificationAlternateAgentDocument,
    "\n  query WebhookConfiguration(\n    $parentId: ID!\n    $parentType: ParentType!\n    $partnerId: ID!\n  ) {\n    webhookConfiguration(\n      parentId: $parentId\n      parentType: $parentType\n      partnerId: $partnerId\n    ) {\n      id\n      parentId\n      parentType\n      partnerId\n      guid\n      inherited\n      overwrittenBy {\n        id\n        name\n      }\n      createdAt\n      updatedAt\n    }\n  }\n": types.WebhookConfigurationDocument,
    "\n  mutation SaveWebhookConfiguration(\n    $parentId: ID!\n    $parentType: ParentType!\n    $partnerId: ID!\n  ) {\n    saveWebhookConfiguration(\n      parentId: $parentId\n      parentType: $parentType\n      partnerId: $partnerId\n    )\n  }\n": types.SaveWebhookConfigurationDocument,
    "\n  mutation DeleteWebhookConfiguration($id: ID!) {\n    deleteWebhookConfiguration(id: $id)\n  }\n": types.DeleteWebhookConfigurationDocument,
    "\n  fragment SiteFragment on Site {\n    authConfiguration\n    domain\n    id\n    liveSite\n    name\n    partnerId\n    timezone\n    tpoSite\n    verticalName\n  }\n": types.SiteFragmentFragmentDoc,
    "\n  query Blueprint($partnerId: ID!, $blueprintId: ID!) {\n    partner(id: $partnerId) {\n      id\n      blueprint(id: $blueprintId) {\n        id\n        type\n        name\n        json\n      }\n    }\n  }\n": types.BlueprintDocument,
    "\n  query BlueprintList($partnerId: ID!, $perPage: Int!, $cursor: String) {\n    partner(id: $partnerId) {\n      id\n      blueprints(options: { perPage: $perPage, cursor: $cursor, orderBy: { name: \"asc\" } }) {\n        total\n        nextCursor\n        previousCursor\n        items {\n          id\n          name\n          type\n          createdAt\n          updatedAt\n          assignments {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n": types.BlueprintListDocument,
    "\n  mutation CreateBlueprint($partnerId: ID!, $blueprint: CreateBlueprintInput!) {\n    createBlueprint(partnerId: $partnerId, blueprint: $blueprint) {\n      id\n    }\n  }\n": types.CreateBlueprintDocument,
    "\n  mutation CreatePartnerDocuments($partnerId: ID!, $documents: [DocumentInput!]!) {\n    createPartnerDocuments(partnerId: $partnerId, documents: $documents) {\n      id\n      description\n      filename\n      size\n    }\n  }\n": types.CreatePartnerDocumentsDocument,
    "\n  mutation CreateRegisteredIntegrationConnection(\n    $partnerId: ID!\n    $data: RegisteredIntegrationConnectionInput!\n  ) {\n    createRegisteredIntegrationConnection(partnerId: $partnerId, data: $data) {\n      id\n    }\n  }\n": types.CreateRegisteredIntegrationConnectionDocument,
    "\n  mutation CreateSite($partnerId: ID!, $data: CreateSiteInput!) {\n    createSite(partnerId: $partnerId, data: $data)\n  }\n": types.CreateSiteDocument,
    "\n  mutation DeletePartnerDocument($partnerId: ID!, $documentId: ID!) {\n    deletePartnerDocument(partnerId: $partnerId, documentId: $documentId) {\n      id\n    }\n  }\n": types.DeletePartnerDocumentDocument,
    "\n  mutation DeleteRegisteredIntegrationConnection($connectionId: ID!) {\n    deleteRegisteredIntegrationConnection(connectionId: $connectionId) {\n      id\n    }\n  }\n": types.DeleteRegisteredIntegrationConnectionDocument,
    "\n  mutation DeleteSite($siteId: ID!) {\n    deleteSite(siteId: $siteId) {\n      id\n    }\n  }\n": types.DeleteSiteDocument,
    "\n  mutation PartnerDocumentPostUrl($partnerId: ID!, $filename: String!) {\n    getPartnerDocumentPostUrl(partnerId: $partnerId, filename: $filename) {\n      id\n      url\n      postUrl\n    }\n  }\n": types.PartnerDocumentPostUrlDocument,
    "\n  query PartnerDocuments($partnerId: ID!, $perPage: Int!, $cursor: String) {\n    getPartnerDocuments(\n      partnerId: $partnerId\n      options: { perPage: $perPage, cursor: $cursor, orderBy: { updated_at: \"ASC\" } }\n    ) {\n      items {\n        id\n        partnerId\n        filename\n        description\n        updatedAt\n      }\n      nextCursor\n      previousCursor\n      total\n    }\n  }\n": types.PartnerDocumentsDocument,
    "\n  query PartnerData($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      name\n      isBillable\n      adminDomain\n      adminInactivityTimeout\n      timezone\n    }\n  }\n": types.PartnerDataDocument,
    "\n  query PartnersSelect($criteria:  [SearchCriteria!]) {\n    partners(\n      criteria: $criteria\n    ) {\n      items {\n        value: id\n        label: name\n      }\n    }\n  }\n": types.PartnersSelectDocument,
    "\n  mutation RegisterSiteAuth0Tenant($siteId: ID!, $data: SiteAuth0TenantInput!) {\n    registerSiteAuth0Tenant(siteId: $siteId, data: $data)\n  }\n": types.RegisterSiteAuth0TenantDocument,
    "\n  query RegisteredIntegrationConnection($partnerId: ID!, $registeredIntegrationConnectionId: ID!) {\n    partner(id: $partnerId) {\n      id\n      registeredIntegrationConnection(id: $registeredIntegrationConnectionId) {\n        id\n        uuid\n        connectionVersion\n        partnerId\n        sagaName\n        pathSlug\n        securityType\n        accessToken\n        accessSecret\n        signatureHeader\n        signatureSigningKey\n        basicUsername\n        basicPassword\n        createdAt\n        updatedAt\n        jwksUrl\n        activated\n        externalToken\n        externalTokenKey\n        integrationType\n        integrationAction\n      }\n    }\n  }\n": types.RegisteredIntegrationConnectionDocument,
    "\n  query RegisteredIntegrationConnections($partnerId: ID!, $perPage: Int!) {\n    partner(id: $partnerId) {\n      id\n      registeredIntegrationConnections(\n        options: { perPage: $perPage, orderBy: { path_slug: \"asc\" } }\n      ) {\n        total\n        items {\n          id\n          uuid\n          connectionVersion\n          partnerId\n          sagaName\n          pathSlug\n          securityType\n          accessToken\n          accessSecret\n          signatureHeader\n          signatureSigningKey\n          basicUsername\n          basicPassword\n          createdAt\n          updatedAt\n          jwksUrl\n          activated\n          externalToken\n          externalTokenKey\n          integrationType\n          integrationAction\n        }\n        nextCursor\n        previousCursor\n      }\n    }\n  }\n": types.RegisteredIntegrationConnectionsDocument,
    "\n  mutation SetBlueprint($blueprintId: ID!, $blueprint: UpdateBlueprintInput!) {\n    saveBlueprint(blueprintId: $blueprintId, blueprint: $blueprint) {\n      id\n    }\n  }\n": types.SetBlueprintDocument,
    "\n  query SiteData($siteId: ID!) {\n    site(id: $siteId) {\n      ...SiteFragment\n      blueprint {\n        id\n      }\n      applicationBlueprint {\n        id\n      }\n    }\n  }\n": types.SiteDataDocument,
    "\n  query PartnerSites($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      sites(options: { orderBy: { name: \"asc\" } }) {\n        items {\n          value: id\n          label: name\n        }\n      }\n    }\n  }\n": types.PartnerSitesDocument,
    "\n  mutation UnregisterSiteAuth0Tenant($siteId: ID!) {\n    unregisterSiteAuth0Tenant(siteId: $siteId)\n  }\n": types.UnregisterSiteAuth0TenantDocument,
    "\n  mutation UpdateRegisteredIntegrationConnection(\n    $connectionId: ID!\n    $data: RegisteredIntegrationConnectionInput!\n  ) {\n    updateRegisteredIntegrationConnection(connectionId: $connectionId, data: $data) {\n      id\n    }\n  }\n": types.UpdateRegisteredIntegrationConnectionDocument,
    "\n  mutation UpdateSite($siteId: ID!, $data: UpdateSiteInput!) {\n    updateSite(siteId: $siteId, data: $data)\n  }\n": types.UpdateSiteDocument,
    "\n  mutation CreateAgentLandingPage($siteId: ID!) {\n    createAgentLandingPage(siteId: $siteId) {\n      ...LandingPageFragment\n    }\n  }\n": types.CreateAgentLandingPageDocument,
    "\n  fragment ApplicationFileStatusFragment on ApplicationFileStatus {\n    id\n    name\n    color\n    default\n    index\n    createdAt\n    updatedAt\n  }\n": types.ApplicationFileStatusFragmentFragmentDoc,
    "\n  query ApplicationStatus($partnerId: ID!, $statusId: ID!) {\n    partner(id: $partnerId) {\n      id\n      applicationFileStatus(id: $statusId) {\n        ...ApplicationFileStatusFragment\n      }\n    }\n  }\n": types.ApplicationStatusDocument,
    "\n  query ApplicationStatuses($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      applicationFileStatuses {\n        ...ApplicationFileStatusFragment\n      }\n    }\n  }\n": types.ApplicationStatusesDocument,
    "\n  mutation CreateApplicationStatus($partnerId: ID!, $data: ApplicationFileStatusInput!) {\n    createApplicationFileStatus(partnerId: $partnerId, data: $data) {\n      ...ApplicationFileStatusFragment\n    }\n  }\n": types.CreateApplicationStatusDocument,
    "\n  mutation DeleteApplicationStatus($statusId: ID!) {\n    deleteApplicationFileStatus(statusId: $statusId) {\n      ...ApplicationFileStatusFragment\n    }\n  }\n": types.DeleteApplicationStatusDocument,
    "\n  mutation MoveApplicationStatusDown($statusId: ID!) {\n    moveApplicationFileStatusDown(statusId: $statusId) {\n      ...ApplicationFileStatusFragment\n    }\n  }\n": types.MoveApplicationStatusDownDocument,
    "\n  mutation MoveApplicationStatusUp($statusId: ID!) {\n    moveApplicationFileStatusUp(statusId: $statusId) {\n      ...ApplicationFileStatusFragment\n    }\n  }\n": types.MoveApplicationStatusUpDocument,
    "\n  mutation SetDefaultApplicationStatus($statusId: ID!) {\n    setDefaultApplicationFileStatus(statusId: $statusId) {\n      ...ApplicationFileStatusFragment\n    }\n  }\n": types.SetDefaultApplicationStatusDocument,
    "\n  mutation UpdateApplicationStatus($statusId: ID!, $data: ApplicationFileStatusInput!) {\n    updateApplicationFileStatus(statusId: $statusId, data: $data) {\n      ...ApplicationFileStatusFragment\n    }\n  }\n": types.UpdateApplicationStatusDocument,
    "\n  fragment AuthRoleFragment on AuthRole {\n    id\n    name\n    description\n    partnerId\n    createdAt\n    updatedAt\n  }\n": types.AuthRoleFragmentFragmentDoc,
    "\n  query PartnerAuthRolesList($partnerId: ID!, $perPage: Int!, $cursor: String, $orderBy: JSON) {\n    partner(id: $partnerId) {\n      id\n      authRoles(options: { perPage: $perPage, cursor: $cursor, orderBy: $orderBy }) {\n        items {\n          ...AuthRoleFragment\n        }\n        nextCursor\n        previousCursor\n        total\n      }\n    }\n  }\n": types.PartnerAuthRolesListDocument,
    "\n  query PermissionsList {\n    permissions {\n      id\n      resourceName\n      actionName\n    }\n  }\n": types.PermissionsListDocument,
    "\n  query AuthRoleData($partnerId: ID!, $authRoleId: ID!) {\n    partner(id: $partnerId) {\n      id\n      authRole(id: $authRoleId) {\n        ...AuthRoleFragment\n        permissions {\n          id\n        }\n      }\n    }\n  }\n": types.AuthRoleDataDocument,
    "\n  mutation CreateAuthRole($partnerId: ID!, $data: AuthRoleInput!) {\n    createAuthRole(partnerId: $partnerId, data: $data) {\n      ...AuthRoleFragment\n    }\n  }\n": types.CreateAuthRoleDocument,
    "\n  mutation UpdateAuthRole($authRoleId: ID!, $data: AuthRoleInput!) {\n    updateAuthRole(authRoleId: $authRoleId, data: $data) {\n      ...AuthRoleFragment\n    }\n  }\n": types.UpdateAuthRoleDocument,
    "\n  mutation DeleteAuthRole($authRoleId: ID!) {\n    deleteAuthRole(authRoleId: $authRoleId) {\n      ...AuthRoleFragment\n    }\n  }\n": types.DeleteAuthRoleDocument,
    "\n  query AnswersDocumentation($verticalName: VerticalType) {\n    answersDocumentation(verticalName: $verticalName)\n  }\n": types.AnswersDocumentationDocument,
    "\n  mutation DeleteBlueprint($blueprintId: ID!) {\n    deleteBlueprint(blueprintId: $blueprintId)\n  }\n": types.DeleteBlueprintDocument,
    "\n  fragment CustomFieldFragment on CustomField {\n    id\n    name\n    typeName\n    description\n    createdAt\n    updatedAt\n    common\n  }\n": types.CustomFieldFragmentFragmentDoc,
    "\n  query PartnerCustomFieldsList(\n    $partnerId: ID!\n    $vertical: VerticalType!\n    $perPage: Int!\n    $cursor: String\n  ) {\n    partner(id: $partnerId) {\n      id\n      customFields(\n        vertical: $vertical\n        options: { perPage: $perPage, cursor: $cursor, orderBy: { name: \"asc\" } }\n      ) {\n        total\n        previousCursor\n        nextCursor\n        items {\n          ...CustomFieldFragment\n        }\n      }\n    }\n  }\n": types.PartnerCustomFieldsListDocument,
    "\n  query CustomFieldData($partnerId: ID!, $vertical: VerticalType!, $fieldId: ID!) {\n    partner(id: $partnerId) {\n      customField(id: $fieldId, vertical: $vertical) {\n        ...CustomFieldFragment\n      }\n    }\n  }\n": types.CustomFieldDataDocument,
    "\n  mutation CreateCustomField(\n    $partnerId: ID!\n    $vertical: VerticalType!\n    $data: CreateCustomFieldInput!\n  ) {\n    createCustomField(partnerId: $partnerId, vertical: $vertical, data: $data) {\n      ...CustomFieldFragment\n    }\n  }\n": types.CreateCustomFieldDocument,
    "\n  mutation UpdateCustomField(\n    $fieldId: ID!\n    $vertical: VerticalType!\n    $data: UpdateCustomFieldInput!\n  ) {\n    updateCustomField(fieldId: $fieldId, vertical: $vertical, data: $data) {\n      ...CustomFieldFragment\n    }\n  }\n": types.UpdateCustomFieldDocument,
    "\n  mutation DeleteCustomField($fieldId: ID!, $vertical: VerticalType!) {\n    deleteCustomField(fieldId: $fieldId, vertical: $vertical) {\n      ...CustomFieldFragment\n    }\n  }\n": types.DeleteCustomFieldDocument,
    "\n  query SiteLandingPagesList($siteId: ID!, $perPage: Int!, $cursor: String) {\n    site(id: $siteId) {\n      id\n      landingPages(options: { perPage: $perPage, cursor: $cursor, orderBy: { title: \"asc\" } }) {\n        total\n        nextCursor\n        previousCursor\n        items {\n          ...LandingPageFragment\n        }\n      }\n    }\n  }\n": types.SiteLandingPagesListDocument,
    "\n  query ApplicationMaintenanceMode {\n    admin {\n      maintenanceMode\n    }\n  }\n": types.ApplicationMaintenanceModeDocument,
    "\n  mutation UpdateAppMaintenanceMode($mode: Boolean!) {\n    admin {\n      updateApplicationMaintenanceMode(mode: $mode)\n    }\n  }\n": types.UpdateAppMaintenanceModeDocument,
    "\n  query PartnerVerticals($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      verticals\n    }\n  }\n": types.PartnerVerticalsDocument,
    "\n  query SequentialLenderMilestones($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      sequentialLenderMilestones\n    }\n  }\n": types.SequentialLenderMilestonesDocument,
    "\n  query LenderMilestones($partnerId: ID!, $vertical: VerticalType) {\n    partner(id: $partnerId) {\n      id\n      lenderMilestones(vertical: $vertical) {\n        id\n        name\n        position\n        externalMilestoneKey\n        externalMilestoneKeyId\n        updatedAt\n      }\n    }\n  }\n": types.LenderMilestonesDocument,
    "\n  query ExternalMilestoneKeys($partnerId: ID!, $vertical: VerticalType, $ignore: Boolean) {\n    partner(id: $partnerId) {\n      id\n      externalMilestoneKeys(vertical: $vertical, ignore: $ignore) {\n        id\n        name\n        ignore\n        updatedAt\n      }\n    }\n  }\n": types.ExternalMilestoneKeysDocument,
    "\n  mutation UpdateLenderMilestones(\n    $partnerId: ID!\n    $vertical: VerticalType!\n    $data: UpdateLenderMilestoneInput!\n  ) {\n    updateLenderMilestones(partnerId: $partnerId, vertical: $vertical, data: $data) {\n      id\n      tempId\n      name\n      updatedAt\n      position\n      errors\n      deleted\n      externalMilestoneKey\n      externalMilestoneKeyId\n    }\n  }\n": types.UpdateLenderMilestonesDocument,
    "\n  mutation AddExternalMilestoneKey(\n    $partnerId: ID!\n    $vertical: VerticalType!\n    $name: String!\n    $ignore: Boolean!\n  ) {\n    addExternalMilestoneKey(\n      partnerId: $partnerId\n      vertical: $vertical\n      name: $name\n      ignore: $ignore\n    )\n  }\n": types.AddExternalMilestoneKeyDocument,
    "\n  mutation EditExternalMilestoneKey($id: ID!, $name: String!, $ignore: Boolean!) {\n    editExternalMilestoneKey(id: $id, name: $name, ignore: $ignore)\n  }\n": types.EditExternalMilestoneKeyDocument,
    "\n  mutation DeleteExternalMilestoneKey($id: ID!) {\n    deleteExternalMilestoneKey(id: $id)\n  }\n": types.DeleteExternalMilestoneKeyDocument,
    "\n  mutation SetSequentialLenderMilestones($partnerId: ID!, $sequential: Boolean!) {\n    setSequentialLenderMilestones(partnerId: $partnerId, sequential: $sequential)\n  }\n": types.SetSequentialLenderMilestonesDocument,
    "\n  query PartnerNotificationProfilesList($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      notificationProfiles {\n        ...NotificationProfileFragment\n      }\n    }\n  }\n": types.PartnerNotificationProfilesListDocument,
    "\n  fragment PartnerFragment on Partner {\n    id\n    name\n    isBillable\n    adminDomain\n  }\n": types.PartnerFragmentFragmentDoc,
    "\n  query PartnersList($page: Int!, $perPage: Int!, $criteria: [SearchCriteria!], $orderBy: JSON) {\n    partners(criteria: $criteria, options: { page: $page, perPage: $perPage, orderBy: $orderBy }) {\n      itemsTotal\n      itemsCount\n      pageNumber\n      pageCount\n      items {\n        ...PartnerFragment\n      }\n    }\n  }\n": types.PartnersListDocument,
    "\n  query DefaultSmartviewConfig($partnerId: ID!, $model: SmartviewModelType!) {\n    partner(id: $partnerId) {\n      id\n      smartview(model: $model) {\n        id\n        config {\n          columns\n          searchFields\n          exportFields\n        }\n        isSystem\n        rawConfig\n      }\n    }\n  }\n": types.DefaultSmartviewConfigDocument,
    "\n  mutation OverrideDefaultSmartview(\n    $partnerId: ID!\n    $model: SmartviewModelType!\n    $config: DefaultSmartviewConfigInput!\n  ) {\n    overrideDefaultSmartview(partnerId: $partnerId, model: $model, config: $config)\n  }\n": types.OverrideDefaultSmartviewDocument,
    "\n  mutation RevertDefaultSmartview($partnerId: ID!, $model: SmartviewModelType!) {\n    revertDefaultSmartview(partnerId: $partnerId, model: $model)\n  }\n": types.RevertDefaultSmartviewDocument,
    "\n  query SmartviewDocumentation($partnerId: ID!, $model: SmartviewModelType!) {\n    partner(id: $partnerId) {\n      id\n      smartviewDocumentation(model: $model) {\n        model\n        dataFields {\n          name\n          title\n          type\n        }\n        exportFields {\n          name\n          title\n          type\n        }\n        searchFields {\n          name\n          title\n          type\n        }\n      }\n    }\n  }\n": types.SmartviewDocumentationDocument,
    "\n  mutation SetSagaRunnerVersion($partnerId: ID!, $siteId: ID, $version: Int!) {\n    saga {\n      setRunnerVersion(partnerId: $partnerId, siteId: $siteId, version: $version)\n    }\n  }\n": types.SetSagaRunnerVersionDocument,
    "\n  mutation DeleteRuleSetSagas($ruleSetId: ID!, $sagaName: String!) {\n    deleteRuleSetSaga(ruleSetId: $ruleSetId, sagaName: $sagaName) {\n      id\n    }\n  }\n": types.DeleteRuleSetSagasDocument,
    "\n  query PartnerSagas($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      ruleSet {\n        id\n        sagas {\n          name\n          custom\n          system\n          arguments {\n            name\n            type\n            required\n            system\n          }\n          codeLines\n        }\n      }\n    }\n  }\n": types.PartnerSagasDocument,
    "\n  query GetSagaRunnerVersion($partnerId: ID!, $siteId: ID) {\n    saga {\n      sagaRunnerVersion(partnerId: $partnerId, siteId: $siteId)\n    }\n  }\n": types.GetSagaRunnerVersionDocument,
    "\n  query SiteSagas($siteId: ID!) {\n    site(id: $siteId) {\n      id\n      ruleSet {\n        id\n        sagas {\n          name\n          custom\n          system\n          arguments {\n            name\n            type\n            required\n            system\n          }\n          codeLines\n        }\n      }\n    }\n  }\n": types.SiteSagasDocument,
    "\n  mutation UpdateSaga($ruleSetId: ID!, $data: RuleSetSagaInput!) {\n    saveRuleSetSaga(ruleSetId: $ruleSetId, data: $data) {\n      id\n    }\n  }\n": types.UpdateSagaDocument,
    "\n  mutation ValidateSaga($saga: ValidateSagaInput!) {\n    saga {\n      validateSaga(saga: $saga) {\n        message\n        severity\n        lineStart\n        lineEnd\n        colStart\n        colEnd\n      }\n    }\n  }\n": types.ValidateSagaDocument,
    "\n  mutation CancelServiceJob($id: ID!) {\n    cancelServiceJob(id: $id)\n  }\n": types.CancelServiceJobDocument,
    "\n  mutation CancelRepeatableServiceJob($id: ID!) {\n    cancelRepeatableServiceJob(id: $id)\n  }\n": types.CancelRepeatableServiceJobDocument,
    "\n  mutation PauseServiceJob($id: ID!) {\n    pauseServiceJob(id: $id)\n  }\n": types.PauseServiceJobDocument,
    "\n  mutation ResumeServiceJob($id: ID!) {\n    resumeServiceJob(id: $id)\n  }\n": types.ResumeServiceJobDocument,
    "\n  query GetServiceJobs($page: Int!, $perPage: Int!, $jobType: JobType, $orderBy: JSON) {\n    getServiceJobs(\n      jobType: $jobType\n      options: { page: $page, perPage: $perPage, orderBy: $orderBy }\n    ) {\n      items {\n        id\n        jobId\n        jobStatus\n        jobType\n        partnerId\n        model\n        progress\n        totalBatchCount\n        currentBatchCount\n        startDate\n        repeatableJobKey\n        manuallyPaused\n        manuallyCanceled\n        createdAt\n      }\n      itemsTotal\n      itemsCount\n      pageNumber\n      pageCount\n    }\n  }\n": types.GetServiceJobsDocument,
    "\n  query GetRepeatableServiceJobs($page: Int!, $perPage: Int!, $orderBy: JSON) {\n    getRepeatableServiceJobs(options: { page: $page, perPage: $perPage, orderBy: $orderBy }) {\n      items {\n        id\n        jobType\n        partnerId\n        model\n        cancellable\n        cronSchedule\n        startDate\n        repeatMillisInterval\n        repeatableJobKey\n        lastRan\n        lastStatus\n        createdAt\n      }\n      itemsTotal\n      itemsCount\n      pageNumber\n      pageCount\n    }\n  }\n": types.GetRepeatableServiceJobsDocument,
    "\n  query SiteMilestonesList($siteId: ID!) {\n    site(id: $siteId) {\n      id\n      milestones {\n        id\n        key\n        name\n        position\n        externalSourceKeys\n        externalMilestoneKeys {\n          id\n          name\n        }\n        createdAt\n        updatedAt\n      }\n    }\n  }\n": types.SiteMilestonesListDocument,
    "\n  mutation UpdateSiteMilestones($siteId: ID!, $data: UpdateMilestoneInput!) {\n    updateMilestones(siteId: $siteId, data: $data) {\n      id\n      tempId\n      key\n      name\n      position\n      externalMilestoneKeys {\n        id\n        name\n      }\n      externalSourceKeys\n      createdAt\n      updatedAt\n      errors\n      deleted\n    }\n  }\n": types.UpdateSiteMilestonesDocument,
    "\n  query SiteNotificationProfilesList($siteId: ID!) {\n    site(id: $siteId) {\n      id\n      notificationProfiles {\n        ...NotificationProfileFragment\n      }\n    }\n  }\n": types.SiteNotificationProfilesListDocument,
    "\n  query PartnerSitesList(\n    $partnerId: ID!\n    $page: Int!\n    $perPage: Int!\n    $criteria: [SearchCriteria!]\n    $orderBy: JSON\n  ) {\n    partner(id: $partnerId) {\n      id\n      sites(criteria: $criteria, options: { page: $page, perPage: $perPage, orderBy: $orderBy }) {\n        itemsTotal\n        itemsCount\n        pageNumber\n        pageCount\n        items {\n          ...SiteFragment\n        }\n      }\n    }\n  }\n": types.PartnerSitesListDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateLandingPage($siteId: ID!, $data: LandingPageInput!) {\n    createLandingPage(siteId: $siteId, data: $data) {\n      ...LandingPageFragment\n    }\n  }\n"): (typeof documents)["\n  mutation CreateLandingPage($siteId: ID!, $data: LandingPageInput!) {\n    createLandingPage(siteId: $siteId, data: $data) {\n      ...LandingPageFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreatePartner($data: CreatePartnerInput!) {\n    createPartner(data: $data)\n  }\n"): (typeof documents)["\n  mutation CreatePartner($data: CreatePartnerInput!) {\n    createPartner(data: $data)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ScheduleRepeatableServiceJob($data: ScheduleRepeatableServiceJobInput!) {\n    scheduleRepeatableServiceJob(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation ScheduleRepeatableServiceJob($data: ScheduleRepeatableServiceJobInput!) {\n    scheduleRepeatableServiceJob(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ScheduleServiceJob($data: ScheduleServiceJobInput!) {\n    scheduleServiceJob(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation ScheduleServiceJob($data: ScheduleServiceJobInput!) {\n    scheduleServiceJob(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteLandingPage($landingPageId: ID!) {\n    deleteLandingPage(landingPageId: $landingPageId) {\n      ...LandingPageFragment\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteLandingPage($landingPageId: ID!) {\n    deleteLandingPage(landingPageId: $landingPageId) {\n      ...LandingPageFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateLandingPage($landingPageId: ID!, $data: LandingPageInput!) {\n    updateLandingPage(landingPageId: $landingPageId, data: $data) {\n      ...LandingPageFragment\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateLandingPage($landingPageId: ID!, $data: LandingPageInput!) {\n    updateLandingPage(landingPageId: $landingPageId, data: $data) {\n      ...LandingPageFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdatePartner($partnerId: ID!, $data: BasicPartnerInput!) {\n    updatePartner(partnerId: $partnerId, data: $data)\n  }\n"): (typeof documents)["\n  mutation UpdatePartner($partnerId: ID!, $data: BasicPartnerInput!) {\n    updatePartner(partnerId: $partnerId, data: $data)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment NotificationProfileFragment on NotificationProfile {\n    id\n    name\n    typeName\n    data {\n      mediums\n    }\n    rawData\n    parentType\n    parentId\n    createdAt\n    updatedAt\n    args\n  }\n"): (typeof documents)["\n  fragment NotificationProfileFragment on NotificationProfile {\n    id\n    name\n    typeName\n    data {\n      mediums\n    }\n    rawData\n    parentType\n    parentId\n    createdAt\n    updatedAt\n    args\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateNotificationProfile(\n    $parentType: ParentType!\n    $parentId: ID!\n    $data: CreateNotificationProfileInput!\n  ) {\n    createNotificationProfile(parentType: $parentType, parentId: $parentId, data: $data) {\n      ...NotificationProfileFragment\n    }\n  }\n"): (typeof documents)["\n  mutation CreateNotificationProfile(\n    $parentType: ParentType!\n    $parentId: ID!\n    $data: CreateNotificationProfileInput!\n  ) {\n    createNotificationProfile(parentType: $parentType, parentId: $parentId, data: $data) {\n      ...NotificationProfileFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteNotificationProfile($profileId: ID!) {\n    deleteNotificationProfile(profileId: $profileId) {\n      ...NotificationProfileFragment\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteNotificationProfile($profileId: ID!) {\n    deleteNotificationProfile(profileId: $profileId) {\n      ...NotificationProfileFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PartnerNotificationProfile($partnerId: ID!, $profileId: ID!) {\n    partner(id: $partnerId) {\n      id\n      notificationProfile(id: $profileId) {\n        ...NotificationProfileFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query PartnerNotificationProfile($partnerId: ID!, $profileId: ID!) {\n    partner(id: $partnerId) {\n      id\n      notificationProfile(id: $profileId) {\n        ...NotificationProfileFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SiteNotificationProfile($siteId: ID!, $profileId: ID!) {\n    site(id: $siteId) {\n      id\n      notificationProfile(id: $profileId) {\n        ...NotificationProfileFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query SiteNotificationProfile($siteId: ID!, $profileId: ID!) {\n    site(id: $siteId) {\n      id\n      notificationProfile(id: $profileId) {\n        ...NotificationProfileFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateNotificationProfile($profileId: ID!, $data: UpdateNotificationProfileInput!) {\n    updateNotificationProfile(profileId: $profileId, data: $data) {\n      ...NotificationProfileFragment\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateNotificationProfile($profileId: ID!, $data: UpdateNotificationProfileInput!) {\n    updateNotificationProfile(profileId: $profileId, data: $data) {\n      ...NotificationProfileFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PartnerCascadeConfigurations(\n    $partnerId: ID!\n    $cursor: String\n    $perPage: Int!\n    $orderBy: JSON\n  ) {\n    partner(id: $partnerId) {\n      id\n      cascadeConfigurations(options: { perPage: $perPage, cursor: $cursor, orderBy: $orderBy }) {\n        items {\n          id\n          name\n          cascadeType\n          cascadeOwnerType\n          version\n          partnerId\n          siteId\n          createdAt\n          actions {\n            id\n            actionType\n            position\n            startCondition\n            options\n            timeout\n          }\n        }\n        total\n        nextCursor\n        previousCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query PartnerCascadeConfigurations(\n    $partnerId: ID!\n    $cursor: String\n    $perPage: Int!\n    $orderBy: JSON\n  ) {\n    partner(id: $partnerId) {\n      id\n      cascadeConfigurations(options: { perPage: $perPage, cursor: $cursor, orderBy: $orderBy }) {\n        items {\n          id\n          name\n          cascadeType\n          cascadeOwnerType\n          version\n          partnerId\n          siteId\n          createdAt\n          actions {\n            id\n            actionType\n            position\n            startCondition\n            options\n            timeout\n          }\n        }\n        total\n        nextCursor\n        previousCursor\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SiteCascadeConfigurations(\n    $partnerId: ID!\n    $siteId: ID!\n    $cursor: String\n    $perPage: Int!\n    $orderBy: JSON\n  ) {\n    partner(id: $partnerId) {\n      id\n      site(id: $siteId) {\n        id\n        cascadeConfigurations(options: { perPage: $perPage, cursor: $cursor, orderBy: $orderBy }) {\n          items {\n            id\n            name\n            cascadeType\n            cascadeOwnerType\n            version\n            partnerId\n            siteId\n            createdAt\n            actions {\n              id\n              actionType\n              position\n              startCondition\n              options\n              timeout\n            }\n          }\n          total\n          nextCursor\n          previousCursor\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SiteCascadeConfigurations(\n    $partnerId: ID!\n    $siteId: ID!\n    $cursor: String\n    $perPage: Int!\n    $orderBy: JSON\n  ) {\n    partner(id: $partnerId) {\n      id\n      site(id: $siteId) {\n        id\n        cascadeConfigurations(options: { perPage: $perPage, cursor: $cursor, orderBy: $orderBy }) {\n          items {\n            id\n            name\n            cascadeType\n            cascadeOwnerType\n            version\n            partnerId\n            siteId\n            createdAt\n            actions {\n              id\n              actionType\n              position\n              startCondition\n              options\n              timeout\n            }\n          }\n          total\n          nextCursor\n          previousCursor\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SaveCascadeConfiguration($input: SaveCascadeConfigurationInput!) {\n    saveCascadeConfiguration(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation SaveCascadeConfiguration($input: SaveCascadeConfigurationInput!) {\n    saveCascadeConfiguration(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CredentialFragment on Credential {\n    typeName\n    partnerId\n    siteId\n    inherited\n    overwrittenBy {\n      id\n      name\n    }\n    updatedAt\n    data\n  }\n"): (typeof documents)["\n  fragment CredentialFragment on Credential {\n    typeName\n    partnerId\n    siteId\n    inherited\n    overwrittenBy {\n      id\n      name\n    }\n    updatedAt\n    data\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PartnerCredentials($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      credentials {\n        ...CredentialFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query PartnerCredentials($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      credentials {\n        ...CredentialFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PartnerCredential($partnerId: ID!, $credentialType: CredentialsType!) {\n    partner(id: $partnerId) {\n      id\n      credential(credentialType: $credentialType) {\n        ...CredentialFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query PartnerCredential($partnerId: ID!, $credentialType: CredentialsType!) {\n    partner(id: $partnerId) {\n      id\n      credential(credentialType: $credentialType) {\n        ...CredentialFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SiteCredentials($siteId: ID!) {\n    site(id: $siteId) {\n      id\n      credentials {\n        ...CredentialFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query SiteCredentials($siteId: ID!) {\n    site(id: $siteId) {\n      id\n      credentials {\n        ...CredentialFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SiteCredential($siteId: ID!, $credentialType: CredentialsType!) {\n    site(id: $siteId) {\n      id\n      credential(credentialType: $credentialType) {\n        ...CredentialFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query SiteCredential($siteId: ID!, $credentialType: CredentialsType!) {\n    site(id: $siteId) {\n      id\n      credential(credentialType: $credentialType) {\n        ...CredentialFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SaveCredential(\n    $partnerId: ID!\n    $siteId: ID\n    $credentialType: CredentialsType!\n    $data: JSON!\n  ) {\n    saveCredential(\n      partnerId: $partnerId\n      siteId: $siteId\n      credentialType: $credentialType\n      data: $data\n    )\n  }\n"): (typeof documents)["\n  mutation SaveCredential(\n    $partnerId: ID!\n    $siteId: ID\n    $credentialType: CredentialsType!\n    $data: JSON!\n  ) {\n    saveCredential(\n      partnerId: $partnerId\n      siteId: $siteId\n      credentialType: $credentialType\n      data: $data\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteCredential($partnerId: ID!, $siteId: ID, $credentialType: CredentialsType!) {\n    deleteCredential(partnerId: $partnerId, siteId: $siteId, credentialType: $credentialType)\n  }\n"): (typeof documents)["\n  mutation DeleteCredential($partnerId: ID!, $siteId: ID, $credentialType: CredentialsType!) {\n    deleteCredential(partnerId: $partnerId, siteId: $siteId, credentialType: $credentialType)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment EncompassWebhookFragment on EncompassWebhook {\n    subscriptionId\n    endpoint\n    signingkey\n    filters\n    events\n    resource\n  }\n"): (typeof documents)["\n  fragment EncompassWebhookFragment on EncompassWebhook {\n    subscriptionId\n    endpoint\n    signingkey\n    filters\n    events\n    resource\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PartnerEncompassWebhooks($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      encompassWebhooks {\n        ...EncompassWebhookFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query PartnerEncompassWebhooks($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      encompassWebhooks {\n        ...EncompassWebhookFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SitePartnerWebhooks($siteId: ID!) {\n    site(id: $siteId) {\n      id\n      encompassWebhooks {\n        ...EncompassWebhookFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query SitePartnerWebhooks($siteId: ID!) {\n    site(id: $siteId) {\n      id\n      encompassWebhooks {\n        ...EncompassWebhookFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SnapdocsSubscriptionFragment on SnapdocsSubscription {\n    id\n    description\n    webhookUrl\n    events\n  }\n"): (typeof documents)["\n  fragment SnapdocsSubscriptionFragment on SnapdocsSubscription {\n    id\n    description\n    webhookUrl\n    events\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PartnerSnapdocsSubscriptions($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      snapdocsSubscriptions {\n        ...SnapdocsSubscriptionFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query PartnerSnapdocsSubscriptions($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      snapdocsSubscriptions {\n        ...SnapdocsSubscriptionFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SiteSnapdocsSubscriptions($siteId: ID!) {\n    site(id: $siteId) {\n      id\n      snapdocsSubscriptions {\n        ...SnapdocsSubscriptionFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query SiteSnapdocsSubscriptions($siteId: ID!) {\n    site(id: $siteId) {\n      id\n      snapdocsSubscriptions {\n        ...SnapdocsSubscriptionFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TotalExpertCustomFieldsFragment on TotalExpertCustomFieldResult {\n    items {\n      id\n      systemName\n      dataType\n      relatedEntity {\n        type\n        subtype\n      }\n      displayName\n    }\n    itemsCount\n    nextPage\n    pageCount\n  }\n"): (typeof documents)["\n  fragment TotalExpertCustomFieldsFragment on TotalExpertCustomFieldResult {\n    items {\n      id\n      systemName\n      dataType\n      relatedEntity {\n        type\n        subtype\n      }\n      displayName\n    }\n    itemsCount\n    nextPage\n    pageCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PartnerTotalExpertCustomFields($partnerId: ID!, $page: Int!, $perPage: Int!) {\n    partner(id: $partnerId) {\n      totalExpertCustomFields(options: { page: $page, perPage: $perPage }) {\n        ...TotalExpertCustomFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query PartnerTotalExpertCustomFields($partnerId: ID!, $page: Int!, $perPage: Int!) {\n    partner(id: $partnerId) {\n      totalExpertCustomFields(options: { page: $page, perPage: $perPage }) {\n        ...TotalExpertCustomFieldsFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SiteTotalExpertCustomFields($siteId: ID!, $page: Int!, $perPage: Int!) {\n    site(id: $siteId) {\n      totalExpertCustomFields(options: { page: $page, perPage: $perPage }) {\n        ...TotalExpertCustomFieldsFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query SiteTotalExpertCustomFields($siteId: ID!, $page: Int!, $perPage: Int!) {\n    site(id: $siteId) {\n      totalExpertCustomFields(options: { page: $page, perPage: $perPage }) {\n        ...TotalExpertCustomFieldsFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateEncompassWebhook(\n    $parentType: ParentType!\n    $parentId: ID!\n    $endpoint: String!\n    $resource: String!\n    $events: JSON!\n    $filters: JSON\n  ) {\n    createEncompassWebhook(\n      parentType: $parentType\n      parentId: $parentId\n      endpoint: $endpoint\n      resource: $resource\n      events: $events\n      filters: $filters\n    ) {\n      statusCode\n      statusDescription\n    }\n  }\n"): (typeof documents)["\n  mutation CreateEncompassWebhook(\n    $parentType: ParentType!\n    $parentId: ID!\n    $endpoint: String!\n    $resource: String!\n    $events: JSON!\n    $filters: JSON\n  ) {\n    createEncompassWebhook(\n      parentType: $parentType\n      parentId: $parentId\n      endpoint: $endpoint\n      resource: $resource\n      events: $events\n      filters: $filters\n    ) {\n      statusCode\n      statusDescription\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteEncompassWebhook($parentType: ParentType!, $parentId: ID!, $id: ID!) {\n    deleteEncompassWebhook(parentType: $parentType, parentId: $parentId, id: $id) {\n      statusCode\n      statusDescription\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteEncompassWebhook($parentType: ParentType!, $parentId: ID!, $id: ID!) {\n    deleteEncompassWebhook(parentType: $parentType, parentId: $parentId, id: $id) {\n      statusCode\n      statusDescription\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateSnapdocsSubscription($data: CreateSnapdocsSubscriptionInput!) {\n    snapdocs {\n      createSubscription(data: $data)\n    }\n  }\n"): (typeof documents)["\n  mutation CreateSnapdocsSubscription($data: CreateSnapdocsSubscriptionInput!) {\n    snapdocs {\n      createSubscription(data: $data)\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeletenapdocsSubscription($id: ID!, $pathSlug: String!, $partnerId: ID!, $siteId: ID) {\n    snapdocs {\n      deleteSubscription(id: $id, pathSlug: $pathSlug, partnerId: $partnerId, siteId: $siteId)\n    }\n  }\n"): (typeof documents)["\n  mutation DeletenapdocsSubscription($id: ID!, $pathSlug: String!, $partnerId: ID!, $siteId: ID) {\n    snapdocs {\n      deleteSubscription(id: $id, pathSlug: $pathSlug, partnerId: $partnerId, siteId: $siteId)\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CustomizationLanguages {\n    customizationLanguages {\n      value: id\n      label: name\n    }\n  }\n"): (typeof documents)["\n  query CustomizationLanguages {\n    customizationLanguages {\n      value: id\n      label: name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CustomizationResourceFragment on CustomizationResource {\n    id\n    key\n    type\n    namespace\n  }\n"): (typeof documents)["\n  fragment CustomizationResourceFragment on CustomizationResource {\n    id\n    key\n    type\n    namespace\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CustomizationValueFragment on CustomizationValue {\n    id\n    key\n    type\n    namespace\n    value\n    language\n    source\n  }\n"): (typeof documents)["\n  fragment CustomizationValueFragment on CustomizationValue {\n    id\n    key\n    type\n    namespace\n    value\n    language\n    source\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CustomizationEditor(\n    $projectId: String!\n    $partnerId: Int!\n    $domain: String\n    $type: String\n    $language: String\n    $namespace: String\n    $key: String\n  ) {\n    customizationResources(projectId: $projectId, type: $type, namespace: $namespace, key: $key) {\n      ...CustomizationResourceFragment\n    }\n    customizationValues(\n      projectId: $projectId\n      partnerId: $partnerId\n      domain: $domain\n      type: $type\n      language: $language\n      namespace: $namespace\n    ) {\n      ...CustomizationValueFragment\n    }\n  }\n"): (typeof documents)["\n  query CustomizationEditor(\n    $projectId: String!\n    $partnerId: Int!\n    $domain: String\n    $type: String\n    $language: String\n    $namespace: String\n    $key: String\n  ) {\n    customizationResources(projectId: $projectId, type: $type, namespace: $namespace, key: $key) {\n      ...CustomizationResourceFragment\n    }\n    customizationValues(\n      projectId: $projectId\n      partnerId: $partnerId\n      domain: $domain\n      type: $type\n      language: $language\n      namespace: $namespace\n    ) {\n      ...CustomizationValueFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CustomizationEditorNamespaces($projectId: String!) {\n    customizationNamespaces(projectId: $projectId)\n  }\n"): (typeof documents)["\n  query CustomizationEditorNamespaces($projectId: String!) {\n    customizationNamespaces(projectId: $projectId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateCustomizationResourceUploadUrl(\n    $resourceId: ID!\n    $data: CreateCustomizationResourceUploadUrlInput!\n  ) {\n    createCustomizationResourceUploadUrl(resourceId: $resourceId, data: $data) {\n      uploadUrl\n      downloadUrl\n    }\n  }\n"): (typeof documents)["\n  mutation CreateCustomizationResourceUploadUrl(\n    $resourceId: ID!\n    $data: CreateCustomizationResourceUploadUrlInput!\n  ) {\n    createCustomizationResourceUploadUrl(resourceId: $resourceId, data: $data) {\n      uploadUrl\n      downloadUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetCustomizationValues($data: [SetCustomizationValueInput!]!) {\n    setCustomizationValues(data: $data) {\n      ...CustomizationValueFragment\n    }\n  }\n"): (typeof documents)["\n  mutation SetCustomizationValues($data: [SetCustomizationValueInput!]!) {\n    setCustomizationValues(data: $data) {\n      ...CustomizationValueFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteCustomizationValue($id: ID!) {\n    deleteCustomizationValue(id: $id) {\n      ...CustomizationValueFragment\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteCustomizationValue($id: ID!) {\n    deleteCustomizationValue(id: $id) {\n      ...CustomizationValueFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation CreateCustomizationUploadUrl($filename: String!) {\n        createCustomizationUploadUrl(filename: $filename) {\n          uploadUrl\n          downloadUrl\n        }\n      }\n    "): (typeof documents)["\n      mutation CreateCustomizationUploadUrl($filename: String!) {\n        createCustomizationUploadUrl(filename: $filename) {\n          uploadUrl\n          downloadUrl\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreatePageComponentResourceUploadUrl($pageComponentId: ID!, $filename: String!) {\n    createPageComponentResourceUploadUrl(pageComponentId: $pageComponentId, filename: $filename) {\n      uploadUrl\n      downloadUrl\n    }\n  }\n"): (typeof documents)["\n  mutation CreatePageComponentResourceUploadUrl($pageComponentId: ID!, $filename: String!) {\n    createPageComponentResourceUploadUrl(pageComponentId: $pageComponentId, filename: $filename) {\n      uploadUrl\n      downloadUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreatePageComponent($landingPageId: ID!, $type: PageComponentType!) {\n    createPageComponent(landingPageId: $landingPageId, type: $type) {\n      ...PageComponentFragment\n    }\n  }\n"): (typeof documents)["\n  mutation CreatePageComponent($landingPageId: ID!, $type: PageComponentType!) {\n    createPageComponent(landingPageId: $landingPageId, type: $type) {\n      ...PageComponentFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeletePageComponent($pageComponentId: ID!) {\n    deletePageComponent(pageComponentId: $pageComponentId) {\n      ...PageComponentFragment\n    }\n  }\n"): (typeof documents)["\n  mutation DeletePageComponent($pageComponentId: ID!) {\n    deletePageComponent(pageComponentId: $pageComponentId) {\n      ...PageComponentFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation MovePageComponentDown($pageComponentId: ID!) {\n    movePageComponentDown(pageComponentId: $pageComponentId) {\n      ...PageComponentFragment\n    }\n  }\n"): (typeof documents)["\n  mutation MovePageComponentDown($pageComponentId: ID!) {\n    movePageComponentDown(pageComponentId: $pageComponentId) {\n      ...PageComponentFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation MovePageComponentUp($pageComponentId: ID!) {\n    movePageComponentUp(pageComponentId: $pageComponentId) {\n      ...PageComponentFragment\n    }\n  }\n"): (typeof documents)["\n  mutation MovePageComponentUp($pageComponentId: ID!) {\n    movePageComponentUp(pageComponentId: $pageComponentId) {\n      ...PageComponentFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AgentPageComponentData($siteId: ID!, $pageComponentId: ID!) {\n    site(id: $siteId) {\n      id\n      agentLandingPage {\n        id\n        pageComponent(id: $pageComponentId) {\n          ...PageComponentFragment\n          customizationValues {\n            ...CustomizationValueFragment\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AgentPageComponentData($siteId: ID!, $pageComponentId: ID!) {\n    site(id: $siteId) {\n      id\n      agentLandingPage {\n        id\n        pageComponent(id: $pageComponentId) {\n          ...PageComponentFragment\n          customizationValues {\n            ...CustomizationValueFragment\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PageComponentData($siteId: ID!, $landingPageId: ID!, $pageComponentId: ID!) {\n    site(id: $siteId) {\n      id\n      landingPage(id: $landingPageId) {\n        id\n        pageComponent(id: $pageComponentId) {\n          ...PageComponentFragment\n          customizationValues {\n            ...CustomizationValueFragment\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query PageComponentData($siteId: ID!, $landingPageId: ID!, $pageComponentId: ID!) {\n    site(id: $siteId) {\n      id\n      landingPage(id: $landingPageId) {\n        id\n        pageComponent(id: $pageComponentId) {\n          ...PageComponentFragment\n          customizationValues {\n            ...CustomizationValueFragment\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdatePageComponent(\n    $pageComponentId: ID!\n    $config: JSON!\n    $resources: [UpdatePageComponentResourcesInput!]!\n  ) {\n    updatePageComponent(pageComponentId: $pageComponentId, config: $config, resources: $resources) {\n      ...PageComponentFragment\n      customizationValues {\n        ...CustomizationValueFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdatePageComponent(\n    $pageComponentId: ID!\n    $config: JSON!\n    $resources: [UpdatePageComponentResourcesInput!]!\n  ) {\n    updatePageComponent(pageComponentId: $pageComponentId, config: $config, resources: $resources) {\n      ...PageComponentFragment\n      customizationValues {\n        ...CustomizationValueFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment LandingPageFragment on LandingPage {\n    id\n    title\n    path\n    type\n    siteId\n    createdAt\n    updatedAt\n  }\n"): (typeof documents)["\n  fragment LandingPageFragment on LandingPage {\n    id\n    title\n    path\n    type\n    siteId\n    createdAt\n    updatedAt\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PageComponentFragment on PageComponent {\n    id\n    typeName\n    landingPageId\n    config\n    resources\n    position\n    createdAt\n    updatedAt\n  }\n"): (typeof documents)["\n  fragment PageComponentFragment on PageComponent {\n    id\n    typeName\n    landingPageId\n    config\n    resources\n    position\n    createdAt\n    updatedAt\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query LandingPageCustomizations($projectId: String!, $partnerId: Int!, $domain: String) {\n    customizationValues(projectId: $projectId, partnerId: $partnerId, domain: $domain) {\n      ...CustomizationValueFragment\n    }\n  }\n"): (typeof documents)["\n  query LandingPageCustomizations($projectId: String!, $partnerId: Int!, $domain: String) {\n    customizationValues(projectId: $projectId, partnerId: $partnerId, domain: $domain) {\n      ...CustomizationValueFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PartnerPreQualificationExpirationDays($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      preQualificationExpirationDays\n    }\n  }\n"): (typeof documents)["\n  query PartnerPreQualificationExpirationDays($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      preQualificationExpirationDays\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SitePreQualificationExpirationDays($partnerId: ID!, $siteId: ID!) {\n    partner(id: $partnerId) {\n      id\n      site(id: $siteId) {\n        preQualificationExpirationDays\n      }\n    }\n  }\n"): (typeof documents)["\n  query SitePreQualificationExpirationDays($partnerId: ID!, $siteId: ID!) {\n    partner(id: $partnerId) {\n      id\n      site(id: $siteId) {\n        preQualificationExpirationDays\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetPreQualificationExpirationDays($partnerId: ID, $siteId: ID, $days: Int!) {\n    setPreQualificationExpirationDays(partnerId: $partnerId, siteId: $siteId, days: $days)\n  }\n"): (typeof documents)["\n  mutation SetPreQualificationExpirationDays($partnerId: ID, $siteId: ID, $days: Int!) {\n    setPreQualificationExpirationDays(partnerId: $partnerId, siteId: $siteId, days: $days)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ResetPreQualificationExpirationDays($siteId: ID!) {\n    resetPreQualificationExpirationDays(siteId: $siteId)\n  }\n"): (typeof documents)["\n  mutation ResetPreQualificationExpirationDays($siteId: ID!) {\n    resetPreQualificationExpirationDays(siteId: $siteId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PartnerNotificationAlternateAgents(\n    $partnerId: ID!\n    $notificationType: String!\n    $orderBy: JSON\n  ) {\n    partner(id: $partnerId) {\n      id\n      notificationAlternateAgents(notificationType: $notificationType, orderBy: $orderBy) {\n        agents {\n          id\n          name\n          avatarUrl\n          email\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query PartnerNotificationAlternateAgents(\n    $partnerId: ID!\n    $notificationType: String!\n    $orderBy: JSON\n  ) {\n    partner(id: $partnerId) {\n      id\n      notificationAlternateAgents(notificationType: $notificationType, orderBy: $orderBy) {\n        agents {\n          id\n          name\n          avatarUrl\n          email\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SiteNotificationAlternateAgents(\n    $partnerId: ID!\n    $notificationType: String!\n    $siteId: ID!\n    $orderBy: JSON\n  ) {\n    partner(id: $partnerId) {\n      id\n      site(id: $siteId) {\n        id\n        notificationAlternateAgents(notificationType: $notificationType, orderBy: $orderBy) {\n          agents {\n            id\n            name\n            avatarUrl\n            email\n          }\n          partnerInheritanceDisabled\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SiteNotificationAlternateAgents(\n    $partnerId: ID!\n    $notificationType: String!\n    $siteId: ID!\n    $orderBy: JSON\n  ) {\n    partner(id: $partnerId) {\n      id\n      site(id: $siteId) {\n        id\n        notificationAlternateAgents(notificationType: $notificationType, orderBy: $orderBy) {\n          agents {\n            id\n            name\n            avatarUrl\n            email\n          }\n          partnerInheritanceDisabled\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query QPartnerAllAgents(\n    $partnerId: ID!\n    $searchCriteria: [SearchCriteria!]\n    $paginationOptions: PaginationOptions\n  ) {\n    partner(id: $partnerId) {\n      id\n      agents(criteria: $searchCriteria, options: $paginationOptions) {\n        items {\n          id\n          name\n          avatarUrl\n          email\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query QPartnerAllAgents(\n    $partnerId: ID!\n    $searchCriteria: [SearchCriteria!]\n    $paginationOptions: PaginationOptions\n  ) {\n    partner(id: $partnerId) {\n      id\n      agents(criteria: $searchCriteria, options: $paginationOptions) {\n        items {\n          id\n          name\n          avatarUrl\n          email\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query QSiteAllAgents(\n    $partnerId: ID!\n    $siteId: ID!\n    $searchCriteria: [SearchCriteria!]\n    $paginationOptions: PaginationOptions\n  ) {\n    partner(id: $partnerId) {\n      id\n      site(id: $siteId) {\n        id\n        agents(criteria: $searchCriteria, options: $paginationOptions) {\n          items {\n            id\n            name\n            avatarUrl\n            email\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query QSiteAllAgents(\n    $partnerId: ID!\n    $siteId: ID!\n    $searchCriteria: [SearchCriteria!]\n    $paginationOptions: PaginationOptions\n  ) {\n    partner(id: $partnerId) {\n      id\n      site(id: $siteId) {\n        id\n        agents(criteria: $searchCriteria, options: $paginationOptions) {\n          items {\n            id\n            name\n            avatarUrl\n            email\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation NotificationAlternateAgentSetPartnerInheritance(\n    $siteId: ID!\n    $notificationType: String!\n    $enabled: Boolean!\n  ) {\n    notificationAlternateAgentSetPartnerInheritance(\n      siteId: $siteId\n      notificationType: $notificationType\n      enabled: $enabled\n    )\n  }\n"): (typeof documents)["\n  mutation NotificationAlternateAgentSetPartnerInheritance(\n    $siteId: ID!\n    $notificationType: String!\n    $enabled: Boolean!\n  ) {\n    notificationAlternateAgentSetPartnerInheritance(\n      siteId: $siteId\n      notificationType: $notificationType\n      enabled: $enabled\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddNotificationAlternateAgent(\n    $agentId: ID!\n    $notificationType: String!\n    $partnerId: ID!\n    $siteId: ID\n  ) {\n    addNotificationAlternateAgent(\n      agentId: $agentId\n      notificationType: $notificationType\n      partnerId: $partnerId\n      siteId: $siteId\n    )\n  }\n"): (typeof documents)["\n  mutation AddNotificationAlternateAgent(\n    $agentId: ID!\n    $notificationType: String!\n    $partnerId: ID!\n    $siteId: ID\n  ) {\n    addNotificationAlternateAgent(\n      agentId: $agentId\n      notificationType: $notificationType\n      partnerId: $partnerId\n      siteId: $siteId\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RemoveNotificationAlternateAgent(\n    $agentId: ID!\n    $notificationType: String!\n    $partnerId: ID!\n    $siteId: ID\n  ) {\n    removeNotificationAlternateAgent(\n      agentId: $agentId\n      notificationType: $notificationType\n      partnerId: $partnerId\n      siteId: $siteId\n    )\n  }\n"): (typeof documents)["\n  mutation RemoveNotificationAlternateAgent(\n    $agentId: ID!\n    $notificationType: String!\n    $partnerId: ID!\n    $siteId: ID\n  ) {\n    removeNotificationAlternateAgent(\n      agentId: $agentId\n      notificationType: $notificationType\n      partnerId: $partnerId\n      siteId: $siteId\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query WebhookConfiguration(\n    $parentId: ID!\n    $parentType: ParentType!\n    $partnerId: ID!\n  ) {\n    webhookConfiguration(\n      parentId: $parentId\n      parentType: $parentType\n      partnerId: $partnerId\n    ) {\n      id\n      parentId\n      parentType\n      partnerId\n      guid\n      inherited\n      overwrittenBy {\n        id\n        name\n      }\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query WebhookConfiguration(\n    $parentId: ID!\n    $parentType: ParentType!\n    $partnerId: ID!\n  ) {\n    webhookConfiguration(\n      parentId: $parentId\n      parentType: $parentType\n      partnerId: $partnerId\n    ) {\n      id\n      parentId\n      parentType\n      partnerId\n      guid\n      inherited\n      overwrittenBy {\n        id\n        name\n      }\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SaveWebhookConfiguration(\n    $parentId: ID!\n    $parentType: ParentType!\n    $partnerId: ID!\n  ) {\n    saveWebhookConfiguration(\n      parentId: $parentId\n      parentType: $parentType\n      partnerId: $partnerId\n    )\n  }\n"): (typeof documents)["\n  mutation SaveWebhookConfiguration(\n    $parentId: ID!\n    $parentType: ParentType!\n    $partnerId: ID!\n  ) {\n    saveWebhookConfiguration(\n      parentId: $parentId\n      parentType: $parentType\n      partnerId: $partnerId\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteWebhookConfiguration($id: ID!) {\n    deleteWebhookConfiguration(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteWebhookConfiguration($id: ID!) {\n    deleteWebhookConfiguration(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SiteFragment on Site {\n    authConfiguration\n    domain\n    id\n    liveSite\n    name\n    partnerId\n    timezone\n    tpoSite\n    verticalName\n  }\n"): (typeof documents)["\n  fragment SiteFragment on Site {\n    authConfiguration\n    domain\n    id\n    liveSite\n    name\n    partnerId\n    timezone\n    tpoSite\n    verticalName\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Blueprint($partnerId: ID!, $blueprintId: ID!) {\n    partner(id: $partnerId) {\n      id\n      blueprint(id: $blueprintId) {\n        id\n        type\n        name\n        json\n      }\n    }\n  }\n"): (typeof documents)["\n  query Blueprint($partnerId: ID!, $blueprintId: ID!) {\n    partner(id: $partnerId) {\n      id\n      blueprint(id: $blueprintId) {\n        id\n        type\n        name\n        json\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query BlueprintList($partnerId: ID!, $perPage: Int!, $cursor: String) {\n    partner(id: $partnerId) {\n      id\n      blueprints(options: { perPage: $perPage, cursor: $cursor, orderBy: { name: \"asc\" } }) {\n        total\n        nextCursor\n        previousCursor\n        items {\n          id\n          name\n          type\n          createdAt\n          updatedAt\n          assignments {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query BlueprintList($partnerId: ID!, $perPage: Int!, $cursor: String) {\n    partner(id: $partnerId) {\n      id\n      blueprints(options: { perPage: $perPage, cursor: $cursor, orderBy: { name: \"asc\" } }) {\n        total\n        nextCursor\n        previousCursor\n        items {\n          id\n          name\n          type\n          createdAt\n          updatedAt\n          assignments {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateBlueprint($partnerId: ID!, $blueprint: CreateBlueprintInput!) {\n    createBlueprint(partnerId: $partnerId, blueprint: $blueprint) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateBlueprint($partnerId: ID!, $blueprint: CreateBlueprintInput!) {\n    createBlueprint(partnerId: $partnerId, blueprint: $blueprint) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreatePartnerDocuments($partnerId: ID!, $documents: [DocumentInput!]!) {\n    createPartnerDocuments(partnerId: $partnerId, documents: $documents) {\n      id\n      description\n      filename\n      size\n    }\n  }\n"): (typeof documents)["\n  mutation CreatePartnerDocuments($partnerId: ID!, $documents: [DocumentInput!]!) {\n    createPartnerDocuments(partnerId: $partnerId, documents: $documents) {\n      id\n      description\n      filename\n      size\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateRegisteredIntegrationConnection(\n    $partnerId: ID!\n    $data: RegisteredIntegrationConnectionInput!\n  ) {\n    createRegisteredIntegrationConnection(partnerId: $partnerId, data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateRegisteredIntegrationConnection(\n    $partnerId: ID!\n    $data: RegisteredIntegrationConnectionInput!\n  ) {\n    createRegisteredIntegrationConnection(partnerId: $partnerId, data: $data) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateSite($partnerId: ID!, $data: CreateSiteInput!) {\n    createSite(partnerId: $partnerId, data: $data)\n  }\n"): (typeof documents)["\n  mutation CreateSite($partnerId: ID!, $data: CreateSiteInput!) {\n    createSite(partnerId: $partnerId, data: $data)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeletePartnerDocument($partnerId: ID!, $documentId: ID!) {\n    deletePartnerDocument(partnerId: $partnerId, documentId: $documentId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeletePartnerDocument($partnerId: ID!, $documentId: ID!) {\n    deletePartnerDocument(partnerId: $partnerId, documentId: $documentId) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteRegisteredIntegrationConnection($connectionId: ID!) {\n    deleteRegisteredIntegrationConnection(connectionId: $connectionId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteRegisteredIntegrationConnection($connectionId: ID!) {\n    deleteRegisteredIntegrationConnection(connectionId: $connectionId) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteSite($siteId: ID!) {\n    deleteSite(siteId: $siteId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteSite($siteId: ID!) {\n    deleteSite(siteId: $siteId) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation PartnerDocumentPostUrl($partnerId: ID!, $filename: String!) {\n    getPartnerDocumentPostUrl(partnerId: $partnerId, filename: $filename) {\n      id\n      url\n      postUrl\n    }\n  }\n"): (typeof documents)["\n  mutation PartnerDocumentPostUrl($partnerId: ID!, $filename: String!) {\n    getPartnerDocumentPostUrl(partnerId: $partnerId, filename: $filename) {\n      id\n      url\n      postUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PartnerDocuments($partnerId: ID!, $perPage: Int!, $cursor: String) {\n    getPartnerDocuments(\n      partnerId: $partnerId\n      options: { perPage: $perPage, cursor: $cursor, orderBy: { updated_at: \"ASC\" } }\n    ) {\n      items {\n        id\n        partnerId\n        filename\n        description\n        updatedAt\n      }\n      nextCursor\n      previousCursor\n      total\n    }\n  }\n"): (typeof documents)["\n  query PartnerDocuments($partnerId: ID!, $perPage: Int!, $cursor: String) {\n    getPartnerDocuments(\n      partnerId: $partnerId\n      options: { perPage: $perPage, cursor: $cursor, orderBy: { updated_at: \"ASC\" } }\n    ) {\n      items {\n        id\n        partnerId\n        filename\n        description\n        updatedAt\n      }\n      nextCursor\n      previousCursor\n      total\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PartnerData($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      name\n      isBillable\n      adminDomain\n      adminInactivityTimeout\n      timezone\n    }\n  }\n"): (typeof documents)["\n  query PartnerData($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      name\n      isBillable\n      adminDomain\n      adminInactivityTimeout\n      timezone\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PartnersSelect($criteria:  [SearchCriteria!]) {\n    partners(\n      criteria: $criteria\n    ) {\n      items {\n        value: id\n        label: name\n      }\n    }\n  }\n"): (typeof documents)["\n  query PartnersSelect($criteria:  [SearchCriteria!]) {\n    partners(\n      criteria: $criteria\n    ) {\n      items {\n        value: id\n        label: name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RegisterSiteAuth0Tenant($siteId: ID!, $data: SiteAuth0TenantInput!) {\n    registerSiteAuth0Tenant(siteId: $siteId, data: $data)\n  }\n"): (typeof documents)["\n  mutation RegisterSiteAuth0Tenant($siteId: ID!, $data: SiteAuth0TenantInput!) {\n    registerSiteAuth0Tenant(siteId: $siteId, data: $data)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query RegisteredIntegrationConnection($partnerId: ID!, $registeredIntegrationConnectionId: ID!) {\n    partner(id: $partnerId) {\n      id\n      registeredIntegrationConnection(id: $registeredIntegrationConnectionId) {\n        id\n        uuid\n        connectionVersion\n        partnerId\n        sagaName\n        pathSlug\n        securityType\n        accessToken\n        accessSecret\n        signatureHeader\n        signatureSigningKey\n        basicUsername\n        basicPassword\n        createdAt\n        updatedAt\n        jwksUrl\n        activated\n        externalToken\n        externalTokenKey\n        integrationType\n        integrationAction\n      }\n    }\n  }\n"): (typeof documents)["\n  query RegisteredIntegrationConnection($partnerId: ID!, $registeredIntegrationConnectionId: ID!) {\n    partner(id: $partnerId) {\n      id\n      registeredIntegrationConnection(id: $registeredIntegrationConnectionId) {\n        id\n        uuid\n        connectionVersion\n        partnerId\n        sagaName\n        pathSlug\n        securityType\n        accessToken\n        accessSecret\n        signatureHeader\n        signatureSigningKey\n        basicUsername\n        basicPassword\n        createdAt\n        updatedAt\n        jwksUrl\n        activated\n        externalToken\n        externalTokenKey\n        integrationType\n        integrationAction\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query RegisteredIntegrationConnections($partnerId: ID!, $perPage: Int!) {\n    partner(id: $partnerId) {\n      id\n      registeredIntegrationConnections(\n        options: { perPage: $perPage, orderBy: { path_slug: \"asc\" } }\n      ) {\n        total\n        items {\n          id\n          uuid\n          connectionVersion\n          partnerId\n          sagaName\n          pathSlug\n          securityType\n          accessToken\n          accessSecret\n          signatureHeader\n          signatureSigningKey\n          basicUsername\n          basicPassword\n          createdAt\n          updatedAt\n          jwksUrl\n          activated\n          externalToken\n          externalTokenKey\n          integrationType\n          integrationAction\n        }\n        nextCursor\n        previousCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query RegisteredIntegrationConnections($partnerId: ID!, $perPage: Int!) {\n    partner(id: $partnerId) {\n      id\n      registeredIntegrationConnections(\n        options: { perPage: $perPage, orderBy: { path_slug: \"asc\" } }\n      ) {\n        total\n        items {\n          id\n          uuid\n          connectionVersion\n          partnerId\n          sagaName\n          pathSlug\n          securityType\n          accessToken\n          accessSecret\n          signatureHeader\n          signatureSigningKey\n          basicUsername\n          basicPassword\n          createdAt\n          updatedAt\n          jwksUrl\n          activated\n          externalToken\n          externalTokenKey\n          integrationType\n          integrationAction\n        }\n        nextCursor\n        previousCursor\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetBlueprint($blueprintId: ID!, $blueprint: UpdateBlueprintInput!) {\n    saveBlueprint(blueprintId: $blueprintId, blueprint: $blueprint) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation SetBlueprint($blueprintId: ID!, $blueprint: UpdateBlueprintInput!) {\n    saveBlueprint(blueprintId: $blueprintId, blueprint: $blueprint) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SiteData($siteId: ID!) {\n    site(id: $siteId) {\n      ...SiteFragment\n      blueprint {\n        id\n      }\n      applicationBlueprint {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query SiteData($siteId: ID!) {\n    site(id: $siteId) {\n      ...SiteFragment\n      blueprint {\n        id\n      }\n      applicationBlueprint {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PartnerSites($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      sites(options: { orderBy: { name: \"asc\" } }) {\n        items {\n          value: id\n          label: name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query PartnerSites($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      sites(options: { orderBy: { name: \"asc\" } }) {\n        items {\n          value: id\n          label: name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UnregisterSiteAuth0Tenant($siteId: ID!) {\n    unregisterSiteAuth0Tenant(siteId: $siteId)\n  }\n"): (typeof documents)["\n  mutation UnregisterSiteAuth0Tenant($siteId: ID!) {\n    unregisterSiteAuth0Tenant(siteId: $siteId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateRegisteredIntegrationConnection(\n    $connectionId: ID!\n    $data: RegisteredIntegrationConnectionInput!\n  ) {\n    updateRegisteredIntegrationConnection(connectionId: $connectionId, data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateRegisteredIntegrationConnection(\n    $connectionId: ID!\n    $data: RegisteredIntegrationConnectionInput!\n  ) {\n    updateRegisteredIntegrationConnection(connectionId: $connectionId, data: $data) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateSite($siteId: ID!, $data: UpdateSiteInput!) {\n    updateSite(siteId: $siteId, data: $data)\n  }\n"): (typeof documents)["\n  mutation UpdateSite($siteId: ID!, $data: UpdateSiteInput!) {\n    updateSite(siteId: $siteId, data: $data)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateAgentLandingPage($siteId: ID!) {\n    createAgentLandingPage(siteId: $siteId) {\n      ...LandingPageFragment\n    }\n  }\n"): (typeof documents)["\n  mutation CreateAgentLandingPage($siteId: ID!) {\n    createAgentLandingPage(siteId: $siteId) {\n      ...LandingPageFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ApplicationFileStatusFragment on ApplicationFileStatus {\n    id\n    name\n    color\n    default\n    index\n    createdAt\n    updatedAt\n  }\n"): (typeof documents)["\n  fragment ApplicationFileStatusFragment on ApplicationFileStatus {\n    id\n    name\n    color\n    default\n    index\n    createdAt\n    updatedAt\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ApplicationStatus($partnerId: ID!, $statusId: ID!) {\n    partner(id: $partnerId) {\n      id\n      applicationFileStatus(id: $statusId) {\n        ...ApplicationFileStatusFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query ApplicationStatus($partnerId: ID!, $statusId: ID!) {\n    partner(id: $partnerId) {\n      id\n      applicationFileStatus(id: $statusId) {\n        ...ApplicationFileStatusFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ApplicationStatuses($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      applicationFileStatuses {\n        ...ApplicationFileStatusFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query ApplicationStatuses($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      applicationFileStatuses {\n        ...ApplicationFileStatusFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateApplicationStatus($partnerId: ID!, $data: ApplicationFileStatusInput!) {\n    createApplicationFileStatus(partnerId: $partnerId, data: $data) {\n      ...ApplicationFileStatusFragment\n    }\n  }\n"): (typeof documents)["\n  mutation CreateApplicationStatus($partnerId: ID!, $data: ApplicationFileStatusInput!) {\n    createApplicationFileStatus(partnerId: $partnerId, data: $data) {\n      ...ApplicationFileStatusFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteApplicationStatus($statusId: ID!) {\n    deleteApplicationFileStatus(statusId: $statusId) {\n      ...ApplicationFileStatusFragment\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteApplicationStatus($statusId: ID!) {\n    deleteApplicationFileStatus(statusId: $statusId) {\n      ...ApplicationFileStatusFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation MoveApplicationStatusDown($statusId: ID!) {\n    moveApplicationFileStatusDown(statusId: $statusId) {\n      ...ApplicationFileStatusFragment\n    }\n  }\n"): (typeof documents)["\n  mutation MoveApplicationStatusDown($statusId: ID!) {\n    moveApplicationFileStatusDown(statusId: $statusId) {\n      ...ApplicationFileStatusFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation MoveApplicationStatusUp($statusId: ID!) {\n    moveApplicationFileStatusUp(statusId: $statusId) {\n      ...ApplicationFileStatusFragment\n    }\n  }\n"): (typeof documents)["\n  mutation MoveApplicationStatusUp($statusId: ID!) {\n    moveApplicationFileStatusUp(statusId: $statusId) {\n      ...ApplicationFileStatusFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetDefaultApplicationStatus($statusId: ID!) {\n    setDefaultApplicationFileStatus(statusId: $statusId) {\n      ...ApplicationFileStatusFragment\n    }\n  }\n"): (typeof documents)["\n  mutation SetDefaultApplicationStatus($statusId: ID!) {\n    setDefaultApplicationFileStatus(statusId: $statusId) {\n      ...ApplicationFileStatusFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateApplicationStatus($statusId: ID!, $data: ApplicationFileStatusInput!) {\n    updateApplicationFileStatus(statusId: $statusId, data: $data) {\n      ...ApplicationFileStatusFragment\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateApplicationStatus($statusId: ID!, $data: ApplicationFileStatusInput!) {\n    updateApplicationFileStatus(statusId: $statusId, data: $data) {\n      ...ApplicationFileStatusFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment AuthRoleFragment on AuthRole {\n    id\n    name\n    description\n    partnerId\n    createdAt\n    updatedAt\n  }\n"): (typeof documents)["\n  fragment AuthRoleFragment on AuthRole {\n    id\n    name\n    description\n    partnerId\n    createdAt\n    updatedAt\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PartnerAuthRolesList($partnerId: ID!, $perPage: Int!, $cursor: String, $orderBy: JSON) {\n    partner(id: $partnerId) {\n      id\n      authRoles(options: { perPage: $perPage, cursor: $cursor, orderBy: $orderBy }) {\n        items {\n          ...AuthRoleFragment\n        }\n        nextCursor\n        previousCursor\n        total\n      }\n    }\n  }\n"): (typeof documents)["\n  query PartnerAuthRolesList($partnerId: ID!, $perPage: Int!, $cursor: String, $orderBy: JSON) {\n    partner(id: $partnerId) {\n      id\n      authRoles(options: { perPage: $perPage, cursor: $cursor, orderBy: $orderBy }) {\n        items {\n          ...AuthRoleFragment\n        }\n        nextCursor\n        previousCursor\n        total\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PermissionsList {\n    permissions {\n      id\n      resourceName\n      actionName\n    }\n  }\n"): (typeof documents)["\n  query PermissionsList {\n    permissions {\n      id\n      resourceName\n      actionName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AuthRoleData($partnerId: ID!, $authRoleId: ID!) {\n    partner(id: $partnerId) {\n      id\n      authRole(id: $authRoleId) {\n        ...AuthRoleFragment\n        permissions {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query AuthRoleData($partnerId: ID!, $authRoleId: ID!) {\n    partner(id: $partnerId) {\n      id\n      authRole(id: $authRoleId) {\n        ...AuthRoleFragment\n        permissions {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateAuthRole($partnerId: ID!, $data: AuthRoleInput!) {\n    createAuthRole(partnerId: $partnerId, data: $data) {\n      ...AuthRoleFragment\n    }\n  }\n"): (typeof documents)["\n  mutation CreateAuthRole($partnerId: ID!, $data: AuthRoleInput!) {\n    createAuthRole(partnerId: $partnerId, data: $data) {\n      ...AuthRoleFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateAuthRole($authRoleId: ID!, $data: AuthRoleInput!) {\n    updateAuthRole(authRoleId: $authRoleId, data: $data) {\n      ...AuthRoleFragment\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateAuthRole($authRoleId: ID!, $data: AuthRoleInput!) {\n    updateAuthRole(authRoleId: $authRoleId, data: $data) {\n      ...AuthRoleFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteAuthRole($authRoleId: ID!) {\n    deleteAuthRole(authRoleId: $authRoleId) {\n      ...AuthRoleFragment\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteAuthRole($authRoleId: ID!) {\n    deleteAuthRole(authRoleId: $authRoleId) {\n      ...AuthRoleFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AnswersDocumentation($verticalName: VerticalType) {\n    answersDocumentation(verticalName: $verticalName)\n  }\n"): (typeof documents)["\n  query AnswersDocumentation($verticalName: VerticalType) {\n    answersDocumentation(verticalName: $verticalName)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteBlueprint($blueprintId: ID!) {\n    deleteBlueprint(blueprintId: $blueprintId)\n  }\n"): (typeof documents)["\n  mutation DeleteBlueprint($blueprintId: ID!) {\n    deleteBlueprint(blueprintId: $blueprintId)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CustomFieldFragment on CustomField {\n    id\n    name\n    typeName\n    description\n    createdAt\n    updatedAt\n    common\n  }\n"): (typeof documents)["\n  fragment CustomFieldFragment on CustomField {\n    id\n    name\n    typeName\n    description\n    createdAt\n    updatedAt\n    common\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PartnerCustomFieldsList(\n    $partnerId: ID!\n    $vertical: VerticalType!\n    $perPage: Int!\n    $cursor: String\n  ) {\n    partner(id: $partnerId) {\n      id\n      customFields(\n        vertical: $vertical\n        options: { perPage: $perPage, cursor: $cursor, orderBy: { name: \"asc\" } }\n      ) {\n        total\n        previousCursor\n        nextCursor\n        items {\n          ...CustomFieldFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query PartnerCustomFieldsList(\n    $partnerId: ID!\n    $vertical: VerticalType!\n    $perPage: Int!\n    $cursor: String\n  ) {\n    partner(id: $partnerId) {\n      id\n      customFields(\n        vertical: $vertical\n        options: { perPage: $perPage, cursor: $cursor, orderBy: { name: \"asc\" } }\n      ) {\n        total\n        previousCursor\n        nextCursor\n        items {\n          ...CustomFieldFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CustomFieldData($partnerId: ID!, $vertical: VerticalType!, $fieldId: ID!) {\n    partner(id: $partnerId) {\n      customField(id: $fieldId, vertical: $vertical) {\n        ...CustomFieldFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query CustomFieldData($partnerId: ID!, $vertical: VerticalType!, $fieldId: ID!) {\n    partner(id: $partnerId) {\n      customField(id: $fieldId, vertical: $vertical) {\n        ...CustomFieldFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateCustomField(\n    $partnerId: ID!\n    $vertical: VerticalType!\n    $data: CreateCustomFieldInput!\n  ) {\n    createCustomField(partnerId: $partnerId, vertical: $vertical, data: $data) {\n      ...CustomFieldFragment\n    }\n  }\n"): (typeof documents)["\n  mutation CreateCustomField(\n    $partnerId: ID!\n    $vertical: VerticalType!\n    $data: CreateCustomFieldInput!\n  ) {\n    createCustomField(partnerId: $partnerId, vertical: $vertical, data: $data) {\n      ...CustomFieldFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateCustomField(\n    $fieldId: ID!\n    $vertical: VerticalType!\n    $data: UpdateCustomFieldInput!\n  ) {\n    updateCustomField(fieldId: $fieldId, vertical: $vertical, data: $data) {\n      ...CustomFieldFragment\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateCustomField(\n    $fieldId: ID!\n    $vertical: VerticalType!\n    $data: UpdateCustomFieldInput!\n  ) {\n    updateCustomField(fieldId: $fieldId, vertical: $vertical, data: $data) {\n      ...CustomFieldFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteCustomField($fieldId: ID!, $vertical: VerticalType!) {\n    deleteCustomField(fieldId: $fieldId, vertical: $vertical) {\n      ...CustomFieldFragment\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteCustomField($fieldId: ID!, $vertical: VerticalType!) {\n    deleteCustomField(fieldId: $fieldId, vertical: $vertical) {\n      ...CustomFieldFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SiteLandingPagesList($siteId: ID!, $perPage: Int!, $cursor: String) {\n    site(id: $siteId) {\n      id\n      landingPages(options: { perPage: $perPage, cursor: $cursor, orderBy: { title: \"asc\" } }) {\n        total\n        nextCursor\n        previousCursor\n        items {\n          ...LandingPageFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SiteLandingPagesList($siteId: ID!, $perPage: Int!, $cursor: String) {\n    site(id: $siteId) {\n      id\n      landingPages(options: { perPage: $perPage, cursor: $cursor, orderBy: { title: \"asc\" } }) {\n        total\n        nextCursor\n        previousCursor\n        items {\n          ...LandingPageFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ApplicationMaintenanceMode {\n    admin {\n      maintenanceMode\n    }\n  }\n"): (typeof documents)["\n  query ApplicationMaintenanceMode {\n    admin {\n      maintenanceMode\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateAppMaintenanceMode($mode: Boolean!) {\n    admin {\n      updateApplicationMaintenanceMode(mode: $mode)\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateAppMaintenanceMode($mode: Boolean!) {\n    admin {\n      updateApplicationMaintenanceMode(mode: $mode)\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PartnerVerticals($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      verticals\n    }\n  }\n"): (typeof documents)["\n  query PartnerVerticals($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      verticals\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SequentialLenderMilestones($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      sequentialLenderMilestones\n    }\n  }\n"): (typeof documents)["\n  query SequentialLenderMilestones($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      sequentialLenderMilestones\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query LenderMilestones($partnerId: ID!, $vertical: VerticalType) {\n    partner(id: $partnerId) {\n      id\n      lenderMilestones(vertical: $vertical) {\n        id\n        name\n        position\n        externalMilestoneKey\n        externalMilestoneKeyId\n        updatedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  query LenderMilestones($partnerId: ID!, $vertical: VerticalType) {\n    partner(id: $partnerId) {\n      id\n      lenderMilestones(vertical: $vertical) {\n        id\n        name\n        position\n        externalMilestoneKey\n        externalMilestoneKeyId\n        updatedAt\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ExternalMilestoneKeys($partnerId: ID!, $vertical: VerticalType, $ignore: Boolean) {\n    partner(id: $partnerId) {\n      id\n      externalMilestoneKeys(vertical: $vertical, ignore: $ignore) {\n        id\n        name\n        ignore\n        updatedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  query ExternalMilestoneKeys($partnerId: ID!, $vertical: VerticalType, $ignore: Boolean) {\n    partner(id: $partnerId) {\n      id\n      externalMilestoneKeys(vertical: $vertical, ignore: $ignore) {\n        id\n        name\n        ignore\n        updatedAt\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateLenderMilestones(\n    $partnerId: ID!\n    $vertical: VerticalType!\n    $data: UpdateLenderMilestoneInput!\n  ) {\n    updateLenderMilestones(partnerId: $partnerId, vertical: $vertical, data: $data) {\n      id\n      tempId\n      name\n      updatedAt\n      position\n      errors\n      deleted\n      externalMilestoneKey\n      externalMilestoneKeyId\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateLenderMilestones(\n    $partnerId: ID!\n    $vertical: VerticalType!\n    $data: UpdateLenderMilestoneInput!\n  ) {\n    updateLenderMilestones(partnerId: $partnerId, vertical: $vertical, data: $data) {\n      id\n      tempId\n      name\n      updatedAt\n      position\n      errors\n      deleted\n      externalMilestoneKey\n      externalMilestoneKeyId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddExternalMilestoneKey(\n    $partnerId: ID!\n    $vertical: VerticalType!\n    $name: String!\n    $ignore: Boolean!\n  ) {\n    addExternalMilestoneKey(\n      partnerId: $partnerId\n      vertical: $vertical\n      name: $name\n      ignore: $ignore\n    )\n  }\n"): (typeof documents)["\n  mutation AddExternalMilestoneKey(\n    $partnerId: ID!\n    $vertical: VerticalType!\n    $name: String!\n    $ignore: Boolean!\n  ) {\n    addExternalMilestoneKey(\n      partnerId: $partnerId\n      vertical: $vertical\n      name: $name\n      ignore: $ignore\n    )\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation EditExternalMilestoneKey($id: ID!, $name: String!, $ignore: Boolean!) {\n    editExternalMilestoneKey(id: $id, name: $name, ignore: $ignore)\n  }\n"): (typeof documents)["\n  mutation EditExternalMilestoneKey($id: ID!, $name: String!, $ignore: Boolean!) {\n    editExternalMilestoneKey(id: $id, name: $name, ignore: $ignore)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteExternalMilestoneKey($id: ID!) {\n    deleteExternalMilestoneKey(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteExternalMilestoneKey($id: ID!) {\n    deleteExternalMilestoneKey(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetSequentialLenderMilestones($partnerId: ID!, $sequential: Boolean!) {\n    setSequentialLenderMilestones(partnerId: $partnerId, sequential: $sequential)\n  }\n"): (typeof documents)["\n  mutation SetSequentialLenderMilestones($partnerId: ID!, $sequential: Boolean!) {\n    setSequentialLenderMilestones(partnerId: $partnerId, sequential: $sequential)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PartnerNotificationProfilesList($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      notificationProfiles {\n        ...NotificationProfileFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query PartnerNotificationProfilesList($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      notificationProfiles {\n        ...NotificationProfileFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PartnerFragment on Partner {\n    id\n    name\n    isBillable\n    adminDomain\n  }\n"): (typeof documents)["\n  fragment PartnerFragment on Partner {\n    id\n    name\n    isBillable\n    adminDomain\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PartnersList($page: Int!, $perPage: Int!, $criteria: [SearchCriteria!], $orderBy: JSON) {\n    partners(criteria: $criteria, options: { page: $page, perPage: $perPage, orderBy: $orderBy }) {\n      itemsTotal\n      itemsCount\n      pageNumber\n      pageCount\n      items {\n        ...PartnerFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query PartnersList($page: Int!, $perPage: Int!, $criteria: [SearchCriteria!], $orderBy: JSON) {\n    partners(criteria: $criteria, options: { page: $page, perPage: $perPage, orderBy: $orderBy }) {\n      itemsTotal\n      itemsCount\n      pageNumber\n      pageCount\n      items {\n        ...PartnerFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query DefaultSmartviewConfig($partnerId: ID!, $model: SmartviewModelType!) {\n    partner(id: $partnerId) {\n      id\n      smartview(model: $model) {\n        id\n        config {\n          columns\n          searchFields\n          exportFields\n        }\n        isSystem\n        rawConfig\n      }\n    }\n  }\n"): (typeof documents)["\n  query DefaultSmartviewConfig($partnerId: ID!, $model: SmartviewModelType!) {\n    partner(id: $partnerId) {\n      id\n      smartview(model: $model) {\n        id\n        config {\n          columns\n          searchFields\n          exportFields\n        }\n        isSystem\n        rawConfig\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OverrideDefaultSmartview(\n    $partnerId: ID!\n    $model: SmartviewModelType!\n    $config: DefaultSmartviewConfigInput!\n  ) {\n    overrideDefaultSmartview(partnerId: $partnerId, model: $model, config: $config)\n  }\n"): (typeof documents)["\n  mutation OverrideDefaultSmartview(\n    $partnerId: ID!\n    $model: SmartviewModelType!\n    $config: DefaultSmartviewConfigInput!\n  ) {\n    overrideDefaultSmartview(partnerId: $partnerId, model: $model, config: $config)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RevertDefaultSmartview($partnerId: ID!, $model: SmartviewModelType!) {\n    revertDefaultSmartview(partnerId: $partnerId, model: $model)\n  }\n"): (typeof documents)["\n  mutation RevertDefaultSmartview($partnerId: ID!, $model: SmartviewModelType!) {\n    revertDefaultSmartview(partnerId: $partnerId, model: $model)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SmartviewDocumentation($partnerId: ID!, $model: SmartviewModelType!) {\n    partner(id: $partnerId) {\n      id\n      smartviewDocumentation(model: $model) {\n        model\n        dataFields {\n          name\n          title\n          type\n        }\n        exportFields {\n          name\n          title\n          type\n        }\n        searchFields {\n          name\n          title\n          type\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SmartviewDocumentation($partnerId: ID!, $model: SmartviewModelType!) {\n    partner(id: $partnerId) {\n      id\n      smartviewDocumentation(model: $model) {\n        model\n        dataFields {\n          name\n          title\n          type\n        }\n        exportFields {\n          name\n          title\n          type\n        }\n        searchFields {\n          name\n          title\n          type\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetSagaRunnerVersion($partnerId: ID!, $siteId: ID, $version: Int!) {\n    saga {\n      setRunnerVersion(partnerId: $partnerId, siteId: $siteId, version: $version)\n    }\n  }\n"): (typeof documents)["\n  mutation SetSagaRunnerVersion($partnerId: ID!, $siteId: ID, $version: Int!) {\n    saga {\n      setRunnerVersion(partnerId: $partnerId, siteId: $siteId, version: $version)\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteRuleSetSagas($ruleSetId: ID!, $sagaName: String!) {\n    deleteRuleSetSaga(ruleSetId: $ruleSetId, sagaName: $sagaName) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteRuleSetSagas($ruleSetId: ID!, $sagaName: String!) {\n    deleteRuleSetSaga(ruleSetId: $ruleSetId, sagaName: $sagaName) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PartnerSagas($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      ruleSet {\n        id\n        sagas {\n          name\n          custom\n          system\n          arguments {\n            name\n            type\n            required\n            system\n          }\n          codeLines\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query PartnerSagas($partnerId: ID!) {\n    partner(id: $partnerId) {\n      id\n      ruleSet {\n        id\n        sagas {\n          name\n          custom\n          system\n          arguments {\n            name\n            type\n            required\n            system\n          }\n          codeLines\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetSagaRunnerVersion($partnerId: ID!, $siteId: ID) {\n    saga {\n      sagaRunnerVersion(partnerId: $partnerId, siteId: $siteId)\n    }\n  }\n"): (typeof documents)["\n  query GetSagaRunnerVersion($partnerId: ID!, $siteId: ID) {\n    saga {\n      sagaRunnerVersion(partnerId: $partnerId, siteId: $siteId)\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SiteSagas($siteId: ID!) {\n    site(id: $siteId) {\n      id\n      ruleSet {\n        id\n        sagas {\n          name\n          custom\n          system\n          arguments {\n            name\n            type\n            required\n            system\n          }\n          codeLines\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SiteSagas($siteId: ID!) {\n    site(id: $siteId) {\n      id\n      ruleSet {\n        id\n        sagas {\n          name\n          custom\n          system\n          arguments {\n            name\n            type\n            required\n            system\n          }\n          codeLines\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateSaga($ruleSetId: ID!, $data: RuleSetSagaInput!) {\n    saveRuleSetSaga(ruleSetId: $ruleSetId, data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSaga($ruleSetId: ID!, $data: RuleSetSagaInput!) {\n    saveRuleSetSaga(ruleSetId: $ruleSetId, data: $data) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ValidateSaga($saga: ValidateSagaInput!) {\n    saga {\n      validateSaga(saga: $saga) {\n        message\n        severity\n        lineStart\n        lineEnd\n        colStart\n        colEnd\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation ValidateSaga($saga: ValidateSagaInput!) {\n    saga {\n      validateSaga(saga: $saga) {\n        message\n        severity\n        lineStart\n        lineEnd\n        colStart\n        colEnd\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CancelServiceJob($id: ID!) {\n    cancelServiceJob(id: $id)\n  }\n"): (typeof documents)["\n  mutation CancelServiceJob($id: ID!) {\n    cancelServiceJob(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CancelRepeatableServiceJob($id: ID!) {\n    cancelRepeatableServiceJob(id: $id)\n  }\n"): (typeof documents)["\n  mutation CancelRepeatableServiceJob($id: ID!) {\n    cancelRepeatableServiceJob(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation PauseServiceJob($id: ID!) {\n    pauseServiceJob(id: $id)\n  }\n"): (typeof documents)["\n  mutation PauseServiceJob($id: ID!) {\n    pauseServiceJob(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ResumeServiceJob($id: ID!) {\n    resumeServiceJob(id: $id)\n  }\n"): (typeof documents)["\n  mutation ResumeServiceJob($id: ID!) {\n    resumeServiceJob(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetServiceJobs($page: Int!, $perPage: Int!, $jobType: JobType, $orderBy: JSON) {\n    getServiceJobs(\n      jobType: $jobType\n      options: { page: $page, perPage: $perPage, orderBy: $orderBy }\n    ) {\n      items {\n        id\n        jobId\n        jobStatus\n        jobType\n        partnerId\n        model\n        progress\n        totalBatchCount\n        currentBatchCount\n        startDate\n        repeatableJobKey\n        manuallyPaused\n        manuallyCanceled\n        createdAt\n      }\n      itemsTotal\n      itemsCount\n      pageNumber\n      pageCount\n    }\n  }\n"): (typeof documents)["\n  query GetServiceJobs($page: Int!, $perPage: Int!, $jobType: JobType, $orderBy: JSON) {\n    getServiceJobs(\n      jobType: $jobType\n      options: { page: $page, perPage: $perPage, orderBy: $orderBy }\n    ) {\n      items {\n        id\n        jobId\n        jobStatus\n        jobType\n        partnerId\n        model\n        progress\n        totalBatchCount\n        currentBatchCount\n        startDate\n        repeatableJobKey\n        manuallyPaused\n        manuallyCanceled\n        createdAt\n      }\n      itemsTotal\n      itemsCount\n      pageNumber\n      pageCount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetRepeatableServiceJobs($page: Int!, $perPage: Int!, $orderBy: JSON) {\n    getRepeatableServiceJobs(options: { page: $page, perPage: $perPage, orderBy: $orderBy }) {\n      items {\n        id\n        jobType\n        partnerId\n        model\n        cancellable\n        cronSchedule\n        startDate\n        repeatMillisInterval\n        repeatableJobKey\n        lastRan\n        lastStatus\n        createdAt\n      }\n      itemsTotal\n      itemsCount\n      pageNumber\n      pageCount\n    }\n  }\n"): (typeof documents)["\n  query GetRepeatableServiceJobs($page: Int!, $perPage: Int!, $orderBy: JSON) {\n    getRepeatableServiceJobs(options: { page: $page, perPage: $perPage, orderBy: $orderBy }) {\n      items {\n        id\n        jobType\n        partnerId\n        model\n        cancellable\n        cronSchedule\n        startDate\n        repeatMillisInterval\n        repeatableJobKey\n        lastRan\n        lastStatus\n        createdAt\n      }\n      itemsTotal\n      itemsCount\n      pageNumber\n      pageCount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SiteMilestonesList($siteId: ID!) {\n    site(id: $siteId) {\n      id\n      milestones {\n        id\n        key\n        name\n        position\n        externalSourceKeys\n        externalMilestoneKeys {\n          id\n          name\n        }\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  query SiteMilestonesList($siteId: ID!) {\n    site(id: $siteId) {\n      id\n      milestones {\n        id\n        key\n        name\n        position\n        externalSourceKeys\n        externalMilestoneKeys {\n          id\n          name\n        }\n        createdAt\n        updatedAt\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateSiteMilestones($siteId: ID!, $data: UpdateMilestoneInput!) {\n    updateMilestones(siteId: $siteId, data: $data) {\n      id\n      tempId\n      key\n      name\n      position\n      externalMilestoneKeys {\n        id\n        name\n      }\n      externalSourceKeys\n      createdAt\n      updatedAt\n      errors\n      deleted\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateSiteMilestones($siteId: ID!, $data: UpdateMilestoneInput!) {\n    updateMilestones(siteId: $siteId, data: $data) {\n      id\n      tempId\n      key\n      name\n      position\n      externalMilestoneKeys {\n        id\n        name\n      }\n      externalSourceKeys\n      createdAt\n      updatedAt\n      errors\n      deleted\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SiteNotificationProfilesList($siteId: ID!) {\n    site(id: $siteId) {\n      id\n      notificationProfiles {\n        ...NotificationProfileFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query SiteNotificationProfilesList($siteId: ID!) {\n    site(id: $siteId) {\n      id\n      notificationProfiles {\n        ...NotificationProfileFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PartnerSitesList(\n    $partnerId: ID!\n    $page: Int!\n    $perPage: Int!\n    $criteria: [SearchCriteria!]\n    $orderBy: JSON\n  ) {\n    partner(id: $partnerId) {\n      id\n      sites(criteria: $criteria, options: { page: $page, perPage: $perPage, orderBy: $orderBy }) {\n        itemsTotal\n        itemsCount\n        pageNumber\n        pageCount\n        items {\n          ...SiteFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query PartnerSitesList(\n    $partnerId: ID!\n    $page: Int!\n    $perPage: Int!\n    $criteria: [SearchCriteria!]\n    $orderBy: JSON\n  ) {\n    partner(id: $partnerId) {\n      id\n      sites(criteria: $criteria, options: { page: $page, perPage: $perPage, orderBy: $orderBy }) {\n        itemsTotal\n        itemsCount\n        pageNumber\n        pageCount\n        items {\n          ...SiteFragment\n        }\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;