import { Alert, Input, useFormValue } from '@mortgagehippo/ds';

export interface IFieldKeyEditor {
  name: string;
  keySuffixes?: string[];
}

export const FieldKeyEditor = ({ name, keySuffixes }: IFieldKeyEditor) => {
  const [currentKey] = useFormValue(`${name}.key`);

  const hasSuffixes = keySuffixes && keySuffixes.length > 0;
  const showSuffixes = hasSuffixes && !!currentKey;

  const label = hasSuffixes ? 'Field Key Prefix' : 'Field Key';

  return (
    <>
      <Input.Box name={`${name}.key`} label={label} required />
      {showSuffixes ? (
        <Alert type="info">
          <p>The specified key prefix will be prepended to the following:</p>
          <ul>
            {keySuffixes!.map((suffix) => (
              <li>{`${currentKey}${suffix}`}</li>
            ))}
          </ul>
        </Alert>
      ) : null}
    </>
  );
};
