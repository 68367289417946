import { groupBy, isArray, isEmpty, merge } from 'lodash-es';

import { getTaskNodeKey } from './util';

export type BlueprintErrors = Record<string, string[]>;

const validateBlueprintTasks = (tasks: any[]): BlueprintErrors => {
  const errors: BlueprintErrors = {};

  // maintain index to be able to generate task paths
  const tasksWithIndex = tasks.map((task: any, i) => ({ ...task, index: i }));
  const tasksByKeys = groupBy(tasksWithIndex, (t) => t.key);
  const taskKeys = Object.keys(tasksByKeys);

  taskKeys.forEach((taskKey) => {
    const tasksForKey = tasksByKeys[taskKey]!;

    if (tasksForKey.length <= 1) {
      return;
    }

    // key has more than 1 task
    tasksForKey.forEach((task) => {
      const path = getTaskNodeKey(task.index);
      const message = 'Duplicate task key';

      if (isArray(errors[path])) {
        errors[path]!.push(message);
      } else {
        errors[path] = [message];
      }
    });
  });

  return errors;
};

export const validateBlueprint = (blueprint: any): BlueprintErrors => {
  let errors: BlueprintErrors = {};

  const tasks = blueprint.automated_tasks || [];

  errors = merge(errors, validateBlueprintTasks(tasks));
  // other stuff......

  return errors;
};

export const isValidBlueprint = (blueprint: any) => isEmpty(validateBlueprint(blueprint));
