import {
  borderRadius,
  type IThemeProp,
  palette,
  type PaletteColor,
  shadow,
  spacing,
  Text,
  useFormValue,
} from '@mortgagehippo/ds';
import { get } from 'lodash-es';
import styled, { withTheme } from 'styled-components';

import { Hex } from '../fields/hex';
import { type PaletteGroup, type PaletteLevel } from '../types';
import { type IBasicThemeResourceEditorProps, ThemeResourceEditor } from './theme-resource-editor';

const Row = styled.div`
  display: flex;
  align-items: start;
`;

const Preview = styled.div`
  flex: 0 0 50px;
  height: 50px;
  line-height: 50px;
  min-width: 50px;
  margin-right: ${spacing(1)};
  border-radius: ${borderRadius(1)};
  box-shadow: ${shadow(1)};
  background-color: ${({ color }) => color};
  text-align: center;
`;

const ColoredLabel = styled.label<{ color: PaletteColor }>`
  color: ${({ color }) => palette(color)};
`;

const Editor = styled.div`
  flex: 1;
`;

interface IBasicPaletteResourceEditorProps
  extends Omit<IBasicThemeResourceEditorProps, 'children' | 'label'> {
  group: PaletteGroup;
  level: PaletteLevel;
}

type IPaletteResourceEditorProps = IThemeProp<IBasicPaletteResourceEditorProps>;

export const PaletteResourceEditor = withTheme((props: IPaletteResourceEditorProps) => {
  const { name, storedValue, group, level, theme, ...rest } = props;

  const [formValue] = useFormValue(name);
  const defaultValue = theme ? get(theme, name) : undefined;

  const displayValue = formValue || storedValue || defaultValue;
  const labelId = `label_${name.replace('.', '_')}`;
  const labelColor = (level <= 400 ? `${group}900` : `${group}50`) as PaletteColor;

  return (
    <Row>
      <Preview color={displayValue || 'transparent'}>
        <ColoredLabel id={labelId} color={labelColor}>
          <Text size="xs">{level}</Text>
        </ColoredLabel>
      </Preview>
      <Editor>
        <ThemeResourceEditor name={name} storedValue={storedValue} {...rest}>
          <Hex name={name} aria-labelledby={labelId} />
        </ThemeResourceEditor>
      </Editor>
    </Row>
  );
});
