import { useQuery } from '@mortgagehippo/apollo-hooks';
import { type ISelectOption } from '@mortgagehippo/ds';

import { graphql } from '../../../apollo/gql';

const QCustomizationLanguages = graphql(`
  query CustomizationLanguages {
    customizationLanguages {
      value: id
      label: name
    }
  }
`);

export const useLanguages = (suspend = false): [ISelectOption[] | undefined, boolean] => {
  const [data, loading] = useQuery(QCustomizationLanguages, undefined, { suspend });

  const languages = !loading && data ? data.customizationLanguages : undefined;

  return [languages, loading];
};
