import {
  Button,
  Form,
  type ITableActions,
  type ITableCols,
  notifications,
  spacing,
  SubmitButton,
  Table,
  Text,
  Title,
} from '@mortgagehippo/ds';
import { UnreachableCaseError } from '@mortgagehippo/util';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { CreateSnapdocsSubscriptionForm } from './create-snapdocs-subscription-form';
import { useCreateSnapdocsSubscription } from './hooks/use-create-snapdocs-subscription';
import { useDeleteSnapdocsSubscription } from './hooks/use-delete-snapdocs-subscription';
import {
  type ISnapdocsSubscription,
  useSnapdocsSubscriptions,
} from './hooks/use-snapdocs-subscriptions';

const FormContainer = styled.div`
  padding: ${spacing(5)} ${spacing(6)};
  max-width: 750px;
  margin: auto;
`;

const Scrollable = styled.div`
  overflow-x: auto;
`;

const Buttons = styled.div`
  text-align: right;
  padding: ${spacing(1)} 0;

  ${Button} {
    margin-bottom: 0;
  }
`;

const columns: ITableCols<ISnapdocsSubscription> = [
  {
    title: 'Webhook Url',
    key: 'webhook_url',
    render: ({ webhookUrl }) => webhookUrl,
  },
  {
    title: 'Description',
    key: 'description',
    render: ({ description }) => description,
  },
  {
    title: 'Events',
    key: 'events',
    render: ({ events }) => events.map((event) => <p>{event}</p>),
  },
];

enum SnapdoscSubscriptionsEditorsActionType {
  DELETE = 'delete',
}

interface ISnapdocsSubscriptionsEditorProps {
  partnerId: string;
  siteId?: string;
}

export const SnapdocsSubscriptionsEditor = (props: ISnapdocsSubscriptionsEditorProps) => {
  const { partnerId, siteId } = props;

  const [subscriptions, loading, refetch] = useSnapdocsSubscriptions(partnerId, siteId);

  const createSubscription = useCreateSnapdocsSubscription();
  const deleteSubscription = useDeleteSnapdocsSubscription();

  const handleAction = useCallback(
    async (actionKey: string, record: ISnapdocsSubscription) => {
      const actionType = actionKey as SnapdoscSubscriptionsEditorsActionType;
      switch (actionType) {
        case SnapdoscSubscriptionsEditorsActionType.DELETE:
          try {
            const url = new URL(record.webhookUrl);
            const pathSlug = url.pathname.split('/').pop();
            if (pathSlug) {
              await deleteSubscription(record.id, pathSlug, partnerId, siteId);
            }
          } catch (e) {
            // webhook url will always be valid from snapdocs so this should never happen
            console.error('Error deleting subscription', e);
          }
          refetch();
          break;
        default: {
          throw new UnreachableCaseError(actionType);
        }
      }
    },
    [deleteSubscription, partnerId, siteId, refetch]
  );

  const [creating, setCreating] = useState(false);

  const handleAdd = useCallback(() => {
    setCreating(true);
  }, []);

  const handleAddClose = useCallback(() => {
    setCreating(false);
  }, []);

  const handleSave = useCallback(
    async (data: any) => {
      try {
        const response = await createSubscription({
          partnerId,
          siteId,
          description: data.description,
          events: data.events,
        });

        if (response) {
          notifications.success({ message: 'Your changes have been saved.' });
          refetch();
          handleAddClose();
        } else {
          notifications.error({
            message: `Error creating subscription`,
          });
        }
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [createSubscription, partnerId, siteId, refetch, handleAddClose]
  );

  const actions: ITableActions<ISnapdocsSubscription> = useMemo(
    () => [
      {
        key: 'options',
        label: 'Options',
        buttonProps: {
          icon: 'menu-dots',
          iconSize: 'md',
        },
        onGroupAction: handleAction,
        actions: [
          {
            key: SnapdoscSubscriptionsEditorsActionType.DELETE,
            label: 'Delete',
            iconProps: {
              name: 'delete',
            },
            confirm: {
              title: 'Warning',
              explanation:
                'Are you sure you want to delete this subscription? This action cannot be undone.',
              type: 'warning',
            },
          },
        ],
      },
    ],
    [handleAction]
  );

  const topActions: ITableActions<ISnapdocsSubscription> = useMemo(
    () => [
      {
        key: 'add-subscription',
        label: 'Add Subscription',
        buttonProps: {
          icon: 'plus',
        },
        onAction: handleAdd,
      },
    ],
    [handleAdd]
  );

  return (
    (!creating && (
      <Scrollable>
        <Table<ISnapdocsSubscription>
          caption="Subscriptions"
          data={subscriptions}
          cols={columns}
          loading={loading}
          rowActions={actions}
          topActions={topActions}
          emptyText="No subscriptions."
          rowKey={(item) => item.id}
          size="sm"
          topContent={
            <Text variant="warning" size="sm">
              Note: If subscriptions are created at the partner level, email lookup flow will not
              work. <br /> (eg. LO manually creates closing in Snapdocs, so the only way we have to
              match is the email address).
            </Text>
          }
        />
      </Scrollable>
    )) || (
      <FormContainer>
        <Form onSubmit={handleSave}>
          <Title level={4}>Create New Subscription</Title>
          <CreateSnapdocsSubscriptionForm />
          <Buttons>
            <Button importance="tertiary" onClick={handleAddClose}>
              Cancel
            </Button>
            <SubmitButton importance="primary">Create subscription</SubmitButton>
          </Buttons>
        </Form>
      </FormContainer>
    )
  );
};
