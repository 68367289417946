import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { type CredentialsType } from '../../../apollo/graphql';
import { MDeleteCredentialMutation } from '../queries';

export interface IDeleteCredential {
  partnerId: string;
  siteId?: string;
  credentialType: CredentialsType;
}

export const useDeleteCredential = () => {
  const deleteCredential = useMutation(MDeleteCredentialMutation);

  return useCallback(
    async ({ partnerId, siteId, credentialType }: IDeleteCredential) => {
      await deleteCredential(
        {
          partnerId,
          siteId,
          credentialType,
        },
        { fetchPolicy: 'no-cache' }
      );
    },
    [deleteCredential]
  );
};
