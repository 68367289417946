import { useSagaSubmitHandler } from '@mortgagehippo/tasks';
import { useMemo } from 'react';
import { type RouteComponentProps } from 'react-router';

import { useSite } from '../../hooks/use-site';
import { useUpdateSite } from '../../hooks/use-update-site';
import { Content, Layout } from '../../layouts/main';
import { SiteForm } from './site-form';

export interface IEditSitePageParams {
  partnerId: string;
  siteId: string;
}

export const EditSitePage = (props: RouteComponentProps<IEditSitePageParams>) => {
  const { match } = props;
  const { params } = match;
  const { siteId, partnerId } = params;

  const [site, , refreshSite] = useSite(siteId, 'network-only');
  const updateSite = useUpdateSite();

  const [handleSubmit, running] = useSagaSubmitHandler({
    infoMessageCid: 'update-site-action:info',
    successMessageCid: 'update-site-action:success',
    errorMessageCid: 'update-site-action:error',
    infoMessage: 'Updating site',
    successMessage: 'The site was successfully updated.',
    errorMessage: 'There was an error while updating the site.',
    async onSubmit(values: any, setAsyncId) {
      const nextAsyncId = await updateSite(siteId, values);
      setAsyncId(nextAsyncId);
    },
    async onDone() {
      await Promise.all([refreshSite()]);
    },
  });

  const initialValues = useMemo(() => {
    if (!site) {
      return {};
    }
    return {
      name: site.name,
      domain: site.domain,
      liveSite: site.liveSite,
      blueprintId: site.blueprint.id,
      lenderPortalBlueprintId: site.applicationBlueprint.id,
      authConfiguration: site.authConfiguration,
      timezone: site.timezone,
      tpoSite: site.tpoSite,
    };
  }, [site]);

  return (
    <Layout>
      <Content title="Details" subTitle={site?.name || ''}>
        <SiteForm
          partnerId={partnerId}
          siteId={siteId}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          editMode
          disabled={running}
          onSiteAuth0TenantChange={refreshSite}
        />
      </Content>
    </Layout>
  );
};
