import { graphql } from '../../../apollo/gql';

export const FNotificationProfileFragment = graphql(`
  fragment NotificationProfileFragment on NotificationProfile {
    id
    name
    typeName
    data {
      mediums
    }
    rawData
    parentType
    parentId
    createdAt
    updatedAt
    args
  }
`);
