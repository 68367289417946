import { isPresent } from '@mortgagehippo/util';

export type PathType = 'blueprint' | 'task_group' | 'task' | 'panel' | 'group' | 'field' | 'case';

export const getPathType = (path: string): PathType | undefined => {
  if (!isPresent(path)) {
    return 'blueprint';
  }

  if (path.match(/^json.task_groups.items\[[^\]]+\]$/)) {
    return 'task_group';
  }

  if (path.match(/^json.automated_tasks\[[^\]]+\]$/)) {
    return 'task';
  }

  if (path.match(/\.panels\[[^\]]+\]$/)) {
    return 'panel';
  }

  if (path.match(/\.groups\[[^\]]+\]$/)) {
    return 'group';
  }

  if (path.match(/\.fields\[[^\]]+\]$/)) {
    return 'field';
  }

  if (path.match(/\.cases\[[^\]]+\]$/)) {
    return 'case';
  }
  return undefined;
};

export const getPanelPathFromGroupPath = (path: string): string =>
  path.replace(/\.groups\[[^\]]+\]$/, '');

export const getTaskNodeKey = (index: number) => `json.automated_tasks[${index}]`;
