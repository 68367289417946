import { Numeric, spacing, Spinner } from '@mortgagehippo/ds';
import { useDebouncedCallback } from '@mortgagehippo/util';
import { useCallback, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';

import { PreQualificationExpirationDaysToggle } from './pre-qualification-expiration-days-toggle';
import { usePartnerPreQualificationExpirationDays } from './use-partner-pre-qualification-expiration-days';
import { useSetPreQualificationExpirationDays } from './use-set-pre-qualification-expiration-days';
import { useSitePreQualificationExpirationDays } from './use-site-pre-qualification-expiration-days';

const Wrapper = styled.div`
  max-width: 600px;
`;

const SpinnerContainer = styled.div`
  margin-top: ${spacing(8)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IPreQualificationLetterProps {
  partnerId: string;
  siteId?: string;
}

export const PreQualificationLetter = (props: IPreQualificationLetterProps) => {
  const { partnerId, siteId } = props;

  const isSitePage = !!siteId;

  const [isInheritedFromPartner, setIsInheritedFromPartner] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [partnerExpirationDays, partnerExpirationDaysLoading, refetchPartnerExpirationDays] =
    usePartnerPreQualificationExpirationDays(partnerId);

  const [siteExpirationDays, siteExpirationDaysLoading, refetchSiteExpirationDays] =
    useSitePreQualificationExpirationDays(partnerId, siteId || '', !isSitePage);

  const setPreQualificationExpirationDays = useSetPreQualificationExpirationDays();

  const daysValue = isSitePage
    ? siteExpirationDays || partnerExpirationDays
    : partnerExpirationDays;

  const handleOverrideChange = useCallback(
    async (nextIsInheritedFromPartner: boolean) => {
      setShowSuccess(false);
      setIsInheritedFromPartner(nextIsInheritedFromPartner);

      if (!isSitePage) {
        await refetchPartnerExpirationDays();
        return;
      }

      if (!nextIsInheritedFromPartner && daysValue) {
        setShowSuccess(false);
        await setPreQualificationExpirationDays({
          siteId,
          days: daysValue,
        });
        await refetchSiteExpirationDays();
        setShowSuccess(true);
        return;
      }

      await refetchSiteExpirationDays();
    },
    [
      daysValue,
      isSitePage,
      refetchPartnerExpirationDays,
      refetchSiteExpirationDays,
      setPreQualificationExpirationDays,
      siteId,
    ]
  );

  const handleChange = useDebouncedCallback(
    async (value: number) => {
      if (!value) {
        return;
      }
      setShowSuccess(false);
      await setPreQualificationExpirationDays({
        partnerId: isSitePage ? undefined : partnerId,
        siteId: isSitePage ? siteId : undefined,
        days: value,
      });

      if (isSitePage && !isInheritedFromPartner) {
        await refetchSiteExpirationDays();
        setShowSuccess(true);
        return;
      }

      setShowSuccess(true);
      await refetchPartnerExpirationDays();
    },
    { wait: 500 }
  );

  useLayoutEffect(() => {
    if (!isSitePage) {
      return;
    }
    setIsInheritedFromPartner(!siteExpirationDaysLoading && !siteExpirationDays);
  }, [isSitePage, siteExpirationDays, siteExpirationDaysLoading]);

  const loading = partnerExpirationDaysLoading || siteExpirationDaysLoading;

  if (loading) {
    return (
      <SpinnerContainer>
        <Spinner size="xxl" />
      </SpinnerContainer>
    );
  }

  return (
    <Wrapper>
      {isSitePage ? (
        <PreQualificationExpirationDaysToggle
          siteId={siteId!}
          isOverridden={!!siteExpirationDays}
          onChange={handleOverrideChange}
        />
      ) : null}
      <Numeric.InputBox
        name="expiration_days"
        onChange={handleChange}
        value={daysValue}
        label="Number of days before removing borrower access to Pre-Qualification letter generation"
        description="The number of days the borrower will have access to the Pre-Qualification Letter feature once it has been activated by the LO. After that, the borrower will not be able to generate letters."
        showSuccess={showSuccess}
        disabled={isInheritedFromPartner}
      />
    </Wrapper>
  );
};
