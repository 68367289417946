import { Card, Choice, type IChoiceOption, spacing, Spinner } from '@mortgagehippo/ds';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { usePartnerSequentialLenderMilestones } from './use-partner-sequential-lender-milestones';
import { useSetSequentialLenderMilestones } from './use-set-sequential-lender-milestones';

const sequentialOptions: IChoiceOption[] = [
  {
    label: 'Arbitrary',
    value: 'arbitrary',
  },
  {
    label: 'Sequential',
    value: 'sequential',
  },
];

const SequentialTooltip = (
  <>
    <p>
      <strong>Arbitrary:</strong> the milestones can be completed in any order.
    </p>
    <p>
      <strong>Sequential:</strong> all milestones before the current/last completed one will
      automatically be marked as completed.
    </p>
  </>
);

const Container = styled.div`
  padding: ${spacing(4)};
  max-width: 480px;
  text-align: center;
`;

export interface ILenderMilestonesSettingsProps {
  loading: boolean;
  partnerId: string;
}

export const LenderMilestonesSettings = (props: ILenderMilestonesSettingsProps) => {
  const { partnerId, loading: propsLoading } = props;
  const [milestoneCompletionMode, setMilestoneCompletionMode] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState(false);

  const setSequentialLenderMilestones = useSetSequentialLenderMilestones();

  const [
    sequentialLenderMilestones,
    sequentialLenderMilestonesLoading,
    refetchSequentialLenderMilestones,
  ] = usePartnerSequentialLenderMilestones(partnerId, propsLoading);

  const handleChange = useCallback(
    async (value: string) => {
      setMilestoneCompletionMode(value);
      setSubmitting(true);

      const nextSequential = value === 'sequential';
      await setSequentialLenderMilestones(partnerId, nextSequential);
      await refetchSequentialLenderMilestones();
      setSubmitting(false);
    },
    [partnerId, refetchSequentialLenderMilestones, setSequentialLenderMilestones]
  );

  useEffect(() => {
    if (sequentialLenderMilestonesLoading) {
      return;
    }

    const nextSequential = sequentialLenderMilestones ? 'sequential' : 'arbitrary';
    setMilestoneCompletionMode(nextSequential);
  }, [sequentialLenderMilestones, sequentialLenderMilestonesLoading]);

  const loading = propsLoading || sequentialLenderMilestonesLoading || submitting;

  return (
    <Card raised={2}>
      <Container>
        {loading ? (
          <Spinner size="lg" />
        ) : (
          <Choice.InputBox
            name="sequential"
            options={sequentialOptions}
            onChange={handleChange}
            value={milestoneCompletionMode}
            disabled={submitting}
            label="Milestone completion mode"
            labelTooltip={SequentialTooltip}
            columns={2}
            required
          />
        )}
      </Container>
    </Card>
  );
};
