/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  borderRadiuses,
  createLayout,
  createSlot,
  type ITemplateProps,
  palette,
  shadow,
  spacing,
} from '@mortgagehippo/ds';
import Helmet from 'react-helmet';
import styled, { createGlobalStyle } from 'styled-components';

import { getFaviconSrc } from '../logo';
import { ContentComponent } from './content';
import { DefaultNavigation } from './default-navigation';

const NAV_WIDTH = 260;

interface IActiveSlots {
  isNavActive?: boolean;
  isContentNavActive?: boolean;
  isSidebarActive?: boolean;
}

const GlobalStyles = createGlobalStyle`
  body, html {
    height: auto !important;
    background: ${palette('neutral700')} !important;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const BodyComponent = styled('div')<IActiveSlots>`
  position: relative;
  flex: 1 1 100%;
`;

const NavComponent = styled('nav')<IActiveSlots>`
  position: fixed;
  z-index: 5;
  height: 100%;
  width: ${NAV_WIDTH}px;
  padding: ${spacing(3)} ${spacing(4)} ${spacing(7)};
  box-sizing: border-box;
  background: ${palette('neutral800')};
  overflow: hidden;
  overflow-y: auto;
`;

const Box = styled.div`
  padding-left: ${NAV_WIDTH}px;
  min-height: 100vh;
  display: flex;
  width: 100%;
  border-radius: ${borderRadiuses(1, 1, 0, 0)};
  box-shadow: ${shadow(3, 'intense')};
  overflow: hidden;
  overflow-x: auto;
`;

const SecondaryNavComponent = styled('nav')<IActiveSlots>`
  flex: 0 0 auto;
  flex-basis: 275px;
  background: #f6f8f9;
  overflow: auto;
  z-index: 3;
`;

export const SidebarComponent = styled.aside`
  z-index: 1;
  flex: 0;
  flex-basis: 400px;
  overflow: auto;
  margin-left: ${spacing(2)};
`;

interface IMyTemplateProps extends ITemplateProps {
  pageTitle?: string;
}

const Template = (props: IMyTemplateProps) => {
  const { pageTitle } = props;
  const activeSlots: IActiveSlots = {
    isNavActive: true,
  };

  const faviconSrc = getFaviconSrc();

  return (
    <>
      <Helmet>
        <title>{pageTitle || 'Maxwell Admin'}</title>
        <link rel="icon" type="image/png" href={faviconSrc} />
      </Helmet>
      <GlobalStyles />
      <Container>
        <BodyComponent {...activeSlots}>
          <Nav.Slot {...activeSlots}>
            <DefaultNavigation />
          </Nav.Slot>
          <Box>
            <SecondaryNav.Slot />
            <Content.Slot />
            <Sidebar.Slot />
          </Box>
        </BodyComponent>
      </Container>
    </>
  );
};

export const Layout = createLayout(Template);
export const Nav = createSlot(Layout, NavComponent);
export const SecondaryNav = createSlot(Layout, SecondaryNavComponent);
export const Content = createSlot(Layout, ContentComponent);
export const Sidebar = createSlot(Layout, SidebarComponent);
