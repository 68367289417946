import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo/gql';

export const MDeleteLandingPageMutation = graphql(`
  mutation DeleteLandingPage($landingPageId: ID!) {
    deleteLandingPage(landingPageId: $landingPageId) {
      ...LandingPageFragment
    }
  }
`);

export const useDeleteLandingPage = () => {
  const deleteLandingPage = useMutation(MDeleteLandingPageMutation, undefined);

  return async (landingPageId: string) => {
    await deleteLandingPage({ landingPageId });
  };
};
