import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo/gql';

export const MUpdateSaga = graphql(`
  mutation UpdateSaga($ruleSetId: ID!, $data: RuleSetSagaInput!) {
    saveRuleSetSaga(ruleSetId: $ruleSetId, data: $data) {
      id
    }
  }
`);

export const useUpdateSaga = () => {
  const updateRuleSetSagas = useMutation(MUpdateSaga);
  return async (ruleSetId: string, data: any) => {
    const result = await updateRuleSetSagas({ ruleSetId, data });
    return result.data!.saveRuleSetSaga;
  };
};
