import {
  FieldLabel,
  FormError,
  type FormSubmitHandler,
  Input,
  type ISelectOption,
  ModalForm,
  Select,
} from '@mortgagehippo/ds';
import { sortBy } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { type CredentialsType } from '../../apollo/graphql';
import { CredentialTypeLabels } from './constants';
import { CredentialsEditorFields } from './credentials-editor-fields';

interface ICredentialsCreateModalProps {
  availableCredentialTypes: CredentialsType[];
  editingCredentialType?: CredentialsType;
  initialValues: any;
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
  isOpen: boolean;
  loading: boolean;
}

export const CredentialModal = (props: ICredentialsCreateModalProps) => {
  const {
    availableCredentialTypes,
    editingCredentialType,
    initialValues,
    isOpen,
    onSubmit,
    onRequestClose,
    loading,
  } = props;
  const [selectedCredentialsType, setSelectedCredentialsType] = useState<
    CredentialsType | undefined
  >(undefined);

  useEffect(() => {
    if (isOpen && !editingCredentialType) {
      setSelectedCredentialsType(undefined);
    }
  }, [editingCredentialType, isOpen]);

  const handleSelectChange = useCallback((value: any) => {
    setSelectedCredentialsType(value);
  }, []);

  // add typeName to values from select.input if new credential
  const handleSubmit: FormSubmitHandler = useCallback(
    (values, form, cb) => {
      if (values.typeName) {
        onSubmit(values, form, cb);
        return;
      }
      onSubmit(
        {
          ...values,
          typeName: selectedCredentialsType,
        },
        form,
        cb
      );
    },
    [onSubmit, selectedCredentialsType]
  );

  const selectOptions: ISelectOption[] = useMemo(() => {
    const options = availableCredentialTypes.map((type) => ({
      label: CredentialTypeLabels[type] || type,
      value: type,
    }));

    return sortBy(options, (i) => i.label);
  }, [availableCredentialTypes]);

  const credentialType = editingCredentialType || selectedCredentialsType;

  return (
    <ModalForm
      title={`${editingCredentialType ? 'Edit' : 'Add'} Credential`}
      okButtonDisabled={!credentialType}
      isOpen={isOpen}
      onSubmit={handleSubmit}
      onRequestClose={onRequestClose}
      initialValues={initialValues}
      loading={loading}
    >
      <FormError />
      <div>
        <FieldLabel id="label_type" required>
          Type
        </FieldLabel>
        {editingCredentialType ? (
          <Input name="typeName" initialValue={editingCredentialType} disabled />
        ) : (
          <Select.Input
            name="typeName"
            aria-labelledby="label_type"
            options={selectOptions}
            value={selectedCredentialsType}
            onChange={handleSelectChange}
            placeholder="Choose one..."
          />
        )}
      </div>
      {credentialType ? <CredentialsEditorFields credentialsType={credentialType} /> : null}
    </ModalForm>
  );
};
