import { EmployersTask, type IEmployersTask } from '@mortgagehippo/tasks';
import { cloneDeep, forEach, isArray, merge } from 'lodash-es';
import { type ReactNode, useState } from 'react';

import { TaskPreviewWrapper } from '../task-preview-wrapper';

export interface IEmployersTaskPreviewProps {
  task: IEmployersTask;
  onComplete?: () => Promise<any>;
  renderComplete?: () => ReactNode;
}

function applyChange(change: any, answers: any) {
  const { relationships: previousRelationships = [], ...prevAnswers } = answers;
  const { relationships: changeRelationships = [], ...changes } = change;
  let nextRelationships = [...previousRelationships, ...changeRelationships];
  const nextAnswers = cloneDeep(prevAnswers);

  forEach(changes, (value, key) => {
    // Change scalar fields
    if (!isArray(value)) {
      merge(nextAnswers, { [key]: value });
      return;
    }

    // Change Entities
    const nextEntities = nextAnswers[key] || [];
    value.forEach((changeEntity: any) => {
      const changeEntityId = changeEntity.id && `${changeEntity.id}`;
      const changeEntityGeneratedId = changeEntity.__id && `${changeEntity.__id}`;
      const changeEntityComputedId = changeEntityId || changeEntityGeneratedId;
      const changeEntityDeleted = !!changeEntity.__deleted;

      const previousEntityIndex = nextEntities.findIndex((previousEntity: any) => {
        const previousEntityId = previousEntity.id && `${previousEntity.id}`;
        const previousEntityGeneratedId = previousEntity.__id && `${previousEntity.__id}`;

        if (previousEntityId && previousEntityId === changeEntityId) {
          return true;
        }

        return previousEntityGeneratedId && previousEntityGeneratedId === changeEntityGeneratedId;
      });

      if (changeEntityDeleted) {
        if (previousEntityIndex === -1) return;

        // Delete the entity
        nextEntities[previousEntityIndex].__deleted = true;

        // Delete relationships
        nextRelationships = nextRelationships.map((r: any) => {
          if (r.child_object_id === changeEntityComputedId) {
            return { ...r, __deleted: true };
          }

          // TODO: delete child entities
          if (r.parent_object_id === changeEntityComputedId) {
            return { ...r, __deleted: true };
          }

          return r;
        });
      } else if (previousEntityIndex !== -1) {
        merge(nextEntities[previousEntityIndex], changeEntity);
      } else {
        nextEntities.push(changeEntity);
      }
    });

    nextAnswers[key] = nextEntities;
  });

  return { ...nextAnswers, relationships: nextRelationships };
}

export const EmployersTaskPreview = (props: IEmployersTaskPreviewProps) => {
  const { task, renderComplete, onComplete } = props;
  const [answers, setAnswers] = useState({});

  const handleSave = async (change: any) => {
    const nextAnswers: any = applyChange(change, answers);
    setAnswers(nextAnswers);
  };

  return (
    <TaskPreviewWrapper task={task}>
      {({ nextTask, frame }) => (
        <EmployersTask
          task={nextTask as IEmployersTask}
          applicantId="1"
          applicationFileId="1"
          onComplete={onComplete}
          frame={frame}
          renderComplete={renderComplete}
          answers={answers}
          onSave={handleSave}
          onWorkNumberReport={async () => null}
          voeEnabled={false}
        />
      )}
    </TaskPreviewWrapper>
  );
};
