import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo/gql';

export const MUpdateSiteMaintenanceMode = graphql(`
  mutation UpdateAppMaintenanceMode($mode: Boolean!) {
    admin {
      updateApplicationMaintenanceMode(mode: $mode)
    }
  }
`);

export const useUpdateAppMaintenanceMode = () => {
  const fn = useMutation(MUpdateSiteMaintenanceMode);
  return (mode: boolean) => fn({ mode });
};
