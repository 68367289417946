import { useMutation, useQuery } from '@mortgagehippo/apollo-hooks';
import { notifications } from '@mortgagehippo/ds';
import { useCallback, useMemo } from 'react';

import { type VerticalType } from '../../apollo/graphql';
import { CustomFieldModal } from './custom-field-modal';
import {
  MCreateCustomFieldMutation,
  MUpdateCustomFieldMutation,
  QCustomFieldData,
} from './queries';

interface ICustomFieldModalContainerProps {
  partnerId: string;
  vertical: VerticalType;
  fieldId?: string;
  onRequestClose: () => void;
  onSubmit: () => void;
}

export const CustomFieldModalContainer = (props: ICustomFieldModalContainerProps) => {
  const { partnerId, vertical, fieldId, onSubmit, onRequestClose } = props;
  const editing = fieldId !== undefined;

  const [fieldData, fieldDataLoading] = useQuery(
    QCustomFieldData,
    {
      partnerId,
      vertical,
      fieldId: fieldId!,
    },
    {
      suspend: false,
      skip: !editing,
      fetchPolicy: 'no-cache',
    }
  );

  const addCustomField = useMutation(MCreateCustomFieldMutation);

  const updateCustomField = useMutation(MUpdateCustomFieldMutation);

  const handleSubmit = useCallback(
    async (values: any) => {
      let successMsg = '';

      try {
        if (fieldId) {
          const { description } = values;
          const data = { description };

          await updateCustomField({ vertical, fieldId, data });
          successMsg = 'The custom field was successfully updated.';
        } else {
          const { name, common, description, type_name: typeName } = values;
          const data = { name, common, description, typeName };

          await addCustomField({ partnerId, vertical, data });
          successMsg = 'The custom field was successfully created.';
        }

        notifications.success({ message: successMsg });

        onSubmit();
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [addCustomField, fieldId, onSubmit, partnerId, updateCustomField, vertical]
  );

  const initialValues = useMemo(() => {
    if (!fieldData?.partner?.customField) {
      return {};
    }

    const { name, common, description, typeName } = fieldData.partner.customField;

    return {
      name,
      common,
      description,
      type_name: typeName,
    };
  }, [fieldData]);

  if (fieldDataLoading) {
    return null;
  }

  const title = !editing ? 'Add Custom Field' : 'Edit Custom Field';

  return (
    <CustomFieldModal
      title={title}
      onSubmit={handleSubmit}
      onRequestClose={onRequestClose}
      initialValues={initialValues}
      editing={editing}
    />
  );
};
