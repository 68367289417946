import { Choice, FormSection, Tabs } from '@mortgagehippo/ds';

import { LogicField } from '$components/logic-field';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { ArrayButtonsEditor } from './array-buttons-editor';
import { ArrayItemButtonsEditor } from './array-item-buttons-editor';
import { ArrayItemPreviewEditor } from './array-item-preview-editor';
import { ArrayModeEditor } from './array-mode-editor';
import { ArrayTitleEditor } from './array-title-editor';
import { ArrayValidationEditor } from './array-validation-editor';
import { FieldEditorFrame } from './field-editor-frame';
import { GroupsEditor } from './groups-editor';

export const ArrayEditor = ({ name }: { name: string }) => (
  <FieldEditorFrame name={name} isEntity validations={() => <ArrayValidationEditor name={name} />}>
    {() => [
      <Tabs.Tab id="array" label="Array Options">
        <EditorTabWrapper size="md">
          <FormSection
            title="Array Mode"
            description="Determines the behavior of adding array items. ALWAYS use 'Modal', unless the form is 1 to 4 fields tall. Then it is permissible to use 'Embedded'."
          >
            <ArrayModeEditor name={name} />
          </FormSection>

          <FormSection
            title="Array Item"
            description="This define how the items are going to look in their collapsed state, all values are hippo logic expressions"
          >
            <ArrayItemPreviewEditor name={name} />
          </FormSection>

          <FormSection
            title="Array Item Title"
            description="When adding an element. This is going to be shown as the title of the sub-form"
          >
            <ArrayTitleEditor name={name} />
          </FormSection>

          <FormSection
            title="Array Item Buttons"
            description="This fields represent the label for the buttons on the item form"
          >
            <ArrayItemButtonsEditor name={name} />
          </FormSection>

          <FormSection
            title="Array Buttons"
            description="This fields represent the labels for adding an element to the array"
          >
            <ArrayButtonsEditor name={name} />
          </FormSection>

          <FormSection
            title="Array Sorting"
            description="This fields represent the label for the buttons on the item form"
          >
            <LogicField.Box name={`${name}.sortBy`} label="Sort Expression" />

            <Choice.Box
              name={`${name}.sortDescending`}
              label="Sort Direction"
              options={[
                { label: 'Ascending', value: false },
                { label: 'Descending', value: true },
              ]}
            />
          </FormSection>
        </EditorTabWrapper>
      </Tabs.Tab>,
      <Tabs.Tab id="fields" label="Fields">
        <EditorTabWrapper size="md">
          <GroupsEditor name={`${name}.groups`} />
        </EditorTabWrapper>
      </Tabs.Tab>,
    ]}
  </FieldEditorFrame>
);
