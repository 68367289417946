import {
  fontSize,
  fontWeight,
  Format,
  type ITableActions,
  type ITableCol,
  lineHeight,
  Table,
  useModal,
} from '@mortgagehippo/ds';
import { lowerCase, upperFirst } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { type VerticalType } from '../../../apollo/graphql';
import { MilestoneEditModalContainer } from './milestone-edit-modal-container';
import { type IMilestone } from './queries';
import { useSiteMilestones } from './use-site-milestones';

const TopContent = styled.div`
  max-width: 300px;
  font-size: ${fontSize('xs')};
  line-height: ${lineHeight('xs')};
`;

const VerticalLabel = styled.span`
  font-weight: ${fontWeight('bold')};
`;

const columns: Array<ITableCol<IMilestone>> = [
  {
    title: 'Label',
    key: 'name',
    render: (t) => t.name,
  },
  {
    title: 'Internal Key',
    key: 'key',
    render: ({ key }) => key,
    noWrap: true,
  },
  {
    title: 'External Milestone Keys',
    key: 'externalMilestoneKeys',
    render: ({ externalMilestoneKeys, externalSourceKeys }) => {
      if (externalMilestoneKeys?.length) {
        return (
          <>
            {externalMilestoneKeys.map((externalMilestoneKey) => (
              <div>{externalMilestoneKey.name}</div>
            ))}
          </>
        );
      }

      if (externalSourceKeys?.length) {
        return (
          <>
            {externalSourceKeys.map((externalSourceKey) => (
              <div>{externalSourceKey}</div>
            ))}
          </>
        );
      }

      return <>-</>;
    },
  },
  {
    title: 'Last Updated',
    key: 'updated',
    render: ({ updatedAt }) => <Format.Date format="fromnow" value={updatedAt} />,
  },
];

interface IMilestonesTableProps {
  partnerId: string;
  siteId: string;
  loading: boolean;
  vertical?: VerticalType;
}

export const MilestonesTable = (props: IMilestonesTableProps) => {
  const { partnerId, siteId, vertical, loading: siteDataLoading } = props;
  const [isOpen, openModal, closeModal] = useModal(false);

  const siteVertical = upperFirst(lowerCase(vertical || ''));

  const [{ milestones }, milestonesLoading, refetchMilestones] = useSiteMilestones(
    siteId,
    siteDataLoading
  );

  const handleModalClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleModalSubmit = useCallback(() => {
    closeModal(() => refetchMilestones());
  }, [closeModal, refetchMilestones]);

  const topActions: ITableActions = useMemo(() => {
    const newActions: ITableActions = [
      {
        key: 'edit-milestones',
        label: 'Edit milestones',
        buttonProps: {
          icon: 'edit',
        },
        onAction: (_actionKey) => {
          openModal();
        },
      },
    ];
    return newActions;
  }, [openModal]);

  const topContent = useMemo(() => {
    if (!siteVertical) {
      return null;
    }
    return (
      <TopContent>
        <div>
          Vertical: <VerticalLabel>{siteVertical}</VerticalLabel>
        </div>
      </TopContent>
    );
  }, [siteVertical]);

  const emptyText = `This Site has no Borrower Milestones for vertical ${siteVertical}`;

  const loading = milestonesLoading || !vertical;

  return (
    <>
      <Table<IMilestone>
        caption="Milestones"
        rowKey={(item) => item.id}
        data={milestones}
        cols={columns}
        columnTitleCasing="uppercase"
        size="sm"
        topActions={topActions}
        topContent={topContent}
        loading={loading}
        emptyText={emptyText}
      />
      <MilestoneEditModalContainer
        partnerId={partnerId}
        siteId={siteId}
        isOpen={isOpen}
        vertical={vertical}
        milestones={milestones}
        onRequestClose={handleModalClose}
        onSubmit={handleModalSubmit}
      />
    </>
  );
};
