import { Alert, FormSection, Input, TextArea, YesNo } from '@mortgagehippo/ds';

export const DocusignFields = () => (
  <>
    <FormSection title="Docusign Integration Details">
      <YesNo.Box name="demo_mode" label="Demo Mode" required />
      <Input.Box name="integration_key" label="Integration Key" required />
      <Input.Box name="integration_user_id" label="Integration User ID" required />
      <Input.Box name="integration_secret" label="Integration Secret" required />
      <TextArea.Box name="private_key" label="Private Key" required />
    </FormSection>

    <Alert type="info">
      Before being able to use this integration the partner must grant permission to their docusign
      account, The following fields will automatically fill once the partner has granted permission.
      <br />
      <br />
      share the following url with them to grant permission:
      <pre>https://[lender-portal-domain]/_docusign/consent</pre>
      and make sure that the docusign app is configured with the following return url:
      <pre>https://[lender-portal-domain]/_docusign/consented</pre>
    </Alert>

    <FormSection title="Partners Docusign Account">
      <Input.Box name="account_id" label="Partner Account ID" />
      <Input.Box name="user_id" label="Partner User ID" />
    </FormSection>
  </>
);
