import { Input, YesNo } from '@mortgagehippo/ds';

export const WorkNumberFields = () => (
  <>
    <Input.Box name="submitting_party_name" label="Submitting Party Name" required />
    <Input.Box name="login_account_password" label="Login Account Password" required />
    <Input.Box name="login_account_identifier" label="Login Account Identifier" required />
    <YesNo.Box name="production_mode" label="Production Mode" />
  </>
);
