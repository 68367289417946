import { borderRadiuses, Checkbox, palette, Select, spacing } from '@mortgagehippo/ds';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { LanguageSelect } from './components/language-select';
import { CustomizationsRoutes } from './customizations-routes';
import { useDiscardUnsavedChanges } from './hooks/use-discard-unsaved-changes';

const DEFAULT_LANGUAGE = 'en';
const DEFAULT_PROJECT_ID = 'BORROWER_PORTAL';

interface ICustomizationsEditorProps {
  basePath: string;
  partnerId: number;
  domain?: string;
}

const Row = styled.div`
  display: flex;
  margin: -${spacing(4)} -${spacing(2)} ${spacing(3)};
  padding: ${spacing(2)} ${spacing(1)};
  background: ${palette('neutral200')};
  border-radius: ${borderRadiuses(2, 2, 2, 2)};
  align-items: center;
`;

const Col = styled.div`
  margin: 0 ${spacing(1)};
  flex: 1 1 auto;
  max-width: 200px;

  &:last-child {
    max-width: none;
    text-align: right;
  }
`;

export const CustomizationsEditor = (props: ICustomizationsEditorProps) => {
  const { basePath, partnerId, domain } = props;
  const [language, setLanguage] = useState<string>(DEFAULT_LANGUAGE);
  const [projectId, setProjectId] = useState<string>(DEFAULT_PROJECT_ID);
  const [overriddenOnly, setOverriddenOnly] = useState<boolean>(false);
  const [unsavedChangesCount, setUnsavedChangesCount] = useState(0);
  const [shouldDiscardUnsavedChanges, changesModalDialog] = useDiscardUnsavedChanges();

  const handleProjectIdChange = useCallback(
    async (value: string) => {
      const discardChanges = await shouldDiscardUnsavedChanges(unsavedChangesCount);

      if (!discardChanges) {
        return false;
      }

      setProjectId(value || DEFAULT_PROJECT_ID);

      setUnsavedChangesCount(0);
      return true;
    },
    [shouldDiscardUnsavedChanges, unsavedChangesCount]
  );

  const handleLanguageChange = useCallback(
    async (value: string) => {
      const discardChanges = await shouldDiscardUnsavedChanges(unsavedChangesCount);

      if (!discardChanges) {
        return false;
      }

      setLanguage(value || DEFAULT_PROJECT_ID);

      setUnsavedChangesCount(0);
      return true;
    },
    [shouldDiscardUnsavedChanges, unsavedChangesCount]
  );

  const handleOverridenOnlyChange = useCallback(
    async (value: string) => {
      const discardChanges = await shouldDiscardUnsavedChanges(unsavedChangesCount);

      if (!discardChanges) {
        return false;
      }

      setOverriddenOnly(!!value);

      setUnsavedChangesCount(0);
      return true;
    },
    [shouldDiscardUnsavedChanges, unsavedChangesCount]
  );

  useEffect(() => {
    if (domain) {
      // editing a site - it only has BP customizations
      setProjectId(DEFAULT_PROJECT_ID);
    }
  }, [domain]);

  useEffect(() => {
    setUnsavedChangesCount(0);
  }, [domain, partnerId, language, partnerId, overriddenOnly]);

  return (
    <>
      <Row>
        <Col>
          <Select.Input
            disabled={!!domain}
            name="projectId"
            value={projectId}
            onChange={handleProjectIdChange}
            options={[
              { label: 'Borrower Hub', value: 'BORROWER_PORTAL' },
              { label: 'Lender Hub', value: 'LENDER_PORTAL' },
            ]}
            size="sm"
            aria-label="project"
            compact
          />
        </Col>
        <Col>
          <LanguageSelect
            name="language"
            label="Language"
            value={language}
            onChange={handleLanguageChange}
          />
        </Col>
        <Col>
          <Checkbox.Input
            name="overridden_only"
            size="sm"
            compact
            value={overriddenOnly}
            onChange={handleOverridenOnlyChange}
          >
            Only show locally overridden values
          </Checkbox.Input>
        </Col>
      </Row>
      <CustomizationsRoutes
        basePath={basePath}
        partnerId={partnerId}
        domain={domain}
        language={language}
        projectId={projectId}
        overriddenOnly={overriddenOnly}
        unsavedChangesCount={unsavedChangesCount}
        setUnsavedChangesCount={setUnsavedChangesCount}
      />
      {changesModalDialog}
    </>
  );
};
