import { FormSection, Input, Tabs } from '@mortgagehippo/ds';
import { type IDefaultTaskMessage } from '@mortgagehippo/tasks';
import { type ReactNode } from 'react';

import { EditorField } from '$components/editor-field';
import { LogicFieldJson } from '$components/logic-field';

import { EditorTabWrapper } from './editor-tab-wrapper';
import { JsonPreview } from './json-preview';
import { TaskEditorDetails } from './task-editor-details';
import { TaskMessages } from './task-messages';
import { TaskMetaActions } from './task-meta-actions';
import { TaskPreview } from './task-preview';

export interface ITaskEditorFrameProps {
  name: string;
  panel?: boolean;
  children: () => ReactNode;
  messages?: IDefaultTaskMessage[];
}

export const TaskEditorFrame = ({
  name,
  panel = true,
  messages,
  children,
}: ITaskEditorFrameProps) => (
  <Tabs>
    <Tabs.Tab id="details" label="Details">
      <EditorTabWrapper size="md">
        <TaskEditorDetails name={name} />
      </EditorTabWrapper>
    </Tabs.Tab>
    {panel ? (
      <Tabs.Tab id="panel" label="Panel">
        <EditorTabWrapper size="md">
          <Input.Box name={`${name}.data.panel.title`} label="Panel Title" />
          <EditorField.Box name={`${name}.data.panel.title_tooltip`} label="Panel Title tooltip" />
          <Input.Box name={`${name}.data.panel.description`} label="Panel Explanation" />
          <EditorField.Box
            name={`${name}.data.panel.description_tooltip`}
            label="Panel Explanation tooltip"
          />
          <EditorField.Box
            key={`${name}.data.panel.content`}
            name={`${name}.data.panel.content`}
            label="Content"
            description="Arbitrary content to be added to the top of the panel. You can use variable {{site.name}}"
          />
        </EditorTabWrapper>
      </Tabs.Tab>
    ) : null}
    <Tabs.Tab id="start_page" label="Text">
      <EditorTabWrapper size="md">
        <FormSection title="Start Page" description="You can use variable {{site.name}}">
          <EditorField name={`${name}.meta.description.open`} />
          <Input.Box
            label="Start Page Button"
            placeholder="Continue"
            name={`${name}.meta.description.open_button`}
          />
        </FormSection>

        <FormSection
          title="Disclaimer"
          description="Arbitrary disclaimer to be added to the bottom of all panels. You can use variable {{site.name}}"
        >
          <EditorField name={`${name}.meta.disclaimer`} />
        </FormSection>
      </EditorTabWrapper>
    </Tabs.Tab>
    {children()}
    <Tabs.Tab id="logic" label="Logic">
      <EditorTabWrapper size="md">
        <LogicFieldJson.Box
          key={`${name}.conditions`}
          name={`${name}.meta.conditions`}
          label="Opening Condition"
        />

        <FormSection title="Task On Complete Actions">
          <TaskMetaActions name={`${name}.actions.on_completed`} />
        </FormSection>

        <FormSection title="Task On Open Actions">
          <TaskMetaActions name={`${name}.actions.on_opened`} />
        </FormSection>

        <FormSection title="Task On Locked Actions">
          <TaskMetaActions name={`${name}.actions.on_locked`} />
        </FormSection>
      </EditorTabWrapper>
    </Tabs.Tab>
    {messages?.length ? (
      <Tabs.Tab id="messages" label="Messages">
        <EditorTabWrapper size="md">
          <TaskMessages name={`${name}.meta.messages`} messages={messages} />
        </EditorTabWrapper>
      </Tabs.Tab>
    ) : null}
    <Tabs.Tab id="preview" label="Preview">
      <EditorTabWrapper>
        <TaskPreview />
      </EditorTabWrapper>
    </Tabs.Tab>
    <Tabs.Tab id="json" label="JSON">
      <EditorTabWrapper>
        <JsonPreview />
      </EditorTabWrapper>
    </Tabs.Tab>
  </Tabs>
);
