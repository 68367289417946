import { Button, FieldBox, Input, SimpleFieldAutoArray, spacing, Tabs } from '@mortgagehippo/ds';
import { useCallback } from 'react';
import styled from 'styled-components';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { TaskEditorFrame } from '../task-editor-frame';

const TEMPLATE_ITEM_PRESENT_FIELDS = ['id'];

interface IHelloSignTaskTemplate {
  id: string;
  name?: string;
}

const TemplateItemRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${spacing(3)};

  &:first-child {
    margin-top: 0;
  }
`;

const TemplateItemCol = styled.div<{ flex?: number }>`
  flex: ${({ flex }) => (flex === undefined ? 1 : flex)};
  flex-basis: auto;
  margin-right: ${spacing(3)};

  &:last-child {
    margin-right: 0;
  }
`;

export const HellosignTaskEditor = ({ name }: { name: string }) => {
  const handleValidateItem = useCallback((value: IHelloSignTaskTemplate) => {
    if (!value.id) {
      return 'Invalid Item';
    }

    return undefined;
  }, []);

  return (
    <TaskEditorFrame name={name}>
      {() => (
        <Tabs.Tab id="options" label="Options">
          <EditorTabWrapper size="md">
            <FieldBox
              name={`${name}.data.templates`}
              label="Templates"
              description="The templates you want to be signed."
              required
            >
              <SimpleFieldAutoArray<IHelloSignTaskTemplate>
                name={`${name}.data.templates`}
                validateItem={handleValidateItem}
                presentFields={TEMPLATE_ITEM_PRESENT_FIELDS}
                render={({ name: itemName, index, value, error, onRemove, active }) => (
                  <TemplateItemRow key={value.id || index}>
                    <TemplateItemCol>
                      <Input
                        name={`${itemName}.id`}
                        placeholder="ID"
                        compact
                        aria-label="Template ID"
                        error={!active && !!error}
                      />
                    </TemplateItemCol>
                    <TemplateItemCol>
                      <Input
                        name={`${itemName}.name`}
                        placeholder="Name"
                        compact
                        aria-label="Template ID"
                        error={!active && !!error}
                      />
                    </TemplateItemCol>
                    <TemplateItemCol flex={0}>
                      <Button
                        icon="delete"
                        iconButton
                        size="xs"
                        type="danger"
                        importance="tertiary"
                        onClick={onRemove}
                        compact
                      />
                    </TemplateItemCol>
                  </TemplateItemRow>
                )}
              />
            </FieldBox>
          </EditorTabWrapper>
        </Tabs.Tab>
      )}
    </TaskEditorFrame>
  );
};
