import { ModalDialog, notifications, useModal } from '@mortgagehippo/ds';
import { useCallback } from 'react';

import { type IActionProps, type IDeleteLandingPageAction } from '../types';
import { useDeleteLandingPage } from './use-delete-landing-page';

export const DeleteLandingPageAction = (props: IActionProps<IDeleteLandingPageAction>) => {
  const { action, onDone } = props;
  const { landingPageId } = action;
  const [isOpen, , closeModal] = useModal(true);

  const deleteLandingPage = useDeleteLandingPage();

  const handleOk = async () => {
    try {
      await deleteLandingPage(landingPageId);
      closeModal(() => {
        notifications.success({
          messageCid: 'delete-landing-page-action:notification-success',
          message: 'Successfully deleted landing page',
        });
        onDone();
      });
    } catch (e) {
      closeModal(() => {
        notifications.error({
          messageCid: 'delete-landing-page-action:notification-error',
          message: 'There was an unexpected error deleting landing page',
        });
        onDone();
      });
    }
  };

  const handleCloseModal = useCallback(() => {
    closeModal(onDone);
  }, [closeModal, onDone]);

  // TODO: use hook useModalConfirm
  return (
    <ModalDialog
      type="error"
      title="Are you sure you want to delete this landing page?"
      onOk={handleOk}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
    >
      This action is irreversible
    </ModalDialog>
  );
};
