import {
  Format,
  type ISelectOption,
  type ITableActions,
  type ITableCol,
  Select,
  Table,
  useModal,
} from '@mortgagehippo/ds';
import { lowerCase, upperFirst } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { type VerticalType } from '../../../apollo/graphql';
import { LenderMilestoneEditModalContainer } from './lender-milestone-edit-modal-container';
import { type ILenderMilestone } from './queries';
import { useLenderMilestones } from './use-lender-milestones';

const TopContent = styled.div`
  max-width: 300px;
`;

const columns: Array<ITableCol<ILenderMilestone>> = [
  {
    title: 'Label',
    key: 'name',
    render: (t) => t.name,
  },
  {
    title: 'External Milestone Key',
    key: 'key',
    render: ({ externalMilestoneKey }) => externalMilestoneKey,
    noWrap: true,
  },
  {
    title: 'Last Updated',
    key: 'updated',
    render: ({ updatedAt }) => <Format.Date format="fromnow" value={updatedAt} />,
  },
];

interface ILenderMilestonesTableProps {
  partnerId: string;
  verticals: VerticalType[];
}

export const LenderMilestonesTable = (props: ILenderMilestonesTableProps) => {
  const { partnerId, verticals } = props;
  const [isOpen, openModal, closeModal] = useModal(false);

  const [selectedVertical, setSelectedVertical] = useState<VerticalType | undefined>(undefined);

  const [{ lenderMilestones }, lenderMilestonesLoading, refetchLenderMilestones] =
    useLenderMilestones(partnerId, selectedVertical, !selectedVertical);

  const handleVerticalChange = useCallback(
    (nextVertical: string) => {
      setSelectedVertical(nextVertical as VerticalType);
    },
    [setSelectedVertical]
  );

  const handleModalClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleModalSubmit = useCallback(() => {
    closeModal(() => refetchLenderMilestones());
  }, [closeModal, refetchLenderMilestones]);

  const topActions: ITableActions = useMemo(() => {
    const newActions: ITableActions = [
      {
        key: 'edit-milestones',
        label: 'Edit milestones',
        buttonProps: {
          icon: 'edit',
        },
        onAction: (_actionKey) => {
          openModal();
        },
      },
    ];
    return newActions;
  }, [openModal]);

  const topContent = useMemo(() => {
    const verticalOptions: ISelectOption[] = verticals.map((vertical) => ({
      label: upperFirst(lowerCase(vertical)),
      value: vertical,
    }));

    return (
      <TopContent>
        <Select.Input
          name="vertical"
          options={verticalOptions}
          value={selectedVertical}
          onChange={handleVerticalChange}
          aria-label="Select vertical to see its milestones"
          placeholder="Select vertical to see its milestones"
          size="sm"
          compact
        />
      </TopContent>
    );
  }, [handleVerticalChange, selectedVertical, verticals]);

  const emptyText = `This Partner has no Lender Milestones for vertical ${selectedVertical}`;

  useEffect(() => {
    if (!lenderMilestonesLoading && !selectedVertical && verticals?.length) {
      setSelectedVertical(verticals[0]);
    }
  }, [lenderMilestonesLoading, selectedVertical, setSelectedVertical, verticals]);

  const loading = lenderMilestonesLoading || !selectedVertical;

  return (
    <>
      <Table<ILenderMilestone>
        caption="Lender Milestones"
        rowKey={(item) => item.id}
        data={lenderMilestones}
        cols={columns}
        columnTitleCasing="uppercase"
        size="sm"
        topActions={topActions}
        topContent={topContent}
        loading={loading}
        emptyText={emptyText}
      />
      <LenderMilestoneEditModalContainer
        partnerId={partnerId}
        isOpen={isOpen}
        vertical={selectedVertical}
        lenderMilestones={lenderMilestones}
        onRequestClose={handleModalClose}
        onSubmit={handleModalSubmit}
      />
    </>
  );
};
