import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { type SaveCascadeConfigurationInput } from '../../apollo/graphql';
import { MSaveCascadeConfigurationMutation } from './queries';

export const useSaveCascadeConfiguration = () => {
  const saveCascade = useMutation(MSaveCascadeConfigurationMutation, undefined);

  return useCallback(
    async (input: SaveCascadeConfigurationInput) => {
      const response = await saveCascade({
        input,
      });

      return response.data!.saveCascadeConfiguration;
    },
    [saveCascade]
  );
};
