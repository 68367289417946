import { graphql } from '../../../apollo/gql';
import { type LenderMilestonesQuery } from '../../../apollo/graphql';

export type ILenderMilestone = NonNullable<LenderMilestonesQuery['partner']>['lenderMilestones'][0];

export const QPartnerVerticals = graphql(`
  query PartnerVerticals($partnerId: ID!) {
    partner(id: $partnerId) {
      id
      verticals
    }
  }
`);

export const QPartnerSequentialLenderMilestones = graphql(`
  query SequentialLenderMilestones($partnerId: ID!) {
    partner(id: $partnerId) {
      id
      sequentialLenderMilestones
    }
  }
`);

export const QLenderMilestones = graphql(`
  query LenderMilestones($partnerId: ID!, $vertical: VerticalType) {
    partner(id: $partnerId) {
      id
      lenderMilestones(vertical: $vertical) {
        id
        name
        position
        externalMilestoneKey
        externalMilestoneKeyId
        updatedAt
      }
    }
  }
`);

export const QExternalMilestoneKeys = graphql(`
  query ExternalMilestoneKeys($partnerId: ID!, $vertical: VerticalType, $ignore: Boolean) {
    partner(id: $partnerId) {
      id
      externalMilestoneKeys(vertical: $vertical, ignore: $ignore) {
        id
        name
        ignore
        updatedAt
      }
    }
  }
`);

export const MUpdateLenderMilestonesMutation = graphql(`
  mutation UpdateLenderMilestones(
    $partnerId: ID!
    $vertical: VerticalType!
    $data: UpdateLenderMilestoneInput!
  ) {
    updateLenderMilestones(partnerId: $partnerId, vertical: $vertical, data: $data) {
      id
      tempId
      name
      updatedAt
      position
      errors
      deleted
      externalMilestoneKey
      externalMilestoneKeyId
    }
  }
`);

export const MAddExternalMilestoneKeyMutation = graphql(`
  mutation AddExternalMilestoneKey(
    $partnerId: ID!
    $vertical: VerticalType!
    $name: String!
    $ignore: Boolean!
  ) {
    addExternalMilestoneKey(
      partnerId: $partnerId
      vertical: $vertical
      name: $name
      ignore: $ignore
    )
  }
`);

export const MEditExternalMilestoneKeyMutation = graphql(`
  mutation EditExternalMilestoneKey($id: ID!, $name: String!, $ignore: Boolean!) {
    editExternalMilestoneKey(id: $id, name: $name, ignore: $ignore)
  }
`);

export const MDeleteExternalMilestoneKeyMutation = graphql(`
  mutation DeleteExternalMilestoneKey($id: ID!) {
    deleteExternalMilestoneKey(id: $id)
  }
`);

export const MSetSequentialLenderMilestonesMutation = graphql(`
  mutation SetSequentialLenderMilestones($partnerId: ID!, $sequential: Boolean!) {
    setSequentialLenderMilestones(partnerId: $partnerId, sequential: $sequential)
  }
`);
