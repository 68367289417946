import { useQuery } from '@mortgagehippo/apollo-hooks';
import { type FetchPolicy } from 'apollo-client';

import { graphql } from '../apollo/gql';
import { type PartnerDataQuery } from '../apollo/graphql';

export type IPartner = NonNullable<PartnerDataQuery['partner']>;

export const QPartnerData = graphql(`
  query PartnerData($partnerId: ID!) {
    partner(id: $partnerId) {
      id
      name
      isBillable
      adminDomain
      adminInactivityTimeout
      timezone
    }
  }
`);

export const usePartner = (
  partnerId: string,
  suspend = false,
  fetchPolicy: FetchPolicy = 'cache-first'
): [IPartner | null | undefined, boolean] => {
  const [data, loading] = useQuery(
    QPartnerData,
    {
      partnerId,
    },
    { suspend, fetchPolicy }
  );

  const partner = !loading && data ? data.partner : undefined;

  return [partner, loading];
};
