import {
  borderRadius,
  Button,
  FieldLabel,
  type IThemeProp,
  palette,
  Popover,
  Tag,
  useFormValue,
} from '@mortgagehippo/ds';
import { isPromise } from '@mortgagehippo/util';
import { get } from 'lodash-es';
import { Children, cloneElement, type ReactElement, useCallback, useState } from 'react';
import styled, { css, withTheme } from 'styled-components';

const Row = styled.div`
  display: flex;
  align-items: start;
`;

const ReadOnly = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  border-width: 1px;
  border-style: solid;
  border-radius: ${borderRadius(1)};
  cursor: pointer;
  ${({ theme }) => {
    const inputSize = theme.components.input.sizes.normal;
    const inputState = theme.components.input.states.disabled;

    return css`
      padding: ${inputSize.paddingV} ${inputSize.paddingL} ${inputSize.paddingV}
        ${inputSize.paddingL};
      color: ${inputState.color} !important;
      border-color: ${inputState.borderColor} !important;
      background: ${palette('neutral200')} !important;
      font-weight: ${inputState.fontWeight} !important;
      box-shadow: ${inputState.shadow} !important;
      transform: none !important;
    `;
  }}
`;

const StoredValue = styled.div`
  flex: 1;
`;

const Source = styled.div`
  flex: 0 0 auto;
`;

const InputContainer = styled.div`
  flex: 1;
`;

const Actions = styled.div`
  flex: 0 0 auto;
`;

export interface IBasicThemeResourceEditorProps {
  name: string;
  label?: string;
  editing?: boolean;
  storedValue?: string | null;
  source?: string;
  showDeleteButton: boolean;
  onToggleEditing?: (name: string) => void;
  onCancelEditing?: (name: string) => void;
  onDelete?: ((name: string) => Promise<any>) | ((name: string) => void);
  children: ReactElement;
}

type IThemeResourceEditorProps = IThemeProp<IBasicThemeResourceEditorProps>;

export const ThemeResourceEditor = withTheme((props: IThemeResourceEditorProps) => {
  const {
    name,
    label,
    editing,
    storedValue,
    source,
    showDeleteButton,
    onToggleEditing,
    onCancelEditing,
    onDelete,
    theme,
    children,
  } = props;

  const [deleting, setDeleting] = useState<boolean>(false);
  const [formValue, setFormValue] = useFormValue(name);
  const defaultValue = theme ? get(theme, name) : undefined;
  const isEdited = formValue && formValue !== storedValue;

  const handleToggleEditing = useCallback(() => {
    if (onToggleEditing) {
      onToggleEditing(name);
    }
  }, [name, onToggleEditing]);

  const handleCancelEditing = useCallback(() => {
    setFormValue(undefined);

    if (onCancelEditing) {
      onCancelEditing(name);
    }
  }, [name, onCancelEditing, setFormValue]);

  const handleTriggerDelete = useCallback(async () => {
    if (onDelete) {
      const result = onDelete(name);

      if (isPromise(result)) {
        setDeleting(true);

        await result;

        setDeleting(false);
      }
    }
  }, [name, onDelete]);

  let labelEl;
  let labelId;

  if (label) {
    labelId = `label_${name.replace('.', '_')}`;
    labelEl = <FieldLabel id={labelId}>{label}</FieldLabel>;
  }

  let inputEl;

  if (editing) {
    const childEl = Children.only(children);

    if (childEl) {
      const childProps = {
        ...childEl.props,
        name,
        compact: true,
        required: true,
        icon: isEdited ? 'edit' : undefined,
        'aria-labelledby': labelId || undefined,
      };

      inputEl = cloneElement(childEl, childProps);
    }
  }

  const displayValue = formValue || storedValue || defaultValue;

  return (
    <div>
      {labelEl}
      <Row>
        {!editing && (
          <ReadOnly role="button" tabIndex={0} onClick={handleToggleEditing}>
            <StoredValue>{displayValue || ' '}</StoredValue>
            {source ? (
              <Source>
                <Tag>{source}</Tag>
              </Source>
            ) : null}
          </ReadOnly>
        )}
        {editing ? <InputContainer>{inputEl}</InputContainer> : null}
        <Actions>
          {showDeleteButton ? (
            <Popover
              content="Are you sure?"
              confirm
              size="sm"
              onConfirm={handleTriggerDelete}
              buttonProps={{
                iconButton: true,
                icon: 'delete',
                type: 'danger',
                importance: 'tertiary',
                loading: deleting,
              }}
            >
              Unset
            </Popover>
          ) : null}
          {!showDeleteButton && editing ? (
            <Button
              iconButton
              type="danger"
              icon="close"
              importance="tertiary"
              onClick={handleCancelEditing}
              tooltip="Cancel"
              compact
            >
              Cancel
            </Button>
          ) : null}
        </Actions>
      </Row>
    </div>
  );
});
