import { FormSection, type UploadHandler } from '@mortgagehippo/ds';
import { TaskType } from '@mortgagehippo/tasks';
import { UnreachableCaseError } from '@mortgagehippo/util';

import {
  TemplatesFormDocumentSubmissionTaskFields,
  TemplatesFormDocusignTaskFields,
  TemplatesFormHellosignTaskFields,
  TemplatesFormInformationTaskFields,
  TemplatesFormPaymentTaskFields,
  TemplatesFormSendDocumentTaskFields,
} from './fields';
import { type TemplateableTaskType } from './types';

interface TemplatesFormTaskFieldsProps {
  taskType: TemplateableTaskType;
  onUploadDocument: UploadHandler;
}

export const TemplatesFormTaskFields = (props: TemplatesFormTaskFieldsProps) => {
  const { taskType, onUploadDocument } = props;

  let el;
  switch (taskType) {
    case TaskType.InformationTask:
      el = <TemplatesFormInformationTaskFields />;
      break;
    case TaskType.SendDocumentTask:
      el = <TemplatesFormSendDocumentTaskFields onUploadDocument={onUploadDocument} />;
      break;
    case TaskType.HellosignTask:
      el = <TemplatesFormHellosignTaskFields onUploadDocument={onUploadDocument} />;
      break;
    case TaskType.PaymentTask:
      el = <TemplatesFormPaymentTaskFields />;
      break;
    case TaskType.DocumentSubmissionTask:
      el = <TemplatesFormDocumentSubmissionTaskFields />;
      break;
    case TaskType.DocusignTask:
      el = <TemplatesFormDocusignTaskFields onUploadDocument={onUploadDocument} />;
      break;
    case TaskType.AssetsTask:
      el = null;
      break;
    default:
      throw new UnreachableCaseError(taskType);
  }

  if (!el) {
    return null;
  }

  return <FormSection title="Data">{el}</FormSection>;
};
