import {
  Avatar,
  fontSize,
  fontWeight,
  type ISearchFilter,
  type ISelectOption,
  lineHeight,
  ModalForm,
  palette,
  SearchOperatorType,
  Select,
  TEXT_SEARCH_FIELD_NAME,
} from '@mortgagehippo/ds';
import { useDebouncedCallback, useSafeCallback } from '@mortgagehippo/util';
import { noop } from 'lodash-es';
import { memo, useMemo, useState } from 'react';
import styled from 'styled-components';

import { type NotificationAlternateAgent } from './unassigned-application-notifications-table';
import { usePartnerNotificationAlternateAvailableAgents } from './use-partner-notification-alternate-available-agents';
import { useSiteNotificationAlternateAvailableAgents } from './use-site-notification-alternate-available-agents';

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  font-size: ${fontSize('sm')};
  line-height: ${lineHeight('sm')};
`;

const Email = styled.div`
  font-size: ${fontSize('sm')};
  line-height: ${lineHeight('sm')};
  color: ${palette('neutral600')};
  font-weight: ${fontWeight('light')};
`;

export interface IUnassignedAgentSelectOptionProps {
  avatarUrl: string | null;
  name: string | null;
  email: string | null;
  isSelected?: boolean;
}

const UnassignedAgentSelectOption = (props: IUnassignedAgentSelectOptionProps) => {
  const { avatarUrl, name, email, isSelected = false } = props;

  return (
    <Flex>
      <div>
        <Avatar src={avatarUrl} alt={`${name}`} size={24} shape="circle" />
      </div>
      <div>
        <Name>{name}</Name>
        {!isSelected && <Email>{email}</Email>}
      </div>
    </Flex>
  );
};

export interface IUnassignedApplicationNotificationsAddAgentModalContentProps {
  partnerId: string;
  siteId?: string;
  loading: boolean;
  isOpen: boolean;
  onSubmit: (agent: NotificationAlternateAgent) => any;
  onClose: () => any;
}

export const UnassignedApplicationNotificationsAddAgentModalContent = memo(
  (props: IUnassignedApplicationNotificationsAddAgentModalContentProps) => {
    const { partnerId, siteId, loading, isOpen, onClose, onSubmit } = props;

    const isSitePage = !!siteId;

    const [searchFilters, setSearchFilters] = useState<ISearchFilter[] | undefined>();
    const [inputValue, setInputValue] = useState<string | null>(null);
    const [selected, setSelected] = useState<boolean | null>(null);

    const [siteUnassignedAgentsData, siteUnassignedAgentsLoading] =
      useSiteNotificationAlternateAvailableAgents(
        partnerId,
        siteId || '',
        searchFilters,
        !isOpen || !isSitePage || !inputValue
      );

    const [partnerUnassignedAgentsData, partnerUnassignedAgentsLoading] =
      usePartnerNotificationAlternateAvailableAgents(
        partnerId,
        searchFilters,
        !isOpen || isSitePage || !inputValue
      );

    const unassignedAgentsLoading = siteUnassignedAgentsLoading || partnerUnassignedAgentsLoading;

    const options: ISelectOption[] = useMemo(() => {
      if (unassignedAgentsLoading || !inputValue) {
        return [];
      }

      let selectOptions: ISelectOption[];
      if (isSitePage && siteUnassignedAgentsData) {
        selectOptions = siteUnassignedAgentsData.map((unassignedAgent) => {
          const { avatarUrl, name, email, id } = unassignedAgent;
          return {
            label: <UnassignedAgentSelectOption avatarUrl={avatarUrl} name={name} email={email} />,
            selectedLabel: (
              <UnassignedAgentSelectOption
                avatarUrl={avatarUrl}
                name={name}
                email={email}
                isSelected
              />
            ),
            value: id,
          };
        });
        return selectOptions;
      }

      if (partnerUnassignedAgentsData) {
        selectOptions = partnerUnassignedAgentsData.map((unassignedAgent) => {
          const { avatarUrl, name, email, id } = unassignedAgent;
          return {
            label: <UnassignedAgentSelectOption avatarUrl={avatarUrl} name={name} email={email} />,
            selectedLabel: (
              <UnassignedAgentSelectOption
                avatarUrl={avatarUrl}
                name={name}
                email={email}
                isSelected
              />
            ),
            value: id,
          };
        });
        return selectOptions;
      }

      return [];
    }, [
      inputValue,
      isSitePage,
      partnerUnassignedAgentsData,
      siteUnassignedAgentsData,
      unassignedAgentsLoading,
    ]);

    const handleSearch = useDebouncedCallback(
      (newValue: string) => {
        setInputValue(newValue);
        setSelected(false);
        setSearchFilters(
          newValue
            ? [
                {
                  value: newValue,
                  field: TEXT_SEARCH_FIELD_NAME,
                  operator: SearchOperatorType.CONTAINS_ALL,
                },
              ]
            : []
        );
      },
      { wait: 200 },
      []
    );

    const handleSelect = useSafeCallback((newValue: string) => {
      setInputValue(newValue);
      setSelected(true);
    });

    const handleClose = useSafeCallback(() => {
      setInputValue(null);
      setSelected(false);
      onClose();
    });

    const handleSubmit = useSafeCallback(() => {
      if (inputValue) {
        const matchedAgent = (
          isSitePage ? siteUnassignedAgentsData : partnerUnassignedAgentsData
        ).find((agent) => agent.id === inputValue);
        if (matchedAgent) {
          onSubmit(matchedAgent);
        }
      }
    });

    /*
     * these props are restricted to combobox so this must be done to avoid type error for select
     * perhaps we want to expose these to select?
     */
    const extraProps = {
      onSearch: handleSearch,
      onSelect: handleSelect,
    };

    return (
      <ModalForm
        loading={loading}
        isOpen={isOpen}
        title="Assign Agent"
        onSubmit={handleSubmit}
        okButtonDisabled={!selected}
        okButtonLabel="Assign agent"
        onRequestClose={handleClose}
      >
        <Select.Input
          placeholder="Type an agent's name or email"
          aria-label="Assign Agent"
          name="Assign Agent"
          value={inputValue}
          options={options}
          loading={unassignedAgentsLoading}
          notFoundContent="No unassigned agents found."
          onChange={noop}
          allowClear
          doNotFilterOptions
          {...extraProps}
        />
      </ModalForm>
    );
  }
);
