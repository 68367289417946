import { type RouteComponentProps } from 'react-router';

import { CredentialsEditor } from '../../../components/credentials-editor';
import { useSite } from '../../../hooks/use-site';
import { Content, Layout } from '../../../layouts/main';

interface ISiteCredentialsPageRouteParams {
  partnerId: string;
  siteId: string;
}

type ISiteCredentialsPageProps = RouteComponentProps<ISiteCredentialsPageRouteParams>;

export const SiteCredentialsPage = (props: ISiteCredentialsPageProps) => {
  const { match } = props;
  const { params } = match;
  const { partnerId, siteId } = params;

  const [site] = useSite(siteId);
  const siteName = site?.name || '';

  return (
    <Layout pageTitle={`${siteName} - Credentials`}>
      <Content title="Credentials" subTitle={siteName}>
        <CredentialsEditor partnerId={partnerId} siteId={siteId} />
      </Content>
    </Layout>
  );
};
