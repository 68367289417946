import { Empty, palette, spacing } from '@mortgagehippo/ds';
import styled from 'styled-components';

const Container = styled.div`
  padding: ${spacing(7)};
  border: 1px solid ${palette('neutral200')};
`;

export const LandingPageEditorPreviewEmpty = () => (
  <Container>
    <Empty>You haven&apos;t added any components yet.</Empty>
  </Container>
);
