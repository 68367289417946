/*
 *this defines whether to display a secondary hard-coded key-path filter
 *drop-down after the namespace dropdown. The list of filters is per namespace
 */
type KeyPathFilters = Record<string, string[]>;
export const KEY_PATH_FILTERS: KeyPathFilters = {
  default: [
    'fonts.*',
    'links.*',
    'components.button.*',
    'components.typography.*',
    'components.choice.*',
    'components.checkbox.*',
    'components.radio.*',
    'components.select.*',
    'components.input.*',
    'components.textArea.*',
    'components.fieldBox.*',
    'components.readOnlyBox.*',
  ],
};
