import { borderRadiuses, Empty, Input, palette, Select, spacing, Text } from '@mortgagehippo/ds';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { useCustomizationEditorNamespaces } from '../../hooks/use-customization-editor-namespaces';
import { useDiscardUnsavedChanges } from '../../hooks/use-discard-unsaved-changes';
import { type ICustomizationsEditorTabProps } from '../../types';
import { KEY_PATH_FILTERS } from './constants/key-path-filters';
import { GeneralEditor } from './general-editor';
import { Loading } from './loading';

const Row = styled.div`
  display: flex;
  margin: -${spacing(3)} -${spacing(2)} ${spacing(3)};
  padding: ${spacing(2)} ${spacing(1)};
  background: ${palette('neutral200')};
  border-radius: ${borderRadiuses(0, 0, 2, 2)};
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.15);
`;

const Col = styled.div`
  margin: 0 ${spacing(1)};
  flex: 1 1 auto;

  &:first-child {
    max-width: 410px;
  }
`;

interface IGeneralEditorTabProps extends ICustomizationsEditorTabProps {
  projectId: string;
  unsavedChangesCount: number;
  setUnsavedChangesCount: (count: number) => void;
}

export const GeneralEditorTab = (props: IGeneralEditorTabProps) => {
  const { projectId, unsavedChangesCount, setUnsavedChangesCount, ...rest } = props;

  const [currentNamespace, setCurrentNamespace] = useState<string>();
  const [currentSearch, setCurrentSearch] = useState<string>('');
  const [currentSearchInputValue, setCurrentSearchInputValue] = useState<string>('');

  const [currentKeyPath, setCurrentKeyPath] = useState<string>();
  const [keyPathFilters, setKeyPathFilters] = useState<string[]>();

  const [shouldDiscardUnsavedChanges, changesModalDialog] = useDiscardUnsavedChanges();

  useEffect(() => {
    setCurrentNamespace(undefined);
  }, [projectId]);

  useEffect(() => {
    if (
      currentNamespace &&
      KEY_PATH_FILTERS[currentNamespace] &&
      KEY_PATH_FILTERS[currentNamespace]!.length > 0
    ) {
      setKeyPathFilters(KEY_PATH_FILTERS[currentNamespace]);
      setCurrentKeyPath(KEY_PATH_FILTERS[currentNamespace]![0]!);
    } else {
      setKeyPathFilters(undefined);
      setCurrentKeyPath(undefined);
    }

    setCurrentSearch('');
    setCurrentSearchInputValue('');
  }, [currentNamespace]);

  useEffect(() => {
    setCurrentSearch('');
    setCurrentSearchInputValue('');
  }, [currentKeyPath]);

  const [customizationNamespaces, customizationNamespacesLoading] =
    useCustomizationEditorNamespaces(projectId);

  const handleKeyPathChange = useCallback(
    async (value: string) => {
      const discardChanges = await shouldDiscardUnsavedChanges(unsavedChangesCount);

      if (!discardChanges) {
        return;
      }

      setCurrentKeyPath(value);
      setUnsavedChangesCount(0);
    },
    [unsavedChangesCount, shouldDiscardUnsavedChanges, setUnsavedChangesCount]
  );

  const handleNamespaceChange = useCallback(
    async (value: string) => {
      const discardChanges = await shouldDiscardUnsavedChanges(unsavedChangesCount);

      if (!discardChanges) {
        return;
      }

      setCurrentNamespace(value);
      setUnsavedChangesCount(0);
    },
    [unsavedChangesCount, shouldDiscardUnsavedChanges, setUnsavedChangesCount]
  );

  const refMounted = useRef(false);
  useEffect(() => {
    refMounted.current = true;
    return () => {
      refMounted.current = false;
    };
  }, []);

  const debouncedSetCurrentSearch = useMemo(
    () =>
      debounce((value: string) => {
        if (refMounted && refMounted.current) {
          setCurrentSearch(value);
        }
      }, 300),
    []
  );

  const handleSearchChange = useCallback(
    (value: string) => {
      setCurrentSearchInputValue(value);
      debouncedSetCurrentSearch(value);
    },
    [debouncedSetCurrentSearch]
  );

  const searchRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const focusSearch = (e: KeyboardEvent) => {
      if (e.code === 'KeyF' && (e.metaKey || e.ctrlKey) && searchRef.current) {
        const el = searchRef.current.querySelector('input');
        if (el && !el.disabled) {
          e.preventDefault();
          el.focus();
        }
      }
    };

    window.addEventListener('keydown', focusSearch);
    return () => {
      window.removeEventListener('keydown', focusSearch);
    };
  }, []);

  const keyPathFilterSelect = useMemo(
    () =>
      (keyPathFilters && keyPathFilters.length > 1 && (
        <Col>
          <Select.Input
            name="keyPathFilter"
            searchable
            size="sm"
            compact
            value={currentKeyPath}
            onChange={handleKeyPathChange}
            options={keyPathFilters.map((filter) => ({
              value: filter,
              label: filter,
            }))}
          />
        </Col>
      )) ||
      null,
    [keyPathFilters, currentKeyPath, handleKeyPathChange]
  );

  const namespaceFilterSelect = useMemo(
    () => (
      <Col>
        <Select.Input
          name="namespaceFilter"
          searchable
          size="sm"
          compact
          placeholder="Select Namespace..."
          loading={customizationNamespacesLoading}
          value={currentNamespace}
          onChange={handleNamespaceChange}
          options={
            customizationNamespaces
              ? customizationNamespaces.map((ns) => ({
                  value: ns,
                  label: ns === 'default' ? 'default (DS - DANGER)' : ns,
                }))
              : []
          }
        />
      </Col>
    ),
    [
      customizationNamespaces,
      currentNamespace,
      handleNamespaceChange,
      customizationNamespacesLoading,
    ]
  );

  const hasNamespace = !!currentNamespace;

  const searchInput = useMemo(
    () => (
      <Col ref={searchRef}>
        <Input.Input
          name="search"
          compact
          size="sm"
          disabled={!hasNamespace}
          value={currentSearchInputValue}
          onChange={handleSearchChange}
          placeholder="search within namespace"
        />
      </Col>
    ),
    [currentSearchInputValue, handleSearchChange, hasNamespace]
  );

  if (
    !customizationNamespacesLoading &&
    customizationNamespaces &&
    customizationNamespaces.length === 0
  ) {
    return <Empty />;
  }

  return (
    <>
      <Row>
        {namespaceFilterSelect}
        {keyPathFilterSelect}
        {searchInput}
      </Row>
      {(customizationNamespacesLoading && <Loading />) ||
        (currentNamespace && (
          <GeneralEditor
            projectId={projectId}
            namespace={currentNamespace}
            keyPathFilter={currentKeyPath}
            searchFilter={currentSearch}
            setUnsavedChangesCount={setUnsavedChangesCount}
            {...rest}
          />
        )) || (
          <Text as="p" align="center" variant="secondary">
            <br />
            <br />
            <strong>Select a namespace...</strong>
          </Text>
        )}
      {changesModalDialog}
    </>
  );
};
