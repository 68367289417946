import { Input, type ISelectOption, Select } from '@mortgagehippo/ds';

enum DocumentCategoryTypes {
  DocExW2 = 'DocExW2',
  DocExPersonalTaxReturn = 'DocExPersonalTaxReturn',
  DocExOtherIncome = 'DocExOtherIncome',
  DocExBankStatement = 'DocExBankStatement',
  DocExHUD1 = 'DocExHUD1',
  DocExOtherAsset = 'DocExOtherAsset',
  DocExGift = 'DocExGift',
  DocExLandlordInformation = 'DocExLandlordInformation',
  DocExExpenses = 'DocExExpenses',
  DocExHomeownersInsurance = 'DocExHomeownersInsurance',
  DocExLoanInformation = 'DocExLoanInformation',
  DocExPropertyInformation = 'DocExPropertyInformation',
  DocExBusinessTaxReturn = 'DocExBusinessTaxReturn',
  'DocExCondoPUDCo-Op' = 'DocExCondoPUDCo-Op',
  DocExSalesContract = 'DocExSalesContract',
  DocExResidencyStatus = 'DocExResidencyStatus',
  DocExPayoffInformation = 'DocExPayoffInformation',
  DocExIdentification = 'DocExIdentification',
  DocExDisclosures = 'DocExDisclosures',
  DocExLoanPackage = 'DocExLoanPackage',
  DocExExplanationLetter = 'DocExExplanationLetter',
  DocExPaystub = 'DocExPaystub',
  DocExOtherMiscellaneous = 'DocExOtherMiscellaneous',
  DocExOther = 'DocExOther',
}

const documentCategoryOptions: ISelectOption[] = Object.keys(DocumentCategoryTypes).map((key) => ({
  value: key,
  label: key,
}));

export const MortgageBotFields = () => (
  <>
    <Input.Box name="client_id" label="Client ID" required />
    <Input.Box name="client_secret" label="Client Secret" required />
    <Input.Box
      name="tenant_id"
      label="Tenant ID"
      labelTooltip="Test environment tenant id is: sandbox"
      required
    />
    <Select.Box
      name="default_document_category_name"
      label="Default document category"
      options={documentCategoryOptions}
      required
    />
  </>
);
