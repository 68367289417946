import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo/gql';
import { type LandingPageInput } from '../../../apollo/graphql';

export const MCreateLandingPageMutation = graphql(`
  mutation CreateLandingPage($siteId: ID!, $data: LandingPageInput!) {
    createLandingPage(siteId: $siteId, data: $data) {
      ...LandingPageFragment
    }
  }
`);

export const useCreateLandingPage = () => {
  const createLandingPage = useMutation(MCreateLandingPageMutation, undefined);

  return async (siteId: string, data: LandingPageInput) => {
    await createLandingPage({ siteId, data });
  };
};
