import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../../../apollo/gql';
import { LandingPageType } from '../../../apollo/graphql';

export const QAgentPageComponentData = graphql(`
  query AgentPageComponentData($siteId: ID!, $pageComponentId: ID!) {
    site(id: $siteId) {
      id
      agentLandingPage {
        id
        pageComponent(id: $pageComponentId) {
          ...PageComponentFragment
          customizationValues {
            ...CustomizationValueFragment
          }
        }
      }
    }
  }
`);

export const QPageComponentData = graphql(`
  query PageComponentData($siteId: ID!, $landingPageId: ID!, $pageComponentId: ID!) {
    site(id: $siteId) {
      id
      landingPage(id: $landingPageId) {
        id
        pageComponent(id: $pageComponentId) {
          ...PageComponentFragment
          customizationValues {
            ...CustomizationValueFragment
          }
        }
      }
    }
  }
`);

export const usePageComponent = (
  type: LandingPageType,
  siteId: string,
  landingPageId: string,
  pageComponentId: string,
  options: IUseApolloQueryOptions = {}
) => {
  const isLandingPage = type === LandingPageType.LandingPage;

  const [landingPageData, landingPageDataLoading, , { refetch: refetchLandingPageData }] = useQuery(
    QPageComponentData,
    {
      siteId,
      landingPageId,
      pageComponentId,
    },
    {
      fetchPolicy: 'network-only',
      ...options,
      skip: !isLandingPage || options.skip,
    }
  );

  const [
    agentLandingPageData,
    agentLandingPageDataLoading,
    ,
    { refetch: refetchAgentLandingPageData },
  ] = useQuery(
    QAgentPageComponentData,
    {
      siteId,
      pageComponentId,
    },
    {
      fetchPolicy: 'network-only',
      ...options,
      skip: isLandingPage || options.skip,
    }
  );

  const handleRefresh = useSafeCallback(async () => {
    if (isLandingPage) {
      await refetchLandingPageData({
        siteId,
        landingPageId,
        pageComponentId,
      });
    } else {
      await refetchAgentLandingPageData({
        siteId,
        pageComponentId,
      });
    }
  });

  const loading = landingPageDataLoading || agentLandingPageDataLoading;
  const pageComponent = isLandingPage
    ? landingPageData?.site?.landingPage?.pageComponent
    : agentLandingPageData?.site?.agentLandingPage?.pageComponent;

  return [{ data: pageComponent, loading }, handleRefresh] as const;
};
