import { Input, type ISelectOption, Select, Title, useFormValue, YesNo } from '@mortgagehippo/ds';

import { CodeEditorField } from '$components/code-editor';

enum LendingQbFileTypes {
  fannie_mae_32 = 'fannie_mae_32',
  mismo_34 = 'mismo_34',
  mismo_34_du = 'mismo_34_du',
}

const LendingQbFileTypeOptions: ISelectOption[] = [
  {
    label: 'Fannie Mae File 3.2',
    value: LendingQbFileTypes.fannie_mae_32,
  },
  {
    label: 'MISMO File 3.4 - ULAD',
    value: LendingQbFileTypes.mismo_34,
  },
  {
    label: 'MISMO File 3.4 - DU',
    value: LendingQbFileTypes.mismo_34_du,
  },
];

export const LendingQbFields = () => {
  const [isUsingOAuthToken] = useFormValue<boolean | undefined>('use_oauth_token');

  return (
    <>
      <Title level={3}>Access Info</Title>
      <YesNo.Box name="use_oauth_token" label="Use new OAuth token for Authorization" required />
      {isUsingOAuthToken ? (
        <>
          <Input.Box name="client_id" label="Client ID" required />
          <Input.Box name="client_secret" label="Client Secret" required />
        </>
      ) : null}
      {!isUsingOAuthToken && (
        <>
          <Input.Box name="user_name" label="Username" required />
          <Input.Box name="password" label="Password" required />
        </>
      )}

      <Title level={3}>Meridian Link</Title>
      <Input.Box name="default_los_login_id" label="Default LOS Login ID" />
      <Input.Box name="modified_loans_app_code" label="Modified Loans App Code" />
      <Input.Box name="purchase_template" label="Purchase Template Path" />
      <Input.Box name="refi_template" label="Refinance Template Path" />
      <Input.Box name="cra" label="CRA" />
      <YesNo.Box name="sync_changes" label="Sync Changes" />
      <Select.Box
        name="file_type"
        label="File type to be used"
        options={LendingQbFileTypeOptions}
      />
      <YesNo.Box name="production_mode" label="Production Mode" required />
      <CodeEditorField.Box mode="json" name="custom_json" label="Custom JSON" />
    </>
  );
};
