import { Button, spacing } from '@mortgagehippo/ds';
import { type ReactNode } from 'react';
import styled from 'styled-components';

export interface IAddMoreButtonProps {
  onClick?: () => void;
  children?: ReactNode;
}

const Container = styled.div`
  position: relative;
  padding: 0px;
  margin: ${spacing(4)} 0;
`;

const Line = styled.div`
  position: relative;
  top: 16px;
  border: 1px dashed #dcdee2;
`;

const AddMoreContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const AddMoreButton = (props: IAddMoreButtonProps) => {
  const { children, onClick } = props;
  return (
    <Container>
      <Line />
      <AddMoreContent>
        <Button icon="edit" type="neutral" size="xs" onClick={onClick} compact>
          {children}
        </Button>
      </AddMoreContent>
    </Container>
  );
};
