import { type RouteComponentProps } from 'react-router';

import { useSite } from '../../../hooks/use-site';
import { Content, Layout } from '../../../layouts/main';
import { SiteUnassignedApplicationNotificationsContactsPageContent } from './site-unassigned-application-notifications-contacts-page-content';

interface ISiteUnassignedApplicationNotificationsContactsPageRouteParams {
  partnerId: string;
  siteId: string;
}

type ISiteUnassignedApplicationNotificationsContactsPageProps =
  RouteComponentProps<ISiteUnassignedApplicationNotificationsContactsPageRouteParams>;

export const SiteUnassignedApplicationNotificationsContactsPage = (
  props: ISiteUnassignedApplicationNotificationsContactsPageProps
) => {
  const { match } = props;
  const { params } = match;
  const { partnerId, siteId } = params;
  const [site] = useSite(siteId);

  const siteName = site?.name || '';

  if (!siteName) {
    return null;
  }

  return (
    <Layout pageTitle={`${siteName} - Unassigned Application Notifications Contacts`}>
      <Content title="Unassigned Application Notifications Contacts" subTitle={siteName}>
        <SiteUnassignedApplicationNotificationsContactsPageContent
          partnerId={partnerId}
          siteId={siteId}
        />
      </Content>
    </Layout>
  );
};
