import { createField, type IFieldInputProps, type ISelectProps, Select } from '@mortgagehippo/ds';

import { VerticalType } from '../../apollo/graphql';

const options = [
  { value: VerticalType.Mortgage, label: 'Mortgage' },
  { value: VerticalType.Auto, label: 'Auto' },
  { value: VerticalType.CreditCard, label: 'Credit Card' },
  { value: VerticalType.Sba, label: 'SBA' },
];

export type IVerticalSelectProps = Omit<ISelectProps, 'options'>;

interface IVerticalSelectInputProps extends IVerticalSelectProps, IFieldInputProps {}

export const VerticalSelecttInput = (props: IVerticalSelectInputProps) => (
  <Select.Input options={options} {...props} />
);

export const VerticalSelect = createField<IVerticalSelectInputProps>(VerticalSelecttInput);
