/*
 * IMPORTANT
 *
 * You can write HTML tags that will render as elements.
 * If you want to display html as text for instructions, use HTML entities,
 * i.e. if you want to display this as text: <img />, enter it as &lt;img&gt;
 * otherwise an image element will be displayed
 */

export const TYPE_INSTRUCTIONS: Record<string, string> = {
  content: `Type "[empty]" to force no text to show, including default app text.`,
  text: `Type "[empty]" to force no text to show, including default app text.`,
  bool: `Turns on or off a feature or setting. The default values in the app could be either off or on depending on each resource.`,
  shadow: `The shadow levels vary from "0" (no shadow) to "5" (largest shadow). Some elements may also have a shadow intensity (on/off) in a separate resource in addition to the shadow.`,
  number: `Enter only whole numbers, e.g. 1, 0, -23, etc.`,
  color: `Enter a color such as "#165BAE" or rgba(0,0,0,.5). You can also select a color from the theme colors select.`,
  script: `Any javascript, html or css. IMPORTANT: All scripts must be inside &lt;script&gt;&lt;/script&gt; tags and all css inside &lt;style&gt;&lt;/style&gt; tags.`,
  html: `Any HTML code. DANGER: Please make sure the HTML is syntactically correct and test it or it could break the app.`,
  json: `Valid JSON enclosed within braces for objects <code>{...}</code> or square brackets for arrays <code>[...]</code>`,
};
