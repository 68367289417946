import {
  type FieldSize,
  type IFieldBoxProps,
  type ISelectOption,
  Select,
  useFormValue,
} from '@mortgagehippo/ds';
import { type ReactNode, useEffect, useMemo } from 'react';

import { type SourceSiteInput, VerticalType } from '../../apollo/graphql';
import { useSites } from '../../hooks/use-sites';
import { SiteDetailFields } from './site-detail-fields';

const verticalOptions: ISelectOption[] = [
  {
    label: 'Mortgage',
    value: VerticalType.Mortgage,
  },
  {
    label: 'SBA',
    value: VerticalType.Sba,
  },
];

interface ISiteSelectProps {
  partnerId?: string;
  label?: ReactNode;
  name: string;
  required?: boolean;
  disabled?: boolean;
  size?: FieldSize;
  multiple?: boolean;
  compact?: boolean;
  onChange?: (newValue: any) => void;
};

export const SiteSelect = (props: ISiteSelectProps & Pick<IFieldBoxProps, 'labelTooltip'>) => {
  const { partnerId, ...restProps } = props;
  const [sites, sitesLoading] = useSites(partnerId, { skip: !partnerId });

  const [selectedSites] = useFormValue<string[] | undefined>('sourceSiteIds');
  const [siteSources, setSiteSources] = useFormValue<SourceSiteInput[] | undefined>('sourceSites');

  useEffect(() => {
    if ((selectedSites?.length || 0) < (siteSources?.length || 0)) {
      const newSiteSources = siteSources?.filter(({ id }) => selectedSites?.includes(id.toString()));
      setSiteSources(newSiteSources);
    } else if ((siteSources?.length || 0) < (selectedSites?.length || 0)) {
      const [newSiteId] = selectedSites?.filter((s) => !siteSources?.find(({ id }) => id?.toString() === s)) || [];
      setSiteSources([...(siteSources || []), ...[{ id: parseInt(newSiteId!, 10) }]]);
    }
  }, [selectedSites, siteSources, setSiteSources]);

  const siteOptions = useMemo(() => {
    if (sitesLoading || !sites?.length) {
      return [];
    }
    return sites;
  }, [sitesLoading, sites]);

  return (
    <>
      {partnerId ? (
        <Select.Box
          label={null}
          {...restProps}
          options={siteOptions}
          loading={sitesLoading}
          searchable
          allowClear
          notFoundContent="No results found."
          placeholder="NONE - Create a blank site"
        />
      ) : null}
      <SiteDetailFields verticalOptions={verticalOptions} sites={sites} selectedSites={selectedSites} />
    </>
  )
};
