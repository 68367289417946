import { CredentialsType } from '../../apollo/graphql';

export enum CredentialsEditorDefaultAction {
  EDIT = 'edit',
  DELETE = 'delete',
}

export const CredentialsEditorActionTypes = {
  ...CredentialsType,
  ...CredentialsEditorDefaultAction,
};

export type CredentialsEditorActionType = CredentialsType | CredentialsEditorDefaultAction;

export const CredentialTypeLabels: { [key in CredentialsType]: string } = {
  [CredentialsType.AccountChek]: 'AccountChek',
  [CredentialsType.Byte]: 'Byte',
  [CredentialsType.CreditPull]: 'Credit Pull',
  [CredentialsType.DataTree]: 'DataTree',
  [CredentialsType.DataVerify]: 'Data Verify',
  [CredentialsType.DesktopUnderwriter]: 'Desktop Underwriter',
  [CredentialsType.Docutech]: 'Docutech',
  [CredentialsType.Docusign]: 'Docusign',
  [CredentialsType.EmailProvider]: 'Email Provider',
  [CredentialsType.EncompassApi]: 'Encompass API',
  [CredentialsType.ExternalSFTP]: 'External SFTP',
  [CredentialsType.Finicity]: 'Finicity',
  [CredentialsType.HelloSign]: 'HelloSign',
  [CredentialsType.IDS]: 'IDS',
  [CredentialsType.InternalSystem]: 'Internal System',
  [CredentialsType.LoanProductAdvisor]: 'Loan Product Advisor',
  [CredentialsType.LendingQb]: 'Meridian Link',
  [CredentialsType.LOSS]: 'LOSS',
  [CredentialsType.MortgageBot]: 'MortgageBot',
  [CredentialsType.MortgageDirector]: 'Mortgage Director',
  [CredentialsType.MortgageFlex]: 'MortgageFlex',
  [CredentialsType.Pricing]: 'Pricing',
  [CredentialsType.MortgageInsurance]: 'Mortgage Insurance',
  [CredentialsType.Salesforce]: 'Salesforce',
  [CredentialsType.SmartFees]: 'SmartFees',
  [CredentialsType.Snapdocs]: 'Snapdocs',
  [CredentialsType.TotalExpert]: 'Total Expert',
  [CredentialsType.Transnational]: 'Transnational',
  [CredentialsType.Truework]: 'Truework',
  [CredentialsType.WorkNumber]: 'Work Number',
  [CredentialsType.Velocify]: 'Velocify',
};
