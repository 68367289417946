import { Choice, Input } from '@mortgagehippo/ds';

export const DataTreeFields = () => (
  <>
    <Input.Box name="client_id" label="ClientID" required />
    <Input.Box name="client_secret" label="Client Secret" required />
    <Choice.Box
      name="production_mode"
      label="Environment"
      options={[
        { label: 'UAT', value: false },
        { label: 'Production', value: true },
      ]}
      required
    />
  </>
);
