import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useMemo } from 'react';

import { graphql } from '../apollo/gql';
import { type RegisteredIntegrationConnectionsQuery } from '../apollo/graphql';

export type RegisteredIntegrationConnectionsQueryIntegrationConnectionItem = NonNullable<
  RegisteredIntegrationConnectionsQuery['partner']
>['registeredIntegrationConnections']['items'][0];

export const QRegisteredIntegrationConnectionsQuery = graphql(`
  query RegisteredIntegrationConnections($partnerId: ID!, $perPage: Int!) {
    partner(id: $partnerId) {
      id
      registeredIntegrationConnections(
        options: { perPage: $perPage, orderBy: { path_slug: "asc" } }
      ) {
        total
        items {
          id
          uuid
          connectionVersion
          partnerId
          sagaName
          pathSlug
          securityType
          accessToken
          accessSecret
          signatureHeader
          signatureSigningKey
          basicUsername
          basicPassword
          createdAt
          updatedAt
          jwksUrl
          activated
          externalToken
          externalTokenKey
          integrationType
          integrationAction
        }
        nextCursor
        previousCursor
      }
    }
  }
`);

export type IRegisteredIntegrationConnection =
  RegisteredIntegrationConnectionsQueryIntegrationConnectionItem;

export const useRegisteredIntegrationConnections = (partnerId: string) => {
  const [data, loading] = useQuery(QRegisteredIntegrationConnectionsQuery, {
    partnerId,
    perPage: 10000,
  });

  const items = useMemo(() => data?.partner?.registeredIntegrationConnections?.items || [], [data]);

  return [items, loading] as const;
};
