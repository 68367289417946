import {
  Avatar,
  fontSize,
  type ITableActions,
  type ITableCols,
  lineHeight,
  MediaBreakpoint,
  notifications,
  Table,
  Tag,
  useModal,
} from '@mortgagehippo/ds';
import { usePrevious } from '@mortgagehippo/util';
import { compact, uniqBy } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import {
  type PartnerNotificationAlternateAgentsQuery,
  type SiteNotificationAlternateAgentsQuery,
} from '../../apollo/graphql';
import { UnassignedApplicationNotificationsAddAgentModal } from './unassigned-application-notifications-add-agent-modal';
import { useAddNotificationAlternateAgent } from './use-add-notification-alternate-agent';
import { useRemoveNotificationAlternateAgent } from './use-remove-notification-alternate-agent';

export type PartnerNotificationAlternateAgentsQueryAgent = NonNullable<
  PartnerNotificationAlternateAgentsQuery['partner']
>['notificationAlternateAgents']['agents'][0];

export type SiteNotificationAlternateAgentsQueryAgent = NonNullable<
  NonNullable<SiteNotificationAlternateAgentsQuery['partner']>['site']
>['notificationAlternateAgents']['agents'][0];

export type NotificationAlternateAgent =
  | PartnerNotificationAlternateAgentsQueryAgent
  | SiteNotificationAlternateAgentsQueryAgent;

const Flex = styled.div`
  display: flex;
  align-items: center;

  &:first-child {
    flex: 0 0 100%;
  }

  ${MediaBreakpoint.PHONE} {
    justify-content: right;
  }
`;

const columns = (inherited?: boolean): ITableCols => [
  {
    title: 'Name',
    key: 'name',
    render: ({ name, avatarUrl }) => (
      <Flex>
        <div>
          <Avatar src={avatarUrl} alt={`${name}`} size={32} shape="circle" />
        </div>
        <div>{name}</div>
      </Flex>
    ),
  },
  {
    title: 'Email',
    key: 'email',
    render: ({ email }) => email,
  },
  {
    key: 'partner_tag',
    render: () => (
      <Tag color="neutral" size="xs">
        Partner
      </Tag>
    ),
    visible: !!inherited,
  },
];

const TopContent = styled.span`
  font-size: ${fontSize('sm')};
  line-height: ${lineHeight('sm')};
`;

interface IUnassignedApplicationNotificationsTableProps {
  partnerId: string;
  siteId?: string;
  loading: boolean;
  assignedAgents: NotificationAlternateAgent[];
  handleRefetch: () => Promise<void>;
  inheritsFromPartner: boolean;
}

export const UnassignedApplicationNotificationsTable = (
  props: IUnassignedApplicationNotificationsTableProps
) => {
  const { partnerId, siteId, loading, assignedAgents, handleRefetch, inheritsFromPartner } = props;

  const previousInheritsFromPartner = usePrevious(inheritsFromPartner);

  const [recentlyAddedAgents, setRecentlyAddedAgents] = useState<NotificationAlternateAgent[]>([]);

  const [isOpen, openModal, closeModal] = useModal(false);
  const addAgent = useAddNotificationAlternateAgent(partnerId, siteId);
  const removeAgent = useRemoveNotificationAlternateAgent(partnerId, siteId);

  const handleAdd = useCallback(() => {
    openModal();
  }, [openModal]);

  const handleDelete = useCallback(
    async (_actionKey: string, record?: NotificationAlternateAgent) => {
      if (record) {
        try {
          await removeAgent(record.id);
          const recentlyAdded = recentlyAddedAgents.find((agent) => agent.id === record.id);
          if (recentlyAdded) {
            setRecentlyAddedAgents((prevState) => [
              ...prevState.filter((agent) => agent.id !== record.id),
            ]);
          }
          await handleRefetch();
        } catch (e: any) {
          notifications.error({ message: 'Error removing agent!' });
        }
      }
    },
    [handleRefetch, recentlyAddedAgents, removeAgent]
  );

  const handleModalSubmit = useCallback(
    async (agent: NotificationAlternateAgent) => {
      try {
        await addAgent(agent.id);
        // do NOT refetch, add newly added agent to TOP of list
        setRecentlyAddedAgents((prev) => [agent, ...prev]);
      } catch (e: unknown) {
        notifications.error({ message: 'Error adding agent!' });
      }
      closeModal();
    },
    [addAgent, closeModal]
  );

  const rowActions: ITableActions = useMemo(() => {
    const rowTableActions: ITableActions = [
      {
        key: 'remove',
        label: 'Remove agent',
        buttonProps: {
          icon: 'delete',
        },
        onAction: handleDelete,
        hidden: () => inheritsFromPartner,
      },
    ];
    return rowTableActions;
  }, [handleDelete, inheritsFromPartner]);

  const topActions: ITableActions = useMemo(() => {
    const topTableActions: ITableActions = [
      {
        key: 'add-agent',
        label: 'Add agent',
        buttonProps: {
          icon: 'plus',
        },
        onAction: handleAdd,
        hidden: () => inheritsFromPartner,
      },
    ];
    return topTableActions;
  }, [handleAdd, inheritsFromPartner]);

  const assignedAgentsData: NotificationAlternateAgent[] = useMemo(() => {
    if (loading || (!assignedAgents.length && !recentlyAddedAgents.length)) {
      return [];
    }
    // remove any null placeholder record
    return uniqBy(compact([...recentlyAddedAgents, ...assignedAgents]), (agent) => agent.id);
  }, [loading, assignedAgents, recentlyAddedAgents]);

  /*
   * cleanup recently added agents list if inheritance is toggled
   * data will be fresh
   */
  useEffect(() => {
    if (inheritsFromPartner !== previousInheritsFromPartner) {
      setRecentlyAddedAgents([]);
    }
  }, [inheritsFromPartner, previousInheritsFromPartner]);

  const topContent = (
    <TopContent>
      The agents in this list will get notified when a notification is triggered for an application
      that is not assigned to an agent.
    </TopContent>
  );

  const emptyText = siteId
    ? 'No agents are assigned to receive notifications. Any agents assigned at the partner level will NOT receive notifications.'
    : 'No agents are assigned to receive notifications.';

  return (
    <>
      <Table<NotificationAlternateAgent>
        caption="Unassigned Applications Notifications Contacts"
        cols={columns(inheritsFromPartner)}
        data={assignedAgentsData}
        rowKey={(item) => item.id}
        rowActions={rowActions}
        topActions={topActions}
        topContent={topContent}
        emptyText={emptyText}
        size="sm"
        loading={loading}
      />
      <UnassignedApplicationNotificationsAddAgentModal
        partnerId={partnerId}
        siteId={siteId}
        isOpen={isOpen}
        loading={loading}
        onClose={closeModal}
        onSubmit={handleModalSubmit}
      />
    </>
  );
};
