import { Tabs } from '@mortgagehippo/ds';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { AllowedStatesEditor } from './allowed-states-editor';
import { FieldEditorFrame } from './field-editor-frame';

export const USStateEditor = ({ name }: { name: string }) => (
  <FieldEditorFrame name={name}>
    {() => (
      <Tabs.Tab id="us_state_options" label="US State Options">
        <EditorTabWrapper size="md">
          <AllowedStatesEditor name={`${name}.allowed_states`} />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
  </FieldEditorFrame>
);
