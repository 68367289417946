import { Input, TextArea, Title, useFormValue, USState, USZipCode, YesNo } from '@mortgagehippo/ds';

export const DocutechFields = () => {
  const [actAsVendor] = useFormValue<boolean>('act_as_vendor');

  const [usingDocumentGeneration] = useFormValue<boolean | undefined>('using_document_generation');

  return (
    <>
      <YesNo.Box name="production_mode" label="Production Mode" required />
      <Input.Box
        name="client_id"
        label="Client ID (aka iss / issuer / vendor identifier)"
        required
      />
      <Input.Box name="sub" label="Sub (e.g. Maxwell)" required />
      <YesNo.Box name="act_as_vendor" label="Act as Vendor (aka 3rd party)" required />

      {actAsVendor ? (
        <Input.Box name="assume_roles_from_sub" label="Assume Roles from Sub" required />
      ) : null}

      <TextArea.Box name="rsa_key" label="RSA Certificate / Key" required />

      <Title level={3}>Document Generation</Title>
      <YesNo.Box
        name="using_document_generation"
        label="Is this client using document generation from Maxwell?"
      />
      {usingDocumentGeneration ? (
        <>
          <Input.Box name="api_key" label="API Key" required />
          <Input.Box name="api_secret" label="API Secret" required />
          <Title level={3}>Lender Address</Title>
          <Input.Box name="lender_data.line_text" label="Address Line Text" required />
          <Input.Box name="lender_data.city_name" label="Address City Name" required />
          <USState.Box name="lender_data.state_code" label="Address State Code" required />
          <USZipCode.Box name="lender_data.postal_code" label="Address Postal Code" required />
        </>
      ) : null}
    </>
  );
};
