import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';
import { useMemo } from 'react';

import {
  QSiteNotificationAlternateAgents,
  UNASSIGNED_APPLICATION_NOTIFICATION_TYPE,
} from './queries';
import { type NotificationAlternateAgent } from './unassigned-application-notifications-table';

type IUseAssignedNotificationAlternateResult = [
  {
    agents: NotificationAlternateAgent[];
    partnerInheritanceDisabled: boolean;
  },
  boolean,
  (inherited?: boolean) => Promise<void>,
];

export const useSiteNotificationAlternateAgents = (
  partnerId: string,
  siteId: string,
  skip: boolean
): IUseAssignedNotificationAlternateResult => {
  const [
    siteAssignedAgentsData,
    siteAssignedAgentsLoading,
    ,
    { refetch: refetchSiteAssignedAgents },
  ] = useQuery(
    QSiteNotificationAlternateAgents,
    {
      partnerId,
      siteId,
      notificationType: UNASSIGNED_APPLICATION_NOTIFICATION_TYPE,
      orderBy: { name: 'asc' },
    },
    {
      fetchPolicy: 'network-only',
      skip,
    }
  );

  const handleRefresh = useSafeCallback(async () => {
    await refetchSiteAssignedAgents({
      partnerId,
      siteId,
      notificationType: UNASSIGNED_APPLICATION_NOTIFICATION_TYPE,
      orderBy: { name: 'asc' },
    });
  });

  const loading = siteAssignedAgentsLoading;

  const agents = useMemo((): NotificationAlternateAgent[] => {
    if (loading || !siteAssignedAgentsData) {
      return [];
    }

    return siteAssignedAgentsData.partner?.site?.notificationAlternateAgents.agents ?? [];
  }, [loading, siteAssignedAgentsData]);

  const partnerInheritanceDisabled =
    !!siteAssignedAgentsData?.partner?.site?.notificationAlternateAgents.partnerInheritanceDisabled;

  const result = {
    agents,
    partnerInheritanceDisabled,
  };

  return [result, loading, handleRefresh];
};
