import styled from 'styled-components';

import { config } from '../config';

const getSuffix = () => {
  let suffix: string;
  switch (config.ENVIRONMENT) {
    case 'staging': {
      suffix = '-staging';
      break;
    }
    case 'development':
    case 'local': {
      suffix = '-develop';
      break;
    }
    default: {
      suffix = '-production';
    }
  }
  return suffix;
};

export const getFaviconSrc = () => {
  const suffix = getSuffix();
  return `/favicon${suffix}.png`;
};

const LogoWithClassName = ({ className }: { className?: string }) => {
  const suffix = getSuffix();
  return <img className={className} alt="Maxwell" src={`/maxwell${suffix}.svg`} />;
};

export const Logo = styled(LogoWithClassName)``;
