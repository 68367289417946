import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo/gql';

const MSetSagaRunnerVersion = graphql(`
  mutation SetSagaRunnerVersion($partnerId: ID!, $siteId: ID, $version: Int!) {
    saga {
      setRunnerVersion(partnerId: $partnerId, siteId: $siteId, version: $version)
    }
  }
`);

export const useSetSagaRunnerVersion = () => {
  const fn = useMutation(MSetSagaRunnerVersion);
  return useCallback(
    async (partnerId: string, siteId: string | undefined, version: number) => {
      const result = await fn({
        partnerId,
        siteId,
        version,
      });

      return result.data!.saga.setRunnerVersion;
    },
    [fn]
  );
};
