import { type ISelectOption } from '@mortgagehippo/ds';
import { TaskType } from '@mortgagehippo/tasks';

export const TEMPLATE_TYPE_OPTIONS: ISelectOption[] = [
  {
    label: 'Request Assets',
    value: TaskType.AssetsTask,
  },
  {
    label: 'Request Document',
    value: TaskType.DocumentSubmissionTask,
  },
  {
    label: 'Request Payment',
    value: TaskType.PaymentTask,
  },
  {
    label: 'Send Document',
    value: TaskType.SendDocumentTask,
  },
  {
    label: 'Show Information',
    value: TaskType.InformationTask,
  },
  {
    label: 'Sign Documents (Hellosign)',
    value: TaskType.HellosignTask,
  },
  {
    label: 'Sign Documents (Docusign)',
    value: TaskType.DocusignTask,
  },
];

export const DEFAULT_DUE_DATE_OPTIONS: ISelectOption[] = [
  {
    label: 'Same day',
    value: 0,
  },
  {
    label: '1 day',
    value: 1,
  },
  {
    label: '2 days',
    value: 2,
  },
  {
    label: '3 days',
    value: 3,
  },
  {
    label: '4 days',
    value: 4,
  },
  {
    label: '5 days',
    value: 5,
  },
  {
    label: '6 days',
    value: 6,
  },
  {
    label: '7 days',
    value: 7,
  },
  {
    label: 'No due date',
    value: null,
  },
];
