import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo/gql';

export const MDeleteRuleSetSaga = graphql(`
  mutation DeleteRuleSetSagas($ruleSetId: ID!, $sagaName: String!) {
    deleteRuleSetSaga(ruleSetId: $ruleSetId, sagaName: $sagaName) {
      id
    }
  }
`);

export const useDeleteRuleSetSaga = () => {
  const deleteRuleSetSaga = useMutation(MDeleteRuleSetSaga);

  return async (ruleSetId: string, sagaName: string) => {
    const result = await deleteRuleSetSaga({ ruleSetId, sagaName });
    return result.data!.deleteRuleSetSaga;
  };
};
