import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo/gql';

export const MUnregisterSiteAuth0TenantMutation = graphql(`
  mutation UnregisterSiteAuth0Tenant($siteId: ID!) {
    unregisterSiteAuth0Tenant(siteId: $siteId)
  }
`);

export const useUnregisterSiteAuth0Tenant = () => {
  const unregisterTenant = useMutation(MUnregisterSiteAuth0TenantMutation);

  return async (siteId: string): Promise<string> => {
    const response = await unregisterTenant({ siteId });
    return response.data!.unregisterSiteAuth0Tenant;
  };
};
