import { type ITableAction, type ITableActions, notifications, useModal } from '@mortgagehippo/ds';
import * as Sentry from '@sentry/browser';
import { difference, sortBy } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';

import { type CredentialsEditorActionType } from '$components/credentials-editor/constants';
import { SnapdocsSubscriptionsModal } from '$components/credentials-editor/snapdocs-subscriptions-modal';
import { TotalExpertCustomFieldsModal } from '$components/credentials-editor/total-expert-custom-fields-modal';

import { CredentialsType, ParentType } from '../../apollo/graphql';
import { CredentialModalContent } from './credential-modal-content';
import {
  CredentialsEditorContentTable,
  type ICredentialExtraActions,
} from './credentials-editor-content-table';
import { EncompassWebhooksModal } from './encompass-webhooks-modal';
import { type ICredential, useCredentialList } from './hooks/use-credential-list';
import { useDeleteCredential } from './hooks/use-delete-credential';

interface ICredentialsEditorContentProps {
  partnerId: string;
  siteId?: string;
  parentType: ParentType;
}

export const CredentialsEditorContent = (props: ICredentialsEditorContentProps) => {
  const { partnerId, siteId, parentType } = props;
  const [credentialModalOpen, openCredentialModal, closeCredentialModal] = useModal(false);
  const [editingCredentialType, setEditingCredentialType] = useState<CredentialsType | undefined>(
    undefined
  );
  const [webhooksModalOpen, openWebhooksModal, closeWebhooksModal] = useModal(false);
  const [
    snapdocsSubscriptionsModalOpen,
    openSnapdocsSubscriptionsModal,
    closeSnapdocsSubscriptionsModal,
  ] = useModal(false);
  const [
    totalExpertCustomFieldsModalOpen,
    openTotalExpertCustomFieldsModal,
    closeTotalExpertCustomFieldsModal,
  ] = useModal(false);

  const [credentialList, credentialListLoading, refetchCredentialList] = useCredentialList(
    partnerId,
    siteId
  );
  const deleteCredential = useDeleteCredential();

  const handleAdd = useCallback(() => {
    openCredentialModal();
  }, [openCredentialModal]);

  const handleCredentialSubmit = useCallback(() => {
    closeCredentialModal(() => {
      setEditingCredentialType(undefined);
      refetchCredentialList();
    });
  }, [closeCredentialModal, refetchCredentialList]);

  const handleRowClick = useCallback(
    (record?: ICredential) => {
      if (!record) {
        return;
      }
      setEditingCredentialType(record.typeName);
      openCredentialModal();
    },
    [openCredentialModal]
  );

  const handleCredentialModalClose = useCallback(() => {
    closeCredentialModal(() => {
      setEditingCredentialType(undefined);
    });
  }, [closeCredentialModal]);

  const handleDelete = useCallback(
    async (record: ICredential) => {
      try {
        await deleteCredential({ partnerId, siteId, credentialType: record.typeName });

        notifications.success({ message: 'Credential successfully deleted.' });
      } catch (e: any) {
        Sentry.captureException(e, { extra: { message: 'Error deleting credential.' } });
        notifications.error({
          message: 'An error occurred deleting this credential. Please try again later.',
        });
        return; // do not refetch credential list on error
      }
      await refetchCredentialList();
    },
    [deleteCredential, partnerId, refetchCredentialList, siteId]
  );

  const handleWebhooksClick = useCallback(() => {
    openWebhooksModal();
  }, [openWebhooksModal]);

  const handleSnapdocsSubscriptionsClick = useCallback(() => {
    openSnapdocsSubscriptionsModal();
  }, [openSnapdocsSubscriptionsModal]);

  const handleTotalExpertCustomFieldsClick = useCallback(() => {
    openTotalExpertCustomFieldsModal();
  }, [openTotalExpertCustomFieldsModal]);

  const dataSource: ICredential[] = useMemo(() => {
    if (!credentialList?.length) {
      return [];
    }

    return sortBy(credentialList, (i) => i.typeName);
  }, [credentialList]);

  const availableCredentialTypes: CredentialsType[] = useMemo(() => {
    const allCredentialTypes = Object.values(CredentialsType);
    if (!credentialList?.length) {
      return allCredentialTypes;
    }
    const credentialTypesInUse = credentialList.map((credential) => credential.typeName);

    return difference(allCredentialTypes, credentialTypesInUse);
  }, [credentialList]);

  const topActions: ITableActions<ICredential> = useMemo(() => {
    const actions: ITableActions<ICredential> = [
      {
        key: 'add-credentials',
        label: parentType === ParentType.Site ? 'Add site-level credentials' : 'Add credentials',
        buttonProps: {
          icon: 'plus',
        },
        disabled: availableCredentialTypes.length === 0,
        hidden: credentialListLoading,
        onAction: handleAdd,
      },
    ];
    return actions;
  }, [availableCredentialTypes.length, handleAdd, parentType, credentialListLoading]);

  const credentialExtraActions: ICredentialExtraActions | undefined = useMemo(() => {
    if (!credentialList) {
      return undefined;
    }

    const extraActions: ICredentialExtraActions = credentialList.reduce(
      (previousValue, credential) => {
        const { typeName } = credential;
        const actionValues: Pick<
          ITableAction<ICredential, CredentialsEditorActionType>,
          'label' | 'iconProps' | 'onAction'
        > = {
          label: '',
        };

        switch (typeName) {
          case CredentialsType.EncompassApi:
            actionValues.label = 'Webhooks';
            actionValues.iconProps = { name: 'redo' };
            actionValues.onAction = handleWebhooksClick;
            break;
          case CredentialsType.Snapdocs:
            actionValues.label = 'Snapdocs Subscriptions';
            actionValues.iconProps = { name: 'redo' };
            actionValues.onAction = handleSnapdocsSubscriptionsClick;
            break;
          case CredentialsType.TotalExpert:
            actionValues.label = 'Custom Fields';
            actionValues.iconProps = { name: 'information' };
            actionValues.onAction = handleTotalExpertCustomFieldsClick;
            break;
          default:
            return previousValue;
        }

        const action: ITableAction<ICredential, CredentialsEditorActionType> = {
          key: typeName,
          hidden: (record) => record?.typeName !== typeName,
          ...actionValues,
        };
        return {
          ...previousValue,
          [typeName]: action,
        };
      },
      {}
    );
    return extraActions;
  }, [
    credentialList,
    handleWebhooksClick,
    handleSnapdocsSubscriptionsClick,
    handleTotalExpertCustomFieldsClick,
  ]);

  return (
    <>
      <CredentialsEditorContentTable
        parentType={parentType}
        dataSource={dataSource}
        loading={credentialListLoading}
        title={(parentType === ParentType.Site && 'Site-Level Credentials') || undefined}
        onRowClick={handleRowClick}
        onEditClick={handleRowClick}
        credentialExtraActions={credentialExtraActions}
        onDelete={handleDelete}
        emptyText="No saved credentials."
        topActions={topActions}
        hideTopBackground
      />

      <CredentialModalContent
        availableCredentialTypes={availableCredentialTypes}
        editingCredentialType={editingCredentialType}
        isOpen={credentialModalOpen}
        onClose={handleCredentialModalClose}
        onSubmit={handleCredentialSubmit}
        partnerId={partnerId}
        siteId={siteId}
      />
      <EncompassWebhooksModal
        partnerId={partnerId}
        siteId={siteId}
        onRequestClose={closeWebhooksModal}
        onOk={closeWebhooksModal}
        isOpen={webhooksModalOpen}
      />
      <SnapdocsSubscriptionsModal
        partnerId={partnerId}
        siteId={siteId}
        onRequestClose={closeSnapdocsSubscriptionsModal}
        onOk={closeSnapdocsSubscriptionsModal}
        isOpen={snapdocsSubscriptionsModalOpen}
      />
      <TotalExpertCustomFieldsModal
        partnerId={partnerId}
        siteId={siteId}
        onRequestClose={closeTotalExpertCustomFieldsModal}
        onOk={closeTotalExpertCustomFieldsModal}
        isOpen={totalExpertCustomFieldsModalOpen}
      />
    </>
  );
};
