import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo/gql';
import { type CreateNotificationProfileInput, type ParentType } from '../../../apollo/graphql';

export const MCreateNotificationProfileMutation = graphql(`
  mutation CreateNotificationProfile(
    $parentType: ParentType!
    $parentId: ID!
    $data: CreateNotificationProfileInput!
  ) {
    createNotificationProfile(parentType: $parentType, parentId: $parentId, data: $data) {
      ...NotificationProfileFragment
    }
  }
`);

export const useCreateNotificationProfile = () => {
  const createNotificationProfile = useMutation(MCreateNotificationProfileMutation, undefined);

  return useCallback(
    async (parentType: ParentType, parentId: string, data: CreateNotificationProfileInput) => {
      await createNotificationProfile({
        parentType,
        parentId,
        data,
      });
    },
    [createNotificationProfile]
  );
};
