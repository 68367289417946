import { FieldArrayList, Select, useFormValue } from '@mortgagehippo/ds';
import { type ReactNode } from 'react';

import { FreezeTasksActionEditor } from './actions/freeze-tasks-action-editor';
import { SavePercentCompleteActionEditor } from './actions/save-percent-complete-action-editor';
import { TriggerEventActionEditor } from './actions/trigger-event-action-editor';
import { UpdateMilestoneActionEditor } from './actions/update-milestone-action-editor';

const actions = [
  {
    label: 'Trigger Event',
    value: 'trigger_event',
    renderLabel: (item: any) => `Trigger Event - ${item.data.event_name}`,
  },
  {
    label: 'Freeze Tasks',
    value: 'freeze_tasks',
    renderLabel: (item: any) => `Freeze Tasks - ${(item.data || []).join(', ')}`,
  },
  {
    label: 'Update Milestone',
    value: 'update_milestone',
    renderLabel: (item: any) => `Update Milestone - ${item.data.milestone}`,
  },
  {
    label: 'Update Application Completion Percentage',
    value: 'save_percent_complete',
    renderLabel: (item: any) =>
      `Update Application Completion Percentage - ${item.data.percent_complete}%`,
  },
  {
    label: 'Mark Application as Completed',
    value: 'completed_application',
    renderLabel: () => 'Mark Application as Completed',
  },
  {
    label: 'Mark Application as Submitted',
    value: 'application_submitted',
    renderLabel: () => 'Mark Application as Submitted',
  },
];

const TaskMetaActionsForm = (props: { blueprint: any }) => {
  const { blueprint } = props;
  const [actionName] = useFormValue(`action`);

  let editor: ReactNode = null;
  switch (actionName) {
    case 'trigger_event': {
      editor = <TriggerEventActionEditor blueprint={blueprint} />;
      break;
    }
    case 'freeze_tasks': {
      editor = <FreezeTasksActionEditor blueprint={blueprint} />;
      break;
    }
    case 'update_milestone': {
      editor = <UpdateMilestoneActionEditor blueprint={blueprint} />;
      break;
    }
    case 'save_percent_complete': {
      editor = <SavePercentCompleteActionEditor blueprint={blueprint} />;
      break;
    }
    default: {
      editor = null;
    }
  }

  return (
    <>
      <Select.Box name="action" label="Action" options={actions} required disabled={!!actionName} />
      {editor}
    </>
  );
};

export const TaskMetaActions = ({ name }: { name: string }) => {
  const [blueprint] = useFormValue('json');

  return (
    <FieldArrayList
      name={name}
      addButtonLabel="Add an action"
      renderItemLabel={(item: any) => {
        const action = actions.find((a) => a.value === item.action);
        if (!action) return '-';
        return action.renderLabel(item);
      }}
      renderForm={() => <TaskMetaActionsForm blueprint={blueprint} />}
      sortable={false}
    />
  );
};
