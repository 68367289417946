import { Input, Tabs, YesNo } from '@mortgagehippo/ds';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { FieldEditorFrame } from './field-editor-frame';

export const SSNEditor = ({ name }: { name: string }) => (
  <FieldEditorFrame
    name={name}
    validations={() => (
      <YesNo.Box name={`${name}.validateSane`} label="Validate Sane" initialValue />
    )}
  >
    {() => (
      <Tabs.Tab id="phone" label="SSN Options">
        <EditorTabWrapper size="md">
          <Input.Box name={`${name}.placeholder`} label="Placeholder" />
          <YesNo.Box
            name={`${name}.enableShowHideValueMasking`}
            label="Enable show/hide value masking"
          />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
  </FieldEditorFrame>
);
