import {
  Checkbox,
  Choice,
  Divider,
  FormError,
  FormSection,
  type FormSubmitHandler,
  HelpButton,
  type IChoiceOption,
  Input,
  ModalForm,
  TextArea,
  useFormValue,
} from '@mortgagehippo/ds';
import { snakeCase } from 'lodash-es';
import { useCallback } from 'react';

import { CodeEditorField } from '$components/code-editor';

import { NotificationMediumType, NotificationProfileType } from '../../../apollo/graphql';

const typeOptions: IChoiceOption[] = [
  {
    label: 'Applicant',
    value: NotificationProfileType.Applicant,
  },
  {
    label: 'Agent',
    value: NotificationProfileType.Agent,
  },
];

const mediumOptions: IChoiceOption[] = [
  {
    label: 'Email',
    value: NotificationMediumType.email,
  },
  {
    label: 'SMS',
    value: NotificationMediumType.sms,
  },
  {
    label: 'In App',
    value: NotificationMediumType.in_app,
  },
];

interface INotificationModalProps {
  title: string;
  initialValues?: any;
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
  editing?: boolean;
  isOpen: boolean;
  loading: boolean;
}

const htmlDescription = 'Use simple HTML. Format titles and buttons with classes (see info).';

const htmlDescriptionTooltip = (
  <>
    <p>
      <em>&lt;h1 class=&quot;heading&quot;&gt;Welcome&lt;/h1&gt;</em>
      <br />A big title suitable for short exciting text at the beginning of the email.
    </p>
    <p>
      <em>&lt;h1&gt;Title&lt;/h1&gt;</em>
      <br />A Heading 1. Suitable separating multiple sections. You can also use h2 and h3 for
      subtitles.
    </p>
    <p>
      <em>
        &lt;p&gt;
        <br />
        &lt;a class=&quot;button&quot; href=&quot;...&quot;&gt;Button&lt;/a&gt;
        <br />
        &lt;/p&gt;
      </em>
      <br />
      Make a link appear like a button. Place it inside a p by itself to appear in a separate line.
    </p>

    <p>
      <em>&lt;p&gt;text&lt;/p&gt;</em>
      <br />
      Put each paragraph inside p tags even if you have one. Headings h1, h2 and h3 must be outside
      paragraphs.
    </p>
  </>
);

const NotificationModalFields = (props: { editing?: boolean }) => {
  const { editing } = props;
  const [mediums] = useFormValue<string[] | undefined>('data.mediums');
  const batchable = useFormValue<boolean | undefined>('data.batchable');
  const emailEnabled = mediums && mediums.includes(NotificationMediumType.email);
  const smsEnabled = mediums && mediums.includes(NotificationMediumType.sms);
  const appEnabled = mediums && mediums.includes(NotificationMediumType.in_app);
  const batchingEnabled = batchable && batchable.includes(true);

  const validateName = useCallback((value: any) => {
    const isSnakeCase = value === snakeCase(value);

    if (!isSnakeCase) {
      return 'Name must be in snake case.';
    }

    return undefined;
  }, []);

  const validateArgs = useCallback((value: any) => {
    try {
      if (!value) {
        return undefined;
      }

      JSON.parse(value);
      return undefined;
    } catch {
      return 'Invalid code';
    }
  }, []);

  return (
    <>
      <Input.Box name="name" label="Name" required validate={validateName} disabled={editing} />
      <Choice.Box
        name="type_name"
        label="Recipient"
        options={typeOptions}
        columns={2}
        required
        disabled={editing}
      />
      <Input.Box name="data.url" label="URL" required />
      <Choice.Box
        name="data.mediums"
        label="Mediums"
        options={mediumOptions}
        multiple
        columns={3}
        required
      />

      <CodeEditorField.Box
        label="Default Message"
        name="data.message.default"
        mode="html"
        height="200px"
        wrap
        required
        description={htmlDescription}
        descriptionTooltip={htmlDescriptionTooltip}
      />
      <Checkbox.Box label="Cc team members?" labelInvisible name="data.cc_team_members">
        Cc team members?
        <HelpButton size="xs" icon="information">
          This functionality only works in notification profiles related to individual application
          files (for which the concept of a &ldquo;team&rdquo; exists).
        </HelpButton>
      </Checkbox.Box>
      <Checkbox.Box label="Notification batchable" labelInvisible name="data.batchable">
        Batchable?
      </Checkbox.Box>
      {batchingEnabled ? (
        <FormSection title="Batched Message">
          <CodeEditorField.Box
            label="Header"
            name="data.batchable_header"
            mode="html"
            height="200px"
            wrap
            description={htmlDescription}
            descriptionTooltip={htmlDescriptionTooltip}
          />
          <CodeEditorField.Box
            label="Footer"
            name="data.batchable_footer"
            mode="html"
            height="200px"
            wrap
            description={htmlDescription}
            descriptionTooltip={htmlDescriptionTooltip}
          />
        </FormSection>
      ) : null}
      {emailEnabled ? (
        <>
          <Divider />
          <FormSection title="Email Settings">
            <Input.Box name="data.email.subject" label="Subject" required />
            <CodeEditorField.Box
              label="Message"
              name="data.message.email"
              mode="html"
              height="200px"
              wrap
              description={htmlDescription}
              descriptionTooltip={htmlDescriptionTooltip}
            />
          </FormSection>
        </>
      ) : null}
      {smsEnabled ? (
        <>
          <Divider />
          <FormSection title="SMS Settings">
            <TextArea.Box name="data.message.sms" label="Message" />
          </FormSection>
        </>
      ) : null}
      {appEnabled ? (
        <>
          <Divider />
          <FormSection title="In App Settings">
            <TextArea.Box name="data.message.in_app" label="Message" />
          </FormSection>
        </>
      ) : null}
      <Divider />
      <CodeEditorField.Box
        label="Arguments"
        name="args"
        mode="json"
        height="200px"
        validate={validateArgs}
      />
    </>
  );
};

const defaultInitialValues = {};
export const NotificationModal = (props: INotificationModalProps) => {
  const { initialValues = defaultInitialValues, editing = false, ...rest } = props;

  return (
    <ModalForm initialValues={initialValues} {...rest} disableOverlayClickClose width="wide">
      <NotificationModalFields editing={editing} />
      <FormError />
    </ModalForm>
  );
};
