export enum ActionType {
  CREATE_PARTNER,
  CREATE_LANDING_PAGE,
  EDIT_LANDING_PAGE,
  DELETE_LANDING_PAGE,
  CREATE_NOTIFICATION_PROFILE,
  EDIT_NOTIFICATION_PROFILE,
  DELETE_NOTIFICATION_PROFILE,
  OVERRIDE_NOTIFICATION_PROFILE,
  EDIT_PARTNER,
  CREATE_SERVICE_JOB,
  UPLOAD_PARTNER_DOCUMENT,
}

export interface ICreatePartnerAction {
  type: ActionType.CREATE_PARTNER;
}

export interface ICreateLandingPageAction {
  type: ActionType.CREATE_LANDING_PAGE;
  siteId: string;
}

export interface IEditLandingPageAction {
  type: ActionType.EDIT_LANDING_PAGE;
  siteId: string;
  landingPageId: string;
}

export interface IDeleteLandingPageAction {
  type: ActionType.DELETE_LANDING_PAGE;
  landingPageId: string;
}

export interface ICreateNotificationProfileAction {
  type: ActionType.CREATE_NOTIFICATION_PROFILE;
  partnerId: string;
  siteId?: string;
}

export interface IEditNotificationProfileAction {
  type: ActionType.EDIT_NOTIFICATION_PROFILE;
  profileId: string;
  partnerId: string;
  siteId?: string;
}

export interface IDeleteNotificationProfileAction {
  type: ActionType.DELETE_NOTIFICATION_PROFILE;
  profileId: string;
}

export interface IOverrideNotificationProfileAction {
  type: ActionType.OVERRIDE_NOTIFICATION_PROFILE;
  profileId: string;
  partnerId: string;
  siteId: string;
}

export interface IEditPartnerAction {
  type: ActionType.EDIT_PARTNER;
  partnerId: string;
}

export interface ICreateServiceJobAction {
  type: ActionType.CREATE_SERVICE_JOB;
  repeatableJob: boolean;
}

export interface IUploadPartnerDocumentAction {
  type: ActionType.UPLOAD_PARTNER_DOCUMENT;
  partnerId: string;
}

export type Action =
  | ICreatePartnerAction
  | IDeleteLandingPageAction
  | IEditLandingPageAction
  | ICreateLandingPageAction
  | ICreateNotificationProfileAction
  | IEditNotificationProfileAction
  | IDeleteNotificationProfileAction
  | IOverrideNotificationProfileAction
  | IEditPartnerAction
  | ICreateServiceJobAction
  | IUploadPartnerDocumentAction;

export interface IActionProps<A extends Action, R = any, C = any> {
  action: A;
  onSubmit: () => void;
  onDone: (result?: R) => void;
  onCancel: (cancelResult?: C) => void;
}

export enum ActionStatus {
  OPEN = 'OPEN',
  SUBMITTED = 'SUBMITTED',
  DONE = 'DONE',
  CANCEL = 'CANCEL',
}

export type ActionListener<T extends Action = Action, R = any> = (
  action: T,
  status: ActionStatus,
  result?: R
) => void;
