import { graphql } from '../../apollo/gql';

export const QPartnerPreQualificationExpirationDays = graphql(`
  query PartnerPreQualificationExpirationDays($partnerId: ID!) {
    partner(id: $partnerId) {
      id
      preQualificationExpirationDays
    }
  }
`);

export const QSitePreQualificationExpirationDays = graphql(`
  query SitePreQualificationExpirationDays($partnerId: ID!, $siteId: ID!) {
    partner(id: $partnerId) {
      id
      site(id: $siteId) {
        preQualificationExpirationDays
      }
    }
  }
`);

export const MSetPreQualificationExpirationDays = graphql(`
  mutation SetPreQualificationExpirationDays($partnerId: ID, $siteId: ID, $days: Int!) {
    setPreQualificationExpirationDays(partnerId: $partnerId, siteId: $siteId, days: $days)
  }
`);

export const MResetPreQualificationExpirationDays = graphql(`
  mutation ResetPreQualificationExpirationDays($siteId: ID!) {
    resetPreQualificationExpirationDays(siteId: $siteId)
  }
`);
