import {
  borderRadius,
  Button,
  Checkbox,
  Choice,
  Empty,
  notifications,
  palette,
  Spacer,
  spacing,
  useModal,
  useModalConfirm,
} from '@mortgagehippo/ds';
import * as Sentry from '@sentry/browser';
import { Affix } from 'antd';
import { isNil, snakeCase } from 'lodash-es';
import { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';

import { sagasReducer } from './reducer';
import { SagasPageContentCreateModal } from './sagas-page-content-create-modal';
import { SagasPageContentEditor } from './sagas-page-content-editor';
import { SagasPageContentMenu } from './sagas-page-content-menu';
import { useSetSagaRunnerVersion } from './set-saga-runner-version';
import { type IEditorSagaArgument } from './types';
import { useDeleteRuleSetSaga } from './use-delete-rule-set-saga';
import { useSagaRunnerVersion } from './use-saga-runner-version';
import { type IRuleSetSaga } from './use-site-sagas';
import { useUpdateSaga } from './use-update-saga';
import { useValidateSaga } from './use-validate-saga';
import { isChanged, isOverridden, scrollToEl, toEditorSagas } from './util';

const Col = styled.div``;

const FiltersBar = styled.div`
  margin-bottom: ${spacing(4)};
  padding: ${spacing(3)};
  background: ${palette('neutral100')};
  border-radius: ${borderRadius(2)};
  display: flex;
  align-items: center;

  ${Col} {
    flex: 1 1 auto;
  }

  ${Col}:last-child {
    flex: 6 6 auto;
    text-align: right;
  }
`;

const Content = styled.div`
  display: flex;

  ${Col} {
    margin: 0 ${spacing(1)};
  }

  ${Col}:first-child {
    flex: 0 0 380px;
    max-width: 380px;
    margin-left: 0;
  }

  ${Col}:last-child {
    flex: 1;
    margin-right: 0;
    background: ${palette('white')};
    padding: ${spacing(3)};
    border-radius: ${borderRadius(3)};
  }
`;

interface ISagasPageContentProps {
  ruleSetId: string;
  sagas: IRuleSetSaga[];
  partnerId: string;
  siteId?: string;
}

export const SagasPageContent = (props: ISagasPageContentProps) => {
  const { ruleSetId, sagas, partnerId, siteId } = props;
  const [activeKey, setActiveKey] = useState<string | undefined>();
  const [overriddenOnly, setOverriddenOnly] = useState<boolean>(false);
  const [modalOpen, openModal, closeModal] = useModal(false);
  const [editorSagas, dispatch] = useReducer(sagasReducer, toEditorSagas(sagas));
  const menuItemRef = useRef(null);
  const shouldScroll = useRef(false);
  const saveSaga = useUpdateSaga();
  const deleteRuleSetSaga = useDeleteRuleSetSaga();
  const validateSaga = useValidateSaga(partnerId, siteId);
  const [runnerVersion, setRunnerVersion] = useState<number | null>(null);
  const [initialRunnerVersion] = useSagaRunnerVersion(partnerId, siteId);
  const updateRunnerVersion = useSetSagaRunnerVersion();

  const [confirmInvalidSave, confirmInvalidSaveDialog] = useModalConfirm(
    'We have detected that you have warnings in your saga',
    {
      type: 'warning',
      cancelButtonLabel: 'Review',
      okButtonLabel: 'Save anyway',
      disableOverlayClickClose: true,
    }
  );

  const hasChanges = useMemo(() => editorSagas.some((s) => isChanged(s)), [editorSagas]);
  const activeSaga = editorSagas.find((s) => s.name === activeKey);
  const visibleSagas = !overriddenOnly ? editorSagas : editorSagas.filter((s) => isOverridden(s));

  useEffect(() => {
    if (overriddenOnly && activeSaga && !isOverridden(activeSaga)) {
      setActiveKey(undefined);
    }
  }, [activeSaga, overriddenOnly]);

  useEffect(() => {
    if (!modalOpen && shouldScroll.current && menuItemRef.current) {
      scrollToEl(menuItemRef.current);
      shouldScroll.current = false;
    }
  }, [modalOpen]);

  useEffect(() => {
    if (!isNil(initialRunnerVersion)) {
      setRunnerVersion(initialRunnerVersion);
    }
  }, [initialRunnerVersion]);

  const validateName = (value: any) => {
    if (editorSagas.some((s) => s.name === snakeCase(value))) {
      return 'Name is already in use.';
    }
    return undefined;
  };

  const handleOverriddenChange = (newValue: boolean) => {
    setOverriddenOnly(newValue);
  };

  const handleCreateClick = () => {
    openModal();
  };

  const handleCreateSubmit = (values: any) => {
    const name = snakeCase(values.name);

    dispatch({ type: 'ADD_SAGA', name });
    setActiveKey(name);
    shouldScroll.current = true;

    closeModal();
  };

  const handleSelect = (key: string) => {
    setActiveKey(key);
  };

  const handleChange = (newValue: string, newArguments: IEditorSagaArgument[]) => {
    if (!activeSaga) {
      return;
    }

    dispatch({
      type: 'UPDATE_SAGA',
      saga: activeSaga,
      newValue,
      newArguments,
    });
  };

  const handleUndo = () => {
    if (!activeSaga || !activeSaga.isEdited) {
      return;
    }

    dispatch({ type: 'RESET_SAGA', saga: activeSaga });
  };

  const handleSave = async () => {
    if (!activeSaga) {
      return;
    }

    // Validate the saga
    const source = activeSaga.currentValue;
    const args = [...activeSaga.systemArguments, ...activeSaga.currentArguments];
    const errors = await validateSaga(source, args);
    if (errors.some((e: any) => e.severity === 'error')) {
      notifications.error({
        message:
          'There are errors that need to be fixed. Please correct them before attempting to save.',
      });
      return;
    }

    if (errors.some((e: any) => e.severity === 'warning')) {
      const confirm = await confirmInvalidSave();
      if (!confirm) return;
    }

    // Save the saga
    const data = {
      arguments: activeSaga.currentArguments,
      codeLines: activeSaga.currentValue,
      name: activeSaga.name,
    };

    try {
      await saveSaga(ruleSetId, data);
      notifications.success({ message: 'Your changes have been saved.' });
    } catch (e) {
      Sentry.captureException(e);
      notifications.error({
        message: 'There was an error processing your request, please try again later.',
      });
    }

    dispatch({ type: 'COMMIT_CHANGES', saga: activeSaga });
  };

  const handleValidate = async () => {
    if (!activeSaga?.currentValue) {
      return [];
    }
    const args = [...activeSaga.systemArguments, ...activeSaga.currentArguments];
    return validateSaga(activeSaga.currentValue!, args);
  };

  const handleDelete = async () => {
    if (!activeSaga || !activeSaga.custom) {
      return;
    }

    if (activeSaga.isNew) {
      // this is unsaved, just remove it from the array
      dispatch({ type: 'DELETE_LOCAL_SAGA', saga: activeSaga });
      setActiveKey(undefined);
      return;
    }

    try {
      await deleteRuleSetSaga(ruleSetId, activeSaga.name);
      notifications.success({ message: 'Your changes have been saved.' });
      dispatch({ type: 'DELETE_REMOTE_SAGA', saga: activeSaga });
      if (!activeSaga.system) {
        setActiveKey(undefined);
      }
    } catch (e) {
      Sentry.captureException(e);
      notifications.error({
        message: 'There was an error processing your request, please try again later',
      });
    }
  };

  const handleChangeRunnerVersion = async (nextRunnerVersion: number | null) => {
    if (isNil(nextRunnerVersion)) return;
    const currentRunnerVersion = runnerVersion;
    setRunnerVersion(nextRunnerVersion);
    try {
      await updateRunnerVersion(partnerId, siteId, nextRunnerVersion);
    } catch (e) {
      setRunnerVersion(currentRunnerVersion);
      Sentry.captureException(e);
      notifications.error({
        message: 'There was an error processing your request, please try again later',
      });
    }
  };

  return (
    <>
      <FiltersBar>
        <Col>
          <Choice.InputBox
            name="runner_version"
            label="Runner Version"
            size="sm"
            value={runnerVersion}
            onChange={handleChangeRunnerVersion}
            columns={2}
            options={[
              { value: 1, label: 'V1' },
              { value: 2, label: 'V2' },
            ]}
            compact
          />
        </Col>
        <Col>
          <Button size="sm" onClick={handleCreateClick} compact>
            Add a new saga
          </Button>
        </Col>
      </FiltersBar>

      <Content>
        <Col>
          <Spacer horizontal={0} vertical={3}>
            <Checkbox.Input
              name="overridden_only"
              size="sm"
              compact
              value={overriddenOnly}
              onChange={handleOverriddenChange}
            >
              Only show overridden sagas
            </Checkbox.Input>
          </Spacer>

          {visibleSagas.length === 0 ? (
            <Empty>No sagas found.</Empty>
          ) : (
            <SagasPageContentMenu
              sagas={visibleSagas}
              activeKey={activeKey}
              onSelect={handleSelect}
              ref={menuItemRef}
            />
          )}
        </Col>
        <Col>
          <Affix offsetTop={20}>
            <SagasPageContentEditor
              activeSaga={activeSaga}
              onChange={handleChange}
              onUndo={handleUndo}
              onSave={handleSave}
              onDelete={handleDelete}
              onValidate={handleValidate}
              key={`${ruleSetId}:${activeKey}`}
            />
          </Affix>
        </Col>
      </Content>
      <SagasPageContentCreateModal
        title="Add New Saga"
        validateName={validateName}
        onRequestClose={closeModal}
        onSubmit={handleCreateSubmit}
        isOpen={modalOpen}
      />
      {confirmInvalidSaveDialog}
      <Prompt
        when={hasChanges}
        message={() =>
          `You have unsaved changes, Are you sure you want to leave this page without saving?`
        }
      />
    </>
  );
};
