import { Icon, UploadInput } from '@mortgagehippo/ds';

import { CodeEditorField } from '$components/code-editor';
import { EditorField } from '$components/editor-field';

import { type IPageComponentFieldsProps } from './types';

const IMAGE_MIME_TYPES = ['image/png', 'image/jpeg'];

export const ContentLeftFields = (props: IPageComponentFieldsProps) => {
  const { languageId, onUpload } = props;

  return (
    <>
      <EditorField.Box name={`resources.${languageId}.content.title`} label="Title" extended />
      <EditorField.Box name={`resources.${languageId}.content.content`} label="Content" extended />
      <UploadInput.Box
        name={`resources.${languageId}.image.image`}
        accept={IMAGE_MIME_TYPES}
        maxItems={1}
        label="Image"
        onUpload={onUpload}
      >
        <Icon size="xxl" name="upload" color="neutral300" /> <br />
        To upload an image, drag it here or <strong>click</strong> to browse files!
      </UploadInput.Box>
      <CodeEditorField.Box mode="scss" name="config.css" label="Custom SCSS" />
    </>
  );
};
