import { useQuery } from '@mortgagehippo/apollo-hooks';
import { type FetchPolicy } from 'apollo-client';

import { graphql } from '../apollo';
import { type PartnersSelectQuery, type SearchCriteria } from '../apollo/graphql';

export const QPartnersSelect = graphql(`
  query PartnersSelect($criteria:  [SearchCriteria!]) {
    partners(
      criteria: $criteria
    ) {
      items {
        value: id
        label: name
      }
    }
  }
`);

export const usePartners = (
  criteria: SearchCriteria[] = [],
  suspend = false,
  fetchPolicy: FetchPolicy = 'cache-first'
): [PartnersSelectQuery["partners"] | undefined, boolean] => {
  const [data, loading] = useQuery(
    QPartnersSelect,
    {
      criteria,
    },
    { suspend, fetchPolicy }
  );

  const partners = !loading && data ? data.partners : undefined;

  return [partners, loading];
};
