import { CheckboxList, FieldBox, useFormValue, USStateFieldStates } from '@mortgagehippo/ds';
import { isPresent } from '@mortgagehippo/util';
import { keyBy } from 'lodash-es';
import { useState } from 'react';

export const AllowedStatesEditor = (props: { name: string }) => {
  const { name } = props;

  const [blueprintAllowedStates, setBlueprintAllowedStates] = useFormValue(name);
  const [value, setValue] = useState<string[]>(() => {
    const allStates = USStateFieldStates.map((s) => s.value);
    return !isPresent(blueprintAllowedStates) ? allStates : blueprintAllowedStates;
  });

  const handleChange = (nextValue: string[]) => {
    const nextValueByKey = keyBy(nextValue);
    const allSelected = USStateFieldStates.every((v) => nextValueByKey[v.value]);

    /*
     * when every state is selected just save an empty array rather than
     * saving every single state on the blueprint. Empty array is another
     * way of saying all of them. (should have probably just saved undefined)
     */
    setBlueprintAllowedStates(allSelected ? [] : nextValue);
    setValue(nextValue);
  };

  return (
    <FieldBox name={`${name}_input`} label="Allowed States">
      <CheckboxList.Input
        name={`${name}_input`}
        onChange={handleChange}
        value={value}
        options={USStateFieldStates}
        allowSelectAll
      />
    </FieldBox>
  );
};
