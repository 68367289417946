import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import {
  MRemoveNotificationAlternateAgent,
  UNASSIGNED_APPLICATION_NOTIFICATION_TYPE,
} from './queries';

export const useRemoveNotificationAlternateAgent = (partnerId: string, siteId?: string) => {
  const removeNotificationAlternateAgent = useMutation(
    MRemoveNotificationAlternateAgent,
    undefined
  );

  return useCallback(
    async (agentId: string) => {
      await removeNotificationAlternateAgent({
        agentId,
        partnerId,
        siteId,
        notificationType: UNASSIGNED_APPLICATION_NOTIFICATION_TYPE,
      });
    },
    [partnerId, removeNotificationAlternateAgent, siteId]
  );
};
