import { type ISelectOption } from '@mortgagehippo/ds';

import { COLUMNS_MAX, COLUMNS_MIN } from './constants';

export const generateColumnOptions = () => {
  const options: ISelectOption[] = [];
  for (let x = COLUMNS_MIN; x <= COLUMNS_MAX; x += 1) {
    options.push({
      label: x,
      value: x,
    });
  }

  return options;
};
