import { Modal, Upload, type UploadHandler } from '@mortgagehippo/ds';

const IMAGE_MIME_TYPES = ['image/png', 'image/jpeg', 'image/svg+xml'];

interface IImagesModalProps {
  onRequestClose: () => void;
  onUpload: UploadHandler;
}

export const ImagesModal = (props: IImagesModalProps) => {
  const { onUpload, ...rest } = props;

  return (
    <Modal isOpen {...rest} hideOkButton>
      <Upload
        name="file"
        onUpload={onUpload}
        accept={IMAGE_MIME_TYPES}
        maxItems={1}
        fileTypeDescription="image"
      />
    </Modal>
  );
};
