import { EncompassDisclosuresTask, type IEncompassDisclosuresTask } from '@mortgagehippo/tasks';
import { type ReactNode } from 'react';

import { TaskPreviewWrapper } from '../task-preview-wrapper';

export interface IEncompassDisclosuresTaskPreviewProps {
  task: IEncompassDisclosuresTask;
  onComplete?: () => Promise<any>;
  renderComplete?: () => ReactNode;
}

export const EncompassDisclosuresTaskPreview = (props: IEncompassDisclosuresTaskPreviewProps) => {
  const { task, renderComplete, onComplete } = props;

  return (
    <TaskPreviewWrapper task={task}>
      {({ nextTask, frame }) => (
        <EncompassDisclosuresTask
          task={nextTask as IEncompassDisclosuresTask}
          applicationFileId="1"
          applicantId="1"
          onComplete={onComplete}
          frame={frame}
          renderComplete={renderComplete}
        />
      )}
    </TaskPreviewWrapper>
  );
};
