import { Input, Select, Tabs, YesNo } from '@mortgagehippo/ds';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { FieldEditorFrame } from './field-editor-frame';

export const PropertyValueEditor = ({ name }: { name: string }) => (
  <FieldEditorFrame name={name} isEntity hasDiscriminator>
    {() => (
      <Tabs.Tab id="currency" label="Currency Options">
        <EditorTabWrapper size="md">
          <Input.Box name={`${name}.placeholder`} label="Placeholder" />
          <YesNo.Box name={`${name}.showPrefix`} label="Show currency symbol?" />
          <Select.Box
            name={`${name}.currency`}
            label="Currency"
            options={[
              {
                label: 'USD',
                value: 'USD',
              },
              {
                label: 'EUR',
                value: 'EUR',
              },
              {
                label: 'CAD',
                value: 'CAD',
              },
              {
                label: 'GBP',
                value: 'GBP',
              },
              {
                label: 'MXN',
                value: 'MXN',
              },
              {
                label: 'BGN',
                value: 'BGN',
              },
            ]}
          />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
  </FieldEditorFrame>
);
