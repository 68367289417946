import { useQuery } from '@mortgagehippo/apollo-hooks';
import { Format, Icon, type ITableActions, type ITableCols, Table } from '@mortgagehippo/ds';
import { UnreachableCaseError } from '@mortgagehippo/util';
import { useCallback, useMemo } from 'react';
import { type RouteComponentProps } from 'react-router';

import { ActionStatus, ActionType, useActionEffect, useDispatchAction } from '$components/actions';

import {
  NotificationMediumType,
  type PartnerNotificationProfilesListQuery,
} from '../../../apollo/graphql';
import { usePartner } from '../../../hooks/use-partner';
import { Content, Layout } from '../../../layouts/main';
import { QPartnerNotificationProfilesList } from './queries';

export type INotificationProfile = NonNullable<
  PartnerNotificationProfilesListQuery['partner']
>['notificationProfiles'][0];

enum PartnerNotificationsPageActionType {
  EDIT = 'edit',
  DELETE = 'delete',
}

const columns: ITableCols<INotificationProfile> = [
  {
    title: 'Name',
    key: 'name',
    render: (t) => t.name,
  },
  {
    title: 'Type',
    key: 'type',
    render: (t) => t.typeName,
  },
  {
    title: 'Email',
    key: NotificationMediumType.email,
    align: 'center',
    render: ({ data }) =>
      data.mediums.includes(NotificationMediumType.email) ? <Icon name="check" size="sm" /> : null,
  },
  {
    title: 'SMS',
    key: NotificationMediumType.sms,
    align: 'center',
    render: ({ data }) =>
      data.mediums.includes(NotificationMediumType.sms) ? <Icon name="check" size="sm" /> : null,
  },
  {
    title: 'App',
    key: NotificationMediumType.in_app,
    align: 'center',
    render: ({ data }) =>
      data.mediums.includes(NotificationMediumType.in_app) ? <Icon name="check" size="sm" /> : null,
  },
  {
    title: 'Last Updated',
    key: 'updated',
    render: ({ createdAt, updatedAt }) => (
      <Format.Date format="fromnow" value={updatedAt || createdAt} />
    ),
  },
];

interface IPartnerNotificationsPageRouteParams {
  partnerId: string;
}

type IPartnerNotificationsPageProps = RouteComponentProps<IPartnerNotificationsPageRouteParams>;

export const PartnerNotificationsPage = (props: IPartnerNotificationsPageProps) => {
  // TODO: less duplicated code with site notifications page
  const { match } = props;
  const { params } = match;
  const { partnerId } = params;

  const [partner] = usePartner(partnerId);
  const dispatch = useDispatchAction();

  const [data, loading, , { refetch }] = useQuery(
    QPartnerNotificationProfilesList,
    {
      partnerId,
    },
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  useActionEffect(
    async (action) => {
      if (
        action.type !== ActionType.CREATE_NOTIFICATION_PROFILE &&
        action.type !== ActionType.EDIT_NOTIFICATION_PROFILE &&
        action.type !== ActionType.DELETE_NOTIFICATION_PROFILE
      ) {
        return;
      }

      refetch();
    },
    undefined,
    ActionStatus.DONE
  );

  const handleAdd = useCallback(() => {
    dispatch({
      type: ActionType.CREATE_NOTIFICATION_PROFILE,
      partnerId,
    });
  }, [dispatch, partnerId]);

  const handleClickRow = useCallback(
    (record: INotificationProfile) => {
      dispatch({
        type: ActionType.EDIT_NOTIFICATION_PROFILE,
        partnerId,
        profileId: record.id,
      });
    },
    [dispatch, partnerId]
  );

  const handleDelete = useCallback(
    async (record: INotificationProfile) => {
      dispatch({
        type: ActionType.DELETE_NOTIFICATION_PROFILE,
        profileId: record.id,
      });
    },
    [dispatch]
  );

  const handleAction = useCallback(
    async (actionKey: string, record: INotificationProfile) => {
      const actionType = actionKey as PartnerNotificationsPageActionType;

      switch (actionType) {
        case PartnerNotificationsPageActionType.EDIT:
          handleClickRow(record);
          break;
        case PartnerNotificationsPageActionType.DELETE:
          await handleDelete(record);
          break;
        default: {
          throw new UnreachableCaseError(actionType);
        }
      }
    },
    [handleClickRow, handleDelete]
  );

  const actions: ITableActions<INotificationProfile> = useMemo(
    () => [
      {
        key: 'options',
        label: 'Options',
        buttonProps: {
          icon: 'menu-dots',
        },
        onGroupAction: handleAction,
        actions: [
          {
            key: PartnerNotificationsPageActionType.EDIT,
            label: 'Edit',
            iconProps: {
              name: 'edit',
            },
          },
          {
            key: PartnerNotificationsPageActionType.DELETE,
            label: 'Delete',
            iconProps: {
              name: 'delete',
            },
          },
        ],
      },
    ],
    [handleAction]
  );

  const topActions: ITableActions<INotificationProfile> = useMemo(
    () => [
      {
        key: 'add-profile',
        label: 'Add profile',
        buttonProps: {
          icon: 'plus',
        },
        onAction: handleAdd,
      },
    ],
    [handleAdd]
  );

  const partnerName = partner?.name || '';

  const dataSource = data?.partner?.notificationProfiles || [];

  return (
    <Layout pageTitle={`${partnerName} - Notifications`}>
      <Content title="Notifications" subTitle={partnerName}>
        <Table<INotificationProfile>
          caption="Partner Notification Profiles"
          data={dataSource}
          rowKey={(item) => item.id}
          cols={columns}
          loading={loading}
          onRowClick={handleClickRow}
          rowActions={actions}
          topActions={topActions}
          emptyText="No saved notification profiles."
          size="sm"
          hideTopBackground
          alwaysShowRowActions
        />
      </Content>
    </Layout>
  );
};
