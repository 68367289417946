import {
  Button,
  Constant,
  FieldBox,
  fontSize,
  FormError,
  type FormSubmitHandler,
  Icon,
  Input,
  type ISelectOption,
  lineHeight,
  ModalForm,
  Select,
  SimpleFieldAutoArray,
  spacing,
} from '@mortgagehippo/ds';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { type IExternalMilestoneKey } from '../../partners/lender-milestones/use-external-milestone-keys';
import { type IMilestone } from './queries';

type IMilestoneModalFormValue = Pick<IMilestone, 'id' | 'name' | 'key'> & {
  externalMilestoneKeys: string[] | null;
};

export interface IMilestoneModalFormValues {
  milestones?: IMilestoneModalFormValue[];
}

const Row = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: ${spacing(4)};
`;

const Col = styled.div<{ flex?: number; isDraggableIcon?: boolean }>`
  flex: ${({ flex }) => (flex === undefined ? 1 : flex)};
  flex-basis: ${({ flex }) => (flex === undefined ? '100%' : 'auto')};
  margin-right: 10px;

  ${(p) =>
    p.isDraggableIcon &&
    css`
      margin-top: ${spacing(1)};
    `}

  &:last-child {
    margin-right: 0;
  }
`;

const StyledIcon = styled(Icon)<{ isHidden?: boolean }>`
  ${(p) =>
    p.isHidden &&
    css`
      visibility: hidden;
    `}
`;

const InfoMessage = styled.div`
  margin: 0 ${spacing(5)};
  font-size: ${fontSize('sm')};
  line-height: ${lineHeight('sm')};
`;

const validator = (value: IMilestoneModalFormValue, allValues: IMilestoneModalFormValues) => {
  const { milestones } = allValues;
  if (!milestones?.length) {
    return undefined;
  }

  const { name, key } = value;

  let errors;

  if (name && name.length > 24) {
    errors = {
      name: 'Name cannot be longer than 24 characters',
    };
  }

  if (milestones.filter((milestone) => milestone.name === name).length > 1) {
    errors = {
      name: `${errors?.name ? `${errors.name} - ` : ''}Duplicate label`,
    };
  }

  if (milestones.filter((milestone) => milestone.key === key).length > 1) {
    errors = {
      ...errors,
      key: 'Duplicate internal key',
    };
  }

  return errors;
};

interface IEditMilestoneModalProps {
  isOpen: boolean;
  initialValues?: IMilestoneModalFormValues;
  onSubmit: FormSubmitHandler<IMilestoneModalFormValues>;
  onRequestClose: () => void;
  loading: boolean;
  externalKeys: IExternalMilestoneKey[];
}

export const MilestoneEditModal = (props: IEditMilestoneModalProps) => {
  const { onSubmit, onRequestClose, isOpen, loading, initialValues = {}, externalKeys } = props;

  const externalKeySelectOptions = useMemo(() => {
    if (loading || !externalKeys.length) {
      return [];
    }
    const selectOptions: ISelectOption[] = externalKeys.map((externalKey) => ({
      label: externalKey.name,
      value: externalKey.name,
    }));
    return selectOptions;
  }, [externalKeys, loading]);

  return (
    <ModalForm<IMilestoneModalFormValues>
      title="Edit Milestones"
      isOpen={isOpen}
      loading={loading}
      onSubmit={onSubmit}
      onRequestClose={onRequestClose}
      initialValues={initialValues}
      okButtonLabel="Save milestones"
      width="wide"
    >
      <FieldBox name="milestones" label="Milestones" labelInvisible>
        <SimpleFieldAutoArray
          name="milestones"
          sortable
          presentFields={['name', 'key']}
          render={({ name: itemName, handleProps, onRemove, index, isLastItem, sortable }) => (
            <Row key={itemName}>
              <Col flex={0} isDraggableIcon {...(handleProps || {})}>
                <StyledIcon name="drag-handle" isHidden={!sortable} />
              </Col>
              <Col>
                <Constant name={`${itemName}.id`} />
                <Input.Box
                  name={`${itemName}.name`}
                  label="Label"
                  placeholder="Label"
                  size="sm"
                  labelInvisible
                  hideRequiredLabel
                  required={index !== 0 && !isLastItem}
                />
              </Col>
              <Col>
                <Input.Box
                  name={`${itemName}.key`}
                  label="Internal Key"
                  placeholder="Internal Key"
                  size="sm"
                  labelInvisible
                  hideRequiredLabel
                  required={index !== 0 && !isLastItem}
                />
              </Col>
              <Col>
                <Select.Box
                  name={`${itemName}.externalMilestoneKeys`}
                  label="External Milestone Keys"
                  placeholder="Map External Milestone Key"
                  options={externalKeySelectOptions}
                  notFoundContent="No External Milestone Keys Found"
                  size="sm"
                  multiple
                  labelInvisible
                />
              </Col>
              <Col flex={0}>
                <Button
                  icon="delete"
                  iconButton
                  size="xs"
                  type="danger"
                  importance="tertiary"
                  onClick={onRemove}
                />
              </Col>
            </Row>
          )}
          validateItem={validator}
        />
      </FieldBox>
      <FormError alertSize="sm" />
      <InfoMessage>
        If you delete a Borrower Milestone or change its internal key, make sure to check the
        Blueprint for any task actions that may trigger that milestone key.
      </InfoMessage>
    </ModalForm>
  );
};
