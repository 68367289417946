import { Select } from '@mortgagehippo/ds';

import { type BlueprintType } from '../../apollo/graphql';
import { useBlueprints } from '../../hooks/use-blueprints';

interface IBlueprintSelectProps {
  partnerId: string;
  name: string;
  label: string;
  description?: string;
  required?: boolean;
  type?: BlueprintType;
}

export const BlueprintsSelect = (props: IBlueprintSelectProps) => {
  const { name, label, description, required, partnerId, type } = props;
  const [blueprints] = useBlueprints(partnerId, type);

  const options = blueprints.map((blueprint) => ({
    value: blueprint.id,
    label: blueprint.name || `Blueprint ${blueprint.id}`,
  }));

  return (
    <Select.Box
      name={name}
      label={label}
      description={description}
      options={options}
      required={required}
    />
  );
};
