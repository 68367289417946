import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { MDeleteSnapdocsSubscriptionMutation } from '../queries';

export const useDeleteSnapdocsSubscription = () => {
  const deleteSubscription = useMutation(MDeleteSnapdocsSubscriptionMutation);

  return useCallback(
    async (id: string, pathSlug: string, partnerId: string, siteId?: string) => {
      await deleteSubscription({
        id,
        pathSlug,
        partnerId,
        siteId,
      });
    },
    [deleteSubscription]
  );
};
