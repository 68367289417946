import {
  Button,
  Currency,
  FieldBox,
  Input,
  SimpleFieldAutoArray,
  spacing,
} from '@mortgagehippo/ds';
import { useCallback } from 'react';
import styled from 'styled-components';

const LINE_ITEM_PRESENT_FIELDS = ['description', 'amount'];

const LineItemRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${spacing(3)};

  &:first-child {
    margin-top: 0;
  }
`;

const LineItemCol = styled.div<{ flex?: number }>`
  flex: ${({ flex }) => (flex === undefined ? 1 : flex)};
  flex-basis: auto;
  margin-right: ${spacing(3)};

  &:last-child {
    margin-right: 0;
  }
`;

export const TemplatesFormPaymentTaskFields = () => {
  const handleValidateItem = useCallback((value: any) => {
    if (!value.description || !value.amount) {
      return 'Invalid Item';
    }

    return undefined;
  }, []);

  return (
    <FieldBox
      name="data.line_items"
      label="Payment Items"
      description="The items you are requesting payment for."
    >
      <SimpleFieldAutoArray
        name="data.line_items"
        validateItem={handleValidateItem}
        presentFields={LINE_ITEM_PRESENT_FIELDS}
        render={({ name: itemName, index, value, error, onRemove, active }) => (
          <LineItemRow key={value.id || index}>
            <LineItemCol>
              <Input
                name={`${itemName}.description`}
                placeholder="Item Description"
                compact
                aria-label="Item Description"
                error={!active && !!error}
              />
            </LineItemCol>
            <LineItemCol flex={0}>
              <Currency
                name={`${itemName}.amount`}
                placeholder="Amount"
                compact
                aria-label="Amount"
                error={!active && !!error}
                decimalMode="allow"
              />
            </LineItemCol>
            <LineItemCol flex={0}>
              <Button
                icon="delete"
                iconButton
                size="xs"
                type="danger"
                importance="tertiary"
                onClick={onRemove}
                compact
              />
            </LineItemCol>
          </LineItemRow>
        )}
      />
    </FieldBox>
  );
};
