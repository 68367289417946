import { useQuery } from '@mortgagehippo/apollo-hooks';
import {
  ButtonLink,
  DatePicker,
  FormSection,
  FormSpy,
  type FormState,
  type FormSubmitHandler,
  Input,
  type ISelectOption,
  ModalWizard2 as ModalWizard,
  Numeric,
  Select,
  Time,
} from '@mortgagehippo/ds';
import { useCallback, useMemo, useState } from 'react';

import { JobType } from '../../../apollo/graphql';
import { QPartnersList } from '../../../pages/partners/queries';
import { reindexModelOptions } from './constants';

interface ICreateServiceJobWizardProps {
  initialValues?: any;
  onRequestClose: () => void;
  onSubmit: FormSubmitHandler;
  isOpen: boolean;
  repeatableJob?: boolean;
}

const jobTypeOptions: ISelectOption[] = [
  {
    label: 'Reindex',
    value: JobType.REINDEX,
  },
];

const STEP = 'create_service_job';

export const CreateServiceJobWizard = (props: ICreateServiceJobWizardProps) => {
  const { initialValues, repeatableJob, ...rest } = props;

  const [isReindexJob, setIsReindexJob] = useState<boolean>(true);

  const [data, loading] = useQuery(
    QPartnersList,
    { page: 1, perPage: 1000, orderBy: { name: 'asc' } },
    {
      suspend: false,
      fetchPolicy: 'network-only',
    }
  );

  const handleFormChange = useCallback(({ values }: FormState<any>) => {
    setIsReindexJob(values.jobType === JobType.REINDEX);
  }, []);

  const partnersOptions = useMemo(() => {
    if (!data?.partners?.items.length) {
      return [];
    }
    const options: ISelectOption[] = data.partners.items.map((item) => ({
      value: item.id,
      label: `${item.id} - ${item.name}`,
    }));
    return options;
  }, [data]);

  const formInitialValues = useMemo(
    () => ({
      jobType: JobType.REINDEX,
      ...initialValues,
    }),
    [initialValues]
  );

  const delayDescription = (
    <>
      Delay job to future date and time.
      {!!repeatableJob && (
        <>
          <br />
          <br />- If <u>Cron Schedule</u> is specified the cron job will be delayed until that time.
          <br />- If <u>Repeat Interval</u> is specified, delay does <b>NOT</b> work so leave this
          empty.
        </>
      )}
    </>
  );

  return (
    <ModalWizard
      title={`Create ${repeatableJob ? 'Repeatable' : 'Service'} Job`}
      step={STEP}
      initialValues={formInitialValues}
      {...rest}
    >
      <FormSpy onChange={handleFormChange} subscription={{ values: true }} />
      <ModalWizard.Step id={STEP}>
        <FormSection title="General">
          <Select.Box name="jobType" label="Job Type" options={jobTypeOptions} required />
          {isReindexJob ? (
            <>
              <Select.Box name="model" label="Model" options={reindexModelOptions} required />
              <Select.Box
                name="partner"
                label="Partner"
                options={partnersOptions}
                loading={loading}
                searchable
              />
            </>
          ) : null}
        </FormSection>
        {!!repeatableJob && (
          <FormSection
            title="Repeat Options"
            description={<b>Use EITHER Cron OR Repeat Interval</b>}
          >
            <ButtonLink target="_blank" href="https://crontab.cronhub.io/">
              Cron expression generator
            </ButtonLink>
            <Input.Box
              name="cronSchedule"
              label="Cron Schedule"
              labelTooltip="Use Repeat Interval option instead of */20 notation in cron expression, or explicitly define the values eg. 0,20,40 * * *"
            />
            <Numeric.Box name="repeatMinutesInterval" label="Repeat Interval (Minutes)" />
          </FormSection>
        )}
        <FormSection title="Delay" description={delayDescription}>
          <DatePicker.Box name="startDate" label="Start Date" />
          <Time.Box
            name="startTime"
            label="Start Time"
            description="24hr format. Your computer's local time."
          />
        </FormSection>
      </ModalWizard.Step>
    </ModalWizard>
  );
};
