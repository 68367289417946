import { type FormSubmitHandler, notifications } from '@mortgagehippo/ds';
import { trim } from 'lodash-es';
import { useCallback, useMemo } from 'react';

import { type DefaultSmartviewConfigInput, type SmartviewModelType } from '../../apollo/graphql';
import { PipelineEditor } from './pipeline-editor';
import { useDefaultSmartviewConfig } from './use-default-smartview-config';
import { useOverrideDefaultSmartview } from './use-override-default-smartview';
import { useRevertDefaultSmartview } from './use-revert-default-smartview';

interface IPipelinePageProps {
  partnerId: string;
  model: SmartviewModelType;
}

export const PipelinePage = (props: IPipelinePageProps) => {
  const { partnerId, model } = props;

  const [{ rawConfig, isOverridden }, loading, refreshConfig] = useDefaultSmartviewConfig(
    partnerId,
    model
  );

  const overrideDefaultSmartview = useOverrideDefaultSmartview();
  const revertDefaultSmartview = useRevertDefaultSmartview();

  const handleSave: FormSubmitHandler = useCallback(
    async (values: any) => {
      try {
        const { columns, searchFields, exportFields } = values;

        const trimmedColumns = trim(columns);
        const trimmedSearchFields = searchFields ? trim(searchFields) : undefined;
        const trimmedExportFields = exportFields ? trim(exportFields) : undefined;

        const nextColumns = JSON.parse(trimmedColumns);
        const nextSearchFields = trimmedSearchFields ? JSON.parse(trimmedSearchFields) : undefined;
        const nextExportFields = trimmedExportFields ? JSON.parse(trimmedExportFields) : undefined;

        const nextConfig: DefaultSmartviewConfigInput = {
          columns: nextColumns,
          searchFields: nextSearchFields,
          exportFields: nextExportFields,
        };

        await overrideDefaultSmartview(partnerId, model, nextConfig);

        await refreshConfig();

        notifications.success({
          message: 'Your changes were saved.',
        });
      } catch (e) {
        notifications.error({
          message: 'There was an unexpected error overriding the pipeline',
        });
      }
    },
    [model, overrideDefaultSmartview, partnerId, refreshConfig]
  );

  const handleRestore = useCallback(async () => {
    try {
      await revertDefaultSmartview(partnerId, model);

      await refreshConfig();

      notifications.success({
        message: 'The pipeline was restored to default.',
      });
    } catch (e) {
      notifications.error({
        message: 'There was an unexpected error reverting the pipeline to default',
      });
    }
  }, [model, partnerId, refreshConfig, revertDefaultSmartview]);

  const initialValues = useMemo(() => {
    if (!rawConfig || !isOverridden) {
      return {};
    }

    const { columns, searchFields, exportFields } = rawConfig;

    const initialColumns = JSON.stringify(columns, null, 4);
    const initialSearchFields = searchFields ? JSON.stringify(searchFields, null, 4) : undefined;
    const initialExportFields = exportFields ? JSON.stringify(exportFields, null, 4) : undefined;

    return {
      columns: initialColumns,
      searchFields: initialSearchFields,
      exportFields: initialExportFields,
    };
  }, [rawConfig, isOverridden]);

  if (loading || !rawConfig) {
    return null;
  }

  return (
    <PipelineEditor
      partnerId={partnerId}
      model={model}
      initialValues={initialValues}
      onSave={handleSave}
      onRestore={isOverridden ? handleRestore : undefined}
    />
  );
};
