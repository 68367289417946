import { AssetsTask, type IAssetsTask } from '@mortgagehippo/tasks';
import { cloneDeep, merge } from 'lodash-es';
import { type ReactNode, useState } from 'react';

import { TaskPreviewWrapper } from '../task-preview-wrapper';

export interface ICreditPullTaskPreviewProps {
  task: IAssetsTask;
  onComplete?: () => Promise<any>;
  renderComplete?: () => ReactNode;
}

export const AssetsTaskPreview = (props: ICreditPullTaskPreviewProps) => {
  const { task, onComplete, renderComplete } = props;
  const [answers, setAnswers] = useState({});
  const handleLinkedAccounts = () => undefined;
  const handleSave = async (change: any) => {
    const nextAnswers: any = cloneDeep(answers);
    const { assets: _changeAssets, ...otherChanges } = change;
    merge(nextAnswers, otherChanges);
    nextAnswers.assets ||= [];

    (change.assets || []).forEach((nextChange: any) => {
      const ix = (nextAnswers.assets || []).findIndex(
        (a: any) => a.id === nextChange.id || a.__id === nextChange.__id
      );

      if (ix >= 0 && nextChange.__deleted) {
        nextAnswers.assets.splice(ix, 1);
      } else if (ix >= 0) {
        merge(nextAnswers.assets[ix], nextChange);
      } else {
        nextAnswers.assets.push(nextChange);
      }
    });

    setAnswers(nextAnswers);
  };

  const handleComplete = async () => {
    if (onComplete) {
      return onComplete();
    }
    return undefined;
  };

  return (
    <TaskPreviewWrapper task={task}>
      {({ nextTask, frame }) => (
        <AssetsTask
          answers={answers}
          applicationFileId="123"
          applicantId="123"
          task={nextTask as IAssetsTask}
          frame={frame}
          onLinkedAccounts={handleLinkedAccounts}
          onSave={handleSave}
          onComplete={handleComplete}
          renderComplete={renderComplete}
        />
      )}
    </TaskPreviewWrapper>
  );
};
