import { type FormSubmitHandler } from '@mortgagehippo/ds';
import { set } from 'lodash-es';
import { useCallback } from 'react';

import { PALETTE_KEY_PREFIX, PALETTE_LEVELS } from '../constants';
import { type PaletteGroup } from '../types';
import { PaletteImportModal } from './palette-import-modal';

interface IPaletteImportModalContainerProps {
  onRequestClose: () => void;
  isOpen: boolean;
  group: PaletteGroup;
  onSubmit: FormSubmitHandler;
}

export const PaletteImportModalContainer = (props: IPaletteImportModalContainerProps) => {
  const { onRequestClose, isOpen, group, onSubmit } = props;

  const handleSubmit: FormSubmitHandler = useCallback(
    async (values, ...rest) => {
      const submitValues = {};

      const palette = JSON.parse(values.palette);
      const hexCodes: string[] = Object.values(palette);

      PALETTE_LEVELS.forEach((level, index) => {
        set(submitValues, `${PALETTE_KEY_PREFIX}${group}${level}`, hexCodes[index]!.toUpperCase());
      });

      return onSubmit(submitValues, ...rest);
    },
    [group, onSubmit]
  );

  return (
    <PaletteImportModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onSubmit={handleSubmit}
      group={group}
    />
  );
};
