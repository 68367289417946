import {
  Input,
  type ISelectOption,
  Select,
  TextArea,
  Title,
  useFormValue,
  YesNo,
} from '@mortgagehippo/ds';
import { useEffect } from 'react';

import { Bureus, CreditPullFieldsBureus } from './credit-pull-fields-bureus';

enum CreditPullProviderName {
  cbc = 'cbc',
  core_logic = 'core_logic',
  informative = 'informative',
  meridian_link = 'meridian_link',
  mortgagehippo = 'mortgagehippo',
  factual_data = 'factual_data',
  xactus = 'xactus',
  partners = 'partners',
}

const providerOptions: ISelectOption[] = [
  {
    label: 'Meridian Link',
    value: CreditPullProviderName.meridian_link,
  },
  {
    label: 'CBC',
    value: CreditPullProviderName.cbc,
  },
  {
    label: 'Informative',
    value: CreditPullProviderName.informative,
  },
  {
    label: 'Factual Data',
    value: CreditPullProviderName.factual_data,
  },
  {
    label: 'MortgageHippo',
    value: CreditPullProviderName.mortgagehippo,
  },
  {
    label: 'CoreLogic / Credco',
    value: CreditPullProviderName.core_logic,
  },
  {
    label: 'Xactus/Avantus',
    value: CreditPullProviderName.xactus,
  },
  {
    label: 'Partners / Advantage',
    value: CreditPullProviderName.partners,
  },
];

const meridianLinkCraResellerOptions: ISelectOption[] = [
  {
    label: 'ADVANTAGE CREDIT',
    value: 1,
  },
  {
    label: 'ADVANTAGE PLUS',
    value: 2,
  },
  {
    label: 'ALLIANCE 2020',
    value: 3,
  },
  {
    label: 'AMERICAN REPORTING COMPANY',
    value: 4,
  },
  {
    label: 'BIRCHWOOD CREDIT SERVICES, INC.',
    value: 5,
  },
  {
    label: 'CBFS BUSINESS SOLUTIONS',
    value: 6,
  },
  {
    label: 'CERTIFIED CREDIT LINK',
    value: 7,
  },
  {
    label: 'CERTIFIED CREDIT REPORTING',
    value: 8,
  },
  {
    label: 'CIC CREDIT',
    value: 9,
  },
  {
    label: 'CIS INFORMATION SERVICES',
    value: 10,
  },
  {
    label: 'CISCO CREDIT',
    value: 11,
  },
  {
    label: 'CLEAR CHOICE CREDIT',
    value: 12,
  },
  {
    label: 'CREDIT BUREAU SERVICES',
    value: 13,
  },
  {
    label: 'CREDIT LINK',
    value: 14,
  },
  {
    label: 'CREDIT PLUS',
    value: 15,
  },
  {
    label: 'CREDIT TECHNOLOGIES, INC',
    value: 16,
  },
  {
    label: 'CREDIT TECHNOLOGY, INC',
    value: 17,
  },
  {
    label: 'DCI CREDIT',
    value: 18,
  },
  {
    label: 'ISC',
    value: 19,
  },
  {
    label: 'KEWANEE CREDIT BUREAU',
    value: 20,
  },
  {
    label: 'MERCHANTS AND PROFESSIONAL CREDIT BUREAU, INC.',
    value: 21,
  },
  {
    label: 'MERCHANTS CREDIT BUREAU',
    value: 22,
  },
  {
    label: 'MERCHANTS CREDIT BUREAU (SAV)',
    value: 23,
  },
  {
    label: 'MIDWEST MORTGAGE CREDIT SERVICES',
    value: 24,
  },
  {
    label: 'PREMIUM CREDIT BUREAU',
    value: 25,
  },
  {
    label: 'PREMIUM CREDIT BUREAU',
    value: 26,
  },
  {
    label: 'RAPID CREDIT REPORTS',
    value: 27,
  },
  {
    label: 'SARMA',
    value: 28,
  },
  {
    label: 'SERVICE 1ST INFOSYSTEM',
    value: 29,
  },
  {
    label: 'SETTLEMENTONE CREDIT CORP.',
    value: 30,
  },
  {
    label: 'THE CREDIT BUREAU.COM',
    value: 31,
  },
  {
    label: 'UNITED ONE RESOURCES',
    value: 32,
  },
  {
    label: 'UNIVERSAL CREDIT SERVICES',
    value: 33,
  },
];

export const CreditPullFields = () => {
  const [providerName] = useFormValue<CreditPullProviderName | undefined>('provider_name');

  const [usingMeridianLinkCraReseller] = useFormValue<boolean | undefined>(
    'meridian_link_data.using_cra_reseller'
  );

  const [, setBureus] = useFormValue('bureaus');

  const handleValidateBureaus = (value: any) => {
    if (value?.[Bureus.equifax] || value?.[Bureus.experian] || value?.[Bureus.transunion]) {
      return undefined;
    }

    return 'You must choose at least one bureau.';
  };

  useEffect(() => {
    // When using a meridian link reseller we reset the bureau selection to all bureaus
    if (usingMeridianLinkCraReseller) {
      setBureus({
        [Bureus.equifax]: true,
        [Bureus.experian]: true,
        [Bureus.transunion]: true,
      });
    }
  }, [setBureus, usingMeridianLinkCraReseller]);

  return (
    <>
      <Select.Box name="provider_name" label="Provider Name" options={providerOptions} required />
      <Input.Box
        name="access_info.login_account_identifier"
        label="Login Account Identifier"
        required
      />
      <Input.Box
        name="access_info.login_account_password"
        label="Login Account Password"
        required
      />
      {!usingMeridianLinkCraReseller && (
        <YesNo.Box name="access_info.production_mode" label="Production Mode" />
      )}
      {providerName === CreditPullProviderName.core_logic && (
        <>
          <Title level={3}>CoreLogic / Credco Data</Title>
          <Input.Box name="core_logic_data.gateway_id" label="Gateway ID" required />
          <TextArea.Box
            name="core_logic_data.digital_certificate"
            label="Digital Certificate"
            required
          />
          <TextArea.Box name="core_logic_data.private_key" label="Private Key" required />
        </>
      )}
      {providerName === CreditPullProviderName.meridian_link && (
        <>
          <Title level={3}>Meridian Link Data</Title>
          <Input.Box name="meridian_link_data.mcl_interface" label="MCL Interface" required />
          <YesNo.Box
            name="meridian_link_data.using_cra_reseller"
            label="Does this client use a CRA Reseller?"
          />
        </>
      )}
      {providerName === CreditPullProviderName.informative && (
        <>
          <Title level={3}>Informative Research Data</Title>
          <Input.Box name="informative_data.branch_identifier" label="Branch Identifier" />
        </>
      )}
      {providerName === CreditPullProviderName.factual_data && (
        <>
          <Title level={3}>Factual Data...Data</Title>
          <Input.Box name="factual_data.branch_identifier" label="Client Code" />
          <Input.Box name="factual_data.submitting_party_name" label="Office Code" required />
          <Input.Box
            name="factual_data.lender_street_address"
            label="Lender Street Address"
            required
          />
          <Input.Box
            name="factual_data.lender_street_address_2"
            label="Lender Street Address 2 (Optional)"
          />
          <Input.Box name="factual_data.lender_city" label="Lender City" required />
          <Input.Box name="factual_data.lender_state" label="Lender State" required />
          <Input.Box name="factual_data.lender_zipcode" label="Lender Zipcode" required />
        </>
      )}
      {providerName === CreditPullProviderName.cbc && (
        <>
          <Title level={3}>CBC Data</Title>
          <Input.Box name="cbc_data.branch_identifier" label="Branch Identifier" />
          <Input.Box name="cbc_data.requestor_name" label="Requestor Name" />
        </>
      )}
      {usingMeridianLinkCraReseller ? (
        <Select.Box
          name="meridian_link_data.cra_reseller_id"
          label="CRA Reseller"
          options={meridianLinkCraResellerOptions}
          required
        />
      ) : null}
      <>
        <Title level={3}>Liabilities</Title>
        <sup>
          Note: This can only be turned on for CBC, CredCo, CreditPlus (a MeridianLink reseller) and
          Informative Research
        </sup>
        <YesNo.Box name="liabilities_enabled" label="Parse and Save Liabilities" />

        {!usingMeridianLinkCraReseller && (
          <>
            <Title level={3}>Bureaus</Title>
            <CreditPullFieldsBureus
              name="bureaus"
              initialValue={{
                [Bureus.equifax]: true,
                [Bureus.experian]: true,
                [Bureus.transunion]: true,
              }}
              validate={handleValidateBureaus}
            />
          </>
        )}
      </>
    </>
  );
};
