import { notifications } from '@mortgagehippo/ds';
import { useAgentLandingPage } from '@mortgagehippo/tasks';
import { NotFoundError } from '@mortgagehippo/util';
import { type ReactNode, useCallback } from 'react';
import { type RouteComponentProps } from 'react-router';

import { LandingPageEditor } from '$components/landing-page-editor';

import { useSite } from '../../hooks/use-site';
import { Content, Layout } from '../../layouts/main';
import { AgentLandingPageEmpty } from './agent-landing-page-empty';
import { AgentLandingPageLoading } from './agent-landing-page-loading';
import { useCreateAgentLandingPage } from './use-create-agent-landing-page';

interface IAgentLandingPagePageRouteParams {
  partnerId: string;
  siteId: string;
}

type IAgentLandingPagePageProps = RouteComponentProps<IAgentLandingPagePageRouteParams>;

export const AgentLandingPagePage = (props: IAgentLandingPagePageProps) => {
  const { match } = props;
  const { params } = match;
  const { siteId } = params;

  const [site, siteLoading] = useSite(siteId);
  const [{ data: landingPage, loading: landingPageLoading }, refreshLandingPage] =
    useAgentLandingPage(siteId);

  const createAgentLandingPage = useCreateAgentLandingPage();

  const loading = siteLoading || landingPageLoading;

  const handleCreate = useCallback(async () => {
    try {
      await createAgentLandingPage(siteId);

      await refreshLandingPage();

      notifications.success({ message: 'Successfully created agent page template.' });
    } catch (e) {
      notifications.error({
        message: 'There was an unexpected error creating agent page template.',
      });
    }
  }, [createAgentLandingPage, refreshLandingPage, siteId]);

  let contentEl: ReactNode = null;
  if (loading) {
    contentEl = <AgentLandingPageLoading />;
  } else {
    if (!site) {
      throw new NotFoundError('Site not found');
    }

    if (!landingPage) {
      contentEl = <AgentLandingPageEmpty onCreate={handleCreate} />;
    } else {
      contentEl = (
        <LandingPageEditor
          landingPage={landingPage}
          domain={site.domain}
          partnerId={site.partnerId}
        />
      );
    }
  }

  const siteName = (!loading && site?.name) || '\u00A0';
  const subTitle = (!loading && 'Agent Page') || '\u00A0';

  return (
    <Layout pageTitle={`${siteName} - ${subTitle}`}>
      <Content title={subTitle} subTitle={siteName}>
        {contentEl}
      </Content>
    </Layout>
  );
};
