import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo/gql';
import { type DefaultSmartviewConfigInput, type SmartviewModelType } from '../../apollo/graphql';

const MOverrideDefaultSmartview = graphql(`
  mutation OverrideDefaultSmartview(
    $partnerId: ID!
    $model: SmartviewModelType!
    $config: DefaultSmartviewConfigInput!
  ) {
    overrideDefaultSmartview(partnerId: $partnerId, model: $model, config: $config)
  }
`);

export const useOverrideDefaultSmartview = () => {
  const fn = useMutation(MOverrideDefaultSmartview);

  return useCallback(
    async (partnerId: string, model: SmartviewModelType, config: DefaultSmartviewConfigInput) => {
      const result = await fn({ partnerId, model, config });

      return result;
    },
    [fn]
  );
};
