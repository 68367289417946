import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo/gql';
import { type PartnerDocumentPostUrlMutation } from '../apollo/graphql';

export type PartnerDocumentPostUrlMutationPartnerDocumentPostUrl =
  PartnerDocumentPostUrlMutation['getPartnerDocumentPostUrl'];

export const MPartnerDocumentPostUrl = graphql(`
  mutation PartnerDocumentPostUrl($partnerId: ID!, $filename: String!) {
    getPartnerDocumentPostUrl(partnerId: $partnerId, filename: $filename) {
      id
      url
      postUrl
    }
  }
`);

export const usePartnerDocumentSubmitUrl = () => {
  const getUpload = useMutation(MPartnerDocumentPostUrl);
  return async (partnerId: string, filename: string) => {
    const response = await getUpload(
      {
        partnerId,
        filename,
      },
      { fetchPolicy: 'no-cache' }
    );
    return response.data!.getPartnerDocumentPostUrl;
  };
};
