import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import {
  borderRadius,
  Button,
  FieldError,
  palette,
  useFieldArray,
  useFormValue,
} from '@mortgagehippo/ds';
import { type IField } from '@mortgagehippo/tasks';
import { isNil, sortBy } from 'lodash-es';
import { useContext, useState } from 'react';
import GridLayout, { WidthProvider } from 'react-grid-layout';
import styled from 'styled-components';

import { EditorContext } from '../editor-context';
import { AddMoreButton } from './add-more-button';

const EmptyContainer = styled.div`
  padding: 20px;
  text-align: center;
`;

const Field = styled.div`
  display: flex;
  border: 1px solid ${palette('neutral400')};
  border-radius: ${borderRadius()};
  padding: 10px;
  background: #5e6269;
  color: white;
`;

const FieldName = styled.div`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const FieldButtons = styled.div`
  flex: 0 0 auto;
  white-space: nowrap;
`;

const GridLayoutWithWidth = WidthProvider(GridLayout);

const COLS = 6;

const byRow = (layout: any) => {
  // Sort the layout by position
  const sortedLayout = sortBy(layout, ['y', 'x', 'i']);

  // Separate the layout into rows
  return sortedLayout.reduce((acum, item) => {
    const lastRow = acum[acum.length - 1] || [];
    const lastItem = lastRow[lastRow.length - 1];

    if (!lastItem || item.y > lastItem.y) {
      acum.push([item]);
    } else {
      lastRow.push(item);
    }
    return acum;
  }, []);
};

const valueToGrid = (value: number[][], cols: number, fields: IField[]) => {
  const grid: any[] = [];
  const fieldCount = fields.length;
  let x = 0;
  let y = 0;
  let i = 0;

  (value || []).forEach((row) => {
    row.forEach((w) => {
      if (i < fieldCount) {
        const item = { i: `${i}`, x, y, w, h: 1, maxH: 1 };
        grid.push(item);
        x += w;
        i += 1;
      }
    });
    x = 0;
    y += 1;
  });

  for (let j = i; j < fieldCount; j += 1) {
    const item = { i: `${j}`, x, y, w: cols, h: 1, maxH: 1 };
    grid.push(item);
    y += 1;
  }

  return grid.slice(0, fieldCount);
};

const gridToValue = (layout: any) => {
  // Separate the layout into rows
  const layoutByRow = byRow(layout);

  // Pad to the right
  const value = layoutByRow.map((row: any) => row.map((col: any) => col.w));

  return value;
};

const hasHoles = (layout: any) => {
  const layoutByRow = byRow(layout);
  for (let i = 0; i < layoutByRow.length; i += 1) {
    if (layoutByRow[i][0].x !== 0) {
      return true;
    }

    for (let j = 1; j < layoutByRow[i].length; j += 1) {
      const nextX = layoutByRow[i][j - 1].x + layoutByRow[i][j - 1].w;
      if (layoutByRow[i][j].x !== nextX) {
        return true;
      }
    }
  }
  return false;
};

interface IGroupFieldsEditorProps {
  name: string;
}

export const GroupFieldsEditor = (props: IGroupFieldsEditorProps) => {
  const { name } = props;
  const [layout, setLayout] = useFormValue(`${name}.layout_data.default.widths_by_row`);
  const [fieldsValue, setFieldsValue] = useFormValue(`${name}.fields`);
  const [key, setKey] = useState(0);
  const { fields } = useFieldArray(`${name}.fields`, {});
  const { onArrayItem } = useContext(EditorContext);

  const handleChangeLayout = (nextGrid: any) => {
    const nextLayout = gridToValue(nextGrid);

    // Update the layout
    setLayout(nextLayout);

    // Resort the fields
    const nextFields = sortBy(nextGrid, ['y', 'x', 'i']).map(({ i }) => fieldsValue[i]);

    setFieldsValue(nextFields);
    setLayout(nextLayout);

    // Re-render the grid if it has holes (bug in rgl)
    if (hasHoles(nextGrid)) {
      setKey((x) => x + 1);
    }
  };

  const handleAdd = (index?: number) => () => {
    const value = index !== undefined ? fields.value[index] : {};
    const ix = isNil(index) ? fields.length || 0 : index;
    onArrayItem(`${name}.fields`, ix, value);
    return false;
  };

  const handleRemove = (i: number) => () => {
    fields.remove(i);
  };

  const grid = valueToGrid(layout || [], COLS, fields.value || []);

  return (
    <>
      {fields.length ? null : (
        <EmptyContainer>
          <Button icon="edit" type="neutral" onClick={handleAdd()} size="sm">
            Add a field
          </Button>
        </EmptyContainer>
      )}
      {!fields.length ? null : (
        <>
          <GridLayoutWithWidth
            key={`${key}`}
            rowHeight={44}
            onDragStop={handleChangeLayout}
            onResizeStop={handleChangeLayout}
            cols={COLS}
            containerPadding={[0, 0]}
            layout={grid}
          >
            {fields.map((_itemName, i) => {
              const field = fields.value ? fields.value[i] : {};
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Field key={`${i}`}>
                  <FieldName>{field.title || field.key}</FieldName>
                  <FieldButtons>
                    <Button
                      style={{
                        color: 'white',
                        border: '1px solid white',
                      }}
                      icon="edit"
                      iconButton
                      importance="secondary-outline"
                      size="xxs"
                      onClick={handleAdd(i)}
                    />
                    <Button
                      style={{
                        color: 'white',
                        border: '1px solid white',
                      }}
                      icon="delete"
                      iconButton
                      type="danger"
                      importance="secondary-outline"
                      size="xxs"
                      onClick={handleRemove(i)}
                    />
                  </FieldButtons>
                </Field>
              );
            })}
          </GridLayoutWithWidth>
          <AddMoreButton onClick={handleAdd()}>Add field</AddMoreButton>
        </>
      )}
      <FieldError name={`${name}.fields`} />
    </>
  );
};
