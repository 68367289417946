import { spacing, Tag } from '@mortgagehippo/ds';
import { startCase } from 'lodash-es';
import { type ReactNode } from 'react';
import styled from 'styled-components';

import { type ParentType } from '../../../apollo/graphql';

interface IProfileNameProps {
  showTag?: boolean;
  parentType: ParentType;
  children?: ReactNode;
}

interface IStyledSpanProps {
  showTag?: boolean;
}

const StyledSpan = styled.span<IStyledSpanProps>`
  margin-right: ${(props) => (props.showTag ? spacing(2) : 0)};
`;

export const ProfileName = (props: IProfileNameProps) => {
  const { children, parentType, showTag = false } = props;

  return (
    <>
      <StyledSpan showTag={showTag}>{children}</StyledSpan>
      {showTag ? <Tag size="xs">{startCase(parentType)}</Tag> : null}
    </>
  );
};
