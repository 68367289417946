import {
  Format,
  type ITableActions,
  type ITableCols,
  notifications,
  Table,
  Tag,
  useModal,
} from '@mortgagehippo/ds';
import { useCallback, useMemo, useState } from 'react';
import { type RouteComponentProps } from 'react-router';

import { usePartner } from '../../hooks/use-partner';
import { Content, Layout } from '../../layouts/main';
import { ApplicationStatusModalContainer } from './application-status-modal-container';
import { type IApplicationStatus, useApplicationStatuses } from './use-application-statuses';
import { useDeleteApplicationStatus } from './use-delete-application-status';
import { useMoveApplicationStatusDown } from './use-move-application-status-down';
import { useMoveApplicationStatusUp } from './use-move-application-status-up';
import { useSetDefaultApplicationStatus } from './use-set-default-application-status';
import { toTagColorProps } from './util';

enum Actions {
  MAKE_DEFAULT = 'MAKE_DEFAULT',
  EDIT = 'EDIT',
  MOVE_UP = 'MOVE_UP',
  MOVE_DOWN = 'MOVE_DOWN',
  DELETE = 'DELETE',
}

const columns: ITableCols<IApplicationStatus> = [
  {
    title: 'Name',
    key: 'name',
    render: ({ color, name, default: isDefault }) => (
      <>
        <Tag {...toTagColorProps(color)}>{name}</Tag>
        {isDefault ? <Tag size="xs">Default</Tag> : null}
      </>
    ),
  },
  {
    title: 'Last Updated',
    key: 'updated',
    render: ({ createdAt, updatedAt }) => (
      <Format.Date format="fromnow" value={updatedAt || createdAt} />
    ),
  },
];

interface IApplicationStatusesPageRouteParams {
  partnerId: string;
}

type IApplicationStatusesPageProps = RouteComponentProps<IApplicationStatusesPageRouteParams>;

export const ApplicationStatusesPage = (props: IApplicationStatusesPageProps) => {
  const { match } = props;
  const { params } = match;
  const { partnerId } = params;
  const [partner] = usePartner(partnerId);

  const [isOpen, openModal, closeModal] = useModal(false);
  const [selectedStatus, setSelectedStatus] = useState<IApplicationStatus | undefined>(undefined);

  const partnerName = partner?.name || '';

  const [applicationStatuses, loading, refetchStatuses] = useApplicationStatuses(partnerId);

  const moveApplicationStatusUp = useMoveApplicationStatusUp();
  const moveApplicationStatusDown = useMoveApplicationStatusDown();
  const deleteApplicationStatus = useDeleteApplicationStatus();
  const setDefaultApplicationStatus = useSetDefaultApplicationStatus();

  const totalStatuses = applicationStatuses.length;

  const handleClickRow = useCallback(
    (record: IApplicationStatus) => {
      setSelectedStatus(record);
      openModal();
    },
    [openModal]
  );

  const handleAdd = useCallback(() => {
    openModal();
  }, [openModal]);

  const handleModalClose = useCallback(() => {
    closeModal(() => {
      setSelectedStatus(undefined);
    });
  }, [closeModal]);

  const handleModalSubmit = useCallback(async () => {
    await refetchStatuses();

    closeModal(() => {
      setSelectedStatus(undefined);
    });
  }, [closeModal, refetchStatuses]);

  const handleMoveUp = useCallback(
    async (record: IApplicationStatus) => {
      try {
        await moveApplicationStatusUp(record.id);

        await refetchStatuses();
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [moveApplicationStatusUp, refetchStatuses]
  );

  const handleMakeDefault = useCallback(
    async (record: IApplicationStatus) => {
      try {
        await setDefaultApplicationStatus(record.id);

        notifications.success({ message: 'The application status was updated successfully.' });

        await refetchStatuses();
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [refetchStatuses, setDefaultApplicationStatus]
  );

  const handleMoveDown = useCallback(
    async (record: IApplicationStatus) => {
      try {
        await moveApplicationStatusDown(record.id);

        await refetchStatuses();
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [moveApplicationStatusDown, refetchStatuses]
  );

  const handleDelete = useCallback(
    async (record: IApplicationStatus) => {
      try {
        await deleteApplicationStatus(record.id);

        notifications.success({ message: 'The application status was deleted successfully.' });

        await refetchStatuses();
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [deleteApplicationStatus, refetchStatuses]
  );

  const handleAction = useCallback(
    async (actionKey: string, record: IApplicationStatus) => {
      switch (actionKey) {
        case Actions.MAKE_DEFAULT:
          return handleMakeDefault(record);
        case Actions.EDIT:
          handleClickRow(record);
          break;
        case Actions.MOVE_UP:
          return handleMoveUp(record);
        case Actions.MOVE_DOWN:
          return handleMoveDown(record);
        case Actions.DELETE:
          return handleDelete(record);
        default: {
          throw new Error(`Unknown action ${actionKey}`);
        }
      }

      return undefined;
    },
    [handleClickRow, handleDelete, handleMakeDefault, handleMoveDown, handleMoveUp]
  );

  const rowActions: ITableActions = useMemo(
    () => [
      {
        key: 'options',
        label: 'Options',
        buttonProps: {
          icon: 'menu-dots',
        },
        onGroupAction: handleAction,
        actions: [
          {
            key: Actions.MAKE_DEFAULT,
            label: 'Make default',
            disabled: (record: any) => record.default,
            confirm: {
              title: 'Warning',
              explanation:
                'Are you sure you want to set this status as default? This will be the default status for new application files',
              type: 'warning',
            },
          },
          {
            key: Actions.EDIT,
            label: 'Edit',
            icon: 'edit',
          },
          {
            key: Actions.MOVE_UP,
            label: 'Move up',
            icon: 'up-arrow',
            disabled: (record) => record.index === 1,
          },
          {
            key: Actions.MOVE_DOWN,
            label: 'Move down',
            icon: 'down-arrow',
            disabled: (record) => record.index === totalStatuses,
          },
          {
            key: Actions.DELETE,
            label: 'Delete',
            icon: 'delete',
            confirm: {
              title: 'Warning',
              explanation:
                'Are you sure you want to delete this status? This action cannot be undone.',
              type: 'warning',
            },
          },
        ],
      },
    ],
    [handleAction, totalStatuses]
  );

  const topActions: ITableActions<IApplicationStatus> = useMemo(
    () => [
      {
        key: 'add-application-status',
        label: 'Add application status',
        buttonProps: {
          icon: 'plus',
        },
        onAction: handleAdd,
      },
    ],
    [handleAdd]
  );

  return (
    <Layout pageTitle={`${partnerName} - Application Statuses`}>
      <Content title="Application Statuses" subTitle={partnerName}>
        <Table<IApplicationStatus>
          caption="Application Status"
          data={applicationStatuses}
          rowKey={(item) => item.id}
          cols={columns}
          rowActions={rowActions}
          topActions={topActions}
          onRowClick={handleClickRow}
          loading={loading}
          size="sm"
          hideTopBackground
        />

        <ApplicationStatusModalContainer
          partnerId={partnerId}
          statusId={selectedStatus?.id || undefined}
          onRequestClose={handleModalClose}
          onSubmit={handleModalSubmit}
          isOpen={isOpen}
        />
      </Content>
    </Layout>
  );
};
