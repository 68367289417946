import { HelpButton, palette, spacing } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { SAMPLE_JSON } from './constants';

const StyledPre = styled.pre`
  background-color: ${palette('neutral50')};
  padding: ${spacing(3)};
`;

export const PaletteImportModalLabel = () => (
  <>
    <span>Hex JSON</span>
    <HelpButton
      popover
      popoverProps={{
        align: 'RightMiddle',
        title: 'Sample Hex JSON',
        minWidthCSS: '300px',
      }}
      size="xs"
    >
      <StyledPre>{JSON.stringify(SAMPLE_JSON, null, 4)}</StyledPre>
    </HelpButton>
  </>
);
