import {
  Checkbox,
  Domain,
  type IChoiceOption,
  Input,
  type ISelectOption,
  Select,
  Title,
} from '@mortgagehippo/ds';
import { Fragment, useCallback } from 'react';

interface ISiteDetailFieldsProps {
  verticalOptions: ISelectOption[];
  sites: IChoiceOption[] | undefined;
  selectedSites?: string[];
}

export const SiteDetailFields = (props: ISiteDetailFieldsProps) => {
  const { verticalOptions, sites, selectedSites } = props;

  const siteMap = useCallback(
    (value: IChoiceOption["value"]) => {
      const site = sites?.find(s => s.value === value);
      if (site?.label) return site.label;
      return value;
    },
    [sites]
  );

  if (selectedSites?.length) {
    return selectedSites.map((site, index) => (
      <Fragment key={site}>
        <Title level={3}>{`"${siteMap(site)}" Settings`}</Title>
        <Input hidden name={`sourceSites[${index}].id`} initialValue={site} />
        <Select.Box
          name={`sourceSites[${index}].params.vertical_name`}
          label="Vertical Name"
          options={verticalOptions}
          required
        />
        <Input.Box name={`sourceSites[${index}].params.name`} label="Name" required />
        <Domain.Box name={`sourceSites[${index}].params.domain`} label="Domain" required validateSane={false} />
        <Checkbox.Box label="Site live" labelInvisible name={`sourceSites[${index}].params.live_site`} initialValue={false}>
          Is it live?
        </Checkbox.Box>
      </Fragment>
    ));
  }

  return (
    <>
      <Title level={3}>Site Details</Title>
      <Select.Box
        name="site.vertical_name"
        label="Vertical Name"
        options={verticalOptions}
        required
      />
      <Input.Box name="site.name" label="Name" required />
      <Domain.Box name="site.domain" label="Domain" required validateSane={false} />
      <Checkbox.Box label="Site live" labelInvisible name="site.live_site" initialValue={false}>
        Is it live?
      </Checkbox.Box>
    </>
  );
};
