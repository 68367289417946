import { Tabs } from '@mortgagehippo/ds';

import { EditorField } from '$components/editor-field';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { TaskEditorFrame } from '../task-editor-frame';

export const InformationTaskEditor = ({ name }: { name: string }) => (
  <TaskEditorFrame name={name}>
    {() => (
      <Tabs.Tab id="content" label="Content">
        <EditorTabWrapper size="md">
          <EditorField key={`${name}.content`} name={`${name}.data.content`} />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
  </TaskEditorFrame>
);
