import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { QSitePreQualificationExpirationDays } from './queries';

export const useSitePreQualificationExpirationDays = (
  partnerId: string,
  siteId: string,
  skip: boolean
) => {
  const [data, loading, , { refetch }] = useQuery(
    QSitePreQualificationExpirationDays,
    {
      partnerId,
      siteId,
    },
    {
      fetchPolicy: 'network-only',
      skip,
    }
  );

  const handleRefresh = useSafeCallback(async () => {
    await refetch({
      partnerId,
      siteId,
    });
  });

  return [data?.partner?.site?.preQualificationExpirationDays, loading, handleRefresh] as const;
};
