import { startCase } from 'lodash-es';
import styled from 'styled-components';

import { RegisteredIntegrationConnectionSecurityType } from '../../../apollo/graphql';
import { type IRegisteredIntegrationConnection } from '../../../hooks/use-registered-integration-connections';

const SecurityInfo = styled.div`
  word-break: break-word;
`;

const mask = (val: string) => val.replace(/./g, '*');

interface IPushbackEndpointsSecurityColumn {
  registeredIntegrationConnection: IRegisteredIntegrationConnection;
}

export const PushbackEndpointsSecurityColumn = (props: IPushbackEndpointsSecurityColumn) => {
  const { registeredIntegrationConnection } = props;
  const {
    accessSecret,
    accessToken,
    basicPassword,
    basicUsername,
    securityType,
    signatureHeader,
    signatureSigningKey,
    jwksUrl,
    activated,
    externalToken,
    externalTokenKey,
  } = registeredIntegrationConnection;

  return (
    <SecurityInfo>
      <strong>Security Type:</strong> {startCase(securityType)}
      <br />
      {activated !== null && activated !== undefined && (
        <>
          <strong>Activated:</strong> {String(activated)}
        </>
      )}
      <br />
      {(securityType === RegisteredIntegrationConnectionSecurityType.token_and_secret ||
        securityType === RegisteredIntegrationConnectionSecurityType.url_params) && (
        <>
          <strong>Token:</strong> {accessToken}
          <br />
          <strong>Secret:</strong> {mask(accessSecret || '')}
        </>
      )}
      {securityType === RegisteredIntegrationConnectionSecurityType.signature_header && (
        <>
          <strong>Header:</strong> {signatureHeader}
          <br />
          <strong>Signing Key:</strong> {mask(signatureSigningKey || '')}
        </>
      )}
      {securityType === RegisteredIntegrationConnectionSecurityType.basic && (
        <>
          <strong>Basic Username:</strong> {basicUsername}
          <br />
          <strong>Basic Password:</strong> {mask(basicPassword || '')}
        </>
      )}
      {securityType === RegisteredIntegrationConnectionSecurityType.json_web_key && (
        <>
          <strong>JSON Web Key Sets URL:</strong> {jwksUrl}
        </>
      )}
      {securityType === RegisteredIntegrationConnectionSecurityType.external_token && (
        <>
          <strong>External Token:</strong> {externalToken}
          <br />
          <strong>External Token Key:</strong> {mask(externalTokenKey || '')}
        </>
      )}
    </SecurityInfo>
  );
};
