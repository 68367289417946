import { useMutation } from '@mortgagehippo/apollo-hooks';
import {
  Card,
  Image,
  type ITableActions,
  type ITableCols,
  notifications,
  palette,
  Popover,
  spacing,
  Table,
  Tag,
} from '@mortgagehippo/ds';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useCustomizationEditorResources } from '../../hooks/use-customization-editor-resources';
import { MDeleteCustomizationValue } from '../../queries';
import {
  type ICustomizationsEditorResource,
  type ICustomizationsEditorTabProps,
} from '../../types';
import { getSourceLabel } from '../../util';
import { ImagesModalContainer } from './images-modal-container';

const SmallPreview = styled(Image)`
  max-height: 200px;
  max-width: 200px;
  background: ${palette('neutral50')};
  padding: 1px;
  border: 1px dashed ${palette('neutral300')};
`;

const StyledPopover = styled(Popover)`
  & button {
    cursor: zoom-in !important;
    padding: ${spacing(1)} !important;
  }
`;

const ZoomContainer = styled.div`
  background: ${palette('neutral50')};
  padding: ${spacing(4)};
`;

const StyledImage = styled(Image)`
  max-width: 98vw;
  max-height: 98vh;
  border: 1px dashed ${palette('neutral300')};
`;

const columns: ITableCols<ICustomizationsEditorResource> = [
  {
    title: 'Resource',
    key: 'key',
    render: ({ key, namespace }) => (
      <span>
        <strong>{namespace}:</strong>
        {key}
      </span>
    ),
  },
  {
    title: 'Value Source',
    key: 'source',
    render: (t) => {
      const sourceLabel = getSourceLabel(t);
      return sourceLabel ? <Tag>{sourceLabel}</Tag> : null;
    },
  },
  {
    key: 'value',
    render: ({ value }) =>
      value ? (
        <StyledPopover
          content={
            <ZoomContainer>
              <StyledImage src={value.value} />
            </ZoomContainer>
          }
          maxWidthCSS="100vw"
          minWidthCSS="0"
          borderless
          buttonProps={{
            importance: 'tertiary',
            compact: true,
            className: 'admin-image-preview',
          }}
        >
          <SmallPreview src={value.value} />
        </StyledPopover>
      ) : (
        ''
      ),
  },
];

export const ImagesTab = (props: ICustomizationsEditorTabProps) => {
  const { projectId, partnerId, domain, language, overriddenOnly = false } = props;
  const [editingId, setEditingId] = useState<string | null>(null);

  const deleteCustomizationValue = useMutation(MDeleteCustomizationValue);

  const [customizationsData, customizationsDataLoading, refetch] = useCustomizationEditorResources({
    projectId,
    partnerId,
    domain,
    type: 'image',
    language,
    overriddenOnly,
  });

  const handleModalClose = useCallback(() => {
    setEditingId(null);
  }, []);

  const handleSubmit = useCallback(() => {
    setEditingId(null);
    refetch();
  }, [refetch]);

  const handleEdit = useCallback((record: ICustomizationsEditorResource) => {
    setEditingId(record.id);
  }, []);

  const handleDelete = useCallback(
    async (record: ICustomizationsEditorResource) => {
      try {
        await deleteCustomizationValue({ id: record.value!.id });

        notifications.success({ message: 'The image was deleted successfully.' });

        refetch();
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [deleteCustomizationValue, refetch]
  );

  const handleAction = useCallback(
    async (actionKey: string, record?: ICustomizationsEditorResource) => {
      if (!record) {
        return;
      }
      switch (actionKey) {
        case 'edit':
          handleEdit(record);
          break;
        case 'delete':
          await handleDelete(record);
          break;
        default: {
          throw new Error(`Images Tab: Unknown action ${actionKey}`);
        }
      }
    },
    [handleDelete, handleEdit]
  );

  const actions: ITableActions<ICustomizationsEditorResource> = useMemo(() => {
    const tableActions: ITableActions<ICustomizationsEditorResource> = [
      {
        key: 'options',
        label: 'Options',
        buttonProps: {
          icon: 'menu-dots',
          iconSize: 'md',
        },
        onGroupAction: handleAction,
        actions: [
          {
            key: 'edit',
            label: 'Upload',
            iconProps: {
              name: 'edit',
            },
          },
          {
            key: 'delete',
            label: 'Delete',
            iconProps: {
              name: 'delete',
            },
            disabled: (t) => !t?.isOverridden,
            confirm: {
              title: 'Warning',
              explanation:
                'Are you sure you want to delete this image? This action cannot be undone.',
              type: 'warning',
            },
          },
        ],
      },
    ];
    return tableActions;
  }, [handleAction]);

  return (
    <>
      <Card compact>
        <Table<ICustomizationsEditorResource>
          caption="Images Customizations"
          data={customizationsData}
          cols={columns}
          loading={customizationsDataLoading}
          rowActions={actions}
          rowKey={(record) => record.id}
          size="sm"
        />
      </Card>
      {editingId ? (
        <ImagesModalContainer
          domain={domain}
          partnerId={partnerId}
          resourceId={editingId}
          language={language}
          onRequestClose={handleModalClose}
          onSubmit={handleSubmit}
        />
      ) : null}
    </>
  );
};
