import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { MSetSequentialLenderMilestonesMutation } from './queries';

export const useSetSequentialLenderMilestones = () => {
  const setSequential = useMutation(MSetSequentialLenderMilestonesMutation);

  return useCallback(
    async (partnerId: string, sequential: boolean) => {
      await setSequential(
        {
          partnerId,
          sequential,
        },
        { fetchPolicy: 'no-cache' }
      );
    },
    [setSequential]
  );
};
