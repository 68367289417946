import { CreditPullTask, type ICreditPullTask, LiabilityType } from '@mortgagehippo/tasks';
import { type ReactNode } from 'react';

import { CreditPullStatusType } from '../../../apollo/graphql';
import { TaskPreviewWrapper } from '../task-preview-wrapper';

export interface ICreditPullTaskPreviewProps {
  task: ICreditPullTask;
  onComplete?: () => Promise<any>;
  renderComplete?: () => ReactNode;
}

const creditPull = {
  id: '1',
  statusCode: CreditPullStatusType.Completed,
  scores: [
    {
      id: '1',
      applicantId: 1,
      equifax: '750',
      transunion: '760',
      experian: '770',
      applicant: {
        id: '1',
        name: null,
        position: 1,
        answers: {
          liabilities: [
            {
              __id: '1',
              account_identifier: '123456',
              full_name: 'Hippo Bank',
              type_name: LiabilityType.Revolving,
              monthly_payment_amount: 1000,
              unpaid_balance_amount: 10000,
              __verified: {
                account_identifier: '123456',
                full_name: 'MH Bank',
                type_name: LiabilityType.Revolving,
                monthly_payment_amount: 1000,
                unpaid_balance_amount: 10000,
              },
            },
          ],
        },
      },
    },
  ],
};

export const CreditPullTaskPreview = (props: ICreditPullTaskPreviewProps) => {
  const { task, renderComplete, onComplete } = props;

  return (
    <TaskPreviewWrapper task={task}>
      {({ nextTask, frame }) => (
        <CreditPullTask
          task={nextTask as ICreditPullTask}
          creditPull={creditPull}
          onComplete={onComplete}
          frame={frame}
          renderComplete={renderComplete}
        />
      )}
    </TaskPreviewWrapper>
  );
};
