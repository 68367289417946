import {
  type IPricingLoan,
  type IPricingScenario,
  type IPricingTask,
  PricingTask,
} from '@mortgagehippo/tasks';

import { TaskPreviewWrapper } from '../task-preview-wrapper';
import { type ITaskPreviewProps } from '../types';

export const PricingTaskPreview = (props: ITaskPreviewProps<IPricingTask>) => {
  const { task, onComplete: _onComplete, renderComplete } = props;

  const loans: IPricingLoan[] = [
    {
      id: '1',
      apr: 5.5,
      closingCost: 10000,
      loanTerm: '30 Yr',
      lockPeriod: 60,
      rate: 5.5,
      principalAndInterest: 1500,
      monthlyMi: 100,
      totalPayment: 1600,
      loanType: 'Conforming',
      position: 1,
      productName: 'Conventional Conforming 30 Yr Fixed',
      createdAt: '2019-09-06 18:08:24.507855',
      updatedAt: '2019-09-06 18:08:24.507855',
      totalLoanAmount: 160000,
      showClosingCosts: true,
      productId: 'test',
    },
  ];

  const scenario: IPricingScenario = {
    loanAmount: 100000,
    downPayment: 10000,
  };

  return (
    <TaskPreviewWrapper task={task}>
      {({ nextTask, frame }) => (
        <PricingTask
          task={nextTask as IPricingTask}
          loans={loans}
          scenario={scenario}
          frame={frame}
          renderComplete={renderComplete}
        />
      )}
    </TaskPreviewWrapper>
  );
};
