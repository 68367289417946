import { Input, type ISelectOption, Select, Title, useFormValue, YesNo } from '@mortgagehippo/ds';

import { CodeEditorField } from '$components/code-editor';

enum ByteFileTypes {
  fannie_mae_32 = 'fannie_mae_32',
  mismo_34_du = 'mismo_34_du',
}

const fileOptions: ISelectOption[] = [
  {
    label: 'Fannie Mae File 3.2',
    value: ByteFileTypes.fannie_mae_32,
  },
  {
    label: 'MISMO File 3.4 - DU',
    value: ByteFileTypes.mismo_34_du,
  },
];

export const ByteFields = () => {
  const [isUsingApi] = useFormValue<boolean | undefined>('byte_data.use_api');

  return (
    <>
      <YesNo.Box name="byte_data.use_api" label="Use Byte API?" required />
      {isUsingApi ? (
        <>
          <Title level={3}>Access Info</Title>
          <Input.Box name="byte_data.api_url" label="URL" required />
          <Input.Box name="byte_data.api_user_name" label="Username" required />
          <Input.Box name="byte_data.api_password" label="Password" required />
          <Title level={3}>Byte</Title>
          <Input.Box
            name="byte_data.api_authorization_key"
            label="API Authorization Key"
            required
          />
          <Input.Box name="byte_data.api_organization_code" label="Organization Code" required />
          <Input.Box name="byte_data.api_site_name" label="Site Name" />
          <Input.Box name="byte_data.document_type" label="Document Type" />
          <Input.Box name="byte_data.document_category" label="Document Category" />
        </>
      ) : null}
      {!isUsingApi && (
        <>
          <Title level={3}>Access Info</Title>
          <Input.Box name="access_info.url" label="URL" required />
          <Input.Box name="access_info.user_name" label="Username" required />
          <Input.Box name="access_info.password" label="Password" required />
          <Title level={3}>Byte</Title>
          <Input.Box name="byte_data.company_code" label="Company Code" />
          <Input.Box name="byte_data.user_no" label="User Number" />
          <Input.Box name="byte_data.authorization_key" label="Authorization Key" />
          <Input.Box name="byte_data.data_connection" label="Data Connection" />
        </>
      )}

      {/* Common */}
      <Select.Box name="file_type" label="File type to be used" options={fileOptions} required />
      <YesNo.Box
        name="byte_data.convert_documents_to_pdfs"
        label="Convert documents pushed to Byte to pdfs?"
      />
      <YesNo.Box
        name="byte_data.byte_generate_file_names"
        label="Should Byte generate file names?"
        required
      />
      <Input.Box name="byte_data.partner_name_prefix" label="Partner Name Prefix" />
      <Input.Box name="byte_data.byte_timeout" label="Byte Timeout" />
      <CodeEditorField.Box mode="json" name="custom_json" label="Custom JSON" />
    </>
  );
};
