import {
  Country,
  Email,
  FormSection,
  Input,
  Phone,
  USState,
  USZipCode,
  YesNo,
} from '@mortgagehippo/ds';

import { TransnationalCustomFields } from './transnational-custom-fields';

export const TransnationalFields = () => (
  <>
    <Input.Box name="api_key" label="Api Key" required />
    <Input.Box name="public_key" label="Public Key" required />
    <YesNo.Box name="sandbox_mode" label="Sandbox Mode" required />
    <Input.Box name="company_name" label="Company Name" required />
    <FormSection title="Custom Field Mapping">
      <TransnationalCustomFields />
    </FormSection>
    <FormSection
      title="Payable To"
      description="This information will appear in Transnational invoices."
    >
      <Input.Box name="payable_address.first_name" label="First Name" />
      <Input.Box name="payable_address.last_name" label="Last Name" />
      <Input.Box name="payable_address.company" label="Company" />
      <Input.Box name="payable_address.address_line_1" label="Adress Line 1" />
      <Input.Box name="payable_address.address_line_2" label="Address Line 2" />
      <Input.Box name="payable_address.city" label="City" />
      <USState.Box name="payable_address.state" label="State" required />
      <USZipCode.Box name="payable_address.postal_code" label="Postal Code" />
      <Country.Box name="payable_address.country" label="Country" required />
      <Phone.Box name="payable_address.phone" label="Phone Number" />
      <Phone.Box name="payable_address.fax" label="Fax Number" />
      <Email.Box name="payable_address.email" label="Email Address" />
    </FormSection>
  </>
);
