import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../apollo/gql';
import { type RegisteredIntegrationConnectionInput } from '../apollo/graphql';

export const MCreateRegisteredIntegrationConnection = graphql(`
  mutation CreateRegisteredIntegrationConnection(
    $partnerId: ID!
    $data: RegisteredIntegrationConnectionInput!
  ) {
    createRegisteredIntegrationConnection(partnerId: $partnerId, data: $data) {
      id
    }
  }
`);

export const useCreateRegisteredIntegrationConnection = () => {
  const fn = useMutation(MCreateRegisteredIntegrationConnection);

  return useCallback(
    async (partnerId: string, data: RegisteredIntegrationConnectionInput) => {
      const result = await fn({ partnerId, data });
      return result.data!.createRegisteredIntegrationConnection!.id;
    },
    [fn]
  );
};
