import { Select } from '@mortgagehippo/ds';

export const DateValidationEditor = ({ name }: { name: string }) => {
  // The value must the same as defined in ValidateFromNowType in the DS / Date validators
  const options = [
    { label: 'Less than now', value: 'lt' },
    { label: 'Less than or equal than now', value: 'lte' },
    { label: 'Greater than now', value: 'gt' },
    { label: 'Greater than or equal than now', value: 'gte' },
  ];

  return (
    <Select.Box
      name={`${name}.validateFromNow`}
      label="Validate Date (from now)"
      options={options}
    />
  );
};
