import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo/gql';
import { type CreatePartnerInput } from '../../../apollo/graphql';

export const MCreatePartnerMutation = graphql(`
  mutation CreatePartner($data: CreatePartnerInput!) {
    createPartner(data: $data)
  }
`);

export const useCreatePartner = () => {
  const createAgent = useMutation(MCreatePartnerMutation, undefined);

  return async (data: CreatePartnerInput): Promise<string> => {
    const response = await createAgent({ data });
    return response.data!.createPartner;
  };
};
