import { FORM_ERROR, notifications, useModal } from '@mortgagehippo/ds';
import { useCallback } from 'react';

import { ParentType } from '../../../apollo/graphql';
import { type IActionProps, type IOverrideNotificationProfileAction } from '../types';
import { NotificationModalContainer } from './notification-modal-container';
import { useCreateNotificationProfile } from './use-create-notification-profile';

export const OverrideNotificationProfileAction = (
  props: IActionProps<IOverrideNotificationProfileAction>
) => {
  const { action, onDone } = props;
  const { profileId, partnerId, siteId } = action;
  const [isOpen, , closeModal] = useModal(true);
  const createNotificationProfile = useCreateNotificationProfile();

  const handleSubmit = useCallback(
    async (values: any) => {
      const { name, type_name, data, args } = values;

      let argsInput;
      try {
        argsInput = args ? JSON.parse(args) : undefined;
      } catch {
        return {
          [FORM_ERROR]: 'Could not parse arguments',
        };
      }

      try {
        const input = {
          name,
          typeName: type_name,
          data,
          args: argsInput,
        };

        await createNotificationProfile(ParentType.Site, siteId, input);

        notifications.success({
          messageCid: 'override-notification-profile-action:notification-success',
          message: 'Successfully created notification profile.',
        });

        closeModal(onDone);
      } catch {
        notifications.error({
          messageCid: 'override-notification-profile-action:notification-error',
          message: 'There was an unexpected error overriding the notification profile.',
        });
      }

      return undefined;
    },
    [createNotificationProfile, siteId, closeModal, onDone]
  );

  const handleCloseModal = useCallback(() => {
    closeModal(onDone);
  }, [closeModal, onDone]);

  return (
    <NotificationModalContainer
      title="Override Notification Profile"
      isOpen={isOpen}
      partnerId={partnerId}
      profileId={profileId}
      onSubmit={handleSubmit}
      onRequestClose={handleCloseModal}
    />
  );
};
