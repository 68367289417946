import {
  Input,
  type ISelectOption,
  Select,
  Title,
  useFormValue,
} from '@mortgagehippo/ds';
import { Fragment, type ReactNode } from 'react';

enum MortgageInsuranceProviderName {
  mgic = 'mgic',
  radian = 'radian',
  enact = 'enact',
  arch = 'arch',
  national = 'national',
  essent = 'essent',
}

const providerOptions: ISelectOption[] = [
  {
    label: 'MGIC',
    value: MortgageInsuranceProviderName.mgic,
  },
  {
    label: 'Radian',
    value: MortgageInsuranceProviderName.radian,
  },
  {
    label: 'Enact',
    value: MortgageInsuranceProviderName.enact,
  },
  {
    label: 'Arch',
    value: MortgageInsuranceProviderName.arch,
  },
  {
    label: 'National',
    value: MortgageInsuranceProviderName.national,
  },
  {
    label: 'Essent',
    value: MortgageInsuranceProviderName.essent,
  },
];

const mortgageInsuranceProviderFields: Record<MortgageInsuranceProviderName, ReactNode> = {
  [MortgageInsuranceProviderName.mgic]: (
    <Fragment key={MortgageInsuranceProviderName.mgic}>
      <Title level={3}>MGIC Provider Data</Title>
      <Input.Box name="mgic_data.customer_id" label="Customer Id" />
      <Title level={4}>Access Info</Title>
      <Input.Box name="mgic_data.access_info.url" label="URL" />
      <Input.Box name="mgic_data.access_info.client_id" label="Client ID" />
      <Input.Box name="mgic_data.access_info.client_secret" label="Client Secret" />
    </Fragment>
  ),
  [MortgageInsuranceProviderName.radian]: (
    <Fragment key={MortgageInsuranceProviderName.radian}>
      <Title level={3}>Radian Provider Data</Title>
      <Input.Box name="radian_data.customer_id" label="Customer Id" />
      <Title level={4}>Access Info</Title>
      <Input.Box name="radian_data.access_info.url" label="URL" />
      <Input.Box name="radian_data.access_info.client_id" label="Client ID" />
      <Input.Box name="radian_data.access_info.client_secret" label="Client Secret" />
    </Fragment>
  ),
  [MortgageInsuranceProviderName.enact]: (
    <Fragment key={MortgageInsuranceProviderName.enact}>
      <Title level={3}>Enact Provider Data</Title>
      <Input.Box name="enact_data.customer_id" label="Customer Id" />
      <Title level={4}>Access Info</Title>
      <Input.Box name="enact_data.access_info.url" label="URL" />
      <Input.Box name="enact_data.access_info.client_id" label="Client ID" />
      <Input.Box name="enact_data.access_info.client_secret" label="Client Secret" />
    </Fragment>
  ),
  [MortgageInsuranceProviderName.arch]: (
    <Fragment key={MortgageInsuranceProviderName.arch}>
      <Title level={3}>Arch Provider Data</Title>
      <Input.Box name="arch_data.customer_id" label="Customer Id" />
      <Title level={4}>Access Info</Title>
      <Input.Box name="arch_data.access_info.url" label="URL" />
      <Input.Box name="arch_data.access_info.client_id" label="Client ID" />
      <Input.Box name="arch_data.access_info.client_secret" label="Client Secret" />
    </Fragment>
  ),
  [MortgageInsuranceProviderName.national]: (
    <Fragment key={MortgageInsuranceProviderName.national}>
      <Title level={3}>National Provider Data</Title>
      <Input.Box name="national_data.customer_id" label="Customer Id" />
      <Title level={4}>Access Info</Title>
      <Input.Box name="national_data.access_info.url" label="URL" />
      <Input.Box name="national_data.access_info.client_id" label="Client ID" />
      <Input.Box name="national_data.access_info.client_secret" label="Client Secret" />
    </Fragment>
  ),
  [MortgageInsuranceProviderName.essent]: (
    <Fragment key={MortgageInsuranceProviderName.essent}>
      <Title level={3}>Essent Provider Data</Title>
      <Input.Box name="essent_data.customer_id" label="Customer Id" />
      <Title level={4}>Access Info</Title>
      <Input.Box name="essent_data.access_info.url" label="URL" />
      <Input.Box name="essent_data.access_info.client_id" label="Client ID" />
      <Input.Box name="essent_data.access_info.client_secret" label="Client Secret" />
    </Fragment>
  ),
};

export const MortgageInsuranceFields = () => {
  const [providerNames] = useFormValue<MortgageInsuranceProviderName[] | undefined>('provider_names');

  return (
    <>
      <Select.Box multiple name="provider_names" label="Provider Name" options={providerOptions} required />
      {providerNames?.map((providerName) => mortgageInsuranceProviderFields[providerName])}
    </>
  );
};
