import { notifications, type UploadHandler } from '@mortgagehippo/ds';
import { isInsecureFileUploadError, useScanDocument } from '@mortgagehippo/tasks';
import axios, { type AxiosProgressEvent } from 'axios';
import { type RouteComponentProps } from 'react-router';

import { useBlueprint } from '../../hooks/use-blueprint';
import { useCreatePartnerDocuments } from '../../hooks/use-create-partner-documents';
import { usePartner } from '../../hooks/use-partner';
import { usePartnerDocumentSubmitUrl } from '../../hooks/use-partner-document-submit-url';
import { useSaveBlueprint } from '../../hooks/use-save-blueprint';
import { BlueprintEditorPage } from './blueprint-editor-page';

export interface IBlueprintEditorEditPageParams {
  partnerId: string;
  blueprintId: string;
}

export const BlueprintEditorEditPage = (
  props: RouteComponentProps<IBlueprintEditorEditPageParams>
) => {
  const { match } = props;
  const { params } = match;
  const { partnerId, blueprintId } = params;

  const [partner] = usePartner(partnerId);
  const [blueprint, loading] = useBlueprint(partnerId, blueprintId);

  const saveBlueprint = useSaveBlueprint();
  const getSubmitUrl = usePartnerDocumentSubmitUrl();
  const createPartnerDocuments = useCreatePartnerDocuments();
  const scanDocument = useScanDocument();

  const handleSaveBlueprint = async (nextBlueprint: any) => {
    try {
      const { name, json } = nextBlueprint;

      await saveBlueprint(blueprintId, {
        name,
        json,
      });
      notifications.success({ message: 'Successfully updated blueprint' });
    } catch (e) {
      notifications.error({ message: 'There was an unexpected error, while saving' });
    }
  };

  const handleUploadDocument: UploadHandler = async (file, meta, progress) => {
    try {
      const { id: uploadId, postUrl } = await getSubmitUrl(partnerId, meta.filename);

      await axios.put(postUrl, file, {
        headers: {
          'Content-Type': meta.mime,
        },
        onUploadProgress: (e: AxiosProgressEvent) => {
          if (!e.total) return;
          const percent = Math.round((e.loaded * 100) / e.total);
          progress(percent);
        },
      });

      try {
        await scanDocument(uploadId);
      } catch (e: unknown) {
        if (isInsecureFileUploadError(e)) {
          return { id: uploadId, hasVirus: true };
        }
        throw e;
      }

      const documents = await createPartnerDocuments(partnerId, [{ id: uploadId }]);

      if (!documents?.length) {
        throw new Error('Invalid response, no documents.');
      }

      const { id, filename, size, description } = documents[0]!;

      // TODO: we should fix these types
      return {
        id,
        filename: filename || undefined,
        size: size || undefined,
        description: description || undefined,
      };
    } catch (e) {
      notifications.error({
        message: 'There was an unexpected error while uploading your file',
      });
      throw e;
    }
  };

  if (!blueprint || loading) {
    return null;
  }

  return (
    <BlueprintEditorPage
      partnerName={partner?.name || ''}
      blueprintName={blueprint.name || `Blueprint ${blueprint.id}`}
      initialValues={blueprint}
      onSave={handleSaveBlueprint}
      onUploadDocument={handleUploadDocument}
      editMode
    />
  );
};
