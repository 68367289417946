export const SAMPLE_JSON = {
  '50': '#fff2fc',
  '100': '#fed6f4',
  '200': '#fdbbed',
  '300': '#fa86df',
  '400': '#f557d3',
  '500': '#ed30ca',
  '600': '#c700bb',
  '700': '#b300b3',
  '800': '#94009e',
  '900': '#770087',
};
