import { Input, type ISelectOption, Select, spacing, useFormValue } from '@mortgagehippo/ds';
import styled from 'styled-components';
import { UnreachableCaseError } from 'ts-essentials';

import { CodeEditorField } from '$components/code-editor';

const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -${spacing(3)};
`;

const FieldsCol = styled.div`
  flex: 1 1 auto;
  margin: 0 ${spacing(3)};
`;

enum ResourceType {
  LOAN = 'Loan',
  TRANSACTION = 'Transaction',
}

export const CreateWebhookForm = () => {
  const [resourceType] = useFormValue<ResourceType | undefined>('resource');
  const [events] = useFormValue<[string] | undefined>('events');

  let changePresent = false;
  if (events) {
    changePresent = events.includes('change');
  }

  let eventOptions: ISelectOption[] = [];
  if (resourceType) {
    switch (resourceType) {
      case ResourceType.LOAN:
        eventOptions = [
          {
            label: 'Create',
            value: 'create',
          },
          {
            label: 'Update',
            value: 'update',
          },
          {
            label: 'Submit',
            value: 'submit',
          },
          {
            label: 'Move',
            value: 'move',
          },
          {
            label: 'Change',
            value: 'change',
          },
        ];
        break;
      case ResourceType.TRANSACTION:
        eventOptions = [
          {
            label: 'Update',
            value: 'update',
          },
        ];
        break;
      default: {
        throw new UnreachableCaseError(resourceType);
      }
    }
  }

  return (
    <>
      <FieldsRow>
        <FieldsCol>
          <Input.Box
            label="Endpoint (e.g. https://api-production.mortgagehippo.com/integrations/bank123-encompass)"
            name="endpoint"
          />
        </FieldsCol>
      </FieldsRow>
      <FieldsRow>
        <FieldsCol>
          <Select.Box
            name="resource"
            label="Resource"
            options={[
              {
                label: 'Loan',
                value: ResourceType.LOAN,
              },
              {
                label: 'Transaction',
                value: ResourceType.TRANSACTION,
              },
            ]}
            required
          />
        </FieldsCol>
        <FieldsCol>
          <Select.Box multiple name="events" label="Events" options={eventOptions} required />
        </FieldsCol>
      </FieldsRow>
      {changePresent ? (
        <FieldsRow>
          <FieldsCol>
            <CodeEditorField.Box label="Filters" name="filters" mode="json" height="200px" />
          </FieldsCol>
        </FieldsRow>
      ) : null}
    </>
  );
};
