import { createContext } from 'react';

export interface IDataGatheringContext {
  onArrayItem:
    | ((name: string, index?: number, initialValues?: any) => boolean)
    | ((name: string, index?: number, initialValues?: any) => void);
  path: string;
}

export const EditorContext = createContext<IDataGatheringContext>({
  onArrayItem: () => undefined,
  path: '',
});
