import { useSagaSubmitHandler } from '@mortgagehippo/tasks';
import { useState } from 'react';
import { type RouteComponentProps } from 'react-router';

import { useCreateSite } from '../../hooks/use-create-site';
import { usePartner } from '../../hooks/use-partner';
import { Content, Layout } from '../../layouts/main';
import { SiteForm } from './site-form';

export interface ICreateSitePageParams {
  partnerId: string;
}

export const CreateSitePage = (props: RouteComponentProps<ICreateSitePageParams>) => {
  const { match } = props;
  const { params } = match;
  const { partnerId } = params;
  const [formKey, setFormKey] = useState(1);
  const [partner] = usePartner(partnerId);

  const createSite = useCreateSite();

  const [handleSubmit, running] = useSagaSubmitHandler({
    infoMessageCid: 'create-site-action:info',
    successMessageCid: 'create-site-action:success',
    errorMessageCid: 'create-site-action:error',
    infoMessage: 'Creating site',
    successMessage: 'The site was successfully created.',
    errorMessage: 'There was an error while creating the site.',
    async onSubmit(values: any, setAsyncId) {
      const nextAsyncId = await createSite(partnerId, values);
      setAsyncId(nextAsyncId);
    },
    async onDone() {
      // reset the form
      setFormKey(formKey + 1);
    },
  });

  return (
    <Layout>
      <Content title="Create Site" subTitle={partner?.name || ''}>
        <SiteForm
          key={formKey}
          partnerId={partnerId}
          initialValues={{}}
          onSubmit={handleSubmit}
          editMode={false}
          disabled={running}
          onSiteAuth0TenantChange={undefined}
        />
      </Content>
    </Layout>
  );
};
