import { ApolloHooksProvider } from '@mortgagehippo/apollo-hooks';
import {
  AuthCallbackRoute,
  AuthenticationError,
  AuthLogoutRoute,
  AuthProvider,
  AuthUniversalLoginRoute,
  ForbiddenError,
  ProtectedRoute,
} from '@mortgagehippo/auth';
import { CustomizationProvider } from '@mortgagehippo/ds';
import { ErrorBoundary, NotFoundError } from '@mortgagehippo/util';
import { Redirect, Route, Router, Switch } from 'react-router';

import { ActionsProvider } from '$components/actions/actions-provider';
import { LogoutCountdown } from '$components/logout-countdown';

import { client } from './apollo/apollo-client';
import { AgentLandingPagePage } from './pages/agent-landing-page';
import { ApplicationStatusesPage } from './pages/application-statuses';
import { AuthErrorPage } from './pages/auth-error';
import { AuthRolesPage } from './pages/auth-roles';
import { BlueprintEditorCreatePage, BlueprintEditorEditPage } from './pages/blueprint';
import { BlueprintsPage } from './pages/blueprints';
import { CustomFieldsPage } from './pages/custom-fields';
import { ForcedLogoutPage } from './pages/forced-logout';
import { LandingPagesPage } from './pages/landing-pages';
import { LandingPagePage } from './pages/landing-pages/landing-page-page';
import { LoadingPage } from './pages/loading';
import { MaintenanceModePage } from './pages/mantainence-mode';
import { NotFoundPage } from './pages/not-found';
import { PartnersPage } from './pages/partners';
import { PartnerCascadesPage } from './pages/partners/cascades';
import { PartnerCredentialsPage } from './pages/partners/credentials/partner-credentials-page';
import { PartnerCustomizationsPage } from './pages/partners/customizations/partner-customizations-page';
import { PartnerDocumentsPage } from './pages/partners/documents/partner-documents-page';
import { LenderMilestonesPage } from './pages/partners/lender-milestones';
import { PartnerNotificationsPage } from './pages/partners/notifications/partner-notifications-page';
import { PartnerPreQualificationLetterPage } from './pages/partners/pre-qualification-letter';
import { PushbackEndpointsPage } from './pages/partners/pushback-endpoints/pushback-endpoints-page';
import { PartnerUnassignedApplicationNotificationsContactsPage } from './pages/partners/unassigned-application-notifications-contacts';
import { PartnerWebhookConfigurationsPage } from './pages/partners/webhook-configurations/partner-webhook-configurations-page';
import { PipelineRoute } from './pages/pipeline';
import { SagasPartnerPage } from './pages/sagas/sagas-partner-page';
import { SagasSitePage } from './pages/sagas/sagas-site-page';
import { ServiceJobManagementPage } from './pages/service-job-management/service-job-management-page';
import { SessionTimeoutPage } from './pages/session-timeout';
import { CreateSitePage, EditSitePage } from './pages/site';
import { SitesPage } from './pages/sites';
import { SiteCascadesPage } from './pages/sites/cascades';
import { SiteCredentialsPage } from './pages/sites/credentials/site-credentials-page';
import { SiteCustomizationsPage } from './pages/sites/customizations/site-customizations-page';
import { MilestonesPage } from './pages/sites/milestones';
import { SiteNotificationsPage } from './pages/sites/notifications/site-notifications-page';
import { SitePreQualificationLetterPage } from './pages/sites/pre-qualification-letter';
import { SiteUnassignedApplicationNotificationsContactsPage } from './pages/sites/unassigned-application-notifications-contacts';
import { SiteWebhookConfigurationsPage } from './pages/sites/webhook-configurations/sites-webhook-configurations-page';
import { auth } from './services/auth';
import { history } from './services/history';

export const App = () => (
  <ApolloHooksProvider client={client}>
    {/*
        need this since we have customization providers in customization/landing pages editors
        and they conflict with this customization provider.
      */}
    <CustomizationProvider>
      <ErrorBoundary errors={[ForbiddenError, AuthenticationError]} errorComponent={AuthErrorPage}>
        <AuthProvider auth={auth} loadingComponent={LoadingPage}>
          <ActionsProvider>
            <ErrorBoundary errors={[NotFoundError]} errorComponent={NotFoundPage}>
              <Router history={history}>
                <Switch>
                  <AuthUniversalLoginRoute path="/" exact allowSignup={false} />
                  <ProtectedRoute path="/partners" component={PartnersPage} exact />
                  <ProtectedRoute path="/maintenance" component={MaintenanceModePage} exact />
                  <ProtectedRoute path="/partners/:partnerId" component={SitesPage} exact />
                  <ProtectedRoute
                    path="/partners/:partnerId/blueprints"
                    component={BlueprintsPage}
                    exact
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/blueprints/new"
                    component={BlueprintEditorCreatePage}
                    exact
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/blueprints/:blueprintId"
                    component={BlueprintEditorEditPage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/cascades"
                    component={PartnerCascadesPage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/customFields"
                    component={CustomFieldsPage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/customizations"
                    component={PartnerCustomizationsPage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/credentials"
                    component={PartnerCredentialsPage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/webhook-configurations"
                    component={PartnerWebhookConfigurationsPage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/documents"
                    component={PartnerDocumentsPage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/lender-milestones"
                    component={LenderMilestonesPage}
                  />
                  <ProtectedRoute path="/partners/:partnerId/sagas" component={SagasPartnerPage} />
                  <ProtectedRoute
                    path="/partners/:partnerId/pushback-endpoints"
                    component={PushbackEndpointsPage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/notifications"
                    component={PartnerNotificationsPage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/unassigned-application-notifications-contacts"
                    component={PartnerUnassignedApplicationNotificationsContactsPage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/pre-qualification-letter"
                    component={PartnerPreQualificationLetterPage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/sites/new"
                    component={CreateSitePage}
                    exact
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/sites/:siteId"
                    component={EditSitePage}
                    exact
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/auth-roles"
                    component={AuthRolesPage}
                    exact
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/sites/:siteId/sagas"
                    component={SagasSitePage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/sites/:siteId/cascades"
                    component={SiteCascadesPage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/sites/:siteId/customizations"
                    component={SiteCustomizationsPage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/sites/:siteId/milestones"
                    component={MilestonesPage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/sites/:siteId/credentials"
                    component={SiteCredentialsPage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/sites/:siteId/webhook-configurations"
                    component={SiteWebhookConfigurationsPage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/sites/:siteId/notifications"
                    component={SiteNotificationsPage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/sites/:siteId/landingPages"
                    component={LandingPagesPage}
                    exact
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/sites/:siteId/landingPages/:landingPageId"
                    component={LandingPagePage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/sites/:siteId/agentLandingPage"
                    component={AgentLandingPagePage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/sites/:siteId/unassigned-application-notifications-contacts"
                    component={SiteUnassignedApplicationNotificationsContactsPage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/sites/:siteId/pre-qualification-letter"
                    component={SitePreQualificationLetterPage}
                  />
                  <ProtectedRoute
                    path="/partners/:partnerId/applicationStatuses"
                    component={ApplicationStatusesPage}
                  />
                  <Redirect from="/search-management" to="/job-management" exact />
                  <ProtectedRoute
                    path="/job-management"
                    component={ServiceJobManagementPage}
                    exact
                  />
                  <ProtectedRoute path="/partners/:partnerId/pipeline" component={PipelineRoute} />
                  <AuthCallbackRoute path="/authorize" component={LoadingPage} />
                  <AuthLogoutRoute path="/logout" />
                  <Route path="/session-timeout" exact component={SessionTimeoutPage} />
                  <Route path="/forced-logout" exact component={ForcedLogoutPage} />
                  <Route component={NotFoundPage} />
                </Switch>
              </Router>
              <LogoutCountdown />
            </ErrorBoundary>
          </ActionsProvider>
        </AuthProvider>
      </ErrorBoundary>
    </CustomizationProvider>
  </ApolloHooksProvider>
);
