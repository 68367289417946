import { Format, type ITableActions, type ITableCol, useModal } from '@mortgagehippo/ds';
import { QueryTable } from '@mortgagehippo/query-components';
import { useCallback, useMemo, useRef, useState } from 'react';

import {
  type PartnerCascadeConfigurationsQuery,
  type PartnerCascadeConfigurationsQueryVariables,
  type SiteCascadeConfigurationsQuery,
  type SiteCascadeConfigurationsQueryVariables,
} from '../../apollo/graphql';
import {
  type ICascadeConfiguration,
  QPartnerCascadeConfigurations,
  QSiteCascadeConfigurations,
} from './queries';
import { SaveCascadeConfigurationModalContainer } from './save-cascade-configuration-modal-container';

const columns: Array<ITableCol<ICascadeConfiguration>> = [
  {
    title: 'Cascade Type',
    key: 'cascadeType',
    render: (t) => t.cascadeType,
  },
  {
    title: 'Cascade Owner Type',
    key: 'cascadeOwnerType',
    render: (t) => t.cascadeOwnerType,
  },
  {
    title: 'Name',
    key: 'name',
    render: ({ name }) => name,
    noWrap: true,
  },
  {
    title: 'Version',
    key: 'version',
    render: ({ version }) => version,
  },
  {
    title: 'Created',
    key: 'created',
    render: ({ createdAt }) => <Format.Date format="fromnow" value={createdAt} />,
  },
];

interface ICascadeConfigurationsTableProps {
  partnerId: string;
  siteId?: string;
  loading: boolean;
}

export const CascadeConfigurationsTable = (props: ICascadeConfigurationsTableProps) => {
  const { partnerId, siteId, loading } = props;
  const [isOpen, openModal, closeModal] = useModal(false);
  const tableRef = useRef<any>();
  const [selectedCascadeConfiguration, setSelectedCascadeConfiguration] = useState<
    ICascadeConfiguration | undefined | null
  >(null);

  const isSitePage = !!siteId;

  const handleModalClose = useCallback(() => {
    closeModal(() => {
      setSelectedCascadeConfiguration(undefined);
    });
  }, [closeModal]);

  const handleModalSubmit = useCallback(() => {
    closeModal(() => {
      setSelectedCascadeConfiguration(undefined);
      tableRef.current.refetch();
    });
  }, [closeModal]);

  const topActions: ITableActions<ICascadeConfiguration> = useMemo(() => {
    const newActions: ITableActions<ICascadeConfiguration> = [
      {
        key: 'create-cascade',
        label: 'Create cascade',
        buttonProps: {
          icon: 'plus',
        },
        onAction: (_actionKey) => {
          openModal();
        },
      },
    ];
    return newActions;
  }, [openModal]);

  const rowActions: ITableActions<ICascadeConfiguration> = useMemo(() => {
    const newActions: ITableActions<ICascadeConfiguration> = [
      {
        key: 'edit-cascade',
        label: 'Edit',
        buttonProps: {
          icon: 'edit',
        },
        onAction: (_actionKey, record) => {
          setSelectedCascadeConfiguration(record);
          openModal();
        },
      },
    ];
    return newActions;
  }, [openModal]);

  const emptyText = `This ${isSitePage ? 'Site' : 'Partner'} has no cascades`;

  if (isSitePage) {
    return (
      <>
        <QueryTable<
          SiteCascadeConfigurationsQuery,
          SiteCascadeConfigurationsQueryVariables,
          ICascadeConfiguration
        >
          ref={tableRef}
          caption="Site Cascades"
          rowKey={(item) => item.id}
          dataSource={(result) => result.partner?.site?.cascadeConfigurations.items || []}
          itemTotal={(result) => result.partner?.site?.cascadeConfigurations.total || 0}
          nextCursor={(result) => result.partner?.site?.cascadeConfigurations.nextCursor}
          previousCursor={(result) => result.partner?.site?.cascadeConfigurations.previousCursor}
          columns={columns}
          topActions={topActions}
          rowActions={rowActions}
          loading={loading}
          query={QSiteCascadeConfigurations}
          variables={{ partnerId, siteId: siteId! }}
        >
          <QueryTable.Data emptyText={emptyText} />
        </QueryTable>
        <SaveCascadeConfigurationModalContainer
          partnerId={partnerId}
          siteId={siteId}
          cascadeConfiguration={selectedCascadeConfiguration}
          isOpen={isOpen}
          onRequestClose={handleModalClose}
          onSubmit={handleModalSubmit}
        />
      </>
    );
  }

  return (
    <>
      <QueryTable<
        PartnerCascadeConfigurationsQuery,
        PartnerCascadeConfigurationsQueryVariables,
        ICascadeConfiguration
      >
        ref={tableRef}
        caption="Partner Cascades"
        rowKey={(item) => item.id}
        dataSource={(result) => result.partner?.cascadeConfigurations.items || []}
        itemTotal={(result) => result.partner?.cascadeConfigurations.total || 0}
        nextCursor={(result) => result.partner?.cascadeConfigurations.nextCursor}
        previousCursor={(result) => result.partner?.cascadeConfigurations.previousCursor}
        columns={columns}
        topActions={topActions}
        rowActions={rowActions}
        loading={loading}
        query={QPartnerCascadeConfigurations}
        variables={{ partnerId }}
      >
        <QueryTable.Data emptyText={emptyText} />
      </QueryTable>
      <SaveCascadeConfigurationModalContainer
        partnerId={partnerId}
        cascadeConfiguration={selectedCascadeConfiguration}
        isOpen={isOpen}
        onRequestClose={handleModalClose}
        onSubmit={handleModalSubmit}
      />
    </>
  );
};
