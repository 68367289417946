import { Numeric, Tabs, YesNo } from '@mortgagehippo/ds';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { FieldEditorFrame } from './field-editor-frame';
import { OptionsField } from './options-field';

export const SelectEditor = ({ name, choices }: { name: string; choices?: boolean }) => (
  <FieldEditorFrame name={name}>
    {() =>
      choices ? (
        <Tabs.Tab id="choices" label="Choices">
          <EditorTabWrapper size="md">
            <Numeric.Box name={`${name}.columns`} label="Columns" />
            <OptionsField name={`${name}.choices`} label="Choices" />
            <YesNo.Box name={`${name}.multiple`} label="Allow multiple selections" />
            <YesNo.Box name={`${name}.searchable`} label="Searchable?" />
          </EditorTabWrapper>
        </Tabs.Tab>
      ) : null
    }
  </FieldEditorFrame>
);
