import { type AuthRoleDataQuery } from '../../apollo/graphql';

export const getInitialValues = (data: AuthRoleDataQuery | undefined) => {
  if (!data?.partner?.authRole) {
    return {};
  }

  const { name, permissions: permissionsList, description } = data.partner.authRole;

  const permissions = permissionsList.map((p) => p.id);

  return {
    name,
    permissions,
    description,
  };
};

export const toServerInput = (values: any): any => {
  const { name, permissions, description } = values;

  return {
    name,
    permissions,
    description,
  };
};
