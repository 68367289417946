import { notifications } from '@mortgagehippo/ds';
import { isEmpty } from 'lodash-es';
import { useCallback, useMemo } from 'react';

import { type ICascadeConfiguration } from './queries';
import {
  type ISaveCascadeConfigurationModalFormValues,
  SaveCascadeConfigurationModal,
} from './save-cascade-configuration-modal';
import { useSaveCascadeConfiguration } from './use-save-cascade-configuration';

interface ISaveCascadeConfigurationModalContainerProps {
  partnerId: string;
  siteId?: string;
  cascadeConfiguration?: ICascadeConfiguration | null;
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: () => void;
}

export const SaveCascadeConfigurationModalContainer = (
  props: ISaveCascadeConfigurationModalContainerProps
) => {
  const { isOpen, partnerId, siteId, cascadeConfiguration, onRequestClose, onSubmit } = props;

  const saveCascadeConfiguration = useSaveCascadeConfiguration();

  const handleSubmit = useCallback(
    async (values: ISaveCascadeConfigurationModalFormValues) => {
      const {
        name,
        cascadeType,
        cascadeOwnerType,
        cascadeConfigurationActions: cascadeConfigurationActionsWithEmptyRow,
      } = values;
      if (!name || !cascadeType || !cascadeOwnerType || !cascadeConfigurationActionsWithEmptyRow) {
        return;
      }

      const nextCascadeConfigurationActions = cascadeConfigurationActionsWithEmptyRow.filter(
        (cascadeConfigurationAction) => !isEmpty(cascadeConfigurationAction)
      );

      try {
        await saveCascadeConfiguration({
          partnerId,
          siteId,
          id: cascadeConfiguration?.id,
          name,
          cascadeType,
          cascadeOwnerType,
          cascadeConfigurationActions: nextCascadeConfigurationActions.map(
            (cascadeConfigurationAction, index) => {
              const { startCondition } = cascadeConfigurationAction;
              const normalizedStartAction = startCondition ? JSON.parse(startCondition) : null;

              return {
                ...cascadeConfigurationAction,
                startCondition: normalizedStartAction,
                position: index + 1,
              };
            }
          ),
        });

        notifications.success({
          message: 'Cascade successfully saved.',
        });
        onSubmit();
      } catch (error: unknown) {
        notifications.error({
          message: 'Error saving cascade.',
        });
      }
    },
    [cascadeConfiguration?.id, partnerId, siteId, onSubmit, saveCascadeConfiguration]
  );

  const initialValues: ISaveCascadeConfigurationModalFormValues = useMemo(() => {
    if (!cascadeConfiguration) {
      return {};
    }

    return {
      name: cascadeConfiguration.name,
      cascadeType: cascadeConfiguration.cascadeType,
      cascadeOwnerType: cascadeConfiguration.cascadeOwnerType,
      cascadeConfigurationActions: (cascadeConfiguration.actions || []).map(
        (cascadeConfigurationAction) => {
          const { startCondition } = cascadeConfigurationAction;

          return {
            actionType: cascadeConfigurationAction.actionType,
            options: cascadeConfigurationAction.options,
            startCondition: isEmpty(cascadeConfigurationAction.startCondition)
              ? null
              : JSON.stringify(startCondition, null, 2),
            position: cascadeConfigurationAction.position,
            timeout: cascadeConfigurationAction.timeout,
          };
        }
      ),
    };
  }, [cascadeConfiguration]);

  return (
    <SaveCascadeConfigurationModal
      isNew={!cascadeConfiguration?.id}
      isOpen={isOpen}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onRequestClose={onRequestClose}
    />
  );
};
