import {
  FieldBox,
  Input,
  type ISelectOption,
  Select,
  Tabs,
  type UploadHandler,
  useFormValue,
} from '@mortgagehippo/ds';
import { type ITaskGroup } from '@mortgagehippo/tasks';
import { useMemo } from 'react';

import { BlueprintType } from '../../apollo/graphql';
import { EditorArray } from './editor-array';
import { EditorTabWrapper } from './editor-tab-wrapper';
import { JsonPreview } from './json-preview';
import { TemplatesArray } from './templates';

const typeOptions = [
  {
    label: 'Borrower Hub',
    value: BlueprintType.BorrowerPortalBlueprint,
  },
  {
    label: 'Lender Hub',
    value: BlueprintType.LenderPortalBlueprint,
  },
];

interface IBlueprintEditorProps {
  onUploadDocument: UploadHandler;
  editMode?: boolean;
}

export const BlueprintEditor = (props: IBlueprintEditorProps) => {
  const { editMode, onUploadDocument } = props;

  const [taskGroups] = useFormValue<ITaskGroup[] | undefined>('json.task_groups.items');
  const [blueprintType] = useFormValue<BlueprintType | undefined>(`type`);

  const groupOptions: ISelectOption[] = useMemo(
    () =>
      (taskGroups || []).map((group) => ({
        label: group.label,
        value: group.key,
      })),
    [taskGroups]
  );

  return (
    <Tabs>
      <Tabs.Tab id="tasks" label="Tasks">
        <EditorTabWrapper size="md">
          <Input.Box label="Blueprint name" name="name" required />
          <Select.Box
            name="type"
            label="Blueprint Type"
            required
            options={typeOptions}
            disabled={editMode}
          />
          <FieldBox name="json.automated_tasks" label="Tasks">
            <EditorArray
              name="json.automated_tasks"
              addButtonLabel="Add task"
              addMoreButtonLabel="Add another task"
              renderItemLabel={(itemValue: any) => itemValue?.meta?.title || 'Unnamed'}
            />
          </FieldBox>
        </EditorTabWrapper>
      </Tabs.Tab>
      <Tabs.Tab id="task_groups" label="Groups">
        <EditorTabWrapper size="md">
          <FieldBox name="json.task_groups.items" label="Groups">
            <EditorArray
              name="json.task_groups.items"
              addButtonLabel="Add group"
              addMoreButtonLabel="Add another group"
              renderItemLabel={(itemValue: any) => itemValue?.label || 'Unnamed'}
            />
          </FieldBox>
          <Select.Box
            name="json.task_groups.default_group"
            label="Default Group"
            options={groupOptions}
            description="Any tasks that haven't been assigned to a specific group will be displayed within this group."
          />
          <Select.Box
            name="json.task_groups.default_custom_task_group"
            label="Default Group for Custom Tasks"
            options={groupOptions}
            description="Custom tasks will be displayed within this group."
          />
        </EditorTabWrapper>
      </Tabs.Tab>
      {blueprintType === BlueprintType.LenderPortalBlueprint && (
        <Tabs.Tab id="custom_task_templates" label="Custom Task Templates">
          <EditorTabWrapper size="md">
            <FieldBox
              name="json.custom_task_templates.v1.items"
              label="Templates"
              aria-label="Templates"
              labelInvisible
            >
              <TemplatesArray
                name="json.custom_task_templates.v1.items"
                addButtonLabel="Add a template"
                sortable={false}
                onUploadDocument={onUploadDocument}
              />
            </FieldBox>
          </EditorTabWrapper>
        </Tabs.Tab>
      )}
      <Tabs.Tab id="json" label="JSON">
        <EditorTabWrapper>
          <JsonPreview />
        </EditorTabWrapper>
      </Tabs.Tab>
    </Tabs>
  );
};
