import {
  Choice,
  type FormSubmitHandler,
  type IChoiceOption,
  Input,
  ModalForm,
  YesNo,
} from '@mortgagehippo/ds';

import { CustomFieldType } from '../../apollo/graphql';

const typeOptions: IChoiceOption[] = [
  {
    label: 'Boolean',
    value: CustomFieldType.Boolean,
  },
  {
    label: 'Date',
    value: CustomFieldType.Date,
  },
  {
    label: 'Integer',
    value: CustomFieldType.Integer,
  },
  {
    label: 'String',
    value: CustomFieldType.String,
  },
];

interface ICustomFieldModalProps {
  title: string;
  initialValues?: any;
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
  editing?: boolean;
}

const defaultInitialValues = {};
export const CustomFieldModal = (props: ICustomFieldModalProps) => {
  const { editing = false, initialValues = defaultInitialValues, ...rest } = props;

  return (
    <ModalForm initialValues={initialValues} isOpen {...rest}>
      <Input.Box name="name" label="Name" required disabled={editing} />
      <Choice.Box
        name="type_name"
        label="Type"
        options={typeOptions}
        columns={2}
        required
        disabled={editing}
      />
      <YesNo.Box name="common" label="Is this a common field?" required disabled={editing} />
      <Input.Box name="description" label="Description" required />
    </ModalForm>
  );
};
