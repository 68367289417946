import { Success, useFormState } from '@mortgagehippo/ds';
import { DataGatheringTask, type IDataGatheringTask } from '@mortgagehippo/tasks';
import { withErrorBoundary } from '@mortgagehippo/util';
import { get } from 'lodash-es';
import { useContext } from 'react';

import { EditorContext } from '../editor-context';
import { TaskPreviewWrapper } from '../task-preview-wrapper';

const ErrorMessage = () => <h1>ERROR</h1>;

export const PanelPreview = withErrorBoundary(ErrorMessage)(() => {
  const { path } = useContext(EditorContext);
  const { values } = useFormState({ subscription: { values: true } });
  const panel = get(values, path, {});

  const handleComplete = async () => undefined;

  const handleChange = async () => ({ success: true, errors: [] });

  const renderComplete = () => <Success />;

  const task: any = {
    key: 'preview',
    data: {
      panels: [panel],
    },
  };

  return (
    <TaskPreviewWrapper task={task}>
      {({ nextTask, frame }) => (
        <DataGatheringTask
          task={nextTask as IDataGatheringTask}
          onComplete={handleComplete}
          onChange={handleChange}
          initialValues={{}}
          renderComplete={renderComplete}
          frame={frame}
        />
      )}
    </TaskPreviewWrapper>
  );
});
