import { type ITableCols, T, Tag } from '@mortgagehippo/ds';
import { QueryTable } from '@mortgagehippo/query-components';

import {
  type PartnerTotalExpertCustomFieldsQuery,
  type PartnerTotalExpertCustomFieldsQueryVariables,
  type SiteTotalExpertCustomFieldsQuery,
  type SiteTotalExpertCustomFieldsQueryVariables,
} from '../../apollo/graphql';
import { QPartnerTotalExpertCustomFields, QSiteTotalExpertCustomFields } from './queries';

export type PartnerTotalExpertCustomFields = NonNullable<
  PartnerTotalExpertCustomFieldsQuery['partner']
>['totalExpertCustomFields']['items'][0];

export type SiteTotalExpertCustomFields = NonNullable<
  SiteTotalExpertCustomFieldsQuery['site']
>['totalExpertCustomFields']['items'][0];

const totalExpertCustomFieldsColumns: ITableCols = [
  {
    key: 'system_name',
    title: 'Name',
    render: ({ systemName }) => systemName,
  },
  {
    key: 'data_type',
    title: 'Data Type',
    render: ({ dataType }) => dataType,
  },
  {
    key: 'related_entity',
    title: 'Related Entity',
    render: ({ relatedEntity }) => {
      const { type, subtype } = relatedEntity;
      if (!relatedEntity || !type) {
        return null;
      }

      return (
        <>
          <T>{type}</T>
          {!!subtype && (
            <>
              <br />
              <T>
                {subtype}
                <Tag color="neutral" size="xxs">
                  Subtype
                </Tag>
              </T>
            </>
          )}
        </>
      );
    },
  },
];

export interface ITotalExpertCustomFieldsContentProps {
  partnerId: string;
  siteId?: string;
}

export const TotalExpertCustomFieldsContent = (props: ITotalExpertCustomFieldsContentProps) => {
  const { partnerId, siteId } = props;

  if (siteId) {
    return (
      <QueryTable<SiteTotalExpertCustomFieldsQuery, SiteTotalExpertCustomFieldsQueryVariables>
        caption="Total Expert Custom Contact Fields"
        columns={totalExpertCustomFieldsColumns}
        query={QSiteTotalExpertCustomFields}
        rowKey={(item) => item.id}
        dataSource={(result) => result.site?.totalExpertCustomFields.items || []}
        itemTotal={(result) =>
          (result.site?.totalExpertCustomFields.pageCount || 0) *
          (result.site?.totalExpertCustomFields.itemsCount || 0)
        } // estimate because we don't know from their API
        pageCount={(result) => result.site?.totalExpertCustomFields.pageCount || 0}
        variables={{ siteId }}
        bottomContent={<QueryTable.Pagination />}
      >
        <QueryTable.Data
          emptyText="No custom fields found or failed to fetch from Total Expert."
          size="sm"
        />
      </QueryTable>
    );
  }

  return (
    <QueryTable<PartnerTotalExpertCustomFieldsQuery, PartnerTotalExpertCustomFieldsQueryVariables>
      caption="Total Expert Custom Contact Fields"
      columns={totalExpertCustomFieldsColumns}
      query={QPartnerTotalExpertCustomFields}
      rowKey={(item) => item.id}
      dataSource={(result) => result.partner?.totalExpertCustomFields.items || []}
      itemTotal={(result) =>
        (result.partner?.totalExpertCustomFields.pageCount || 0) *
        (result.partner?.totalExpertCustomFields.itemsCount || 0)
      } // estimate because we don't know from their API
      pageCount={(result) => result.partner?.totalExpertCustomFields.pageCount || 0}
      variables={{ partnerId }}
      bottomContent={<QueryTable.Pagination />}
    >
      <QueryTable.Data
        emptyText="No custom fields found or failed to fetch from Total Expert."
        size="sm"
      />
    </QueryTable>
  );
};
