import { type ITabRouteProps, TabRoute, TabRouter } from '@mortgagehippo/ds';
import { NotFoundError } from '@mortgagehippo/util';
import { snakeCase } from 'lodash-es';
import { type RouteComponentProps } from 'react-router';

import { SmartviewModelType } from '../../apollo/graphql';
import { usePartner } from '../../hooks/use-partner';
import { Content, Layout } from '../../layouts/main';
import { PipelinePage } from './pipeline-page';

const TABS: Record<SmartviewModelType, Partial<ITabRouteProps>> = {
  [SmartviewModelType.ApplicationFile]: {
    label: 'Application Files',
  },
  [SmartviewModelType.ArchivedApplicationFile]: {
    label: 'Archived Application Files',
  },
  [SmartviewModelType.Agent]: {
    label: 'Agents',
  },
};

interface IPipelineRouteParams {
  partnerId: string;
}

type IPipelineRouteProps = RouteComponentProps<IPipelineRouteParams>;

export const PipelineRoute = (props: IPipelineRouteProps) => {
  const { match } = props;
  const { params } = match;
  const { partnerId } = params;

  const [partner, loading] = usePartner(partnerId);

  if (!partner && !loading) {
    throw new NotFoundError();
  }

  const partnerName = partner?.name || '';
  const models = Object.keys(TABS) as SmartviewModelType[];

  return (
    <Layout pageTitle={`${partnerName} - Pipeline`}>
      <Content title="Pipeline" subTitle={partnerName}>
        <TabRouter>
          {models.map((model) => {
            const tabProps = TABS[model];
            const { label, ...rest } = tabProps;

            return (
              <TabRoute
                key={model}
                label={label}
                path={`/partners/:partnerId/pipeline/${snakeCase(model)}`}
                to={`/partners/${partnerId}/pipeline/${snakeCase(model)}`}
                {...rest}
                exact
              >
                <PipelinePage partnerId={partnerId} model={model} />
              </TabRoute>
            );
          })}
        </TabRouter>
      </Content>
    </Layout>
  );
};
