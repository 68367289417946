import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo/gql';
import { type SiteSagasQuery, type SiteSagasQueryVariables } from '../../apollo/graphql';

export const QSiteSagas = graphql(`
  query SiteSagas($siteId: ID!) {
    site(id: $siteId) {
      id
      ruleSet {
        id
        sagas {
          name
          custom
          system
          arguments {
            name
            type
            required
            system
          }
          codeLines
        }
      }
    }
  }
`);

export type IRuleSetSaga = NonNullable<NonNullable<SiteSagasQuery['site']>['ruleSet']>['sagas'][0];

export type IRuleSetSagaArgument = NonNullable<
  NonNullable<SiteSagasQuery['site']>['ruleSet']
>['sagas'][0]['arguments'][0];

interface IUseSiteSagasResultData {
  sagas?: IRuleSetSaga[];
  ruleSetId?: string;
}

type IUseSiteSagasResult = [IUseSiteSagasResultData, boolean, () => Promise<any>];

export const useSiteSagas = (siteId: string): IUseSiteSagasResult => {
  const [data, loading, , { refetch }] = useQuery<SiteSagasQuery, SiteSagasQueryVariables>(
    QSiteSagas,
    {
      siteId,
    },
    {
      fetchPolicy: 'network-only',
    }
  );

  const handleRefresh = useCallback(async () => {
    await refetch({ siteId });
  }, [siteId, refetch]);

  const ruleSet = data?.site?.ruleSet || undefined;
  const ruleSetId = ruleSet?.id || undefined;
  const sagas = ruleSet?.sagas || [];

  return [{ sagas, ruleSetId }, loading, handleRefresh];
};
