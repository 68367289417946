import { notifications, type UploadHandler } from '@mortgagehippo/ds';
import { isInsecureFileUploadError, useScanDocument } from '@mortgagehippo/tasks';
import axios, { type AxiosProgressEvent } from 'axios';
import { type RouteComponentProps } from 'react-router';

import { useCreateBlueprint } from '../../hooks/use-create-blueprint';
import { useCreatePartnerDocuments } from '../../hooks/use-create-partner-documents';
import { usePartner } from '../../hooks/use-partner';
import { usePartnerDocumentSubmitUrl } from '../../hooks/use-partner-document-submit-url';
import { history } from '../../services/history';
import { BlueprintEditorPage } from './blueprint-editor-page';

export interface IBlueprintEditorCreatePageParams {
  partnerId: string;
}

export const BlueprintEditorCreatePage = (
  props: RouteComponentProps<IBlueprintEditorCreatePageParams>
) => {
  const { match } = props;
  const { params } = match;
  const { partnerId } = params;

  const [partner] = usePartner(partnerId);

  const createBlueprint = useCreateBlueprint();
  const getSubmitUrl = usePartnerDocumentSubmitUrl();
  const scanDocument = useScanDocument();
  const createPartnerDocuments = useCreatePartnerDocuments();

  const handleSaveBlueprint = async (nextBlueprint: any) => {
    try {
      const { type, name, json } = nextBlueprint;

      const id = await createBlueprint(partnerId, {
        type,
        name,
        json,
      });
      notifications.success({ message: 'Successfully created blueprint' });
      history.push(`/partners/${partnerId}/blueprints/${id}`);
    } catch (e) {
      notifications.error({ message: 'There was an unexpected error, while saving' });
    }
  };

  const handleUploadDocument: UploadHandler = async (file, meta, progress) => {
    try {
      const { id: uploadId, postUrl } = await getSubmitUrl(partnerId, meta.filename);

      await axios.put(postUrl, file, {
        headers: {
          'Content-Type': meta.mime,
        },
        onUploadProgress: (e: AxiosProgressEvent) => {
          if (!e.total) return;
          const percent = Math.round((e.loaded * 100) / e.total);
          progress(percent);
        },
      });

      try {
        await scanDocument(uploadId);
      } catch (e: unknown) {
        if (isInsecureFileUploadError(e)) {
          return { id: uploadId, hasVirus: true };
        }
        throw e;
      }

      const documents = await createPartnerDocuments(partnerId, [{ id: uploadId }]);

      if (!documents?.length) {
        throw new Error('Invalid response, no documents.');
      }

      const { id, filename, size, description } = documents[0]!;

      // TODO: we should fix these types
      return {
        id,
        filename: filename || undefined,
        size: size || undefined,
        description: description || undefined,
      };
    } catch (e) {
      notifications.error({
        message: 'There was an unexpected error while uploading your file',
      });
      throw e;
    }
  };

  return (
    <BlueprintEditorPage
      partnerName={partner?.name || ''}
      blueprintName="New Blueprint"
      initialValues={{ name: '', json: {} }}
      onSave={handleSaveBlueprint}
      onUploadDocument={handleUploadDocument}
    />
  );
};
