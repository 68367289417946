import { type IDocusignTask } from '@mortgagehippo/tasks';

import { TaskPreviewWrapper } from '../task-preview-wrapper';
import { type ITaskPreviewProps } from '../types';

export const DocusignTaskPreview = (props: ITaskPreviewProps<IDocusignTask>) => {
  const { task, onComplete: _onComplete, renderComplete: __renderComplete } = props;

  return <TaskPreviewWrapper task={task}>{() => null}</TaskPreviewWrapper>;
};
