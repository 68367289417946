import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo/gql';
import { type SmartviewDocumentationQuery, type SmartviewModelType } from '../../apollo/graphql';

const QSmartviewDocumentation = graphql(`
  query SmartviewDocumentation($partnerId: ID!, $model: SmartviewModelType!) {
    partner(id: $partnerId) {
      id
      smartviewDocumentation(model: $model) {
        model
        dataFields {
          name
          title
          type
        }
        exportFields {
          name
          title
          type
        }
        searchFields {
          name
          title
          type
        }
      }
    }
  }
`);

export type ISmartviewDocumentation = NonNullable<
  SmartviewDocumentationQuery['partner']
>['smartviewDocumentation'];

export type ISmartviewDocumentationField = NonNullable<
  SmartviewDocumentationQuery['partner']
>['smartviewDocumentation']['dataFields'][0];

export const useSmartviewDocumentation = (
  partnerId: string,
  model: SmartviewModelType,
  options: IUseApolloQueryOptions = {}
) => {
  const [data, loading] = useQuery(
    QSmartviewDocumentation,
    {
      partnerId,
      model,
    },
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );

  const documentation = data?.partner?.smartviewDocumentation;

  return [documentation, loading] as const;
};
