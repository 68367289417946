import { palette, spacing, useTheme } from '@mortgagehippo/ds';
import { Menu, MenuGroup, MenuItem } from '@mortgagehippo/menu';
import { type RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Logo } from '../logo';
import { MenuTitle } from './menu-title';
import { PartnerNavigation } from './partner-navigation';
import { SiteNavigation } from './site-navigation';

const LogoColumn = styled.div`
  padding: ${spacing(2)} 0;

  ${Logo} {
    max-height: 42px;
    max-width: 100%;
  }
`;

const AccountMenu = styled('div')`
  margin-top: ${spacing(4)};
  padding-top: ${spacing(4)};
  border-top: 1px solid ${palette('neutral700')};
`;

export interface IDefaultNavigationParams {
  partnerId: string;
  siteId?: string;
}

const DefaultNavigationWithRouter = (props: RouteComponentProps<IDefaultNavigationParams>) => {
  const { match } = props;
  const { params } = match;
  const { partnerId, siteId } = params;

  const isPartner = !!partnerId && !siteId;
  const isSite = !!siteId;

  const theme = useTheme();

  return (
    <>
      <LogoColumn>
        <Link to="/">
          <Logo />
        </Link>
      </LogoColumn>
      <Menu
        size="sm"
        dark
        activeBackground={theme.palette.neutral100}
        activeColor={theme.palette.neutral900}
      >
        {!isPartner && !isSite && (
          <>
            <MenuTitle>
              <strong>Partners</strong>
            </MenuTitle>

            <MenuItem label="Partners" to="/partners" exact />
            <MenuGroup label="Advanced" expandIconPosition="left" compact>
              <MenuItem label="Maintenance Mode" to="/maintenance" exact />
            </MenuGroup>
          </>
        )}
        {isPartner ? <PartnerNavigation partnerId={partnerId} /> : null}
        {isSite ? <SiteNavigation partnerId={partnerId} siteId={siteId} /> : null}
        <AccountMenu>
          <MenuItem label="Sign out" to="/logout" exact icon="undo" />
        </AccountMenu>
      </Menu>
    </>
  );
};

export const DefaultNavigation = withRouter(DefaultNavigationWithRouter);
