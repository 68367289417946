import { Tabs, YesNo } from '@mortgagehippo/ds';
import { creditPullTaskMessages } from '@mortgagehippo/tasks';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { TaskEditorFrame } from '../task-editor-frame';

const CreditTaskEditorFields = ({ name }: { name: string }) => (
  <>
    <YesNo.Box
      name={`${name}.data.show_liabilities`}
      label="Display liabilities found in the credit report"
      initialValue={false}
    />
    <YesNo.Box
      name={`${name}.data.collapse_empty_scores`}
      label="If less than 3 scores are found, collapse the scores section to not include them"
      initialValue={false}
    />
  </>
);

export const CreditTaskEditor = ({ name }: { name: string }) => (
  <TaskEditorFrame name={name} messages={creditPullTaskMessages}>
    {() => (
      <Tabs.Tab id="options" label="Options">
        <EditorTabWrapper size="md">
          <CreditTaskEditorFields name={name} />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
  </TaskEditorFrame>
);
