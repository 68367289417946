import { Input, type ISelectOption, Select, YesNo } from '@mortgagehippo/ds';

import { CodeEditorField } from '$components/code-editor';

enum MortgageDirectorFileTypes {
  fannie_mae_32 = 'fannie_mae_32',
  mismo_34 = 'mismo_34',
}

const fileOptions: ISelectOption[] = [
  {
    label: 'Fannie Mae File 3.2',
    value: MortgageDirectorFileTypes.fannie_mae_32,
  },
  {
    label: 'MISMO File 3.4',
    value: MortgageDirectorFileTypes.mismo_34,
  },
];

export const MortgageDirectorFields = () => (
  <>
    <Input.Box name="database_name" label="Database Name" required />
    <Input.Box name="user_name" label="User Name" required />
    <Input.Box name="password" label="Password" required />
    <Input.Box name="app_id" label="App Id" required />
    <Input.Box name="app_password" label="App Password" required />
    <Input.Box name="hard_coded_value" label="Hard Coded Value" required />
    <Select.Box name="file_type" label="File type to be used" options={fileOptions} required />
    <YesNo.Box name="production_mode" label="Production Mode" required />
    <CodeEditorField.Box mode="json" name="custom_json" label="Custom JSON" />
  </>
);
