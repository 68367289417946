import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';
import { useMemo } from 'react';

import {
  QPartnerNotificationAlternateAgents,
  UNASSIGNED_APPLICATION_NOTIFICATION_TYPE,
} from './queries';
import { type NotificationAlternateAgent } from './unassigned-application-notifications-table';

type IUseAssignedNotificationAlternateResult = [
  NotificationAlternateAgent[],
  boolean,
  (inherited?: boolean) => Promise<void>,
];

export const usePartnerNotificationAlternateAgents = (
  partnerId: string,
  skip: boolean
): IUseAssignedNotificationAlternateResult => {
  const [
    partnerAssignedAgentsData,
    partnerAssignedAgentsLoading,
    ,
    { refetch: refetchPartnerAssignedAgents },
  ] = useQuery(
    QPartnerNotificationAlternateAgents,
    {
      partnerId,
      notificationType: UNASSIGNED_APPLICATION_NOTIFICATION_TYPE,
      orderBy: { name: 'asc' },
    },
    {
      fetchPolicy: 'network-only',
      skip,
    }
  );

  const handleRefresh = useSafeCallback(async () => {
    await refetchPartnerAssignedAgents({
      partnerId,
      notificationType: UNASSIGNED_APPLICATION_NOTIFICATION_TYPE,
      orderBy: { name: 'asc' },
    });
  });

  const loading = partnerAssignedAgentsLoading;

  const result = useMemo((): NotificationAlternateAgent[] => {
    if (loading || !partnerAssignedAgentsData) {
      return [];
    }

    return partnerAssignedAgentsData.partner?.notificationAlternateAgents.agents ?? [];
  }, [loading, partnerAssignedAgentsData]);

  return [result, loading, handleRefresh];
};
