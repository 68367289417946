import { type ISelectOption } from '@mortgagehippo/ds';

import { SearchModelTypes } from '../../../apollo/graphql';

export const reindexModelOptions: ISelectOption[] = [
  {
    label: 'Partners',
    value: SearchModelTypes.Partner,
  },
  {
    label: 'Sites',
    value: SearchModelTypes.Site,
  },
  {
    label: 'Agents',
    value: SearchModelTypes.Agent,
  },
  {
    label: 'Application Files',
    value: SearchModelTypes.ApplicationFile,
  },
];
