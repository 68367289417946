import { type RouteComponentProps } from 'react-router';

import { WebhookConfigurationsEditor } from '../../../components/webhook-configurations-editor';
import { usePartner } from '../../../hooks/use-partner';
import { Content, Layout } from '../../../layouts/main';

interface IPartnerWebhooksPageRouteParams {
  partnerId: string;
}

type IPartnerWebhooksPageProps = RouteComponentProps<IPartnerWebhooksPageRouteParams>;

export const PartnerWebhookConfigurationsPage = (props: IPartnerWebhooksPageProps) => {
  const { match } = props;
  const { params } = match;
  const { partnerId } = params;

  const [partner] = usePartner(partnerId);
  const partnerName = partner?.name || '';

  return (
    <Layout pageTitle={`${partnerName} - Webhook configurations`}>
      <Content title="Webhook configurations" subTitle={partnerName}>
        <WebhookConfigurationsEditor partnerId={partnerId} />
      </Content>
    </Layout>
  );
};
