import {
  type FormSubmitHandler,
  type IFile,
  ModalForm,
  type UploadHandler,
  UploadInput,
} from '@mortgagehippo/ds';
import { ACCEPTED_DOCUMENT_MIME_TYPES } from '@mortgagehippo/tasks';

export interface IUploadPartnerFilesFormValues {
  documents?: IFile[];
}

interface IUploadPartnerFilesModalProps {
  isOpen: boolean;
  title: string;
  onUpload: UploadHandler;
  onSubmit: FormSubmitHandler;
  onRequestClose: (values?: any) => void;
  initialValues?: IUploadPartnerFilesFormValues;
  submitDisabled?: boolean;
}

export const UploadPartnerFilesModal = (props: IUploadPartnerFilesModalProps) => {
  const { title, onUpload, onRequestClose, onSubmit, isOpen, initialValues, submitDisabled } =
    props;

  return (
    <ModalForm
      title={title}
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      onSubmit={onSubmit}
      initialValues={initialValues}
      okButtonDisabled={submitDisabled}
    >
      <UploadInput.Box
        name="documents"
        label="Documents"
        onUpload={onUpload}
        required
        descriptionField
        accept={ACCEPTED_DOCUMENT_MIME_TYPES}
      />
    </ModalForm>
  );
};
