import { graphql } from '../../apollo/gql';

export const UNASSIGNED_APPLICATION_NOTIFICATION_TYPE = 'UNASSIGNED_APPLICATION';

export const QPartnerNotificationAlternateAgents = graphql(`
  query PartnerNotificationAlternateAgents(
    $partnerId: ID!
    $notificationType: String!
    $orderBy: JSON
  ) {
    partner(id: $partnerId) {
      id
      notificationAlternateAgents(notificationType: $notificationType, orderBy: $orderBy) {
        agents {
          id
          name
          avatarUrl
          email
        }
      }
    }
  }
`);

export const QSiteNotificationAlternateAgents = graphql(`
  query SiteNotificationAlternateAgents(
    $partnerId: ID!
    $notificationType: String!
    $siteId: ID!
    $orderBy: JSON
  ) {
    partner(id: $partnerId) {
      id
      site(id: $siteId) {
        id
        notificationAlternateAgents(notificationType: $notificationType, orderBy: $orderBy) {
          agents {
            id
            name
            avatarUrl
            email
          }
          partnerInheritanceDisabled
        }
      }
    }
  }
`);

export const QPartnerAllAgents = graphql(`
  query QPartnerAllAgents(
    $partnerId: ID!
    $searchCriteria: [SearchCriteria!]
    $paginationOptions: PaginationOptions
  ) {
    partner(id: $partnerId) {
      id
      agents(criteria: $searchCriteria, options: $paginationOptions) {
        items {
          id
          name
          avatarUrl
          email
        }
      }
    }
  }
`);

export const QSiteAllAgents = graphql(`
  query QSiteAllAgents(
    $partnerId: ID!
    $siteId: ID!
    $searchCriteria: [SearchCriteria!]
    $paginationOptions: PaginationOptions
  ) {
    partner(id: $partnerId) {
      id
      site(id: $siteId) {
        id
        agents(criteria: $searchCriteria, options: $paginationOptions) {
          items {
            id
            name
            avatarUrl
            email
          }
        }
      }
    }
  }
`);

export const MNotificationAlternateAgentSetPartnerInheritance = graphql(`
  mutation NotificationAlternateAgentSetPartnerInheritance(
    $siteId: ID!
    $notificationType: String!
    $enabled: Boolean!
  ) {
    notificationAlternateAgentSetPartnerInheritance(
      siteId: $siteId
      notificationType: $notificationType
      enabled: $enabled
    )
  }
`);

export const MAddNotificationAlternateAgent = graphql(`
  mutation AddNotificationAlternateAgent(
    $agentId: ID!
    $notificationType: String!
    $partnerId: ID!
    $siteId: ID
  ) {
    addNotificationAlternateAgent(
      agentId: $agentId
      notificationType: $notificationType
      partnerId: $partnerId
      siteId: $siteId
    )
  }
`);

export const MRemoveNotificationAlternateAgent = graphql(`
  mutation RemoveNotificationAlternateAgent(
    $agentId: ID!
    $notificationType: String!
    $partnerId: ID!
    $siteId: ID
  ) {
    removeNotificationAlternateAgent(
      agentId: $agentId
      notificationType: $notificationType
      partnerId: $partnerId
      siteId: $siteId
    )
  }
`);
