import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo/gql';
import { type RegisteredIntegrationConnectionQuery } from '../apollo/graphql';

export type IRegisteredIntegrationConnection = NonNullable<
  NonNullable<RegisteredIntegrationConnectionQuery['partner']>['registeredIntegrationConnection']
>;

export const QRegisteredIntegrationConnection = graphql(`
  query RegisteredIntegrationConnection($partnerId: ID!, $registeredIntegrationConnectionId: ID!) {
    partner(id: $partnerId) {
      id
      registeredIntegrationConnection(id: $registeredIntegrationConnectionId) {
        id
        uuid
        connectionVersion
        partnerId
        sagaName
        pathSlug
        securityType
        accessToken
        accessSecret
        signatureHeader
        signatureSigningKey
        basicUsername
        basicPassword
        createdAt
        updatedAt
        jwksUrl
        activated
        externalToken
        externalTokenKey
        integrationType
        integrationAction
      }
    }
  }
`);

export const useRegisteredIntegrationConnection = (
  partnerId: string,
  registeredIntegrationConnectionId: string,
  options: IUseApolloQueryOptions = {}
) => {
  const [data, loading] = useQuery(
    QRegisteredIntegrationConnection,
    {
      partnerId,
      registeredIntegrationConnectionId,
    },
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );

  return [data?.partner?.registeredIntegrationConnection, loading] as const;
};
