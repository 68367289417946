import { type RouteComponentProps } from 'react-router';

import { CredentialsEditor } from '../../../components/credentials-editor';
import { usePartner } from '../../../hooks/use-partner';
import { Content, Layout } from '../../../layouts/main';

interface IPartnerCredentialsPageRouteParams {
  partnerId: string;
}

type IPartnerCredentialsPageProps = RouteComponentProps<IPartnerCredentialsPageRouteParams>;

export const PartnerCredentialsPage = (props: IPartnerCredentialsPageProps) => {
  const { match } = props;
  const { params } = match;
  const { partnerId } = params;

  const [partner] = usePartner(partnerId);
  const partnerName = partner?.name || '';

  return (
    <Layout pageTitle={`${partnerName} - Credentials`}>
      <Content title="Credentials" subTitle={partnerName}>
        <CredentialsEditor partnerId={partnerId} />
      </Content>
    </Layout>
  );
};
