import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo/gql';
import { type SmartviewModelType } from '../../apollo/graphql';

const MRevertDefaultSmartview = graphql(`
  mutation RevertDefaultSmartview($partnerId: ID!, $model: SmartviewModelType!) {
    revertDefaultSmartview(partnerId: $partnerId, model: $model)
  }
`);

export const useRevertDefaultSmartview = () => {
  const fn = useMutation(MRevertDefaultSmartview);

  return useCallback(
    async (partnerId: string, model: SmartviewModelType) => {
      const result = await fn({ partnerId, model });

      return result;
    },
    [fn]
  );
};
