import { Input, ModalForm } from '@mortgagehippo/ds';
import { useSagaSubmitHandler } from '@mortgagehippo/tasks';

import { useRegisterSiteAuth0Tenant } from '../../hooks/use-register-site-auth0-tenant';

interface IAuth0SiteTenantModalProps {
  siteId: string;
  isOpen: boolean;
  name: string;
  onDone: (() => Promise<void>) | undefined;
  onCancel: () => void;
}

export const Auth0SiteTenantModal = (props: IAuth0SiteTenantModalProps) => {
  const { isOpen, name, onCancel, onDone, siteId } = props;

  const registerSiteAuth0Tenant = useRegisterSiteAuth0Tenant();

  const [handleSubmit, running] = useSagaSubmitHandler({
    infoMessage: 'Registering site Auth0 Tenant',
    successMessage: 'Successfully registered site Auth0 Tenant',
    errorMessage: 'There was an error registering site Auth0 Tenant.',
    async onSubmit(values: any, setAsyncId) {
      const { authConfiguration } = values || {};
      const {
        client_id,
        connection,
        domain,
        api,
        auth0_management_application_client_id,
        auth0_management_application_client_secret,
      } = authConfiguration;

      const nextAsyncId = await registerSiteAuth0Tenant(siteId, {
        apiIdentifier: api,
        applicationClientId: client_id,
        clientId: auth0_management_application_client_id,
        clientSecret: auth0_management_application_client_secret,
        connectionName: connection,
        domain,
      });

      setAsyncId(nextAsyncId);
    },
    async onDone() {
      if (onDone) {
        await onDone();
      }
    },
  });

  return (
    <ModalForm
      title="Auth0 Site Tenant"
      isOpen={isOpen}
      onSubmit={handleSubmit}
      onRequestClose={onCancel}
      loading={running}
    >
      <Input.Box
        label="Domain"
        labelTooltip="Eg. 'site-tenant-domain.us.auth0.com'"
        name={`${name}.domain`}
        required
      />
      <Input.Box
        label="Client Id"
        description="If not provided one is going to be created for you"
        name={`${name}.client_id`}
      />
      <Input.Box
        label="Default Connection"
        name={`${name}.connection`}
        description="If not provided one is going to be created for you"
      />
      <Input.Box
        label="API Identifier"
        labelTooltip="Eg. 'https://site-tenant-domain.us.auth0.com/api/v2/'"
        name={`${name}.api`}
        required
      />
      <Input.Box
        name={`${name}.auth0_management_application_client_id`}
        label="Auth0 Management Application Client ID"
        required
      />
      <Input.Box
        name={`${name}.auth0_management_application_client_secret`}
        label="Auth0 Management Application Client Secret"
        required
      />
    </ModalForm>
  );
};
