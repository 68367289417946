import { Choice, type IChoiceOption, useModalConfirm } from '@mortgagehippo/ds';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useSetPartnerInheritance } from './use-set-partner-inheritance';

const Wrapper = styled.div`
  max-width: 480px;
`;

export interface IUnassignedApplicationNotificationsToggleProps {
  siteId: string;
  isOverridden: boolean;
  onChange: (nextOverrideValue: boolean) => Promise<any>;
}

const choiceOptions: IChoiceOption[] = [
  {
    label: 'Inherit from Partner',
    value: 'inherit',
  },
  {
    label: 'Override for this site',
    value: 'override',
  },
];

export const UnassignedApplicationNotificationsToggle = (
  props: IUnassignedApplicationNotificationsToggleProps
) => {
  const { siteId, isOverridden, onChange } = props;
  const [value, setValue] = useState<string | null>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [confirm, modalDialog] = useModalConfirm(
    <>
      The customized list for this Site will be lost if you switch to inherit from Partner.
      <br />
      <br />
      Are you sure you want to continue?
    </>,
    {
      type: 'warning',
      cancelButtonLabel: 'Cancel',
      okButtonLabel: 'Continue',
      disableOverlayClickClose: true,
    }
  );

  const setPartnerInheritance = useSetPartnerInheritance();

  const handleChange = useCallback(
    async (newValue: string | undefined) => {
      if (newValue === 'inherit') {
        await confirm({
          onConfirm: async () => {
            setDisabled(true);
            setValue(newValue);
            await setPartnerInheritance(siteId, true);
            await onChange(false);
            setDisabled(false);
          },
        });
      }

      if (newValue === 'override') {
        setDisabled(true);
        setValue(newValue);
        await setPartnerInheritance(siteId, false);
        await onChange(true);
        setDisabled(false);
      }
    },
    [confirm, onChange, setPartnerInheritance, siteId]
  );

  const initialValue = useMemo(() => {
    if (isOverridden) {
      return 'override';
    }

    return 'inherit';
  }, [isOverridden]);

  return (
    <Wrapper>
      <Choice.Input
        onChange={handleChange}
        disabled={disabled}
        value={value || initialValue}
        name="Select Inheritance"
        options={choiceOptions}
        columns={2}
        size="sm"
      />
      {modalDialog}
    </Wrapper>
  );
};
