import { Email, Input, type ISelectOption, Select, YesNo } from '@mortgagehippo/ds';

const accountMonitoringOptions: ISelectOption[] = [
  {
    label: '60',
    value: 60,
  },
  {
    label: '90',
    value: 90,
  },
];

export const AccountchekFields = () => (
  <>
    <Email.Box name="service_email" label="Service Email" required />
    <Input.Box name="password" label="Password" required />
    <YesNo.Box name="production_mode" label="Production Mode" required />
    <Select.Box
      name="account_monitoring"
      label="Account Monitoring"
      options={accountMonitoringOptions}
    />
  </>
);
