import { FORM_ERROR, notifications } from '@mortgagehippo/ds';
import { isEmpty } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import {
  type MilestoneInput,
  MilestoneInputAction,
  type VerticalType,
} from '../../../apollo/graphql';
import { useExternalMilestoneKeys } from '../../partners/lender-milestones/use-external-milestone-keys';
import { type IMilestoneModalFormValues, MilestoneEditModal } from './milestone-edit-modal';
import { type IMilestone } from './queries';
import { useUpdateMilestones } from './use-update-milestones';

interface IMilestoneEditModalContainerProps {
  partnerId: string;
  siteId: string;
  vertical: VerticalType | undefined;
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: () => void;
  milestones: IMilestone[];
}

export const MilestoneEditModalContainer = (props: IMilestoneEditModalContainerProps) => {
  const { isOpen, partnerId, siteId, milestones, onRequestClose, onSubmit, vertical } = props;

  const updateMilestones = useUpdateMilestones();

  const [{ externalKeys }, externalKeysLoading] = useExternalMilestoneKeys(
    partnerId,
    vertical ?? null,
    !vertical
  );

  const handleSubmit = useCallback(
    async (values: IMilestoneModalFormValues) => {
      const { milestones: newMilestonesWithEmptyRow } = values;

      if (!newMilestonesWithEmptyRow) {
        return undefined;
      }

      const newMilestones = newMilestonesWithEmptyRow.filter(
        (newMilestone) => !isEmpty(newMilestone)
      );

      try {
        if (!vertical) {
          // Satisfy typescript, vertical should always be defined...
          throw new Error('Vertical must be selected.');
        }

        const deletedMilestones = milestones
          .filter(
            (milestone) =>
              !newMilestones.length ||
              !newMilestones.find((newMilestone) => milestone.id === newMilestone.id)
          )
          .map((filteredMilestone) => {
            const { id, name, key, externalSourceKeys, externalMilestoneKeys } = filteredMilestone;

            const backwardsCompatibleExternalKeys = externalMilestoneKeys
              ? externalMilestoneKeys.map((externalMilestoneKey) => externalMilestoneKey.name)
              : externalSourceKeys;

            const input: MilestoneInput = {
              action: MilestoneInputAction.delete,
              id,
              name,
              key,
              externalMilestoneKeys: backwardsCompatibleExternalKeys,
              externalMilestoneKeyIds: (backwardsCompatibleExternalKeys || []).map(
                (backwardsCompatibleExternalKey) =>
                  externalKeys.find(
                    (externalKey) => externalKey.name === backwardsCompatibleExternalKey
                  )?.id || ''
              ),
            };
            return input;
          });

        const createUpdateMilestones = (newMilestones || []).map((newMilestone, index) => {
          const { id, name, key, externalMilestoneKeys } = newMilestone;
          const input: MilestoneInput = {
            action: id ? MilestoneInputAction.update : MilestoneInputAction.create,
            id,
            tempId: !id ? uuid() : undefined,
            name,
            key,
            externalMilestoneKeys,
            externalMilestoneKeyIds: (externalMilestoneKeys || []).map(
              (externalMilestoneKey) =>
                externalKeys.find((externalKey) => externalKey.name === externalMilestoneKey)?.id ||
                ''
            ),
            position: index + 1,
          };
          return input;
        });

        const response = await updateMilestones(siteId, {
          milestones: [...deletedMilestones, ...createUpdateMilestones],
        });

        const responseErrors = response.reduce<Record<string, any>>(
          (accumulator, updatedMilestone) => {
            const { errors, id, tempId } = updatedMilestone;

            if (!errors.length) {
              return accumulator;
            }

            accumulator.milestones ||= newMilestones.map(() => undefined);

            const matchedInputMilestone =
              deletedMilestones.find(
                (deletedMilestone) => id && deletedMilestone.id && id === deletedMilestone.id
              ) ||
              createUpdateMilestones.find(
                (createUpdateMilestone) =>
                  (id && createUpdateMilestone.id && id === createUpdateMilestone.id) ||
                  (tempId &&
                    createUpdateMilestone.tempId &&
                    tempId === createUpdateMilestone.tempId)
              );

            const matchedPosition = matchedInputMilestone?.position;
            if (!matchedPosition) {
              accumulator[FORM_ERROR] = errors;
              return accumulator;
            }

            // attempt to organize error to specific input by keywords...
            accumulator.milestones.splice(matchedPosition - 1, 1, {
              name: errors
                .filter(
                  (error) =>
                    error.toLowerCase().includes('name') || !error.toLowerCase().includes('key')
                )
                .join(' --- '),
              key: errors
                .filter(
                  (error) =>
                    error.toLowerCase().includes('key') && !error.toLowerCase().includes('external')
                )
                .join(' --- '),
              externalMilestoneKeys: errors
                .filter(
                  (error) =>
                    error.toLowerCase().includes('key') && error.toLowerCase().includes('external')
                )
                .join(' --- '),
            });

            return accumulator;
          },
          {}
        );

        if (!isEmpty(responseErrors)) {
          return responseErrors;
        }

        notifications.success({
          message: 'The milestones were successfully updated.',
        });
        onSubmit();
      } catch (error: any) {
        notifications.error({
          message: 'Error updating milestones.',
        });
      }
      return undefined;
    },
    [externalKeys, milestones, onSubmit, siteId, updateMilestones, vertical]
  );

  const initialValues = useMemo(() => {
    if (!milestones.length || externalKeysLoading) {
      return { milestones: [] };
    }

    const values: IMilestoneModalFormValues = {
      milestones: milestones.map((milestone) => {
        const { id, name, key, externalSourceKeys, externalMilestoneKeys } = milestone;
        return {
          id,
          name,
          key,
          externalMilestoneKeys: externalMilestoneKeys
            ? externalMilestoneKeys.map((externalMilestoneKey) => externalMilestoneKey.name)
            : externalSourceKeys,
        };
      }),
    };

    return values;
  }, [externalKeysLoading, milestones]);

  return (
    <MilestoneEditModal
      isOpen={isOpen}
      loading={externalKeysLoading}
      externalKeys={externalKeys || []}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onRequestClose={onRequestClose}
    />
  );
};
