import { Tabs } from '@mortgagehippo/ds';

import { EditorTabWrapper } from './editor-tab-wrapper';
import { JsonPreview } from './json-preview';
import { TaskGroupEditorDetails } from './task-group-editor-details';

export const TaskGroupEditor = ({ name }: { name: string }) => (
  <Tabs>
    <Tabs.Tab id="details" label="Details">
      <EditorTabWrapper size="md">
        <TaskGroupEditorDetails name={name} />
      </EditorTabWrapper>
    </Tabs.Tab>
    <Tabs.Tab id="json" label="JSON">
      <EditorTabWrapper>
        <JsonPreview />
      </EditorTabWrapper>
    </Tabs.Tab>
  </Tabs>
);
