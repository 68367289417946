import { Checkbox, Input, type ITableCols, Table, useFormValue } from '@mortgagehippo/ds';
import { isPresent } from '@mortgagehippo/util';
import { pickBy } from 'lodash-es';
import { useState } from 'react';

interface ITransnationalCustomFieldsDataSource {
  revvinFieldName: string;
  revvinFieldKey: string;
}

const dataSource: ITransnationalCustomFieldsDataSource[] = [
  {
    revvinFieldName: 'Revvin Loan ID',
    revvinFieldKey: 'application_file_id',
  },
  {
    revvinFieldName: 'Revvin Task ID',
    revvinFieldKey: 'task_id',
  },
  {
    revvinFieldName: 'LOS Loan Number',
    revvinFieldKey: 'loan_file_number',
  },
];

export const TransnationalCustomFields = () => {
  const [customFields, setCustomFields] = useFormValue('custom_fields');

  const initialCheckboxes = dataSource.reduce(
    (acum: Record<string, boolean>, { revvinFieldKey }) => {
      // eslint-disable-next-line no-param-reassign
      acum[revvinFieldKey] = isPresent(customFields?.[revvinFieldKey]);
      return acum;
    },
    {}
  );

  const [checkboxes, setCheckboxes] = useState<Record<string, any>>(initialCheckboxes);

  const handleCheck = (revvinFieldKey: string) => (checked: boolean) => {
    if (!checked) {
      const nextCustomFields = pickBy(customFields || {}, (_v, k) => k !== revvinFieldKey);
      setCustomFields(nextCustomFields);
    }

    setCheckboxes({ ...checkboxes, [revvinFieldKey]: checked });
  };

  const cols: ITableCols = [
    {
      key: 'revvinFieldName',
      title: 'REVVIN FIELD',
      render: ({ revvinFieldKey, revvinFieldName }) => {
        const field_name = `custom_fields.${revvinFieldKey}_check`;
        const value = !!checkboxes[revvinFieldKey];

        return (
          <Checkbox.Input name={field_name} value={value} onChange={handleCheck(revvinFieldKey)}>
            {revvinFieldName}
          </Checkbox.Input>
        );
      },
    },
    {
      key: 'celero_custom_field',
      title: 'CELERO CUSTOM FIELD',
      render: ({ revvinFieldKey }) => {
        const disabled = !checkboxes[revvinFieldKey];

        return (
          <Input
            name={`custom_fields.${revvinFieldKey}`}
            disabled={disabled}
            required={!disabled}
          />
        );
      },
    },
  ];

  return (
    <Table
      caption="Custom Field Mapping"
      rowKey={(i) => i.revvinFieldKey}
      data={dataSource}
      cols={cols}
      emptyText="No custom fields."
      size="sm"
    />
  );
};
