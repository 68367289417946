import { graphql } from '../../../apollo/gql';

export const QPartnerNotificationProfilesList = graphql(`
  query PartnerNotificationProfilesList($partnerId: ID!) {
    partner(id: $partnerId) {
      id
      notificationProfiles {
        ...NotificationProfileFragment
      }
    }
  }
`);
