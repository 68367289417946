import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo/gql';
import { type VerticalType } from '../../../apollo/graphql';

const QAnswersDocumentationQuery = graphql(`
  query AnswersDocumentation($verticalName: VerticalType) {
    answersDocumentation(verticalName: $verticalName)
  }
`);

export const useAnswersDocumentation = (
  verticalName?: VerticalType,
  options: IUseApolloQueryOptions = {}
) => {
  const [data, loading, error, { refetch }] = useQuery(
    QAnswersDocumentationQuery,
    {
      verticalName,
    },
    {
      ...options,
      fetchPolicy: 'network-only',
      throw: false,
    }
  );

  let result;

  if (error) {
    result = null;
  } else if (data?.answersDocumentation) {
    result = data.answersDocumentation;
  }

  return [result, loading, refetch] as const;
};
