import { ParentType } from '../../apollo/graphql';
import { WebhookConfigurationsEditorContent } from './webhook-configurations-editor-content';


interface IWebhooksEditorProps {
  partnerId: string;
  siteId?: string;
}

export const WebhookConfigurationsEditor = (props: IWebhooksEditorProps) => {
  const { partnerId, siteId } = props;

  const parentType = siteId ? ParentType.Site : ParentType.Partner;

  return <WebhookConfigurationsEditorContent partnerId={partnerId} siteId={siteId} parentType={parentType} />;
};
