import { isValidElement } from 'react';

export interface ITaskMessageValueProps {
  type?: 'text' | 'html';
  value: any;
}

export const TaskMessageValue = (props: ITaskMessageValueProps) => {
  const { type, value } = props;

  if (isValidElement(value)) {
    return <p>{value}</p>;
  }

  switch (type) {
    case 'html': {
      // eslint-disable-next-line react/no-danger
      return <p dangerouslySetInnerHTML={{ __html: value }} />;
    }
    case 'text':
    default: {
      return <p>{value}</p>;
    }
  }
};
