import { groupBy, isEmpty, isEqual, xorWith } from 'lodash-es';

import { type IEditorSaga, type IEditorSagaArgument } from './types';
import { type IRuleSetSaga, type IRuleSetSagaArgument } from './use-site-sagas';

const toEditorSagaArguments = (args: IRuleSetSagaArgument[]): IEditorSagaArgument[] =>
  args.map(({ name, required, type }) => ({
    name,
    required,
    type,
  }));

export const toEditorSagas = (sagas: IRuleSetSaga[]): IEditorSaga[] => {
  const result = sagas.map((saga) => {
    const argumentGroups = groupBy(saga.arguments, (a) => (a.system ? 'system' : 'custom'));

    const systemArguments = toEditorSagaArguments(argumentGroups.system || []);
    const customArguments = toEditorSagaArguments(argumentGroups.custom || []);

    const initialValue = saga.codeLines?.trim() || '';
    const initialArguments = customArguments;

    const { name, system, custom } = saga;

    return {
      name,
      system,
      custom,
      isEdited: false,
      isNew: false,
      initialValue,
      currentValue: initialValue,
      systemArguments,
      initialArguments,
      currentArguments: initialArguments,
    };
  });

  return result;
};

export const isChanged = (saga: IEditorSaga): boolean => saga.isEdited || saga.isNew;

export const isOverridden = (saga: IEditorSaga): boolean =>
  saga.custom || saga.isEdited || saga.isNew;

export const scrollToEl = (el: any) => {
  window.scrollTo(0, el.offsetTop);
};

export const isArrayEqual = (x: any[], y: any[]) => isEmpty(xorWith(x, y, isEqual));
