import {
  FormSection,
  Input,
  type ISelectOption,
  Select,
  Timezone,
  Title,
  useFormValue,
  YesNo,
} from '@mortgagehippo/ds';

import { CodeEditorField } from '$components/code-editor';

import { EncompassGrantTypes } from './encompass-api-fields';

enum PricingProviderName {
  encompass = 'encompass',
  lending_qb = 'lending_qb',
  // loantek = 'loantek', // Commented out since the Loantek integration isn't built yet, but we want to eventually
  mortech = 'mortech',
  mortgagehippo = 'mortgagehippo',
  optimal_blue = 'optimal_blue',
  pollyex = 'pollyex',
}

const providerOptions: ISelectOption[] = [
  {
    label: 'Encompass',
    value: PricingProviderName.encompass,
  },
  {
    label: 'Meridian Link (PriceMyLoan)',
    value: PricingProviderName.lending_qb,
  },
  /*
   * Commented out since the Loantek integration isn't built yet, but we want to eventually
   * {
   *   label: 'Loantek',
   *   value: PricingProviderName.loantek,
   * },
   */
  {
    label: 'Mortech',
    value: PricingProviderName.mortech,
  },
  {
    label: 'MortgageHippo',
    value: PricingProviderName.mortgagehippo,
  },
  {
    label: 'Optimal Blue',
    value: PricingProviderName.optimal_blue,
  },
  {
    label: 'Pollyex',
    value: PricingProviderName.pollyex,
  },
];

const grantTypeOptions: ISelectOption[] = [
  {
    label: 'Client Credentials (authorize as vendor)',
    value: EncompassGrantTypes.client_credentials,
  },
  {
    label: 'Password (authorize as lender)',
    value: EncompassGrantTypes.password,
  },
];

const buydownPointsOptions: ISelectOption[] = [
  {
    label: 'Par',
    value: '0',
  },
  {
    label: '1 Pt',
    value: '1',
  },
  {
    label: '2 Pts',
    value: '2',
  },
  {
    label: '3 Pts',
    value: '3',
  },
  {
    label: '4 Pts',
    value: '4',
  },
  {
    label: '5 Pts',
    value: '5',
  },
  {
    label: '6 Pts',
    value: '6',
  },
  {
    label: 'All Pts',
    value: '-999',
  },
];

const expirationTimeOptions: ISelectOption[] = [
  {
    label: '12:00 AM',
    value: 0,
  },
  {
    label: '1:00 AM',
    value: 1,
  },
  {
    label: '2:00 AM',
    value: 2,
  },
  {
    label: '3:00 AM',
    value: 3,
  },
  {
    label: '4:00 AM',
    value: 4,
  },
  {
    label: '5:00 AM',
    value: 5,
  },
  {
    label: '6:00 AM',
    value: 6,
  },
  {
    label: '7:00 AM',
    value: 7,
  },
  {
    label: '8:00 AM',
    value: 8,
  },
  {
    label: '9:00 AM',
    value: 9,
  },
  {
    label: '10:00 AM',
    value: 10,
  },
  {
    label: '11:00 AM',
    value: 11,
  },
  {
    label: '12:00 PM',
    value: 12,
  },
  {
    label: '1:00 PM',
    value: 13,
  },
  {
    label: '2:00 PM',
    value: 14,
  },
  {
    label: '3:00 PM',
    value: 15,
  },
  {
    label: '4:00 PM',
    value: 16,
  },
  {
    label: '5:00 PM',
    value: 17,
  },
  {
    label: '6:00 PM',
    value: 18,
  },
  {
    label: '7:00 PM',
    value: 19,
  },
  {
    label: '8:00 PM',
    value: 20,
  },
  {
    label: '9:00 PM',
    value: 21,
  },
  {
    label: '10:00 PM',
    value: 22,
  },
  {
    label: '11:00 PM',
    value: 23,
  },
];

export const PricingFields = () => {
  const [providerName] = useFormValue<PricingProviderName | undefined>('provider_name');
  const [grantType] = useFormValue<EncompassGrantTypes | undefined>('encompass_data.grant_type');
  const [isUsingOAuthToken] = useFormValue<boolean | undefined>('lending_qb_data.use_oauth_token');

  return (
    <>
      <Select.Box name="provider_name" label="Provider Name" options={providerOptions} required />
      {providerName === PricingProviderName.optimal_blue && (
        <>
          <Title level={3}>Access Info</Title>
          <Input.Box name="access_info.client_id" label="Client ID" required />
          <Input.Box name="access_info.client_secret" label="Client Secret" required />
          <Title level={3}>Provider Data</Title>
          <Input.Box
            name="provider_data.business_channel_id"
            label="Business Channel ID"
            required
          />
          <Input.Box name="provider_data.originator_id" label="Originator ID" required />
        </>
      )}
      {providerName === PricingProviderName.encompass && (
        <>
          <Select.Box
            name="encompass_data.grant_type"
            label="Grant Type"
            options={grantTypeOptions}
            required
          />
          <Input.Box name="encompass_data.url" label="URL" required />
          <Input.Box name="encompass_data.instance_id" label="Instance Id" required />
          <Input.Box name="encompass_data.client_id" label="Client Id" required />
          <Input.Box name="encompass_data.client_secret" label="Client Secret" required />
          <Input.Box name="encompass_data.epps_user_name" label="EPPS Username" required />
          <YesNo.Box
            name="encompass_data.return_special_products"
            label="Return Special Products"
          />
        </>
      )}
      {providerName === PricingProviderName.encompass && grantType === 'password' && (
        <>
          <Input.Box
            name="encompass_data.encompass_user_name"
            label="Encompass Username"
            required
          />
          <Input.Box name="encompass_data.encompass_password" label="Encompass Password" required />
        </>
      )}
      {providerName === PricingProviderName.mortech && (
        <>
          <Input.Box name="mortech_data.customer_id" label="Customer Id" required />
          <Input.Box name="mortech_data.third_party_name" label="Third Party Name" required />
          <Input.Box name="mortech_data.license_key" label="License Key" required />
          <Input.Box name="mortech_data.email" label="Mortech Email" required />
          <Input.Box name="mortech_data.filter_id" label="Filter Id" />
        </>
      )}
      {providerName === PricingProviderName.lending_qb && (
        <>
          <YesNo.Box
            name="lending_qb_data.use_oauth_token"
            label="Use new OAuth token for Authorization"
            required
          />
          {isUsingOAuthToken ? (
            <>
              <Input.Box name="lending_qb_data.client_id" label="Client ID" required />
              <Input.Box name="lending_qb_data.client_secret" label="Client Secret" required />
            </>
          ) : null}
          {!isUsingOAuthToken && (
            <>
              <Input.Box name="lending_qb_data.user_name" label="User Name" required />
              <Input.Box name="lending_qb_data.password" label="Password" required />
            </>
          )}
        </>
      )}
      {providerName === PricingProviderName.pollyex && (
        <>
          <Input.Box name="access_info.client_id" label="Client Id" required />
          <Input.Box name="access_info.client_secret" label="Secret" required />

          <Title level={3}>Pollyex</Title>
          <Input.Box name="pollyex_data.user_name" label="Username" required />
          <Input.Box name="pollyex_data.password" label="Password" required />
          <Input.Box name="pollyex_data.org_uuid" label="Org UUID" required />
          <Input.Box name="pollyex_data.audience_id" label="Audience Id" required />
          <YesNo.Box name="pollyex_data.production_mode" label="Production Mode" />
        </>
      )}
      <Select.Box
        name="buydown_points_limit"
        label="Amount Of Buydown Points To Display"
        options={buydownPointsOptions}
      />
      <CodeEditorField.Box height="350px" mode="json" name="custom_json" label="Custom JSON" />
      {providerName === PricingProviderName.optimal_blue && (
        <YesNo.Box name="rate_lock_enabled" label="Enable Rate Lock?" />
      )}
      <FormSection title="Featured Rates">
        <Timezone.Box name="featured_rates.expiration_timezone" label="Expiration Timezone" />
        <Select.Box
          name="featured_rates.expiration_hour"
          label="Expiration Hour"
          options={expirationTimeOptions}
        />
      </FormSection>
    </>
  );
};
