import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo/gql';
import { type PageComponentType } from '../../../apollo/graphql';

export const MCreatePageComponentMutation = graphql(`
  mutation CreatePageComponent($landingPageId: ID!, $type: PageComponentType!) {
    createPageComponent(landingPageId: $landingPageId, type: $type) {
      ...PageComponentFragment
    }
  }
`);

export const useCreatePageComponent = () => {
  const createPageComponent = useMutation(MCreatePageComponentMutation, undefined);

  return async (landingPageId: string, type: PageComponentType) => {
    await createPageComponent({ landingPageId, type });
  };
};
