import { type IFile, type UploadHandler } from '@mortgagehippo/ds';
import { DocumentSubmissionTask, type IDocumentSubmissionTask } from '@mortgagehippo/tasks';
import { uniqueId } from 'lodash-es';
import { type ReactNode, useState } from 'react';

import { TaskPreviewWrapper } from '../task-preview-wrapper';

export interface IDocumentSubmissionTaskPreviewProps {
  task: IDocumentSubmissionTask;
  onComplete?: () => Promise<any>;
  renderComplete?: () => ReactNode;
}

export const DocumentSubmissionTaskPreview = (props: IDocumentSubmissionTaskPreviewProps) => {
  const { task, renderComplete, onComplete } = props;
  const [documents, setDocuments] = useState<any[]>([]);

  const handleUpload: UploadHandler = async (_file, meta, progress) =>
    new Promise<IFile>((resolve) => {
      let p = 10;
      const h = setInterval(() => {
        p += 10;
        if (p >= 100) {
          clearInterval(h);
          // const nextDocuments = [...documents];
          const nextDocument: IFile = {
            ...meta,
            id: uniqueId(),
            url: '#',
            status: undefined,
            progress: 100,
            created_at: Date.now(),
          };

          setDocuments((prevDocuments) => [...prevDocuments, nextDocument]);

          resolve(nextDocument);
        } else {
          progress(p);
        }
      }, 250);
    });

  return (
    <TaskPreviewWrapper task={task}>
      {({ nextTask, frame }) => (
        <DocumentSubmissionTask
          task={nextTask as IDocumentSubmissionTask}
          documents={documents}
          onUpload={handleUpload}
          onComplete={onComplete}
          frame={frame}
          renderComplete={renderComplete}
        />
      )}
    </TaskPreviewWrapper>
  );
};
