import {
  ExpandableText,
  Format,
  isTableGroupAction,
  type ITableAction,
  type ITableActions,
  type ITableCols,
  Table,
  Tag,
  Title,
} from '@mortgagehippo/ds';
import { compact } from 'lodash-es';
import { type ReactNode, useCallback, useMemo } from 'react';

import { type CredentialsType, ParentType } from '../../apollo/graphql';
import {
  type CredentialsEditorActionType,
  CredentialsEditorActionTypes,
  CredentialTypeLabels,
} from './constants';
import { type ICredential } from './hooks/use-credential-list';

const partnerCredentialsColumns: ITableCols<ICredential> = [
  {
    title: 'Name',
    key: 'name',
    render: ({ typeName }) => CredentialTypeLabels[typeName] || typeName,
  },
  {
    title: 'Overwritten By',
    key: 'overwrittenBy',
    render: ({ overwrittenBy }) => {
      if (!overwrittenBy?.length) {
        return null;
      }
      return (
        <ExpandableText
          buttonProps={{ size: 'xxs' }}
          maxLines={2}
          expandButtonLabel="show all"
          compact
        >
          {overwrittenBy.map(({ name }) => (
            <div key={`${name}_key`}>{name}</div>
          ))}
        </ExpandableText>
      );
    },
  },
  {
    key: 'updated_at',
    title: 'Last Updated',
    render: ({ updatedAt }) =>
      !!updatedAt && <Format.Date value={updatedAt} format="date-med-time" />,
    width: '170px',
  },
];

const siteCredentialsColumns: ITableCols<ICredential> = [
  {
    title: 'Name',
    key: 'key',
    render: ({ typeName, inherited }) => (
      <>
        {CredentialTypeLabels[typeName] || typeName}{' '}
        {inherited ? (
          <Tag color="neutral" size="xs">
            Partner
          </Tag>
        ) : null}
      </>
    ),
  },
  {
    key: 'updated_at',
    title: 'Last Updated',
    render: ({ updatedAt }) =>
      !!updatedAt && <Format.Date value={updatedAt} format="date-med-time" />,
    width: '300px',
  },
];

export type ICredentialExtraActions = {
  [key in CredentialsType]?: ITableAction<ICredential, CredentialsEditorActionType>;
};

interface ICredentialsEditorContentTableProps {
  parentType: ParentType;
  dataSource?: ICredential[];
  loading?: boolean;
  disabled?: boolean;
  title?: string;
  onRowClick?: (record: ICredential) => void;
  onEditClick?: (record: ICredential) => void;
  onDelete?: (record: ICredential) => Promise<any>;
  emptyText?: string;
  topContent?: ReactNode;
  topActions?: ITableActions<ICredential>;
  hideTopBackground?: boolean;
  credentialExtraActions?: ICredentialExtraActions;
}

export const CredentialsEditorContentTable = (props: ICredentialsEditorContentTableProps) => {
  const {
    parentType,
    dataSource,
    disabled,
    title,
    loading,
    onEditClick,
    onRowClick,
    credentialExtraActions,
    onDelete,
    emptyText,
    topActions,
    topContent,
    hideTopBackground,
  } = props;

  const handleAction = useCallback(
    async (actionType: CredentialsEditorActionType, record?: ICredential) => {
      if (!record) {
        return;
      }

      if (actionType === CredentialsEditorActionTypes.EDIT) {
        if (onEditClick) {
          onEditClick(record);
        }
        return;
      }

      if (actionType === CredentialsEditorActionTypes.DELETE) {
        if (onDelete) {
          await onDelete(record);
        }
        return;
      }

      if (!credentialExtraActions) {
        return;
      }

      const extraAction = credentialExtraActions[actionType];
      if (!extraAction?.onAction) {
        return;
      }

      extraAction.onAction(actionType, record);
    },
    [credentialExtraActions, onDelete, onEditClick]
  );

  const actions: ITableActions<ICredential, CredentialsEditorActionType> = useMemo(() => {
    const tableActions: ITableActions<ICredential, CredentialsEditorActionType> = [
      {
        key: 'options',
        label: 'Options',
        buttonProps: {
          icon: 'menu-dots',
        },
        onGroupAction: handleAction,
        actions: [
          {
            key: CredentialsEditorActionTypes.EDIT,
            label: 'Edit',
            iconProps: {
              name: 'edit',
            },
            confirm:
              parentType === ParentType.Site
                ? {
                    title: 'Info',
                    explanation:
                      'Editing these credentials will only affect this site. Partner level will remain unchanged.',
                    type: 'info',
                  }
                : undefined,
          },
          /*
           * {
           *   key: CredentialsEditorActionTypes.EncompassApi,
           *   label: 'Webhooks',
           *   iconProps: {
           *     name: 'redo',
           *   },
           *   hidden: (record) => {
           *     return record?.typeName !== CredentialsType.EncompassApi;
           *   },
           * },
           */
          {
            key: CredentialsEditorActionTypes.DELETE,
            label: 'Delete',
            iconProps: {
              name: 'delete',
            },
            confirm: {
              title: 'Warning',
              explanation:
                'Are you sure you want to delete these credentials? This action cannot be undone.',
              type: 'warning',
            },
          },
        ],
      },
    ];

    if (credentialExtraActions && isTableGroupAction(tableActions[0]!)) {
      tableActions[0].actions = [
        ...compact(Object.values(credentialExtraActions)),
        ...tableActions[0].actions,
      ];
    }

    return tableActions;
  }, [credentialExtraActions, handleAction, parentType]);

  return (
    <>
      {title ? <Title level={2}>{title}</Title> : null}

      <Table<ICredential, CredentialsEditorActionType>
        caption="Credentials"
        data={dataSource}
        cols={
          parentType === ParentType.Partner ? partnerCredentialsColumns : siteCredentialsColumns
        }
        loading={loading}
        onRowClick={onRowClick}
        rowActions={!disabled ? actions : undefined}
        alwaysShowRowActions
        emptyText={emptyText}
        rowKey={(i) => i.typeName}
        topActions={topActions}
        topContent={topContent}
        size="sm"
        hideTopBackground={hideTopBackground}
      />
    </>
  );
};
