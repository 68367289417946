import { notifications, useModal } from '@mortgagehippo/ds';
import { useCallback } from 'react';

import { type IActionProps, type ICreateLandingPageAction } from '../types';
import { CreateLandingPageModal } from './create-landing-page-modal';
import { useCreateLandingPage } from './use-create-landing-page';

export const CreateLandingPageAction = (props: IActionProps<ICreateLandingPageAction>) => {
  const { action, onDone } = props;
  const { siteId } = action;
  const [isOpen, , closeModal] = useModal(true);

  const createLandingPage = useCreateLandingPage();

  const handleSubmit = async (values: any) => {
    try {
      const { title, path } = values;

      const input = {
        title,
        path,
      };

      await createLandingPage(siteId, input);
      closeModal(() => {
        notifications.success({
          message: 'Successfully created landing page',
          messageCid: 'create-landing-page-action:notification-success',
        });
        onDone();
      });
    } catch (e) {
      closeModal(() => {
        notifications.error({
          message: 'There was an unexpected error creating landing page',
          messageCid: 'create-landing-page-action:notification-error',
        });
        onDone();
      });
    }
  };

  const handleCloseModal = useCallback(() => {
    closeModal(onDone);
  }, [closeModal, onDone]);

  return (
    <CreateLandingPageModal
      onSubmit={handleSubmit}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
    />
  );
};
