import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo/gql';
import { type ApplicationFileStatusInput } from '../../apollo/graphql';

export const MCreateApplicationStatus = graphql(`
  mutation CreateApplicationStatus($partnerId: ID!, $data: ApplicationFileStatusInput!) {
    createApplicationFileStatus(partnerId: $partnerId, data: $data) {
      ...ApplicationFileStatusFragment
    }
  }
`);

export const useCreateApplicationStatus = () => {
  const fn = useMutation(MCreateApplicationStatus);

  return useCallback(
    async (partnerId: string, data: ApplicationFileStatusInput) => {
      const result = await fn({ partnerId, data });

      return result.data!.createApplicationFileStatus;
    },
    [fn]
  );
};
