import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { MResetPreQualificationExpirationDays } from './queries';

export const useResetPreQualificationExpirationDays = () => {
  const resetPreQualificationExpirationDays = useMutation(
    MResetPreQualificationExpirationDays,
    undefined
  );

  return useCallback(
    async (siteId: string) => {
      await resetPreQualificationExpirationDays({
        siteId,
      });
    },
    [resetPreQualificationExpirationDays]
  );
};
