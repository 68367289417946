import { type RouteComponentProps } from 'react-router';

import { WebhookConfigurationsEditor } from '../../../components/webhook-configurations-editor';
import { useSite } from '../../../hooks/use-site';
import { Content, Layout } from '../../../layouts/main';

interface ISiteWebhooksPageRouteParams {
  partnerId: string;
  siteId: string;
}

type ISiteWebhooksPageProps = RouteComponentProps<ISiteWebhooksPageRouteParams>;

export const SiteWebhookConfigurationsPage = (props: ISiteWebhooksPageProps) => {
  const { match } = props;
  const { params } = match;
  const { partnerId, siteId } = params;

  const [site] = useSite(siteId);
  const siteName = site?.name || '';

  return (
    <Layout pageTitle={`${siteName} - Webhook configurations`}>
      <Content title="Webhook configurations" subTitle={siteName}>
        <WebhookConfigurationsEditor partnerId={partnerId} siteId={siteId} />
      </Content>
    </Layout>
  );
};
