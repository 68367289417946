import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { type CredentialsType } from '../../../apollo/graphql';
import { MSaveCredentialMutation } from '../queries';

export interface ISaveCredential {
  partnerId: string;
  siteId?: string;
  credentialType: CredentialsType;
  data: any;
}

export const useSaveCredential = () => {
  const saveCredential = useMutation(MSaveCredentialMutation);

  return useCallback(
    async ({ partnerId, siteId, credentialType, data }: ISaveCredential) => {
      await saveCredential(
        {
          partnerId,
          siteId,
          credentialType,
          data,
        },
        { fetchPolicy: 'no-cache' }
      );
    },
    [saveCredential]
  );
};
