import { type RouteComponentProps } from 'react-router';

import { useSite } from '../../../hooks/use-site';
import { Content, Layout } from '../../../layouts/main';
import { MilestonesPageContent } from './milestones-page-content';

interface IMilestonesPageRouteParams {
  partnerId: string;
  siteId: string;
}

type IMilestonesPageProps = RouteComponentProps<IMilestonesPageRouteParams>;

export const MilestonesPage = (props: IMilestonesPageProps) => {
  const { match } = props;
  const { params } = match;
  const { partnerId, siteId } = params;
  const [site, loading] = useSite(siteId);

  const siteName = site?.name || '';

  return (
    <Layout pageTitle={`${siteName} - Milestones`}>
      <Content title="Milestones" subTitle={siteName}>
        <MilestonesPageContent
          partnerId={partnerId}
          siteId={siteId}
          loading={loading}
          verticalName={site?.verticalName}
        />
      </Content>
    </Layout>
  );
};
