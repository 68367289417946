import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../../../apollo/gql';
import { type PartnerNotificationProfileQuery } from '../../../apollo/graphql';

export type INotificationProfile = NonNullable<
  NonNullable<PartnerNotificationProfileQuery['partner']>['notificationProfile']
>;

export const QPartnerNotificationProfile = graphql(`
  query PartnerNotificationProfile($partnerId: ID!, $profileId: ID!) {
    partner(id: $partnerId) {
      id
      notificationProfile(id: $profileId) {
        ...NotificationProfileFragment
      }
    }
  }
`);

export const QSiteNotificationProfile = graphql(`
  query SiteNotificationProfile($siteId: ID!, $profileId: ID!) {
    site(id: $siteId) {
      id
      notificationProfile(id: $profileId) {
        ...NotificationProfileFragment
      }
    }
  }
`);

export const useNotificationProfile = (
  profileId: string,
  partnerId: string,
  siteId?: string | null,
  options: IUseApolloQueryOptions = {}
) => {
  const isSite = !!siteId;

  const [partnerData, partnerDataLoading, , { refetch: refetchPartnerData }] = useQuery(
    QPartnerNotificationProfile,
    {
      profileId,
      partnerId,
    },
    {
      fetchPolicy: 'network-only',
      ...options,
      skip: options.skip || isSite,
    }
  );

  const [siteData, siteDataLoading, , { refetch: refetchSiteData }] = useQuery(
    QSiteNotificationProfile,
    {
      profileId,
      siteId: siteId!,
    },
    {
      fetchPolicy: 'network-only',
      ...options,
      skip: options.skip || !isSite,
    }
  );

  const handleRefetch = useSafeCallback(async () => {
    if (isSite) {
      return refetchSiteData({ profileId, siteId });
    }
    return refetchPartnerData({ profileId, partnerId });
  });

  const result: INotificationProfile | null | undefined = isSite
    ? siteData?.site?.notificationProfile
    : partnerData?.partner?.notificationProfile;
  const loading = partnerDataLoading || siteDataLoading;

  return [result, loading, handleRefetch] as const;
};
