import { useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo/gql';

const QSagaRunnerVersion = graphql(`
  query GetSagaRunnerVersion($partnerId: ID!, $siteId: ID) {
    saga {
      sagaRunnerVersion(partnerId: $partnerId, siteId: $siteId)
    }
  }
`);

export const useSagaRunnerVersion = (partnerId: string, siteId?: string) => {
  const [data, ...rest] = useQuery(QSagaRunnerVersion, {
    partnerId,
    siteId,
  });
  const result = data?.saga?.sagaRunnerVersion;
  return [result, ...rest] as const;
};
