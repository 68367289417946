import { type GraphQLError } from '@mortgagehippo/apollo-hooks';
import { ButtonLink, Format, notifications } from '@mortgagehippo/ds';
import {
  type IQueryTableColumns,
  type IQueryTableDataActions,
  QueryTable,
} from '@mortgagehippo/query-components';
import { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import { type RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import {
  type BlueprintListQuery,
  type BlueprintListQueryVariables,
  BlueprintType,
} from '../../apollo/graphql';
import { QBlueprintList } from '../../hooks/use-blueprints';
import { usePartner } from '../../hooks/use-partner';
import { Content, Layout } from '../../layouts/main';
import { useDeleteBlueprint } from './use-delete-blueprint';

const toUserInputError = (exception: any) => {
  if (!exception.graphQLErrors) {
    return undefined;
  }

  const errors = exception.graphQLErrors;

  const validationErrors: GraphQLError[] = errors.filter(
    (ge: GraphQLError) => ge.extensions && ge.extensions.code === 'BAD_USER_INPUT'
  );

  return validationErrors.length ? validationErrors[0]!.message : undefined;
};

interface IBlueprintsPageParams {
  partnerId: string;
}

const TextList = (props: { labels: string[]; max: number }) => {
  const { labels: l, max } = props;
  const [showAll, setShowAll] = useState(false);
  const handleShowAll = useCallback(() => {
    setShowAll(true);
  }, []);

  const rest = [...(l || [])];
  const visible = showAll ? rest : rest.slice(0, max || 3);

  return (
    <>
      {visible.map((str, i, a) => {
        const last = i === a.length - 1;
        return (
          <Fragment key={str}>
            {str}
            {!last && <br />}
          </Fragment>
        );
      })}
      {rest.length > visible.length ? (
        <>
          <br />
          <ButtonLink onClick={handleShowAll}>+{rest.length - visible.length} more</ButtonLink>
        </>
      ) : null}
    </>
  );
};

export const BlueprintsPage = (props: RouteComponentProps<IBlueprintsPageParams>) => {
  const { history, match } = props;
  const { params } = match;
  const { partnerId } = params;
  const ref = useRef<any>();

  const [partner] = usePartner(partnerId);
  const deleteBlueprint = useDeleteBlueprint();
  const columns: IQueryTableColumns = useMemo(
    () => [
      {
        title: 'Name',
        key: 'name',
        render: ({ id, name }) => (
          <Link to={`/partners/${partnerId}/blueprints/${id}`}>{name}</Link>
        ),
      },
      {
        title: 'Type',
        key: 'type',
        render: (t) => {
          switch (t.type) {
            case BlueprintType.LenderPortalBlueprint: {
              return 'Lender Hub';
            }
            case BlueprintType.BorrowerPortalBlueprint: {
              return 'Borrower Hub';
            }
            default:
              return '';
          }
        },
      },
      {
        title: 'Sites',
        key: 'sites',
        render: ({ assignments }) => {
          const sites = assignments.map((s: any) => s.name);
          return <TextList labels={sites} max={2} />;
        },
      },
      {
        title: 'Created at',
        key: 'created_at',
        render: ({ createdAt }) => <Format.Date value={createdAt} format="date-long-time" />,
      },
      {
        title: 'Updated at',
        key: 'updated_at',
        render: ({ updatedAt }) => <Format.Date value={updatedAt} format="date-long-time" />,
      },
    ],
    [partnerId]
  );

  const handleAction = useCallback(
    async (action: string, record: any) => {
      if (action === 'delete') {
        try {
          await deleteBlueprint(record.id);
          await ref.current.refetch();
        } catch (error) {
          const message = toUserInputError(error);
          const defaultMessage =
            'There was an error processing your request, please try again later';
          notifications.error({ message: message || defaultMessage });
        }
      }
    },
    [deleteBlueprint]
  );

  const handleAdd = useCallback(() => {
    history.push(`/partners/${partnerId}/blueprints/new`);
  }, [history, partnerId]);

  const actions: IQueryTableDataActions = useMemo(
    () => [
      {
        key: 'options',
        label: 'Options',
        buttonProps: {
          icon: 'menu-dots',
        },
        onGroupAction: handleAction,
        actions: [
          {
            key: 'delete',
            label: 'Delete',
            iconProps: {
              name: 'delete',
            },
            confirm: {
              title: 'Warning',
              explanation:
                'Are you sure you want to delete this blueprint? This action cannot be undone.',
              type: 'warning',
            },
          },
        ],
      },
    ],
    [handleAction]
  );

  const topActions: IQueryTableDataActions = useMemo(
    () => [
      {
        key: 'create-blueprint',
        label: 'Create new blueprint',
        buttonProps: {
          icon: 'plus',
        },
        onAction: handleAdd,
      },
    ],
    [handleAdd]
  );

  return (
    <Layout pageTitle="Partners Management">
      <Content title="Blueprints" subTitle={partner?.name || ''}>
        <QueryTable<BlueprintListQuery, BlueprintListQueryVariables>
          caption="Blueprints"
          ref={ref}
          query={QBlueprintList}
          variables={{ partnerId }}
          dataSource={(result) => result.partner?.blueprints.items || []}
          itemTotal={(result) => result.partner?.blueprints.total || 0}
          nextCursor={(result) => result.partner?.blueprints.nextCursor}
          previousCursor={(result) => result.partner?.blueprints.previousCursor}
          rowKey={(item) => item.id}
          columns={columns}
          rowActions={actions}
          topActions={topActions}
          bottomContent={<QueryTable.Pagination />}
          hideTopBackground
        >
          <QueryTable.Data />
        </QueryTable>
      </Content>
    </Layout>
  );
};
