import { useMutation } from '@mortgagehippo/apollo-hooks';
import {
  Button,
  Form,
  type ITableActions,
  type ITableCols,
  notifications,
  spacing,
  SubmitButton,
  Table,
  Title,
} from '@mortgagehippo/ds';
import { UnreachableCaseError } from '@mortgagehippo/util';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { ParentType } from '../../apollo/graphql';
import { CreateWebhookForm } from './create-webhook-form';
import { type IEncompassWebhook, useEncompassWebhooks } from './hooks/use-encompass-webhooks';
import { MCreateEncompassWebhookMutation, MDeleteEncompassWebhookMutation } from './queries';

const FormContainer = styled.div`
  padding: ${spacing(5)} ${spacing(6)};
  max-width: 750px;
  margin: auto;
`;

const Scrollable = styled.div`
  overflow-x: auto;
`;

const Buttons = styled.div`
  text-align: right;
  padding: ${spacing(1)} 0;

  ${Button} {
    margin-bottom: 0;
  }
`;

const columns: ITableCols<IEncompassWebhook> = [
  {
    title: 'Endpoint',
    key: 'endpoint',
    render: (w) => w.endpoint,
  },
  {
    title: 'Signing Key',
    key: 'signingKey',
    render: (w) => w.signingkey,
  },
  {
    title: 'Resource',
    key: 'resource',
    render: (w) => w.resource,
  },
  {
    title: 'Events',
    key: 'events',
    render: (w) => w.events && JSON.stringify(w.events),
  },
  {
    title: 'Filters',
    key: 'filters',
    render: (w) => w.filters && JSON.stringify(w.filters),
  },
];

enum EncompassWebhooksEditorsActionType {
  DELETE = 'delete',
}

interface IEncompassWebhooksEditorProps {
  partnerId: string;
  siteId?: string;
}

export const EncompassWebhooksEditor = (props: IEncompassWebhooksEditorProps) => {
  const { partnerId, siteId } = props;
  const parentType = siteId ? ParentType.Site : ParentType.Partner;
  const parentId = siteId || partnerId;

  const [webhooks, loading, refetch] = useEncompassWebhooks(partnerId, siteId);

  const createWebhook = useMutation(MCreateEncompassWebhookMutation);

  const deleteWebhook = useMutation(MDeleteEncompassWebhookMutation);

  const handleAction = useCallback(
    async (actionKey: string, record: IEncompassWebhook) => {
      const actionType = actionKey as EncompassWebhooksEditorsActionType;
      switch (actionType) {
        case EncompassWebhooksEditorsActionType.DELETE:
          await deleteWebhook({
            id: record.subscriptionId,
            parentType,
            parentId,
          });
          refetch();
          break;
        default: {
          throw new UnreachableCaseError(actionType);
        }
      }
    },
    [deleteWebhook, parentId, parentType, refetch]
  );

  const [creating, setCreating] = useState(false);

  const handleAdd = useCallback(() => {
    setCreating(true);
  }, []);

  const handleAddClose = useCallback(() => {
    setCreating(false);
  }, []);

  const handleSave = useCallback(
    async (data: any) => {
      try {
        const response = await createWebhook({
          parentType,
          parentId,
          ...data,
          filters: data.filters && JSON.parse(data.filters),
        });

        if (
          response.data?.createEncompassWebhook &&
          response.data.createEncompassWebhook.statusCode === 'ERROR'
        ) {
          notifications.error({
            message: `Error creating webhook: ${response.data.createEncompassWebhook.statusDescription}`,
          });
        } else {
          notifications.success({ message: 'Your changes have been saved.' });
          refetch();
          handleAddClose();
        }
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [createWebhook, parentType, parentId, refetch, handleAddClose]
  );

  const actions: ITableActions<IEncompassWebhook> = useMemo(
    () => [
      {
        key: 'options',
        label: 'Options',
        buttonProps: {
          icon: 'menu-dots',
          iconSize: 'md',
        },
        onGroupAction: handleAction,
        actions: [
          {
            key: EncompassWebhooksEditorsActionType.DELETE,
            label: 'Delete',
            iconProps: {
              name: 'delete',
            },
            confirm: {
              title: 'Warning',
              explanation:
                'Are you sure you want to delete this webhook? This action cannot be undone.',
              type: 'warning',
            },
          },
        ],
      },
    ],
    [handleAction]
  );

  const topActions: ITableActions<IEncompassWebhook> = useMemo(
    () => [
      {
        key: 'add-webhook',
        label: 'Add webhook',
        buttonProps: {
          icon: 'plus',
        },
        onAction: handleAdd,
      },
    ],
    [handleAdd]
  );

  return (
    (!creating && (
      <Scrollable>
        <Table<IEncompassWebhook>
          caption="Webhooks"
          data={webhooks}
          cols={columns}
          loading={loading}
          rowActions={actions}
          topActions={topActions}
          emptyText="No webhooks."
          rowKey={(item) => item.subscriptionId}
          size="sm"
        />
      </Scrollable>
    )) || (
      <FormContainer>
        <Form onSubmit={handleSave}>
          <Title level={4}>Create New Webhook</Title>
          <CreateWebhookForm />
          <Buttons>
            <Button importance="tertiary" onClick={handleAddClose}>
              Cancel
            </Button>
            <SubmitButton importance="primary">Create webhook</SubmitButton>
          </Buttons>
        </Form>
      </FormContainer>
    )
  );
};
