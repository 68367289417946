import { useMutation, useQuery } from '@mortgagehippo/apollo-hooks';
import { notifications } from '@mortgagehippo/ds';
import { sortBy } from 'lodash-es';
import { useCallback, useMemo } from 'react';

import { AuthRoleModal } from './auth-role-modal';
import {
  MCreateAuthRoleMutation,
  MUpdateAuthRoleMutation,
  QAuthRoleData,
  QPermissionsList,
} from './queries';
import { getInitialValues, toServerInput } from './util';

interface IAuthRoleModalContainerProps {
  partnerId: string;
  authRoleId?: string;
  onRequestClose: () => void;
  onSubmit: () => void;
  isOpen: boolean;
}

export const AuthRoleModalContainer = (props: IAuthRoleModalContainerProps) => {
  const { partnerId, authRoleId, onSubmit, onRequestClose, isOpen } = props;

  const [authRoleData, authRoleDataLoading] = useQuery(
    QAuthRoleData,
    {
      partnerId,
      authRoleId: authRoleId!,
    },
    {
      skip: !authRoleId,
      fetchPolicy: 'network-only',
    }
  );

  const [permissionsData, permissionsLoading] = useQuery(QPermissionsList, {});

  const addAuthRole = useMutation(MCreateAuthRoleMutation);

  const updateAuthRole = useMutation(MUpdateAuthRoleMutation);

  const handleSubmit = useCallback(
    async (values: any) => {
      const submitData = toServerInput(values);
      let successMsg = '';
      try {
        if (authRoleId) {
          await updateAuthRole({ authRoleId, data: submitData });
          successMsg = 'The Access Level was successfully updated.';
        } else {
          await addAuthRole({ partnerId, data: submitData });
          successMsg = 'The Access Level was successfully created.';
        }

        notifications.success({ message: successMsg });

        onSubmit();
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [addAuthRole, authRoleId, onSubmit, partnerId, updateAuthRole]
  );

  const permissionOptions = useMemo(() => {
    if (!permissionsData?.permissions) {
      return [];
    }

    const options = permissionsData.permissions.map((p) => {
      const { id, resourceName, actionName } = p;

      return {
        label: `${resourceName}:${actionName}`,
        value: id,
      };
    });

    return sortBy(options, 'label');
  }, [permissionsData]);

  const initialValues = useMemo(() => getInitialValues(authRoleData), [authRoleData]);

  const title = !authRoleId ? 'Add Access Level' : 'Edit Access Level';

  return (
    <AuthRoleModal
      title={title}
      loading={authRoleDataLoading || permissionsLoading}
      onSubmit={handleSubmit}
      onRequestClose={onRequestClose}
      initialValues={initialValues}
      permissionOptions={permissionOptions}
      isOpen={isOpen}
    />
  );
};
