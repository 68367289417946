import { fontFamily, fontSize, fontWeight, palette, spacing } from '@mortgagehippo/ds';
import styled from 'styled-components';

export const MenuTitle = styled('div')`
  &&& {
    font-size: ${fontSize('normal')};
    line-height: 1.3em;
    margin: ${spacing(5)} 0 ${spacing(4)} ${spacing(2)};
    padding-bottom: ${spacing(4)};
    border-bottom: 1px solid ${palette('neutral700')};
    font-family: ${fontFamily('secondary')};

    strong {
      color: ${palette('neutral50')};
      font-weight: ${fontWeight('semibold', 'secondary')};
    }

    > span {
      color: ${palette('neutral200')};
      font-weight: ${fontWeight('light', 'secondary')};

      &:after {
        color: ${palette('neutral600')};
        content: ' \\2192';
      }
    }

    a,
    a:visited {
      color: ${palette('neutral200')};
      font-weight: ${fontWeight('light', 'secondary')};

      span {
        white-space: normal;
        display: inline;
      }
    }

    a {
      &:hover,
      &:focus,
      &:active {
        text-decoration: underline !important;
        color: ${palette('white')} !important;
      }
    }
  }
`;
