import Editor, { type OnChange } from '@monaco-editor/react';
import { borderRadius, palette, spacing, Spinner } from '@mortgagehippo/ds';
import { useDebouncedEffect, useMountedRef, useSafeCallback } from '@mortgagehippo/util';
import { useMemo, useRef } from 'react';
import styled from 'styled-components';

export interface ICodeEditorValidatorResult {
  message: string;
  severity: string;
  startColumn?: number;
  endColumn?: number;
  startLineNumber?: number;
  endLineNumber?: number;
}

export type ICodeEditorValidator = (value: string) => Promise<ICodeEditorValidatorResult[]>;

export type CodeEditorMode = 'ruby' | 'javascript' | 'json' | 'css' | 'html' | 'scss';
interface ICodeEditorProps {
  mode: CodeEditorMode;
  value: string;
  onChange?: (value: string) => void;
  onValidate?: ICodeEditorValidator;
  wrap?: boolean;
  height?: string;
  tabSize?: number;
  detectIndentation?: boolean;
  path?: string;
}

const VALIDATE_DEBOUNCE_MS = 2500;

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: ${palette('neutral200')};
  border-radius: ${borderRadius(2)};
  padding: ${spacing(3)};
  background: #1e1e1e; // editor background
`;

const EditorContainer = styled.div`
  .monaco-editor,
  .monaco-scrollable-element,
  .margin {
    border-radius: ${borderRadius(2)};
  }
`;

export const CodeEditor = (props: ICodeEditorProps) => {
  const { value, mode, onChange, onValidate, wrap, height, tabSize, detectIndentation, path } =
    props;
  const editorRef = useRef<any>(null);
  const monacoRef = useRef<any>(null);
  const mounted = useMountedRef();

  const options = useMemo(
    () => ({
      tabSize: tabSize || 2,
      wordWrap: wrap ? 'on' : 'off',
      detectSpaces: detectIndentation !== undefined ? detectIndentation : false,
      minimap: {
        enabled: false,
      },
    }),
    [tabSize, wrap, detectIndentation]
  );

  const handleEditorMount = useSafeCallback((editor: any, monaco: any) => {
    editorRef.current = editor;
    monacoRef.current = monaco;
  });

  const handleValidate = useSafeCallback(async (nextValue: string) => {
    if (onValidate) return onValidate(nextValue);
    return [];
  });

  const handleChange: OnChange = useSafeCallback((nextValue) => {
    if (!mounted.current) {
      return;
    }

    if (onChange) onChange(nextValue || '');
  });

  useDebouncedEffect(
    () => {
      let canceled = false;
      (async () => {
        if (canceled) return;

        const result = await handleValidate(value);

        const editor = editorRef.current!;
        const monaco = monacoRef.current!;
        const model = editor?.getModel();
        if (!editor || !monaco || !model) return;

        const errorLevels: Record<string, number> = {
          error: monaco.MarkerSeverity.Error,
          warning: monaco.MarkerSeverity.Warning,
          info: monaco.MarkerSeverity.Info,
          hint: monaco.MarkerSeverity.Hint,
        };

        const markers = result.map((error: any) => ({
          message: error.message,
          severity: errorLevels[error.severity] || monaco.MarkerSeverity.Error,
          startLineNumber: error.lineStart || 0,
          endLineNumber: error.lineEnd || 0,
          startColumn: error.colStart,
          endColumn: error.colEnd,
        }));

        monaco.editor.setModelMarkers(model, 'hippo', markers);
      })();
      return () => {
        canceled = false;
      };
    },
    { wait: VALIDATE_DEBOUNCE_MS },
    [handleValidate, value]
  );

  return (
    <EditorContainer>
      <Editor
        height={height || '60vh'}
        theme="vs-dark"
        language={mode}
        value={value}
        path={path}
        onMount={handleEditorMount}
        onChange={handleChange}
        options={options}
        loading={
          <LoadingContainer>
            <Spinner /> Loading code editor...
          </LoadingContainer>
        }
      />
    </EditorContainer>
  );
};
