import {
  type FormSubmitHandler,
  type IFile,
  type ISelectOption,
  notifications,
  type UploadHandler,
} from '@mortgagehippo/ds';
import axios, { type AxiosProgressEvent } from 'axios';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { type LandingPageType } from '../../../apollo/graphql';
import { toResourcesInitialValues } from '../util';
import { PageComponentEditModal } from './page-component-edit-modal';
import { useCreatePageComponentResourceUploadUrl } from './use-create-page-component-resource-upload-url';
import { usePageComponent } from './use-page-component';

interface IPageComponentEditModalContainerProps {
  landingPageType: LandingPageType;
  siteId: string;
  landingPageId: string;
  pageComponentId: string | undefined;
  languages: ISelectOption[];
  currentLanguage?: string;
  onSubmit?: FormSubmitHandler;
  onRequestClose?: () => void;
  isOpen: boolean;
}

export const PageComponentEditModalContainer = (props: IPageComponentEditModalContainerProps) => {
  const {
    landingPageType,
    siteId,
    landingPageId,
    pageComponentId,
    onRequestClose,
    isOpen,
    ...rest
  } = props;
  const notified = useRef(false);

  const [{ data, loading }] = usePageComponent(
    landingPageType,
    siteId,
    landingPageId,
    pageComponentId!,
    {
      skip: !pageComponentId,
    }
  );

  const createPageComponentResourceUploadUrl = useCreatePageComponentResourceUploadUrl();

  useEffect(() => {
    if (isOpen) {
      notified.current = false;
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && pageComponentId && !loading && !data && !notified.current) {
      notifications.error({
        messageCid: 'edit-page-component:not-found-error',
        message: 'The specified page component was not found.',
      });
      notified.current = true;

      if (onRequestClose) {
        onRequestClose();
      }
    }
  }, [data, isOpen, loading, onRequestClose, pageComponentId]);

  const handleUpload: UploadHandler = useCallback(
    async (file: File, meta: IFile, progress: (p: number) => void) => {
      try {
        const response = await createPageComponentResourceUploadUrl(
          pageComponentId!,
          meta.filename
        );

        const { downloadUrl, uploadUrl } = response;

        await axios.put(uploadUrl, file, {
          headers: {
            'Content-Type': meta.mime,
          },
          onUploadProgress: (e: AxiosProgressEvent) => {
            if (!e.total) return;
            const percent = Math.round((e.loaded * 100) / e.total);
            progress(percent);
          },
        });

        return { id: downloadUrl };
      } catch (e) {
        notifications.error({ message: 'There was an unexpected error while uploading your file' });
        throw e;
      }
    },
    [createPageComponentResourceUploadUrl, pageComponentId]
  );

  const initialValues = useMemo(() => {
    if (!data) {
      return {};
    }

    const { config, resources: initialResources } = data;
    const resources = toResourcesInitialValues(initialResources, data.customizationValues);

    return {
      config,
      resources,
    };
  }, [data]);

  if (loading || !data) {
    return null;
  }

  return (
    <PageComponentEditModal
      type={data.typeName}
      initialValues={initialValues}
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      onUpload={handleUpload}
      {...rest}
    />
  );
};
