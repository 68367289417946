/*
 * TODO: must refactor this code to abstract each value editor into a
 * separate component along with its special functionality.
 */

import {
  Button,
  Input,
  isEmptyCustomizationToken,
  Numeric,
  spacing,
  Text,
} from '@mortgagehippo/ds';
import { type ReactNode, useCallback } from 'react';
import styled from 'styled-components';

import { EditorField } from '$components/editor-field';

import { type ICustomizationsEditorResource } from '../../../types';
import { RESOURCE_INSTRUCTIONS } from '../constants/resource-instructions';
import { TYPE_INSTRUCTIONS } from '../constants/type-instructions';
import { BoolEditor } from './bool-editor';
import { ColorEditor } from './color-editor';
import { FontSizeEditor } from './font-size-editor';
import { FontWeightEditor } from './font-weight-editor';
import { HtmlEditor } from './html-editor';
import { ImageEditor } from './image-editor';
import { JsonEditor } from './json-editor';
import { ScriptEditor } from './script-editor';
import { ShadowEditor } from './shadow-editor';
import { SpacingEditor } from './spacing-editor';
import { StyleEditor } from './style-editor';

const Label = styled.label`
  display: block;
  margin-bottom: -1px;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

const Actions = styled.div`
  margin-top: ${spacing(3)};
  text-align: right;
`;

const Instructions = styled.div`
  margin-top: ${spacing(4)};
`;

const replaceNewLineWithBr = (text: string) => text.replace(/\n/g, '<br />');

interface IValueEditorProps {
  showReset?: boolean;
  onReset: () => void;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  resource: ICustomizationsEditorResource;
  partnerId: number;
  domain?: string | null;
  language: string;
  projectId: string;
}

export const ValueEditor = (props: IValueEditorProps) => {
  const {
    showReset,
    onReset,
    value,
    onChange,
    disabled,
    resource,
    partnerId,
    domain,
    language,
    projectId,
  } = props;

  const labelId = `label_${resource.type}`;

  const handleReset = useCallback(() => {
    onReset();
  }, [onReset]);

  const handleContentChange = useCallback(
    (nextValue: string) => {
      // strip out the p tags from <p>[empty]</p>
      const doc = new DOMParser().parseFromString(nextValue, 'text/html');
      const textContent = doc.body.textContent || '';

      if (isEmptyCustomizationToken(textContent)) {
        onChange(textContent);
        return;
      }

      onChange(nextValue);
    },
    [onChange]
  );

  let inputEl: ReactNode;
  switch (resource.type) {
    case 'style': {
      inputEl = <StyleEditor value={value} onChange={onChange} />;
      break;
    }
    case 'image': {
      inputEl = (
        <ImageEditor
          value={value}
          onChange={onChange}
          disabled={disabled}
          labelId={labelId}
          partnerId={partnerId}
          domain={domain}
          language={language}
          resourceId={resource.id}
        />
      );
      break;
    }
    case 'bool': {
      inputEl = (
        <BoolEditor value={value} onChange={onChange} labelId={labelId} disabled={disabled} />
      );
      break;
    }
    case 'fontWeight': {
      inputEl = (
        <FontWeightEditor value={value} onChange={onChange} labelId={labelId} disabled={disabled} />
      );
      break;
    }
    case 'fontSize': {
      inputEl = (
        <FontSizeEditor value={value} onChange={onChange} labelId={labelId} disabled={disabled} />
      );
      break;
    }
    case 'spacing': {
      inputEl = (
        <SpacingEditor value={value} onChange={onChange} labelId={labelId} disabled={disabled} />
      );
      break;
    }
    case 'shadow': {
      inputEl = (
        <ShadowEditor value={value} onChange={onChange} labelId={labelId} disabled={disabled} />
      );
      break;
    }
    case 'color': {
      inputEl = (
        <ColorEditor
          onChange={onChange}
          value={value}
          labelId={labelId}
          disabled={disabled}
          domain={domain}
          language={language}
          projectId={projectId}
          partnerId={partnerId}
        />
      );
      break;
    }
    case 'number': {
      inputEl = (
        <Numeric.Input
          name="customization_value"
          aria-labelledby={labelId}
          value={value}
          onChange={onChange}
          disabled={disabled}
          compact
        />
      );
      break;
    }
    case 'theme': {
      inputEl = (
        <Input.Input
          name="customization_value"
          aria-labelledby={labelId}
          value={value}
          onChange={onChange}
          disabled={disabled}
          compact
        />
      );
      break;
    }
    case 'script': {
      inputEl = <ScriptEditor value={value} onChange={onChange} />;
      break;
    }
    case 'json': {
      inputEl = <JsonEditor value={value} onChange={onChange} />;
      break;
    }
    case 'html': {
      inputEl = <HtmlEditor value={value} onChange={onChange} />;

      break;
    }
    case 'content': {
      /*
       * Use the resourceKey so that we forcefully unmount the component
       * and bypass the onChange event called with the new value
       */
      inputEl = (
        <EditorField.Input
          key={resource.key}
          name="customization_value"
          value={value}
          onChange={handleContentChange}
          disabled={disabled}
        />
      );
      break;
    }
    case 'text':
    default: {
      inputEl = (
        <Input.Input
          name="customization_value"
          aria-labelledby={labelId}
          value={value}
          onChange={onChange}
          disabled={disabled}
          compact
        />
      );
      break;
    }
  }

  let typeInstructions: ReactNode = null;
  let resourceInstructions: ReactNode = null;

  if (resource.type && (TYPE_INSTRUCTIONS as any)[resource.type]) {
    typeInstructions = replaceNewLineWithBr((TYPE_INSTRUCTIONS as any)[resource.type]);
  }

  if (RESOURCE_INSTRUCTIONS[resource.namespace]?.[resource.key]) {
    resourceInstructions = replaceNewLineWithBr(
      RESOURCE_INSTRUCTIONS[resource.namespace]![resource.key]!
    );
  }

  return (
    <>
      <Label id={labelId}>{resource.key}</Label>
      {inputEl}

      <Actions>
        {showReset ? (
          <Button type="neutral" size="xs" onClick={handleReset} compact icon="undo">
            Undo change
          </Button>
        ) : null}
      </Actions>

      {resourceInstructions || typeInstructions ? (
        <Instructions>
          {resourceInstructions ? (
            <Text as="p" size="sm" variant="secondary">
              <strong>INSTRUCTIONS: </strong>
              {/* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: resourceInstructions }} />
            </Text>
          ) : null}
          {typeInstructions ? (
            <Text as="p" size="sm" variant="secondary">
              <strong>CONTENT TYPE: </strong>
              {/* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: typeInstructions }} />
            </Text>
          ) : null}
        </Instructions>
      ) : null}
    </>
  );
};
