import {
  ButtonLink,
  ColumnOrderFieldType,
  type ISearchFields,
  type ISearchFilter,
  Search,
  SearchFieldType,
  TableColSortDirection,
  useTableSort,
} from '@mortgagehippo/ds';
import {
  type IQueryTableColumns,
  type IQueryTableDataActions,
  QueryTable,
} from '@mortgagehippo/query-components';
import { type MouseEvent, useCallback, useMemo, useRef, useState } from 'react';
import { type RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import { ActionType, useDispatchAction } from '$components/actions';

import { type PartnersListQuery, type PartnersListQueryVariables } from '../../apollo/graphql';
import { Content, Layout } from '../../layouts/main';
import { QPartnersList } from './queries';

export type IPartner = NonNullable<PartnersListQuery['partners']['items']>[0];

const DEFAULT_ORDER_COLUMN = 'name';
const DEFAULT_ORDER_DIRECTION: TableColSortDirection = TableColSortDirection.ASCENDING;

const COLUMNS: IQueryTableColumns<IPartner> = [
  {
    key: 'name',
    title: 'Name',
    sortable: true,
    type: ColumnOrderFieldType.STRING,
    render: ({ id, name }) => <Link to={`/partners/${id}`}>{name}</Link>,
  },
  {
    key: 'lender_portal_domain',
    title: 'Lender Hub Domain',
    sortable: true,
    type: ColumnOrderFieldType.STRING,
    render: ({ adminDomain }) => (
      <ButtonLink
        href={`https://${adminDomain}`}
        target={adminDomain}
        icon="open-in-new"
        iconSize="xs"
        iconLocation="right"
      >
        {adminDomain}
      </ButtonLink>
    ),
  },
];

const SEARCH_FIELDS: ISearchFields = {
  name: {
    label: 'Name',
    type: SearchFieldType.STRING,
  },
  lender_portal_domain: {
    label: 'Lender Hub Domain',
    type: SearchFieldType.STRING,
  },
};

export const PartnersPage = ({ history }: RouteComponentProps) => {
  const tableRef = useRef<any>();
  const sortProps = useTableSort(DEFAULT_ORDER_COLUMN, DEFAULT_ORDER_DIRECTION);
  const [searchFilters, setSearchFilters] = useState<ISearchFilter[] | undefined>();

  const dispatch = useDispatchAction();

  const handlePartnerAction = useCallback(
    async (actionKey: string, record: IPartner) => {
      switch (actionKey) {
        case 'edit':
          dispatch(
            {
              type: ActionType.EDIT_PARTNER,
              partnerId: record.id,
            },
            () => {
              if (tableRef) {
                tableRef.current.refetch();
              }
            }
          );
          break;
        default: {
          throw new Error(`Partners Page: Unknown action ${actionKey}`);
        }
      }
    },
    [dispatch]
  );

  const handleClickRow = useCallback(
    (record: IPartner, _index: number, clickDetails?: MouseEvent) => {
      if (clickDetails?.altKey || clickDetails?.metaKey) {
        window.open(`/partners/${record.id}`);
      } else {
        history.push(`/partners/${record.id}`);
      }
    },
    [history]
  );

  const handleMiddleClickRow = useCallback((key: string) => {
    window.open(`/partners/${key}`);
  }, []);

  const rowActions: IQueryTableDataActions = useMemo(
    () => [
      {
        key: 'options',
        label: 'Options',
        buttonProps: {
          icon: 'menu-dots',
        },
        onGroupAction: handlePartnerAction,
        actions: [
          {
            key: 'edit',
            label: 'Edit',
            iconProps: {
              name: 'edit',
            },
          },
        ],
      },
    ],
    [handlePartnerAction]
  );

  const topActions: IQueryTableDataActions = useMemo(
    () => [
      {
        key: 'add-partner',
        label: 'Add partner',
        buttonProps: {
          icon: 'plus',
        },
        onAction: () => {
          dispatch(
            {
              type: ActionType.CREATE_PARTNER,
            },
            () => {
              if (tableRef) {
                tableRef.current.refetch();
              }
            }
          );
        },
      },
    ],
    [dispatch]
  );

  const orderBy = useMemo(() => {
    const { sortColumn, sortDirection } = sortProps;

    if (!sortColumn || !sortDirection) {
      return undefined;
    }

    return {
      [sortColumn]: sortDirection,
    };
  }, [sortProps]);

  const topContent = (
    <Search
      fields={SEARCH_FIELDS}
      showSearchBarIcon
      label="Search"
      size="sm"
      search={searchFilters}
      onChange={setSearchFilters}
    />
  );

  return (
    <Layout pageTitle="Partners Management">
      <Content title="Partners">
        <QueryTable<PartnersListQuery, PartnersListQueryVariables>
          ref={tableRef}
          caption="Partners"
          query={QPartnersList}
          variables={{
            orderBy,
            criteria: searchFilters as any,
          }}
          dataSource={(result) => result.partners.items}
          itemTotal={(result) => result.partners.itemsTotal}
          rowKey={(item) => item.id}
          rowActions={rowActions}
          topActions={topActions}
          columns={COLUMNS}
          sortProps={sortProps}
          onRowClick={handleClickRow}
          onRowMiddleClick={handleMiddleClickRow}
          topContent={topContent}
          bottomContent={<QueryTable.Pagination />}
        >
          <QueryTable.Data />
        </QueryTable>
      </Content>
    </Layout>
  );
};
