import { graphql } from '../../apollo/gql';

export const FCustomizationResourceFragment = graphql(`
  fragment CustomizationResourceFragment on CustomizationResource {
    id
    key
    type
    namespace
  }
`);

export const FCustomizationValueFragment = graphql(`
  fragment CustomizationValueFragment on CustomizationValue {
    id
    key
    type
    namespace
    value
    language
    source
  }
`);

export const QCustomizationEditorQuery = graphql(`
  query CustomizationEditor(
    $projectId: String!
    $partnerId: Int!
    $domain: String
    $type: String
    $language: String
    $namespace: String
    $key: String
  ) {
    customizationResources(projectId: $projectId, type: $type, namespace: $namespace, key: $key) {
      ...CustomizationResourceFragment
    }
    customizationValues(
      projectId: $projectId
      partnerId: $partnerId
      domain: $domain
      type: $type
      language: $language
      namespace: $namespace
    ) {
      ...CustomizationValueFragment
    }
  }
`);

export const QCustomizationEditorNamespaces = graphql(`
  query CustomizationEditorNamespaces($projectId: String!) {
    customizationNamespaces(projectId: $projectId)
  }
`);

export const MCreateCustomizationResourceUploadUrl = graphql(`
  mutation CreateCustomizationResourceUploadUrl(
    $resourceId: ID!
    $data: CreateCustomizationResourceUploadUrlInput!
  ) {
    createCustomizationResourceUploadUrl(resourceId: $resourceId, data: $data) {
      uploadUrl
      downloadUrl
    }
  }
`);

export const MSetCustomizationValues = graphql(`
  mutation SetCustomizationValues($data: [SetCustomizationValueInput!]!) {
    setCustomizationValues(data: $data) {
      ...CustomizationValueFragment
    }
  }
`);

export const MDeleteCustomizationValue = graphql(`
  mutation DeleteCustomizationValue($id: ID!) {
    deleteCustomizationValue(id: $id) {
      ...CustomizationValueFragment
    }
  }
`);
