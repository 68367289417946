import { graphql } from '../../apollo/gql';

export const QGetServiceJobs = graphql(`
  query GetServiceJobs($page: Int!, $perPage: Int!, $jobType: JobType, $orderBy: JSON) {
    getServiceJobs(
      jobType: $jobType
      options: { page: $page, perPage: $perPage, orderBy: $orderBy }
    ) {
      items {
        id
        jobId
        jobStatus
        jobType
        partnerId
        model
        progress
        totalBatchCount
        currentBatchCount
        startDate
        repeatableJobKey
        manuallyPaused
        manuallyCanceled
        createdAt
      }
      itemsTotal
      itemsCount
      pageNumber
      pageCount
    }
  }
`);

export const QGetRepeatableServiceJobs = graphql(`
  query GetRepeatableServiceJobs($page: Int!, $perPage: Int!, $orderBy: JSON) {
    getRepeatableServiceJobs(options: { page: $page, perPage: $perPage, orderBy: $orderBy }) {
      items {
        id
        jobType
        partnerId
        model
        cancellable
        cronSchedule
        startDate
        repeatMillisInterval
        repeatableJobKey
        lastRan
        lastStatus
        createdAt
      }
      itemsTotal
      itemsCount
      pageNumber
      pageCount
    }
  }
`);
