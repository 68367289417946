import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';
import { compact } from 'lodash-es';

import {
  type PartnerEncompassWebhooksQuery,
  type SitePartnerWebhooksQuery,
} from '../../../apollo/graphql';
import { QPartnerEncompassWebhooks, QSiteEncompassWebhooks } from '../queries';

export type IEncompassWebhook =
  | NonNullable<
      NonNullable<NonNullable<PartnerEncompassWebhooksQuery['partner']>['encompassWebhooks']>[0]
    >
  | NonNullable<NonNullable<NonNullable<SitePartnerWebhooksQuery['site']>['encompassWebhooks']>[0]>;

export const useEncompassWebhooks = (partnerId: string, siteId?: string | null) => {
  const isSite = !!siteId;

  const [partnerData, partnerDataLoading, , { refetch: refetchPartnerData }] = useQuery(
    QPartnerEncompassWebhooks,
    {
      partnerId,
    },
    {
      suspend: false,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      skip: isSite,
    }
  );

  const [siteData, siteDataLoading, , { refetch: refetchSiteData }] = useQuery(
    QSiteEncompassWebhooks,
    {
      siteId: siteId!,
    },
    {
      suspend: false,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      skip: !isSite,
    }
  );

  const handleRefetch = useSafeCallback(async () => {
    if (isSite) {
      await refetchSiteData({
        siteId,
      });
    } else {
      await refetchPartnerData({
        partnerId,
      });
    }
  });

  const data = isSite ? siteData?.site?.encompassWebhooks : partnerData?.partner?.encompassWebhooks;

  // TODO: remove compact once graphql schema is fixed
  const result: IEncompassWebhook[] | undefined = compact(data || []);
  const loading = siteDataLoading || partnerDataLoading;

  return [result, loading, handleRefetch] as const;
};
