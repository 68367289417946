import { forOwn, upperFirst } from 'lodash-es';

import { type ICustomizationsEditorResource } from './types';

export const getSourceLabel = (resource: ICustomizationsEditorResource) => {
  let sourceLabel;

  if (resource.value) {
    if (resource.isOverridden) {
      sourceLabel = 'Self';
    } else {
      sourceLabel = upperFirst(resource.value.source);
    }
  }

  return sourceLabel;
};

export const traverse = (obj: any, iteratee: (val: any, key: string) => void, path?: string) => {
  forOwn(obj, (val, key) => {
    if (typeof val === 'object') {
      traverse(val, iteratee, path ? `${path}.${key}` : key);
    } else {
      iteratee(val, path ? `${path}.${key}` : key);
    }
  });
};
