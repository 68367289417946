import {
  Alert,
  FormSpy,
  type FormState,
  type FormSubmitHandler,
  ModalForm,
  TextArea,
} from '@mortgagehippo/ds';
import { isNil, isPlainObject, isString, upperFirst } from 'lodash-es';
import { useCallback, useState } from 'react';
import { hex } from 'wcag-contrast';

import { PALETTE_LEVELS } from '../constants';
import { type PaletteGroup } from '../types';
import { PaletteImportModalLabel } from './palette-import-modal-label';

interface IPaletteImportModalProps {
  isOpen: boolean;
  initialValues?: any;
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
  group: PaletteGroup;
}

export const PaletteImportModal = (props: IPaletteImportModalProps) => {
  const { onSubmit, onRequestClose, isOpen, initialValues, group } = props;
  const [invalidScore, setInvalidScore] = useState<number | undefined>(undefined);

  const handleValidate = useCallback((value: any) => {
    try {
      const palette = JSON.parse(value);

      if (!isPlainObject(palette) || Object.keys(palette).length !== 10) {
        return 'The amount of colors must match 10.';
      }

      const isHex = /^#[0-9A-F]{6}$/;
      const hexCodes: string[] = Object.values(palette);

      if (!hexCodes.every((v) => isString(v) && isHex.test(v.toUpperCase()))) {
        return 'Invalid Hex values.';
      }
    } catch (e) {
      return 'Invalid JSON.';
    }

    return undefined;
  }, []);

  const handleFormChange = (state: FormState<any>) => {
    const { values } = state;
    const { palette: value } = values || {};

    const isValid = isNil(handleValidate(value));

    if (!isValid) {
      setInvalidScore(undefined);
      return;
    }

    const palette = JSON.parse(value);
    const hexCodes: string[] = Object.values(palette);
    const index50 = PALETTE_LEVELS.indexOf(50);
    const index600 = PALETTE_LEVELS.indexOf(600);

    const score = hex(hexCodes[index50]!, hexCodes[index600]!);

    if (score < 4.5) {
      setInvalidScore(score);
      return;
    }

    setInvalidScore(undefined);
  };

  const title = `Import ${upperFirst(group)} Palette`;

  return (
    <ModalForm
      title={title}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      <FormSpy onChange={handleFormChange} subscription={{ values: true }} />
      <TextArea.Box
        name="palette"
        label={<PaletteImportModalLabel />}
        rows={12}
        validate={handleValidate}
        compact
      />
      {!isNil(invalidScore) && (
        <Alert type="warning" size="sm">
          Contrast ratio between {group}50 and {group}600 is {invalidScore}. Must be 4.5 or greater.
        </Alert>
      )}
    </ModalForm>
  );
};
