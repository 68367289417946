import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo/gql';
import { type DeletePartnerDocumentMutation } from '../apollo/graphql';

export type DeletePartnerDocument = NonNullable<
  DeletePartnerDocumentMutation['deletePartnerDocument']
>;

export const MDeletePartnerDocument = graphql(`
  mutation DeletePartnerDocument($partnerId: ID!, $documentId: ID!) {
    deletePartnerDocument(partnerId: $partnerId, documentId: $documentId) {
      id
    }
  }
`);

export const useDeletePartnerDocument = () => {
  const deletePartnerDocument = useMutation(MDeletePartnerDocument);
  return async (partnerId: string, documentId: string) => {
    const response = await deletePartnerDocument(
      {
        partnerId,
        documentId,
      },
      { fetchPolicy: 'no-cache' }
    );
    return response.data?.deletePartnerDocument;
  };
};
