import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo/gql';

export const MDeletePageComponentMutation = graphql(`
  mutation DeletePageComponent($pageComponentId: ID!) {
    deletePageComponent(pageComponentId: $pageComponentId) {
      ...PageComponentFragment
    }
  }
`);

export const useDeletePageComponent = () => {
  const deletePageComponent = useMutation(MDeletePageComponentMutation, undefined);

  return async (pageComponentId: string) => {
    await deletePageComponent({ pageComponentId });
  };
};
