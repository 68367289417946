import {
  Constant,
  FieldBox,
  type IFile,
  type UploadHandler,
  UploadInput,
  useFormValue,
} from '@mortgagehippo/ds';
import { ACCEPTED_DOCUMENT_MIME_TYPES } from '@mortgagehippo/tasks';
import { useCallback } from 'react';

interface ITemplatesFormHellosignTaskFieldsProps {
  onUploadDocument: UploadHandler;
}

export const TemplatesFormHellosignTaskFields = (props: ITemplatesFormHellosignTaskFieldsProps) => {
  const { onUploadDocument } = props;

  const [document, setDocument] = useFormValue('data.document');

  const handleChange = useCallback(
    (value: IFile[]) => {
      setDocument(value[0]);
    },
    [setDocument]
  );

  const value = document ? [document] : [];

  return (
    <>
      <Constant name="data.document" />

      <FieldBox
        name="document"
        label="Document"
        description="A partner level document will be created from the uploaded file immediately"
      >
        <UploadInput.Input
          aria-labelledby="document"
          name="document"
          onUpload={onUploadDocument}
          maxItems={1}
          accept={ACCEPTED_DOCUMENT_MIME_TYPES}
          onChange={handleChange}
          value={value}
          fileTypeDescription="document"
        />
      </FieldBox>
    </>
  );
};
