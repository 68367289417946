import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';
import { type ApolloQueryResult } from 'apollo-client';

import {
  type SiteMilestonesListQuery,
  type SiteMilestonesListQueryVariables,
} from '../../../apollo/graphql';
import { type IMilestone, QSiteMilestonesList } from './queries';

export type IUseMilestoneResult = Readonly<
  [
    result: {
      milestones: IMilestone[];
    },
    loading: boolean,
    refetch: () => Promise<ApolloQueryResult<SiteMilestonesListQuery>>,
  ]
>;

export const useSiteMilestones = (siteId: string, skip?: boolean): IUseMilestoneResult => {
  const [data, loading, , { refetch }] = useQuery<
    SiteMilestonesListQuery,
    SiteMilestonesListQueryVariables
  >(
    QSiteMilestonesList,
    { siteId },
    {
      suspend: false,
      fetchPolicy: 'network-only',
      skip,
    }
  );

  const handleRefresh = useSafeCallback(() => refetch({ siteId }));

  const milestones = data?.site?.milestones || [];

  return [{ milestones }, loading, handleRefresh] as const;
};
