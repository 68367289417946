import { PreQualificationLetter } from '../../../components/pre-qualification-letter';

interface ISitePreQualificationLetterPageContentProps {
  partnerId: string;
  siteId: string;
}

export const SitePreQualificationLetterPageContent = (
  props: ISitePreQualificationLetterPageContentProps
) => {
  const { partnerId, siteId } = props;

  return <PreQualificationLetter partnerId={partnerId} siteId={siteId} />;
};
