import { Input, type ISelectOption, Select, Title, useFormValue, YesNo } from '@mortgagehippo/ds';

import { CodeEditorField } from '$components/code-editor';

export enum EncompassGrantTypes {
  client_credentials = 'client_credentials',
  password = 'password',
}

const grantTypeOptions: ISelectOption[] = [
  {
    label: 'Client Credentials (authorize as vendor)',
    value: EncompassGrantTypes.client_credentials,
  },
  {
    label: 'Password (authorize as lender)',
    value: EncompassGrantTypes.password,
  },
];

export const EncompassApiFields = () => {
  const [grantType] = useFormValue<EncompassGrantTypes>('access_info.grant_type');

  return (
    <>
      <Title level={3}>Access Info</Title>
      <Input.Box name="access_info.url" label="URL" required />
      <Select.Box
        name="access_info.grant_type"
        label="Grant Type"
        options={grantTypeOptions}
        required
      />
      <Input.Box name="access_info.instance_id" label="Instance Id" required />
      <Input.Box name="access_info.client_id" label="Client Id" required />
      <Input.Box name="access_info.client_secret" label="Client Secret" required />
      {grantType === 'password' && (
        <>
          <Input.Box name="access_info.user_name" label="Username" required />
          <Input.Box name="access_info.password" label="Password" required />
        </>
      )}
      <Input.Box name="access_info.encompass_site_id" label="Consumer Connect Site ID" />
      <Title level={3}>Encompass Options</Title>
      <Input.Box name="encompass_data.loan_folder" label="Loan Folder" required />
      <Input.Box name="encompass_data.loan_template_path" label="Loan Template Path" required />
      <YesNo.Box
        name="encompass_data.fetch_legacy_disclosures"
        label="Fetch Legacy Disclosures"
        initialValue={false}
      />
      <YesNo.Box
        name="encompass_data.v1_document_and_attachment_api"
        label="Use V1 Encompass API for pushing documents"
      />

      <CodeEditorField.Box mode="json" name="custom_json" label="Custom JSON" />
    </>
  );
};
