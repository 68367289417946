import { CodeEditorField } from '$components/code-editor';
import { EditorField } from '$components/editor-field';

import { type IPageComponentFieldsProps } from './types';

export const LogoBarFields = (props: IPageComponentFieldsProps) => {
  const { languageId } = props;

  return (
    <>
      <EditorField.Box name={`resources.${languageId}.content.content`} label="Content" extended />
      <CodeEditorField.Box mode="scss" name="config.css" label="Custom SCSS" />
    </>
  );
};
