import { isArray, isPlainObject, isString } from 'lodash-es';

import {
  type IPipelineColumn,
  type IPipelineExportField,
  type IPipelineSearchField,
} from './types';

export const isPipelineColumn = (value: any): value is IPipelineColumn =>
  isPlainObject(value) && !!value.fields && (isString(value.fields) || isArray(value.fields));

export const isPipelineSearchField = (value: any): value is IPipelineSearchField =>
  isPlainObject(value) && !!value.field && isString(value.field);

export const isPipelineExportField = (value: any): value is IPipelineExportField =>
  isPlainObject(value) && !!value.field && isString(value.field);
