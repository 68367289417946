import { Form, type IFormProps } from '@mortgagehippo/ds';
import { type ReactNode } from 'react';

import { FixedSubmitBar } from '../../../fixed-submit-bar';

type ITabFormProps = Omit<IFormProps, 'as' | 'children'> & { children: ReactNode };

export const TabForm = (props: ITabFormProps) => {
  const { children, ...rest } = props;

  return (
    <Form {...rest}>
      {children}
      <FixedSubmitBar />
    </Form>
  );
};
