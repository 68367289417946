import { type FieldSize, type IFieldBoxProps, Select } from '@mortgagehippo/ds';
import { sortBy } from 'lodash-es';
import { type ReactNode, useMemo } from 'react';

import { SearchOperatorType } from '../../apollo/graphql';
import { config } from '../../config';
import { usePartners } from '../../hooks/use-partners';

interface IPartnersSelectProps {
  label?: ReactNode;
  name: string;
  required?: boolean;
  disabled?: boolean;
  size?: FieldSize;
  multiple?: boolean;
  compact?: boolean;
  onChange?: (newValue: any) => void;
};

export const PartnerSelect = (props: IPartnersSelectProps & Pick<IFieldBoxProps, 'labelTooltip'>) => {
  const [partners, partnersLoading] = usePartners([{ field: "clonable", operator: SearchOperatorType.eq, value: true }]);

  const partnerOptions = useMemo(() => {
    if (partnersLoading || !partners?.items.length) {
      return [];
    }
    const sortedPartners = sortBy(partners.items, [(o) => o.value !== config.DEFAULT_CLONING_PARTNER_ID, (o) => o.label]);
    return sortedPartners;
  }, [partnersLoading, partners]);

  return (
    <Select.Box
      label={null}
      {...props}
      options={partnerOptions}
      loading={partnersLoading}
      searchable
      allowClear
      notFoundContent="No results found."
      placeholder="NONE - Create a blank partner"
    />
  );
};
