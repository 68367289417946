import { Alert, spacing } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { AnswersDocumentationEntity } from './answers-documentation-entity';

const UL = styled.ul`
  margin-top: ${spacing(3)};
`;

interface IAnswersDocumentationModalContentProps {
  documentation?: any;
}

export const AnswersDocumentationModalContent = (props: IAnswersDocumentationModalContentProps) => {
  const { documentation } = props;

  if (!documentation) {
    return <Alert type="error">Documentation not found.</Alert>;
  }

  return (
    <UL>
      {documentation.items.map((entity: any) => (
        <AnswersDocumentationEntity key={entity.name} entity={entity} />
      ))}
    </UL>
  );
};
