import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import {
  MNotificationAlternateAgentSetPartnerInheritance,
  UNASSIGNED_APPLICATION_NOTIFICATION_TYPE,
} from './queries';

export const useSetPartnerInheritance = () => {
  const setPartnerInheritance = useMutation(
    MNotificationAlternateAgentSetPartnerInheritance,
    undefined
  );

  return useCallback(
    async (siteId: string, enabled: boolean) => {
      await setPartnerInheritance({
        siteId,
        enabled,
        notificationType: UNASSIGNED_APPLICATION_NOTIFICATION_TYPE,
      });
    },
    [setPartnerInheritance]
  );
};
