import { FORM_ERROR, notifications, useModal } from '@mortgagehippo/ds';
import { useCallback } from 'react';

import { ParentType } from '../../../apollo/graphql';
import { type IActionProps, type ICreateNotificationProfileAction } from '../types';
import { NotificationModalContainer } from './notification-modal-container';
import { useCreateNotificationProfile } from './use-create-notification-profile';

export const CreateNotificationProfileAction = (
  props: IActionProps<ICreateNotificationProfileAction>
) => {
  const { action, onDone } = props;
  const { partnerId, siteId } = action;
  const [isOpen, , closeModal] = useModal(true);
  const createNotificationProfile = useCreateNotificationProfile();
  const parentType = siteId ? ParentType.Site : ParentType.Partner;
  const parentId = siteId || partnerId;

  const handleSubmit = useCallback(
    async (values: any) => {
      const { name, type_name, data, args } = values;

      let argsInput;
      try {
        argsInput = args ? JSON.parse(args) : undefined;
      } catch {
        return {
          [FORM_ERROR]: 'Could not parse arguments',
        };
      }

      try {
        const input = {
          name,
          typeName: type_name,
          data,
          args: argsInput,
        };

        await createNotificationProfile(parentType, parentId, input);

        notifications.success({
          messageCid: 'edit-notification-profile-action:notification-success',
          message: 'Successfully updated notification profile.',
        });

        closeModal(onDone);
      } catch {
        notifications.error({
          messageCid: 'update-notification-profile-action:notification-error',
          message: 'There was an unexpected error updating the notification profile.',
        });
      }

      return undefined;
    },
    [createNotificationProfile, parentType, parentId, closeModal, onDone]
  );

  const handleCloseModal = useCallback(() => {
    closeModal(onDone);
  }, [closeModal, onDone]);

  return (
    <NotificationModalContainer
      title="Add Notification Profile"
      isOpen={isOpen}
      partnerId={partnerId}
      siteId={siteId}
      onSubmit={handleSubmit}
      onRequestClose={handleCloseModal}
    />
  );
};
