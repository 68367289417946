import { Format } from '@mortgagehippo/ds';
import { type IQueryTableColumns } from '@mortgagehippo/query-components';

import { type PartnerDocumentsQuery } from '../../apollo/graphql';

export type PartnerDocumentsQueryItem = PartnerDocumentsQuery['getPartnerDocuments']['items'][0];

export const PARTNER_DOCUMENTS_COLUMNS: IQueryTableColumns<PartnerDocumentsQueryItem> = [
  {
    key: 'filename',
    title: 'Filename',
    render: ({ filename }) => filename || '',
    width: '450px',
  },
  {
    key: 'description',
    title: 'Description',
    render: ({ description }) => description || '',
    width: '300px',
  },
  {
    key: 'updated_at',
    title: 'Last Updated',
    render: ({ updatedAt }) =>
      !!updatedAt && <Format.Date value={updatedAt} format="date-med-time" />,
    width: '170px',
  },
];
