import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { QPartnerPreQualificationExpirationDays } from './queries';

export const usePartnerPreQualificationExpirationDays = (partnerId: string) => {
  const [data, loading, , { refetch }] = useQuery(
    QPartnerPreQualificationExpirationDays,
    {
      partnerId,
    },
    {
      fetchPolicy: 'network-only',
    }
  );

  const handleRefresh = useSafeCallback(async () => {
    await refetch({
      partnerId,
    });
  });

  return [data?.partner?.preQualificationExpirationDays, loading, handleRefresh] as const;
};
