import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo/gql';
import { type PartnerSagasQuery, type PartnerSagasQueryVariables } from '../../apollo/graphql';

export const QPartnerSagas = graphql(`
  query PartnerSagas($partnerId: ID!) {
    partner(id: $partnerId) {
      id
      ruleSet {
        id
        sagas {
          name
          custom
          system
          arguments {
            name
            type
            required
            system
          }
          codeLines
        }
      }
    }
  }
`);

export type IRuleSetSaga = NonNullable<
  NonNullable<PartnerSagasQuery['partner']>['ruleSet']
>['sagas'][0];

export type IRuleSetSagaArgument = NonNullable<
  NonNullable<PartnerSagasQuery['partner']>['ruleSet']
>['sagas'][0]['arguments'][0];

interface IUsePartnerSagasResultData {
  sagas?: IRuleSetSaga[];
  ruleSetId?: string;
}

type IUsePartnerSagasResult = [IUsePartnerSagasResultData, boolean, () => Promise<any>];

export const usePartnerSagas = (partnerId: string): IUsePartnerSagasResult => {
  const [data, loading, , { refetch }] = useQuery<PartnerSagasQuery, PartnerSagasQueryVariables>(
    QPartnerSagas,
    {
      partnerId,
    },
    {
      fetchPolicy: 'network-only',
    }
  );

  const handleRefresh = useCallback(async () => {
    await refetch({ partnerId });
  }, [partnerId, refetch]);

  const ruleSet = data?.partner?.ruleSet || undefined;
  const ruleSetId = ruleSet?.id || undefined;
  const sagas = ruleSet?.sagas || [];

  return [{ sagas, ruleSetId }, loading, handleRefresh];
};
