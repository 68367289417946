import { Col, Numeric, Row } from '@mortgagehippo/ds';

export const NumericValidationEditor = ({ name }: { name: string }) => (
  <Row>
    <Col xs={24} sm={12}>
      <Numeric.Box name={`${name}.validateMin`} label="Min Value" allowNegative />
    </Col>
    <Col xs={24} sm={12}>
      <Numeric.Box name={`${name}.validateMax`} label="Max Value" />
    </Col>
  </Row>
);
