import { type ISendDocumentTask, SendDocumentTask } from '@mortgagehippo/tasks';
import { type ReactNode } from 'react';

import { TaskPreviewWrapper } from '../task-preview-wrapper';

export interface ISendDocumentTaskPreviewProps {
  task: ISendDocumentTask;
  onComplete?: () => Promise<any>;
  renderComplete?: () => ReactNode;
}

export const SendDocumentTaskPreview = (props: ISendDocumentTaskPreviewProps) => {
  const { task, renderComplete, onComplete } = props;

  return (
    <TaskPreviewWrapper task={task}>
      {({ nextTask, frame }) => (
        <SendDocumentTask
          task={nextTask as ISendDocumentTask}
          documents={[]}
          frame={frame}
          renderComplete={renderComplete}
          onComplete={onComplete}
        />
      )}
    </TaskPreviewWrapper>
  );
};
