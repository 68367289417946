import {
  borderRadius,
  Button,
  Modal,
  palette,
  spacing,
  Text,
  useModal,
  YesNo,
} from '@mortgagehippo/ds';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { CodeEditor } from '$components/code-editor/code-editor';

import { type IValueEditorProps } from './types';

const Actions = styled.div`
  background: #2e312a;
  padding: ${spacing(1)};
  text-align: right;
`;

const HtmlPreviewArea = styled('div')<{ dark?: boolean }>`
  margin: ${spacing(3)} 0 ${spacing(7)};
  border-radius: ${borderRadius(2)};
  border: 1px dashed ${palette('neutral200')};
  padding: ${spacing(3)} ${spacing(4)};
  background-color: ${(p) => (p.dark ? palette('neutral900') : palette('neutral50'))};
  color: ${(p) => (p.dark ? palette('neutral50') : palette('neutral900'))};
  transition:
    color 300ms,
    background-color 300ms;
`;

export const HtmlEditor = (props: IValueEditorProps) => {
  const { onChange, value } = props;

  const [isHtmlPreviewModalOpen, openHtmlPreview, closeHtmlPreview] = useModal(false);

  const handleHtmlPreviewClick = useCallback(() => {
    openHtmlPreview();
  }, [openHtmlPreview]);

  const [htmlPreviewBackgroundDark, setHtmlPreviewBackgroundDark] = useState(false);
  const handleHtmlPreviewBackgroundToggle = useCallback(
    (v: boolean) => {
      setHtmlPreviewBackgroundDark(v);
    },
    [setHtmlPreviewBackgroundDark]
  );

  return (
    <>
      <CodeEditor mode="html" wrap height="30vh" value={value || '<p></p>'} onChange={onChange} />
      <Actions>
        <Button
          onClick={handleHtmlPreviewClick}
          size="xxs"
          type="neutral"
          importance="tertiary"
          inverted
          compact
          icon="preview"
        >
          Preview
        </Button>
      </Actions>
      <Modal
        title="HTML Preview"
        width={800}
        isOpen={isHtmlPreviewModalOpen}
        onRequestClose={closeHtmlPreview}
        hideOkButton
        cancelButtonLabel="Close"
      >
        <HtmlPreviewArea
          dark={htmlPreviewBackgroundDark}
          dangerouslySetInnerHTML={{ __html: value }}
        />
        <YesNo.Input
          size="sm"
          value={htmlPreviewBackgroundDark}
          name="htmlPreviewBackgroundDark"
          labelYes="Dark Background"
          labelNo="Light Background"
          onChange={handleHtmlPreviewBackgroundToggle}
          showSuccess
        />
        <Text as="p" size="sm" variant="secondary">
          This is just a general preview. Your HTML may actually render with slightly different
          formatting (color, font size, etc.) based on the context in which it is rendered. For
          example, the same footer html content may be rendered on a white background with dark text
          when viewed in a modal, but with white text on a dark color background when viewed on the
          start page.
        </Text>
      </Modal>
    </>
  );
};
