import { CustomizationService, notifications } from '@mortgagehippo/ds';
import { type ILandingPage, useAgentLandingPage, useLandingPage } from '@mortgagehippo/tasks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useLanguages } from '$components/customizations-editor';

import { LandingPageType, type PageComponentType } from '../../apollo/graphql';
import { LandingPageEditorContent } from './landing-page-editor-content';
import { LandingPageEditorLoading } from './landing-page-editor-loading';
import {
  useCreatePageComponent,
  useDeletePageComponent,
  useMovePageComponentDown,
  useMovePageComponentUp,
  useUpdatePageComponent,
} from './page-components';
import { useLandingPageCustomizations } from './use-landing-page-customizations';
import { toResourcesServerInput } from './util';

interface ILandingPageEditorProps {
  landingPage: ILandingPage;
  domain: string;
  partnerId: number;
}

/*
 * const toCustomizationsNamespaces = (namespaces: string[], allValues: any[]) => {
 *   const allValuesByNamespace = groupBy(allValues, (v) => v.namespace);
 *   return namespaces.map((namespace) => {
 *     const namespaceValues = allValuesByNamespace[namespace] || [];
 *     return namespaceValues.reduce((acum, value) => {
 *       set(acum, value.key, value.value);
 *       return acum;
 *     }, {});
 *   });
 * };
 */

export const LandingPageEditor = (props: ILandingPageEditorProps) => {
  const { landingPage, domain, partnerId } = props;
  const { siteId, id: landingPageId } = landingPage;

  const [languages, languagesLoading] = useLanguages();
  const [{ data: customizations, loading: customizationsLoading }, refreshCustomizations] =
    useLandingPageCustomizations(partnerId, domain);
  const [currentLanguage, setCurrentLanguage] = useState<string | undefined>();

  // TODO: don't like this
  const [, refreshLandingPage] = useLandingPage(`${siteId}`, landingPageId, {
    skip: true,
  });

  const [, refreshAgentLandingPage] = useAgentLandingPage(`${siteId}`, {
    skip: true,
  });

  const customizationsRef = useRef(customizations);
  useEffect(() => {
    customizationsRef.current = customizations;
  }, [customizations]);

  const service = useMemo(
    () =>
      new CustomizationService({
        cache: false,
        fetcher: async (namespaces: string[], language?) => {
          const languageValues = customizationsRef.current.filter((c) => language === c.language);
          /*
           * return toCustomizationsNamespaces(namespaces, languageValues);
           * console.log('namespaces', namespaces);
           * console.log('languageValues', languageValues);
           */
          return CustomizationService.fromArray(namespaces, languageValues || []);
          // return toCustomizationsNamespaces(namespaces, languageValues);
        },
      }),
    []
  );

  const createPageComponent = useCreatePageComponent();
  const deletePageComponent = useDeletePageComponent();
  const updatePageComponent = useUpdatePageComponent();
  const movePageComponentUp = useMovePageComponentUp();
  const movePageComponentDown = useMovePageComponentDown();

  const refresh =
    landingPage.type === LandingPageType.AgentLandingPage
      ? refreshAgentLandingPage
      : refreshLandingPage;

  const handleCreate = useCallback(
    async (componentType: PageComponentType) => {
      try {
        await createPageComponent(landingPageId, componentType);
        await refresh();

        notifications.success({ message: 'Successfully created page component' });
      } catch (error) {
        notifications.error({ message: 'There was an unexpected error creating page component.' });
      }
    },
    [createPageComponent, landingPageId, refresh]
  );

  const handleUpdate = useCallback(
    async (id: string, values: any) => {
      try {
        const { config = {}, resources = {} } = values;

        const resourcesInput = toResourcesServerInput(resources);

        await updatePageComponent(id, config, resourcesInput);
        await refreshCustomizations();
        await service.reload();

        notifications.success({ message: 'Successfully updated page component' });

        return true;
      } catch (error) {
        notifications.error({ message: 'There was an unexpected error updating page component.' });

        return false;
      }
    },
    [refreshCustomizations, service, updatePageComponent]
  );

  const handleDelete = useCallback(
    async (id: string) => {
      try {
        await deletePageComponent(id);
        await refresh();

        notifications.success({ message: 'Successfully deleted page component' });
      } catch (error) {
        notifications.error({ message: 'There was an unexpected error deleting page component.' });
      }
    },
    [deletePageComponent, refresh]
  );

  const handleMoveUp = useCallback(
    async (id: string) => {
      try {
        await movePageComponentUp(id);
        await refresh();
      } catch (error) {
        notifications.error({
          message: "There was an error updating the component's order, please try again.",
        });
      }
    },
    [movePageComponentUp, refresh]
  );

  const handleMoveDown = useCallback(
    async (id: string) => {
      try {
        await movePageComponentDown(id);
        await refresh();
      } catch (error) {
        notifications.error({
          message: "There was an error updating the component's order, please try again.",
        });
      }
    },
    [movePageComponentDown, refresh]
  );

  const handleLanguageChange = useCallback(
    (nextLanguage: string) => {
      setCurrentLanguage(nextLanguage);
      service.setLanguage(nextLanguage);
    },
    [service]
  );

  useEffect(() => {
    if (!languages) return;
    if (currentLanguage) return;
    const initialLanguage = languages[0]!.value;
    setCurrentLanguage(initialLanguage);
    service.setLanguage(initialLanguage);
    service.reload();
  }, [currentLanguage, languages, service]);

  if (!currentLanguage || languagesLoading || customizationsLoading) {
    return <LandingPageEditorLoading />;
  }

  return (
    <LandingPageEditorContent
      landingPage={landingPage}
      currentLanguage={currentLanguage}
      languages={languages || []}
      service={service}
      onLanguageChange={handleLanguageChange}
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
      onMoveUp={handleMoveUp}
      onMoveDown={handleMoveDown}
    />
  );
};
