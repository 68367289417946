import { Tabs } from '@mortgagehippo/ds';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { FieldEditorFrame } from './field-editor-frame';
import { GroupsEditor } from './groups-editor';

export const EntityEditor = ({ name }: { name: string }) => (
  <FieldEditorFrame name={name} isEntity hasDiscriminator>
    {() => [
      <Tabs.Tab id="fields" label="Fields">
        <EditorTabWrapper size="md">
          <GroupsEditor name={`${name}.groups`} />
        </EditorTabWrapper>
      </Tabs.Tab>,
    ]}
  </FieldEditorFrame>
);
