import { type IQueryTableDataActions, QueryTable } from '@mortgagehippo/query-components';
import { useCallback, useMemo, useRef } from 'react';

import { ActionType, useDispatchAction } from '$components/actions';

import {
  type PartnerDocumentsQuery,
  type PartnerDocumentsQueryVariables,
} from '../../apollo/graphql';
import { useDeletePartnerDocument } from '../../hooks/use-delete-partner-document';
import { QPartnerDocuments } from '../../hooks/use-partner-documents';
import { PARTNER_DOCUMENTS_COLUMNS } from './partner-documents-columns';

const LOCAL_STORAGE_KEY = 'mp:partner-documents';

export type PartnerDocumentsQueryItem = PartnerDocumentsQuery['getPartnerDocuments']['items'][0];

export interface IPartnerDocumentsTableProps {
  partnerId: string;
}

export const PartnerDocumentsTable = (props: IPartnerDocumentsTableProps) => {
  const { partnerId } = props;
  const tableRef = useRef<any>();
  const dispatch = useDispatchAction();

  const deleteDocument = useDeletePartnerDocument();

  const handleRowAction = useCallback(
    async (actionKey: string, record?: PartnerDocumentsQueryItem) => {
      if (!record) {
        return;
      }
      if (actionKey === 'delete') {
        await deleteDocument(partnerId, record.id);
      }

      if (tableRef) {
        tableRef.current.refetch();
      }
    },
    [deleteDocument, partnerId]
  );

  const rowActions: IQueryTableDataActions<PartnerDocumentsQueryItem> = useMemo(() => {
    const actions: IQueryTableDataActions<PartnerDocumentsQueryItem> = [
      {
        key: 'delete',
        label: 'Delete',
        buttonProps: {
          icon: 'delete',
        },
        confirm: {
          title: 'Confirm',
          explanation: 'NOTE: This will permanently remove this file! Are you sure?',
          okButtonLabel: 'Delete',
          type: 'info',
        },
        onAction: handleRowAction,
      },
    ];
    return actions;
  }, [handleRowAction]);

  const topActions: IQueryTableDataActions = useMemo(
    () => [
      {
        key: 'add-partner-document',
        label: 'Add new document(s)',
        buttonProps: {
          icon: 'plus',
        },
        onAction: () => {
          dispatch(
            {
              type: ActionType.UPLOAD_PARTNER_DOCUMENT,
              partnerId,
            },

            () => {
              if (tableRef) {
                tableRef.current.refetch();
              }
            }
          );
        },
      },
    ],
    [dispatch, partnerId]
  );

  return (
    <QueryTable<PartnerDocumentsQuery, PartnerDocumentsQueryVariables>
      ref={tableRef}
      caption="Partner Documents"
      query={QPartnerDocuments}
      dataSource={(result) => result.getPartnerDocuments.items || []}
      itemTotal={(result) => result.getPartnerDocuments.total || 0}
      nextCursor={(result) => result.getPartnerDocuments.nextCursor}
      previousCursor={(result) => result.getPartnerDocuments.previousCursor}
      rowKey={(item) => item.id}
      rowActions={rowActions}
      topActions={topActions}
      columns={PARTNER_DOCUMENTS_COLUMNS}
      variables={{
        partnerId,
      }}
      bottomContent={<QueryTable.Pagination />}
      localStorageKey={LOCAL_STORAGE_KEY}
      resizableColumns
    >
      <QueryTable.Data />
    </QueryTable>
  );
};
