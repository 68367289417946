import { Choice, type IChoiceOption, useModalConfirm } from '@mortgagehippo/ds';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useResetPreQualificationExpirationDays } from './use-reset-pre-qualification-expiration-days';

const Wrapper = styled.div`
  max-width: 480px;
`;

export interface IPreQualificationExpirationDaysToggleProps {
  siteId: string;
  isOverridden: boolean;
  onChange: (nextIsInheritedFromPartner: boolean) => Promise<any>;
}

const choiceOptions: IChoiceOption[] = [
  {
    label: 'Inherit from Partner',
    value: 'inherit',
  },
  {
    label: 'Override for this site',
    value: 'override',
  },
];

export const PreQualificationExpirationDaysToggle = (
  props: IPreQualificationExpirationDaysToggleProps
) => {
  const { siteId, isOverridden, onChange } = props;
  const [value, setValue] = useState<string | null>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [confirm, modalDialog] = useModalConfirm('Are you sure?', {
    type: 'warning',
    cancelButtonLabel: 'Cancel',
    okButtonLabel: 'Continue',
    disableOverlayClickClose: true,
  });

  const resetPartnerInheritance = useResetPreQualificationExpirationDays();

  const handleChange = useCallback(
    async (newValue: string | undefined) => {
      if (newValue === 'inherit') {
        await confirm({
          onConfirm: async () => {
            setDisabled(true);
            setValue(newValue);
            await resetPartnerInheritance(siteId);
            await onChange(true);
            setDisabled(false);
          },
        });
      }

      if (newValue === 'override') {
        setDisabled(true);
        setValue(newValue);
        await onChange(false);
        setDisabled(false);
      }
    },
    [confirm, onChange, resetPartnerInheritance, siteId]
  );

  const initialValue = useMemo(() => {
    if (isOverridden) {
      return 'override';
    }

    return 'inherit';
  }, [isOverridden]);

  return (
    <Wrapper>
      <Choice.Input
        onChange={handleChange}
        disabled={disabled}
        value={value || initialValue}
        name="Select Inheritance"
        options={choiceOptions}
        columns={2}
        size="sm"
      />
      {modalDialog}
    </Wrapper>
  );
};
