import { DEFAULT_THEME, type ISelectOption, Select } from '@mortgagehippo/ds';
import { times } from 'lodash-es';

import { type IValueEditorProps } from './types';

export const ShadowEditor = (props: IValueEditorProps) => {
  const { onChange, value, labelId, disabled } = props;

  const shadowOptions: ISelectOption[] = times(
    Object.keys(DEFAULT_THEME.shadows.neutral).length,
    (i) => ({
      label: `${i}`,
      value: `${i}`,
    })
  );

  return (
    <Select.Input
      name="customization_value"
      aria-labelledby={labelId}
      value={value}
      onChange={onChange}
      placeholder="select shadow level"
      disabled={disabled}
      options={shadowOptions}
    />
  );
};
