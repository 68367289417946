import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo/gql';

export const MCreateAgentLandingPageMutation = graphql(`
  mutation CreateAgentLandingPage($siteId: ID!) {
    createAgentLandingPage(siteId: $siteId) {
      ...LandingPageFragment
    }
  }
`);

export const useCreateAgentLandingPage = () => {
  const createAgentLandingPage = useMutation(MCreateAgentLandingPageMutation, undefined);

  return async (siteId: string) => {
    await createAgentLandingPage({ siteId });
  };
};
