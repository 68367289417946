import { Tabs, YesNo } from '@mortgagehippo/ds';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { FieldEditorFrame } from './field-editor-frame';

const SUFFIXES = ['_monthly_total_amount', '_interval_type', '_interval_units', '_interval_amount'];

export const IncomeEditor = ({ name }: { name: string }) => (
  <FieldEditorFrame name={name} keySuffixes={SUFFIXES}>
    {() => (
      <Tabs.Tab id="currency" label="Currency Options">
        <EditorTabWrapper size="md">
          <YesNo.Box name={`${name}.show_hourly`} label="Show Hours" />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
  </FieldEditorFrame>
);
