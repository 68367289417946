import { useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo/gql';
import { type PartnerDocumentsQuery } from '../apollo/graphql';

export type PartnerDocumentsQueryPartnerDocuments = PartnerDocumentsQuery['getPartnerDocuments'];

export const QPartnerDocuments = graphql(`
  query PartnerDocuments($partnerId: ID!, $perPage: Int!, $cursor: String) {
    getPartnerDocuments(
      partnerId: $partnerId
      options: { perPage: $perPage, cursor: $cursor, orderBy: { updated_at: "ASC" } }
    ) {
      items {
        id
        partnerId
        filename
        description
        updatedAt
      }
      nextCursor
      previousCursor
      total
    }
  }
`);

export const usePartnerDocuments = (partnerId: string, perPage: number, cursor?: string) => {
  const [data, loading] = useQuery(
    QPartnerDocuments,
    {
      partnerId,
      cursor,
      perPage,
    },
    {
      fetchPolicy: 'network-only',
    }
  );

  return [data?.getPartnerDocuments, loading] as const;
};
