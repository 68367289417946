import { useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo/gql';
import { type ApplicationStatusQuery } from '../../apollo/graphql';

export const QApplicationStatusQuery = graphql(`
  query ApplicationStatus($partnerId: ID!, $statusId: ID!) {
    partner(id: $partnerId) {
      id
      applicationFileStatus(id: $statusId) {
        ...ApplicationFileStatusFragment
      }
    }
  }
`);

export type IApplicationStatus = NonNullable<
  NonNullable<ApplicationStatusQuery['partner']>['applicationFileStatus']
>;

export const useApplicationStatus = (partnerId: string, statusId: string, skip = false) => {
  const [data, loading] = useQuery(
    QApplicationStatusQuery,
    { partnerId, statusId },
    {
      fetchPolicy: 'network-only',
      skip,
    }
  );

  const result = data?.partner?.applicationFileStatus;

  return [result, loading] as const;
};
