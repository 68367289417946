import { useMutation } from '@mortgagehippo/apollo-hooks';
import {
  borderRadius,
  Checkbox,
  EMPTY_IMAGE,
  fontSize,
  fontWeight,
  HelpButton,
  notifications,
  palette,
  shadow,
  spacing,
  TextArea,
  Upload,
  type UploadHandler,
} from '@mortgagehippo/ds';
import axios, { type AxiosProgressEvent } from 'axios';
import { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import { MCreateCustomizationResourceUploadUrl } from '../../../queries';
import { type IValueEditorProps } from './types';

const IMAGE_MIME_TYPES = ['image/png', 'image/jpeg', 'image/svg+xml'];

/* prettier-ignore */
const CurrentImageContainer = styled.div<{ emptyImage?: boolean }>`
  display: inline-block;
  line-height: 16px;
  min-width: 16px;
  margin-bottom: ${spacing(2)};
  border-radius: ${borderRadius(1)};
  background-color: ${palette("neutral300")};
  background-image: linear-gradient(45deg, ${palette("neutral400")} 25%, transparent 25%),
  linear-gradient(-45deg, ${palette("neutral400")} 25%, transparent 25%),
  linear-gradient(45deg, transparent 75%, ${palette("neutral400")} 75%),
  linear-gradient(-45deg, transparent 75%, ${palette("neutral400")} 75%);
  background-size: 16px 16px;
  background-position: 0 0, 0 8px, 8px -8px, -8px 0px;
  position: relative;
  box-shadow: ${shadow(2)};

  ${p => css`
    &:empty {
      width: 200px;
      height: 100px;

      &:before {
        content: "${p.emptyImage ? "[empty image]" : "no image"}";
        position: absolute;
        line-height: 1em;
        height: 1em;
        top: 0;
        bottom: 0;
        margin: auto;
        text-align: center;
        width: 100%;
        font-weight: ${fontWeight("bold")};
        color: ${palette(p.emptyImage ? "danger600" : "neutral800")};
        font-size: ${fontSize("md")};
      }
    }`
  }
`;

const CurrentImage = styled.img`
  max-width: 100%;
  max-height: 50vh;
  object-fit: contain;
`;

const EditImageUrlAction = styled.div`
  margin: ${spacing(3)} 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > span > * {
    vertical-align: middle;
  }
`;

export const ImageEditor = (
  props: IValueEditorProps & {
    partnerId: number;
    domain?: string | null;
    language: string;
    resourceId: string;
  }
) => {
  const { onChange, value, partnerId, domain, language, resourceId } = props;

  const [emptyImagePrevious, setEmptyImagePrevious] = useState<Record<string, string>>({});

  const onChangeEmptyImage = useCallback(
    (v: boolean) => {
      if (v) {
        setEmptyImagePrevious((state) => {
          const newState = state;
          newState[resourceId] = value;
          return newState;
        });
        onChange(EMPTY_IMAGE);
      } else {
        const prevImage = emptyImagePrevious[resourceId] || '';
        onChange(prevImage);
      }
    },
    [onChange, value, emptyImagePrevious, resourceId]
  );

  const createPostUrl = useMutation(MCreateCustomizationResourceUploadUrl);

  const handleUpload: UploadHandler = useCallback(
    async (file, meta, progress): Promise<any> => {
      try {
        const createPostUrlInput = {
          partnerId,
          domain,
          language,
          filename: meta.filename,
        };

        const response = await createPostUrl({
          resourceId,
          data: createPostUrlInput,
        });

        const { downloadUrl, uploadUrl } = response.data!.createCustomizationResourceUploadUrl;

        await axios.put(uploadUrl, file, {
          headers: {
            'Content-Type': meta.mime,
          },
          onUploadProgress: (e: AxiosProgressEvent) => {
            if (!e.total) return;
            const percent = Math.round((e.loaded * 100) / e.total);
            progress(percent);
          },
        });

        onChange(downloadUrl);
        notifications.success({ message: 'Upload successful. REMEMBER to click the SAVE button.' });
      } catch (e) {
        notifications.error({ message: 'There was an unexpected error while uploading your file' });
        throw e;
      }
    },
    [createPostUrl, language, partnerId, domain, onChange, resourceId]
  );

  return (
    <>
      <CurrentImageContainer emptyImage={value === EMPTY_IMAGE}>
        {value && value !== EMPTY_IMAGE ? <CurrentImage src={value} alt="current image" /> : null}
      </CurrentImageContainer>
      <EditImageUrlAction>
        <span>
          <Checkbox.Input
            size="sm"
            compact
            name="empty_image"
            onChange={onChangeEmptyImage}
            value={value === EMPTY_IMAGE}
          >
            use empty image
          </Checkbox.Input>
          <HelpButton size="xxs">
            If there is a default hard-coded image or a partner-level customization image, it will
            not show when this box is checked. Any image you currently have here will be removed
            after you save.
          </HelpButton>
        </span>
      </EditImageUrlAction>
      <TextArea.Input
        value={value}
        onChange={onChange}
        name="download_url"
        size="sm"
        compact
        placeholder="image URL"
      />
      <Upload
        name="customization_value"
        onUpload={handleUpload}
        accept={IMAGE_MIME_TYPES}
        maxItems={1}
      />
    </>
  );
};
