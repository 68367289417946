import { spacing, Spinner } from '@mortgagehippo/ds';
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  padding: ${spacing(8)} 0;
`;

export const Loading = () => (
  <Container>
    <Spinner size="xl" />
  </Container>
);
