import { FieldArrayListItems, useFieldArray } from '@mortgagehippo/ds';
import { type ReactNode, useContext } from 'react';

import { EditorContext } from './editor-context';

export interface IEditorArrayProps {
  name: string;
  addButtonLabel?: ReactNode;
  addMoreButtonLabel?: ReactNode;
  renderItemLabel: (itemValue: any) => ReactNode;
}

export const EditorArray = (props: IEditorArrayProps) => {
  const { name, renderItemLabel } = props;
  const { onArrayItem } = useContext(EditorContext);
  const array = useFieldArray(name);

  const handleAddArrayItem = (index: number) => {
    array.fields.push({});
    onArrayItem(name, index);
    return true;
  };

  const handleEditArrayItem = (index: number) => {
    onArrayItem(name, index);
    return true;
  };

  const handleMove = (from: number, to: number) => {
    array.fields.move(from, to);
  };

  const handleDelete = (index: number) => {
    array.fields.remove(index);
  };

  return (
    <FieldArrayListItems
      {...(props as any)}
      renderItemLabel={renderItemLabel}
      sortable
      values={array.fields.value || []}
      onAdd={handleAddArrayItem}
      onEdit={handleEditArrayItem}
      onMove={handleMove}
      onDelete={handleDelete}
    />
  );
};
