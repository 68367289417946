import {
  type FormSubmitHandler,
  type ISelectOption,
  ModalForm,
  spacing,
  Tabs,
  type UploadHandler,
} from '@mortgagehippo/ds';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { type PageComponentType } from '../../../apollo/graphql';
import { getEditorConfig } from './util';

const FieldsContainer = styled.div`
  padding-top: ${spacing(4)};
`;

interface IPageComponentEditModalProps {
  type: PageComponentType;
  languages: ISelectOption[];
  currentLanguage?: string;
  initialValues?: any;
  onSubmit?: FormSubmitHandler;
  onRequestClose?: () => void;
  onUpload: UploadHandler;
  isOpen: boolean;
}

export const PageComponentEditModal = (props: IPageComponentEditModalProps) => {
  const { type, languages, currentLanguage, onUpload, ...rest } = props;
  const [submitting, setSubmitting] = useState(false);

  const handleUpload: UploadHandler = useCallback(
    async (file, meta, progress) => {
      setSubmitting(true);
      const result = await onUpload(file, meta, progress);
      setSubmitting(false);
      return result;
    },
    [onUpload]
  );

  const editorConfig = getEditorConfig(type);

  if (!editorConfig) {
    throw Error(`Unknown editor type: ${type}`);
  }

  const FieldsComponent = editorConfig.component;

  return (
    <ModalForm
      title="Edit Page Component"
      okButtonDisabled={submitting}
      width={800}
      disableOverlayClickClose
      {...rest}
    >
      <Tabs initialSelected={currentLanguage}>
        {languages.map((language) => {
          const { label, value: languageId } = language;

          return (
            <Tabs.Tab key={languageId} id={languageId} label={label}>
              <FieldsContainer>
                <FieldsComponent languageId={languageId} onUpload={handleUpload} />
              </FieldsContainer>
            </Tabs.Tab>
          );
        })}
      </Tabs>
    </ModalForm>
  );
};
