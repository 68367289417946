import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { MSetPreQualificationExpirationDays } from './queries';

export const useSetPreQualificationExpirationDays = () => {
  const setPreQualificationExpirationDays = useMutation(
    MSetPreQualificationExpirationDays,
    undefined
  );

  return useCallback(
    async ({ partnerId, siteId, days }: { partnerId?: string; siteId?: string; days: number }) => {
      await setPreQualificationExpirationDays({
        partnerId,
        siteId,
        days,
      });
    },
    [setPreQualificationExpirationDays]
  );
};
