import { Button, Input } from '@mortgagehippo/ds';
import { isValidElement, type ReactNode, useState } from 'react';
import styled from 'styled-components';

import { EditorField } from '../../components/editor-field';

const ButtonRow = styled.div`
  text-align: right;
`;

export interface ITaskMessageEditorProps {
  onSave: (nextValue: string | undefined) => void;
  onCancel: () => void;
  initialValue: any;
  type?: 'text' | 'html';
}

export const TaskMessageEditor = (props: ITaskMessageEditorProps) => {
  const { type, onSave, onCancel, initialValue } = props;
  const initialValueNormalized = isValidElement(initialValue) ? '' : initialValue;
  const [currentValue, setCurrentValue] = useState<string | undefined>(initialValueNormalized);

  const handleChange = (nextValue: string) => {
    setCurrentValue(nextValue);
  };

  const handleSubmit = () => {
    onSave(currentValue);
  };

  let editor: ReactNode = null;
  switch (type) {
    case 'html': {
      editor = (
        <EditorField.Input name="value" value={currentValue} onChange={handleChange} extended />
      );
      break;
    }
    case 'text':
    default: {
      editor = <Input.Input name="value" value={currentValue} onChange={handleChange} />;
      break;
    }
  }

  return (
    <ButtonRow>
      {editor}
      <Button type="danger" size="xs" onClick={onCancel}>
        Cancel
      </Button>
      <Button importance="tertiary" size="xs" icon="edit" onClick={handleSubmit}>
        Save
      </Button>
    </ButtonRow>
  );
};
