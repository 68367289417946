import {
  Constant,
  FormError,
  type FormSubmitHandler,
  Input,
  ModalForm,
  YesNo,
} from '@mortgagehippo/ds';
import { isEmpty } from 'lodash-es';

export interface IExternalMilestoneKeyFormValues {
  name?: string;
  id?: string;
  ignore?: boolean;
}

interface IAddExternalMilestoneKeyModalProps {
  isOpen: boolean;
  initialValues: any;
  onSubmit: FormSubmitHandler<IExternalMilestoneKeyFormValues>;
  onCancel: () => void;
  onDelete: () => void;
  loading: boolean;
}

export const ExternalMilestoneKeyModal = (props: IAddExternalMilestoneKeyModalProps) => {
  const { onSubmit, onCancel, onDelete, isOpen, loading, initialValues } = props;

  const editing = !isEmpty(initialValues);

  return (
    <ModalForm
      title={`${editing ? 'Edit' : 'Add'} External Milestone Key`}
      initialValues={initialValues}
      isOpen={isOpen}
      loading={loading}
      onSubmit={onSubmit}
      onRequestClose={onCancel}
      okButtonLabel={`${editing ? 'Save' : 'Add'} key`}
      secondaryActionButtonLabel={editing ? 'Delete' : undefined}
      secondaryActionButtonPosition="left"
      onSecondaryActionButtonAction={onDelete}
      secondaryActionButtonType="danger"
      secondaryActionButtonImportance="tertiary"
    >
      <Constant name="id" />
      <Input.Box
        name="name"
        label="External Milestone Key"
        placeholder="External Milestone Key"
        labelInvisible
        hideRequiredLabel
        required
      />
      <YesNo.Box name="ignore" label="Ignore" required />
      <FormError alertSize="sm" />
    </ModalForm>
  );
};
