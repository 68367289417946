import { notifications, useModal } from '@mortgagehippo/ds';
import { useCallback } from 'react';

import { type IActionProps, type IEditLandingPageAction } from '../types';
import { EditLandingPageModalContainer } from './edit-landing-page-modal-container';
import { useUpdateLandingPage } from './use-update-landing-page';

export const EditLandingPageAction = (props: IActionProps<IEditLandingPageAction>) => {
  const { action, onDone } = props;
  const { siteId, landingPageId } = action;
  const [isOpen, , closeModal] = useModal(true);

  const updateLandingPage = useUpdateLandingPage();

  const handleSubmit = async (values: any) => {
    try {
      const { title, path } = values;

      const input = {
        title,
        path,
      };

      await updateLandingPage(landingPageId, input);
      closeModal(() => {
        notifications.success({
          messageCid: 'update-landing-page-action:notification-success',
          message: 'Successfully updated landing page',
        });
        onDone();
      });
    } catch (e) {
      closeModal(() => {
        notifications.error({
          messageCid: 'update-landing-page-action:notification-error',
          message: 'There was an unexpected error updating landing page',
        });
        onDone();
      });
    }
  };

  const handleCloseModal = useCallback(() => {
    closeModal(onDone);
  }, [closeModal, onDone]);

  return (
    <EditLandingPageModalContainer
      siteId={siteId}
      landingPageId={landingPageId}
      onSubmit={handleSubmit}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
    />
  );
};
