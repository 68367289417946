import { type RouteComponentProps } from 'react-router';

import { usePartner } from '../../../hooks/use-partner';
import { Content, Layout } from '../../../layouts/main';
import { PartnerCascadesPageContent } from './partner-cascades-page-content';

interface IPartnerCascadesPageRouteParams {
  partnerId: string;
}

type IPartnerCascadesPageProps = RouteComponentProps<IPartnerCascadesPageRouteParams>;

export const PartnerCascadesPage = (props: IPartnerCascadesPageProps) => {
  const { match } = props;
  const { params } = match;
  const { partnerId } = params;
  const [partner, loading] = usePartner(partnerId);

  const partnerName = partner?.name || '';

  return (
    <Layout pageTitle={`${partnerName} - Cascades`}>
      <Content title="Cascades" subTitle={partnerName}>
        <PartnerCascadesPageContent partnerId={partnerId} loading={loading} />
      </Content>
    </Layout>
  );
};
