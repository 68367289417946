import { FieldLabel, Modal } from '@mortgagehippo/ds';
import { useCallback } from 'react';

import { VerticalSelect } from '$components/vertical-select';

import { type VerticalType } from '../../../apollo/graphql';
import { AnswersDocumentationModalContent } from './answers-documentation-modal-content';

interface IAnswersDocumentationModalProps {
  documentation?: any;
  isOpen: boolean;
  selectedVertical: VerticalType;
  onChange: (vertical: VerticalType) => void;
  onRequestClose: () => void;
}

export const AnswersDocumentationModal = (props: IAnswersDocumentationModalProps) => {
  const { documentation, onRequestClose, isOpen, onChange, selectedVertical } = props;
  const loading = typeof documentation === 'undefined';

  const handleVerticalChange = useCallback(
    (value: any) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <Modal
      title="Field Documentation"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onOk={onRequestClose}
      hideCancelButton
      width={800}
    >
      <FieldLabel id="label_vertical">Vertical</FieldLabel>
      <VerticalSelect.Input
        name="vertical"
        value={selectedVertical}
        onChange={handleVerticalChange}
        compact
        aria-labelledby="label_vertical"
        disabled={loading}
        loading={loading}
      />
      {!loading && <AnswersDocumentationModalContent documentation={documentation} />}
    </Modal>
  );
};
