import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { type CreateSnapdocsSubscriptionInput } from '../../../apollo/graphql';
import { MCreateSnapdocsSubscriptionMutation } from '../queries';

export const useCreateSnapdocsSubscription = () => {
  const createSubscription = useMutation(MCreateSnapdocsSubscriptionMutation);

  return useCallback(
    async (data: CreateSnapdocsSubscriptionInput) => {
      const result = await createSubscription({ data });

      return result.data!.snapdocs.createSubscription;
    },
    [createSubscription]
  );
};
