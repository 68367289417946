import { type RouteComponentProps } from 'react-router';

import { useSite } from '../../../hooks/use-site';
import { Content, Layout } from '../../../layouts/main';
import { SitePreQualificationLetterPageContent } from './site-pre-qualification-letter-page-content';

interface ISitePreQualificationLetterPageRouteParams {
  partnerId: string;
  siteId: string;
}

type ISitePreQualificationLetterPageProps =
  RouteComponentProps<ISitePreQualificationLetterPageRouteParams>;

export const SitePreQualificationLetterPage = (props: ISitePreQualificationLetterPageProps) => {
  const { match } = props;
  const { params } = match;
  const { partnerId, siteId } = params;
  const [site] = useSite(siteId);

  const siteName = site?.name || '';

  if (!siteName) {
    return null;
  }

  return (
    <Layout pageTitle={`${siteName} - Pre-Qualification Letter`}>
      <Content title="Pre-Qualification Letter" subTitle={siteName}>
        <SitePreQualificationLetterPageContent partnerId={partnerId} siteId={siteId} />
      </Content>
    </Layout>
  );
};
