import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import {
  type CredentialsType,
  type PartnerCredentialQuery,
  type SiteCredentialQuery,
} from '../../../apollo/graphql';
import { QPartnerCredential, QSiteCredential } from '../queries';

export type ICredentialWithPartnerData = NonNullable<
  NonNullable<PartnerCredentialQuery['partner']>['credential']
>;

export type ICredentialWithSiteData = NonNullable<
  NonNullable<SiteCredentialQuery['site']>['credential']
>;

export type ICredentialWithData = ICredentialWithPartnerData | ICredentialWithSiteData;

export interface IGetCredentialParams {
  partnerId: string;
  siteId?: string;
  credentialType: CredentialsType;
}

export const useCredential = (params: IGetCredentialParams, skip = false) => {
  const { partnerId, siteId, credentialType } = params;

  const isSite = !!siteId;

  const [partnerData, partnerDataLoading, , { refetch: refetchPartnerData }] = useQuery(
    QPartnerCredential,
    {
      partnerId,
      credentialType,
    },
    {
      suspend: false,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      skip: skip || isSite,
    }
  );

  const [siteData, siteDataLoading, , { refetch: refetchSiteData }] = useQuery(
    QSiteCredential,
    {
      siteId: siteId!,
      credentialType,
    },
    {
      suspend: false,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      skip: skip || !isSite,
    }
  );

  const handleRefresh = useSafeCallback(async () => {
    if (isSite) {
      await refetchSiteData({
        siteId: siteId!,
        credentialType,
      });
    } else {
      await refetchPartnerData({
        partnerId,
        credentialType,
      });
    }
  });

  const result: ICredentialWithData | undefined = isSite
    ? siteData?.site?.credential?.data
    : partnerData?.partner?.credential?.data;
  const loading = partnerDataLoading || siteDataLoading;

  return [result, loading, handleRefresh] as const;
};
