import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { type UpdateMilestoneInput } from '../../../apollo/graphql';
import { MUpdateSiteMilestonesMutation } from './queries';

export const useUpdateMilestones = () => {
  const updateMilestones = useMutation(MUpdateSiteMilestonesMutation, undefined);

  return useCallback(
    async (siteId: string, data: UpdateMilestoneInput) => {
      const response = await updateMilestones({
        siteId,
        data,
      });

      return response.data!.updateMilestones;
    },
    [updateMilestones]
  );
};
