import {
  FormSection,
  Input,
  Select,
  TextArea,
  type UploadHandler,
  useFormValue,
} from '@mortgagehippo/ds';

import { DEFAULT_DUE_DATE_OPTIONS, TEMPLATE_TYPE_OPTIONS } from './constant';
import { TemplatesFormTaskFields } from './templates-form-task-fields';

interface ITemplatesFormProps {
  onUploadDocument: UploadHandler;
}

export const TemplatesForm = (props: ITemplatesFormProps) => {
  const { onUploadDocument } = props;

  const [taskType] = useFormValue('type_name');

  return (
    <>
      <FormSection title="Details">
        <Select.Box name="type_name" label="Task Type" options={TEMPLATE_TYPE_OPTIONS} required />
        <Input.Box name="key" label="Key" required />
        <Select.Box
          name="default_due_date_days"
          label="Default due date (days after creation)"
          options={DEFAULT_DUE_DATE_OPTIONS}
          initialValue={3}
        />
        <Input.Box name="title" label="Title" required />
        <TextArea.Box name="description" label="Description" />
        <Input.Box
          name="group"
          label="Group"
          description="Used for separating the templates into groups when displaying them for selection."
        />
      </FormSection>
      {taskType ? (
        <TemplatesFormTaskFields taskType={taskType} onUploadDocument={onUploadDocument} />
      ) : null}
    </>
  );
};
