import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo/gql';

const MDeleteBlueprintMutation = graphql(`
  mutation DeleteBlueprint($blueprintId: ID!) {
    deleteBlueprint(blueprintId: $blueprintId)
  }
`);

export const useDeleteBlueprint = () => {
  const fn = useMutation(MDeleteBlueprintMutation);
  return async (blueprintId: string) => {
    const { data } = await fn({ blueprintId });
    return data;
  };
};
