import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { MDeleteWebhookConfigurationMutation } from '../queries';

export interface IDeleteWebhookConfiguration {
  id: string;
}

export const useDeleteWebhookConfiguration = () => {
  const deleteWebhookConfiguration = useMutation(MDeleteWebhookConfigurationMutation);

  return useCallback(
    async ({ id }: IDeleteWebhookConfiguration) => {
      await deleteWebhookConfiguration(
        {
          id,
        },
        { fetchPolicy: 'no-cache' }
      );
    },
    [deleteWebhookConfiguration]
  );
};
