import { HelpButton, palette, spacing } from '@mortgagehippo/ds';
import { type ReactNode } from 'react';
import styled from 'styled-components';

const StyledPre = styled.pre`
  background-color: ${palette('neutral50')};
  padding: ${spacing(3)};
`;

interface IPipelineEditorLabelProps {
  sample?: any;
  children?: ReactNode;
}

export const PipelineEditorLabel = (props: IPipelineEditorLabelProps) => {
  const { children, sample } = props;

  return (
    <>
      <span>{children}</span>
      {sample ? (
        <HelpButton
          popover
          popoverProps={{
            align: 'RightMiddle',
            title: 'Sample JSON',
            minWidthCSS: '400px',
          }}
          size="xs"
        >
          <StyledPre>{JSON.stringify(sample, null, 4)}</StyledPre>
        </HelpButton>
      ) : null}
    </>
  );
};
