import { Tabs, YesNo } from '@mortgagehippo/ds';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { TaskEditorFrame } from '../task-editor-frame';

export const AssetsTaskEditor = ({ name }: { name: string }) => (
  <TaskEditorFrame name={name}>
    {() => (
      <Tabs.Tab id="options" label="Asset Task Options">
        <EditorTabWrapper size="md">
          <YesNo.Box
            name={`${name}.data.showLinkAccountInfoBox`}
            label="Show Link Account Info Box"
            initialValue
          />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
  </TaskEditorFrame>
);
