import { palette, spacing, Text, Title } from '@mortgagehippo/ds';
import { type FunctionComponent, type ReactNode } from 'react';
import styled from 'styled-components';

export const Box = styled.div`
  flex: 1;
  background: ${palette('neutral50')};
  // overflow-y: auto;
  z-index: 2;
  padding: ${spacing(5)} ${spacing(6)};
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0;
  padding: 0 0 ${spacing(4)};
`;

const ColTitle = styled.div`
  flex: 1;
`;

const ColActions = styled.div`
  flex: 0 0 auto;
  white-space: nowrap;
`;

const StyledTitle = styled(Title)`
  margin: 0;
  padding: 0;
`;

const Body = styled.main`
  padding: ${spacing(2)} 0 0;
`;

export interface IContentProps {
  title?: string;
  subTitle?: string;
  actions?: ReactNode;
  children?: ReactNode;
}

export const ContentComponent: FunctionComponent<IContentProps> = (props) => {
  const { title, subTitle, actions, children } = props;
  return (
    <Box>
      <Header>
        <ColTitle>
          <StyledTitle>{title}</StyledTitle>
          {subTitle ? <Text variant="secondary">{subTitle}</Text> : null}
        </ColTitle>
        {actions ? <ColActions>{actions}</ColActions> : null}
      </Header>
      <Body>{children}</Body>
    </Box>
  );
};
