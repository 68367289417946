import { type UploadHandler } from '@mortgagehippo/ds';
import { UnreachableCaseError } from '@mortgagehippo/util';

import { BlueprintEditor } from './blueprint-editor';
import { FieldEditor } from './data-gathering-task/field-editor';
import { PanelEditor } from './data-gathering-task/panel-editor';
import { SwitchArrayVariantEditor } from './data-gathering-task/switch-array-variant-editor';
import { EditorEmpty } from './editor-empty';
import { TaskEditor } from './task-editor';
import { TaskGroupEditor } from './task-group-editor';
import { getPanelPathFromGroupPath, getPathType } from './util';

interface IBlueprintEditorFormProps {
  path: string;
  editMode?: boolean;
  onUploadDocument: UploadHandler;
}

export const BlueprintEditorForm = (props: IBlueprintEditorFormProps) => {
  const { path, editMode, onUploadDocument } = props;
  const pathType = getPathType(path);

  switch (pathType) {
    case 'blueprint': {
      return <BlueprintEditor editMode={editMode} onUploadDocument={onUploadDocument} />;
    }
    case 'task_group': {
      return <TaskGroupEditor name={path} />;
    }
    case 'task': {
      return <TaskEditor name={path} />;
    }
    case 'panel': {
      return <PanelEditor name={path} />;
    }
    case 'group': {
      return <PanelEditor name={getPanelPathFromGroupPath(path)} tabId="fields" />;
    }
    case 'field': {
      return <FieldEditor name={path} />;
    }
    case 'case': {
      return <SwitchArrayVariantEditor name={path} />;
    }
    case undefined: {
      return <EditorEmpty />;
    }
    default: {
      throw new UnreachableCaseError(pathType);
    }
  }
};
