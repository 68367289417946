import { graphql } from '../../apollo/gql';

// eslint-disable-next-line unused-imports/no-unused-vars
const FAuthRoleFragment = graphql(`
  fragment AuthRoleFragment on AuthRole {
    id
    name
    description
    partnerId
    createdAt
    updatedAt
  }
`);

export const QPartnerAuthRolesList = graphql(`
  query PartnerAuthRolesList($partnerId: ID!, $perPage: Int!, $cursor: String, $orderBy: JSON) {
    partner(id: $partnerId) {
      id
      authRoles(options: { perPage: $perPage, cursor: $cursor, orderBy: $orderBy }) {
        items {
          ...AuthRoleFragment
        }
        nextCursor
        previousCursor
        total
      }
    }
  }
`);

export const QPermissionsList = graphql(`
  query PermissionsList {
    permissions {
      id
      resourceName
      actionName
    }
  }
`);

export const QAuthRoleData = graphql(`
  query AuthRoleData($partnerId: ID!, $authRoleId: ID!) {
    partner(id: $partnerId) {
      id
      authRole(id: $authRoleId) {
        ...AuthRoleFragment
        permissions {
          id
        }
      }
    }
  }
`);

export const MCreateAuthRoleMutation = graphql(`
  mutation CreateAuthRole($partnerId: ID!, $data: AuthRoleInput!) {
    createAuthRole(partnerId: $partnerId, data: $data) {
      ...AuthRoleFragment
    }
  }
`);

export const MUpdateAuthRoleMutation = graphql(`
  mutation UpdateAuthRole($authRoleId: ID!, $data: AuthRoleInput!) {
    updateAuthRole(authRoleId: $authRoleId, data: $data) {
      ...AuthRoleFragment
    }
  }
`);

export const MDeleteAuthRoleMutation = graphql(`
  mutation DeleteAuthRole($authRoleId: ID!) {
    deleteAuthRole(authRoleId: $authRoleId) {
      ...AuthRoleFragment
    }
  }
`);
