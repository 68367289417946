import {
  ChooseAgentTask,
  type IChooseAgentTask,
  type IChooseAgentTaskAgent,
} from '@mortgagehippo/tasks';
import { isEmpty } from 'lodash-es';
import { type ReactNode, useCallback, useState } from 'react';

import { TaskPreviewWrapper } from '../task-preview-wrapper';
import { AGENT_LIST } from './agent-list';

export interface IChooseAgentTaskPreviewProps {
  task: IChooseAgentTask;
  onComplete?: () => Promise<any>;
  renderComplete?: () => ReactNode;
}

const AGENT_SHOW_SEARCH_COUNT = 10;

const primaryJobFunction = {
  id: '1',
  name: 'Loan Officer',
};

export const ChooseAgentTaskPreview = (props: IChooseAgentTaskPreviewProps) => {
  const { task, renderComplete, onComplete } = props;

  const [agents, setAgents] = useState<IChooseAgentTaskAgent[] | undefined>(undefined);
  const [search, setSearch] = useState<string>('');

  const handleComplete = async () => {
    if (onComplete) {
      return onComplete();
    }
    return undefined;
  };

  const handleSearch = useCallback((nextSearch: string) => {
    setSearch(nextSearch);

    if (isEmpty(nextSearch)) {
      setAgents(undefined);
      return;
    }

    const nSearch = nextSearch.trim().toLowerCase();
    const nextAgents = AGENT_LIST.filter((agent) =>
      (agent.name || '').trim().toLowerCase().includes(nSearch)
    ).slice(0, AGENT_SHOW_SEARCH_COUNT);

    setAgents(nextAgents);
  }, []);

  return (
    <TaskPreviewWrapper task={task}>
      {({ nextTask, frame }) => (
        <ChooseAgentTask
          task={nextTask as IChooseAgentTask}
          frame={frame}
          onComplete={handleComplete}
          renderComplete={renderComplete}
          showSearch
          primaryJobFunction={primaryJobFunction}
          primaryAgent={undefined}
          agents={agents}
          search={search}
          onSearch={handleSearch}
        />
      )}
    </TaskPreviewWrapper>
  );
};
