import { DataGatheringTask, type IDataGatheringTask } from '@mortgagehippo/tasks';
import { type ReactNode } from 'react';

import { TaskPreviewWrapper } from '../task-preview-wrapper';

export interface IDataGatheringTaskPreviewProps {
  task: IDataGatheringTask;
  onComplete?: () => Promise<any>;
  renderComplete?: () => ReactNode;
}

export const DataGatheringTaskPreview = (props: IDataGatheringTaskPreviewProps) => {
  const { task, onComplete, renderComplete } = props;
  const handleChange = async () => ({ success: true, errors: [] });

  return (
    <TaskPreviewWrapper task={task}>
      {({ nextTask, frame }) => (
        <DataGatheringTask
          key={nextTask.key}
          task={nextTask as IDataGatheringTask}
          onComplete={onComplete}
          onChange={handleChange}
          initialValues={{}}
          frame={frame}
          renderComplete={renderComplete}
        />
      )}
    </TaskPreviewWrapper>
  );
};
