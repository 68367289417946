import { type RouteComponentProps } from 'react-router';

import { CustomizationsEditor } from '$components/customizations-editor';

import { useSite } from '../../../hooks/use-site';
import { Content, Layout } from '../../../layouts/main';

interface ISiteCustomizationsPageRouteParams {
  partnerId: string;
  siteId: string;
}

type ISiteCustomizationsPageProps = RouteComponentProps<ISiteCustomizationsPageRouteParams>;

export const SiteCustomizationsPage = (props: ISiteCustomizationsPageProps) => {
  const { match } = props;
  const { params } = match;
  const { partnerId, siteId } = params;

  const [site] = useSite(siteId);
  const siteName = site?.name || '';

  if (!site) {
    return null;
  }

  return (
    <Layout pageTitle={`${siteName} - Customizations`}>
      <Content title="Customizations - Site Level">
        <CustomizationsEditor
          basePath={match.url}
          partnerId={Number(partnerId)}
          domain={site.domain}
        />
      </Content>
    </Layout>
  );
};
