import {
  ButtonLink,
  ColumnOrderFieldType,
  type ISearchFields,
  type ISearchFilter,
  notifications,
  Search,
  SearchFieldType,
  TableColSortDirection,
  useTableSort,
} from '@mortgagehippo/ds';
import {
  type IQueryTableColumns,
  type IQueryTableDataActions,
  QueryTable,
} from '@mortgagehippo/query-components';
import { type MouseEvent, useCallback, useMemo, useRef, useState } from 'react';
import { type RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import {
  type PartnerSitesListQuery,
  type PartnerSitesListQueryVariables,
} from '../../apollo/graphql';
import { type ISite } from '../../hooks/site-fragment';
import { useDeleteSite } from '../../hooks/use-delete-site';
import { usePartner } from '../../hooks/use-partner';
import { Content, Layout } from '../../layouts/main';
import { QPartnerSitesList } from './queries';

const DEFAULT_ORDER_COLUMN = 'name';
const DEFAULT_ORDER_DIRECTION: TableColSortDirection = TableColSortDirection.ASCENDING;

const COLUMNS: IQueryTableColumns<ISite> = [
  {
    key: 'name',
    title: 'Name',
    sortable: true,
    type: ColumnOrderFieldType.STRING,
    render: ({ name, partnerId, id }) => (
      <Link to={`/partners/${partnerId}/sites/${id}`}>{name}</Link>
    ),
  },
  {
    key: 'domain',
    title: 'Domain',
    sortable: true,
    type: ColumnOrderFieldType.STRING,
    render: ({ domain }) => (
      <ButtonLink
        href={`https://${domain}`}
        target={domain}
        icon="open-in-new"
        iconSize="xs"
        iconLocation="right"
      >
        {domain}
      </ButtonLink>
    ),
  },
];

const SEARCH_FIELDS: ISearchFields = {
  name: {
    label: 'Name',
    type: SearchFieldType.STRING,
  },
  domain: {
    label: 'Domain',
    type: SearchFieldType.STRING,
  },
};

interface ISitesPageRouteParams {
  partnerId: string;
}

type ISitesPageProps = RouteComponentProps<ISitesPageRouteParams>;

export const SitesPage = (props: ISitesPageProps) => {
  const { match, history } = props;
  const { params } = match;
  const { partnerId } = params;

  const tableRef = useRef<any>();
  const sortProps = useTableSort(DEFAULT_ORDER_COLUMN, DEFAULT_ORDER_DIRECTION);
  const [searchFilters, setSearchFilters] = useState<ISearchFilter[] | undefined>();

  const [partner] = usePartner(partnerId);

  const deleteSite = useDeleteSite();

  const handleClickRow = useCallback(
    (record: ISite, _index: number, clickDetails?: MouseEvent) => {
      if (clickDetails?.altKey || clickDetails?.metaKey) {
        window.open(`/partners/${partnerId}/sites/${record.id}`);
      } else {
        history.push(`/partners/${partnerId}/sites/${record.id}`);
      }
    },
    [history, partnerId]
  );

  const handleMiddleClickRow = useCallback(
    (key: string) => {
      window.open(`/partners/${partnerId}/sites/${key}`);
    },
    [partnerId]
  );

  const handleDeleteSite = useCallback(
    async (record: ISite) => {
      try {
        await deleteSite(record.id);

        notifications.success({ message: 'The site was deleted successfully.' });

        tableRef.current.refetch();
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [deleteSite, tableRef]
  );

  const handleSiteAction = useCallback(
    async (actionKey: string, record: ISite) => {
      switch (actionKey) {
        case 'edit':
          history.push(`/partners/${partnerId}/sites/${record.id}`);
          break;
        case 'milestones':
          history.push(`/partners/${partnerId}/sites/${record.id}/milestones`);
          break;
        case 'customizations':
          history.push(`/partners/${partnerId}/sites/${record.id}/customizations`);
          break;
        case 'credentials':
          history.push(`/partners/${partnerId}/sites/${record.id}/credentials`);
          break;
        case 'notifications':
          history.push(`/partners/${partnerId}/sites/${record.id}/notifications`);
          break;
        case 'sagas': {
          history.push(`/partners/${partnerId}/sites/${record.id}/sagas`);
          break;
        }
        case 'delete':
          await handleDeleteSite(record);
          break;
        default: {
          throw new Error(`Sites Page: Unknown action ${actionKey}`);
        }
      }
    },
    [handleDeleteSite, history, partnerId]
  );

  const rowActions: IQueryTableDataActions = useMemo(
    () => [
      {
        key: 'options',
        label: 'Options',
        buttonProps: {
          icon: 'menu-dots',
        },
        onGroupAction: handleSiteAction,
        actions: [
          {
            key: 'edit',
            label: 'Edit',
            iconProps: {
              name: 'edit',
            },
          },
          {
            key: 'milestones',
            label: 'Milestones',
            iconProps: {
              name: 'star',
            },
          },
          {
            key: 'customizations',
            label: 'Customizations',
            iconProps: {
              name: 'preview',
            },
          },
          {
            key: 'credentials',
            label: 'Credentials',
            iconProps: {
              name: 'lock',
            },
          },
          {
            key: 'notifications',
            label: 'Notifications',
            iconProps: {
              name: 'warning',
            },
          },
          {
            key: 'sagas',
            label: 'Sagas',
            iconProps: {
              name: 'tasks',
            },
          },
          {
            key: 'delete',
            label: 'Delete',
            iconProps: {
              name: 'delete',
            },
            confirm: {
              title: 'Warning',
              explanation:
                'Are you sure you want to delete this site? This action cannot be undone.',
              type: 'warning',
            },
          },
        ],
      },
    ],
    [handleSiteAction]
  );

  const topActions: IQueryTableDataActions = useMemo(
    () => [
      {
        key: 'add-site',
        label: 'Add site',
        buttonProps: {
          icon: 'plus',
        },
        onAction: () => {
          history.push(`/partners/${partnerId}/sites/new`);
        },
      },
    ],
    [history, partnerId]
  );

  const orderBy = useMemo(() => {
    const { sortColumn, sortDirection } = sortProps;

    if (!sortColumn || !sortDirection) {
      return undefined;
    }

    return {
      [sortColumn]: sortDirection,
    };
  }, [sortProps]);

  const partnerName = partner?.name || '';

  const topContent = (
    <Search
      fields={SEARCH_FIELDS}
      showSearchBarIcon
      label="Search"
      size="sm"
      search={searchFilters}
      onChange={setSearchFilters}
    />
  );

  return (
    <Layout pageTitle={`${partnerName} - Sites`}>
      <Content title="Sites" subTitle={partnerName}>
        <QueryTable<PartnerSitesListQuery, PartnerSitesListQueryVariables>
          ref={tableRef}
          caption="Sites"
          query={QPartnerSitesList}
          variables={{
            partnerId,
            orderBy,
            criteria: searchFilters as any,
          }}
          dataSource={(result) => result.partner?.sites.items || []}
          itemTotal={(result) => result.partner?.sites.itemsTotal || 0}
          rowKey={(item) => item.id}
          rowActions={rowActions}
          topActions={topActions}
          columns={COLUMNS}
          sortProps={sortProps}
          onRowClick={handleClickRow}
          onRowMiddleClick={handleMiddleClickRow}
          topContent={topContent}
          bottomContent={<QueryTable.Pagination />}
        >
          <QueryTable.Data />
        </QueryTable>
      </Content>
    </Layout>
  );
};
