import { notifications } from '@mortgagehippo/ds';
import * as Sentry from '@sentry/browser';
import { useCallback } from 'react';

import { type CredentialsType } from '../../apollo/graphql';
import { CredentialModal } from './credential-modal';
import { useCredential } from './hooks/use-credential';
import { useSaveCredential } from './hooks/use-save-credential';

interface ICredentialsEditorContentProps {
  partnerId: string;
  siteId?: string;
  isOpen: boolean;
  editingCredentialType: CredentialsType | undefined;
  availableCredentialTypes: CredentialsType[];
  onSubmit: () => void;
  onClose: () => void;
}

export const CredentialModalContent = (props: ICredentialsEditorContentProps) => {
  const {
    availableCredentialTypes,
    isOpen,
    partnerId,
    siteId,
    editingCredentialType,
    onSubmit,
    onClose,
  } = props;

  const [credentialData, credentialDataLoading] = useCredential(
    { partnerId, siteId, credentialType: editingCredentialType! },
    !editingCredentialType
  );
  const saveCredential = useSaveCredential();

  const handleCredentialSubmit = useCallback(
    async (values: any) => {
      const { typeName, ...rest } = values;

      try {
        await saveCredential({ partnerId, siteId, credentialType: typeName, data: rest });

        notifications.success({ message: 'Your changes have been saved.' });

        onSubmit();
      } catch (e: any) {
        Sentry.captureException(e, { extra: { message: 'Error saving credential.' } });
        notifications.error({
          message: 'An error occurred saving this credential. Please try again later.',
        });
      }
    },
    [onSubmit, partnerId, saveCredential, siteId]
  );

  const initialValues = (editingCredentialType && credentialData) || null;

  return (
    <CredentialModal
      availableCredentialTypes={availableCredentialTypes}
      editingCredentialType={editingCredentialType}
      onRequestClose={onClose}
      onSubmit={handleCredentialSubmit}
      isOpen={isOpen}
      initialValues={initialValues}
      loading={credentialDataLoading}
    />
  );
};
