import { borderRadius, Button, palette, shadow, spacing } from '@mortgagehippo/ds';
import { type ITask } from '@mortgagehippo/tasks';
import { type ReactNode, useMemo, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  background: ${palette('neutral50')};
  padding: ${spacing(6)} ${spacing(3)};
  border: 1px dashed ${palette('neutral400')};
  border-radius: ${borderRadius(2)};
  box-shadow: ${shadow(3)};
  position: relative;
`;

const Content = styled.div`
  width: 100%;
  max-width: 520px;
  position: relative;
  margin: auto;
`;

const Buttons = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`;

export interface ITaskPreviewWrapperRendererProps {
  nextTask: ITask;
  frame: boolean;
}

interface ITaskPreviewWrapperProps {
  task: ITask;
  children: (props: ITaskPreviewWrapperRendererProps) => ReactNode;
}

export function TaskPreviewWrapper(props: ITaskPreviewWrapperProps) {
  const { task, children } = props;
  const [frame, setFrame] = useState(true);
  const [frozen, setFrozen] = useState(false);

  const handleToggleFrozen = () => {
    setFrozen((f) => !f);
  };
  const handleToggleFrame = () => {
    setFrame((f) => !f);
  };

  const nextTask = useMemo(() => ({ ...task, isFrozen: frozen }), [task, frozen]);

  return (
    <Wrapper>
      <Buttons>
        <Button
          size="xxs"
          type="neutral"
          importance={frame ? 'primary' : 'secondary'}
          onClick={handleToggleFrame}
          icon="user"
          iconButton
          iconButtonHumble
          title="Toggle Frame"
        />
        <Button
          size="xxs"
          type="neutral"
          importance={frozen ? 'primary' : 'secondary'}
          onClick={handleToggleFrozen}
          icon="edit"
          iconButton
          iconButtonHumble
          title="Toggle Frozen"
        />
      </Buttons>
      <Content>{children({ nextTask, frame })}</Content>
    </Wrapper>
  );
}
