import { Tabs, useFormValue } from '@mortgagehippo/ds';
import { TaskType } from '@mortgagehippo/tasks';
import { UnreachableCaseError } from '@mortgagehippo/util';

import { AssetsTaskEditor } from './assets-task/assets-task-editor';
import { ChooseAgentTaskEditor } from './choose-agent-task/choose-agent-task-editor';
import { CreditTaskEditor } from './credit-pull-task/credit-task-editor';
import { DataGatheringTaskEditor } from './data-gathering-task/data-gathering-task-editor';
import { DocumentSubmissionTaskEditor } from './document-submission-task/document-submission-task-editor';
import { DocusignTaskEditor } from './docusign-task/docusign-task-editor';
import { DocutechDisclosuresTaskEditor } from './docutech-disclosures-task/docutech-disclosures-task-editor';
import { EditorTabWrapper } from './editor-tab-wrapper';
import { EmployersTaskEditor } from './employers-task/employers-task-editor';
import { EncompassDisclosuresTaskEditor } from './encompass-disclosures-task/encompass-disclosures-task-editor';
import { HellosignTaskEditor } from './hellosign-task/hellosign-task-editor';
import { InformationTaskEditor } from './information-task/information-task-editor';
import { InviteApplicantsTaskEditor } from './invite-applicants-task/invite-applicants-task-editor';
import { PaymentTaskEditor } from './payment-task/payment-task-editor';
import { PricingTaskEditor } from './pricing-task/pricing-task-editor';
import { SendDocumentTaskEditor } from './send-document-task/send-document-task-editor';
import { SignDisclosuresTaskEditor } from './sign-disclosures-task/sign-disclosures-task-editor';
import { SubmitApplicationTaskEditor } from './submit-application-task/submit-application-task-editor';
import { TaskEditorDetails } from './task-editor-details';
import { VOIECascadeTaskEditor } from './voie-cascade-task/voie-cascade-task-editor';

export const TaskEditor = ({ name }: { name: string }) => {
  const [typeName] = useFormValue<TaskType | undefined>(`${name}.type_name`);

  switch (typeName) {
    case TaskType.AssetsTask: {
      return <AssetsTaskEditor name={name} />;
    }
    case TaskType.ChooseAgentTask: {
      return <ChooseAgentTaskEditor name={name} />;
    }
    case TaskType.CreditPullTask: {
      return <CreditTaskEditor name={name} />;
    }
    case TaskType.DataGatheringTask: {
      return <DataGatheringTaskEditor name={name} />;
    }
    case TaskType.DocumentSubmissionTask: {
      return <DocumentSubmissionTaskEditor name={name} />;
    }
    case TaskType.DocutechDisclosuresTask: {
      return <DocutechDisclosuresTaskEditor name={name} />;
    }
    case TaskType.DocusignTask: {
      return <DocusignTaskEditor name={name} />;
    }
    case TaskType.HellosignTask: {
      return <HellosignTaskEditor name={name} />;
    }
    case TaskType.InformationTask: {
      return <InformationTaskEditor name={name} />;
    }
    case TaskType.InviteApplicantsTask: {
      return <InviteApplicantsTaskEditor name={name} />;
    }
    case TaskType.EmployersTask: {
      return <EmployersTaskEditor name={name} />;
    }
    case TaskType.EncompassDisclosuresTask: {
      return <EncompassDisclosuresTaskEditor name={name} />;
    }
    case TaskType.PaymentTask: {
      return <PaymentTaskEditor name={name} />;
    }
    case TaskType.PreQualificationTask: {
      return null;
    }
    case TaskType.SnapdocsClosingTask: {
      return null;
    }
    case TaskType.PricingTask: {
      return <PricingTaskEditor name={name} />;
    }
    case TaskType.SendDocumentTask: {
      return <SendDocumentTaskEditor name={name} />;
    }
    case TaskType.SignDisclosuresTask: {
      return <SignDisclosuresTaskEditor name={name} />;
    }
    case TaskType.SubmitApplicationTask: {
      return <SubmitApplicationTaskEditor name={name} />;
    }
    case TaskType.VOIECascadeTask: {
      return <VOIECascadeTaskEditor name={name} />;
    }
    case undefined: {
      return (
        <Tabs>
          <Tabs.Tab id="details" label="Details">
            <EditorTabWrapper size="md">
              <TaskEditorDetails name={name} />
            </EditorTabWrapper>
          </Tabs.Tab>
        </Tabs>
      );
    }
    default: {
      throw new UnreachableCaseError(typeName);
    }
  }
};
