import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo/gql';
import { type UpdateSiteInput } from '../apollo/graphql';

export const MUpdateSiteMutation = graphql(`
  mutation UpdateSite($siteId: ID!, $data: UpdateSiteInput!) {
    updateSite(siteId: $siteId, data: $data)
  }
`);

export const useUpdateSite = () => {
  const updateSite = useMutation(MUpdateSiteMutation);

  return async (siteId: string, data: UpdateSiteInput): Promise<string> => {
    const response = await updateSite({ siteId, data });
    return response.data!.updateSite;
  };
};
