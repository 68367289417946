import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo/gql';
import { type SiteAuth0TenantInput } from '../apollo/graphql';

export const MRegisterSiteAuth0TenantMutation = graphql(`
  mutation RegisterSiteAuth0Tenant($siteId: ID!, $data: SiteAuth0TenantInput!) {
    registerSiteAuth0Tenant(siteId: $siteId, data: $data)
  }
`);

export const useRegisterSiteAuth0Tenant = () => {
  const registerTenant = useMutation(MRegisterSiteAuth0TenantMutation);

  return async (siteId: string, data: SiteAuth0TenantInput): Promise<string> => {
    const response = await registerTenant({ siteId, data });
    return response.data!.registerSiteAuth0Tenant;
  };
};
