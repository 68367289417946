import { Alert, Constant, FieldBox, useFormValue } from '@mortgagehippo/ds';
import { useDebouncedEffect } from '@mortgagehippo/util';
import { useEffect, useRef, useState } from 'react';

import { CodeEditorField } from '$components/code-editor';

export const InternalSystemFields = () => {
  const [value, setValue] = useState<string | undefined>();
  const initializedRef = useRef<boolean>(false);

  const [data, setData] = useFormValue<Record<string, unknown> | undefined>('data');

  useDebouncedEffect(
    () => {
      try {
        const parsed = JSON.parse(value || '{}');
        setData(parsed);
      } catch (e) {
        // do nothing
      }
    },
    { wait: 100 },
    [value]
  );

  useEffect(() => {
    if (!value && !initializedRef.current) {
      setValue(JSON.stringify(data || {}, null, 4));
    }
    initializedRef.current = true;
  }, [data, value]);

  return (
    <>
      <Alert type="info" size="sm">
        You must override the internal system saga
      </Alert>
      <Constant name="data" />
      <FieldBox label="Custom Data" name="dummy">
        <CodeEditorField.Input
          mode="json"
          name="dummy"
          onChange={setValue}
          value={value}
          required
        />
      </FieldBox>
    </>
  );
};
