import {
  Combobox,
  type IChoiceOption,
  Input,
  Select,
  useFormValue,
  YesNo,
} from '@mortgagehippo/ds';
import { useEffect } from 'react';

import { RegisteredIntegrationConnectionSecurityType } from '../../../apollo/graphql';

const versionOptions: IChoiceOption[] = [
  { label: 'v1', value: 1 },
  { label: 'v2', value: 2 },
];

const typeOptions: IChoiceOption[] = [
  {
    label: 'Basic Authentication',
    value: RegisteredIntegrationConnectionSecurityType.basic,
  },
  {
    label: 'Signature in Header',
    value: RegisteredIntegrationConnectionSecurityType.signature_header,
  },
  {
    label: 'Token and Secret in Headers',
    value: RegisteredIntegrationConnectionSecurityType.token_and_secret,
  },
  {
    label: 'URL Params',
    value: RegisteredIntegrationConnectionSecurityType.url_params,
  },
  {
    label: 'JSON Web Key',
    value: RegisteredIntegrationConnectionSecurityType.json_web_key,
  },
  {
    label: 'External Token',
    value: RegisteredIntegrationConnectionSecurityType.external_token,
  },
  {
    label: 'HMAC Key',
    value: RegisteredIntegrationConnectionSecurityType.hmac_key,
  },
];

const integrationTypeOptions: IChoiceOption[] = [
  {
    label: 'Truework',
    value: 'Truework',
  },
  {
    label: 'Encompass',
    value: 'Encompass',
  },
  {
    label: 'MortgageBot',
    value: 'MortgageBot',
  },
];

const integrationActionOptions: Record<string, IChoiceOption[]> = {
  Truework: [
    {
      label: 'Payroll State Change',
      value: 'payroll_state_change',
    },
  ],
  Encompass: [
    {
      label: 'Get Underwriting Conditions',
      value: 'get_underwriting_conditions',
    },
    {
      label: 'Create Application File',
      value: 'create_application_file',
    },
    {
      label: 'Update Application File',
      value: 'update_application_file',
    },
    {
      label: 'Create Disclosures',
      value: 'create_disclosures',
    },
    {
      label: 'Completed Disclosures',
      value: 'completed_disclosures',
    },
    {
      label: 'Update Milestones',
      value: 'update_milestones',
    },
  ],
  MortgageBot: [
    {
      label: 'Event',
      value: 'event',
    },
  ],
};

const IntegrationTypeDescription = (
  <>
    Required for:
    <ul>
      <li>
        <strong>Truework</strong>: TrueworkJS
      </li>
      <li>
        <strong>Encompass</strong>: Underwriting Conditions, Create Application File, Update
        Application File.
      </li>
      <li>
        <strong>MortgageBot</strong>: Event (also must be v2 connection version!)
      </li>
    </ul>
  </>
);

export const PushbackEndpointForm = () => {
  const [connectionVersion, setConnectionVersion] = useFormValue('connectionVersion');
  const [securityType] = useFormValue('securityType');
  const [integrationType] = useFormValue('integrationType');
  const [integrationAction] = useFormValue('integrationAction');
  const [, setExternalTokenKey] = useFormValue('externalTokenKey');
  const [, setSagaName] = useFormValue('sagaName');

  useEffect(() => {
    if (integrationType === 'Truework' && integrationAction === 'payroll_state_change') {
      setSagaName('TrueworkPayrollStateChange');
      setExternalTokenKey('HTTP_X_TRUEWORK_TOKEN');
    }

    if (integrationType === 'Encompass' && integrationAction === 'get_underwriting_conditions') {
      setSagaName('get_underwriting_conditions');
    }

    if (integrationType === 'MortgageBot' && integrationAction === 'event') {
      setConnectionVersion(2);
      setSagaName('mortgage_bot_milestone_update');
    }
  }, [integrationAction, integrationType, setConnectionVersion, setExternalTokenKey, setSagaName]);

  return (
    <>
      <Select.Box
        name="connectionVersion"
        label="Connection Version"
        options={versionOptions}
        required
      />

      {connectionVersion === 1 ? (
        <Input.Box
          name="pathSlug"
          label="Endpoint Path Slug"
          description="e.g. my-endpoint"
          descriptionTooltip="The endpoint would be available at https://api-production.mortgagehippo.com/integrations/my-endpoint (for production)"
          required
        />
      ) : null}

      <Combobox.Box
        name="integrationType"
        label="Integration Type"
        description={IntegrationTypeDescription}
        options={integrationTypeOptions}
        required={connectionVersion === 2}
        allowClear
      />

      {!!integrationType && (
        <Combobox.Box
          name="integrationAction"
          label="Integration Action"
          options={integrationActionOptions[integrationType] || []}
          required
          allowClear
        />
      )}

      <Input.Box
        name="sagaName"
        label="Saga Name"
        description="e.g. test"
        descriptionTooltip="This is the saga that will be triggered when the endpoint is called. A value of 'test' will trigger the saga called 'TestSaga'"
        required
      />

      <Select.Box name="securityType" label="Security Type" options={typeOptions} required />

      {securityType === RegisteredIntegrationConnectionSecurityType.signature_header && (
        <>
          <Input.Box name="signatureHeader" label="Signature header" required />
          <Input.Box name="signatureSigningKey" label="Signature signing key" required />
        </>
      )}

      {securityType === RegisteredIntegrationConnectionSecurityType.basic && (
        <>
          <Input.Box name="basicUsername" label="Basic Username" required />
          <Input.Box name="basicPassword" label="Basic Password" required />
        </>
      )}

      {securityType === RegisteredIntegrationConnectionSecurityType.json_web_key && (
        <Input.Box name="jwksUrl" label="JSON Web Key Sets URL" required />
      )}

      {securityType === RegisteredIntegrationConnectionSecurityType.external_token && (
        <>
          <Input.Box name="externalToken" label="External Token" required />
          <Input.Box name="externalTokenKey" label="External Token Key" required />
        </>
      )}

      {/* If no input is done by the user, the backend with set the default for this field to be true */}
      <YesNo.Box name="activated" label="Activated" />
    </>
  );
};
