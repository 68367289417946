import { Modal } from '@mortgagehippo/ds';

import { EncompassWebhooksEditor } from './encompass-webhooks-editor';

interface IEncompassWebhooksModalProps {
  partnerId: string;
  siteId?: string;
  onRequestClose: () => void;
  onOk: () => void;
  isOpen: boolean;
}

export const EncompassWebhooksModal = (props: IEncompassWebhooksModalProps) => {
  const { partnerId, siteId, ...rest } = props;

  return (
    <Modal
      title="Manage Encompass Webhooks"
      width={1300}
      compact
      hideCancelButton
      hideOkButton
      {...rest}
    >
      <EncompassWebhooksEditor partnerId={partnerId} siteId={siteId} />
    </Modal>
  );
};
