import { graphql } from '../../apollo/gql';

export const QSiteLandingPagesList = graphql(`
  query SiteLandingPagesList($siteId: ID!, $perPage: Int!, $cursor: String) {
    site(id: $siteId) {
      id
      landingPages(options: { perPage: $perPage, cursor: $cursor, orderBy: { title: "asc" } }) {
        total
        nextCursor
        previousCursor
        items {
          ...LandingPageFragment
        }
      }
    }
  }
`);
