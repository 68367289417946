import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../../apollo/gql';
import { type SmartviewModelType } from '../../apollo/graphql';

const QDefaultSmartviewConfig = graphql(`
  query DefaultSmartviewConfig($partnerId: ID!, $model: SmartviewModelType!) {
    partner(id: $partnerId) {
      id
      smartview(model: $model) {
        id
        config {
          columns
          searchFields
          exportFields
        }
        isSystem
        rawConfig
      }
    }
  }
`);

export const useDefaultSmartviewConfig = (
  partnerId: string,
  model: SmartviewModelType,
  options: IUseApolloQueryOptions = {}
) => {
  const [data, loading, , { refetch }] = useQuery(
    QDefaultSmartviewConfig,
    {
      partnerId,
      model,
    },
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );

  const handleRefetch = useSafeCallback(async () => refetch());

  const smartview = data?.partner?.smartview;
  const { config, isSystem, rawConfig } = smartview || {};

  const isOverridden = !!smartview && !isSystem;

  return [{ config, rawConfig, isOverridden }, loading, handleRefetch] as const;
};
