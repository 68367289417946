import { type IChoiceOption, Numeric, Select, USState, YesNo } from '@mortgagehippo/ds';
import { QuickQuoteLoanPurposeType } from '@mortgagehippo/tasks';

import { CodeEditorField } from '$components/code-editor';

import { generateColumnOptions } from './util';

const columnOptions = generateColumnOptions();

const loanPurposeOptions: IChoiceOption[] = [
  {
    label: 'Purchase',
    value: QuickQuoteLoanPurposeType.PURCHASE,
  },
  {
    label: 'Refinance',
    value: QuickQuoteLoanPurposeType.REFINANCE,
  },
  {
    label: 'Cashout Refinance',
    value: QuickQuoteLoanPurposeType.CASHOUT_REFINANCE,
  },
];

export const QuickQuoteFields = () => (
  <>
    <Select.Box name="config.columns" label="Columns" options={columnOptions} />
    <YesNo.Box
      label="Use assumptions to pre-fill form and generate initial results"
      name="config.useAssumptions"
      initialValue
    />
    <YesNo.Box
      label="Allow displaying buy down points"
      name="config.allowBuyDownOptions"
      initialValue={false}
    />
    <Numeric.Box label="Buy down points page size" name="config.buyDownOptionsPageSize" />
    <YesNo.Box
      label="Allow starting an application with pre-filled information"
      name="config.allowStartApplication"
      initialValue={false}
    />
    <Select.Box
      label="Loan Types"
      description="The types of loans the user can choose from when entering their loan information"
      name="config.allowedLoanPurposes"
      options={loanPurposeOptions}
      multiple
    />
    <USState.Box
      label="States"
      description="The states the user can choose from when entering their property details"
      name="config.allowedStates"
      multiple
    />
    <CodeEditorField.Box mode="scss" name="config.css" label="Custom SCSS" />
  </>
);
