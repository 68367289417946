import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { type ParentType } from '../../../apollo/graphql';
import { MSaveWebhookConfigurationMutation } from '../queries';

export interface ISaveWebhookConfiguration {
  parentId: string;
  parentType: ParentType;
  partnerId: string;
}

export const useSaveWebhookConfiguration = () => {
  const saveWebhookConfiguration = useMutation(MSaveWebhookConfigurationMutation);

  return useCallback(
    async ({ parentId, parentType, partnerId }: ISaveWebhookConfiguration) => {
      await saveWebhookConfiguration(
        {
          parentId,
          parentType,
          partnerId,
        },
        { fetchPolicy: 'no-cache' }
      );
    },
    [saveWebhookConfiguration]
  );
};
