import { Input, Tabs, YesNo } from '@mortgagehippo/ds';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { FieldEditorFrame } from './field-editor-frame';

export const YesNoEditor = ({ name }: { name: string }) => (
  <FieldEditorFrame name={name}>
    {() => [
      <Tabs.Tab key="choices" id="choices" label="Choices">
        <EditorTabWrapper size="md">
          <Input.Box name={`${name}.yesLabel`} label="Yes Label" placeholder="Yes" />
          <Input.Box name={`${name}.noLabel`} label="No Label" placeholder="No" />
          <YesNo.Box
            name={`${name}.displayVertical`}
            label="Vertical"
            description="Displays Yes/No choices vertically instead of side by side."
          />
        </EditorTabWrapper>
      </Tabs.Tab>,
    ]}
  </FieldEditorFrame>
);
