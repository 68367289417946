import { Button, Col, FieldBox, Input, Row, useFieldArray } from '@mortgagehippo/ds';
import { useEffect } from 'react';

interface IOptionProps {
  name: string;
  onChange: (value: string) => any;
  onDelete: () => void;
}

const Option = (props: IOptionProps) => {
  const { name, onChange, onDelete } = props;

  return (
    <Row>
      <Col sm={11}>
        <Input name={`${name}.label`} onChange={onChange} placeholder="Label" />
      </Col>
      <Col sm={11}>
        <Input name={`${name}.value`} onChange={onChange} placeholder="Value" />
      </Col>
      <Col sm={2}>
        <Button
          icon="delete"
          iconButton
          size="xs"
          type="danger"
          importance="tertiary"
          onClick={onDelete}
        />
      </Col>
    </Row>
  );
};

export const OptionsField = (props: { name: string; label: string }) => {
  const { name, label } = props;
  const { fields } = useFieldArray(name, {});
  const len = (fields.value || []).length;

  useEffect(() => {
    if (len < 1) {
      fields.push({ label: '', value: '' });
    }
  }, [fields, len]);

  const handleChange = (index: number) => () => {
    const isLast = !fields.length || fields.length - 1 === index;
    if (isLast) {
      fields.push({ label: '', value: '' });
    }
  };

  const handleDelete = (index: number) => () => {
    fields.remove(index);
  };

  return (
    <FieldBox name={name} label={label}>
      {fields.map((itemName: string, i: number) => (
        <Option
          key={itemName}
          name={itemName}
          onChange={handleChange(i)}
          onDelete={handleDelete(i)}
        />
      ))}
    </FieldBox>
  );
};
