import { Select } from '@mortgagehippo/ds';

import { CodeEditorField } from '$components/code-editor';
import { EditorField } from '$components/editor-field';

import { type IPageComponentFieldsProps } from './types';
import { generateColumnOptions } from './util';

const columnOptions = generateColumnOptions();

export const ContentFields = (props: IPageComponentFieldsProps) => {
  const { languageId } = props;

  return (
    <>
      <Select.Box name="config.columns" label="Columns" options={columnOptions} />
      <EditorField.Box name={`resources.${languageId}.content.title`} label="Title" extended />
      <EditorField.Box name={`resources.${languageId}.content.content`} label="Content" extended />
      <CodeEditorField.Box mode="scss" name="config.css" label="Custom SCSS" />
    </>
  );
};
