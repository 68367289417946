import { ParentType } from '../../apollo/graphql';
import { CredentialsEditorContent } from './credentials-editor-content';

interface ICredentialsEditorProps {
  partnerId: string;
  siteId?: string;
}

export const CredentialsEditor = (props: ICredentialsEditorProps) => {
  const { partnerId, siteId } = props;

  const parentType = siteId ? ParentType.Site : ParentType.Partner;

  return <CredentialsEditorContent partnerId={partnerId} siteId={siteId} parentType={parentType} />;
};
