import { Button, FieldError, useFieldArray } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { AddMoreButton } from './add-more-button';
import { GroupEditor } from './group-editor';

const EmptyContainer = styled.div`
  padding: 20px;
  text-align: center;
`;

interface IGroupsEditorProps {
  name: string;
}

export const GroupsEditor = (props: IGroupsEditorProps) => {
  const { name } = props;
  const { fields } = useFieldArray(name, {});

  const handleAdd = () => () => {
    fields.push({});
  };

  const handleRemove = (i: number) => () => {
    fields.remove(i);
  };

  if (!fields.length) {
    return (
      <>
        <EmptyContainer>
          <Button icon="edit" type="neutral" onClick={handleAdd()} size="sm">
            Add a group
          </Button>
        </EmptyContainer>
        <FieldError name={name} />
      </>
    );
  }

  return (
    <>
      {fields.map((itemName, i) => (
        <GroupEditor key={itemName} name={itemName} onRemove={handleRemove(i)} />
      ))}
      <AddMoreButton onClick={handleAdd()}>Add Group</AddMoreButton>
      <FieldError name={name} />
    </>
  );
};
