import { graphql } from '../../../apollo/gql';
import { type SiteMilestonesListQuery } from '../../../apollo/graphql';

export type IMilestone = NonNullable<SiteMilestonesListQuery['site']>['milestones'][0];

export const QSiteMilestonesList = graphql(`
  query SiteMilestonesList($siteId: ID!) {
    site(id: $siteId) {
      id
      milestones {
        id
        key
        name
        position
        externalSourceKeys
        externalMilestoneKeys {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`);

export const MUpdateSiteMilestonesMutation = graphql(`
  mutation UpdateSiteMilestones($siteId: ID!, $data: UpdateMilestoneInput!) {
    updateMilestones(siteId: $siteId, data: $data) {
      id
      tempId
      key
      name
      position
      externalMilestoneKeys {
        id
        name
      }
      externalSourceKeys
      createdAt
      updatedAt
      errors
      deleted
    }
  }
`);
