import { type RouteComponentProps } from 'react-router';

import { usePartner } from '../../../hooks/use-partner';
import { Content, Layout } from '../../../layouts/main';
import { PartnerPreQualificationLetterPageContent } from './partner-pre-qualification-letter-page-content';

interface IPartnerPreQualificationLetterPageRouteParams {
  partnerId: string;
}

type IPartnerPreQualificationLetterPageProps =
  RouteComponentProps<IPartnerPreQualificationLetterPageRouteParams>;

export const PartnerPreQualificationLetterPage = (
  props: IPartnerPreQualificationLetterPageProps
) => {
  const { match } = props;
  const { params } = match;
  const { partnerId } = params;
  const [partner] = usePartner(partnerId);

  const partnerName = partner?.name || '';

  if (!partnerName) {
    return null;
  }

  return (
    <Layout pageTitle={`${partnerName} - Pre-Qualification Letter`}>
      <Content title="Pre-Qualification Letter" subTitle={partnerName}>
        <PartnerPreQualificationLetterPageContent partnerId={partnerId} />
      </Content>
    </Layout>
  );
};
