import { DEFAULT_THEME, type ISelectOption, Select, Tabs } from '@mortgagehippo/ds';
import { DIVIDER_DEFAULT_SPACING_BOTTOM, DIVIDER_DEFAULT_SPACING_TOP } from '@mortgagehippo/tasks';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { FieldEditorFrame } from './field-editor-frame';

const createOptions = (defaultValue: number) => {
  const options: ISelectOption[] = [
    {
      label: `Default Spacing (${defaultValue})`,
      value: 'default',
    },
  ];
  options.push(
    ...Object.keys(DEFAULT_THEME.spacing).map((_key, index) => ({
      label: index.toString(),
      value: index.toString(),
    }))
  );
  return options;
};

export const DividerEditor = ({ name }: { name: string }) => (
  <FieldEditorFrame name={name}>
    {() => (
      <Tabs.Tab id="options" label="Divider Options">
        <EditorTabWrapper size="md">
          <Select.Box
            name={`${name}.spacingTop`}
            label="Spacing above divider"
            options={createOptions(DIVIDER_DEFAULT_SPACING_TOP)}
          />
          <Select.Box
            name={`${name}.spacingBottom`}
            label="Spacing below divider"
            options={createOptions(DIVIDER_DEFAULT_SPACING_BOTTOM)}
          />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
  </FieldEditorFrame>
);
