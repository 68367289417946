import { Input, Tabs } from '@mortgagehippo/ds';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { FieldEditorFrame } from './field-editor-frame';
import { NumericValidationEditor } from './numeric-validation-editor';

export const YearEditor = ({ name }: { name: string }) => (
  <FieldEditorFrame name={name} validations={() => <NumericValidationEditor name={name} />}>
    {() => (
      <Tabs.Tab id="year" label="Year Options">
        <EditorTabWrapper size="md">
          <Input.Box name={`${name}.placeholder`} label="Placeholder" />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
  </FieldEditorFrame>
);
