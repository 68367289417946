import Logic from '@himaxwell/logic';
import {
  createField,
  Icon,
  type IFieldInputProps,
  type IInputProps,
  Input,
  Text,
} from '@mortgagehippo/ds';
import { isPresent } from '@mortgagehippo/util';
import { isPlainObject, memoize } from 'lodash-es';
import { useMemo, useState } from 'react';

// We need to use the raw logic so it doesn't have any extensions
const logic = new Logic({ extended: false });
logic.resetCustomOperations();

const validate = memoize((value: any) => {
  if (isPresent(value)) {
    const error = logic.validate(value);
    if (isPresent(error)) {
      return error;
    }
  }
  return undefined;
});

const LogicFieldJsonInput = (props: IInputProps & IFieldInputProps) => {
  const { value: formValue, onChange } = props;

  const initialValue = useMemo(() => {
    if (isPlainObject(formValue)) {
      try {
        return logic.compile(formValue);
      } catch (e) {
        return '';
      }
    }
    return formValue;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [value, setValue] = useState(initialValue);
  const [valid, setValid] = useState(true);

  const handleChange = (nextValue: string) => {
    setValue(nextValue);

    if (!isPresent(nextValue)) {
      onChange(undefined);
      setValid(true);
      return;
    }

    try {
      onChange(logic.parse(nextValue));
      setValid(true);
    } catch (e) {
      console.error(e);
      onChange(null);
      setValid(false);
    }
  };

  return (
    <div>
      <Input.Input {...props} value={value} onChange={handleChange} compact />
      {!valid && (
        <Text variant="warning" size="sm">
          <Icon name="warning" size="normal" color="warning600" /> invalid syntax
        </Text>
      )}
    </div>
  );
};

export const LogicFieldJson: any = createField(LogicFieldJsonInput, undefined, {
  allowNull: true,
  validate,
});
