import { FieldArrayList, type IFieldArrayListProps, type UploadHandler } from '@mortgagehippo/ds';

import { TemplatesForm } from './templates-form';

type ITemplatesArrayProps = Omit<IFieldArrayListProps, 'renderForm' | 'renderItemLabel'> & {
  onUploadDocument: UploadHandler;
};

export const TemplatesArray = (props: ITemplatesArrayProps) => {
  const { onUploadDocument, ...rest } = props;

  return (
    <FieldArrayList
      {...rest}
      renderItemLabel={(itemValue: any) => itemValue?.title || 'Unnamed'}
      renderForm={() => <TemplatesForm onUploadDocument={onUploadDocument} />}
    />
  );
};
