import {
  Alert,
  Button,
  Constant,
  FieldBox,
  FormError,
  FormSpy,
  type FormState,
  type FormSubmitHandler,
  HelpButton,
  Icon,
  Input,
  type ISelectOption,
  ModalForm,
  Numeric,
  palette,
  Select,
  SimpleFieldAutoArray,
  spacing,
} from '@mortgagehippo/ds';
import { isEmpty, isEqual } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { CodeEditorField } from '$components/code-editor';

import { CascadeActionType, CascadeOwnerType, CascadeType } from '../../apollo/graphql';
import { type ICascadeConfiguration } from './queries';
import { type ICascadeConfigurationAction } from './types';

export interface ISaveCascadeConfigurationAction
  extends Omit<ICascadeConfigurationAction, 'startCondition' | 'id'> {
  startCondition: string | null;
}

export type ISaveCascadeConfigurationModalFormValues = Partial<
  Pick<ICascadeConfiguration, 'name' | 'cascadeType' | 'cascadeOwnerType'>
> & {
  cascadeConfigurationActions?: ISaveCascadeConfigurationAction[];
};

const startConditionExample = [
  'IF this cascade action is transitioned:',
  '  -> Sets the cascade run item status to {SPECIFIED STATUS} if *either* the first or second item status is SUCCESS',
  '  -> Sets the cascade run item status to SKIPPED if *both* the first and second item status are *not* SUCCESS',
  'IF another cascade action is transitioned',
  '  -> Sets the cascade run item status to SKIPPED if *both* the first and second item status are *not* SUCCESS',
  '',
  '{',
  '  "or" : [',
  '    {"===" : [ { "var" : "cascade_run_items.0.status" },"SUCCESS" ]},',
  '    {"===" : [ { "var" : "cascade_run_items.1.status" },"SUCCESS" ]}',
  '  ]',
  '}',
  '',
  'NOTE: Just because the status is changed does not mean anything else will happen as a result!!!',
  'Future actions may be prevented, but any callback actions must be manually handled within whatever service is responsible for transitioning the cascade run!!!',
];

const startConditionLabelTooltip = (
  <div style={{ minWidth: 800 }}>
    Whenever any cascade run item for the cascade run is transitioned, this will set any cascade run
    item for this action as skipped if the specified conditions are met.
    <br />
    <br />
    Example:
    <br />
    <br />
    <div style={{ whiteSpace: 'pre-wrap' }}>
      {startConditionExample.map((line, index) => (
        <>
          {index !== 0 && <br />}
          {line}
        </>
      ))}
    </div>
  </div>
);

const cascadeTypeOptions: ISelectOption[] = [
  {
    label: CascadeType.VOA,
    value: CascadeType.VOA,
  },
  {
    label: CascadeType.VOIE,
    value: CascadeType.VOIE,
  },
];

const cascadeOwnerTypeOptions: ISelectOption[] = [
  {
    label: 'Applicant',
    value: CascadeOwnerType.APPLICANT,
  },
  {
    label: 'Agent',
    value: CascadeOwnerType.AGENT,
  },
];

const getCascadeActionTypeOptions = (
  selectedCascadeType?: CascadeType,
  selectedCascadeOwnerType?: CascadeOwnerType
): ISelectOption[] => {
  if (!selectedCascadeType || !selectedCascadeOwnerType) {
    return [];
  }

  const options: Record<CascadeOwnerType, Record<CascadeType, ISelectOption[]>> = {
    [CascadeOwnerType.APPLICANT]: {
      [CascadeType.VOIE]: [
        {
          label: CascadeActionType.WORKNUMBER_INSTANT,
          value: CascadeActionType.WORKNUMBER_INSTANT,
        },
        {
          label: CascadeActionType.TRUEWORK_INSTANT,
          value: CascadeActionType.TRUEWORK_INSTANT,
        },
        {
          label: CascadeActionType.TRUEWORK_PAYROLL,
          value: CascadeActionType.TRUEWORK_PAYROLL,
        },
      ],
      [CascadeType.VOA]: [],
    },
    [CascadeOwnerType.AGENT]: {
      [CascadeType.VOIE]: [
        {
          label: CascadeActionType.WORKNUMBER_INSTANT,
          value: CascadeActionType.WORKNUMBER_INSTANT,
        },
        {
          label: CascadeActionType.TRUEWORK_INSTANT,
          value: CascadeActionType.TRUEWORK_INSTANT,
        },
      ],
      [CascadeType.VOA]: [],
    },
  };

  return options[selectedCascadeOwnerType][selectedCascadeType] || [];
};

const Row = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: ${spacing(4)};
`;

const Col = styled.div<{ flex?: number; isDraggableIcon?: boolean; isDeleteIcon?: boolean }>`
  flex: ${({ flex }) => (flex === undefined ? 1 : flex)};
  flex-basis: ${({ flex }) => (flex === undefined ? '100%' : 'auto')};
  margin-right: 10px;

  ${(p) =>
    p.isDraggableIcon &&
    css`
      padding-top: ${spacing(5)};
    `}

  ${(p) =>
    p.isDeleteIcon &&
    css`
      padding-top: ${spacing(4)};
    `}

  &:last-child {
    margin-right: 0;
  }
`;

const StyledIcon = styled(Icon)<{ greyed?: boolean }>`
  ${(p) =>
    p.greyed &&
    css`
      color: ${palette('neutral300')};
    `}
`;

const validateStartCondition = (value: any) => {
  try {
    if (!value) {
      return undefined;
    }

    JSON.parse(value);
    return undefined;
  } catch {
    return 'Invalid code';
  }
};

const validator = (
  value: ICascadeConfigurationAction,
  allValues: ISaveCascadeConfigurationModalFormValues
) => {
  const { cascadeConfigurationActions } = allValues;

  if (!cascadeConfigurationActions?.length) {
    return undefined;
  }

  const { actionType } = value;

  let errors;

  if (
    cascadeConfigurationActions.filter(
      (cascadeConfigurationAction) => cascadeConfigurationAction.actionType === actionType
    ).length > 1
  ) {
    errors = {
      actionType: 'Duplicate',
    };
  }

  return errors;
};

interface ISaveCascadeConfigurationModalProps {
  isOpen: boolean;
  isNew: boolean;
  initialValues?: ISaveCascadeConfigurationModalFormValues;
  onSubmit: FormSubmitHandler<ISaveCascadeConfigurationModalFormValues>;
  onRequestClose: () => void;
  loading?: boolean;
  saveDisabled?: boolean;
}

export const SaveCascadeConfigurationModal = (props: ISaveCascadeConfigurationModalProps) => {
  const { onSubmit, onRequestClose, isNew, isOpen, loading, initialValues } = props;

  const [hasChanges, setHasChanges] = useState(false);
  const [selectedCascadeType, setSelectedCascadeType] = useState(initialValues?.cascadeType);
  const [selectedCascadeOwnerType, setSelectedCascadeOwnerType] = useState(
    initialValues?.cascadeOwnerType
  );

  // TODO: handle undefined <-> empty string changes when input is enabled
  const handleFormChange = useCallback(
    ({
      values,
    }: FormState<
      ISaveCascadeConfigurationModalFormValues,
      ISaveCascadeConfigurationModalFormValues
    >) => {
      const withoutEmptyRowValues = {
        ...values,
        cascadeConfigurationActions: (values.cascadeConfigurationActions || []).filter(
          (cascadeConfigurationAction) => !isEmpty(cascadeConfigurationAction)
        ),
      };
      const { cascadeType, cascadeOwnerType } = withoutEmptyRowValues;

      setSelectedCascadeType(cascadeType);
      setSelectedCascadeOwnerType(cascadeOwnerType);
      setHasChanges(!isEqual(initialValues, withoutEmptyRowValues));
    },
    [initialValues]
  );

  const cascadeActionTypeOptions: ISelectOption[] = useMemo(
    () => getCascadeActionTypeOptions(selectedCascadeType, selectedCascadeOwnerType),
    [selectedCascadeType, selectedCascadeOwnerType]
  );

  return (
    <ModalForm<ISaveCascadeConfigurationModalFormValues>
      title={`${isNew ? 'Create' : 'Update'} Cascade`}
      isOpen={isOpen}
      loading={loading}
      okButtonLabel="Save cascade"
      onSubmit={onSubmit}
      onRequestClose={onRequestClose}
      initialValues={initialValues}
      okButtonDisabled={!hasChanges}
      width="wide"
    >
      <FormSpy onChange={handleFormChange} subscription={{ values: true }} />
      <Select.Box
        name="cascadeType"
        label="Cascade Type"
        placeholder="Select Type"
        options={cascadeTypeOptions}
        disabled={!isNew}
        required
      />
      <Select.Box
        name="cascadeOwnerType"
        label="Cascade Owner Type"
        placeholder="Select Owner Type"
        options={cascadeOwnerTypeOptions}
        disabled={!isNew}
        required
      />
      <Input.Box name="name" label="Cascade Name" disabled={!isNew} required />
      {!isNew && (
        <Alert icon="warning" type="warning">
          <strong>
            Editing cascade actions will create a new cascade with the new configuration and a new
            version!
            <br />
            <br />
            The old version will still be used for previously created application files.
          </strong>
        </Alert>
      )}
      <FieldBox name="cascadeConfigurationActions" label="Cascade Actions" labelInvisible>
        <SimpleFieldAutoArray
          name="cascadeConfigurationActions"
          sortable
          presentFields={['position', 'actionType']}
          render={({ name: itemName, handleProps, onRemove, index, isLastItem, sortable }) => (
            <Row key={itemName}>
              <Col flex={0} isDraggableIcon {...(handleProps || {})}>
                <StyledIcon name="drag-handle" greyed={!sortable} />
              </Col>
              <Col flex={1}>
                <Constant name={`${itemName}.id`} />
                <Select.Box
                  name={`${itemName}.actionType`}
                  label="Cascade Action Type"
                  placeholder="Select Action Type"
                  options={cascadeActionTypeOptions}
                  size="sm"
                  required={index !== 0 && !isLastItem}
                  hideRequiredLabel
                  compact
                />
              </Col>
              <Col flex={0.25}>
                <CodeEditorField.Box
                  label={
                    <>
                      Start Condition{' '}
                      <HelpButton
                        size="xs"
                        icon="information"
                        popover
                        popoverProps={{
                          align: 'TopCenter',
                          minWidthCSS: '900px',
                        }}
                      >
                        {startConditionLabelTooltip}
                      </HelpButton>
                    </>
                  }
                  name={`${itemName}.startCondition`}
                  mode="json"
                  height="200px"
                  validate={validateStartCondition}
                  size="sm"
                  compact
                />
              </Col>
              <Col flex={0}>
                <Numeric.Box
                  name={`${itemName}.timeout`}
                  label="Timeout"
                  size="sm"
                  labelTooltip="DOES NOT WORK YET! Timeout in seconds (after started)"
                  disabled
                  compact
                />
              </Col>

              <Col flex={0} isDeleteIcon>
                <Button
                  icon="delete"
                  iconButton
                  size="xs"
                  type="danger"
                  importance="tertiary"
                  onClick={onRemove}
                  compact
                />
              </Col>
            </Row>
          )}
          validateItem={validator}
        />
      </FieldBox>
      <FormError alertSize="sm" />
    </ModalForm>
  );
};
