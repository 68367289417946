import { borderRadius, Button, palette, shadow, spacing, Tabs } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { ConditionEditor } from './condition-editor';
import { GroupFieldsEditor } from './group-fields-editor';
import { TitleEditor } from './title-editor';

const Container = styled.div`
  position: relative;
  box-shadow: ${shadow(1)};
  border-radius: ${borderRadius()};
  min-height: 150px;
  padding: ${spacing(1)} ${spacing(4)};
  margin-bottom: ${spacing(7)};
  background: ${palette('white')};
`;

const Buttons = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
`;

interface IGroupEditorProps {
  name: string;
  onRemove: () => void;
}

export const GroupEditor = ({ name, onRemove }: IGroupEditorProps) => (
  <Container>
    <Buttons>
      <Button
        icon="delete"
        size="xxs"
        type="danger"
        importance="secondary-outline"
        iconButton
        onClick={onRemove}
      />
    </Buttons>
    <Tabs>
      <Tabs.Tab id="fields" label="Fields">
        <EditorTabWrapper>
          <GroupFieldsEditor name={name} />
        </EditorTabWrapper>
      </Tabs.Tab>

      <Tabs.Tab id="description" label="Logic & Description">
        <EditorTabWrapper>
          <TitleEditor name={name} />
          <ConditionEditor name={name} />
        </EditorTabWrapper>
      </Tabs.Tab>
    </Tabs>
  </Container>
);
