import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../apollo/gql';

export const MDeleteSiteMutation = graphql(`
  mutation DeleteSite($siteId: ID!) {
    deleteSite(siteId: $siteId) {
      id
    }
  }
`);

export const useDeleteSite = () => {
  const deleteSite = useMutation(MDeleteSiteMutation);

  return useCallback((siteId: string) => deleteSite({ siteId }), [deleteSite]);
};
