import {
  Button,
  Card,
  Form,
  FormError,
  FormSpy,
  type FormState,
  type FormSubmitHandler,
  type IButtonProps,
  Popover,
  spacing,
  SubmitButton,
  useModal,
} from '@mortgagehippo/ds';
import { isEqual, trim } from 'lodash-es';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { CodeEditorField } from '$components/code-editor';

import { type SmartviewModelType } from '../../apollo/graphql';
import {
  SAMPLE_COLUMNS_JSON,
  SAMPLE_EXPORT_FIELDS_JSON,
  SAMPLE_SEARCH_FIELDS_JSON,
} from './constants';
import { PipelineDocumentationModalContainer } from './pipeline-documentation-modal-container';
import { PipelineEditorLabel } from './pipeline-editor-label';
import { isPipelineColumn, isPipelineExportField, isPipelineSearchField } from './util';

const validateColumns = (value: string) => {
  try {
    const parsed = JSON.parse(value);

    if (!Array.isArray(parsed) || !parsed.every((c: any) => isPipelineColumn(c))) {
      return 'Invalid columns format';
    }

    return undefined;
  } catch (e) {
    return 'Could not parse columns';
  }
};

const validateSearchFields = (value: string) => {
  try {
    const trimmed = trim(value || '');

    if (!trimmed) {
      return undefined;
    }

    const parsed = JSON.parse(trimmed);

    if (!Array.isArray(parsed) || !parsed.every((c: any) => isPipelineSearchField(c))) {
      return 'Invalid search fields format';
    }

    return undefined;
  } catch (e) {
    return 'Could not parse search fields';
  }
};

const validateExportFields = (value: string) => {
  try {
    const trimmed = trim(value || '');

    if (!trimmed) {
      return undefined;
    }

    const parsed = JSON.parse(trimmed);

    if (!Array.isArray(parsed) || !parsed.every((c: any) => isPipelineExportField(c))) {
      return 'Invalid export fields format';
    }

    return undefined;
  } catch (e) {
    return 'Could not parse export fields';
  }
};

const Actions = styled.div`
  text-align: right;
`;

const ConfirmationDialog = styled(Popover)`
  margin-right: ${spacing(3)};
`;

interface IPipelineEditorProps {
  partnerId: string;
  model: SmartviewModelType;
  initialValues: any;
  onRestore?: () => Promise<any>;
  onSave: FormSubmitHandler;
}

const restoreButtonProps: IButtonProps = {
  icon: 'delete',
  size: 'xs',
  importance: 'secondary',
  type: 'danger',
};

export const PipelineEditor = (props: IPipelineEditorProps) => {
  const { partnerId, model, initialValues, onRestore, onSave } = props;

  const [formKey, setFormKey] = useState(1);
  const [isOpen, openModal, closeModal] = useModal(false);

  const [values, setValues] = useState(initialValues);
  const [hasChanges, setHasChanges] = useState(false);

  const handleFormChange = (state: FormState<any>) => {
    const { values: nextValues } = state;

    setValues(nextValues);
  };

  const handleUndo = () => {
    setFormKey((prevValue) => prevValue + 1);
  };

  const handleDocumentation = () => {
    openModal();
  };

  useEffect(() => {
    setHasChanges(!isEqual(initialValues, values));
  }, [initialValues, values]);

  return (
    <Card>
      <Form key={`${formKey}`} onSubmit={onSave} initialValues={initialValues}>
        <FormSpy
          onChange={handleFormChange}
          subscription={{
            values: true,
          }}
        />
        <Actions>
          {onRestore ? (
            <ConfirmationDialog
              content="This action is irreversible"
              title="Are you sure?"
              onConfirm={onRestore}
              buttonProps={restoreButtonProps}
              confirm
            >
              Revert to default
            </ConfirmationDialog>
          ) : null}

          <Button
            icon="undo"
            size="xs"
            onClick={handleUndo}
            disabled={!hasChanges}
            importance="secondary"
            type="neutral"
          >
            Undo changes
          </Button>

          <SubmitButton size="xs" importance="primary" disabled={!hasChanges}>
            Save
          </SubmitButton>

          <Button
            type="neutral"
            icon="information"
            importance="tertiary"
            onClick={handleDocumentation}
            size="sm"
            iconButton
            iconButtonRound
            iconButtonHumble
          />
        </Actions>
        <CodeEditorField.Box
          name="columns"
          label={<PipelineEditorLabel sample={SAMPLE_COLUMNS_JSON}>Columns</PipelineEditorLabel>}
          mode="json"
          required
          validate={validateColumns}
        />
        <CodeEditorField.Box
          name="searchFields"
          label={
            <PipelineEditorLabel sample={SAMPLE_SEARCH_FIELDS_JSON}>
              Search Fields
            </PipelineEditorLabel>
          }
          mode="json"
          validate={validateSearchFields}
          description="If you leave this empty the system defaults will be used."
        />
        <CodeEditorField.Box
          name="exportFields"
          label={
            <PipelineEditorLabel sample={SAMPLE_EXPORT_FIELDS_JSON}>
              Export Fields
            </PipelineEditorLabel>
          }
          mode="json"
          description="If you leave this empty the export fields will be calculated from the columns configured for this pipeline."
          validate={validateExportFields}
        />
        <FormError />
      </Form>
      <PipelineDocumentationModalContainer
        partnerId={partnerId}
        model={model}
        onRequestClose={closeModal}
        isOpen={isOpen}
      />
    </Card>
  );
};
