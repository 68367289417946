import { CheckboxList, type IChoiceOption, Input, spacing } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { SnapdocsSubscriptionEventsType } from '../../apollo/graphql';

const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -${spacing(3)};
`;

const FieldsCol = styled.div`
  flex: 1 1 auto;
  margin: 0 ${spacing(3)};
`;

const eventOptions: IChoiceOption[] = (
  Object.keys(SnapdocsSubscriptionEventsType) as Array<keyof typeof SnapdocsSubscriptionEventsType>
).map((key) => ({
  label: key,
  value: SnapdocsSubscriptionEventsType[key],
}));

export const CreateSnapdocsSubscriptionForm = () => (
  <FieldsRow>
    <FieldsCol>
      <CheckboxList.Box
        name="events"
        label="Events"
        options={eventOptions}
        description="Events to subscribe to."
        allowSelectAll
        required
      />
    </FieldsCol>
    <FieldsCol>
      <Input.Box name="description" label="Description" description="Optional description." />
    </FieldsCol>
  </FieldsRow>
);
