import { type SmartviewModelType } from '../../apollo/graphql';
import { PipelineDocumentationModal } from './pipeline-documentation-modal';
import { useSmartviewDocumentation } from './use-smartview-documentation';

interface IPipelineDocumentationModalContainerProps {
  partnerId: string;
  model: SmartviewModelType;
  onRequestClose: () => void;
  isOpen: boolean;
}

export const PipelineDocumentationModalContainer = (
  props: IPipelineDocumentationModalContainerProps
) => {
  const { partnerId, model, onRequestClose, isOpen } = props;

  const [pipelineDocumentation] = useSmartviewDocumentation(partnerId, model, {
    skip: !isOpen,
  });

  return (
    <PipelineDocumentationModal
      documentation={pipelineDocumentation}
      onRequestClose={onRequestClose}
      isOpen={isOpen}
    />
  );
};
