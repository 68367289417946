import { Input, useFormValue, YesNo } from '@mortgagehippo/ds';
import { FieldType } from '@mortgagehippo/tasks';
import { useEffect } from 'react';

import { EditorField } from '$components/editor-field';

export interface ITitleEditorProps {
  name: string;
  titleRequired?: boolean;
  allowCompact?: boolean;
}

export const TitleEditor = ({ name, titleRequired, allowCompact }: ITitleEditorProps) => {
  const [title] = useFormValue(`${name}.title`);
  const [fieldType] = useFormValue(`${name}.type_name`);
  const [compactTop, setCompactTop] = useFormValue(`${name}.compactTop`);

  useEffect(() => {
    if ((title && compactTop !== undefined) || fieldType === FieldType.DIVIDER) {
      setCompactTop(undefined);
    }
  }, [title, compactTop, setCompactTop, fieldType]);

  const compactTopVisible = allowCompact && fieldType !== FieldType.DIVIDER;

  return (
    <>
      <Input.Box
        name={`${name}.title`}
        label="Label"
        description="Mandatory for accessibility and display purposes. Optional for checkbox fields and groups. Can be optional for Entity fields, if the containing group has a label."
        required={titleRequired}
      />
      <EditorField.Box name={`${name}.title_tooltip`} label="Label tooltip" />
      <Input.Box name={`${name}.explanation`} label="Explanation" />
      <Input.Box name={`${name}.explanation_tooltip`} label="Explanation tooltip" />
      {compactTopVisible ? (
        <YesNo.Box
          disabled={!!title}
          name={`${name}.compactTop`}
          label="Snap to element above"
          description="Adds negative top margin to remove the space between this field and whatever comes above it. Only available if no label is provided. This option is suitable to chain choice or checkbox fields so if you have multiple consecutive fields but want them to be displayed like one field. DO NOT turn this on if this is the first field in a panel or a group."
        />
      ) : null}
    </>
  );
};
