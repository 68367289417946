import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo/gql';
import { type LandingPageCustomizationsQuery } from '../../apollo/graphql';

export const QLandingPageCustomizations = graphql(`
  query LandingPageCustomizations($projectId: String!, $partnerId: Int!, $domain: String) {
    customizationValues(projectId: $projectId, partnerId: $partnerId, domain: $domain) {
      ...CustomizationValueFragment
    }
  }
`);

export type ILandingPageCustomization = LandingPageCustomizationsQuery['customizationValues'][0];

export const useLandingPageCustomizations = (
  partnerId: number,
  domain: string,
  options: IUseApolloQueryOptions = {}
) => {
  const query = QLandingPageCustomizations;

  const projectId = 'BORROWER_PORTAL';

  const [data, loading, , { refetch }] = useQuery(
    query,
    {
      partnerId,
      domain,
      projectId,
    },
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );

  const handleRefresh = useCallback(async () => {
    await refetch({ partnerId, domain, projectId });
  }, [refetch, partnerId, domain]);

  let customizations: ILandingPageCustomization[] = [];
  if (!loading && data) {
    customizations = data.customizationValues;
  }

  return [{ data: customizations, loading }, handleRefresh] as const;
};
