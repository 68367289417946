import { Button, FieldBox, Input, SimpleFieldAutoArray, spacing } from '@mortgagehippo/ds';
import styled, { css } from 'styled-components';

const Row = styled.div`
  display: flex;
  align-items: stretch;
`;

const Col = styled.div<{ flex?: number; isDraggableIcon?: boolean }>`
  flex: ${({ flex }) => (flex === undefined ? 1 : flex)};
  flex-basis: ${({ flex }) => (flex === undefined ? '100%' : 'auto')};
  margin-right: 10px;

  ${(p) =>
    p.isDraggableIcon &&
    css`
      margin-top: ${spacing(1)};
    `}

  &:last-child {
    margin-right: 0;
  }
`;

export const VelocifyFields = () => (
  <>
    <Input.Box name="client_id" label="Client ID" required />
    <Input.Box name="campaign_id" label="Campaign ID" required />
    <FieldBox name="optional_query_params" label="Optional query params">
      <SimpleFieldAutoArray
        name="optional_query_params"
        presentFields={['key', 'value']}
        render={({ name: itemName, onRemove, index, isLastItem }) => (
          <Row key={itemName}>
            <Col>
              <Input.Box
                name={`${itemName}.key`}
                label="Key"
                placeholder="Key"
                size="normal"
                labelInvisible
                hideRequiredLabel
                required={index !== 0 && !isLastItem}
              />
            </Col>
            <Col>
              <Input.Box
                name={`${itemName}.value`}
                label="value"
                placeholder="Value"
                size="normal"
                labelInvisible
                hideRequiredLabel
                required={index !== 0 && !isLastItem}
              />
            </Col>
            <Col flex={0}>
              <Button
                icon="delete"
                iconButton
                size="xs"
                type="danger"
                importance="tertiary"
                onClick={onRemove}
              />
            </Col>
          </Row>
        )}
      />
    </FieldBox>
  </>
);
