import { notifications, type UploadHandler, useModal } from '@mortgagehippo/ds';
import { isInsecureFileUploadError, useScanDocument } from '@mortgagehippo/tasks';
import axios, { type AxiosProgressEvent } from 'axios';
import { useCallback, useState } from 'react';

import { useCreatePartnerDocuments } from '../../../hooks/use-create-partner-documents';
import { usePartnerDocumentSubmitUrl } from '../../../hooks/use-partner-document-submit-url';
import { type IActionProps, type IUploadPartnerDocumentAction } from '../types';
import {
  type IUploadPartnerFilesFormValues,
  UploadPartnerFilesModal,
} from './upload-partner-files-modal';

export const UploadPartnerDocumentsAction = (
  props: IActionProps<IUploadPartnerDocumentAction, any, IUploadPartnerFilesFormValues>
) => {
  const { action, onDone, onCancel } = props;
  const { partnerId } = action;

  const [submitDisabled, setSubmitDisabled] = useState<boolean | undefined>();

  const getSubmitUrl = usePartnerDocumentSubmitUrl();
  const createPartnerDocuments = useCreatePartnerDocuments();
  const scanDocument = useScanDocument();

  const [isOpen, , closeModal] = useModal(true);

  const handleSubmit = async (values: any) => {
    try {
      const documents = (values.documents || []).map(({ id, description }: any) => ({
        id,
        description,
      }));

      const docs = await createPartnerDocuments(partnerId, documents);
      if (!docs?.length) {
        throw new Error('Invalid response, no documents.');
      }

      closeModal(async () => {
        notifications.success({
          message: 'Successfully uploaded documents',
        });
        onDone();
      });
    } catch (e) {
      console.error(e);
      notifications.error({
        messageCid: 'uploadPartnerDocumentsModal:notification.create.error',
        message: 'There was an unexpected error creating the documents',
      });
    }
  };

  const handleUpload: UploadHandler = async (file, meta, progress) => {
    try {
      setSubmitDisabled(true);
      const { id: uploadId, postUrl } = await getSubmitUrl(partnerId, meta.filename);

      await axios.put(postUrl, file, {
        headers: {
          'Content-Type': meta.mime,
        },
        onUploadProgress: (e: AxiosProgressEvent) => {
          if (!e.total) return;
          const percent = Math.round((e.loaded * 100) / e.total);
          progress(percent);
        },
      });

      try {
        await scanDocument(uploadId);
      } catch (e: unknown) {
        if (isInsecureFileUploadError(e)) {
          return { id: uploadId, hasVirus: true };
        }
        throw e;
      }

      return { id: uploadId };
    } catch (e) {
      notifications.error({
        message: 'There was an unexpected error while uploading your file',
      });
      throw e;
    } finally {
      setSubmitDisabled(false);
    }
  };

  const handleCloseModal = useCallback(
    (formValues?: IUploadPartnerFilesFormValues) => {
      closeModal(() => {
        onCancel(formValues);
      });
    },
    [closeModal, onCancel]
  );

  return (
    <UploadPartnerFilesModal
      isOpen={isOpen}
      title="Upload Partner Files"
      onUpload={handleUpload}
      onSubmit={handleSubmit}
      onRequestClose={handleCloseModal}
      submitDisabled={submitDisabled}
    />
  );
};
