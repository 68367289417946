import { Modal, spacing } from '@mortgagehippo/ds';
import { Skeleton } from 'antd';
import styled from 'styled-components';

import { PipelineDocumentationModalList } from './pipeline-documentation-modal-list';
import { type ISmartviewDocumentation } from './use-smartview-documentation';

const Section = styled.div`
  margin-top: ${spacing(4)};

  &:first-child {
    margin-top: 0;
  }
`;

interface IPipelineDocumentationModalProps {
  documentation?: ISmartviewDocumentation;
  onRequestClose: () => void;
  isOpen: boolean;
}

export const PipelineDocumentationModal = (props: IPipelineDocumentationModalProps) => {
  const { documentation, onRequestClose, isOpen } = props;
  const { dataFields, searchFields, exportFields } = documentation || {};

  return (
    <Modal
      title="Pipeline Documentation"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onOk={onRequestClose}
      hideCancelButton
      width={800}
    >
      {!documentation && <Skeleton active paragraph />}
      {dataFields ? (
        <Section>
          <PipelineDocumentationModalList
            title="Data Fields"
            fields={dataFields}
            tooltip="These fields can be used in pipeline columns."
          />
        </Section>
      ) : null}
      {searchFields ? (
        <Section>
          <PipelineDocumentationModalList title="Search Fields" fields={searchFields} />
        </Section>
      ) : null}
      {exportFields ? (
        <Section>
          <PipelineDocumentationModalList title="Export Fields" fields={exportFields} />
        </Section>
      ) : null}
    </Modal>
  );
};
