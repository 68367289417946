import { graphql } from '../../apollo/gql';

export const QWebhookConfiguration = graphql(`
  query WebhookConfiguration(
    $parentId: ID!
    $parentType: ParentType!
    $partnerId: ID!
  ) {
    webhookConfiguration(
      parentId: $parentId
      parentType: $parentType
      partnerId: $partnerId
    ) {
      id
      parentId
      parentType
      partnerId
      guid
      inherited
      overwrittenBy {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`);

export const MSaveWebhookConfigurationMutation = graphql(`
  mutation SaveWebhookConfiguration(
    $parentId: ID!
    $parentType: ParentType!
    $partnerId: ID!
  ) {
    saveWebhookConfiguration(
      parentId: $parentId
      parentType: $parentType
      partnerId: $partnerId
    )
  }
`);

export const MDeleteWebhookConfigurationMutation = graphql(`
  mutation DeleteWebhookConfiguration($id: ID!) {
    deleteWebhookConfiguration(id: $id)
  }
`);
