import { AuthEventType, AuthState, createUrl } from '@mortgagehippo/auth';
import { Content, Modal, T, useCustomizations, useModal } from '@mortgagehippo/ds';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { auth } from '../../services/auth';

const Timer = styled.div`
  p:last-child {
    margin-bottom: 0;
  }
`;

export const LogoutCountdown = () => {
  const [isOpen, open, close] = useModal(false);
  const inactivityCountdownMs = auth.getInactivityCountdownMs();
  const [counter, setCounter] = useState(
    inactivityCountdownMs ? inactivityCountdownMs / 1000 : -1 // default to -1 and not null or 0, respectively for easier typing and avoiding auto logout (if 0)
  );
  const timeoutIdRef = useRef(0);

  useEffect(() => {
    if (counter === 0) {
      clearInterval(timeoutIdRef.current);
      auth.forceInactivity();
    }
  }, [counter]);

  useEffect(
    () => () => {
      clearInterval(timeoutIdRef.current);
    },
    []
  );

  const startCountdown = useCallback(
    (event: AuthEventType | null, state: AuthState) => {
      if (
        event === AuthEventType.LOGOUT_COUNTDOWN &&
        !isOpen &&
        state === AuthState.AUTHENTICATED_ACCOUNT &&
        inactivityCountdownMs
      ) {
        setCounter(inactivityCountdownMs / 1000);
        clearInterval(timeoutIdRef.current);
        timeoutIdRef.current = window.setInterval(() => {
          setCounter((curCount) => (curCount > 0 ? curCount - 1 : 0));
        }, 1000);
        open();
      }
    },
    [open, inactivityCountdownMs, isOpen]
  );

  useEffect(() => {
    const unsubscribeStartCountdown = auth.subscribe(startCountdown);

    return () => {
      unsubscribeStartCountdown();
    };
  }, [startCountdown]);

  const handleOk = useCallback(() => {
    clearInterval(timeoutIdRef.current);
    close();
  }, [close]);

  const handleLogOut = useCallback(() => {
    clearInterval(timeoutIdRef.current);
    const url = createUrl('/');
    auth.logout(url);
  }, []);

  const customizations = useCustomizations();
  const minLabel = customizations.text('logoutCountdown:timer.min.label', 'min');
  const secLabel = customizations.text('logoutCountdown:timer.sec.label', 'sec');
  const minutes = Math.floor(counter / 60);
  const seconds = counter % 60;
  const formattedTime = `${minutes >= 1 ? `${minutes} ${minLabel} ` : ''}${
    seconds < 10 ? '0' : ''
  }${seconds < 0 ? 0 : seconds} ${secLabel}`;
  const contentContext = { timer: formattedTime };

  return (
    <Modal
      isOpen={isOpen}
      onOk={handleOk}
      secondaryActionButtonLabel={<T cid="logoutCountdown:signOutButton.label">Sign out</T>}
      secondaryActionButtonImportance="tertiary"
      secondaryActionButtonPosition="right"
      onSecondaryActionButtonAction={handleLogOut}
      okButtonLabel={<T cid="logoutCountdown:staySignedInButton.label">Stay signed in</T>}
      hideCancelButton
      disableOverlayClickClose
      cancelable={false}
    >
      <Content cid="logoutCountdown:message.description.content">
        <p>
          Due to inactivity your session is about to expire and you will be automatically signed
          out.
        </p>
      </Content>
      <Timer role="timer" aria-live="polite">
        <Content cid="logoutCountdown:timer.content" context={contentContext}>
          <p>
            Time left: <strong>{contentContext.timer}</strong>
          </p>
        </Content>
      </Timer>
    </Modal>
  );
};
