import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { MEditExternalMilestoneKeyMutation } from './queries';

export const useEditExternalMilestoneKey = () => {
  const editExternalKey = useMutation(MEditExternalMilestoneKeyMutation);

  return useCallback(
    async (id: string, name: string, ignore: boolean) => {
      await editExternalKey(
        {
          id,
          name,
          ignore,
        },
        { fetchPolicy: 'no-cache' }
      );
    },
    [editExternalKey]
  );
};
