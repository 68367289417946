import { Content, Layout } from '../../layouts/main';
import { ServiceJobs } from './service-jobs';

export const ServiceJobManagementPage = () => (
  <Layout pageTitle="Service Jobs">
    <Content title="Service Jobs">
      <ServiceJobs />
    </Content>
  </Layout>
);
