import {
  type CharacterSets,
  CheckboxList,
  type IChoiceOption,
  IconNamesList,
  Input,
  Select,
  Tabs,
  YesNo,
} from '@mortgagehippo/ds';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { FieldEditorFrame } from './field-editor-frame';

export const TextEditor = ({ name }: { name: string }) => {
  const iconOptions = IconNamesList.map((i) => ({
    label: i,
    value: i,
  }));

  const characterSetOptions: IChoiceOption<CharacterSets>[] = [
    { value: 'lowercase', label: 'Lowercase' },
    { value: 'uppercase', label: 'Uppercase' },
    { value: 'numbers', label: 'Numbers' },
    { value: 'spaces', label: 'Spaces' },
    { value: 'symbols', label: 'Symbols' },
  ];

  return (
    <FieldEditorFrame name={name}>
      {() => (
        <Tabs.Tab id="phone" label="Text Options">
          <EditorTabWrapper size="md">
            <Input.Box name={`${name}.placeholder`} label="Placeholder" />
            <Select.Box name={`${name}.icon`} label="Icon" options={iconOptions} />
            <YesNo.Box name={`${name}.iconOutline`} label="Use icon outline" />
            <CheckboxList.Box
              name={`${name}.characterSets`}
              label="Restrict allowed character sets (defaults to any character allowed)"
              options={characterSetOptions}
            />
          </EditorTabWrapper>
        </Tabs.Tab>
      )}
    </FieldEditorFrame>
  );
};
