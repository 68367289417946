import { Input, type ISelectOption, Select } from '@mortgagehippo/ds';

/*
 * These enums/select box represent values of LOS's supported in LOSS
 * It is not necessarily at parity with NextGen's LOS support
 */
enum LOSSSupportedLOSListTypes {
  encompass = 'encompass',
  integra = 'integra',
  mortgage_bot = 'mortgage_bot',
  mlm = 'mlm',
  byte = 'byte',
}

const LOSSActiveLosOptions: ISelectOption[] = [
  {
    label: 'Encompass',
    value: LOSSSupportedLOSListTypes.encompass,
  },
  {
    label: 'Integra',
    value: LOSSSupportedLOSListTypes.integra,
  },
  {
    label: 'Mortgagebot',
    value: LOSSSupportedLOSListTypes.mortgage_bot,
  },
  {
    label: 'MeridianLink',
    value: LOSSSupportedLOSListTypes.mlm,
  },
  {
    label: 'Byte',
    value: LOSSSupportedLOSListTypes.byte,
  },
];

export const LOSSFields = () => (
  <>
    <Input.Box name="guid" label="LOSS Lender Key or Token" required />
    <Select.Box
      name="active_los"
      label="LOS Type matching the LOSS Lender Config"
      options={LOSSActiveLosOptions}
    />
  </>
);
