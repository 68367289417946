import { Input, Numeric, Tabs, YesNo } from '@mortgagehippo/ds';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { FieldEditorFrame } from './field-editor-frame';

export const TextAreaEditor = ({ name }: { name: string }) => (
  <FieldEditorFrame name={name}>
    {() => (
      <Tabs.Tab id="phone" label="Textarea Options">
        <EditorTabWrapper size="md">
          <Input.Box name={`${name}.placeholder`} label="Placeholder" />
          <Input.Box name={`${name}.placeholder`} label="Placeholder" />
          <Numeric.Box name={`${name}.rows`} label="Rows" />
          <YesNo.Box
            name={`${name}.autosize`}
            label="Automatically adjust height based on the content"
          />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
  </FieldEditorFrame>
);
