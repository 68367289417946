import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo/gql';

export const MMoveApplicationStatusUp = graphql(`
  mutation MoveApplicationStatusUp($statusId: ID!) {
    moveApplicationFileStatusUp(statusId: $statusId) {
      ...ApplicationFileStatusFragment
    }
  }
`);

export const useMoveApplicationStatusUp = () => {
  const fn = useMutation(MMoveApplicationStatusUp);

  return useCallback(
    async (statusId: string) => {
      const result = await fn({ statusId });

      return result.data!.moveApplicationFileStatusUp;
    },
    [fn]
  );
};
