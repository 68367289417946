import { useMutation } from '@mortgagehippo/apollo-hooks';
import { Format, notifications } from '@mortgagehippo/ds';
import {
  type IQueryTableColumns,
  type IQueryTableDataActions,
  QueryTable,
} from '@mortgagehippo/query-components';
import { useCallback, useMemo, useRef, useState } from 'react';
import { type RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { VerticalSelect } from '$components/vertical-select';

import {
  type PartnerCustomFieldsListQuery,
  type PartnerCustomFieldsListQueryVariables,
  VerticalType,
} from '../../apollo/graphql';
import { usePartner } from '../../hooks/use-partner';
import { Content, Layout } from '../../layouts/main';
import { CustomFieldModalContainer } from './custom-field-modal-container';
import { MDeleteCustomFieldMutation, QPartnerCustomFieldsList } from './queries';

export type ICustomField = NonNullable<
  PartnerCustomFieldsListQuery['partner']
>['customFields']['items'][0];

const Filters = styled.div`
  width: 33%;
`;

const columns: IQueryTableColumns<ICustomField> = [
  {
    title: 'Name',
    key: 'name',
    render: (t) => t.name,
  },
  {
    title: 'Type',
    key: 'type',
    render: (t) => t.typeName,
  },
  {
    title: 'Last Updated',
    key: 'updated',
    render: ({ createdAt, updatedAt }) => (
      <Format.Date format="fromnow" value={updatedAt || createdAt} />
    ),
  },
];

interface ICustomFieldsPageRouteParams {
  partnerId: string;
}

type ICustomFieldsPageProps = RouteComponentProps<ICustomFieldsPageRouteParams>;

export const CustomFieldsPage = (props: ICustomFieldsPageProps) => {
  const { match } = props;
  const { params } = match;
  const { partnerId } = params;
  const [partner] = usePartner(partnerId);
  const [editing, setEditing] = useState(false);
  const [editingFieldId, setEditingFieldId] = useState<string | undefined>(undefined);
  const [selectedVertical, setSelectedVertical] = useState(VerticalType.Mortgage);
  const ref = useRef<any>();

  const deleteCustomField = useMutation(MDeleteCustomFieldMutation);

  const handleVerticalChange = useCallback((value: VerticalType) => {
    setSelectedVertical(value);
  }, []);

  const handleAdd = useCallback(() => {
    setEditing(true);
    setEditingFieldId(undefined);
  }, []);

  const handleClickRow = useCallback((record: ICustomField) => {
    setEditing(true);
    setEditingFieldId(record.id);
  }, []);

  const handleDelete = useCallback(
    async (record: ICustomField) => {
      try {
        await deleteCustomField({
          vertical: selectedVertical,
          fieldId: record.id,
        });

        notifications.success({ message: 'The custom field was deleted successfully.' });

        ref.current.refetch();
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [deleteCustomField, selectedVertical]
  );

  const handleAction = useCallback(
    async (actionKey: string, record: ICustomField) => {
      switch (actionKey) {
        case 'edit':
          handleClickRow(record);
          break;
        case 'delete':
          await handleDelete(record);
          break;
        default: {
          throw new Error(`Unknown action ${actionKey}`);
        }
      }
    },
    [handleClickRow, handleDelete]
  );

  const handleModalClose = useCallback(() => {
    setEditing(false);
    setEditingFieldId(undefined);
  }, []);

  const handleSubmit = useCallback(() => {
    ref.current.refetch();
    setEditing(false);
    setEditingFieldId(undefined);
  }, []);

  const actions: IQueryTableDataActions = useMemo(
    () => [
      {
        key: 'options',
        label: 'Options',
        buttonProps: {
          icon: 'menu-dots',
        },
        onGroupAction: handleAction,
        actions: [
          {
            key: 'edit',
            label: 'Edit',
            iconProps: {
              name: 'edit',
            },
          },
          {
            key: 'delete',
            label: 'Delete',
            iconProps: {
              name: 'delete',
            },
            confirm: {
              title: 'Warning',
              explanation:
                'Are you sure you want to delete this custom field? This action cannot be undone.',
              type: 'warning',
            },
          },
        ],
      },
    ],
    [handleAction]
  );

  const topActions: IQueryTableDataActions = useMemo(
    () => [
      {
        key: 'add-custom-field',
        label: 'Add custom field',
        buttonProps: {
          icon: 'plus',
        },
        onAction: handleAdd,
      },
    ],
    [handleAdd]
  );

  const partnerName = partner?.name || '';
  // don't need useMemo
  const variables = { partnerId, vertical: selectedVertical };

  const topContent = useMemo(
    () => (
      <Filters>
        <VerticalSelect.Input
          name="vertical"
          value={selectedVertical}
          onChange={handleVerticalChange}
          aria-label="Vertical"
          size="sm"
          compact
        />
      </Filters>
    ),
    [selectedVertical, handleVerticalChange]
  );

  return (
    <Layout pageTitle={`${partnerName} - Custom Fields`}>
      <Content title="Custom Fields" subTitle={partnerName}>
        <QueryTable<PartnerCustomFieldsListQuery, PartnerCustomFieldsListQueryVariables>
          caption="Custom Fields"
          query={QPartnerCustomFieldsList}
          dataSource={(result) => (result.partner ? result.partner.customFields.items : [])}
          itemTotal={(result) => (result.partner ? result.partner.customFields.total : 0)}
          nextCursor={(result) => result.partner?.customFields.nextCursor}
          previousCursor={(result) => result.partner?.customFields.previousCursor}
          rowKey={(item) => item.id}
          columns={columns}
          variables={variables}
          rowActions={actions}
          topActions={topActions}
          topContent={topContent}
          bottomContent={<QueryTable.Pagination />}
          onRowClick={handleClickRow}
          ref={ref}
        >
          <QueryTable.Data />
        </QueryTable>
        {editing ? (
          <CustomFieldModalContainer
            partnerId={partnerId}
            vertical={selectedVertical}
            fieldId={editingFieldId}
            onRequestClose={handleModalClose}
            onSubmit={handleSubmit}
          />
        ) : null}
      </Content>
    </Layout>
  );
};
