import { UnreachableCaseError } from '@mortgagehippo/util';

import { CredentialsType } from '../../apollo/graphql';
import {
  AccountchekFields,
  ByteFields,
  CreditPullFields,
  DataTreeFields,
  DataVerifyFields,
  DesktopUnderwriterFields,
  DocusignFields,
  DocutechFields,
  EmailProviderFields,
  EncompassApiFields,
  ExternalSftpFields,
  FinicityFields,
  HelloSignFields,
  IDSFields,
  InternalSystemFields,
  LendingQbFields,
  LOSSFields,
  LpaFields,
  MortgageBotFields,
  MortgageDirectorFields,
  MortgageFlexFields,
  MortgageInsuranceFields,
  PricingFields,
  SalesforceFields,
  SmartFeesFields,
  SnapdocsFields,
  TotalExpertFields,
  TransnationalFields,
  TrueworkFields,
  VelocifyFields,
  WorkNumberFields,
} from './fields';

interface ICredentialsEditorFieldsProps {
  credentialsType: CredentialsType;
}

export const CredentialsEditorFields = (props: ICredentialsEditorFieldsProps) => {
  const { credentialsType } = props;

  switch (credentialsType) {
    case CredentialsType.AccountChek:
      return <AccountchekFields />;
    case CredentialsType.Byte:
      return <ByteFields />;
    case CredentialsType.CreditPull:
      return <CreditPullFields />;
    case CredentialsType.DataTree:
      return <DataTreeFields />;
    case CredentialsType.DataVerify:
      return <DataVerifyFields />;
    case CredentialsType.DesktopUnderwriter:
      return <DesktopUnderwriterFields />;
    case CredentialsType.EncompassApi:
      return <EncompassApiFields />;
    case CredentialsType.HelloSign:
      return <HelloSignFields />;
    case CredentialsType.Pricing:
      return <PricingFields />;
    case CredentialsType.WorkNumber:
      return <WorkNumberFields />;
    case CredentialsType.LoanProductAdvisor:
      return <LpaFields />;
    case CredentialsType.MortgageBot:
      return <MortgageBotFields />;
    case CredentialsType.MortgageFlex:
      return <MortgageFlexFields />;
    case CredentialsType.MortgageDirector:
      return <MortgageDirectorFields />;
    case CredentialsType.MortgageInsurance:
      return <MortgageInsuranceFields />;
    case CredentialsType.IDS:
      return <IDSFields />;
    case CredentialsType.InternalSystem:
      return <InternalSystemFields />;
    case CredentialsType.LendingQb:
      return <LendingQbFields />;
    case CredentialsType.EmailProvider:
      return <EmailProviderFields />;
    case CredentialsType.Finicity:
      return <FinicityFields />;
    case CredentialsType.Transnational:
      return <TransnationalFields />;
    case CredentialsType.Docutech:
      return <DocutechFields />;
    case CredentialsType.Salesforce:
      return <SalesforceFields />;
    case CredentialsType.SmartFees:
      return <SmartFeesFields />;
    case CredentialsType.ExternalSFTP:
      return <ExternalSftpFields />;
    case CredentialsType.Docusign:
      return <DocusignFields />;
    case CredentialsType.TotalExpert:
      return <TotalExpertFields />;
    case CredentialsType.Truework:
      return <TrueworkFields />;
    case CredentialsType.Velocify:
      return <VelocifyFields />;
    case CredentialsType.Snapdocs:
      return <SnapdocsFields />;
    case CredentialsType.LOSS:
      return <LOSSFields />;
    default:
      throw new UnreachableCaseError(credentialsType);
  }
};
