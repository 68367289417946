import { type FormSubmitHandler } from '@mortgagehippo/ds';
import { useMemo } from 'react';

import { usePartner } from '../../../hooks/use-partner';
import { EditPartnerModal } from './edit-partner-modal';

interface IEditPartnerModalContainerProps {
  partnerId: string;
  onRequestClose: () => void;
  onSubmit: FormSubmitHandler;
  isOpen: boolean;
}

export const EditPartnerModalContainer = (props: IEditPartnerModalContainerProps) => {
  const { partnerId, onRequestClose, onSubmit, isOpen } = props;
  const [partnerData, partnerDataLoading] = usePartner(partnerId, false, 'network-only');

  const initialValues = useMemo(() => {
    if (!partnerData) {
      return {};
    }

    const { name, adminDomain, isBillable, adminInactivityTimeout, timezone } = partnerData;

    return {
      name,
      admin_domain: adminDomain,
      is_billable: isBillable,
      admin_inactivity_timeout: adminInactivityTimeout,
      timezone,
    };
  }, [partnerData]);

  return (
    <EditPartnerModal
      loading={partnerDataLoading}
      onSubmit={onSubmit}
      onRequestClose={onRequestClose}
      initialValues={initialValues}
      isOpen={isOpen}
    />
  );
};
