import { type RouteComponentProps } from 'react-router';

import { usePartner } from '../../../hooks/use-partner';
import { Content, Layout } from '../../../layouts/main';
import { LenderMilestonesPageContent } from './lender-milestones-page-content';

interface ILenderMilestonesPageRouteParams {
  partnerId: string;
}

type ILenderMilestonesPageProps = RouteComponentProps<ILenderMilestonesPageRouteParams>;

export const LenderMilestonesPage = (props: ILenderMilestonesPageProps) => {
  const { match } = props;
  const { params } = match;
  const { partnerId } = params;
  const [partner, loading] = usePartner(partnerId);

  const partnerName = partner?.name || '';

  return (
    <Layout pageTitle={`${partnerName} - Milestones`}>
      <Content title="Milestones" subTitle={partnerName}>
        <LenderMilestonesPageContent partnerId={partnerId} loading={loading} />
      </Content>
    </Layout>
  );
};
