import { PreQualificationLetter } from '../../../components/pre-qualification-letter';

interface IPartnerPreQualificationLetterPageContentProps {
  partnerId: string;
}

export const PartnerPreQualificationLetterPageContent = (
  props: IPartnerPreQualificationLetterPageContentProps
) => {
  const { partnerId } = props;

  return <PreQualificationLetter partnerId={partnerId} />;
};
