import { Email, Input, type ISelectOption, Select } from '@mortgagehippo/ds';

enum EmailProviderName {
  mailgun = 'mailgun',
}

const providerOptions: ISelectOption[] = [
  {
    label: 'Mailgun',
    value: EmailProviderName.mailgun,
  },
];

export const EmailProviderFields = () => (
  <>
    <Select.Box name="provider_name" label="Provider Name" options={providerOptions} required />
    <Input.Box name="access_info.smtp_user_name" label="SMTP User Name" required />
    <Input.Box name="access_info.smtp_password" label="SMTP Password" required />
    <Email.Box
      name="access_info.default_from_address"
      label="Default Email Address to Send From (domain must match SMTP credentials)"
      required
    />
    <Input.Box
      name="access_info.default_from_display_name"
      label="Default Display Name to Send From"
      required
    />
  </>
);
