import { type IInformationTask, InformationTask } from '@mortgagehippo/tasks';
import { type ReactNode } from 'react';

import { TaskPreviewWrapper } from '../task-preview-wrapper';

export interface IInformationTaskPreviewProps {
  task: IInformationTask;
  onComplete?: () => Promise<any>;
  renderComplete?: () => ReactNode;
}

export const InformationTaskPreview = (props: IInformationTaskPreviewProps) => {
  const { task, renderComplete, onComplete } = props;

  return (
    <TaskPreviewWrapper task={task}>
      {({ nextTask, frame }) => (
        <InformationTask
          task={nextTask as IInformationTask}
          onComplete={onComplete}
          frame={frame}
          renderComplete={renderComplete}
        />
      )}
    </TaskPreviewWrapper>
  );
};
