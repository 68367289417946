import { Button } from '@mortgagehippo/ds';
import { useState } from 'react';

import { AnswersDocumentationModalContainer } from './answers-documentation-modal-container';

export const AnswersDocumentation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        type="neutral"
        icon="information"
        importance="tertiary"
        onClick={handleOpen}
        size="normal"
        iconButton
        iconButtonRound
        iconButtonHumble
      />
      <AnswersDocumentationModalContainer onRequestClose={handleClose} isOpen={isOpen} />
    </>
  );
};
