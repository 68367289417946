import { AuthService } from '@mortgagehippo/auth';

import { config } from '../config';

export const auth = new AuthService({
  loginPath: '/',
  callbackPath: '/authorize',
  afterLoginPath: '/partners',
  afterLogoutUrl: '/',
  afterInactivityTimeoutPath: '/session-timeout',
  afterForcedLogoutPath: '/forced-logout',
  endpoint: config.AUTHENTICATE_ENDPOINT,
  defaultInactivityMs: config.DEFAULT_INACTIVITY_TIMEOUT,
  inactivityCountdownMs: config.INACTIVITY_COUNTDOWN,
});
