import { Button, shadow, spacing, SubmitButton, Tag } from '@mortgagehippo/ds';
import { isPromise } from '@mortgagehippo/util';
import { useCallback, useState } from 'react';
import { useSize } from 'react-use';
import styled from 'styled-components';

const Container = styled.div`
  padding-bottom: 60px;
`;

const ActionsBar = styled.div<{ calculatedWidth: number }>`
  box-sizing: content-box;
  position: fixed;
  z-index: 5;
  bottom: 0;
  width: ${(p) => p.calculatedWidth}px;
  text-align: right;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: ${shadow(2)};
  /* TODO: the padding is based on the layout's content padding, find a better way */
  padding: ${spacing(3)} ${spacing(6)};
  margin: 0 -${spacing(6)};
  backdrop-filter: blur(7px);
`;

interface IFixedSubmitBarProps {
  submitText?: string;
  onSubmit?: (() => Promise<any>) | (() => void);
  disabled?: boolean;
  unsavedChanges?: number;
}

const buttonProps = {
  size: 'sm' as const,
  compact: true,
  importance: 'primary' as const,
};

export const FixedSubmitBar = (props: IFixedSubmitBarProps) => {
  const { submitText = 'Save', onSubmit, disabled, unsavedChanges } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = useCallback(async () => {
    if (onSubmit) {
      const result = onSubmit();

      if (isPromise(result)) {
        setLoading(true);

        await result;

        setLoading(false);
      }
    }
  }, [onSubmit]);

  const [actionsBarEl] = useSize(({ width }) => (
    <Container>
      <ActionsBar calculatedWidth={width}>
        {!!unsavedChanges && (
          <Tag color="danger" inverted>
            {`${unsavedChanges} unsaved change${unsavedChanges !== 1 ? 's' : ''}`}
          </Tag>
        )}{' '}
        {onSubmit ? (
          <Button {...buttonProps} onClick={handleSubmit} loading={loading} disabled={disabled}>
            {submitText}
          </Button>
        ) : null}
        {!onSubmit && (
          <SubmitButton {...buttonProps} disabled={disabled}>
            {submitText}
          </SubmitButton>
        )}
      </ActionsBar>
    </Container>
  ));

  return actionsBarEl;
};
