import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { MDeleteExternalMilestoneKeyMutation } from './queries';

export const useDeleteExternalMilestoneKey = () => {
  const deleteExternalKey = useMutation(MDeleteExternalMilestoneKeyMutation);

  return useCallback(
    async (id: string) => {
      await deleteExternalKey(
        {
          id,
        },
        { fetchPolicy: 'no-cache' }
      );
    },
    [deleteExternalKey]
  );
};
