import { formatDateTime, notifications, useModal } from '@mortgagehippo/ds';
import { useCallback } from 'react';

import { type IActionProps, type ICreateServiceJobAction } from '../types';
import { CreateServiceJobWizard } from './create-service-job-wizard';
import { useScheduleRepeatableServiceJob } from './use-schedule-repeatable-service-job';
import { useScheduleServiceJob } from './use-schedule-service-job';

export const CreateServiceJobAction = (props: IActionProps<ICreateServiceJobAction>) => {
  const {
    onDone,
    onSubmit,
    action: { repeatableJob },
  } = props;

  const scheduleServiceJob = useScheduleServiceJob();
  const scheduleRepeatableServiceJob = useScheduleRepeatableServiceJob();

  const [isOpen, , closeModal] = useModal(true);

  const handleSubmit = useCallback(
    async (values: any) => {
      const {
        jobType,
        startDate: rawStartDate,
        startTime,
        cronSchedule,
        partner,
        model,
        repeatMinutesInterval,
      } = values;

      let startDate: Date | undefined;
      if (rawStartDate && startTime) {
        const [hours, minutes] = startTime.split(':');
        startDate = new Date(formatDateTime(rawStartDate, 'date-short-zeros').formattedValue);
        startDate.setHours(hours, minutes, 0, 0);
      }

      const submitValues = {
        partnerId: partner,
        jobType,
        modelType: model,
        startDate: startDate?.toISOString() || undefined,
      };

      onSubmit();

      try {
        if (repeatableJob) {
          await scheduleRepeatableServiceJob({
            ...submitValues,
            cronSchedule,
            repeatMillisInterval: repeatMinutesInterval
              ? repeatMinutesInterval * 60 * 1000
              : undefined,
          });
        } else {
          await scheduleServiceJob(submitValues);
        }

        closeModal(() => {
          onDone();
        });
      } catch (e: unknown) {
        notifications.error({ message: 'Error scheduling job!' });
      }
    },
    [repeatableJob, onSubmit, closeModal, scheduleRepeatableServiceJob, scheduleServiceJob, onDone]
  );

  const handleCloseModal = useCallback(() => {
    closeModal(onDone);
  }, [closeModal, onDone]);

  return (
    <CreateServiceJobWizard
      onSubmit={handleSubmit}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
      repeatableJob={repeatableJob}
    />
  );
};
