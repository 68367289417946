import { graphql } from '../../apollo/gql';

export const FCredentialFragment = graphql(`
  fragment CredentialFragment on Credential {
    typeName
    partnerId
    siteId
    inherited
    overwrittenBy {
      id
      name
    }
    updatedAt
    data
  }
`);

export const QPartnerCredentials = graphql(`
  query PartnerCredentials($partnerId: ID!) {
    partner(id: $partnerId) {
      id
      credentials {
        ...CredentialFragment
      }
    }
  }
`);

export const QPartnerCredential = graphql(`
  query PartnerCredential($partnerId: ID!, $credentialType: CredentialsType!) {
    partner(id: $partnerId) {
      id
      credential(credentialType: $credentialType) {
        ...CredentialFragment
      }
    }
  }
`);

export const QSiteCredentials = graphql(`
  query SiteCredentials($siteId: ID!) {
    site(id: $siteId) {
      id
      credentials {
        ...CredentialFragment
      }
    }
  }
`);

export const QSiteCredential = graphql(`
  query SiteCredential($siteId: ID!, $credentialType: CredentialsType!) {
    site(id: $siteId) {
      id
      credential(credentialType: $credentialType) {
        ...CredentialFragment
      }
    }
  }
`);

export const MSaveCredentialMutation = graphql(`
  mutation SaveCredential(
    $partnerId: ID!
    $siteId: ID
    $credentialType: CredentialsType!
    $data: JSON!
  ) {
    saveCredential(
      partnerId: $partnerId
      siteId: $siteId
      credentialType: $credentialType
      data: $data
    )
  }
`);

export const MDeleteCredentialMutation = graphql(`
  mutation DeleteCredential($partnerId: ID!, $siteId: ID, $credentialType: CredentialsType!) {
    deleteCredential(partnerId: $partnerId, siteId: $siteId, credentialType: $credentialType)
  }
`);

// Encompass Webhooks (should these go in a separate file?)
export const FEncompassWebhookFragment = graphql(`
  fragment EncompassWebhookFragment on EncompassWebhook {
    subscriptionId
    endpoint
    signingkey
    filters
    events
    resource
  }
`);

export const QPartnerEncompassWebhooks = graphql(`
  query PartnerEncompassWebhooks($partnerId: ID!) {
    partner(id: $partnerId) {
      id
      encompassWebhooks {
        ...EncompassWebhookFragment
      }
    }
  }
`);

export const QSiteEncompassWebhooks = graphql(`
  query SitePartnerWebhooks($siteId: ID!) {
    site(id: $siteId) {
      id
      encompassWebhooks {
        ...EncompassWebhookFragment
      }
    }
  }
`);

export const FSnapdocsSubscriptionFragment = graphql(`
  fragment SnapdocsSubscriptionFragment on SnapdocsSubscription {
    id
    description
    webhookUrl
    events
  }
`);

export const QPartnerSnapdocsSubscriptions = graphql(`
  query PartnerSnapdocsSubscriptions($partnerId: ID!) {
    partner(id: $partnerId) {
      id
      snapdocsSubscriptions {
        ...SnapdocsSubscriptionFragment
      }
    }
  }
`);

export const QSiteSnapdocsSubscriptions = graphql(`
  query SiteSnapdocsSubscriptions($siteId: ID!) {
    site(id: $siteId) {
      id
      snapdocsSubscriptions {
        ...SnapdocsSubscriptionFragment
      }
    }
  }
`);

export const FTotalExpertCustomFieldsFragment = graphql(`
  fragment TotalExpertCustomFieldsFragment on TotalExpertCustomFieldResult {
    items {
      id
      systemName
      dataType
      relatedEntity {
        type
        subtype
      }
      displayName
    }
    itemsCount
    nextPage
    pageCount
  }
`);

export const QPartnerTotalExpertCustomFields = graphql(`
  query PartnerTotalExpertCustomFields($partnerId: ID!, $page: Int!, $perPage: Int!) {
    partner(id: $partnerId) {
      totalExpertCustomFields(options: { page: $page, perPage: $perPage }) {
        ...TotalExpertCustomFieldsFragment
      }
    }
  }
`);

export const QSiteTotalExpertCustomFields = graphql(`
  query SiteTotalExpertCustomFields($siteId: ID!, $page: Int!, $perPage: Int!) {
    site(id: $siteId) {
      totalExpertCustomFields(options: { page: $page, perPage: $perPage }) {
        ...TotalExpertCustomFieldsFragment
      }
    }
  }
`);

export const MCreateEncompassWebhookMutation = graphql(`
  mutation CreateEncompassWebhook(
    $parentType: ParentType!
    $parentId: ID!
    $endpoint: String!
    $resource: String!
    $events: JSON!
    $filters: JSON
  ) {
    createEncompassWebhook(
      parentType: $parentType
      parentId: $parentId
      endpoint: $endpoint
      resource: $resource
      events: $events
      filters: $filters
    ) {
      statusCode
      statusDescription
    }
  }
`);

export const MDeleteEncompassWebhookMutation = graphql(`
  mutation DeleteEncompassWebhook($parentType: ParentType!, $parentId: ID!, $id: ID!) {
    deleteEncompassWebhook(parentType: $parentType, parentId: $parentId, id: $id) {
      statusCode
      statusDescription
    }
  }
`);

export const MCreateSnapdocsSubscriptionMutation = graphql(`
  mutation CreateSnapdocsSubscription($data: CreateSnapdocsSubscriptionInput!) {
    snapdocs {
      createSubscription(data: $data)
    }
  }
`);

export const MDeleteSnapdocsSubscriptionMutation = graphql(`
  mutation DeletenapdocsSubscription($id: ID!, $pathSlug: String!, $partnerId: ID!, $siteId: ID) {
    snapdocs {
      deleteSubscription(id: $id, pathSlug: $pathSlug, partnerId: $partnerId, siteId: $siteId)
    }
  }
`);
