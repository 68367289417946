import { type RouteComponentProps } from 'react-router';

import { PartnerDocumentsTable } from '$components/partner-documents';

import { usePartner } from '../../../hooks/use-partner';
import { Content, Layout } from '../../../layouts/main';

interface IPartnerDocumentsPageRouteParams {
  partnerId: string;
}

export const PartnerDocumentsPage = (
  props: RouteComponentProps<IPartnerDocumentsPageRouteParams>
) => {
  const { match } = props;
  const { params } = match;
  const { partnerId } = params;

  const [partner] = usePartner(partnerId);
  const partnerName = partner?.name || '';

  return (
    <Layout pageTitle={`${partnerName} - Documents`}>
      <Content title="Documents" subTitle={partnerName}>
        <PartnerDocumentsTable partnerId={partnerId} />
      </Content>
    </Layout>
  );
};
