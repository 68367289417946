import { type UploadHandler, UploadInput } from '@mortgagehippo/ds';
import { ACCEPTED_DOCUMENT_MIME_TYPES } from '@mortgagehippo/tasks';

interface ITemplatesFormSendDocumentTaskFieldsProps {
  onUploadDocument: UploadHandler;
}

export const TemplatesFormSendDocumentTaskFields = (
  props: ITemplatesFormSendDocumentTaskFieldsProps
) => {
  const { onUploadDocument } = props;

  return (
    <UploadInput.Box
      name="data.documents"
      label="Documents"
      onUpload={onUploadDocument}
      accept={ACCEPTED_DOCUMENT_MIME_TYPES}
      description="A partner level document will be created from the uploaded file immediately"
      fileTypeDescription="document"
    />
  );
};
