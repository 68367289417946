import { Tabs, useFormValue } from '@mortgagehippo/ds';
import { FieldType } from '@mortgagehippo/tasks';
import { UnreachableCaseError } from '@mortgagehippo/util';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { APREditor } from './apr-editor';
import { ArrayEditor } from './array-editor';
import { BorrowAmountEditor } from './borow-amount-editor';
import { CheckboxEditor } from './checkbox-editor';
import { ChoiceEditor } from './choice-editor';
import { ConsentJornayaEditor } from './consent-jornaya-editor';
import { ConstantEditor } from './constant-editor';
import { ContentEditor } from './content-editor';
import { CurrencyEditor } from './currency-editor';
import { DateEditor } from './date-editor';
import { DatePickerEditor } from './date-picker-editor';
import { DividerEditor } from './divider-editor';
import { DurationDateEditor } from './duration-date-editor';
import { DurationEditor } from './duration-editor';
import { EntityEditor } from './enity-editor';
import { FieldEditorDetails } from './field-editor-details';
import { IncomeEditor } from './income-editor';
import { MonthPickerEditor } from './month-picker-editor';
import { NumericEditor } from './numeric-editor';
import { PhoneEditor } from './phone-editor';
import { PropertyValueEditor } from './property-value-editor';
import { SelectEditor } from './select-editor';
import { SSNEditor } from './ssn-editor';
import { SwitchArrayEditor } from './switch-array-editor';
import { TextAreaEditor } from './text-area-editor';
import { TextEditor } from './text-editor';
import { USAddressEditor } from './us-address-editor';
import { USStateEditor } from './us-state-editor';
import { USZipCodeEditor } from './us-zip-code-editor';
import { YearEditor } from './year-editor';
import { YesNoEditor } from './yes-no-editor';

export const FieldEditor = ({ name }: { name: string }) => {
  const [typeName] = useFormValue<FieldType>(`${name}.type_name`);

  switch (typeName) {
    case FieldType.BOOLEAN: {
      return <YesNoEditor name={name} />;
    }
    case FieldType.BORROW_AMOUNT: {
      return <BorrowAmountEditor name={name} />;
    }
    case FieldType.CHOICE: {
      return <ChoiceEditor name={name} />;
    }
    case FieldType.SWITCH_ARRAY: {
      return <SwitchArrayEditor name={name} />;
    }
    case FieldType.ARRAY: {
      return <ArrayEditor name={name} />;
    }
    case FieldType.TEXT:
    case FieldType.EMAIL: {
      return <TextEditor name={name} />;
    }
    case FieldType.SELECT: {
      return <SelectEditor name={name} choices />;
    }
    case FieldType.COUNTRY: {
      return <SelectEditor name={name} choices={false} />;
    }
    case FieldType.DATE: {
      return <DateEditor name={name} />;
    }
    case FieldType.DATE_PICKER: {
      return <DatePickerEditor name={name} />;
    }
    case FieldType.CONSENT_JORNAYA: {
      return <ConsentJornayaEditor name={name} />;
    }
    case FieldType.CONSTANT: {
      return <ConstantEditor name={name} />;
    }
    case FieldType.CONTENT: {
      return <ContentEditor name={name} />;
    }
    case FieldType.DIVIDER: {
      return <DividerEditor name={name} />;
    }
    case FieldType.DURATION: {
      return <DurationEditor name={name} />;
    }
    case FieldType.DURATION_DATE: {
      return <DurationDateEditor name={name} />;
    }
    case FieldType.TEXTAREA: {
      return <TextAreaEditor name={name} />;
    }
    case FieldType.NUMERIC: {
      return <NumericEditor name={name} />;
    }
    case FieldType.PHONE: {
      return <PhoneEditor name={name} />;
    }
    case FieldType.PROPERTY_VALUE: {
      return <PropertyValueEditor name={name} />;
    }
    case FieldType.SSN: {
      return <SSNEditor name={name} />;
    }
    case FieldType.CURRENCY: {
      return <CurrencyEditor name={name} />;
    }
    case FieldType.APR: {
      return <APREditor name={name} />;
    }
    case FieldType.MONTH: {
      return <MonthPickerEditor name={name} />;
    }
    case FieldType.CHECKBOX: {
      return <CheckboxEditor name={name} />;
    }
    case FieldType.ENTITY: {
      return <EntityEditor name={name} />;
    }
    case FieldType.USADDRESS: {
      return <USAddressEditor name={name} />;
    }
    case FieldType.USSTATE: {
      return <USStateEditor name={name} />;
    }
    case FieldType.USZIPCODE: {
      return <USZipCodeEditor name={name} />;
    }
    case FieldType.INCOME: {
      return <IncomeEditor name={name} />;
    }
    case FieldType.YEAR: {
      return <YearEditor name={name} />;
    }
    case undefined: {
      return (
        <Tabs>
          <Tabs.Tab id="details" label="Details">
            <EditorTabWrapper size="md">
              <FieldEditorDetails name={name} />
            </EditorTabWrapper>
          </Tabs.Tab>
        </Tabs>
      );
    }
    default: {
      throw new UnreachableCaseError(typeName);
    }
  }
};
