import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { type UpdateLenderMilestoneInput, type VerticalType } from '../../../apollo/graphql';
import { MUpdateLenderMilestonesMutation } from './queries';

export const useUpdateLenderMilestones = () => {
  const updateLenderMilestones = useMutation(MUpdateLenderMilestonesMutation, undefined);

  return useCallback(
    async (partnerId: string, vertical: VerticalType, data: UpdateLenderMilestoneInput) => {
      const response = await updateLenderMilestones({
        partnerId,
        vertical,
        data,
      });

      return response.data!.updateLenderMilestones;
    },
    [updateLenderMilestones]
  );
};
