import {
  borderRadius,
  FieldLabel,
  type IThemeProp,
  shadow,
  spacing,
  useFormValue,
} from '@mortgagehippo/ds';
import { get } from 'lodash-es';
import styled, { withTheme } from 'styled-components';

import { Hex } from '../fields/hex';
import { type IBasicThemeResourceEditorProps, ThemeResourceEditor } from './theme-resource-editor';

const StyledLabel = styled(FieldLabel)`
  margin-left: 50px;
  padding-left: ${spacing(2)};
`;

const Row = styled.div`
  display: flex;
  align-items: start;
`;

const Preview = styled.div`
  flex: 0 0 50px;
  height: 50px;
  line-height: 50px;
  min-width: 50px;
  margin-right: ${spacing(1)};
  border-radius: ${borderRadius(1)};
  box-shadow: ${shadow(1)};
  background-color: ${({ color }) => color};
  text-align: center;
`;

const Editor = styled.div`
  flex: 1;
`;

type IBasicColorResourceEditorProps = Omit<IBasicThemeResourceEditorProps, 'children'>;

type IColorResourceEditorProps = IThemeProp<IBasicColorResourceEditorProps>;

export const ColorResourceEditor = withTheme((props: IColorResourceEditorProps) => {
  const { name, storedValue, label, theme, ...rest } = props;

  const [formValue] = useFormValue(name);
  const defaultValue = theme ? get(theme, name) : undefined;
  const displayValue = formValue || storedValue || defaultValue;

  let labelEl;
  let labelId;

  if (label) {
    labelId = `label_${name.replace('.', '_')}`;
    labelEl = <StyledLabel id={labelId}>{label}</StyledLabel>;
  }

  return (
    <div>
      {labelEl}
      <Row>
        <Preview color={displayValue || 'transparent'} />
        <Editor>
          <ThemeResourceEditor name={name} storedValue={storedValue} {...rest}>
            <Hex name={name} aria-labelledby={labelId} />
          </ThemeResourceEditor>
        </Editor>
      </Row>
    </div>
  );
});
