import { Numeric, Percent, Tabs } from '@mortgagehippo/ds';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { FieldEditorFrame } from './field-editor-frame';

export const BorrowAmountEditor = ({ name }: { name: string }) => (
  <FieldEditorFrame name={name}>
    {() => [
      <Tabs.Tab id="settings" label="Settings">
        <EditorTabWrapper size="md">
          <Numeric.Box
            name={`${name}.min_borrow_amount`}
            label="Min Borrow Amount"
            description="Don't allow borrowing less than this amount"
          />
          <Percent.Box
            name={`${name}.default_borrow_amount_percent`}
            label="Default Borrow Amount Percent"
            description="Automatically set slider to this percentage of the max available to borrow amount (default is 50%)"
          />
          <Numeric.Box
            name={`${name}.limit_results`}
            label="Maximum number of results to show"
            description="Setting this value will restrict results to the N lowest monthly payments, if not specified all matching results will be shown"
          />
          <Numeric.Box
            name={`${name}.slider_step`}
            label="Slider Step"
            description="The amount to increment the slider by (default is $1000)"
          />
        </EditorTabWrapper>
      </Tabs.Tab>,
    ]}
  </FieldEditorFrame>
);
