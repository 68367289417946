import { type IUseApolloQueryOptions, useQuery } from '@mortgagehippo/apollo-hooks';
import { type IChoiceOption } from '@mortgagehippo/ds';

import { graphql } from '../apollo/gql';

const QPartnerSites = graphql(`
  query PartnerSites($partnerId: ID!) {
    partner(id: $partnerId) {
      id
      sites(options: { orderBy: { name: "asc" } }) {
        items {
          value: id
          label: name
        }
      }
    }
  }
`);

export const useSites = (partnerId: string | undefined, queryOptions?: IUseApolloQueryOptions): [IChoiceOption[] | undefined, boolean] => {
  const [data, loading] = useQuery(
    QPartnerSites,
    {
      partnerId: partnerId || '',
    },
    queryOptions,
  );

  const sites = data?.partner?.sites.items;
  return [sites, loading] as const;
};