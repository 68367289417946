import { useMutation } from '@mortgagehippo/apollo-hooks';
import { Col, notifications, Numeric, Row, spacing, Title } from '@mortgagehippo/ds';
import { set, upperFirst } from 'lodash-es';
import { type ReactElement, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { type SetCustomizationValueInput } from '../../../../apollo/graphql';
import { TabForm } from '../../components/tab-form';
import { useCustomizationEditorResources } from '../../hooks/use-customization-editor-resources';
import { MDeleteCustomizationValue, MSetCustomizationValues } from '../../queries';
import { type ICustomizationsEditorTabProps } from '../../types';
import { getSourceLabel, traverse } from '../../util';
import { PALETTE_GROUPS, PALETTE_KEY_PREFIX, PALETTE_LEVELS } from './constants';
import { ColorResourceEditor, PaletteResourceEditor, ThemeResourceEditor } from './editors';
import { PaletteImport } from './palette-import';

const PALETTE_COLUMNS = 3;

const Section = styled.div`
  margin-bottom: ${spacing(8)};
`;

const PaletteCol = styled(Col)`
  && {
    padding: 0 ${spacing(5)} 0 ${spacing(2)} !important;
  }
`;

const PaletteTitleRow = styled(Row)`
  && {
    margin-bottom: ${spacing(5)} 0 ${spacing(3)} !important;
  }
`;

const StyledPaletteTitle = styled(Title)`
  display: flex;
  align-items: center;
`;

export const ThemeTab = (props: ICustomizationsEditorTabProps) => {
  const { projectId, partnerId, domain, language } = props;
  const [toggledWidgets, setToggledWidgets] = useState<string[]>([]);

  const setCustomizationValues = useMutation(MSetCustomizationValues);

  const deleteCustomizationValue = useMutation(MDeleteCustomizationValue);

  const [customizationsDataPalette, customizationsDataLoadingPalette, refetchPalette] =
    useCustomizationEditorResources({
      projectId,
      partnerId,
      domain,
      type: 'theme',
      namespace: 'default',
      key: 'palette*',
      language,
      overriddenOnly: false,
      notifyOnNetworkStatusChange: false,
    });

  const [
    customizationsDataPageBackground,
    customizationsDataLoadingPageBackground,
    refetchPageBackground,
  ] = useCustomizationEditorResources({
    projectId,
    partnerId,
    domain,
    type: 'theme',
    namespace: 'default',
    key: 'pageBackground*',
    language,
    overriddenOnly: false,
    notifyOnNetworkStatusChange: false,
  });

  const customizationsData = useMemo(
    () => [...(customizationsDataPalette || []), ...(customizationsDataPageBackground || [])],
    [customizationsDataPageBackground, customizationsDataPalette]
  );

  const customizationsDataLoading =
    customizationsDataLoadingPalette && customizationsDataLoadingPageBackground;

  const handleToggleWidget = useCallback(
    (name: string) => {
      setToggledWidgets([...toggledWidgets, name]);
    },
    [toggledWidgets]
  );

  const handleUnToggleWidget = useCallback(
    (name: string) => {
      setToggledWidgets(toggledWidgets.filter((f) => f !== name));
    },
    [toggledWidgets]
  );

  const handleSubmit = useCallback(
    async (values: any): Promise<boolean> => {
      try {
        const submitData: SetCustomizationValueInput[] = [];

        traverse(values, (value, key) => {
          const targetResource = customizationsData.find((r) => r.key === key);

          if (targetResource) {
            submitData.push({
              resourceId: targetResource.id,
              partnerId,
              domain,
              language,
              value,
            });
          }
        });

        if (submitData.length > 0) {
          await setCustomizationValues({ data: submitData });

          await refetchPalette();
          await refetchPageBackground();
        }

        setToggledWidgets([]);
        notifications.success({ message: 'Your changes have been saved.' });

        return true;
      } catch (e) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });

        return false;
      }
    },
    [
      customizationsData,
      domain,
      language,
      partnerId,
      refetchPalette,
      refetchPageBackground,
      setCustomizationValues,
    ]
  );

  const handleFormSubmit = async (values: any) => handleSubmit(values);

  const handleDelete = useCallback(
    async (name: string) => {
      try {
        const targetResource = customizationsData.find((r) => r.key === name);

        if (targetResource?.value) {
          await deleteCustomizationValue({ id: targetResource.value.id });

          await refetchPalette();
          await refetchPageBackground();
        }
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [customizationsData, deleteCustomizationValue, refetchPalette, refetchPageBackground]
  );

  const validateAngle = useCallback((value: any) => {
    if (value < -360 || value > 360) {
      return 'Invalid value.';
    }

    return undefined;
  }, []);

  const getBasicEditorProps = (name: string) => {
    const targetResource = customizationsData.find((r) => r.key === name);
    const isOverridden = targetResource ? targetResource.isOverridden : false;

    const resourceValue = targetResource?.value ? targetResource.value.value : null;

    const editing = isOverridden || toggledWidgets.includes(name);

    const sourceLabel = targetResource ? getSourceLabel(targetResource) : undefined;

    return {
      name,
      editing,
      storedValue: resourceValue,
      source: sourceLabel,
      showDeleteButton: isOverridden,
      onToggleEditing: handleToggleWidget,
      onCancelEditing: handleUnToggleWidget,
      onDelete: handleDelete,
    };
  };

  const renderPaletteFields = () => {
    const columns = PALETTE_COLUMNS;
    const rows = Math.ceil(PALETTE_GROUPS.length / columns);
    const columnSpan = Math.floor(24 / columns);
    const el: ReactElement[] = [];
    let count = 0;

    while (count < rows) {
      const startIndex = count * columns;
      const endIndex = startIndex + columns;
      const groups = PALETTE_GROUPS.slice(startIndex, endIndex);

      const headerRow: ReactElement = (
        <PaletteTitleRow>
          {groups.map((group) => (
            <Col xs={columnSpan} key={group}>
              <StyledPaletteTitle level={3}>
                <span>{upperFirst(group)}</span>
                <PaletteImport group={group} onSubmit={handleSubmit} />
              </StyledPaletteTitle>
            </Col>
          ))}
        </PaletteTitleRow>
      );

      const colorRows: ReactElement[] = PALETTE_LEVELS.map((level) => (
        <Row key={level}>
          {groups.map((group) => {
            const name = `${PALETTE_KEY_PREFIX}${group}${level}`;

            return (
              <PaletteCol xs={columnSpan} key={group}>
                <PaletteResourceEditor {...getBasicEditorProps(name)} group={group} level={level} />
              </PaletteCol>
            );
          })}
        </Row>
      ));

      el.push(
        <div key={count}>
          {headerRow}
          {colorRows}
        </div>
      );

      count += 1;
    }

    return <div>{el}</div>;
  };

  const initialValues = useMemo(() => {
    const values: { [key: string]: string | undefined } = {};

    customizationsData.forEach((resource) => {
      if (resource.value && resource.isOverridden) {
        set(values, resource.key, resource.value.value);
      }
    });

    return values;
  }, [customizationsData]);

  if (customizationsDataLoading) {
    return null;
  }

  return (
    <TabForm initialValues={initialValues} onSubmit={handleFormSubmit}>
      <Section>
        <Title level={2}>Palette</Title>
        {renderPaletteFields()}
      </Section>
      <Section>
        <Title level={2}>Background</Title>
        <Row>
          <Col xs={8}>
            <ColorResourceEditor {...getBasicEditorProps('pageBackground.start')} label="Start" />
          </Col>
          <Col xs={8}>
            <ColorResourceEditor {...getBasicEditorProps('pageBackground.end')} label="End" />
          </Col>
          <Col xs={8}>
            <ThemeResourceEditor {...getBasicEditorProps('pageBackground.angle')} label="Angle">
              <Numeric name="" validate={validateAngle} />
            </ThemeResourceEditor>
          </Col>
        </Row>
      </Section>
    </TabForm>
  );
};
