import { Choice, Tabs, YesNo } from '@mortgagehippo/ds';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { FieldEditorFrame } from './field-editor-frame';

export const DurationDateEditor = ({ name }: { name: string }) => (
  <FieldEditorFrame name={name}>
    {() => (
      <Tabs.Tab id="duration_date" label="Duration Date Options">
        <EditorTabWrapper size="md">
          <Choice.Box
            name={`${name}.future`}
            label="Direction"
            options={[
              { label: 'Date is in the future', value: true },
              { label: 'Date is in the past', value: false },
            ]}
            initialValue
          />
          <YesNo.Box
            name={`${name}.endOfMonth`}
            label="Calculate as end of month"
            initialValue={false}
          />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
  </FieldEditorFrame>
);
