import {
  Checkbox,
  type FormSubmitHandler,
  Input,
  ModalForm,
  Numeric,
  Timezone,
} from '@mortgagehippo/ds';

interface IEditPartnerModalProps {
  initialValues?: any;
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
  isOpen: boolean;
  loading: boolean;
}

const defaultInitialValues = {};
export const EditPartnerModal = (props: IEditPartnerModalProps) => {
  const { initialValues = defaultInitialValues, ...rest } = props;
  return (
    <ModalForm title="Edit Partner" initialValues={initialValues} {...rest}>
      <Input.Box name="name" label="Name" required />
      <Input.Box name="admin_domain" label="Admin Domain" required />
      <Numeric.Box name="admin_inactivity_timeout" label="Admin Inactivity Timeout (ms)" />
      <Timezone.Box name="timezone" label="Timezone" required />
      <Checkbox.Box label="Billable" labelInvisible name="is_billable">
        Is billable?
      </Checkbox.Box>
    </ModalForm>
  );
};
