import { Input, YesNo } from '@mortgagehippo/ds';

export const FinicityFields = () => (
  <>
    <Input.Box name="app_key" label="App Key" required />
    <Input.Box name="partner_id" label="Partner ID" required />
    <Input.Box name="partner_secret" label="Partner Secret" required />
    <Input.Box name="experience_id" label="Experience ID" />
    <Input.Box
      name="number_of_days_to_include_in_report"
      label="Number of Days to Include in Report"
      required
    />
    <YesNo.Box name="production_mode" label="Production Mode" required />
  </>
);
