import { useFormState } from '@mortgagehippo/ds';
import { DataGatheringTask, type IDataGatheringTask } from '@mortgagehippo/tasks';
import { withErrorBoundary } from '@mortgagehippo/util';
import { get } from 'lodash-es';
import { useContext } from 'react';

import { EditorContext } from '../editor-context';
import { TaskPreviewWrapper } from '../task-preview-wrapper';

const ErrorMessage = () => <h1>ERROR</h1>;

export const FieldPreview = withErrorBoundary(ErrorMessage)(() => {
  const { path } = useContext(EditorContext);
  const { values } = useFormState({ subscription: { values: true } });
  const field = get(values, path, {});

  if (!field?.type_name) {
    return null;
  }

  const task: any = {
    data: {
      panels: [
        {
          groups: [
            {
              fields: [
                {
                  ...field,
                  showIf: null,
                  hideIf: null,
                  disableIf: null,
                },
              ],
            },
          ],
        },
      ],
    },
  };

  const handleChange = async () => ({ success: true, errors: [] });
  const handleComplete = async () => undefined;

  return (
    <TaskPreviewWrapper task={task}>
      {({ nextTask, frame }) => (
        <DataGatheringTask
          task={nextTask as IDataGatheringTask}
          onComplete={handleComplete}
          onChange={handleChange}
          initialValues={{}}
          frame={frame}
        />
      )}
    </TaskPreviewWrapper>
  );
});
