import { Choice, Input, TextArea } from '@mortgagehippo/ds';

export const SmartFeesFields = () => (
  <>
    <Input.Box name="api_key" label="Api Key" required />
    <Input.Box name="user_id" label="Username" required />
    <Input.Box name="password" label="Password" required />
    <Choice.Box
      name="environment"
      label="Environment"
      options={[
        { label: 'SIT', value: 'sit' },
        { label: 'Production', value: 'production' },
        { label: 'UAT', value: 'uat' },
      ]}
      required
    />
    <Input.Box name="issuer" label="Issuer" required />
    <TextArea.Box name="private_key" label="Private Key" required />
    <TextArea.Box name="public_cert" label="Public Certificate" required />
  </>
);
