import {
  Button,
  Col,
  FieldAutoArray,
  FieldBox,
  Icon,
  Input,
  type ISelectOption,
  Numeric,
  Row,
  Select,
  styled,
  Title,
  useFormValue,
  useModal,
  useModalConfirm,
  YesNo,
} from '@mortgagehippo/ds';
import { useSagaClickHandler } from '@mortgagehippo/tasks';
import { useCallback } from 'react';

import { useUnregisterSiteAuth0Tenant } from '../../hooks/use-unregister-site-auth0-tenant';
import { Auth0SiteTenantModal } from './auth0-site-tenant-modal';

const Container = styled.div`
  display: flex;
  align-items: center;

  & > *:last-child {
    width: 50%;
  }

  & > * {
    flex: 1 1 auto;
  }
`;

const providers: ISelectOption[] = [
  { value: 'AUTH0', label: 'Auth0' },
  { value: 'OIDC', label: 'OpenID Connect' },
];

const AuthConfigurationAuth0 = (props: {
  name: string;
  editMode: boolean;
  onSiteAuth0TenantChange: (() => Promise<void>) | undefined;
  siteId?: string;
}) => {
  const { name, editMode, onSiteAuth0TenantChange, siteId } = props;

  const [isSiteTenantModalOpen, openSiteTenantModal, closeSiteTenantModal] = useModal(false);

  const unregisterSiteAuth0Tenant = useUnregisterSiteAuth0Tenant();

  const [handleConfirmResetTenant, resetTenantModal] = useModalConfirm(
    "Are you sure you want to reset the tenant? The site will once again inherit from the partner. A new app client and connection will be created in the partner's auth0 tenant. If you wish to use a pre-existing connection, you will need to manually point the new app client to that connection in auth0 and then update the site's connection name here.",
    {
      type: 'warning',
      cancelButtonLabel: 'Cancel',
      okButtonLabel: 'Confirm',
      disableOverlayClickClose: true,
    }
  );

  const [hasSiteTenantValue, setHasSiteTenantValue] = useFormValue(`${name}.site_auth0_tenant`);

  const [handleClickResetTenant, unregistering] = useSagaClickHandler({
    infoMessage: 'Unregistering site Auth0 Tenant',
    successMessage: 'Successfully unregistered site Auth0 Tenant',
    errorMessage: 'There was an error unregistering site Auth0 Tenant.',
    async onClick(setAsyncId) {
      if (!siteId) {
        return;
      }

      const nextAsyncId = await unregisterSiteAuth0Tenant(siteId);

      setAsyncId(nextAsyncId);
    },
    async onDone() {
      setHasSiteTenantValue(false);
      if (onSiteAuth0TenantChange) {
        await onSiteAuth0TenantChange();
      }
    },
  });

  const handleResetTenant = useCallback(async () => {
    await handleConfirmResetTenant({
      onConfirm: async () => {
        await handleClickResetTenant();
      },
    });
  }, [handleClickResetTenant, handleConfirmResetTenant]);

  const handleSetupAuth0SiteTenant = useCallback(() => {
    openSiteTenantModal();
  }, [openSiteTenantModal]);

  const handleDone = useCallback(async () => {
    if (onSiteAuth0TenantChange) {
      await onSiteAuth0TenantChange();
    }
    closeSiteTenantModal();
  }, [closeSiteTenantModal, onSiteAuth0TenantChange]);

  const siteTenantModal = siteId ? (
    <Auth0SiteTenantModal
      siteId={siteId}
      isOpen={isSiteTenantModalOpen}
      name={name}
      onDone={handleDone}
      onCancel={closeSiteTenantModal}
    />
  ) : null;

  return (
    <>
      {!editMode && (
        <>
          <br />
          <Title level={4}>
            Note: If you need to specify a different auth0 tenant, you may do so after creating the
            site.
          </Title>
          <br />
        </>
      )}
      {editMode && hasSiteTenantValue ? (
        <Container>
          <Button onClick={handleResetTenant} loading={unregistering} disabled={unregistering}>
            Reset tenant?
          </Button>
          <p>
            <strong>This site has a different Auth0 tenant configured than the partner.</strong>
          </p>
        </Container>
      ) : null}
      {editMode && !hasSiteTenantValue ? (
        <Button onClick={handleSetupAuth0SiteTenant}>Setup Site Auth0 Tenant</Button>
      ) : null}
      <Input.Box
        label="Domain"
        description="If not provided this will inherit from the partner"
        name={`${name}.domain`}
      />
      <Input.Box
        label="Client Id"
        description={!editMode ? 'If not provided one is going to be created for you' : undefined}
        name={`${name}.client_id`}
        required={editMode}
      />
      <Input.Box
        label="Default Connection"
        name={`${name}.connection`}
        description={!editMode ? 'If not provided one is going to be created for you' : undefined}
        required={editMode}
      />
      <Input.Box
        label="API Identifier"
        description="If not provided this will inherit from the partner"
        name={`${name}.api`}
      />
      {siteTenantModal}
      {resetTenantModal}
    </>
  );
};

const AuthConfigurationOIDC = (props: { name: string; editMode: boolean }) => {
  const { name } = props;
  return (
    <>
      <Input.Box label="Authority" name={`${name}.authority`} required />
      <Input.Box label="Client Id" name={`${name}.client_id`} required />
      <Input.Box
        label="Sub Prefix"
        name={`${name}.authentication_id_prefix`}
        description="Prepend this string to the sub claims"
      />

      <Input.Box
        label="Provider Name"
        name={`${name}.connection_name`}
        description="Use this to identify the provider in the db"
        required
      />

      <Input.Box
        label="Response Type"
        name={`${name}.response_type`}
        description="Some clients don't like you requesting id_token other require it"
        initialValue="token id_token"
        required
      />

      <FieldBox
        name={`${name}.extra_query_params`}
        label="Extra Query Params"
        description="Pass this information as query parameters to the OIDC server"
      >
        <FieldAutoArray
          name={`${name}.extra_query_params`}
          sortable
          isPresent={(v) => v.name && v.value}
          render={({ name: itemName, handleProps }) => (
            <Row>
              <Col flex={0} {...(handleProps || {})}>
                <Icon name="drag-handle" />
              </Col>
              <Col>
                <Input name={`${itemName}.name`} placeholder="Name" compact />
              </Col>
              <Col>
                <Input name={`${itemName}.value`} placeholder="Value" compact />
              </Col>
            </Row>
          )}
        />
      </FieldBox>

      <Input.Box
        label="Signup URL"
        description="If the OIDC server doesn't support the create prompt, enter the signup url here"
        name={`${name}.signup_url`}
      />
      <Input.Box
        label="Logout URL"
        description="If the OIDC server doesn't support end_session_url, enter a logout url here"
        name={`${name}.logout_url`}
      />
    </>
  );
};

export const AuthConfiguration = (props: {
  name: string;
  editMode: boolean;
  onSiteAuth0TenantChange: (() => Promise<void>) | undefined;
  siteId?: string;
}) => {
  const { name, editMode, siteId, onSiteAuth0TenantChange } = props;

  const [type_name] = useFormValue(`${name}.type_name`);
  return (
    <>
      <Select.Box
        name={`${name}.type_name`}
        label="Authentication Type"
        options={providers}
        required
      />

      {type_name === 'AUTH0' && (
        <AuthConfigurationAuth0
          name={name}
          editMode={editMode}
          siteId={siteId}
          onSiteAuth0TenantChange={onSiteAuth0TenantChange}
        />
      )}
      {type_name === 'OIDC' && <AuthConfigurationOIDC name={name} editMode={editMode} />}

      <YesNo.Box
        label="Allow Anonymous Accounts"
        name={`${name}.allow_anonymous_accounts`}
        required
      />

      <YesNo.Box
        label="Use Silent Authentication"
        name={`${name}.silent_authentication`}
        required
      />

      <Numeric.Box label="Inactivity Timeout (ms)" name={`${name}.inactivity_ms`} />
    </>
  );
};
