import { graphql } from '../../apollo/gql';

// eslint-disable-next-line unused-imports/no-unused-vars
const FCustomFieldFragment = graphql(`
  fragment CustomFieldFragment on CustomField {
    id
    name
    typeName
    description
    createdAt
    updatedAt
    common
  }
`);

export const QPartnerCustomFieldsList = graphql(`
  query PartnerCustomFieldsList(
    $partnerId: ID!
    $vertical: VerticalType!
    $perPage: Int!
    $cursor: String
  ) {
    partner(id: $partnerId) {
      id
      customFields(
        vertical: $vertical
        options: { perPage: $perPage, cursor: $cursor, orderBy: { name: "asc" } }
      ) {
        total
        previousCursor
        nextCursor
        items {
          ...CustomFieldFragment
        }
      }
    }
  }
`);

export const QCustomFieldData = graphql(`
  query CustomFieldData($partnerId: ID!, $vertical: VerticalType!, $fieldId: ID!) {
    partner(id: $partnerId) {
      customField(id: $fieldId, vertical: $vertical) {
        ...CustomFieldFragment
      }
    }
  }
`);

export const MCreateCustomFieldMutation = graphql(`
  mutation CreateCustomField(
    $partnerId: ID!
    $vertical: VerticalType!
    $data: CreateCustomFieldInput!
  ) {
    createCustomField(partnerId: $partnerId, vertical: $vertical, data: $data) {
      ...CustomFieldFragment
    }
  }
`);

export const MUpdateCustomFieldMutation = graphql(`
  mutation UpdateCustomField(
    $fieldId: ID!
    $vertical: VerticalType!
    $data: UpdateCustomFieldInput!
  ) {
    updateCustomField(fieldId: $fieldId, vertical: $vertical, data: $data) {
      ...CustomFieldFragment
    }
  }
`);

export const MDeleteCustomFieldMutation = graphql(`
  mutation DeleteCustomField($fieldId: ID!, $vertical: VerticalType!) {
    deleteCustomField(fieldId: $fieldId, vertical: $vertical) {
      ...CustomFieldFragment
    }
  }
`);
