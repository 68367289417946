import { FORM_ERROR, notifications } from '@mortgagehippo/ds';
import { isEmpty } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import {
  type LenderMilestoneInput,
  LenderMilestoneInputAction,
  type VerticalType,
} from '../../../apollo/graphql';
import {
  type ILenderMilestoneModalFormValues,
  LenderMilestoneEditModal,
} from './lender-milestone-edit-modal';
import { type ILenderMilestone } from './queries';
import { useExternalMilestoneKeys } from './use-external-milestone-keys';
import { useUpdateLenderMilestones } from './use-update-lender-milestones';

interface ILenderMilestoneEditModalContainerProps {
  partnerId: string;
  vertical?: VerticalType;
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: (hasErrors?: boolean) => void;
  lenderMilestones: ILenderMilestone[];
}

export const LenderMilestoneEditModalContainer = (
  props: ILenderMilestoneEditModalContainerProps
) => {
  const { isOpen, partnerId, lenderMilestones, onRequestClose, onSubmit, vertical } = props;

  const updateLenderMilestones = useUpdateLenderMilestones();

  const [{ externalKeys }, externalKeysLoading] = useExternalMilestoneKeys(
    partnerId,
    vertical ?? null,
    !vertical,
    false
  );

  const handleSubmit = useCallback(
    async (values: ILenderMilestoneModalFormValues) => {
      const { lenderMilestones: newLenderMilestonesWithEmptyRow } = values;

      if (!newLenderMilestonesWithEmptyRow) {
        return undefined;
      }

      const newLenderMilestones = newLenderMilestonesWithEmptyRow.filter(
        (newLenderMilestone) => !isEmpty(newLenderMilestone)
      );

      try {
        if (!vertical) {
          // Satisfy typescript, vertical should always be defined...
          throw new Error('Vertical must be selected.');
        }

        const deletedMilestones = lenderMilestones
          .filter(
            (lenderMilestone) =>
              !newLenderMilestones.length ||
              !newLenderMilestones.find(
                (newLenderMilestone) => lenderMilestone.id === newLenderMilestone.id
              )
          )
          .map((filteredMilestone) => {
            const { id, name, externalMilestoneKey, externalMilestoneKeyId } = filteredMilestone;
            const input: LenderMilestoneInput = {
              action: LenderMilestoneInputAction.delete,
              id,
              name,
              externalMilestoneKey,
              externalMilestoneKeyId,
            };
            return input;
          });

        const createUpdateMilestones = (newLenderMilestones || []).map(
          (newLenderMilestone, index) => {
            const { id, name, externalMilestoneKey } = newLenderMilestone;
            const input: LenderMilestoneInput = {
              action: id ? LenderMilestoneInputAction.update : LenderMilestoneInputAction.create,
              id,
              tempId: !id ? uuid() : undefined,
              name,
              externalMilestoneKey,
              externalMilestoneKeyId:
                externalKeys.find((externalKey) => externalKey.name === externalMilestoneKey)?.id ||
                '',
              position: index + 1,
            };
            return input;
          }
        );

        const response = await updateLenderMilestones(partnerId, vertical, {
          lenderMilestones: [...deletedMilestones, ...createUpdateMilestones],
        });

        const responseErrors = response.reduce<Record<string, any>>(
          (accumulator, updatedMilestone) => {
            const { errors, id, tempId } = updatedMilestone;

            if (!errors.length) {
              return accumulator;
            }

            accumulator.lenderMilestones ||= newLenderMilestones.map(() => undefined);

            const matchedInputLenderMilestone =
              deletedMilestones.find(
                (deletedMilestone) => id && deletedMilestone.id && id === deletedMilestone.id
              ) ||
              createUpdateMilestones.find(
                (createUpdateMilestone) =>
                  (id && createUpdateMilestone.id && id === createUpdateMilestone.id) ||
                  (tempId &&
                    createUpdateMilestone.tempId &&
                    tempId === createUpdateMilestone.tempId)
              );

            const matchedPosition = matchedInputLenderMilestone?.position;

            if (!matchedPosition) {
              accumulator[FORM_ERROR] = errors;
              return accumulator;
            }

            // attempt to organize error to specific input by keywords...
            accumulator.lenderMilestones.splice(matchedPosition - 1, 1, {
              name: errors
                .filter(
                  (error) =>
                    error.toLowerCase().includes('name') || !error.toLowerCase().includes('key')
                )
                .join(' --- '),
              externalMilestoneKey: errors
                .filter((error) => error.toLowerCase().includes('key'))
                .join(' --- '),
            });

            return accumulator;
          },
          {}
        );

        if (!isEmpty(responseErrors)) {
          return responseErrors;
        }

        notifications.success({
          message: 'The milestones were successfully updated.',
        });
        onSubmit();
      } catch (error: unknown) {
        notifications.error({
          message: 'Error updating milestones.',
        });
      }
      return undefined;
    },
    [externalKeys, lenderMilestones, onSubmit, partnerId, updateLenderMilestones, vertical]
  );

  const initialValues = useMemo(() => {
    if (!lenderMilestones.length || externalKeysLoading) {
      return { lenderMilestones: [] };
    }

    const values: ILenderMilestoneModalFormValues = {
      lenderMilestones: lenderMilestones.map((lenderMilestone) => {
        const { id, name, externalMilestoneKey } = lenderMilestone;
        return {
          id,
          name,
          externalMilestoneKey,
        };
      }),
    };

    return values;
  }, [externalKeysLoading, lenderMilestones]);

  return (
    <LenderMilestoneEditModal
      isOpen={isOpen}
      loading={externalKeysLoading}
      externalKeys={externalKeys || []}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onRequestClose={onRequestClose}
    />
  );
};
