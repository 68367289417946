import { Input, Tabs, YesNo } from '@mortgagehippo/ds';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { FieldEditorFrame } from './field-editor-frame';
import { MonthPickerValidationEditor } from './month-picker-validation-editor';

export const MonthPickerEditor = ({ name }: { name: string }) => (
  <FieldEditorFrame name={name} validations={() => <MonthPickerValidationEditor name={name} />}>
    {() => (
      <Tabs.Tab id="monthpicker" label="Month Picker options">
        <EditorTabWrapper size="md">
          <Input.Box name={`${name}.placeholder`} label="Placeholder" />
          <YesNo.Box name={`${name}.useFirstDay`} label="Use first day of month" />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
  </FieldEditorFrame>
);
