import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo/gql';
import { type UpdatePageComponentResourcesInput } from '../../../apollo/graphql';

export const MUpdatePageComponentMutation = graphql(`
  mutation UpdatePageComponent(
    $pageComponentId: ID!
    $config: JSON!
    $resources: [UpdatePageComponentResourcesInput!]!
  ) {
    updatePageComponent(pageComponentId: $pageComponentId, config: $config, resources: $resources) {
      ...PageComponentFragment
      customizationValues {
        ...CustomizationValueFragment
      }
    }
  }
`);

export const useUpdatePageComponent = () => {
  const updatePageComponent = useMutation(MUpdatePageComponentMutation, undefined);

  return async (
    pageComponentId: string,
    config: any,
    resources: UpdatePageComponentResourcesInput[]
  ) => {
    await updatePageComponent({ pageComponentId, config, resources });
  };
};
