import { useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../apollo/gql';

export const QApplicationMaintenanceMode = graphql(`
  query ApplicationMaintenanceMode {
    admin {
      maintenanceMode
    }
  }
`);

export const useAppMaintenanceMode = () => {
  const [data, ...rest] = useQuery(QApplicationMaintenanceMode);
  return [data?.admin?.maintenanceMode, ...rest] as const;
};
