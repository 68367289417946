import { isArray, isEmpty, isPlainObject, set } from 'lodash-es';

const _flatten = (
  acum: any[],
  prefix: string | undefined,
  language: string,
  type: string,
  obj: any
) => {
  Object.keys(obj).forEach((k) => {
    let value = obj[k];
    const key = prefix ? `${prefix}.${k}` : k;

    if (isPlainObject(value)) {
      _flatten(acum, key, language, type, value);
      return;
    }

    if (type === 'image' && isArray(value) && value.length > 0) {
      value = value[0].id;
    }

    if (!value || isEmpty(value)) {
      return;
    }

    acum.push({
      language,
      type,
      key,
      value,
    });
  });
};

export const toResourcesServerInput = (obj: any) => {
  const acum: any[] = [];
  const languages = Object.keys(obj);
  languages.forEach((lng) => {
    const types = Object.keys(obj[lng]);
    types.forEach((tp) => {
      _flatten(acum, '', lng, tp, obj[lng][tp]);
    });
  });
  return acum;
};

export const toResourcesInitialValues = (resources: any, values: any[]) => {
  const acum = {};

  Object.keys(resources).forEach((key) => {
    const cid = resources[key];
    const [, cidKey] = cid.split(':');
    const keyValues = values.filter((v) => v.key === cidKey);

    keyValues.forEach((record: any) => {
      const { language, type } = record;
      let { value } = record;

      if (type === 'image') {
        const filename = value.substring(value.lastIndexOf('/') + 1);
        value = [{ id: value, filename }];
      }

      set(acum, `${language}.${type}.${key}`, value);
    });
  });

  return acum;
};
