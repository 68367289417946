import { UnassignedApplicationNotifications } from '../../../components/unassigned-application-notifications/unassigned-application-notifications';

interface ISiteUnassignedApplicationNotificationsContactsPageContentProps {
  partnerId: string;
  siteId: string;
}

export const SiteUnassignedApplicationNotificationsContactsPageContent = (
  props: ISiteUnassignedApplicationNotificationsContactsPageContentProps
) => {
  const { partnerId, siteId } = props;

  return <UnassignedApplicationNotifications partnerId={partnerId} siteId={siteId} />;
};
