import { useQuery } from '@mortgagehippo/apollo-hooks';
import { Format, Icon, type ITableActions, type ITableCols, Table } from '@mortgagehippo/ds';
import { UnreachableCaseError } from '@mortgagehippo/util';
import { useCallback, useMemo } from 'react';
import { type RouteComponentProps } from 'react-router';

import { ActionStatus, ActionType, useActionEffect, useDispatchAction } from '$components/actions';

import {
  NotificationMediumType,
  ParentType,
  type SiteNotificationProfilesListQuery,
} from '../../../apollo/graphql';
import { useSite } from '../../../hooks/use-site';
import { Content, Layout } from '../../../layouts/main';
import { ProfileName } from './profile-name';
import { QSiteNotificationProfilesList } from './queries';

const PARENT_TYPE = ParentType.Site;

enum SiteNotificationsPageActionType {
  EDIT = 'edit',
  OVERRIDE = 'override',
  DELETE = 'delete',
}

export type INotificationProfile = NonNullable<
  SiteNotificationProfilesListQuery['site']
>['notificationProfiles'][0];

const columns: ITableCols<INotificationProfile> = [
  {
    title: 'Name',
    key: 'name',
    render: ({ name, parentType }) => (
      <ProfileName showTag={PARENT_TYPE !== parentType} parentType={parentType}>
        {name}
      </ProfileName>
    ),
  },
  {
    title: 'Type',
    key: 'type',
    render: (t) => t.typeName,
  },
  {
    title: 'Email',
    key: NotificationMediumType.email,
    align: 'center',
    render: ({ data }) =>
      data.mediums.includes(NotificationMediumType.email) ? <Icon name="check" size="sm" /> : null,
  },
  {
    title: 'SMS',
    key: NotificationMediumType.sms,
    align: 'center',
    render: ({ data }) =>
      data.mediums.includes(NotificationMediumType.sms) ? <Icon name="check" size="sm" /> : null,
  },
  {
    title: 'App',
    key: NotificationMediumType.in_app,
    align: 'center',
    render: ({ data }) =>
      data.mediums.includes(NotificationMediumType.in_app) ? <Icon name="check" size="sm" /> : null,
  },
  {
    title: 'Last Updated',
    key: 'updated',
    render: ({ createdAt, updatedAt }) => (
      <Format.Date format="fromnow" value={updatedAt || createdAt} />
    ),
  },
];

interface ISiteNotificationsPageRouteParams {
  partnerId: string;
  siteId: string;
}

type ISiteNotificationsPageProps = RouteComponentProps<ISiteNotificationsPageRouteParams>;

export const SiteNotificationsPage = (props: ISiteNotificationsPageProps) => {
  // TODO: less duplicated code with partner notifications page
  const { match } = props;
  const { params } = match;
  const { partnerId, siteId } = params;
  const [site] = useSite(siteId);
  const dispatch = useDispatchAction();

  const [data, loading, , { refetch }] = useQuery(
    QSiteNotificationProfilesList,
    {
      siteId,
    },
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  useActionEffect(
    async (action) => {
      if (
        action.type !== ActionType.CREATE_NOTIFICATION_PROFILE &&
        action.type !== ActionType.EDIT_NOTIFICATION_PROFILE &&
        action.type !== ActionType.DELETE_NOTIFICATION_PROFILE &&
        action.type !== ActionType.OVERRIDE_NOTIFICATION_PROFILE
      ) {
        return;
      }

      refetch();
    },
    undefined,
    ActionStatus.DONE
  );

  const handleAdd = useCallback(() => {
    dispatch({
      type: ActionType.CREATE_NOTIFICATION_PROFILE,
      partnerId,
      siteId,
    });
  }, [dispatch, partnerId, siteId]);

  const handleClickRow = useCallback(
    (record?: INotificationProfile) => {
      if (!record || record.parentType !== PARENT_TYPE) {
        return;
      }

      dispatch({
        type: ActionType.EDIT_NOTIFICATION_PROFILE,
        partnerId,
        siteId,
        profileId: record.id,
      });
    },
    [dispatch, partnerId, siteId]
  );

  const handleOverride = useCallback(
    (record: INotificationProfile) => {
      dispatch({
        type: ActionType.OVERRIDE_NOTIFICATION_PROFILE,
        partnerId,
        siteId,
        profileId: record.id,
      });
    },
    [dispatch, partnerId, siteId]
  );

  const handleDelete = useCallback(
    async (record: INotificationProfile) => {
      dispatch({
        type: ActionType.DELETE_NOTIFICATION_PROFILE,
        profileId: record.id,
      });
    },
    [dispatch]
  );

  const handleAction = useCallback(
    async (actionKey: string, record?: INotificationProfile) => {
      if (!record) {
        return;
      }

      const actionType = actionKey as SiteNotificationsPageActionType;

      switch (actionType) {
        case SiteNotificationsPageActionType.EDIT:
          handleClickRow(record);
          break;
        case SiteNotificationsPageActionType.OVERRIDE:
          handleOverride(record);
          break;
        case SiteNotificationsPageActionType.DELETE:
          await handleDelete(record);
          break;
        default: {
          throw new UnreachableCaseError(actionType);
        }
      }
    },
    [handleClickRow, handleDelete, handleOverride]
  );

  const actions: ITableActions<INotificationProfile> = useMemo(() => {
    const tableActions: ITableActions<INotificationProfile> = [
      {
        key: 'options',
        label: 'Options',
        buttonProps: {
          icon: 'menu-dots',
        },
        onGroupAction: handleAction,
        actions: [
          {
            key: SiteNotificationsPageActionType.EDIT,
            label: 'Edit',
            iconProps: {
              name: 'edit',
            },
            disabled: (record) => record?.parentType !== PARENT_TYPE,
          },
          {
            key: SiteNotificationsPageActionType.DELETE,
            label: 'Delete',
            iconProps: {
              name: 'delete',
            },
            disabled: (record) => record?.parentType !== PARENT_TYPE,
          },
          {
            key: SiteNotificationsPageActionType.OVERRIDE,
            label: 'Override',
            iconProps: {
              name: 'redo',
            },
            disabled: (record) => record?.parentType === PARENT_TYPE,
          },
        ],
      },
    ];
    return tableActions;
  }, [handleAction]);

  const topActions: ITableActions<INotificationProfile> = useMemo(
    () => [
      {
        key: 'add-profile',
        label: 'Add profile',
        buttonProps: {
          icon: 'plus',
        },
        onAction: handleAdd,
      },
    ],
    [handleAdd]
  );

  const siteName = site?.name || '';

  const dataSource = data?.site?.notificationProfiles || [];

  return (
    <Layout pageTitle={`${siteName} - Notifications`}>
      <Content title="Notifications" subTitle={siteName}>
        <Table<INotificationProfile>
          caption="Site Notification Profiles"
          data={dataSource}
          rowKey={(item) => item.id}
          cols={columns}
          loading={loading}
          onRowClick={handleClickRow}
          rowActions={actions}
          topActions={topActions}
          emptyText="No saved notification profiles."
          size="sm"
          hideTopBackground
        />
      </Content>
    </Layout>
  );
};
