import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo/gql';
import { type ScheduleRepeatableServiceJobInput } from '../../../apollo/graphql';

export const MScheduleRepeatableServiceJobMutation = graphql(`
  mutation ScheduleRepeatableServiceJob($data: ScheduleRepeatableServiceJobInput!) {
    scheduleRepeatableServiceJob(data: $data) {
      id
    }
  }
`);

export const useScheduleRepeatableServiceJob = () => {
  const scheduleRepeatableServiceJob = useMutation(
    MScheduleRepeatableServiceJobMutation,
    undefined
  );

  return useCallback(
    async (data: ScheduleRepeatableServiceJobInput) => {
      const response = await scheduleRepeatableServiceJob({ data });
      return response.data!.scheduleRepeatableServiceJob?.id;
    },
    [scheduleRepeatableServiceJob]
  );
};
