import { DEFAULT_THEME, type ISelectOption, type IThemeSpacing, Select } from '@mortgagehippo/ds';

import { type IValueEditorProps } from './types';

export const SpacingEditor = (props: IValueEditorProps) => {
  const { onChange, value, labelId, disabled } = props;

  const spacingOptions: ISelectOption[] = Object.keys(DEFAULT_THEME.spacing).map((s) => {
    const spacingValue = DEFAULT_THEME.spacing[s as keyof IThemeSpacing] as number;
    return {
      label: `${s.replace('space', '')} (${spacingValue}rem, ${spacingValue * 16}px)`,
      value: `${s.replace('space', '')}`,
    };
  });

  return (
    <Select.Input
      name="customization_value"
      aria-labelledby={labelId}
      value={value}
      onChange={onChange}
      placeholder="select spacing value"
      disabled={disabled}
      options={spacingOptions}
    />
  );
};
