import { useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo/gql';

export const QBlueprint = graphql(`
  query Blueprint($partnerId: ID!, $blueprintId: ID!) {
    partner(id: $partnerId) {
      id
      blueprint(id: $blueprintId) {
        id
        type
        name
        json
      }
    }
  }
`);

export const useBlueprint = (partnerId: string, blueprintId: string) => {
  const [data, loading] = useQuery(
    QBlueprint,
    {
      partnerId,
      blueprintId,
    },
    {
      fetchPolicy: 'network-only',
    }
  );

  const blueprint = !loading && data ? data?.partner?.blueprint : undefined;
  return [blueprint, loading] as const;
};
