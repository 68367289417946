import { Tabs } from '@mortgagehippo/ds';
import { type ReactNode } from 'react';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { JsonPreview } from '../json-preview';
import { ConditionEditor } from './condition-editor';
import { FieldConditionEditor } from './field-condition-editor';
import { FieldEditorDetails } from './field-editor-details';
import { FieldPreview } from './field-preview';
import { FieldValidationEditor } from './field-validation-editor';
import { TitleEditor } from './title-editor';
import { ValidationsEditor } from './validations-editor';

export interface ITaskEditorFrameProps {
  name: string;
  isEntity?: boolean;
  hasDiscriminator?: boolean;
  children?: () => ReactNode;
  validations?: () => ReactNode;
  disableTitleTab?: boolean;
  keySuffixes?: string[];
}

export const FieldEditorFrame = ({
  name,
  isEntity,
  hasDiscriminator,
  validations,
  disableTitleTab,
  children,
  keySuffixes,
}: ITaskEditorFrameProps) => (
  <Tabs>
    <Tabs.Tab id="details" label="Details">
      <EditorTabWrapper size="md">
        <FieldEditorDetails
          name={name}
          isEntity={isEntity}
          hasDiscriminator={hasDiscriminator}
          keySuffixes={keySuffixes}
        />
      </EditorTabWrapper>
    </Tabs.Tab>
    {!disableTitleTab && (
      <Tabs.Tab id="title" label="Label/Description">
        <EditorTabWrapper size="md">
          <TitleEditor name={name} allowCompact />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
    {children ? children() : null}
    <Tabs.Tab id="validation" label="Validation">
      <EditorTabWrapper size="md">
        <FieldValidationEditor name={name} />
        {validations ? validations() : null}
        <ValidationsEditor name={`${name}.validations`} />
      </EditorTabWrapper>
    </Tabs.Tab>
    <Tabs.Tab id="logic" label="Logic">
      <EditorTabWrapper size="md">
        <ConditionEditor name={name} />
        <FieldConditionEditor name={name} />
      </EditorTabWrapper>
    </Tabs.Tab>
    <Tabs.Tab id="preview" label="Preview">
      <EditorTabWrapper>
        <FieldPreview />
      </EditorTabWrapper>
    </Tabs.Tab>
    <Tabs.Tab id="json" label="JSON">
      <EditorTabWrapper>
        <JsonPreview />
      </EditorTabWrapper>
    </Tabs.Tab>
  </Tabs>
);
