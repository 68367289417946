import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo/gql';
import { type IEditorSagaArgument } from './types';

const MValidateSagaMutation = graphql(`
  mutation ValidateSaga($saga: ValidateSagaInput!) {
    saga {
      validateSaga(saga: $saga) {
        message
        severity
        lineStart
        lineEnd
        colStart
        colEnd
      }
    }
  }
`);

export const useValidateSaga = (partnerId: string, siteId?: string) => {
  const fn = useMutation(MValidateSagaMutation);
  return useCallback(
    async (source: string, args: IEditorSagaArgument[]) => {
      const result = await fn({
        saga: { source, arguments: args, partnerId, siteId },
      });
      return result.data!.saga.validateSaga;
    },
    [fn, partnerId, siteId]
  );
};
