import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo/gql';

export const MMovePageComponentUpMutation = graphql(`
  mutation MovePageComponentUp($pageComponentId: ID!) {
    movePageComponentUp(pageComponentId: $pageComponentId) {
      ...PageComponentFragment
    }
  }
`);

export const useMovePageComponentUp = () => {
  const movePageComponentUp = useMutation(MMovePageComponentUpMutation, undefined);

  return async (pageComponentId: string) => {
    await movePageComponentUp({ pageComponentId });
  };
};
