/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */
import 'element-closest-polyfill';
import 'setimmediate';

(function () {
  if (window.Intl && window.Intl.NumberFormat) {
    var formatter = new Intl.NumberFormat('en-US');
    if (typeof formatter.formatToParts === 'undefined') {
      // fixes Safari issues with incomplete Intl implementation
      var IntlPolyfill = require('intl');
      require('intl/locale-data/jsonp/en-US');
      require('intl/locale-data/jsonp/es-US');
      require('intl/locale-data/jsonp/es-MX');
      require('intl/locale-data/jsonp/en-CA');
      Intl.NumberFormat = IntlPolyfill.NumberFormat;
    }
  } else {
    window.Intl = require('intl');
  }
})();
