/* global CONFIG */
/* global PRODUCTION */
import { asBool } from '@mortgagehippo/util';

export interface IConfig {
  PRODUCTION: boolean;
  APPLICATION: string;
  DEFAULT_INACTIVITY_TIMEOUT?: number;
  INACTIVITY_COUNTDOWN?: number;
  SENTRY_DSN?: string;
  SENTRY_RELEASE?: string;
  GRAPHQL_ENDPOINT: string;
  GRAPHQL_SUBSCRIPTION_ENDPOINT: string;
  AUTHENTICATE_ENDPOINT: string;
  ENVIRONMENT: string;
  DEFAULT_CLONING_PARTNER_ID: string;
}

const { API_DOMAIN } = CONFIG;
const API_SECURE = asBool(CONFIG.API_SECURE, true);
const s = API_SECURE ? 's' : '';
const GRAPHQL_ENDPOINT = `http${s}://${API_DOMAIN}/graphql`;
const GRAPHQL_SUBSCRIPTION_ENDPOINT = `ws${s}://${API_DOMAIN}/graphql`;
const AUTHENTICATE_ENDPOINT = `http${s}://${API_DOMAIN}`;

export const config: IConfig = {
  PRODUCTION,
  APPLICATION: 'MANAGEMENT_PORTAL',
  DEFAULT_INACTIVITY_TIMEOUT: Number(CONFIG.INACTIVITY_TIMEOUT) || 60 * 30 * 1000,
  INACTIVITY_COUNTDOWN: Number(CONFIG.INACTIVITY_COUNTDOWN) || 60 * 5 * 1000,
  SENTRY_DSN: CONFIG.MANAGEMENT_PORTAL_SENTRY_DSN,
  SENTRY_RELEASE: CONFIG.SENTRY_RELEASE,
  GRAPHQL_ENDPOINT,
  GRAPHQL_SUBSCRIPTION_ENDPOINT,
  AUTHENTICATE_ENDPOINT,
  ENVIRONMENT: CONFIG.ENVIRONMENT,
  DEFAULT_CLONING_PARTNER_ID: CONFIG.DEFAULT_CLONING_PARTNER_ID,
};
