import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { graphql } from '../../apollo/gql';
import { type ApplicationStatusesQuery } from '../../apollo/graphql';

export const QApplicationStatusesQuery = graphql(`
  query ApplicationStatuses($partnerId: ID!) {
    partner(id: $partnerId) {
      id
      applicationFileStatuses {
        ...ApplicationFileStatusFragment
      }
    }
  }
`);

export type IApplicationStatus = NonNullable<
  ApplicationStatusesQuery['partner']
>['applicationFileStatuses'][0];

export const useApplicationStatuses = (partnerId: string) => {
  const [data, loading, , { refetch }] = useQuery(
    QApplicationStatusesQuery,
    { partnerId },
    {
      fetchPolicy: 'network-only',
    }
  );

  const handleRefetch = useSafeCallback(async () => refetch({ partnerId }));

  return [data?.partner?.applicationFileStatuses || [], loading, handleRefetch] as const;
};
