import { type RouteComponentProps } from 'react-router';

import { useSite } from '../../../hooks/use-site';
import { Content, Layout } from '../../../layouts/main';
import { SiteCascadesPageContent } from './site-cascades-page-content';

interface IPartnerCascadesPageRouteParams {
  partnerId: string;
  siteId: string;
}

type IPartnerCascadesPageProps = RouteComponentProps<IPartnerCascadesPageRouteParams>;

export const SiteCascadesPage = (props: IPartnerCascadesPageProps) => {
  const { match } = props;
  const { params } = match;
  const { partnerId, siteId } = params;
  const [site, loading] = useSite(siteId);

  const siteName = site?.name || '';

  return (
    <Layout pageTitle={`${siteName} - Cascades`}>
      <Content title="Cascades" subTitle={siteName}>
        <SiteCascadesPageContent partnerId={partnerId} siteId={siteId} loading={loading} />
      </Content>
    </Layout>
  );
};
