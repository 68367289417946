import { Col, Input, Numeric, Row } from '@mortgagehippo/ds';

export const ArrayValidationEditor = ({ name }: { name: string }) => (
  <>
    <Row>
      <Col>
        <Numeric.Box name={`${name}.minItems`} label="Min Items" />
      </Col>
      <Col>
        <Numeric.Box name={`${name}.maxItems`} label="Max Items" />
      </Col>
    </Row>
    <Input.Box name={`${name}.acknowledgeEmptyKey`} label="Acknowledge Empty Key" />
    <Input.Box name={`${name}.acknowledgeEmptyLabel`} label="Acknowledge Empty label" />
    <Input.Box name={`${name}.acknowledgeEmptyError`} label="Acknowledge Empty error" />
  </>
);
