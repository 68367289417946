import { Input } from '@mortgagehippo/ds';

export interface ITaskGroupEditorDetails {
  name: string;
}

export const TaskGroupEditorDetails = (props: ITaskGroupEditorDetails) => {
  const { name } = props;

  return (
    <>
      <Input.Box name={`${name}.key`} label="key" required />
      <Input.Box name={`${name}.label`} label="Label" required />
    </>
  );
};
