import { Choice, Input, Select, Tabs, YesNo } from '@mortgagehippo/ds';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { FieldEditorFrame } from './field-editor-frame';
import { NumericValidationEditor } from './numeric-validation-editor';

export const CurrencyEditor = ({ name }: { name: string }) => (
  <FieldEditorFrame name={name} validations={() => <NumericValidationEditor name={name} />}>
    {() => (
      <Tabs.Tab id="currency" label="Currency Options">
        <EditorTabWrapper size="md">
          <Input.Box name={`${name}.placeholder`} label="Placeholder" />
          <YesNo.Box name={`${name}.showPrefix`} label="Show currency symbol?" />
          <YesNo.Box name={`${name}.allowNegative`} label="Allow Negative Values" />
          <Select.Box
            name={`${name}.currency`}
            label="Currency"
            options={[
              {
                label: 'USD',
                value: 'USD',
              },
              {
                label: 'EUR',
                value: 'EUR',
              },
              {
                label: 'CAD',
                value: 'CAD',
              },
              {
                label: 'GBP',
                value: 'GBP',
              },
              {
                label: 'MXN',
                value: 'MXN',
              },
              {
                label: 'BGN',
                value: 'BGN',
              },
            ]}
          />
          <Choice.Box
            name={`${name}.decimalMode`}
            label="Decimal Mode"
            options={[
              {
                label: 'Allow',
                value: 'allow',
              },
              {
                label: 'Zeros',
                value: 'zeros',
              },
              {
                label: 'Hide',
                value: 'hide',
              },
            ]}
          />
          <Input.Box name={`${name}.constant`} label="Value" />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
  </FieldEditorFrame>
);
