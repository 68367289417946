import { useQuery } from '@mortgagehippo/apollo-hooks';
import { type ISearchFilter } from '@mortgagehippo/ds';
import { useSafeCallback } from '@mortgagehippo/util';
import { useMemo } from 'react';

import {
  QSiteAllAgents,
  QSiteNotificationAlternateAgents,
  UNASSIGNED_APPLICATION_NOTIFICATION_TYPE,
} from './queries';
import { type NotificationAlternateAgent } from './unassigned-application-notifications-table';

type IUseUnassignedApplicationNotificationsAgentsResult = [
  NotificationAlternateAgent[],
  boolean,
  () => Promise<void>,
];

export const useSiteNotificationAlternateAvailableAgents = (
  partnerId: string,
  siteId: string,
  searchCriteria?: ISearchFilter[],
  skip?: boolean
): IUseUnassignedApplicationNotificationsAgentsResult => {
  const [siteAllAgentsData, siteAllAgentsLoading, , { refetch: refetchSiteAllAgents }] = useQuery(
    QSiteAllAgents,
    {
      partnerId,
      siteId,
      searchCriteria: searchCriteria as any,
      paginationOptions: {
        orderBy: {
          name: 'asc',
        },
        perPage: 25,
      },
    },
    {
      skip,
    }
  );

  const [
    siteAssignedAgentsData,
    siteAssignedAgentsLoading,
    ,
    { refetch: refetchSiteAssignedAgents },
  ] = useQuery(
    QSiteNotificationAlternateAgents,
    {
      partnerId,
      siteId,
      notificationType: UNASSIGNED_APPLICATION_NOTIFICATION_TYPE,
    },
    {
      fetchPolicy: 'no-cache',
      skip,
    }
  );

  const handleRefresh = useSafeCallback(async () => {
    if (siteId) {
      await refetchSiteAllAgents({
        partnerId,
        siteId,
        searchCriteria: searchCriteria as any,
        paginationOptions: {
          orderBy: {
            name: 'asc',
          },
          perPage: 25,
        },
      });
      await refetchSiteAssignedAgents({
        partnerId,
        siteId: siteId || '',
        notificationType: UNASSIGNED_APPLICATION_NOTIFICATION_TYPE,
      });
    }
  });

  const loading = siteAllAgentsLoading || siteAssignedAgentsLoading;

  const result = useMemo((): NotificationAlternateAgent[] => {
    if (loading || !siteAllAgentsData || !siteAssignedAgentsData) {
      return [];
    }

    return (
      siteAllAgentsData.partner?.site?.agents.items.filter(
        (agent) =>
          !siteAssignedAgentsData.partner?.site?.notificationAlternateAgents.agents.some(
            (assignedAgent) => agent.id === assignedAgent.id
          )
      ) ?? []
    );
  }, [loading, siteAllAgentsData, siteAssignedAgentsData]);

  return [result, loading, handleRefresh];
};
