import {
  Button,
  CustomizationProvider,
  type CustomizationService,
  type IButtonProps,
  type ISelectOption,
  Tabs,
  useModal,
} from '@mortgagehippo/ds';
import { type ILandingPage } from '@mortgagehippo/tasks';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { type PageComponentType } from '../../apollo/graphql';
import { LandingPageEditorPreview } from './landing-page-editor-preview';
import { LandingPageEditorVariablesModal } from './landing-page-editor-variables-modal';
import { PageComponentDropdown, PageComponentEditModalContainer } from './page-components';

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const dropdownButtonProps: IButtonProps = {
  icon: 'plus',
  type: 'default',
  importance: 'tertiary',
  size: 'sm',
  compact: true,
};

interface ILandingPageEditorContentProps {
  landingPage: ILandingPage;
  currentLanguage: string;
  languages: ISelectOption[];
  service: CustomizationService;
  onLanguageChange: (lng: string) => void;
  onCreate?: (type: PageComponentType) => Promise<any>;
  onUpdate?: (id: string, values: any) => Promise<boolean>;
  onDelete?: (id: string) => Promise<any>;
  onMoveUp?: (id: string) => Promise<any>;
  onMoveDown?: (id: string) => Promise<any>;
}

export const LandingPageEditorContent = (props: ILandingPageEditorContentProps) => {
  const {
    landingPage,
    currentLanguage,
    languages,
    service,
    onLanguageChange,
    onCreate,
    onUpdate,
    onDelete,
    onMoveUp,
    onMoveDown,
  } = props;

  const [editingId, setEditingId] = useState<string | undefined>(undefined);
  const [editIsOpen, openEditModal, closeEditModal] = useModal(false);
  const [variablesModalOpen, setVariablesModalOpen] = useState(false);

  const handleViewVariables = useCallback(() => {
    setVariablesModalOpen(true);
  }, []);

  const handleCloseVariables = useCallback(() => {
    setVariablesModalOpen(false);
  }, []);

  const handleTabChange = useCallback(
    (nextLanguage: string) => {
      onLanguageChange(nextLanguage);
    },
    [onLanguageChange]
  );

  const handleEdit = useCallback(
    (id: string) => {
      setEditingId(id);
      openEditModal();
    },
    [openEditModal]
  );

  const handleCloseEdit = useCallback(() => {
    closeEditModal(() => {
      setEditingId(undefined);
    });
  }, [closeEditModal]);

  const handleSubmitModal = useCallback(
    async (values: any) => {
      if (editingId && onUpdate) {
        const success = await onUpdate(editingId, values);

        if (success) {
          handleCloseEdit();
        }
      }
    },
    [editingId, handleCloseEdit, onUpdate]
  );

  return (
    <>
      <Actions>
        <Button icon="information" importance="tertiary" size="sm" onClick={handleViewVariables}>
          Available variables
        </Button>
        <PageComponentDropdown
          landingPageType={landingPage.type}
          label="Add component"
          buttonProps={dropdownButtonProps}
          onClick={onCreate}
        />
      </Actions>
      <CustomizationProvider service={service}>
        <Tabs onChange={handleTabChange} selected={currentLanguage}>
          {languages.map((language) => {
            const { label, value: languageId } = language;

            return (
              <Tabs.Tab key={languageId} id={languageId} label={label}>
                <LandingPageEditorPreview
                  landingPage={landingPage}
                  onEdit={handleEdit}
                  onDelete={onDelete}
                  onMoveDown={onMoveDown}
                  onMoveUp={onMoveUp}
                />
              </Tabs.Tab>
            );
          })}
        </Tabs>
      </CustomizationProvider>
      <LandingPageEditorVariablesModal
        landingPageType={landingPage.type}
        isOpen={variablesModalOpen}
        onRequestClose={handleCloseVariables}
      />
      <PageComponentEditModalContainer
        landingPageType={landingPage.type}
        siteId={`${landingPage.siteId}`}
        landingPageId={landingPage.id}
        pageComponentId={editingId}
        languages={languages}
        currentLanguage={currentLanguage}
        onSubmit={handleSubmitModal}
        onRequestClose={handleCloseEdit}
        isOpen={editIsOpen}
      />
    </>
  );
};
