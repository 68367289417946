import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo/gql';
import { type CreateBlueprintInput } from '../apollo/graphql';

export const MCreateBlueprint = graphql(`
  mutation CreateBlueprint($partnerId: ID!, $blueprint: CreateBlueprintInput!) {
    createBlueprint(partnerId: $partnerId, blueprint: $blueprint) {
      id
    }
  }
`);

export const useCreateBlueprint = () => {
  const fn = useMutation(MCreateBlueprint);
  return async (partnerId: string, blueprint: CreateBlueprintInput) => {
    const result = await fn({ partnerId, blueprint });
    return result.data!.createBlueprint!.id;
  };
};
