import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo/gql';
import { type UpdateBlueprintInput } from '../apollo/graphql';

export const MSetBlueprint = graphql(`
  mutation SetBlueprint($blueprintId: ID!, $blueprint: UpdateBlueprintInput!) {
    saveBlueprint(blueprintId: $blueprintId, blueprint: $blueprint) {
      id
    }
  }
`);

export const useSaveBlueprint = () => {
  const fn = useMutation(MSetBlueprint);
  return (blueprintId: string, blueprint: UpdateBlueprintInput) => fn({ blueprintId, blueprint });
};
