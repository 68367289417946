import { Button, fontSize, fontWeight, palette, Tag, useFormValue } from '@mortgagehippo/ds';
import { type IDefaultTaskMessage } from '@mortgagehippo/tasks';
import { isPresent } from '@mortgagehippo/util';
import { useState } from 'react';
import styled from 'styled-components';

import { TaskMessageEditor } from './task-message-editor';
import { TaskMessageValue } from './task-message-value';

const MessageValue = styled.p``;

const MessageKey = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageKeyLabel = styled.div`
  flex: 1;
  font-weight: ${fontWeight('bold')};
  font-size: ${fontSize('md')};
  text-overflow: ellipsis;
`;

const MessageKeyTag = styled.div`
  flex: 1;
`;

const MessageKeyActions = styled.div`
  flex: 0;
`;

const MessageRow = styled.div`
  border-bottom: 1px solid ${palette('neutral300')};
  cursor: pointer;

  ${MessageKeyActions} {
    opacity: 0;
  }

  &:hover {
    ${MessageKeyActions} {
      opacity: 1;
    }
  }
`;

export interface ITaskMessagesProps {
  name: string;
  messages: IDefaultTaskMessage[];
}

export const TaskMessages = (props: ITaskMessagesProps) => {
  const { name, messages } = props;
  const [editKey, setEditKey] = useState<string | undefined>();
  const [value, setValues] = useFormValue(name);

  const handleEdit = (key: string) => () => {
    setEditKey(key);
  };

  const handleSave = (key: string) => (nextValue: string | undefined) => {
    const nextValues = { ...value };
    if (isPresent(nextValue)) {
      nextValues[key] = nextValue;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete nextValues[key];
    }
    setValues(nextValues);
    setEditKey(undefined);
  };

  const handleCancel = () => {
    setEditKey(undefined);
  };

  return (
    <>
      {messages.map((message) => {
        const defaultValue = message.value;
        const initialValue = value?.[message.key] ?? defaultValue;
        const edited = defaultValue !== initialValue;

        return (
          <MessageRow key={message.key} onClick={handleEdit(message.key)}>
            <MessageKey>
              <MessageKeyLabel>{message.key}</MessageKeyLabel>
              <MessageKeyTag> {edited ? <Tag color="primary">edited</Tag> : null}</MessageKeyTag>
              <MessageKeyActions>
                {!editKey && (
                  <Button
                    size="xs"
                    icon="edit"
                    importance="tertiary"
                    onClick={handleEdit(message.key)}
                    iconButton
                  />
                )}
              </MessageKeyActions>
            </MessageKey>
            <MessageValue>
              {editKey === message.key ? (
                <TaskMessageEditor
                  type={message.type}
                  initialValue={initialValue}
                  onCancel={handleCancel}
                  onSave={handleSave(message.key)}
                />
              ) : (
                <TaskMessageValue type={message.type} value={initialValue} />
              )}
            </MessageValue>
          </MessageRow>
        );
      })}
    </>
  );
};
