import { ModalDialog, notifications, useModal } from '@mortgagehippo/ds';
import { useCallback } from 'react';

import { type IActionProps, type IDeleteNotificationProfileAction } from '../types';
import { useDeleteNotificationProfile } from './use-delete-notification-profile';

export const DeleteNotificationProfileAction = (
  props: IActionProps<IDeleteNotificationProfileAction>
) => {
  const { action, onDone } = props;
  const { profileId } = action;
  const [isOpen, , closeModal] = useModal(true);

  const deleteLandingPage = useDeleteNotificationProfile();

  const handleOk = async () => {
    try {
      await deleteLandingPage(profileId);

      closeModal(() => {
        notifications.success({
          messageCid: 'delete-notification-profile-action:notification-success',
          message: 'Successfully deleted notification profile',
        });
        onDone();
      });
    } catch (e) {
      closeModal(() => {
        notifications.error({
          messageCid: 'delete-notification-profile-action:notification-error',
          message: 'There was an unexpected error deleting notification profile',
        });
        onDone();
      });
    }
  };

  const handleCloseModal = useCallback(() => {
    closeModal(onDone);
  }, [closeModal, onDone]);

  // TODO: use hook useModalConfirm
  return (
    <ModalDialog
      type="error"
      title="Are you sure you want to delete this notification profile?"
      onOk={handleOk}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
    >
      This action is irreversible
    </ModalDialog>
  );
};
