import {
  ExpandableText,
  Format,
  type ITableActions,
  type ITableCols,
  Table,
  Tag,
  Title,
} from '@mortgagehippo/ds';
import { type ReactNode } from 'react';

import { ParentType } from '../../apollo/graphql';
import { type WebhookConfigurationsEditorActionType } from './constants';
import { type IWebhookConfiguration } from './hooks/use-webhook-configuration';

const partnerWebhookConfigurationColumns: ITableCols = [
  {
    key: 'guid',
    title: 'GUID',
    render: ({ guid }) => guid
  },
  {
    title: 'Overwritten By',
    key: 'overwrittenBy',
    render: ({ overwrittenBy }) => {
      if (!overwrittenBy?.length) {
        return null;
      }
      return (
        <ExpandableText
          buttonProps={{ size: 'xxs' }}
          maxLines={2}
          expandButtonLabel="show all"
          compact
        >
          {overwrittenBy.map(({ name }: { name: string }) => (
            <div key={`${name}_key`}>{name}</div>
          ))}
        </ExpandableText>
      );
    },
  },
  {
    key: 'created_at',
    title: 'Created at',
    render: ({ createdAt }) =>
      !!createdAt && <Format.Date value={createdAt} format="date-med-time" />,
    width: '170px',
  },
];

const siteWebhookConfigurationColumns: ITableCols = [
  {
    key: 'guid',
    title: 'GUID',
    render: ({ guid, inherited }) => (
      <>
        {guid}{' '}
        {inherited ? (
          <Tag color="neutral" size="xs">
            Partner
          </Tag>
        ) : null}
      </>
    ),
  },
  {
    key: 'created_at',
    title: 'Created at',
    render: ({ createdAt }) =>
      !!createdAt && <Format.Date value={createdAt} format="date-med-time" />,
    width: '170px',
  },
];

interface IWebhookConfigurationsEditorContentTableProps {
  parentType: ParentType;
  dataSource?: IWebhookConfiguration[];
  loading?: boolean;
  title?: string;
  emptyText?: string;
  topContent?: ReactNode;
  topActions?: ITableActions;
  hideTopBackground?: boolean;
}

export const WebhookConfigurationsEditorContentTable = (props: IWebhookConfigurationsEditorContentTableProps) => {
  const {
    parentType,
    dataSource,
    title,
    loading,
    emptyText,
    topActions,
    topContent,
    hideTopBackground,
  } = props;

  return (
    <>
      {title ? <Title level={2}>{title}</Title> : null}

      <Table<IWebhookConfiguration, WebhookConfigurationsEditorActionType>
        caption="Webhook configurations"
        data={dataSource}
        cols={
          parentType === ParentType.Partner ? partnerWebhookConfigurationColumns : siteWebhookConfigurationColumns
        }
        loading={loading}
        alwaysShowRowActions
        emptyText={emptyText}
        rowKey={(i) => i.id}
        topActions={topActions}
        topContent={topContent}
        size="sm"
        hideTopBackground={hideTopBackground}
      />
    </>
  );
};
