import { graphql } from '../../../apollo/gql';

export const QSiteNotificationProfilesList = graphql(`
  query SiteNotificationProfilesList($siteId: ID!) {
    site(id: $siteId) {
      id
      notificationProfiles {
        ...NotificationProfileFragment
      }
    }
  }
`);
