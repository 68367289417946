import { type RouteComponentProps } from 'react-router';

import { CustomizationsEditor } from '$components/customizations-editor';

import { usePartner } from '../../../hooks/use-partner';
import { Content, Layout } from '../../../layouts/main';

interface IPartnerCustomizationsPageRouteParams {
  partnerId: string;
}

type IPartnerCustomizationsPageProps = RouteComponentProps<IPartnerCustomizationsPageRouteParams>;

export const PartnerCustomizationsPage = (props: IPartnerCustomizationsPageProps) => {
  const { match } = props;
  const { params } = match;
  const { partnerId } = params;

  const [partner] = usePartner(partnerId);
  const partnerName = partner?.name || '';

  return (
    <Layout pageTitle={`${partnerName} - Customizations`}>
      <Content title="Customizations: Partner Level">
        <CustomizationsEditor basePath={match.url} partnerId={Number(partnerId)} />
      </Content>
    </Layout>
  );
};
