import { useCallback, useEffect, useState } from 'react';

import { VerticalType } from '../../../apollo/graphql';
import { AnswersDocumentationModal } from './answers-documentation-modal';
import { useAnswersDocumentation } from './use-answers-documentation';

interface IAnswersDocumentationModalContainerProps {
  onRequestClose: () => void;
  isOpen: boolean;
}

export const AnswersDocumentationModalContainer = (
  props: IAnswersDocumentationModalContainerProps
) => {
  const { onRequestClose, isOpen } = props;
  const [selectedVertical, setSelectedVertical] = useState<VerticalType>(VerticalType.Mortgage);
  const [openCount, setOpenCount] = useState(0);

  const [documentation] = useAnswersDocumentation(selectedVertical, {
    skip: openCount === 0,
    notifyOnNetworkStatusChange: true,
  });

  const handleChange = useCallback((vertical: VerticalType) => {
    setSelectedVertical(vertical);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setOpenCount((prevState) => prevState + 1);
    }
  }, [isOpen]);

  return (
    <AnswersDocumentationModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      documentation={documentation}
      onChange={handleChange}
      selectedVertical={selectedVertical}
    />
  );
};
