import {
  borderRadius,
  fontWeight,
  Icon,
  palette,
  Popover,
  shadow,
  spacing,
  Tag,
  Text,
} from '@mortgagehippo/ds';
import { isPromise } from '@mortgagehippo/util';
import { type MouseEvent, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import { type ICustomizationsEditorResource } from '../../../types';
import { getSourceLabel } from '../../../util';

const ResourcesListUL = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const ResourceButton = styled.div`
  padding: ${spacing(3)} ${spacing(2)};
  cursor: pointer;
  align-items: center;
  outline: 0;
`;

const Resource = styled('li')<{ selected?: boolean }>`
  position: relative;
  z-index: 0;

  border-top: 1px solid ${palette('neutral200')};

  &:first-child {
    border-top-color: transparent;
  }

  &:not(.selected):hover {
    background: ${palette('neutral100')};
    border-radius: ${borderRadius(2)};
    border-top-color: transparent;
  }

  &:hover + & {
    border-top-color: transparent;
  }

  &.selected + & {
    border-top-color: transparent;
  }

  ${({ selected }) =>
    selected &&
    css`
      background: ${palette('white')};
      border-radius: ${borderRadius(2)};
      border-top-color: transparent;
      box-shadow: ${shadow(2)};
      z-index: 200;

      &:after,
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: -13px;
        height: 0;
        width: 0;
        border: 16px solid transparent;
        border-left: 16px solid ${palette('white')};
        border-right: none;
        z-index: 1;
        bottom: 0;
        margin: auto;
      }

      &:before {
        z-index: 0;
        border-left-color: ${palette('neutral900')};
        opacity: 0.13;
        filter: blur(1px);
        right: -16px;
        top: 3px;
      }
    `}
`;

const ResourceStatus = styled.div`
  display: inline-block;
  margin-right: ${spacing(2)};
`;

const ResourceName = styled.div`
  display: inline-block;
`;

const BlockText = styled(Text)`
  display: inline-block;
  & > * {
    display: inline-block;
  }
`;

const ResourceActionWrapper = styled.div`
  display: inline-block;
  white-space: nowrap;
`;

const ResourceActions = styled.div`
  display: inline-block;
`;

const ResourceSource = styled.div`
  display: inline-block;
  margin-left: ${spacing(1)};
`;

const StyledTag = styled(Tag)`
  margin-right: 0;
`;

const ResourceKeyPart = styled.span`
  &:after {
    content: ''; /* to make words break and carry over */
  }
  &:nth-child(odd) {
    font-weight: ${fontWeight('light')};
  }
`;

export interface IResourceItemProps {
  resource: ICustomizationsEditorResource;
  selected?: boolean;
  edited?: boolean;
  onClick?: (resource: ICustomizationsEditorResource) => void;
  onDelete?:
    | ((resource: ICustomizationsEditorResource) => Promise<any>)
    | ((resource: ICustomizationsEditorResource) => void);
}

const ResourcesListItem = (props: IResourceItemProps) => {
  const { resource, selected, edited, onClick, onDelete } = props;
  const [deleting, setDeleting] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(resource);
    }
  }, [onClick, resource]);

  const handleActionClick = useCallback((event: MouseEvent) => {
    // stop propagation of click event from delete button / confirm component
    event.stopPropagation();
  }, []);

  const handleDelete = useCallback(async () => {
    if (onDelete) {
      const result = onDelete(resource);

      if (isPromise(result)) {
        setDeleting(true);

        await result;

        setDeleting(false);
      }
    }
  }, [onDelete, resource]);

  const sourceLabel = getSourceLabel(resource);

  return (
    <Resource selected={selected} className={selected ? 'selected' : undefined}>
      <ResourceButton role="button" tabIndex={0} onClick={handleClick} onKeyDown={handleClick}>
        {edited ? (
          <ResourceStatus>
            <Icon name="edit" />
          </ResourceStatus>
        ) : null}
        <ResourceName>
          <BlockText size="sm">
            {resource.key.split('.').map((keyPart, i, arr) => (
              <ResourceKeyPart key={keyPart}>
                {keyPart}
                {i < arr.length - 1 ? '.' : ''}
              </ResourceKeyPart>
            ))}
          </BlockText>
        </ResourceName>
        {resource.isOverridden ? (
          <ResourceActionWrapper>
            <ResourceActions onClick={handleActionClick}>
              <Popover
                content="Are you sure?"
                confirm
                size="sm"
                onConfirm={handleDelete}
                buttonProps={{
                  size: 'xs',
                  iconButton: true,
                  icon: 'delete',
                  iconButtonHumble: true,
                  type: 'danger',
                  importance: 'tertiary',
                  compact: true,
                  loading: deleting,
                }}
              >
                Delete
              </Popover>
            </ResourceActions>
            {sourceLabel ? (
              <ResourceSource>
                <StyledTag>{sourceLabel}</StyledTag>
              </ResourceSource>
            ) : null}
          </ResourceActionWrapper>
        ) : null}
      </ResourceButton>
    </Resource>
  );
};

export const ResourcesList = Object.assign(ResourcesListUL, { Item: ResourcesListItem });
