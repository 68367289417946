import 'antd/es/tree/style/css.js';

import { Tabs, useFormState } from '@mortgagehippo/ds';
import { fromBlueprintTask, type ITaskGroup, toTaskGroups } from '@mortgagehippo/tasks';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';

import { BlueprintEditorNavigatorTree } from './blueprint-editor-navigator-tree';
import { type IEditorTask } from './types';
import { type BlueprintErrors, validateBlueprint } from './validator';

export interface IBlueprintEditorNavigatorProps {
  selected: string;
  onChange: (selected: string) => void;
}

export const BlueprintEditorNavigator = (props: IBlueprintEditorNavigatorProps) => {
  const { selected, onChange } = props;
  const { values } = useFormState({ subscription: { values: true } });

  const [taskGroups, errors] = useMemo(() => {
    const blueprint = values.json || {};

    const blueprintGroups: ITaskGroup[] = blueprint.task_groups?.items || [];
    const defaultTaskGroup = blueprint.task_groups?.default_group || undefined;

    /*
     * we need to track the index of the task in the original array for the
     * tree navigation keys (original index gets lost when tasks get grouped)
     */
    const tasks: IEditorTask[] = (blueprint.automated_tasks || []).map(
      (blueprintTask: any, index: number) => {
        const task = fromBlueprintTask(blueprintTask, 1);

        return {
          ...task,
          index,
        };
      }
    );

    const groupsResult = toTaskGroups(blueprintGroups, tasks, defaultTaskGroup);

    const blueprintErrors: BlueprintErrors = validateBlueprint(blueprint);

    return [groupsResult, blueprintErrors];
  }, [values.json]);

  if (isEmpty(errors)) {
    return (
      <BlueprintEditorNavigatorTree
        selected={selected}
        onChange={onChange}
        taskGroups={taskGroups}
      />
    );
  }

  return (
    <Tabs>
      <Tabs.Tab id="tasks" label="Tasks">
        <BlueprintEditorNavigatorTree
          selected={selected}
          onChange={onChange}
          taskGroups={taskGroups}
          errors={errors}
          showErrorIcons
        />
      </Tabs.Tab>
      <Tabs.Tab id="errors" label="Errors">
        <BlueprintEditorNavigatorTree
          selected={selected}
          onChange={onChange}
          taskGroups={taskGroups}
          errors={errors}
          showErrorNodes
          hideValidNodes
        />
      </Tabs.Tab>
    </Tabs>
  );
};
