import { createField, type IFieldInputProps, type IInputProps, Input } from '@mortgagehippo/ds';
import { Logic } from '@mortgagehippo/tasks';
import { isPresent } from '@mortgagehippo/util';
import { memoize } from 'lodash-es';

const logic = new Logic();
const validate = memoize((value: any) => {
  if (isPresent(value)) {
    const error = logic.validate(value);
    if (isPresent(error)) {
      return error;
    }
  }
  return undefined;
});

const LogicFieldInput = (props: IInputProps & IFieldInputProps) => <Input.Input {...props} />;

export const LogicField: any = createField(LogicFieldInput, undefined, {
  validate,
});
