import { Choice, Input, Tabs, USZipCodeMode } from '@mortgagehippo/ds';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { FieldEditorFrame } from './field-editor-frame';

export const USZipCodeEditor = ({ name }: { name: string }) => (
  <FieldEditorFrame name={name}>
    {() => (
      <Tabs.Tab id="us-zip-code" label="US Zip Options">
        <EditorTabWrapper size="md">
          <Input.Box name={`${name}.placeholder`} label="Placeholder" />
          <Choice.Box
            name={`${name}.mode`}
            label="Mode"
            options={[
              {
                label: 'Any Valid ZIP',
                value: USZipCodeMode.ANY,
              },
              {
                label: 'Five-Digit ZIP',
                value: USZipCodeMode.FIVE_DIGITS,
              },
              {
                label: 'ZIP+4',
                value: USZipCodeMode.NINE_DIGITS,
              },
            ]}
          />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
  </FieldEditorFrame>
);
