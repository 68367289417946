import { type FormSubmitHandler } from '@mortgagehippo/ds';
import { useMemo } from 'react';

import { NotificationModal } from './notification-modal';
import { type INotificationProfile, useNotificationProfile } from './use-notification-profile';

const toInitialValues = (data: INotificationProfile) => {
  const { name, typeName, args } = data;

  let initialArgs;
  try {
    initialArgs = JSON.stringify(args, null, 4);
  } catch {
    //
  }

  return {
    name,
    type_name: typeName,
    data: {
      ...data.rawData,
    },
    args: initialArgs,
  };
};

interface INotificationsModalContainerProps {
  title: string;
  partnerId: string;
  siteId?: string;
  profileId?: string;
  onRequestClose: () => void;
  onSubmit: FormSubmitHandler;
  isOpen: boolean;
}

export const NotificationModalContainer = (props: INotificationsModalContainerProps) => {
  const { title, partnerId, siteId, profileId, onSubmit, onRequestClose, isOpen } = props;
  const editing = profileId !== undefined;

  const [data, loading] = useNotificationProfile(`${profileId}`, partnerId, siteId, {
    skip: !profileId,
  });

  const initialValues = useMemo(() => {
    if (data) {
      return toInitialValues(data);
    }

    return {};
  }, [data]);

  return (
    <NotificationModal
      title={title}
      loading={loading}
      onSubmit={onSubmit}
      onRequestClose={onRequestClose}
      initialValues={initialValues}
      editing={editing}
      isOpen={isOpen}
    />
  );
};
