import { HelpButton, List, spacing, Title } from '@mortgagehippo/ds';
import { type ReactNode } from 'react';
import styled from 'styled-components';

import { type ISmartviewDocumentationField } from './use-smartview-documentation';

const StyledList = styled(List)`
  & li {
    margin-bottom: ${spacing(1)};
  }
`;

interface IPipelineDocumentationModalListProps {
  title: string;
  fields: ISmartviewDocumentationField[];
  tooltip?: ReactNode;
}

export const PipelineDocumentationModalList = (props: IPipelineDocumentationModalListProps) => {
  const { title, fields, tooltip } = props;

  if (!fields.length) {
    return null;
  }

  return (
    <>
      <div>
        <Title level={3}>
          {title}
          {tooltip ? (
            <HelpButton size="xs" icon="information">
              {tooltip}
            </HelpButton>
          ) : null}
        </Title>
      </div>

      <StyledList>
        {fields.map((field) => (
          <li>{field.name}</li>
        ))}
      </StyledList>
    </>
  );
};
