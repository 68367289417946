import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo/gql';

export const MMovePageComponentDownMutation = graphql(`
  mutation MovePageComponentDown($pageComponentId: ID!) {
    movePageComponentDown(pageComponentId: $pageComponentId) {
      ...PageComponentFragment
    }
  }
`);

export const useMovePageComponentDown = () => {
  const movePageComponentDown = useMutation(MMovePageComponentDownMutation, undefined);

  return async (pageComponentId: string) => {
    await movePageComponentDown({ pageComponentId });
  };
};
