import { YesNo } from '@mortgagehippo/ds';
import { type ReactNode } from 'react';

export interface FieldValidationEditorProps {
  name: string;
  children?: ReactNode;
}

export const FieldValidationEditor = (props: FieldValidationEditorProps) => {
  const { name, children } = props;
  return (
    <>
      <YesNo.Box name={`${name}.required`} label="Is this field required" />
      {children}
    </>
  );
};
