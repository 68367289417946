import { Select } from '@mortgagehippo/ds';

import { CodeEditorField } from '$components/code-editor';

import { generateColumnOptions } from './util';

const columnOptions = generateColumnOptions();

export const FeaturedRatesFields = () => (
  <>
    <Select.Box name="config.columns" label="Columns" options={columnOptions} />
    <CodeEditorField.Box mode="scss" name="config.css" label="Custom SCSS" />
  </>
);
