import {
  type IPipelineColumn,
  type IPipelineExportField,
  type IPipelineSearchField,
} from './types';

export const SAMPLE_COLUMNS_JSON: IPipelineColumn[] = [
  {
    fields: 'start_date',
    defaultSort: 'desc',
  },
  {
    fields: 'full_name',
    title: 'Borrower Name',
  },
  {
    fields: 'email',
  },
];

export const SAMPLE_SEARCH_FIELDS_JSON: IPipelineSearchField[] = [
  {
    field: 'id',
  },
  {
    field: 'first_name',
  },
  {
    field: 'last_name',
  },
  {
    field: 'email',
  },
  {
    field: 'mobile_phone_number',
    label: 'Cell phone',
  },
];

export const SAMPLE_EXPORT_FIELDS_JSON: IPipelineExportField[] = [
  {
    field: 'start_date',
  },
  {
    field: 'full_name',
    title: 'Borrower Name',
  },
  {
    field: 'email',
  },
  {
    field: 'site_name',
  },
];
