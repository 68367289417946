import { ForbiddenError, InvalidTimeError } from '@mortgagehippo/auth';
import { Button, fontSize, lineHeight, palette, spacing } from '@mortgagehippo/ds';
import { type IErrorComponentProps } from '@mortgagehippo/util';
import styled from 'styled-components';

import { auth } from '../../services/auth';

const Container = styled.div`
  width: 100%;
  max-width: 500px;
  height: 400px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  svg#image {
    width: 90px;
    fill: ${palette('white')};
    opacity: 0.5;
  }

  h1 {
    margin-top: ${spacing(4)};
    color: ${palette('white')};
    font-size: ${fontSize('xxl')};
    line-height: ${lineHeight('xxl')};
    text-align: center;
  }

  p {
    color: ${palette('white')};
  }
`;

export const AuthErrorPage = (props: IErrorComponentProps) => {
  const { error } = props;

  let title = 'Authentication Failed';
  let message = `We could not verify your account credentials, possibly because your session expired. Please sign in again.`;
  let buttonLabel = 'Sign in again';

  if (error instanceof InvalidTimeError) {
    title = 'Clock out of sync';
    message = `It looks like your computer or mobile device's clock is out of sync with the current time. You can fix this by opening your system settings and adjusting the time and date to match the correct one.`;
    buttonLabel = 'Sign in again';
  }

  if (error instanceof ForbiddenError) {
    title = 'You Must Sign In';
    message = 'Please sign in order to continue.';
    buttonLabel = 'Sign in';
  }

  const handleLogin = () => {
    auth.login(error instanceof ForbiddenError ? window.location.pathname : undefined);
  };

  return (
    <Container>
      <svg id="image" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <title>key</title>
        <g>
          <path d="M61.01,1.99h-12a1,1,0,0,0-.7.291L24.23,26.257A18.113,18.113,0,1,0,37.741,39.764l3.984-4.075a1,1,0,0,0,.285-.7v-7h7a1,1,0,0,0,1-1v-7h7a1,1,0,0,0,.707-.293l4-4a1,1,0,0,0,.293-.707v-12A1,1,0,0,0,61.01,1.99Zm-42.5,49a5.5,5.5,0,1,1,5.5-5.5A5.5,5.5,0,0,1,18.51,50.99Z" />
        </g>
      </svg>

      <h1>{title}</h1>
      <p>{message}</p>
      <Button
        onClick={handleLogin}
        importance="primary"
        icon="right-arrow"
        iconLocation="right"
        size="normal"
        inverted
      >
        {buttonLabel}
      </Button>
    </Container>
  );
};
