import { type ReactNode } from 'react';
import { type RouteComponentProps } from 'react-router';

import { usePartner } from '../../hooks/use-partner';
import { Content, Layout } from '../../layouts/main';
import { SagasPageContent } from './sagas-page-content';
import { SagasPageLoading } from './sagas-page-loading';
import { usePartnerSagas } from './use-partner-sagas';

interface ISagasPageParams {
  partnerId: string;
  siteId: string;
}

type ISagasPageProps = RouteComponentProps<ISagasPageParams>;

export const SagasPartnerPage = (props: ISagasPageProps) => {
  const { match } = props;
  const { params } = match;
  const { partnerId } = params;
  const [partner] = usePartner(partnerId);
  const [{ sagas, ruleSetId }, sagasLoading] = usePartnerSagas(partnerId);

  const subTitle = partner?.name || '';
  let contentEl: ReactNode;

  if (sagasLoading || !sagas || !ruleSetId) {
    contentEl = <SagasPageLoading />;
  } else {
    contentEl = <SagasPageContent ruleSetId={ruleSetId} sagas={sagas} partnerId={partnerId} />;
  }

  return (
    <Layout>
      <Content title="Sagas" subTitle={subTitle}>
        {contentEl}
      </Content>
    </Layout>
  );
};
