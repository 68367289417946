import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo/gql';
import { type ScheduleServiceJobInput } from '../../../apollo/graphql';

export const MScheduleServiceJobMutation = graphql(`
  mutation ScheduleServiceJob($data: ScheduleServiceJobInput!) {
    scheduleServiceJob(data: $data) {
      id
    }
  }
`);

export const useScheduleServiceJob = () => {
  const scheduleServiceJob = useMutation(MScheduleServiceJobMutation, undefined);

  return useCallback(
    async (data: ScheduleServiceJobInput) => {
      const response = await scheduleServiceJob({ data });
      return response.data!.scheduleServiceJob?.id;
    },
    [scheduleServiceJob]
  );
};
