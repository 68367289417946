import { type RouteComponentProps } from 'react-router';

import { usePartner } from '../../../hooks/use-partner';
import { Content, Layout } from '../../../layouts/main';
import { PartnerUnassignedApplicationNotificationsContactsPageContent } from './partner-unassigned-application-notifications-contacts-page-content';

interface IPartnerUnassignedApplicationNotificationsContactsPageRouteParams {
  partnerId: string;
}

type IPartnerUnassignedApplicationNotificationsContactsPageProps =
  RouteComponentProps<IPartnerUnassignedApplicationNotificationsContactsPageRouteParams>;

export const PartnerUnassignedApplicationNotificationsContactsPage = (
  props: IPartnerUnassignedApplicationNotificationsContactsPageProps
) => {
  const { match } = props;
  const { params } = match;
  const { partnerId } = params;
  const [partner] = usePartner(partnerId);

  const partnerName = partner?.name || '';

  if (!partnerName) {
    return null;
  }

  return (
    <Layout pageTitle={`${partnerName} - Unassigned Application Notifications Contacts`}>
      <Content title="Unassigned Application Notifications Contacts" subTitle={partnerName}>
        <PartnerUnassignedApplicationNotificationsContactsPageContent partnerId={partnerId} />
      </Content>
    </Layout>
  );
};
