import { FormSection, Input, Tabs } from '@mortgagehippo/ds';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { ArrayItemButtonsEditor } from './array-item-buttons-editor';
import { ArrayItemPreviewEditor } from './array-item-preview-editor';
import { GroupsEditor } from './groups-editor';
import { TitleEditor } from './title-editor';

export const SwitchArrayVariantEditor = ({ name }: { name: string }) => (
  <Tabs>
    <Tabs.Tab id="discriminator" label="Discriminator Value">
      <EditorTabWrapper size="md">
        <Input.Box name={`${name}.discriminator_value`} label="Discriminator Value" required />
      </EditorTabWrapper>
    </Tabs.Tab>
    <Tabs.Tab id="title" label="Label/Description">
      <EditorTabWrapper size="md">
        <TitleEditor name={name} />
      </EditorTabWrapper>
    </Tabs.Tab>
    <Tabs.Tab id="item" label="Array Item">
      <EditorTabWrapper size="md">
        <FormSection
          title="Array Item Previews"
          description="This defines how the items are going to look in their collapsed state, all values are hippo logic expressions"
        >
          <ArrayItemPreviewEditor name={name} />
        </FormSection>

        <FormSection
          title="Array Item Buttons"
          description="This fields represent the label for the buttons on the item form"
        >
          <ArrayItemButtonsEditor name={name} />
        </FormSection>
      </EditorTabWrapper>
    </Tabs.Tab>
    <Tabs.Tab id="fields" label="Fields">
      <EditorTabWrapper size="md">
        <GroupsEditor name={`${name}.groups`} />
      </EditorTabWrapper>
    </Tabs.Tab>
  </Tabs>
);
