import { CascadeConfigurationsTable } from '$components/cascade-configurations';

interface IPartnerCascadesPageContentProps {
  partnerId: string;
  loading: boolean;
}

export const PartnerCascadesPageContent = (props: IPartnerCascadesPageContentProps) => {
  const { partnerId, loading } = props;

  return <CascadeConfigurationsTable partnerId={partnerId} loading={loading} />;
};
