import { graphql } from '../../apollo/gql';

export const FLandingPageFragment = graphql(`
  fragment LandingPageFragment on LandingPage {
    id
    title
    path
    type
    siteId
    createdAt
    updatedAt
  }
`);

export const FPageComponentFragment = graphql(`
  fragment PageComponentFragment on PageComponent {
    id
    typeName
    landingPageId
    config
    resources
    position
    createdAt
    updatedAt
  }
`);
