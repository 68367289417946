import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo/gql';
import { type BasicPartnerInput } from '../../../apollo/graphql';

const MUpdatePartnerMutation = graphql(`
  mutation UpdatePartner($partnerId: ID!, $data: BasicPartnerInput!) {
    updatePartner(partnerId: $partnerId, data: $data)
  }
`);

export const useUpdatePartner = () => {
  const updatePartner = useMutation(MUpdatePartnerMutation, undefined);

  return async (partnerId: string, data: BasicPartnerInput): Promise<string> => {
    const response = await updatePartner({ partnerId, data });

    return response.data!.updatePartner;
  };
};
