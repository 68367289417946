import { Input, Tabs } from '@mortgagehippo/ds';
import { useCallback, useEffect, useState } from 'react';

import { EditorField } from '$components/editor-field';
import { LogicField } from '$components/logic-field';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { JsonPreview } from '../json-preview';
import { GroupsEditor } from './groups-editor';
import { PanelPreview } from './panel-preview';
import { ValidationsEditor } from './validations-editor';

export const PanelEditor = ({ name, tabId }: { name: string; tabId?: 'fields' }) => {
  const [currentTabId, setCurrentTabId] = useState<string>(tabId || 'details');
  /*
   * onChange is defined to force the tab to change the selected tab
   * when tabId is passed and the tabs component is already loaded
   */
  const handleChange = useCallback((id: string) => {
    setCurrentTabId(id);
  }, []);

  useEffect(() => {
    setCurrentTabId(tabId || 'details');
  }, [tabId]);

  return (
    <Tabs initialSelected={tabId} selected={currentTabId} onChange={handleChange}>
      <Tabs.Tab id="details" label="Details">
        <EditorTabWrapper size="md">
          <Input.Box name={`${name}.title`} label="Panel Title" />
          <EditorField.Box name={`${name}.title_tooltip`} label="Panel Title tooltip" />
          <Input.Box name={`${name}.description`} label="Panel Explanation" />
          <EditorField.Box name={`${name}.description_tooltip`} label="Panel Explanation tooltip" />
          <EditorField.Box
            key={`${name}.content`}
            name={`${name}.content`}
            label="Content"
            description="Arbitrary content to be added to the top of the panel. You can use variable {{site.name}}"
          />
        </EditorTabWrapper>
      </Tabs.Tab>

      <Tabs.Tab id="buttons" label="Buttons">
        <EditorTabWrapper size="md">
          <Input.Box
            name={`${name}.previous_button_label`}
            label="Previous button label"
            placeholder="Previous"
          />
          <Input.Box
            name={`${name}.next_button_label`}
            label="Continue / Complete button label"
            description="Default values: 'Continue'; if last panel: 'Complete'"
            placeholder="Continue"
          />
          <Input.Box
            name={`${name}.next_button_label_when_completed`}
            label="Continue / Complete button label if the task has been completed"
            description="Same as above but only if the task has already been completed"
            placeholder="Update"
          />
        </EditorTabWrapper>
      </Tabs.Tab>

      <Tabs.Tab id="fields" label="Fields">
        <EditorTabWrapper>
          <GroupsEditor name={`${name}.groups`} />
        </EditorTabWrapper>
      </Tabs.Tab>

      <Tabs.Tab id="validation" label="Validations">
        <EditorTabWrapper>
          <ValidationsEditor name={`${name}.validations`} />
        </EditorTabWrapper>
      </Tabs.Tab>

      <Tabs.Tab id="logic" label="Logic">
        <EditorTabWrapper>
          <LogicField.Box name={`${name}.showIf`} label="Show if" />
        </EditorTabWrapper>
      </Tabs.Tab>

      <Tabs.Tab id="preview" label="Preview">
        <EditorTabWrapper>
          <PanelPreview />
        </EditorTabWrapper>
      </Tabs.Tab>

      <Tabs.Tab id="json" label="JSON">
        <EditorTabWrapper>
          <JsonPreview />
        </EditorTabWrapper>
      </Tabs.Tab>
    </Tabs>
  );
};
