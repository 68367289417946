import { Tabs } from '@mortgagehippo/ds';

import { EditorField } from '$components/editor-field';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { FieldEditorFrame } from './field-editor-frame';

export const ContentEditor = ({ name }: { name: string }) => (
  <FieldEditorFrame name={name} disableTitleTab>
    {() => (
      <Tabs.Tab id="content" label="Content Options">
        <EditorTabWrapper size="md">
          <EditorField.Box
            key={`${name}.content`}
            name={`${name}.content`}
            label="Content"
            description="You can use field {{site.name}}"
            extended
          />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
  </FieldEditorFrame>
);
