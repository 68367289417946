import { useQuery } from '@mortgagehippo/apollo-hooks';
import { isPresent } from '@mortgagehippo/util';
import { type ApolloQueryResult } from 'apollo-client';
import { compact, sortBy } from 'lodash-es';
import { useMemo } from 'react';

import { QCustomizationEditorQuery } from '$components/customizations-editor/queries';

import {
  type CustomizationResourceFragmentFragment as ICustomizationResource,
  type CustomizationValueFragmentFragment as ICustomizationValue,
} from '../../../apollo/graphql';
import { type ICustomizationsEditorResource } from '../types';

type UseCustomizationEditorResourcesResponse = [
  ICustomizationsEditorResource[],
  boolean,
  (variables?: any) => Promise<ApolloQueryResult<any>>,
];

interface IUseCustomizationEditorResourcesParam {
  projectId: string;
  partnerId: number;
  domain?: string | null;
  type?: string;
  language?: string;
  overriddenOnly?: boolean;
  notifyOnNetworkStatusChange?: boolean;
  namespace?: string;
  key?: string;
  cacheFirst?: boolean;
}

export const useCustomizationEditorResources = ({
  projectId,
  partnerId,
  domain,
  language,
  overriddenOnly = false,
  notifyOnNetworkStatusChange = true,
  namespace,
  key,
  cacheFirst,
  type,
}: IUseCustomizationEditorResourcesParam): UseCustomizationEditorResourcesResponse => {
  const currentSource = isPresent(domain) ? 'site' : 'partner';

  const variables = {
    projectId,
    partnerId,
    domain,
    language,
    namespace,
    type,
    key,
  };

  const [customizationsData, customizationsDataLoading, , { refetch }] = useQuery(
    QCustomizationEditorQuery,
    { ...variables },
    {
      fetchPolicy: cacheFirst ? 'cache-first' : 'network-only',
      notifyOnNetworkStatusChange,
    }
  );

  const handleRefetch = () => refetch({ ...variables });

  const result: ICustomizationsEditorResource[] = useMemo(() => {
    const customizationResources: ICustomizationResource[] =
      customizationsData?.customizationResources || [];
    const customizationValues: ICustomizationValue[] =
      customizationsData?.customizationValues || [];

    const resources = compact(
      customizationResources
        .filter((resource) => {
          if (resource.namespace.includes('landing-page')) {
            // filter out all landing page resources.
            return false;
          }

          return true;
        })
        .map((resource) => {
          const value = customizationValues.find(
            (v) => resource.namespace === v.namespace && resource.key === v.key
          );
          const isOverridden = !!value && value.source === currentSource;

          if (overriddenOnly && !isOverridden) {
            return undefined;
          }

          return {
            ...resource,
            value: value || null,
            isOverridden,
          };
        })
    );

    return sortBy(resources, [(r) => `${r.namespace}:${r.key}`]);
  }, [currentSource, customizationsData, overriddenOnly]);

  return [result, customizationsDataLoading, handleRefetch];
};
