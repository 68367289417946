import { FormSection, Input, type ISelectOption, Select, YesNo } from '@mortgagehippo/ds';

const verificationTypeOptions: ISelectOption[] = [
  {
    label: 'Employment Income',
    value: 'employment-income',
  },
  {
    label: 'Employment',
    value: 'employment',
  },
];

const useCaseOptions: ISelectOption[] = [
  {
    label: 'Mortgage',
    value: 'mortgage',
  },
  {
    label: 'Home Equity',
    value: 'home-equity',
  },
];

export const TrueworkFields = () => (
  <>
    <FormSection title="Sandbox API Keys">
      <Input.Box name="sandbox_api_key" label="Truework Sandbox API Key" />
      <Input.Box name="sandbox_publishable_key" label="TrueworkJS Sandbox Publishable Key" />
    </FormSection>
    <FormSection title="Production API Keys">
      <Input.Box name="production_api_key" label="Truework Production API Key" />
      <Input.Box name="production_publishable_key" label="TrueworkJS Publishable Key" />
    </FormSection>
    <Select.Box
      name="verification_type"
      options={verificationTypeOptions}
      label="Verification Type"
      required
    />
    <Select.Box name="use_case" options={useCaseOptions} label="Use Case" required />
    <YesNo.Box name="production_mode" label="Production Mode" required />
  </>
);
