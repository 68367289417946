import {
  Dropdown,
  type IButtonProps,
  type IDropdownActionHandler,
  type IDropdownActionList,
} from '@mortgagehippo/ds';
import { isPromise } from '@mortgagehippo/util';
import { sortBy } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';

import { type LandingPageType, type PageComponentType } from '../../../apollo/graphql';
import { PageComponentEditorConfig } from './types';
import { getEditorConfig } from './util';

interface IPageComponentDropdownProps {
  landingPageType: LandingPageType;
  onClick?: ((type: PageComponentType) => Promise<any>) | ((type: PageComponentType) => void);
  label: string;
  buttonProps?: IButtonProps;
}

export const PageComponentDropdown = (props: IPageComponentDropdownProps) => {
  const { landingPageType, label, onClick, buttonProps: _buttonProps } = props;
  const [loading, setLoading] = useState(false);

  const handleAction = useCallback<IDropdownActionHandler>(
    async (_key, _data, actionData) => {
      if (onClick) {
        const result = onClick(actionData);

        if (isPromise(result)) {
          setLoading(true);

          await result;

          setLoading(false);
        }
      }
    },
    [onClick]
  );

  const buttonProps: IButtonProps = {
    ..._buttonProps,
    loading,
  };

  const typeOptions = useMemo(() => {
    const result: IDropdownActionList = [];
    Object.keys(PageComponentEditorConfig).forEach((type) => {
      const config = getEditorConfig(type as PageComponentType);

      if (config.allowedPages.includes(landingPageType)) {
        result.push({
          key: type,
          label: config.name,
          data: type,
        });
      }
    });

    return sortBy(result, ['label']);
  }, [landingPageType]);

  return (
    <Dropdown
      label={label}
      onAction={handleAction}
      actions={typeOptions}
      buttonProps={buttonProps}
    />
  );
};
