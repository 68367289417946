import { useMutation } from '@mortgagehippo/apollo-hooks';
import { Format, notifications, useModal } from '@mortgagehippo/ds';
import {
  type IQueryTableColumns,
  type IQueryTableDataActions,
  QueryTable,
} from '@mortgagehippo/query-components';
import { UnreachableCaseError } from '@mortgagehippo/util';
import { useCallback, useMemo, useRef, useState } from 'react';
import { type RouteComponentProps } from 'react-router';

import {
  type PartnerAuthRolesListQuery,
  type PartnerAuthRolesListQueryVariables,
} from '../../apollo/graphql';
import { usePartner } from '../../hooks/use-partner';
import { Content, Layout } from '../../layouts/main';
import { AuthRoleModalContainer } from './auth-role-modal-container';
import { MDeleteAuthRoleMutation, QPartnerAuthRolesList } from './queries';

enum AuthRolesActionType {
  EDIT = 'edit',
  DELETE = 'delete',
}

const columns: IQueryTableColumns<IAuthRole> = [
  {
    title: 'Name',
    key: 'name',
    render: (t) => t.name,
  },
  {
    title: 'Description',
    key: 'description',
    render: (t) => t.description,
  },
  {
    title: 'Created at',
    key: 'createdAt',
    render: ({ createdAt }) => <Format.Date value={createdAt || ''} format="date-long-time" />,
  },
];

export type IAuthRole = NonNullable<PartnerAuthRolesListQuery['partner']>['authRoles']['items'][0];

interface IAuthRolesPageRouteParams {
  partnerId: string;
}

type IAuthRolesPageProps = RouteComponentProps<IAuthRolesPageRouteParams>;

export const AuthRolesPage = (props: IAuthRolesPageProps) => {
  const { match } = props;
  const { params } = match;
  const { partnerId } = params;
  const [partner] = usePartner(partnerId);
  const [modalOpen, openModal, closeModal] = useModal(false);
  const [selectedAuthRoleId, setSelectedAuthRoleId] = useState<string | undefined>(undefined);
  const tableRef = useRef<any>();

  const deleteAuthRole = useMutation(MDeleteAuthRoleMutation);

  const handleAdd = useCallback(() => {
    setSelectedAuthRoleId(undefined);
    openModal();
  }, [openModal]);

  const handleClickRow = useCallback(
    (record: IAuthRole) => {
      setSelectedAuthRoleId(record.id);
      openModal();
    },
    [openModal]
  );

  const handleClose = useCallback(() => {
    closeModal(() => {
      setSelectedAuthRoleId(undefined);
    });
  }, [closeModal]);

  const handleSubmit = useCallback(() => {
    if (tableRef) {
      tableRef!.current.refetch();
    }

    handleClose();
  }, [tableRef, handleClose]);

  const handleDelete = useCallback(
    async (record: IAuthRole) => {
      try {
        await deleteAuthRole({ authRoleId: record.id });

        notifications.success({ message: 'The access level was deleted successfully.' });

        tableRef.current.refetch();
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [deleteAuthRole]
  );

  const handleAction = useCallback(
    async (actionKey: string, record: IAuthRole) => {
      const actionType = actionKey as AuthRolesActionType;
      switch (actionType) {
        case AuthRolesActionType.EDIT:
          handleClickRow(record);
          break;
        case AuthRolesActionType.DELETE:
          await handleDelete(record);
          break;
        default:
          throw new UnreachableCaseError(actionType);
      }
    },
    [handleClickRow, handleDelete]
  );

  const actions: IQueryTableDataActions<IAuthRole> = useMemo(
    () => [
      {
        key: 'options',
        label: 'Options',
        buttonProps: {
          icon: 'menu-dots',
        },
        onGroupAction: handleAction,
        actions: [
          {
            key: AuthRolesActionType.EDIT,
            label: 'Edit',
            iconProps: {
              name: 'edit',
            },
          },
          {
            key: AuthRolesActionType.DELETE,
            label: 'Delete',
            iconProps: {
              name: 'delete',
            },
            confirm: {
              title: 'Warning',
              explanation:
                'Are you sure you want to delete this Access Level? This action cannot be undone.',
              type: 'warning',
            },
          },
        ],
      },
    ],
    [handleAction]
  );

  const topActions: IQueryTableDataActions<IAuthRole> = useMemo(
    () => [
      {
        key: 'add-access-level',
        label: 'Add access level',
        buttonProps: {
          icon: 'plus',
        },
        onAction: handleAdd,
      },
    ],
    [handleAdd]
  );

  const partnerName = partner?.name || '';

  return (
    <Layout pageTitle={`${partnerName} - Access Levels`}>
      <Content title="Access Levels" subTitle={partnerName}>
        <QueryTable<PartnerAuthRolesListQuery, PartnerAuthRolesListQueryVariables>
          caption="Auth Roles"
          query={QPartnerAuthRolesList}
          dataSource={(result) => (result.partner ? result.partner.authRoles.items : [])}
          itemTotal={(result) => (result.partner ? result.partner.authRoles.total : 0)}
          nextCursor={(result) => result.partner?.authRoles.nextCursor}
          previousCursor={(result) => result.partner?.authRoles.previousCursor}
          rowKey={(item) => item.id}
          columns={columns}
          variables={{ partnerId }}
          rowActions={actions}
          onRowClick={handleClickRow}
          ref={tableRef}
          topActions={topActions}
          bottomContent={<QueryTable.Pagination />}
          hideTopBackground
          alwaysShowRowActions
        >
          <QueryTable.Data />
        </QueryTable>
        <AuthRoleModalContainer
          partnerId={partnerId}
          authRoleId={selectedAuthRoleId}
          isOpen={modalOpen}
          onSubmit={handleSubmit}
          onRequestClose={handleClose}
        />
      </Content>
    </Layout>
  );
};
