import { Modal } from '@mortgagehippo/ds';

import { SnapdocsSubscriptionsEditor } from './snapdocs-subscriptions-editor';

interface ISnapdocsSubscriptionsModalProps {
  partnerId: string;
  siteId?: string;
  onRequestClose: () => void;
  onOk: () => void;
  isOpen: boolean;
}

export const SnapdocsSubscriptionsModal = (props: ISnapdocsSubscriptionsModalProps) => {
  const { partnerId, siteId, ...rest } = props;

  return (
    <Modal
      title="Manage Snapdocs Subscriptions"
      width={1300}
      compact
      hideCancelButton
      hideOkButton
      {...rest}
    >
      <SnapdocsSubscriptionsEditor partnerId={partnerId} siteId={siteId} />
    </Modal>
  );
};
