import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo/gql';
import { type CreatePartnerDocumentsMutation, type DocumentInput } from '../apollo/graphql';

export type CreatePartnerDocumentsMutationPartnerDocument = NonNullable<
  CreatePartnerDocumentsMutation['createPartnerDocuments']
>[0];

export const MCreatePartnerDocuments = graphql(`
  mutation CreatePartnerDocuments($partnerId: ID!, $documents: [DocumentInput!]!) {
    createPartnerDocuments(partnerId: $partnerId, documents: $documents) {
      id
      description
      filename
      size
    }
  }
`);

export const useCreatePartnerDocuments = () => {
  const createPartnerDocuments = useMutation(MCreatePartnerDocuments);
  return async (
    partnerId: string,
    documents: DocumentInput[]
  ): Promise<CreatePartnerDocumentsMutationPartnerDocument[] | null> => {
    const response = await createPartnerDocuments({
      partnerId,
      documents,
    });
    return response.data?.createPartnerDocuments || null;
  };
};
