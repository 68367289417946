import { Tabs } from '@mortgagehippo/ds';

import { ValidationsEditor } from '../data-gathering-task/validations-editor';
import { EditorTabWrapper } from '../editor-tab-wrapper';
import { TaskEditorFrame } from '../task-editor-frame';

export const EmployersTaskEditor = ({ name }: { name: string }) => (
  <TaskEditorFrame name={name}>
    {() => (
      <Tabs.Tab id="validations" label="Validations">
        <EditorTabWrapper>
          <ValidationsEditor name={`${name}.data.panel.validations`} />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
  </TaskEditorFrame>
);
