import 'antd/es/breadcrumb/style/css.js';
import 'antd/es/tree/style/css.js';

import {
  Alert,
  Button,
  Card,
  createForm,
  Form,
  FormSpy,
  palette,
  spacing,
  type UploadHandler,
} from '@mortgagehippo/ds';
import { Affix } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Content, Layout } from '../../layouts/main';
import { AnswersDocumentation } from './answers-documentation';
import { BlueprintEditorForm } from './blueprint-editor-form';
import { BlueprintEditorNavigator } from './blueprint-editor-navigator';
import { EditorContext } from './editor-context';
import { isValidBlueprint } from './validator';

const Container = styled.div`
  display: flex;
`;

const EditorContainer = styled.div`
  flex: 1;
  background: ${palette('neutral50')};
  position: relative;
  z-index: 10;
  padding-left: ${spacing(4)};
`;

const NavigationContainer = styled.aside`
  flex: 0;
  flex-basis: 440px;
  max-width: 440px;
  margin: 0;
`;

const NavigationCard = styled(Card)`
  max-height: calc(100vh - ${spacing(2)} * 2);
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: ${spacing(2)};
`;

export interface IBlueprintEditorPageProps {
  partnerName: string;
  blueprintName: string;
  initialValues: any;
  onSave: (blueprint: any) => Promise<any>;
  onUploadDocument: UploadHandler;
  editMode?: boolean;
}

export const BlueprintEditorPage = (props: IBlueprintEditorPageProps) => {
  const { partnerName, blueprintName, initialValues, onSave, onUploadDocument, editMode } = props;
  const [path, setPath] = useState<string>('');
  const [saving, setSaving] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handlePush = useCallback((nextPath: string, index?: number) => {
    setPath(`${nextPath}[${index}]`);
    document.documentElement.scrollTop = 0;
    return undefined;
  }, []);

  const handleSelect = useCallback((nextPath: string) => {
    setPath(nextPath);
    document.documentElement.scrollTop = 0;
  }, []);

  const form = useMemo(
    () =>
      createForm<any>({
        initialValues,
        onSubmit: () => undefined,
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleFormChange = () => {
    if (hasError) {
      const { values } = form.getState();

      const blueprint = values.json || {};

      if (isValidBlueprint(blueprint)) {
        setHasError(false);
      }
    }
  };

  const handleSave = async () => {
    const { values } = form.getState();

    const blueprint = values.json || {};

    if (!isValidBlueprint(blueprint)) {
      setHasError(true);
      return;
    }

    setSaving(true);
    try {
      await onSave(values);
    } finally {
      setSaving(false);
    }
  };

  const contextValue = useMemo(
    () => ({
      onArrayItem: handlePush,
      path,
    }),
    [handlePush, path]
  );

  return (
    <Layout>
      <Content
        title={blueprintName}
        subTitle={partnerName}
        actions={
          <>
            <Button
              importance="primary"
              size="sm"
              disabled={saving}
              loading={saving}
              onClick={handleSave}
            >
              Save blueprint
            </Button>
            <AnswersDocumentation />
          </>
        }
      >
        <Form as="div" form={form} onSubmit={handleSave}>
          <FormSpy onChange={handleFormChange} subscription={{ values: true }} />
          {hasError ? <Alert>Please check the blueprint for errors.</Alert> : null}
          <Container>
            <NavigationContainer>
              <Affix>
                <NavigationCard raised={1}>
                  <BlueprintEditorNavigator selected={path} onChange={handleSelect} />
                </NavigationCard>
              </Affix>
            </NavigationContainer>
            <EditorContainer>
              <EditorContext.Provider value={contextValue}>
                <BlueprintEditorForm
                  path={path}
                  editMode={editMode}
                  onUploadDocument={onUploadDocument}
                  key={path}
                />
              </EditorContext.Provider>
            </EditorContainer>
          </Container>
        </Form>
      </Content>
    </Layout>
  );
};
