import { type FormSubmitHandler, Input, ModalForm } from '@mortgagehippo/ds';
import { type ReactNode } from 'react';

interface ISagasPageContentCreateModalProps {
  title: string;
  initialValues?: any;
  validateName?: (value: any) => ReactNode | undefined | Promise<ReactNode | undefined>;
  onSubmit?: FormSubmitHandler;
  onRequestClose?: () => void;
  isOpen: boolean;
}

const defaultInitialValues = {};
export const SagasPageContentCreateModal = (props: ISagasPageContentCreateModalProps) => {
  const { initialValues = defaultInitialValues, validateName, ...rest } = props;

  return (
    <ModalForm disableOverlayClickClose initialValues={initialValues} {...rest}>
      <Input.Box name="name" label="Name" required validate={validateName} />
    </ModalForm>
  );
};
