import { type IEditorSagaArgument } from '../types';

interface IArgumentsModalItemLabelProps {
  item: IEditorSagaArgument;
}

export const ArgumentsModalItemLabel = (props: IArgumentsModalItemLabelProps) => {
  const { item } = props;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{item.name}</>;
};
