import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../apollo/gql';

export const MDeleteRegisteredIntegrationConnection = graphql(`
  mutation DeleteRegisteredIntegrationConnection($connectionId: ID!) {
    deleteRegisteredIntegrationConnection(connectionId: $connectionId) {
      id
    }
  }
`);

export const useDeleteRegisteredIntegrationConnection = () => {
  const fn = useMutation(MDeleteRegisteredIntegrationConnection);

  return useCallback(
    async (connectionId: string) => {
      const result = await fn({ connectionId });
      return result.data!.deleteRegisteredIntegrationConnection!.id;
    },
    [fn]
  );
};
