import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo/gql';
import { type CreateSiteInput } from '../apollo/graphql';

export const MCreateSiteMutation = graphql(`
  mutation CreateSite($partnerId: ID!, $data: CreateSiteInput!) {
    createSite(partnerId: $partnerId, data: $data)
  }
`);

export const useCreateSite = () => {
  const createSite = useMutation(MCreateSiteMutation);

  return async (partnerId: string, data: CreateSiteInput): Promise<string> => {
    const response = await createSite({
      partnerId,
      data,
    });
    return response.data!.createSite;
  };
};
