import { useQuery } from '@mortgagehippo/apollo-hooks';
import { type ISearchFilter } from '@mortgagehippo/ds';
import { useSafeCallback } from '@mortgagehippo/util';
import { useMemo } from 'react';

import {
  QPartnerAllAgents,
  QPartnerNotificationAlternateAgents,
  UNASSIGNED_APPLICATION_NOTIFICATION_TYPE,
} from './queries';
import { type NotificationAlternateAgent } from './unassigned-application-notifications-table';

type IUseUnassignedApplicationNotificationsAgentsResult = [
  NotificationAlternateAgent[],
  boolean,
  () => Promise<void>,
];

export const usePartnerNotificationAlternateAvailableAgents = (
  partnerId: string,
  searchCriteria?: ISearchFilter[],
  skip?: boolean
): IUseUnassignedApplicationNotificationsAgentsResult => {
  const [partnerAllAgentsData, partnerAllAgentsLoading, , { refetch: refetchPartnerAllAgents }] =
    useQuery(
      QPartnerAllAgents,
      {
        partnerId,
        searchCriteria: searchCriteria as any,
        paginationOptions: {
          orderBy: {
            name: 'asc',
          },
          perPage: 25,
        },
      },
      {
        skip,
      }
    );

  const [
    partnerAssignedAgentsData,
    partnerAssignedAgentsLoading,
    ,
    { refetch: refetchPartnerAssignedAgents },
  ] = useQuery(
    QPartnerNotificationAlternateAgents,
    {
      partnerId,
      notificationType: UNASSIGNED_APPLICATION_NOTIFICATION_TYPE,
    },
    {
      suspend: false,
      fetchPolicy: 'no-cache',
      skip,
    }
  );

  const handleRefresh = useSafeCallback(async () => {
    await refetchPartnerAllAgents({
      partnerId,
      searchCriteria: searchCriteria as any,
      paginationOptions: {
        orderBy: {
          name: 'asc',
        },
        perPage: 25,
      },
    });
    await refetchPartnerAssignedAgents({
      partnerId,
      notificationType: UNASSIGNED_APPLICATION_NOTIFICATION_TYPE,
    });
  });

  const loading = partnerAllAgentsLoading || partnerAssignedAgentsLoading;

  const result = useMemo((): NotificationAlternateAgent[] => {
    if (loading || !partnerAllAgentsData || !partnerAssignedAgentsData) {
      return [];
    }
    return (
      partnerAllAgentsData.partner?.agents.items.filter(
        (agent) =>
          !partnerAssignedAgentsData.partner?.notificationAlternateAgents.agents.some(
            (assignedAgent) => agent.id === assignedAgent.id
          )
      ) ?? []
    );
  }, [loading, partnerAllAgentsData, partnerAssignedAgentsData]);

  return [result, loading, handleRefresh];
};
