import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import {
  type ParentType,
  type WebhookConfigurationQuery,
} from '../../../apollo/graphql';
import { QWebhookConfiguration } from '../queries';

export type IWebhookConfiguration = NonNullable<WebhookConfigurationQuery['webhookConfiguration']>;

export interface IGetWebhookConfiguration {
  parentId: string;
  parentType: ParentType;
  partnerId: string;
}

export const useWebhookConfiguration = (params: IGetWebhookConfiguration) => {
  const { parentId, parentType, partnerId } = params;

  const [data, loading, , { refetch: refetchData }] = useQuery(
    QWebhookConfiguration,
    {
      parentId,
      parentType,
      partnerId,
    },
    {
      suspend: false,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const handleRefresh = useSafeCallback(async () => {
    await refetchData({
      parentId,
      parentType,
      partnerId,
    });
  });

  const webhookConfiguration = !loading && data ? data.webhookConfiguration : undefined;

  return [webhookConfiguration, loading, handleRefresh] as const;
};
