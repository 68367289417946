import {
  Checkbox,
  type FormSubmitHandler,
  Input,
  ModalForm,
  Select,
  Tag,
  useFormValue,
} from '@mortgagehippo/ds';
import { useMemo } from 'react';

import { toTagColorProps } from './util';

const AVAILABLE_TAG_COLORS = [0, 5, 10, 15, 20, 25, 30, 100, 105, 110, 115, 120, 125, 130];

interface IApplicationStatusModalProps {
  title: string;
  initialValues?: any;
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
  isOpen: boolean;
  loading: boolean;
}

const ApplicationStatusModalFields = () => {
  const [name] = useFormValue('name');

  const colorOptions = useMemo(
    () =>
      AVAILABLE_TAG_COLORS.map((c) => {
        const tagColorProps = toTagColorProps(c);

        return {
          label: <Tag {...tagColorProps}>{name || 'Status'}</Tag>,
          value: c,
        };
      }),
    [name]
  );

  return (
    <>
      <Input.Box name="name" label="Name" required />
      <Select.Box name="color" label="Color" options={colorOptions} required />
      <Checkbox.Box
        name="default"
        label="Make this the default status for new application files"
        labelInvisible
      >
        Make this the default status for new application files
      </Checkbox.Box>
    </>
  );
};

export const ApplicationStatusModal = (props: IApplicationStatusModalProps) => (
  <ModalForm {...props}>
    <ApplicationStatusModalFields />
  </ModalForm>
);
