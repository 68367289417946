import { createField, type IFieldInputProps, type IInputProps, Input } from '@mortgagehippo/ds';
import { isPresent } from '@mortgagehippo/util';
import { useCallback } from 'react';

export type IHexProps = IInputProps;

interface IHexInputProps extends IHexProps, IFieldInputProps {}

const HexInput = (props: IHexInputProps) => {
  const { onChange, ...rest } = props;

  const handleChange = useCallback(
    (value: string | undefined) => {
      let nextValue;

      if (value) {
        nextValue = `#${value}`;
      }

      onChange(nextValue);
    },
    [onChange]
  );

  return <Input.Input {...rest} onChange={handleChange} mask="######" />;
};

export const Hex = createField<IHexInputProps>(HexInput, undefined, {
  async validate(value) {
    if (!isPresent(value)) {
      return undefined;
    }

    const isHex = /^#[0-9A-F]{6}$/.test(value);

    if (!isHex) {
      return 'Invalid format.';
    }

    return undefined;
  },
});
