import { useModal } from '@mortgagehippo/ds';
import { useSagaSubmitHandler } from '@mortgagehippo/tasks';
import { useCallback } from 'react';

import { type IActionProps, type ICreatePartnerAction } from '../types';
import { CreatePartnerWizard } from './create-partner-wizard';
import { useCreatePartner } from './use-create-partner';
import { toServerInput } from './util';

export const CreatePartnerAction = (props: IActionProps<ICreatePartnerAction>) => {
  const { onDone, onSubmit } = props;

  const createPartner = useCreatePartner();

  const [isOpen, , closeModal] = useModal(true);

  const [handleSubmit, running] = useSagaSubmitHandler({
    infoMessageCid: 'create-partner-action:info',
    successMessageCid: 'create-partner-action:success',
    errorMessageCid: 'create-partner-action:error',
    infoMessage: 'Creating partner',
    successMessage: 'The partner was successfully created.',
    errorMessage: 'There was an error while creating the partner.',
    async onSubmit(values: any, setAsyncId) {
      const data = toServerInput(values);

      const nextAsyncId = await createPartner(data);

      closeModal(() => {
        setAsyncId(nextAsyncId);
        onSubmit();
      });
    },
    async onDone() {
      closeModal(onDone);
    },
  });

  const handleCloseModal = useCallback(() => {
    closeModal(onDone);
  }, [closeModal, onDone]);

  if (running) {
    return null;
  }

  return (
    <CreatePartnerWizard
      onComplete={handleSubmit}
      onRequestClose={handleCloseModal}
      isOpen={isOpen}
    />
  );
};
