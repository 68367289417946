import { ModalForm, notifications } from '@mortgagehippo/ds';
import { isNil } from 'lodash-es';
import { useCallback, useMemo } from 'react';

import { useCreateRegisteredIntegrationConnection } from '../../../hooks/use-create-registered-integration-connection';
import { useRegisteredIntegrationConnection } from '../../../hooks/use-registered-integration-connection';
import { useUpdateRegisteredIntegrationConnection } from '../../../hooks/use-update-registered-integration-connection';
import { PushbackEndpointForm } from './pushback-endpoint-form';

interface IPushbackEndpointModalContainerProps {
  partnerId: string;
  registeredIntegrationConnectionId?: string;
  onRequestClose: () => void;
  onSubmit: () => void;
  isOpen: boolean;
}

export const PushbackEndpointModalContainer = (props: IPushbackEndpointModalContainerProps) => {
  const { partnerId, registeredIntegrationConnectionId, onSubmit, onRequestClose, isOpen } = props;

  const editing = !isNil(registeredIntegrationConnectionId);

  const [registeredIntegrationConnection, loading] = useRegisteredIntegrationConnection(
    partnerId,
    registeredIntegrationConnectionId!,
    {
      skip: !editing,
    }
  );
  const createRegisteredIntegrationConnection = useCreateRegisteredIntegrationConnection();
  const updateRegisteredIntegrationConnection = useUpdateRegisteredIntegrationConnection();

  const handleSubmit = useCallback(
    async (values: any) => {
      let successMsg = '';

      const {
        connectionVersion,
        sagaName,
        pathSlug,
        securityType,
        signatureHeader,
        signatureSigningKey,
        basicUsername,
        basicPassword,
        jwksUrl,
        activated,
        externalToken,
        externalTokenKey,
        integrationType,
        integrationAction,
      } = values;

      const data = {
        connectionVersion,
        sagaName,
        pathSlug,
        securityType,
        signatureHeader,
        signatureSigningKey,
        basicUsername,
        basicPassword,
        jwksUrl,
        activated,
        externalToken,
        externalTokenKey,
        integrationType,
        integrationAction,
      };

      try {
        if (registeredIntegrationConnectionId) {
          await updateRegisteredIntegrationConnection(registeredIntegrationConnectionId, data);

          successMsg = 'The pushback endpoint was successfully updated.';
        } else {
          await createRegisteredIntegrationConnection(partnerId, data);

          successMsg = 'The pushback endpoint was successfully created.';
        }

        notifications.success({ message: successMsg });

        onSubmit();
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [
      createRegisteredIntegrationConnection,
      onSubmit,
      partnerId,
      registeredIntegrationConnectionId,
      updateRegisteredIntegrationConnection,
    ]
  );

  const initialValues: any = useMemo(() => {
    if (!registeredIntegrationConnection) {
      return {};
    }

    const {
      connectionVersion,
      sagaName,
      pathSlug,
      securityType,
      signatureHeader,
      signatureSigningKey,
      basicUsername,
      basicPassword,
      jwksUrl,
      activated,
      externalToken,
      externalTokenKey,
      integrationType,
      integrationAction,
    } = registeredIntegrationConnection;

    return {
      connectionVersion,
      sagaName,
      pathSlug,
      securityType,
      signatureHeader,
      signatureSigningKey,
      basicUsername,
      basicPassword,
      jwksUrl,
      activated,
      externalToken,
      externalTokenKey,
      integrationType,
      integrationAction,
    };
  }, [registeredIntegrationConnection]);

  const title = !editing ? 'Add Pushback Endpoint' : 'Edit Pushback Endpoint';

  return (
    <ModalForm
      loading={loading}
      isOpen={isOpen}
      title={title}
      onSubmit={handleSubmit}
      onRequestClose={onRequestClose}
      initialValues={initialValues}
    >
      <PushbackEndpointForm />
    </ModalForm>
  );
};
