import {
  Choice,
  Col,
  FieldBox,
  Input,
  Row,
  SimpleFieldAutoArray,
  Tabs,
  YesNo,
} from '@mortgagehippo/ds';

import { LogicField } from '$components/logic-field';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { AllowedStatesEditor } from './allowed-states-editor';
import { FieldEditorFrame } from './field-editor-frame';

const SUFFIXES = [
  '.line_text',
  '.unit_identifier',
  '.city',
  '.state_code',
  '.postal_code',
  '.county_name',
];

export const USAddressEditor = ({ name }: { name: string }) => (
  <FieldEditorFrame name={name} keySuffixes={SUFFIXES}>
    {() => (
      <Tabs.Tab id="address" label="US Address Options">
        <EditorTabWrapper size="md">
          <Choice.Box
            name={`${name}.mode`}
            label="Mode"
            options={[
              { label: 'Full', value: 'full' },
              { label: 'City/State/Zip', value: 'city_state_zip' },
            ]}
            columns={2}
          />

          <FieldBox name="sources" label="Sources">
            <SimpleFieldAutoArray
              name={`${name}.sources`}
              presentFields={['source']}
              render={({ name: itemName, index }) => (
                <Row key={`${index}`}>
                  <Col xs={12}>
                    <LogicField.Box name={`${itemName}.source`} label="Source" required />
                  </Col>
                  <Col xs={12}>
                    <Input.Box name={`${itemName}.label`} label="Label (optional)" />
                  </Col>
                </Row>
              )}
            />
          </FieldBox>

          <YesNo.Box name={`${name}.autocomplete`} label="Autocomplete" />
          <Input.Box name={`${name}.autocompletePlaceholder`} label="Autocomplete placeholder" />
          <Input.Box name={`${name}.lineTextPlaceholder`} label="Line text label" />
          <Input.Box
            name={`${name}.unitIdentifierPlaceholder`}
            label="Unit identifier placeholder"
          />
          <Input.Box name={`${name}.cityPlaceholder`} label="City placeholder" />
          <Input.Box name={`${name}.postalCodePlaceholder`} label="Postal code placeholder" />
          <Input.Box name={`${name}.countyPlaceholder`} label="County placeholder" />

          <AllowedStatesEditor name={`${name}.allowedStates`} />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
  </FieldEditorFrame>
);
