import { useLazyQuery } from '@mortgagehippo/apollo-hooks';
import { useEffect, useMemo } from 'react';

import { QCustomizationEditorNamespaces } from '$components/customizations-editor/queries';

type UseCustomizationEditorNamespacesResult = [string[], boolean];

export const useCustomizationEditorNamespaces = (
  projectId: string
): UseCustomizationEditorNamespacesResult => {
  const [executeCustomizationQuery, customizationsData, customizationsDataLoading] = useLazyQuery(
    QCustomizationEditorNamespaces,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    const variables = {
      projectId,
    };
    executeCustomizationQuery({ ...variables });
  }, [projectId, executeCustomizationQuery]);

  const namespaces = useMemo(() => {
    if (customizationsData?.customizationNamespaces) {
      const {
        customizationNamespaces,
      }: {
        customizationNamespaces: string[];
      } = customizationsData;

      const filteredNamespaces: string[] = [];

      customizationNamespaces.forEach((namespace) => {
        if (!namespace.includes('landing-page')) {
          // filter out all landing page resources.
          filteredNamespaces.push(namespace);
        }
      });
      return filteredNamespaces;
    }

    return [];
  }, [customizationsData]);

  return [namespaces, customizationsDataLoading];
};
