import {
  Form,
  type FormSubmitHandler,
  notifications,
  SubmitButton,
  YesNo,
} from '@mortgagehippo/ds';
import { useMemo } from 'react';

import { Content, Layout } from '../../layouts/main';
import { useAppMaintenanceMode } from './use-app-mantainance-mode';
import { useUpdateAppMaintenanceMode } from './use-update-app-maintenance-mode';

export const MaintenanceModePage = () => {
  const updateMaintenanceMode = useUpdateAppMaintenanceMode();
  const [maintenanceMode] = useAppMaintenanceMode();

  const handleSubmit: FormSubmitHandler = async ({ maintenanceMode: nextMaintenanceMode }) => {
    try {
      await updateMaintenanceMode(!!nextMaintenanceMode);
      notifications.success({ message: 'Successfully changed maintenance mode' });
    } catch (e) {
      notifications.error({ message: 'There was an unexpected error changing maintenance mode' });
    }
  };

  const initialValues = useMemo(
    () => ({
      maintenanceMode,
    }),
    [maintenanceMode]
  );

  return (
    <Layout>
      <Content title="Maintenance Mode" subTitle="Make the whole application unavailable">
        <Form onSubmit={handleSubmit} initialValues={initialValues}>
          <YesNo.Box name="maintenanceMode" label="Put application into maintenance mode" />
          <SubmitButton>Save</SubmitButton>
        </Form>
      </Content>
    </Layout>
  );
};
