import { UnassignedApplicationNotifications } from '../../../components/unassigned-application-notifications';

interface IPartnerUnassignedApplicationNotificationsContactsPageContentProps {
  partnerId: string;
}

export const PartnerUnassignedApplicationNotificationsContactsPageContent = (
  props: IPartnerUnassignedApplicationNotificationsContactsPageContentProps
) => {
  const { partnerId } = props;

  return <UnassignedApplicationNotifications partnerId={partnerId} />;
};
