import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import { type PartnerCredentialsQuery, type SiteCredentialsQuery } from '../../../apollo/graphql';
import { QPartnerCredentials, QSiteCredentials } from '../queries';

export type ICredentialPartner = NonNullable<
  NonNullable<PartnerCredentialsQuery['partner']>['credentials']
>[0];

export type ICredentialSite = NonNullable<
  NonNullable<SiteCredentialsQuery['site']>['credentials']
>[0];

export type ICredential = ICredentialPartner | ICredentialSite;

export const useCredentialList = (partnerId: string, siteId?: string | null, skip = false) => {
  const isSite = !!siteId;

  const [partnerData, partnerDataLoading, , { refetch: refetchPartnerData }] = useQuery(
    QPartnerCredentials,
    { partnerId },
    {
      suspend: false,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      skip: skip || isSite,
    }
  );

  const [siteData, siteDataLoading, , { refetch: refetchSiteData }] = useQuery(
    QSiteCredentials,
    { siteId: siteId! },
    {
      suspend: false,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      skip: skip || !isSite,
    }
  );

  const handleRefetch = useSafeCallback(async () => {
    if (isSite) {
      return refetchSiteData({ siteId });
    }
    return refetchPartnerData({ partnerId });
  });

  const result: ICredential[] | undefined = isSite
    ? siteData?.site?.credentials
    : partnerData?.partner?.credentials;
  const loading = partnerDataLoading || siteDataLoading;

  return [result, loading, handleRefetch] as const;
};
