import { type IEditorSaga, type IEditorSagaArgument } from './types';
import { isArrayEqual } from './util';

export const sagasReducer = (previousSagas: IEditorSaga[], action: any): IEditorSaga[] => {
  switch (action.type) {
    case 'ADD_SAGA': {
      const { name } = action;
      const nextSagas = [...previousSagas];

      const initialValue = '';
      const initialArguments: IEditorSagaArgument[] = [];

      const newSaga: IEditorSaga = {
        name,
        custom: true,
        system: false,
        isNew: true,
        isEdited: false,
        initialValue,
        currentValue: initialValue,
        systemArguments: [],
        initialArguments,
        currentArguments: initialArguments,
      };

      nextSagas.push(newSaga);

      return nextSagas;
    }
    case 'UPDATE_SAGA': {
      const { saga, newValue, newArguments } = action;
      const nextSagas = [...previousSagas.filter((s) => s.name !== saga.name)];
      // lets create a copy of the saga object to modify
      const nextSaga: IEditorSaga = { ...saga };

      if (!nextSaga.isNew) {
        const isInitialValue =
          nextSaga.initialValue === newValue &&
          isArrayEqual(nextSaga.initialArguments, newArguments);

        nextSaga.isEdited = !isInitialValue;
      }

      nextSaga.currentValue = newValue;
      nextSaga.currentArguments = newArguments;
      nextSagas.push(nextSaga);

      return nextSagas;
    }
    case 'RESET_SAGA': {
      const { saga } = action;
      const nextSagas = [...previousSagas.filter((s) => s.name !== saga.name)];
      // lets create a copy of the saga object to modify
      const nextSaga: IEditorSaga = { ...saga };

      nextSaga.currentValue = nextSaga.initialValue;
      nextSaga.currentArguments = nextSaga.initialArguments;
      nextSaga.isEdited = false;

      nextSagas.push(nextSaga);

      return nextSagas;
    }
    case 'DELETE_LOCAL_SAGA': {
      const { saga } = action;
      const nextSagas = [...previousSagas.filter((s) => s.name !== saga.name)];

      return nextSagas;
    }
    case 'DELETE_REMOTE_SAGA': {
      const { saga } = action;
      const nextSagas = [...previousSagas.filter((s) => s.name !== saga.name)];

      if (saga.system) {
        // reset to system saga
        const initialValue = '';
        const initialArguments: IEditorSagaArgument[] = [];

        const nextSaga = {
          ...saga,
          isEdited: false,
          custom: false,
          initialValue,
          currentValue: initialValue,
          initialArguments,
          currentArguments: initialArguments,
        };

        nextSagas.push(nextSaga);
      }

      return nextSagas;
    }
    case 'COMMIT_CHANGES': {
      const { saga } = action;

      const nextSagas = previousSagas.map((previousSaga) => {
        if (previousSaga.name !== saga.name) {
          return previousSaga;
        }

        const { name, system, systemArguments, currentArguments, currentValue } = previousSaga;

        return {
          name,
          system,
          custom: true,
          isNew: false,
          isEdited: false,
          initialValue: currentValue,
          currentValue,
          systemArguments,
          initialArguments: currentArguments,
          currentArguments,
        };
      });

      return nextSagas;
    }
    default:
      throw new Error(`Unknown action ${action.type}`);
  }
};
