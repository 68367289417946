import { type FormSubmitHandler, Input, ModalForm, Slug } from '@mortgagehippo/ds';

interface ICreateLandingPageModalProps {
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
  isOpen: boolean;
}

export const CreateLandingPageModal = (props: ICreateLandingPageModalProps) => {
  const { onSubmit, isOpen, ...rest } = props;

  return (
    <ModalForm title="Create Landing Page" isOpen={isOpen} onSubmit={onSubmit} {...rest}>
      <Input.Box name="title" label="Title" />
      <Slug.Box name="path" label="Path" />
    </ModalForm>
  );
};
