import {
  Checkbox,
  Domain,
  Form,
  FormSection,
  type FormSubmitHandler,
  Input,
  SubmitButton,
  Timezone,
  YesNo,
} from '@mortgagehippo/ds';

import { VerticalSelect } from '$components/vertical-select';

import { BlueprintType } from '../../apollo/graphql';
import { AuthConfiguration } from './auth-configuration';
import { BlueprintsSelect } from './blueprints-select';

export interface ISitePageProps {
  editMode: boolean;
  partnerId: string;
  siteId?: string;
  initialValues: any;
  onSubmit: FormSubmitHandler;
  disabled?: boolean;
  onSiteAuth0TenantChange: (() => Promise<void>) | undefined;
}

export const SiteForm = (props: ISitePageProps) => {
  const {
    editMode,
    partnerId,
    siteId,
    initialValues,
    onSubmit,
    disabled,
    onSiteAuth0TenantChange,
  } = props;

  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      <Input.Box name="name" label="Name" required />
      <Domain.Box name="domain" label="Domain" required validateSane={false} />
      {!editMode && <VerticalSelect.Box name="verticalName" label="Vertical" required />}
      <BlueprintsSelect
        partnerId={partnerId}
        name="blueprintId"
        label="Borrower Hub Blueprint"
        required
        type={BlueprintType.BorrowerPortalBlueprint}
      />
      <BlueprintsSelect
        partnerId={partnerId}
        name="lenderPortalBlueprintId"
        label="Lender Hub Blueprint"
        required
        type={BlueprintType.LenderPortalBlueprint}
      />
      <FormSection title="Timezone Settings">
        <Timezone.Box name="timezone" label="Timezone" />
      </FormSection>

      <FormSection title="Authentication Settings">
        <AuthConfiguration
          name="authConfiguration"
          editMode={editMode}
          siteId={siteId}
          onSiteAuth0TenantChange={onSiteAuth0TenantChange}
        />
      </FormSection>

      <FormSection title="TPO Settings">
        <YesNo.Box label="Is this a TPO site?" name="tpoSite" required />
      </FormSection>

      <Checkbox.Box label="Site live" labelInvisible name="liveSite">
        Is it live?
      </Checkbox.Box>

      <SubmitButton importance="primary" disabled={disabled}>
        Save
      </SubmitButton>
    </Form>
  );
};
