import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo/gql';
import { type UpdateNotificationProfileInput } from '../../../apollo/graphql';

export const MUpdateNotificationProfileMutation = graphql(`
  mutation UpdateNotificationProfile($profileId: ID!, $data: UpdateNotificationProfileInput!) {
    updateNotificationProfile(profileId: $profileId, data: $data) {
      ...NotificationProfileFragment
    }
  }
`);

export const useUpdateNotificationProfile = () => {
  const updateNotificationProfile = useMutation(MUpdateNotificationProfileMutation, undefined);

  return useCallback(
    async (profileId: string, data: UpdateNotificationProfileInput) => {
      await updateNotificationProfile({ profileId, data });
    },
    [updateNotificationProfile]
  );
};
