import { Select } from '@mortgagehippo/ds';
import { type ITask } from '@mortgagehippo/tasks';
import { get } from 'lodash-es';
import { useMemo } from 'react';

import { type ITaskActionComponentProps } from './types';

export const FreezeTasksActionEditor = (props: ITaskActionComponentProps) => {
  const { blueprint } = props;
  const tasks = get(blueprint, 'automated_tasks', []) as ITask[];
  const options = useMemo(
    () =>
      tasks.map((task) => {
        const label = task.meta?.title || task.key || 'Unnamed';
        return { value: task.key, label };
      }),
    [tasks]
  );

  return <Select.Box name="data" label="Tasks" options={options} multiple required />;
};
