/*
 * IMPORTANT
 *
 * You can write HTML tags that will render as elements.
 * If you want to display html as text for instructions, use HTML entities,
 * i.e. if you want to display this as text: <img />, enter it as &lt;img&gt;
 * otherwise an image element will be displayed
 */

type ResourceInstructions = Record<string, Record<string, string>>;

export const RESOURCE_INSTRUCTIONS: ResourceInstructions = {
  app: {
    'header.logo.height': `The logo height in pixels. A number between 30 and 50 will work best.`,
    'header.logo': `This logo will be displayed in the BP header as well as in Auth0.`,
    'header.logo.heightMobile': `The logo height in pixels for mobile devices. A number between 25 and 40 will work best.`,
    'header.width': `The header height in pixels. Ideally it should be the same or within 10px of the appNav width.`,

    'start.logo.showInsideContent': `If "1" the logo will be displayed inside the white content area above the title.`,
    'start.logo.height': `The logo height on the start page in px (do not enter "px").`,

    'content.border.radius': `The top left border radius of the content. The following numbers work best: 0, 2, 4 or 8.`,
    'content.nav.width': `Width in pixels of the navigation inside the content, e.g. the tasks list. Values around "320" work best.`,

    'appNav.width': `The side app navigation width in pixels. Ideally it should be the same or within 10px of the header height.`,

    'site.maxWidth': `CAREFUL - ASK GEORGE!!! This is the entire site max width in pixels. The value should be "1300" or larger. Depending on the Header and/or appNav background colors you may have to also add site.shadow OR site.border.`,
    'site.shadow.level': `If you set site.shadow it may not look well if you also have site.border. Set one or the other.`,
    'site.border.color': `If you set site.border it may not look well if you have also have site.shadow. Set one or the other.`,

    'start.footer.background': `This will affect only public pages (e.g. not Application page). Do not change unless there is a reason to.`,
    'start.background.properties': `Control the <code>start.footer.background</code> image position and sizing, e.g. <code>bottom/cover no-repeat fixed</code>`,
    'start.background.containAboveFooter': `Apply the background image to the div above the footer, which contains only the content. This way the bottom part of the background image won't be covered by the footer if it has its own background color. If Off/default, the background image is applied on the <code>&lt;body&gt;</code>.`,
    'start.footer.color': `Use this if you change the background. This will not change link colors. To do so, switch light/dark link colors by switching "app:start.footer.links.invert"`,

    'dashboard.stateStyle.name': `Determines the style of the images to display for task and application state. Available themes are: "isometric", "illustration", "drawing", and "icon"`,
    'dashboard.stateStyle.hue': `Shifts the hue (color) for application and task state images. VALUE: must be between 0 and 360.`,
    'dashboard.stateStyle.saturation': `Changes the saturation (more/less color) for application and task state images. VALUES: 0 = black & white; 0 to 100 = less saturation; 100 = no change; >100 = more saturated/bright/burnt colors.`,
    'dashboard.stateStyle.luminosity': `Changes the luminosity (darker/lighter) for application and task state images. VALUES: 0 = black; 0 to 100 = darker; 100 = no change; >100 = lighter/washed out colors.`,
    'dashboard.stateStyle.contrast': `Changes the contrast for application and task state images. VALUES: 0 = none; 0 to 100 = lower; 100 = no change; >100 = higher contrast.`,
    'taskList.alwaysShowUpcomingTasks':
      'If enabled, the "Show upcoming tasks" button will be hidden, and locked tasks will be always displayed without the option to hide them.',
    'pageTerms.disabled':
      'When "On", the Terms & Conditions page will skipped for newly signed up users. IMPORTANT: you must check with a legal expert if this is ok AND make sure users agree to the MH Software Terms in a different manner.',
  },
  footer: {
    'text.top':
      'IMPORTANT: If you include images (such as Equal Lender logos, etc), they MOST LIKELY will have to work both on dark and light backgrounds at the same time. This depends on the start page and/or footer backgrounds, and also in the Borrower Hub modal background, which is normally white. The recommended image format for the footer is "embedded SVG" as it will take the color of the surrounding text automatically. Ask George.',
    'text.bottom':
      'IMPORTANT: If you include images (such as Equal Lender logos, etc), they MOST LIKELY will have to work both on dark and light backgrounds at the same time. This depends on the start page and/or footer backgrounds, and also in the Borrower Hub modal background, which is normally white. The recommended image format for the footer is "embedded SVG" as it will take the color of the surrounding text automatically. Ask George.',
    copyright: `You can use variables {{year}} to display current year and also {{siteName}} to display the site's name.\n\nIMPORTANT: If you include images (such as Equal Lender logos, etc), they MOST LIKELY will have to work both on dark and light backgrounds at the same time. This depends on the start page and/or footer backgrounds, and also in the Borrower Hub modal background, which is normally white. The recommended image format for the footer is "embedded SVG" as it will take the color of the surrounding text automatically. Ask George.`,
    equalLogo: `IMPORTANT: If you include images (such as Equal Lender logos, etc), they MOST LIKELY will have to work both on dark and light backgrounds at the same time. This depends on the start page and/or footer backgrounds, and also in the Borrower Hub modal background, which is normally white. The recommended image format for the footer is "embedded SVG" as it will take the color of the surrounding text automatically. Ask George.`,
  },
  pageClaim: {
    description: `You can use variable {{siteName}} to display the site's name.`,
    logoutDescription: `You can use variable {{siteName}} to display the site's name.`,
  },
  pageStart: {
    'dangerouslyReplacePageContent.html': `<strong style="color:red">DANGER:</strong> This HTML content will replace the content in the entire white area of the start page. It will replace all other content/text/title/buttons.`,
  },
  pageTerms: {
    'checkbox0Software.label': `<strong style="color:red">DANGER - DO NOT CHANGE:</strong> Do not change the software terms. The hardcoded default value of this field is legally approved by MH legal team. Add custom client terms as an extra checkbox that will show below (e.g. use key <code>checkbox1.label</code>). If there is an issue, talk to our legal or ux team.`,
    'button.label': `<strong style="color:red">DANGER - DO NOT CHANGE:</strong> The default title of the button is "Agree", which is tied to the text in the e-consent policy. Do not change, unless you are cleared by the legal team.`,
  },
  dashboard: {
    'application.start.dangerouslyReplaceContent.html':
      '<strong>DANGER:</strong> For <em>authenticated</em> users, replace the entire Application Start Dashboard content below the image (i.e. title/content/buttons) with this html content.',
    'application.start.anonymous.dangerouslyReplaceContent.html':
      '<strong>DANGER:</strong> For <em>anonymous</em> users, replace the entire Application Start Dashboard content below the image (i.e. title/content/buttons) with this html content.',
    'application.completed.dangerouslyReplaceContent.html':
      '<strong>DANGER:</strong> For <em>authenticated</em> users, replace the entire Application Completed Dashboard content below the image (i.e. title/content/buttons) with this html content.',
    'application.completed.anonymous.dangerouslyReplaceContent.html':
      '<strong>DANGER:</strong> For <em>anonymous</em> users, replace the entire Application Completed Dashboard content below the image (i.e. title/content/buttons) with this html content.',
    'task.authenticate.dangerouslyReplaceContent.html':
      '<strong>DANGER:</strong> Replace the entire Task Authenticate Panel content below the image (i.e. title/content/buttons) with this html content.',
    'task.task.private.completed.dangerouslyReplaceContent.html':
      'You can use variable {{ownerApplicantName}} and {{completedDate }}.<br><br><strong>DANGER:</strong> When a task is completed, replace the entire Task Private Panel content below the image (i.e. title/content/buttons) with this html content.',
    'task.task.private.open.dangerouslyReplaceContent.html':
      'You can use variable {{ownerApplicantName}}.<br><br><strong>DANGER:</strong> When a task is open, replace the entire Task Private Panel content below the image (i.e. title/content/buttons) with this html content.',
  },
  auth: {
    'login.options.authButtons': `Customization for Auth0 login custom login buttons. Format: <code>{ "connection1" : {...}, "connection2": {...}, ... }</code>. See the <a href="https://auth0.com/docs/libraries/lock/v11/ui-customization#authbuttons-object-" target="_blank">Auth0 Documentation</a> for details.`,
    'login.options.languageDictionary': `Customization for Auth0 login labels. Format: <code>{ "title" : "Authenticate", "loginSubmitLabel": "Sign In", ... }</code>. See the <a href="https://auth0.com/docs/libraries/lock/v11/ui-customization#customizing-text" target="_blank">Auth0 Documentation</a> for details.`,
    'login.options.lockOptions': `Auth0 lock options. Format: <code>{ "allowLogin": true, "allowSignup": true, ... }</code>. See the <a href="https://auth0.com/docs/libraries/lock/lock-configuration#ui" target="_blank">Auth0 Config Documentation</a> for details.`,
    'pageLogin.allowSignup': `Whether to show the "Sign Up" tab on the Auth0 login screen`,
    'pageSignup.allowLogin': `Whether to show the "Log In" tab on the Auth0 sign up screen`,
    'resetPassword.options.dict': `Customization for Auth0 change password labels. Format: <code>{ "title": "Change Password", "claimModeTitle": "Claim Account", ... }</code>. See the <a href="https://auth0.com/docs/i18n/password-options#change-password-widget" target="_blank">Auth0 Documentation</a> for details. <strong>IMPORTANT: </strong> you can pass undocumented field "claimModeTitle" for claim mode - this is for the MH hack on the password change page.`,
  },
  logoutCountdown: {
    'timer.content': `Explanation for the timer. It must be short. You <strong>must</strong> use the <code>{{timer}}</code> token to display the timer. Example: "Time left: {{timer}}"`,
  },
  taskEmployers: {
    'wizard.report.incomeType.label':
      'You must use interpolation token {incomeType}, e.g. "{incomeType} Income"',
  },
  pageApplication: {
    'menu.tabs.order': `To reorder the tabs just create an array similar to that: <code>["overview", "application", "tasks", "services", "documents", "pricer", "team", "status"]</code>. If you omit any of the tab keys they'll keep their original order and will be displayed after the keys you've specified.`,
    'tabInvitationLink.alertNoEmail.message':
      "You can use variable {fullName} to display the borrower's name",
    'tabInvitationLink.alertNoEmailNoAddButton.message':
      "You can use variable {fullName} to display the borrower's name",
    'alert.archived.daysAgo':
      'You can use variable {daysAgo} to display how many days have passed since archival.',
  },
  defaultEmail: {
    css: `For a full list of the classes you can override, see the <a href="https://github.com/MortgageHippo/mortgagehippo-integrations/blob/develop/app/engines/notifier/app/views/layouts/notifier/mailer.html.haml" target="_blank">notifier engine layout code</a>.`,
  },
  preapproval: {
    css: `For a full list of the classes you can override, see the <a href="https://github.com/MortgageHippo/mortgagehippo-integrations/blob/develop/app/engines/preapproval/app/views/preapproval/task_document_pdf.html.erb" target="_blank">preapproval engine template code</a>.`,
  },
  settings: {
    'inviteApplicant.forceSharedTasks':
      'Always share tasks between borrowers. You should set this for <strong>BOTH</strong> the LP and BP.',
    'inviteApplicant.allowSharedTasks':
      'Ask borrower if they want to share tasks with co-borrower. You should set this for <strong>BOTH</strong> the LP and BP.',
    'inviteApplicant.allowSharedTasksNonSpouse':
      'Ask borrower if they want to share tasks with co-borrower when they are not spouses. You should set this for <strong>BOTH</strong> the LP and BP.',
    'inviteApplicant.allowSharedEmailAddress':
      'Allow the borrower and the co-borrower to share the same email address. You should set this for <strong>BOTH</strong> the LP and BP.',
  },
  pageDashboard: {
    'pipeline.columns': `Enter an array similar to: <code>["start_date", "full_name", "email_address", "site_name", "loan_type", "primary_agent", "los_loan_number", "percent_complete", "submitted_date", "last_accessed_date", "trid_collected", "trid_collected_date", "trid_submitted", "trid_submitted_date", "status"]</code>. This will control which columns are displayed in the table and in what order.`,
    'pipeline.filters': `Enter an array similar to: <code>["application_id", "application_site",  "application_start_date", "application_percent_complete",  "application_submitted_at", "application_loan_file_number",  "application_last_access_date", "application_status", "application_trid_collected", "application_trid_collected_date", "application_trid_submitted",  "application_trid_submitted_date",  "borrower_first_name",  "borrower_last_name", "borrower_email", "borrower_mobile_phone_number", "borrower_ssn",  "borrower_citizenship","borrower_marital_status","borrower_military_status", "property_mixed_use", "property_usage_type", "property_value","loan_amount", "loan_down_payment","loan_purpose", "milestone_name", "agent_name", "agent_email"]</code>. This will control which filters are available.`,
  },
};
