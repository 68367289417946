import { FormSection, Input, Numeric, Tabs, YesNo } from '@mortgagehippo/ds';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { FieldEditorFrame } from './field-editor-frame';
import { OptionsField } from './options-field';

export const ChoiceEditor = ({ name }: { name: string }) => (
  <FieldEditorFrame name={name}>
    {() => (
      <Tabs.Tab id="choices" label="Choices">
        <EditorTabWrapper size="md">
          <Numeric.Box name={`${name}.columns`} label="Columns" />
          <OptionsField name={`${name}.choices`} label="Choices" />

          <FormSection
            title="Alternate Choice"
            description="You can have a choice that points to another field instead of this one"
          >
            <Input.Box name={`${name}.alternateChoiceKey`} label="Alternate Choice Key" />
            <Input.Box name={`${name}.alternateChoiceLabel`} label="Alternate Choice Label" />
            <Input.Box name={`${name}.alternateChoiceValue`} label="Alternate Choice Value" />
          </FormSection>

          <YesNo.Box name={`${name}.multiple`} label="Allow multiple selections" />
          <YesNo.Box name={`${name}.checkboxes`} label="Use checkboxes instead of buttons" />
          <YesNo.Box
            name={`${name}.alternateChoiceExclusive`}
            label="Remove other options when choosing the alternate choice"
            initialValue
          />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
  </FieldEditorFrame>
);
