import { Input, type ISelectOption, Select, YesNo } from '@mortgagehippo/ds';

import { RuleSetSagaArgumentType } from '../../../apollo/graphql';

const typeOptions: ISelectOption[] = [
  {
    label: 'Array',
    value: RuleSetSagaArgumentType.Array,
  },
  {
    label: 'Boolean',
    value: RuleSetSagaArgumentType.Boolean,
  },
  {
    label: 'Hash',
    value: RuleSetSagaArgumentType.Hash,
  },
  {
    label: 'Integer',
    value: RuleSetSagaArgumentType.Integer,
  },
  {
    label: 'String',
    value: RuleSetSagaArgumentType.String,
  },
];

export const ArgumentsModalItemForm = () => (
  <>
    <Input.Box name="name" label="Name" required />
    <Select.Box name="type" label="Type" options={typeOptions} required />
    <YesNo.Box name="required" label="Required" required />
  </>
);
