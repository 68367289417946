import { type FormSubmitHandler, notifications } from '@mortgagehippo/ds';
import { useLandingPage } from '@mortgagehippo/tasks';
import { useEffect, useMemo, useRef } from 'react';

import { EditLandingPageModal } from './edit-landing-page-modal';

interface IEditLandingPageModalContainerProps {
  siteId: string;
  landingPageId: string;
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
  isOpen: boolean;
}

export const EditLandingPageModalContainer = (props: IEditLandingPageModalContainerProps) => {
  const { siteId, landingPageId, onRequestClose, isOpen, ...rest } = props;
  const notified = useRef(false);

  const [{ data: landingPage, loading }] = useLandingPage(siteId, landingPageId);

  useEffect(() => {
    if (isOpen && !loading && !landingPage && !notified.current) {
      notifications.error({
        messageCid: 'edit-landing-page:not-found-error',
        message: 'The specified landing page was not found.',
      });
      notified.current = true;

      if (onRequestClose) {
        onRequestClose();
      }
    }
  }, [isOpen, landingPage, loading, onRequestClose]);

  const initialValues = useMemo(() => {
    if (!landingPage) {
      return {};
    }

    const { title, path } = landingPage!;

    return {
      title,
      path,
    };
  }, [landingPage]);

  return (
    <EditLandingPageModal
      loading={loading}
      initialValues={initialValues}
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      {...rest}
    />
  );
};
