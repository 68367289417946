import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo/gql';

export const MCreatePageComponentResourceUploadUrlMutation = graphql(`
  mutation CreatePageComponentResourceUploadUrl($pageComponentId: ID!, $filename: String!) {
    createPageComponentResourceUploadUrl(pageComponentId: $pageComponentId, filename: $filename) {
      uploadUrl
      downloadUrl
    }
  }
`);

export const useCreatePageComponentResourceUploadUrl = () => {
  const createPageComponentResourceUploadUrl = useMutation(
    MCreatePageComponentResourceUploadUrlMutation,
    undefined
  );

  return async (pageComponentId: string, filename: string): Promise<any> => {
    const result = await createPageComponentResourceUploadUrl({
      pageComponentId,
      filename,
    });
    return result.data!.createPageComponentResourceUploadUrl;
  };
};
