import {
  borderRadius,
  DEFAULT_THEME,
  Input,
  type ISelectGroup,
  palette,
  type PaletteColor,
  Select,
  spacing,
} from '@mortgagehippo/ds';
import { find, groupBy, map, sortBy } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { useCustomizationEditorResources } from '../../../hooks/use-customization-editor-resources';
import { type IValueEditorProps } from './types';

/* prettier-ignore */
const ColorBox = styled.span`
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  border-radius: ${borderRadius(2)};
  border: 1px solid ${palette('neutral50')};
  margin-right: ${spacing(1)};
  background-color: ${palette('neutral50')};
  background-image: linear-gradient(45deg, ${palette('neutral400')} 25%, transparent 25%),
    linear-gradient(-45deg, ${palette('neutral400')} 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, ${palette('neutral400')} 75%),
    linear-gradient(-45deg, transparent 75%, ${palette('neutral400')} 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  position: relative;
`;

const ColorBoxContent = styled('span')<{ backgroundCss?: string }>`
  display: block;
  width: 100%;
  height: 100%;
  background: ${(p) => p.backgroundCss || 'transparent'};
`;

const ColorRow = styled.div`
  display: flex;

  & > * {
    flex: 1 1 auto;
  }

  & > ${ColorBox} {
    flex: 0 0 50px;
    height: 50px;
  }
`;

export const ColorEditor = (
  props: IValueEditorProps & {
    partnerId: number;
    domain?: string | null;
    language: string;
    projectId: string;
  }
) => {
  const { value, onChange, labelId, disabled, partnerId, domain, language, projectId } = props;

  /*
   * the variables below must be exactly as the query in the theme-tab so that the below
   * query uses the same cache when colors are edited from the theme-tab. Otherwise if colors
   * are updated in the theme-tab, the caches will be different and the new color won't show here until
   * the page is refreshed
   */
  const [customizationsColorData, customizationsColorDataLoading] = useCustomizationEditorResources(
    {
      projectId,
      partnerId,
      domain,
      language,
      overriddenOnly: false,
      notifyOnNetworkStatusChange: false,
      namespace: 'default',
      key: 'palette*',
      type: 'theme',
      cacheFirst: true,
    }
  );

  /*
   * the variables below must be exactly as the query in the theme-tab so that the below
   * query uses the same cache when colors are edited from the theme-tab. Otherwise if colors
   * are updated in the theme-tab, the caches will be different and the new color won't show here until
   * the page is refreshed
   */
  const [customizationsPartnerColorData, customizationsPartnerColorDataLoading] =
    useCustomizationEditorResources({
      projectId,
      partnerId,
      domain: undefined,
      language,
      overriddenOnly: false,
      notifyOnNetworkStatusChange: false,
      namespace: 'default',
      key: 'palette*',
      type: 'theme',
      cacheFirst: true,
    });

  const handleChange = useCallback(
    (nextValue: string) => {
      const deconstructedValue = nextValue.split('~')[1]!;
      onChange(deconstructedValue);
    },
    [onChange]
  );

  const themeColorOptions: ISelectGroup[] = useMemo(() => {
    if (!customizationsColorData.length) {
      return [];
    }

    const groupedColorResources = groupBy(customizationsColorData, (r) =>
      r.key.replace(/palette\.|[0-9]/gi, '')
    );

    return map(groupedColorResources, (group, groupKey) => {
      // sort 50 to be on top and not after 500
      const sortedGroup = sortBy(group, (r) => (r.key.endsWith('50') ? '_' : r.key));
      const subColors = map(sortedGroup, (r, ix) => {
        const key = r.key.replace('palette.', '');
        const originalVal = r.value?.value;
        const partnerR = find(customizationsPartnerColorData, { key: r.key });
        const partnerVal = partnerR ? partnerR.value?.value : undefined;
        const val = originalVal || partnerVal || DEFAULT_THEME.palette[key as PaletteColor];
        const explanation: string = originalVal
          ? ''
          : (partnerVal && ' (PARTNER color)') || ' (default theme color)';
        return {
          label: (
            <>
              <ColorBox>
                <ColorBoxContent style={{ background: val }} />
              </ColorBox>
              {key} - <strong>{val}</strong>
              {explanation ? <em>{explanation}</em> : null}
            </>
          ),
          // sometimes two palettes may have the same color value - avoids duplicate Option values & React keys
          value: `${groupKey}${ix}~${val}`,
        };
      });

      return {
        label: groupKey,
        options: subColors,
      };
    });
  }, [customizationsColorData, customizationsPartnerColorData]);

  // must modify the value for the Select to be the best unique guess since some palette values may have been customized to be the same
  const selectValue = useMemo(() => {
    let modifiedValue = '';
    themeColorOptions.forEach((group) => {
      group.options.forEach((o) => {
        if (o.value.split('~')[1] === value) {
          modifiedValue = o.value;
        }
      });
    });
    return modifiedValue;
  }, [themeColorOptions, value]);

  return (
    <>
      <ColorRow>
        <ColorBox>
          <ColorBoxContent backgroundCss={value || undefined} />
        </ColorBox>
        <Input.Input
          name="customization_value"
          aria-labelledby={labelId}
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </ColorRow>
      <Select.Input
        name="themeColorOption"
        value={selectValue}
        searchable
        compact
        options={themeColorOptions}
        onChange={handleChange}
        placeholder="copy theme color"
        loading={customizationsColorDataLoading || customizationsPartnerColorDataLoading}
        disabled={customizationsColorDataLoading || customizationsPartnerColorDataLoading}
      />
    </>
  );
};
