import { spacing, Spinner } from '@mortgagehippo/ds';
import { useCallback, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';

import { UnassignedApplicationNotificationsTable } from './unassigned-application-notifications-table';
import { UnassignedApplicationNotificationsToggle } from './unassigned-application-notifications-toggle';
import { usePartnerNotificationAlternateAgents } from './use-partner-notification-alternate-agents';
import { useSiteNotificationAlternateAgents } from './use-site-notification-alternate-agents';

const SpinnerContainer = styled.div`
  margin-top: ${spacing(8)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface IUnassignedApplicationNotificationsProps {
  partnerId: string;
  siteId?: string;
}

export const UnassignedApplicationNotifications = (
  props: IUnassignedApplicationNotificationsProps
) => {
  const { partnerId, siteId } = props;

  const isSitePage = !!siteId;

  const [partnerAssignedAgents, partnerAssignedAgentsLoading, refetchPartnerAssignedAgents] =
    usePartnerNotificationAlternateAgents(partnerId, isSitePage);

  const [
    { agents: siteAssignedAgents, partnerInheritanceDisabled },
    siteAssignedAgentsLoading,
    refetchSiteAssignedAgents,
  ] = useSiteNotificationAlternateAgents(partnerId, siteId || '', !isSitePage);

  const [isPartnerInheritanceDisabled, setIsPartnerInheritanceDisabled] = useState<boolean>(false);

  const handleOverrideChange = useCallback(
    async (nextIsPartnerInheritanceDisabled: boolean) => {
      setIsPartnerInheritanceDisabled(nextIsPartnerInheritanceDisabled);
      if (isSitePage) {
        await refetchSiteAssignedAgents();
        return;
      }

      await refetchPartnerAssignedAgents();
    },
    [isSitePage, refetchPartnerAssignedAgents, refetchSiteAssignedAgents]
  );

  const handleRefetch = useCallback(async () => {
    if (isSitePage && isPartnerInheritanceDisabled) {
      await refetchSiteAssignedAgents();
      return;
    }

    await refetchPartnerAssignedAgents();
  }, [
    isPartnerInheritanceDisabled,
    isSitePage,
    refetchPartnerAssignedAgents,
    refetchSiteAssignedAgents,
  ]);

  useLayoutEffect(() => {
    if (partnerInheritanceDisabled) {
      setIsPartnerInheritanceDisabled(true);
    }
  }, [partnerInheritanceDisabled]);

  const dataAgents = isSitePage ? siteAssignedAgents : partnerAssignedAgents;
  const loading = partnerAssignedAgentsLoading || siteAssignedAgentsLoading;

  if (loading) {
    return (
      <SpinnerContainer>
        <Spinner size="xxl" />
      </SpinnerContainer>
    );
  }

  return (
    <>
      {isSitePage ? (
        <UnassignedApplicationNotificationsToggle
          siteId={siteId!}
          isOverridden={isPartnerInheritanceDisabled}
          onChange={handleOverrideChange}
        />
      ) : null}
      <UnassignedApplicationNotificationsTable
        partnerId={partnerId}
        siteId={siteId}
        loading={loading}
        assignedAgents={dataAgents}
        handleRefetch={handleRefetch}
        inheritsFromPartner={isSitePage ? !isPartnerInheritanceDisabled : false}
      />
    </>
  );
};
