import { Choice } from '@mortgagehippo/ds';
import { ArrayMode } from '@mortgagehippo/tasks';

export const ArrayModeEditor = ({ name }: { name: string }) => (
  <Choice.Box
    label="Array mode"
    labelInvisible
    name={`${name}.mode`}
    initialValue={ArrayMode.MODAL}
    options={[
      {
        label: 'Panel',
        value: ArrayMode.PANEL,
      },
      {
        label: 'Embedded',
        value: ArrayMode.NESTED,
      },
      {
        label: 'Modal',
        value: ArrayMode.MODAL,
      },
    ]}
  />
);
