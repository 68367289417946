import { Alert, spacing, Title } from '@mortgagehippo/ds';
import { type IQueryTableDataActions, QueryTable } from '@mortgagehippo/query-components';
import { useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';

import {
  type GetRepeatableServiceJobsQuery as GetRepeatableServiceJobs,
  type GetRepeatableServiceJobsQueryVariables as GetRepeatableServiceJobsVariables,
  type GetServiceJobsQuery as GetServiceJobs,
  type GetServiceJobsQueryVariables as GetServiceJobsVariables,
} from '../../apollo/graphql';
import { ActionType, useDispatchAction } from '../../components/actions';
import {
  useCancelRepeatableServiceJob,
  useCancelServiceJob,
  usePauseServiceJob,
  useResumeServiceJob,
} from './cancel-pause-resume-service-job';
import { REPEATABLE_JOB_COLUMNS, SERVICE_JOB_COLUMNS } from './jobs-columns';
import { QGetRepeatableServiceJobs, QGetServiceJobs } from './use-service-jobs';

const POLL_INTERVAL = 30000;

const TableWrapper = styled.div`
  margin-bottom: ${spacing(4)};
`;

type IServiceJobs = GetServiceJobs['getServiceJobs']['items'][0];
type IRepeatableServiceJobs = GetRepeatableServiceJobs['getRepeatableServiceJobs']['items'][0];

const emptyObj = {};

export const ServiceJobs = () => {
  const jobTableRef = useRef<any>();
  const repeatableJobTableRef = useRef<any>();
  const dispatch = useDispatchAction();

  const cancelJob = useCancelServiceJob();
  const cancelRepeatableJob = useCancelRepeatableServiceJob();
  const pauseJob = usePauseServiceJob();
  const resumeJob = useResumeServiceJob();

  const handleJobRowAction = useCallback(
    async (actionKey: string, record?: IServiceJobs) => {
      if (!record) {
        return;
      }
      if (actionKey === 'cancel') {
        await cancelJob(record.id);
      } else if (actionKey === 'pause') {
        await pauseJob(record.id);
      } else if (actionKey === 'resume') {
        await resumeJob(record.id);
      }

      if (jobTableRef) {
        jobTableRef.current.refetch();
      }
    },
    [cancelJob, pauseJob, resumeJob]
  );

  const handleRepeatableJobRowAction = useCallback(
    async (actionKey: string, record?: IRepeatableServiceJobs) => {
      if (!record) {
        return;
      }
      if (actionKey === 'cancel') {
        await cancelRepeatableJob(record.id);
      }

      if (repeatableJobTableRef) {
        repeatableJobTableRef.current.refetch();
      }
      if (jobTableRef) {
        jobTableRef.current.refetch();
      }
    },
    [cancelRepeatableJob]
  );

  const jobRowActions: IQueryTableDataActions<IServiceJobs> = useMemo(() => {
    const actions: IQueryTableDataActions<IServiceJobs> = [
      {
        key: 'pause',
        label: 'Pause',
        buttonProps: {
          icon: 'pause-circle',
        },
        confirm: {
          title: 'Confirm',
          explanation:
            'NOTE: If a job has already started, it can only be manually paused for up to 10 hours before being canceled! (which is not guaranteed)',
          okButtonLabel: 'Pause',
          type: 'info',
        },
        onAction: handleJobRowAction,
        hidden: (record) =>
          !!record &&
          (record.jobStatus === 'completed' ||
            record.jobStatus === 'delayed' ||
            record.jobStatus === 'failed' ||
            record.jobStatus === 'canceled'),
        disabled: (record) =>
          !!record &&
          (record.jobStatus === 'paused' ||
            record.jobStatus === 'manually-paused' ||
            !!record.manuallyPaused),
      },
      {
        key: 'resume',
        label: 'Resume',
        buttonProps: {
          icon: 'play-circle',
        },
        confirm: {
          title: 'Confirm',
          explanation: 'Are you sure you want to resume this job?',
          okButtonLabel: 'Resume',
          type: 'info',
        },
        onAction: handleJobRowAction,
        hidden: (record) =>
          !!record &&
          (record.jobStatus === 'completed' ||
            record.jobStatus === 'delayed' ||
            record.jobStatus === 'failed' ||
            record.jobStatus === 'canceled'),
        disabled: (record) =>
          !!record && record.jobStatus !== 'paused' && record.jobStatus !== 'manually-paused',
      },
      {
        key: 'cancel',
        label: 'Cancel',
        buttonProps: {
          icon: 'close',
        },
        confirm: {
          title: 'Cancel',
          explanation:
            'NOTE: Cancellation might take a short amount of time. Are you sure you want to cancel this job?',
          okButtonLabel: 'Confirm',
          type: 'info',
        },
        onAction: handleJobRowAction,
        hidden: (record) =>
          !!record &&
          (record.jobStatus === 'completed' ||
            record.jobStatus === 'failed' ||
            record.jobStatus === 'canceled'),
      },
    ];
    return actions;
  }, [handleJobRowAction]);

  const repeatableJobRowActions: IQueryTableDataActions<IRepeatableServiceJobs> = useMemo(() => {
    const actions: IQueryTableDataActions<IRepeatableServiceJobs> = [
      {
        key: 'cancel',
        label: 'Trash',
        buttonProps: {
          icon: 'delete',
        },
        confirm: {
          title: 'Cancel',
          explanation: 'NOTE: This will cancel all future jobs (not started). Are you sure?',
          okButtonLabel: 'Confirm',
          type: 'info',
        },
        onAction: handleRepeatableJobRowAction,
        hidden: (record) => !!record?.cancellable,
      },
    ];
    return actions;
  }, [handleRepeatableJobRowAction]);

  const topActions: IQueryTableDataActions = useMemo(
    () => [
      {
        key: 'add-job',
        label: 'Add service job',
        buttonProps: {
          icon: 'plus',
        },
        onAction: () => {
          dispatch(
            {
              type: ActionType.CREATE_SERVICE_JOB,
              repeatableJob: false,
            },

            () => {
              if (jobTableRef) {
                jobTableRef.current.refetch();
              }
            }
          );
        },
      },
    ],
    [dispatch]
  );

  const repeatableJobTopActions: IQueryTableDataActions = useMemo(
    () => [
      {
        key: 'add-repeatable-job',
        label: 'Add repeatable job',
        buttonProps: {
          icon: 'plus',
        },
        onAction: () => {
          dispatch(
            {
              type: ActionType.CREATE_SERVICE_JOB,
              repeatableJob: true,
            },

            () => {
              if (repeatableJobTableRef) {
                repeatableJobTableRef.current.refetch();
              }
              if (jobTableRef) {
                jobTableRef.current.refetch();
              }
            }
          );
        },
      },
    ],
    [dispatch]
  );

  return (
    <>
      <Alert type="info">Jobs are updated every {POLL_INTERVAL / 1000} seconds.</Alert>
      <TableWrapper>
        <QueryTable<GetServiceJobs, GetServiceJobsVariables>
          ref={jobTableRef}
          caption="Service Jobs"
          query={QGetServiceJobs}
          dataSource={(result) => result.getServiceJobs.items}
          itemTotal={(result) => result.getServiceJobs.itemsTotal}
          pollInterval={POLL_INTERVAL}
          rowKey={(item) => item.id}
          rowActions={jobRowActions}
          topActions={topActions}
          columns={SERVICE_JOB_COLUMNS}
          topContent={
            <Title level={2} style={{ display: 'inline' }}>
              Jobs
            </Title>
          }
          bottomContent={<QueryTable.Pagination />}
          perPage={10}
          variables={emptyObj}
        >
          <QueryTable.Data />
        </QueryTable>
      </TableWrapper>
      <QueryTable<GetRepeatableServiceJobs, GetRepeatableServiceJobsVariables>
        ref={repeatableJobTableRef}
        caption="Repeatable Cron Jobs"
        query={QGetRepeatableServiceJobs}
        dataSource={(result) => result.getRepeatableServiceJobs.items}
        itemTotal={(result) => result.getRepeatableServiceJobs.itemsTotal}
        pollInterval={POLL_INTERVAL}
        rowKey={(item) => item.id}
        rowActions={repeatableJobRowActions}
        columns={REPEATABLE_JOB_COLUMNS}
        topActions={repeatableJobTopActions}
        topContent={
          <Title level={2} style={{ display: 'inline' }}>
            Cron/Repeatable Jobs
          </Title>
        }
        bottomContent={<QueryTable.Pagination />}
        variables={emptyObj}
      >
        <QueryTable.Data />
      </QueryTable>
    </>
  );
};
