import {
  Button,
  Constant,
  FieldBox,
  FormError,
  type FormSubmitHandler,
  Icon,
  Input,
  type ISelectOption,
  ModalForm,
  Select,
  SimpleFieldAutoArray,
  spacing,
} from '@mortgagehippo/ds';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';

import { type ILenderMilestone } from './queries';
import { type IExternalMilestoneKey } from './use-external-milestone-keys';

type ILenderMilestoneModalFormValue = Pick<
  ILenderMilestone,
  'id' | 'name' | 'externalMilestoneKey'
>;

export interface ILenderMilestoneModalFormValues {
  lenderMilestones?: ILenderMilestoneModalFormValue[];
}

const Row = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: ${spacing(4)};
`;

const Col = styled.div<{ flex?: number; isDraggableIcon?: boolean }>`
  flex: ${({ flex }) => (flex === undefined ? 1 : flex)};
  flex-basis: ${({ flex }) => (flex === undefined ? '100%' : 'auto')};
  margin-right: 10px;

  ${(p) =>
    p.isDraggableIcon &&
    css`
      margin-top: ${spacing(1)};
    `}

  &:last-child {
    margin-right: 0;
  }
`;

const StyledIcon = styled(Icon)<{ isHidden?: boolean }>`
  ${(p) =>
    p.isHidden &&
    css`
      visibility: hidden;
    `}
`;

const validator = (
  value: ILenderMilestoneModalFormValue,
  allValues: ILenderMilestoneModalFormValues
) => {
  const { lenderMilestones } = allValues;

  if (!lenderMilestones?.length) {
    return undefined;
  }

  const { name, externalMilestoneKey } = value;

  let errors;

  if (lenderMilestones.filter((lenderMilestone) => lenderMilestone.name === name).length > 1) {
    errors = {
      name: 'Duplicate label',
    };
  }

  if (
    externalMilestoneKey &&
    lenderMilestones.filter(
      (lenderMilestone) => lenderMilestone.externalMilestoneKey === externalMilestoneKey
    ).length > 1
  ) {
    errors = {
      ...errors,
      externalMilestoneKey: 'Duplicate external key',
    };
  }

  return errors;
};

interface IEditLenderMilestoneModalProps {
  isOpen: boolean;
  initialValues?: ILenderMilestoneModalFormValues;
  onSubmit: FormSubmitHandler<ILenderMilestoneModalFormValues>;
  onRequestClose: () => void;
  loading: boolean;
  externalKeys: IExternalMilestoneKey[];
}

export const LenderMilestoneEditModal = (props: IEditLenderMilestoneModalProps) => {
  const { onSubmit, onRequestClose, isOpen, loading, initialValues = {}, externalKeys } = props;

  const externalKeySelectOptions = useMemo(() => {
    if (loading || !externalKeys.length) {
      return [];
    }
    const selectOptions: ISelectOption[] = externalKeys.map((externalKey) => ({
      label: externalKey.name,
      value: externalKey.name,
    }));
    return selectOptions;
  }, [externalKeys, loading]);

  return (
    <ModalForm<ILenderMilestoneModalFormValues>
      title="Edit Lender Milestones"
      isOpen={isOpen}
      loading={loading}
      okButtonLabel="Save milestones"
      onSubmit={onSubmit}
      onRequestClose={onRequestClose}
      initialValues={initialValues}
    >
      <FieldBox name="lenderMilestones" label="Lender Milestones" labelInvisible>
        <SimpleFieldAutoArray
          name="lenderMilestones"
          sortable
          presentFields={['name', 'externalMilestoneKey']}
          render={({ name: itemName, handleProps, onRemove, index, isLastItem, sortable }) => (
            <Row key={itemName}>
              <Col flex={0} isDraggableIcon {...(handleProps || {})}>
                <StyledIcon name="drag-handle" isHidden={!sortable} />
              </Col>
              <Col>
                <Constant name={`${itemName}.id`} />
                <Input.Box
                  name={`${itemName}.name`}
                  label="Label"
                  placeholder="Label"
                  size="sm"
                  labelInvisible
                  hideRequiredLabel
                  required={index !== 0 && !isLastItem}
                  compact
                />
              </Col>
              <Col>
                <Select.Box
                  name={`${itemName}.externalMilestoneKey`}
                  label="External Milestone Key"
                  placeholder="Map External Milestone Key"
                  options={externalKeySelectOptions}
                  notFoundContent="No External Milestone Keys Found"
                  size="sm"
                  labelInvisible
                  hideRequiredLabel
                  allowClear
                  compact
                />
              </Col>

              <Col flex={0}>
                <Button
                  icon="delete"
                  iconButton
                  size="xs"
                  type="danger"
                  importance="tertiary"
                  onClick={onRemove}
                  compact
                />
              </Col>
            </Row>
          )}
          validateItem={validator}
        />
      </FieldBox>
      <FormError alertSize="sm" />
    </ModalForm>
  );
};
