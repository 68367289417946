import { Alert } from '@mortgagehippo/ds';
import styled from 'styled-components';

const Container = styled.div`
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledAlert = styled(Alert)`
  max-width: 400px;
`;

export const EditorEmpty = () => (
  <Container>
    <StyledAlert type="warning">
      This item is not editable. Please choose another item from the navigation.
    </StyledAlert>
  </Container>
);
