import { uniqueId } from 'lodash-es';
import { createElement, type ReactNode, useCallback, useMemo, useRef, useState } from 'react';

import {
  ApplicationActionsContext,
  type IApplicationActionsContext,
} from './application-actions-context';
import { CreateLandingPageAction } from './create-landing-page-action';
import { CreatePartnerAction } from './create-partner-action';
import { CreateServiceJobAction } from './create-service-job-action';
import { DeleteLandingPageAction } from './delete-landing-page-action';
import { EditLandingPageAction } from './edit-landing-page-action';
import { EditPartnerAction } from './edit-partner-action';
import {
  CreateNotificationProfileAction,
  DeleteNotificationProfileAction,
  EditNotificationProfileAction,
  OverrideNotificationProfileAction,
} from './notification-profile-action';
import { type Action, type ActionListener, ActionStatus, ActionType } from './types';
import { UploadPartnerDocumentsAction } from './upload-partner-document-action';

const ActionComponents: Record<ActionType, any> = {
  [ActionType.CREATE_PARTNER]: CreatePartnerAction,
  [ActionType.CREATE_LANDING_PAGE]: CreateLandingPageAction,
  [ActionType.EDIT_LANDING_PAGE]: EditLandingPageAction,
  [ActionType.DELETE_LANDING_PAGE]: DeleteLandingPageAction,
  [ActionType.CREATE_NOTIFICATION_PROFILE]: CreateNotificationProfileAction,
  [ActionType.EDIT_NOTIFICATION_PROFILE]: EditNotificationProfileAction,
  [ActionType.DELETE_NOTIFICATION_PROFILE]: DeleteNotificationProfileAction,
  [ActionType.OVERRIDE_NOTIFICATION_PROFILE]: OverrideNotificationProfileAction,
  [ActionType.EDIT_PARTNER]: EditPartnerAction,
  [ActionType.CREATE_SERVICE_JOB]: CreateServiceJobAction,
  [ActionType.UPLOAD_PARTNER_DOCUMENT]: UploadPartnerDocumentsAction,
};

export interface IActionsProviderProps {
  children?: ReactNode;
}

export const ActionsProvider = (props: IActionsProviderProps) => {
  const { children } = props;

  const [actions, setActions] = useState<Array<[string, Action, ActionListener | undefined]>>([]);

  const listenersRef = useRef<
    Array<{
      listener: ActionListener;
      type?: ActionType;
      status?: ActionStatus;
    }>
  >([]);

  const handleAction = useCallback((action: Action, cb?: ActionListener) => {
    setActions((state) => [...state, [uniqueId(), action, cb]]);
    listenersRef.current.forEach(({ listener, type, status }) => {
      if ((type && action.type !== type) || (status && status !== ActionStatus.OPEN)) {
        return;
      }
      listener(action, ActionStatus.OPEN);
    });
  }, []);

  const handleSubscribe = (listener: ActionListener, type?: ActionType, status?: ActionStatus) => {
    listenersRef.current = [...listenersRef.current, { listener, type, status }];
    return () => {
      listenersRef.current = listenersRef.current.filter(({ listener: l }) => l !== listener);
    };
  };

  const handleSubmit = (action: Action) => () => {
    listenersRef.current.forEach(({ listener, type, status }) => {
      if ((type && action.type !== type) || (status && status !== ActionStatus.SUBMITTED)) {
        return;
      }
      listener(action, ActionStatus.SUBMITTED);
    });
  };

  const handleDone = (action: Action) => (result?: Record<string, unknown>) => {
    setActions((state) =>
      state.filter(([, a, cb]) => {
        if (a === action) {
          if (cb) {
            cb(a, ActionStatus.DONE, result || undefined);
          }

          listenersRef.current.forEach(({ listener, type, status }) => {
            if ((type && a.type !== type) || (status && status !== ActionStatus.DONE)) {
              return;
            }
            listener(a, ActionStatus.DONE, result || undefined);
          });
        }

        return a !== action;
      })
    );
  };

  const handleCancel = (action: Action) => (cancelResult?: Record<string, unknown>) => {
    setActions((state) =>
      state.filter(([, a, cb]) => {
        if (a === action) {
          if (cb) {
            cb(a, ActionStatus.CANCEL, cancelResult || undefined);
          }

          listenersRef.current.forEach(({ listener, type, status }) => {
            if ((type && a.type !== type) || (status && status !== ActionStatus.CANCEL)) {
              return;
            }
            listener(a, ActionStatus.CANCEL, cancelResult || undefined);
          });
        }

        return a !== action;
      })
    );
  };

  const value: IApplicationActionsContext = useMemo(
    () => ({
      dispatch: handleAction,
      subscribe: handleSubscribe,
    }),
    [handleAction]
  );

  return (
    <ApplicationActionsContext.Provider value={value}>
      {children}
      {actions.map(([id, action]) => {
        const { type } = action;
        const component = ActionComponents[type];
        return createElement(component, {
          key: id,
          action,
          onSubmit: handleSubmit(action),
          onDone: handleDone(action),
          onCancel: handleCancel(action),
        });
      })}
    </ApplicationActionsContext.Provider>
  );
};
