import './polyfills';
import './global.css';

import * as Sentry from '@sentry/browser';
import { escapeRegExp } from 'lodash-es';
import ReactDOM from 'react-dom';

import { App } from './app';
import { config } from './config';
import { IGNORED_ERRORS } from './ignored-errors';

// Configure sentry

// Hardcoded whitelisted URLs
const EncompassApi = new RegExp(`^${escapeRegExp('https://api.elliemae.com')}/?.*$`, 'i');
const Finicity = /^https:\/\/(.*).finicity.com\/?.*$/i;
const AccountChek = /^https:\/\/(.*).accountchek.net\/?.*$/i;
const GoogleMapsApi = /^https:\/\/maps.googleapis.com\/?.*$/i;
const Transnational = /^https:\/\/(.*).gotnpgateway.com\/?.*$/i;

const allowUrls = [EncompassApi, Finicity, AccountChek, GoogleMapsApi, Transnational];

// Allow reporting for in page scripts
allowUrls.push(
  new RegExp(
    `^${escapeRegExp(
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ''
      }`
    )}/?.*$`,
    'i'
  )
);

// Allow reporting from the CDN
const publicPath = process.env.PUBLIC_PATH;
// We filter out only if it has http because locally it won't
if (publicPath && publicPath.startsWith('http')) {
  allowUrls.push(new RegExp(`^${escapeRegExp(publicPath)}/?.*$`, 'i'));
}

Sentry.init({
  dsn: config.SENTRY_DSN,
  release: config.SENTRY_RELEASE,
  environment: config.ENVIRONMENT,
  ignoreErrors: IGNORED_ERRORS,
  allowUrls,
});

Sentry.configureScope((scope) => {
  scope.setTag('service', 'management-portal');
});

ReactDOM.render(<App />, document.getElementById('root'));
