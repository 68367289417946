import {
  Button,
  Form,
  type IButtonProps,
  Icon,
  Popover,
  spacing,
  T,
  useModal,
} from '@mortgagehippo/ds';
import styled from 'styled-components';

import { CodeEditor } from '$components/code-editor';
import { type ICodeEditorValidator } from '$components/code-editor/code-editor';

import { ArgumentsModal } from './arguments/arguments-modal';
import { type IEditorSaga, type IEditorSagaArgument } from './types';
import { isChanged } from './util';

const Actions = styled.div`
  text-align: right;
`;

const ConfirmationDialog = styled(Popover)`
  margin-right: ${spacing(3)};
`;

interface ISagasPageContentEditorProps {
  activeSaga?: IEditorSaga;
  onSave: () => void;
  onValidate: ICodeEditorValidator;
  onChange: (newValue: string, newArguments: IEditorSagaArgument[]) => void;
  onUndo: () => void;
  onDelete: () => void;
}

const deleteButtonProps: IButtonProps = {
  icon: 'delete',
  size: 'xs',
  importance: 'secondary',
  type: 'danger',
};

export const SagasPageContentEditor = (props: ISagasPageContentEditorProps) => {
  const { activeSaga, onSave, onChange, onUndo, onDelete, onValidate } = props;
  const [modalOpen, openModal, closeModal] = useModal(false);
  const hasChanges = activeSaga ? isChanged(activeSaga) : false;

  const handleArguments = () => {
    openModal();
  };

  const handleArgumentsSubmit = (values: any) => {
    const { arguments: newArguments } = values;

    if (!activeSaga) {
      return;
    }

    onChange(activeSaga.currentValue, newArguments);
    closeModal();
  };

  const handleChange = (value: string) => {
    if (!activeSaga) {
      return;
    }
    onChange(value, activeSaga.currentArguments);
  };

  if (!activeSaga) {
    return (
      <div style={{ padding: 40, textAlign: 'center' }}>
        <Icon name="edit" size="xxl" />
        <br />
        <br />
        Choose a saga from the left, to start editing
      </div>
    );
  }

  const deleteLabel =
    activeSaga.custom && activeSaga.system ? <T>Revert to Original</T> : <T>Delete</T>;

  const totalArguments = activeSaga.systemArguments.length + activeSaga.currentArguments.length;

  return (
    <>
      <Actions>
        {activeSaga.custom ? (
          <ConfirmationDialog
            content="This action is irreversible"
            title="Are you sure?"
            confirm
            onConfirm={onDelete}
            buttonProps={deleteButtonProps}
          >
            {deleteLabel}
          </ConfirmationDialog>
        ) : null}

        <Button
          icon="information"
          size="xs"
          onClick={handleArguments}
          importance="secondary"
          type="neutral"
        >
          Arguments {`(${totalArguments})`}
        </Button>

        <Button
          icon="undo"
          size="xs"
          onClick={onUndo}
          disabled={!activeSaga.isEdited}
          importance="secondary"
          type="neutral"
        >
          Undo changes
        </Button>

        <Button size="xs" onClick={onSave} importance="primary" disabled={!hasChanges}>
          Save
        </Button>
      </Actions>
      <Form>
        <CodeEditor
          mode="ruby"
          value={activeSaga.currentValue}
          onChange={handleChange}
          onValidate={onValidate}
          tabSize={2}
          path={activeSaga.name}
        />
      </Form>
      <ArgumentsModal
        saga={activeSaga}
        onRequestClose={closeModal}
        onSubmit={handleArgumentsSubmit}
        isOpen={modalOpen}
      />
    </>
  );
};
