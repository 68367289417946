import { ButtonLink, Icon } from '@mortgagehippo/ds';
import { MenuGroup, MenuItem } from '@mortgagehippo/menu';

import { usePartner } from '../../hooks/use-partner';
import { MenuTitle } from './menu-title';

interface IPartnerNavigation {
  partnerId: string;
}

export const PartnerNavigation = (props: IPartnerNavigation) => {
  const { partnerId } = props;
  const [partnerData, partnerDataLoading] = usePartner(partnerId);

  if (partnerDataLoading || !partnerData) {
    return null;
  }

  const { id, name } = partnerData;

  return (
    <>
      <MenuTitle>
        <span>
          <ButtonLink to="/partners">Partners</ButtonLink>
        </span>{' '}
        <strong>{name}</strong>
      </MenuTitle>
      <MenuItem label="Sites" to={`/partners/${id}`} exact />
      <MenuItem label="Credentials" to={`/partners/${id}/credentials`} exact />
      <MenuItem label="Blueprints" to={`/partners/${id}/blueprints`} exact />
      <MenuItem label="Pipeline" to={`/partners/${id}/pipeline`} exact />
      <MenuItem label="Custom Fields" to={`/partners/${partnerId}/customFields`} exact />
      <MenuItem label="Milestones" to={`/partners/${partnerId}/lender-milestones`} exact />
      <MenuItem label="Notifications" to={`/partners/${partnerId}/notifications`} exact />
      <MenuItem label="Access Levels" to={`/partners/${id}/auth-roles`} exact />
      <MenuItem
        label="Application Statuses"
        to={`/partners/${partnerId}/applicationStatuses`}
        exact
      />
      <MenuGroup label="Partner Customizations" expandIconPosition="left" compact>
        <MenuItem label="Namespaces" to={`/partners/${id}/customizations/namespaces`} exact />
        <MenuItem label="Images" to={`/partners/${id}/customizations/images`} exact />
        <MenuItem label="Colors" to={`/partners/${id}/customizations/colors`} exact />
      </MenuGroup>
      <MenuItem label="Documents" to={`/partners/${id}/documents`} exact />
      <MenuGroup label="Advanced" expandIconPosition="left" compact>
        <MenuItem label="Sagas" to={`/partners/${partnerId}/sagas`} exact />
        <MenuItem
          label="Pushback Endpoints"
          to={`/partners/${partnerId}/pushback-endpoints`}
          exact
        />
        <MenuItem label="Cascades" to={`/partners/${partnerId}/cascades`} exact />
        <MenuItem label="Webhook Configurations" to={`/partners/${id}/webhook-configurations`} exact />
      </MenuGroup>
      <MenuGroup label="Other Settings" expandIconPosition="left" compact>
        <MenuItem
          label="Unassigned Application Notifications Contacts"
          to={`/partners/${partnerId}/unassigned-application-notifications-contacts`}
          exact
        />
        <MenuItem
          label="Pre-Qualification Letter"
          to={`/partners/${partnerId}/pre-qualification-letter`}
          exact
        />
      </MenuGroup>
      <MenuItem
        label={
          <>
            Open Lender Hub <Icon name="open-in-new" size="sm" />
          </>
        }
        to={`https://${partnerData.adminDomain}/admin`}
        asNonRouterLink
        nonRouterLinkTarget="_blank"
      />
    </>
  );
};
