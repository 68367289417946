import { Input, TextArea } from '@mortgagehippo/ds';

export const SwitchArrayLabelsEditor = ({ name }: { name: string }) => (
  <>
    <Input.Box
      name={`${name}.addButtonLabel`}
      label="Add Button Label"
      placeholder="e.g. Add a property"
      required
    />
    <Input.Box
      name={`${name}.addMoreButtonLabel`}
      label="Add More Button Label"
      placeholder="e.g. Add another property"
      required
    />
    <Input.Box
      name={`${name}.variantChoiceLabel`}
      label="Variant field choice label"
      placeholder="e.g. Select property type"
      required
    />
    <TextArea.Box
      name={`${name}.variantChoiceDescription`}
      label="Variant field choice description"
    />
  </>
);
