import { type ITableActions } from '@mortgagehippo/ds';
import {
  type IQueryTableColumns,
  type IQueryTableDataActions,
  QueryTable,
} from '@mortgagehippo/query-components';
import { UnreachableCaseError } from '@mortgagehippo/util';
import { useCallback, useMemo, useRef } from 'react';
import { type RouteComponentProps } from 'react-router';

import { ActionType, useDispatchAction } from '$components/actions';

import {
  type SiteLandingPagesListQuery,
  type SiteLandingPagesListQueryVariables,
} from '../../apollo/graphql';
import { useSite } from '../../hooks/use-site';
import { Content, Layout } from '../../layouts/main';
import { QSiteLandingPagesList } from './queries';

enum LandingPageActionType {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export type ILandingPage = NonNullable<
  SiteLandingPagesListQuery['site']
>['landingPages']['items'][0];

const columns: IQueryTableColumns<ILandingPage> = [
  {
    title: 'Title',
    key: 'title',
    render: (t) => t.title,
  },
  {
    title: 'Path',
    key: 'path',
    render: (t) => t.path,
  },
];

interface ILandingPagesPageRouteParams {
  partnerId: string;
  siteId: string;
}

type ILandingPagesPageProps = RouteComponentProps<ILandingPagesPageRouteParams>;

export const LandingPagesPage = (props: ILandingPagesPageProps) => {
  const { match, history } = props;
  const { params } = match;
  const { partnerId, siteId } = params;
  const [site] = useSite(siteId);
  const tableRef = useRef<any>();

  const dispatch = useDispatchAction();

  const handleAdd = useCallback(() => {
    dispatch(
      {
        type: ActionType.CREATE_LANDING_PAGE,
        siteId,
      },
      () => {
        if (tableRef) {
          tableRef.current.refetch();
        }
      }
    );
  }, [dispatch, siteId]);

  const handleClickRow = useCallback(
    (record: ILandingPage) => {
      history.push(`/partners/${partnerId}/sites/${siteId}/landingPages/${record.id}`);
    },
    [history, partnerId, siteId]
  );

  const handleDelete = useCallback(
    (record: ILandingPage) => {
      dispatch(
        {
          type: ActionType.DELETE_LANDING_PAGE,
          landingPageId: record.id,
        },
        () => {
          if (tableRef) {
            tableRef.current.refetch();
          }
        }
      );
    },
    [dispatch]
  );

  const handleAction = useCallback(
    async (actionKey: string, record: ILandingPage) => {
      const actionType = actionKey as LandingPageActionType;

      switch (actionType) {
        case LandingPageActionType.EDIT:
          handleClickRow(record);
          break;
        case LandingPageActionType.DELETE:
          handleDelete(record);
          break;
        default: {
          throw new UnreachableCaseError(actionType);
        }
      }
    },
    [handleClickRow, handleDelete]
  );

  const actions: IQueryTableDataActions = useMemo(
    () => [
      {
        key: 'options',
        label: 'Options',
        buttonProps: {
          icon: 'menu-dots',
        },
        onGroupAction: handleAction,
        actions: [
          {
            key: LandingPageActionType.EDIT,
            label: 'Edit',
            iconProps: {
              name: 'edit',
            },
          },
          {
            key: LandingPageActionType.DELETE,
            label: 'Delete',
            iconProps: {
              name: 'delete',
            },
          },
        ],
      },
    ],
    [handleAction]
  );

  const topActions: ITableActions<ILandingPage> = useMemo(
    () => [
      {
        key: 'add-landing-page',
        label: 'Add landing page',
        buttonProps: {
          icon: 'plus',
        },
        onAction: handleAdd,
      },
    ],
    [handleAdd]
  );

  const siteName = site?.name || '';

  return (
    <Layout pageTitle={`${siteName} - Landing Pages`}>
      <Content title="Landing Pages" subTitle={siteName}>
        <QueryTable<SiteLandingPagesListQuery, SiteLandingPagesListQueryVariables>
          ref={tableRef}
          caption="Landing Pages"
          query={QSiteLandingPagesList}
          dataSource={(result) => (result.site ? result.site.landingPages.items : [])}
          itemTotal={(result) => (result.site ? result.site.landingPages.total : 0)}
          nextCursor={(result) => result.site?.landingPages.nextCursor}
          previousCursor={(result) => result.site?.landingPages.previousCursor}
          rowKey={(item) => item.id}
          columns={columns}
          variables={{ siteId }}
          rowActions={actions}
          topActions={topActions}
          onRowClick={handleClickRow}
          bottomContent={<QueryTable.Pagination />}
          hideTopBackground
        >
          <QueryTable.Data />
        </QueryTable>
      </Content>
    </Layout>
  );
};
