import { Input, Select, Url, useFormValue, YesNo } from '@mortgagehippo/ds';

import { CodeEditorField } from '$components/code-editor';
import { EditorField } from '$components/editor-field';

import { type IPageComponentFieldsProps } from './types';
import { generateColumnOptions } from './util';

const columnOptions = generateColumnOptions();

export const StartApplicationFields = (props: IPageComponentFieldsProps) => {
  const { languageId } = props;

  const [showShoppingButton] = useFormValue<boolean | undefined>('config.showShoppingButton');

  return (
    <>
      <Select.Box name="config.columns" label="Columns" options={columnOptions} />
      <EditorField.Box name={`resources.${languageId}.content.title`} label="Title" extended />
      <Input.Box
        name={`resources.${languageId}.text.start_button_text`}
        label="Start Button Text"
      />
      <Input.Box
        name={`resources.${languageId}.text.already_started_text`}
        label="Already Started Text"
      />
      <Input.Box name={`resources.${languageId}.text.resume_link_text`} label="Resume Link Text" />
      <YesNo.Box
        name="config.showShoppingButton"
        label="Show Secondary Button?"
        initialValue={false}
      />
      {showShoppingButton ? (
        <>
          <Input.Box
            name={`resources.${languageId}.text.shopping_button_text`}
            label="Secondary Button Text"
            placeholder="Just Shopping"
          />
          <Url.Box name="config.shoppingPageUrl" label="Shopping Page URL" />
        </>
      ) : null}
      <CodeEditorField.Box mode="scss" name="config.css" label="Custom SCSS" />
    </>
  );
};
