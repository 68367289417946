import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../../apollo/gql';

export const MDeleteNotificationProfileMutation = graphql(`
  mutation DeleteNotificationProfile($profileId: ID!) {
    deleteNotificationProfile(profileId: $profileId) {
      ...NotificationProfileFragment
    }
  }
`);

export const useDeleteNotificationProfile = () => {
  const deleteNotificationProfile = useMutation(MDeleteNotificationProfileMutation, undefined);

  return useCallback(
    async (profileId: string) => {
      await deleteNotificationProfile({ profileId });
    },
    [deleteNotificationProfile]
  );
};
