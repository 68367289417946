import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';

import {
  type PartnerSnapdocsSubscriptionsQuery,
  type SiteSnapdocsSubscriptionsQuery,
} from '../../../apollo/graphql';
import { QPartnerSnapdocsSubscriptions, QSiteSnapdocsSubscriptions } from '../queries';

export type ISnapdocsSubscription =
  | NonNullable<
      NonNullable<
        NonNullable<PartnerSnapdocsSubscriptionsQuery['partner']>['snapdocsSubscriptions']
      >[0]
    >
  | NonNullable<
      NonNullable<NonNullable<SiteSnapdocsSubscriptionsQuery['site']>['snapdocsSubscriptions']>[0]
    >;

export const useSnapdocsSubscriptions = (partnerId: string, siteId?: string | null) => {
  const isSite = !!siteId;

  const [partnerData, partnerDataLoading, , { refetch: refetchPartnerData }] = useQuery(
    QPartnerSnapdocsSubscriptions,
    {
      partnerId,
    },
    {
      suspend: false,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      skip: isSite,
    }
  );

  const [siteData, siteDataLoading, , { refetch: refetchSiteData }] = useQuery(
    QSiteSnapdocsSubscriptions,
    {
      siteId: siteId!,
    },
    {
      suspend: false,
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      skip: !isSite,
    }
  );

  const handleRefetch = useSafeCallback(async () => {
    if (isSite) {
      await refetchSiteData({
        siteId,
      });
    } else {
      await refetchPartnerData({
        partnerId,
      });
    }
  });

  const result: ISnapdocsSubscription[] = isSite
    ? siteData?.site?.snapdocsSubscriptions || []
    : partnerData?.partner?.snapdocsSubscriptions || [];
  const loading = siteDataLoading || partnerDataLoading;

  return [result, loading, handleRefetch] as const;
};
