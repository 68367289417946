import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import {
  MAddNotificationAlternateAgent,
  UNASSIGNED_APPLICATION_NOTIFICATION_TYPE,
} from './queries';

export const useAddNotificationAlternateAgent = (partnerId: string, siteId?: string) => {
  const addNotificationAlternateAgent = useMutation(MAddNotificationAlternateAgent, undefined);

  return useCallback(
    async (agentId: string) => {
      await addNotificationAlternateAgent({
        agentId,
        partnerId,
        siteId,
        notificationType: UNASSIGNED_APPLICATION_NOTIFICATION_TYPE,
      });
    },
    [addNotificationAlternateAgent, partnerId, siteId]
  );
};
