import { useQuery } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../apollo/gql';
import { type BlueprintType } from '../apollo/graphql';

export const QBlueprintList = graphql(`
  query BlueprintList($partnerId: ID!, $perPage: Int!, $cursor: String) {
    partner(id: $partnerId) {
      id
      blueprints(options: { perPage: $perPage, cursor: $cursor, orderBy: { name: "asc" } }) {
        total
        nextCursor
        previousCursor
        items {
          id
          name
          type
          createdAt
          updatedAt
          assignments {
            id
            name
          }
        }
      }
    }
  }
`);

export const useBlueprints = (partnerId: string, type?: BlueprintType) => {
  const [data, ...rest] = useQuery(QBlueprintList, {
    partnerId,
    perPage: 10000,
  });

  let items = data?.partner?.blueprints.items || [];

  if (type) {
    items = items.filter((i) => i.type === type);
  }

  return [items, ...rest] as const;
};
