import { Email, Input, YesNo } from '@mortgagehippo/ds';

export const HelloSignFields = () => (
  <>
    <Input.Box name="api_key" label="API Key" required />
    <Input.Box name="client_id" label="Client ID" required />
    <Email.Box
      name="email_address"
      label="Requester Email Address"
      description="This is used when creating a custom task based on an uploaded document"
    />
    <YesNo.Box name="test_mode" label="Test Mode" required />
  </>
);
