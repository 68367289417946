import { FormSection, Input, useFormValue } from '@mortgagehippo/ds';
import { noop } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';

const maskClientSecret = (val: string) => val.replace(/./g, '\u25CF');

export interface ITotalExpertFieldsProps {
  isEditing?: boolean;
}

export const TotalExpertFields = (props: ITotalExpertFieldsProps) => {
  const { isEditing } = props;
  const [clientSecret, setClientSecret] = useFormValue<string | undefined>('client_secret');
  const [hideClientSecret, setHideClientSecret] = useState(isEditing);

  const maskedClientSecret = useMemo(() => {
    const clientSecretValue = clientSecret || '';
    return hideClientSecret ? maskClientSecret(clientSecretValue) : clientSecretValue;
  }, [clientSecret, hideClientSecret]);

  const handleToggleIconClick = useCallback(() => {
    setHideClientSecret((prevState) => !prevState);
  }, []);

  return (
    <FormSection title="Total Expert Client Credentials">
      <Input.Box name="client_id" label="Client ID" required />
      <Input.InputBox
        name="client_secret"
        label="Client Secret"
        value={maskedClientSecret}
        onChange={
          hideClientSecret
            ? noop
            : (newValue) => {
                setClientSecret(newValue);
              }
        }
        enableShowHideTextToggleIcon={isEditing}
        onShowHideTextToggleIconClick={handleToggleIconClick}
        textShowHideToggleIconPressed={!hideClientSecret}
        readOnly={hideClientSecret}
        autoComplete="off"
        required
      />
    </FormSection>
  );
};
