import { Editor } from '@mortgagehippo/ckeditor';
import {
  borderRadius,
  createField,
  type IFieldInputProps,
  palette,
  spacing,
  Spinner,
} from '@mortgagehippo/ds';
import { Suspense } from 'react';
import styled from 'styled-components';

import { client } from '../../apollo/apollo-client';
import { graphql } from '../../apollo/gql';

const handleUpload = async (file: File) => {
  const filename = file.name;
  const result = await client.mutate({
    mutation: graphql(`
      mutation CreateCustomizationUploadUrl($filename: String!) {
        createCustomizationUploadUrl(filename: $filename) {
          uploadUrl
          downloadUrl
        }
      }
    `),
    variables: { filename },
  });

  const { uploadUrl, downloadUrl } = result.data.createCustomizationUploadUrl;
  return { uploadUrl, downloadUrl };
};

const LoadingContainer = styled.div`
  width: 100%;
  color: ${palette('neutral600')};
  border-radius: ${borderRadius(2)};
  border: 1px solid ${palette('neutral300')};
  padding: ${spacing(3)};
  background: ${palette('white')};
`;

const EditorFieldInput = (
  props: IFieldInputProps & {
    extended?: boolean;
  }
) => {
  const { name, value, onChange, extended } = props;

  return (
    <Suspense
      fallback={
        <LoadingContainer>
          <Spinner /> Loading editor...
        </LoadingContainer>
      }
    >
      <Editor
        key={name}
        value={value}
        onChange={onChange}
        onUpload={handleUpload}
        mode={extended ? 'extended' : 'default'}
      />
    </Suspense>
  );
};

export const EditorField = createField(EditorFieldInput);
