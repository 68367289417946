import { CascadeConfigurationsTable } from '$components/cascade-configurations';

interface IPartnerCascadesPageContentProps {
  partnerId: string;
  siteId: string;
  loading: boolean;
}

export const SiteCascadesPageContent = (props: IPartnerCascadesPageContentProps) => {
  const { partnerId, siteId, loading } = props;

  return <CascadeConfigurationsTable partnerId={partnerId} siteId={siteId} loading={loading} />;
};
