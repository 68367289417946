import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';
import { type ApolloQueryResult } from 'apollo-client';

import {
  type PartnerVerticalsQuery,
  type PartnerVerticalsQueryVariables,
  type VerticalType,
} from '../../../apollo/graphql';
import { QPartnerVerticals } from './queries';

export type IUsePartnerVerticalsResult = Readonly<
  [
    result: {
      verticals: VerticalType[];
    },
    loading: boolean,
    refetch: () => Promise<ApolloQueryResult<PartnerVerticalsQuery>>,
  ]
>;

export const usePartnerVerticals = (
  partnerId: string,
  skip?: boolean
): IUsePartnerVerticalsResult => {
  const [data, loading, , { refetch }] = useQuery<
    PartnerVerticalsQuery,
    PartnerVerticalsQueryVariables
  >(
    QPartnerVerticals,
    { partnerId },
    {
      suspend: false,
      fetchPolicy: 'network-only',
      skip,
    }
  );

  const handleRefresh = useSafeCallback(() => refetch({ partnerId }));

  const verticals = data?.partner?.verticals || [];

  return [{ verticals }, loading, handleRefresh] as const;
};
