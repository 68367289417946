import { Button, useFormValue } from '@mortgagehippo/ds';
import { useCallback, useContext, useEffect, useState } from 'react';

import { CodeEditor } from '$components/code-editor/code-editor';

import { EditorContext } from './editor-context';

export const JsonPreview = () => {
  const { path } = useContext(EditorContext);
  const [code, setCode] = useState('');
  const [value, setValue] = useFormValue(path || 'json');

  const handleChange = useCallback((nextCode: string) => {
    setCode(nextCode);
  }, []);

  const handleSave = () => {
    try {
      const nextValue = JSON.parse(code);
      setValue(nextValue);
    } catch (e) {
      // Don't update invalid code
    }
  };

  useEffect(() => {
    try {
      const nextCode = JSON.stringify(value || {}, null, 4);
      setCode(nextCode);
    } catch (e) {
      // Don't update invalid code
    }
  }, [value]);

  return (
    <>
      <CodeEditor mode="json" value={code || ''} onChange={handleChange} />
      <br />
      <Button icon="left-arrow" importance="secondary-outline" onClick={handleSave} block>
        Sync
      </Button>
    </>
  );
};
