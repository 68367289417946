import { UnassignedApplicationNotificationsAddAgentModalContent } from './unassigned-application-notifications-add-agent-modal-content';
import { type NotificationAlternateAgent } from './unassigned-application-notifications-table';

export interface IUnassignedApplicationNotificationsAddAgentModalProps {
  partnerId: string;
  siteId?: string;
  onClose: () => any;
  onSubmit: (agent: NotificationAlternateAgent) => any;
  isOpen: boolean;
  loading: boolean;
}

export const UnassignedApplicationNotificationsAddAgentModal = (
  props: IUnassignedApplicationNotificationsAddAgentModalProps
) => {
  const { partnerId, siteId, onClose, onSubmit, isOpen, loading } = props;

  return (
    <UnassignedApplicationNotificationsAddAgentModalContent
      partnerId={partnerId}
      siteId={siteId}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
};
