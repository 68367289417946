import { Format, notifications, styled, useModal } from '@mortgagehippo/ds';
import {
  type IQueryTableColumns,
  type IQueryTableDataActions,
  QueryTable,
} from '@mortgagehippo/query-components';
import { NotFoundError, useClipboard } from '@mortgagehippo/util';
import { useCallback, useMemo, useRef, useState } from 'react';
import { type RouteComponentProps } from 'react-router';
import { UnreachableCaseError } from 'ts-essentials';

import {
  type RegisteredIntegrationConnectionsQuery,
  type RegisteredIntegrationConnectionsQueryVariables,
} from '../../../apollo/graphql';
import { useDeleteRegisteredIntegrationConnection } from '../../../hooks/use-delete-registered-integration-connection';
import { usePartner } from '../../../hooks/use-partner';
import {
  type IRegisteredIntegrationConnection,
  QRegisteredIntegrationConnectionsQuery,
} from '../../../hooks/use-registered-integration-connections';
import { Content, Layout } from '../../../layouts/main';
import { PushbackEndpointModalContainer } from './pushback-endpoint-modal-container';
import { PushbackEndpointsSecurityColumn } from './pushback-endpoints-security-column';

enum PushbackEndpointsActionType {
  EDIT = 'edit',
  DELETE = 'delete',
  COPY_PATH = 'copy_path',
}

const PathContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
`;

const columns: IQueryTableColumns<IRegisteredIntegrationConnection> = [
  {
    title: 'Path',
    key: 'path_slug',
    verticalAlign: 'top',
    render: (t) => {
      const { connectionVersion, pathSlug, uuid, integrationType, integrationAction } = t;
      if (connectionVersion === 1) {
        return <PathContainer>{`/integrations/${pathSlug}`}</PathContainer>;
      }
      if (connectionVersion === 2) {
        return (
          <PathContainer>{`/integrations/v2/${uuid}/${integrationType}/${integrationAction}`}</PathContainer>
        );
      }

      return null;
    },
  },
  {
    title: 'Saga Name',
    key: 'saga_name',
    verticalAlign: 'top',
    render: (t) => t.sagaName,
  },
  {
    title: 'Security Info',
    key: 'security_type',
    verticalAlign: 'top',
    render: (t) => <PushbackEndpointsSecurityColumn registeredIntegrationConnection={t} />,
  },
  {
    title: 'Updated at',
    key: 'updated_at',
    verticalAlign: 'top',
    render: ({ updatedAt }) => <Format.Date value={updatedAt} format="date-long-time" />,
  },
];

interface IPushbackEndpointsPageParams {
  partnerId: string;
}

export const PushbackEndpointsPage = (props: RouteComponentProps<IPushbackEndpointsPageParams>) => {
  const { match } = props;
  const { params } = match;
  const { partnerId } = params;
  const ref = useRef<any>();

  const clipboard = useClipboard();

  const [registeredIntegrationConnectionId, setRegisteredIntegrationConnectionId] = useState<
    string | undefined
  >(undefined);
  const [isOpen, openModal, closeModal] = useModal(false);

  const [partner, loading] = usePartner(partnerId);

  const deleteRegisteredIntegrationConnection = useDeleteRegisteredIntegrationConnection();

  const handleAdd = useCallback(() => {
    openModal();
  }, [openModal]);

  const handleRowClick = useCallback(
    (record: IRegisteredIntegrationConnection) => {
      setRegisteredIntegrationConnectionId(record.id);
      openModal();
    },
    [openModal]
  );

  const handleDelete = useCallback(
    async (record: IRegisteredIntegrationConnection) => {
      try {
        await deleteRegisteredIntegrationConnection(record.id);
      } catch (e) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }

      if (ref.current) {
        ref.current.refetch();
      }
    },
    [deleteRegisteredIntegrationConnection]
  );

  const handleCopyPath = useCallback(
    async (record: IRegisteredIntegrationConnection) => {
      const { connectionVersion, pathSlug, integrationType, integrationAction, uuid } = record;
      let path = '/integrations';
      try {
        switch (connectionVersion) {
          case 1:
            if (!pathSlug) {
              throw new Error('Path slug is missing.');
            }
            path += `/${pathSlug}`;
            break;
          case 2:
            if (!uuid || !integrationType || !integrationAction) {
              throw new Error('Missing one of: uuid, integration type, integration action.');
            }
            path += `/v2/${uuid}/${integrationType}/${integrationAction}`;
            break;
          default:
            throw new Error('Unsupported connection version.');
        }

        const success = await clipboard.copy(path);

        if (success) {
          notifications.success({ message: 'Successfully copied path to clipboard.' });
        }
      } catch (e) {
        notifications.error({ message: e.message });
      }
    },
    [clipboard]
  );

  const handleAction = useCallback(
    async (actionKey: string, record: IRegisteredIntegrationConnection) => {
      const actionType = actionKey as PushbackEndpointsActionType;

      switch (actionType) {
        case PushbackEndpointsActionType.EDIT:
          handleRowClick(record);
          break;
        case PushbackEndpointsActionType.DELETE:
          await handleDelete(record);
          break;
        case PushbackEndpointsActionType.COPY_PATH:
          await handleCopyPath(record);
          break;
        default: {
          throw new UnreachableCaseError(actionType);
        }
      }
    },
    [handleCopyPath, handleDelete, handleRowClick]
  );

  const handleRequestClose = useCallback(() => {
    closeModal(() => {
      setRegisteredIntegrationConnectionId(undefined);
    });
  }, [closeModal]);

  const handleSubmit = useCallback(() => {
    closeModal(() => {
      setRegisteredIntegrationConnectionId(undefined);

      if (ref.current) {
        ref.current.refetch();
      }
    });
  }, [closeModal]);

  const rowActions: IQueryTableDataActions = useMemo(
    () => [
      {
        key: 'options',
        label: 'Options',
        buttonProps: {
          icon: 'menu-dots',
        },
        onGroupAction: handleAction,
        actions: [
          {
            key: PushbackEndpointsActionType.COPY_PATH,
            label: 'Copy Path',
            iconProps: {
              name: 'copy',
            },
          },
          {
            key: PushbackEndpointsActionType.EDIT,
            label: 'Edit',
            iconProps: {
              name: 'edit',
            },
          },
          {
            key: PushbackEndpointsActionType.DELETE,
            label: 'Delete',
            iconProps: {
              name: 'delete',
            },
            confirm: {
              title: 'Warning',
              explanation:
                'Are you sure you want to delete this pushback endpoint? This action cannot be undone.',
              type: 'warning',
            },
          },
        ],
      },
    ],
    [handleAction]
  );

  const topActions: IQueryTableDataActions = useMemo(
    () => [
      {
        key: 'add-pushback-endpoint',
        label: 'Create a new pushback endpoint',
        buttonProps: {
          icon: 'plus',
        },
        onAction: handleAdd,
      },
    ],
    [handleAdd]
  );

  if (loading) {
    return null;
  }

  if (!partner) {
    throw new NotFoundError();
  }

  return (
    <Layout pageTitle="Partners Management">
      <Content title="Pushback Endpoints" subTitle={partner.name}>
        <QueryTable<
          RegisteredIntegrationConnectionsQuery,
          RegisteredIntegrationConnectionsQueryVariables,
          IRegisteredIntegrationConnection
        >
          caption="Pushback Endpoints"
          ref={ref}
          query={QRegisteredIntegrationConnectionsQuery}
          variables={{ partnerId }}
          dataSource={(result) => result.partner?.registeredIntegrationConnections.items || []}
          itemTotal={(result) => result.partner?.registeredIntegrationConnections.total || 0}
          nextCursor={(result) => result.partner?.registeredIntegrationConnections.nextCursor}
          previousCursor={(result) =>
            result.partner?.registeredIntegrationConnections.previousCursor
          }
          rowKey={(item) => item.id}
          columns={columns}
          onRowClick={handleRowClick}
          rowActions={rowActions}
          topActions={topActions}
          bottomContent={<QueryTable.Pagination />}
          hideTopBackground
        >
          <QueryTable.Data />
        </QueryTable>

        <PushbackEndpointModalContainer
          partnerId={partnerId}
          registeredIntegrationConnectionId={registeredIntegrationConnectionId}
          onRequestClose={handleRequestClose}
          onSubmit={handleSubmit}
          isOpen={isOpen}
        />
      </Content>
    </Layout>
  );
};
