import {
  fontSize,
  type ITableActions,
  notifications,
} from '@mortgagehippo/ds';
import * as Sentry from '@sentry/browser';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { ParentType } from '../../apollo/graphql';
import { useDeleteWebhookConfiguration } from './hooks/use-delete-webhook-configuration';
import { useSaveWebhookConfiguration } from './hooks/use-save-webhook-configuration';
import { type IWebhookConfiguration, useWebhookConfiguration } from './hooks/use-webhook-configuration';
import {
  WebhookConfigurationsEditorContentTable,
} from './webhook-configurations-editor-content-table';

const DescriptionParagraph = styled.p`
  font-size: ${fontSize("sm")};
`;

interface IWebhookConfigurationsEditorContentProps {
  partnerId: string;
  siteId?: string;
  parentType: ParentType;
}

export const WebhookConfigurationsEditorContent = (props: IWebhookConfigurationsEditorContentProps) => {
  const { partnerId, siteId, parentType } = props;

  const saveWebhookConfiguration = useSaveWebhookConfiguration();
  const deleteWebhookConfiguration = useDeleteWebhookConfiguration();
  const [webhookConfiguration, webhookConfigurationLoading, refetchWebhookConfiguration] = useWebhookConfiguration({ parentId: siteId || partnerId, parentType, partnerId });

  const handleAdd = useCallback(
    async () => {
      try {
        await saveWebhookConfiguration({ parentId: siteId || partnerId, parentType, partnerId });

        notifications.success({ message: 'Webhook configuration successfully added.' });
      } catch (e: any) {
        Sentry.captureException(e, { extra: { message: 'Error creating webhook configuration.' } });
        notifications.error({
          message: 'An error occurred creating a webhook configuration. Please try again later.',
        });
        return; // do not refetch webhook configuration on error
      }
      await refetchWebhookConfiguration();
    },
    [saveWebhookConfiguration, siteId, partnerId, parentType, refetchWebhookConfiguration]
  );

  const handleDelete = useCallback(
    async () => {
      if (webhookConfiguration) {
        try {
          await deleteWebhookConfiguration({ id: webhookConfiguration.id });

          notifications.success({ message: 'Webhook configuration successfully deleted.' });
        } catch (e: any) {
          Sentry.captureException(e, { extra: { message: 'Error deleting webhook configuration.' } });
          notifications.error({
            message: 'An error occurred deleting this webhook configuration. Please try again later.',
          });
          return; // do not refetch webhook configuration on error
        }
        await refetchWebhookConfiguration();
      }
    },
    [webhookConfiguration, deleteWebhookConfiguration, refetchWebhookConfiguration]
  );

  const dataSource: IWebhookConfiguration[] = useMemo(() => {
    if (!webhookConfiguration) {
      return [];
    }

    return [webhookConfiguration];
  }, [webhookConfiguration]);

  const topActions: ITableActions = useMemo(() => {
    const isDisabled = !!webhookConfiguration && webhookConfiguration.parentType === parentType;
    const actions: ITableActions = [
      {
        key: 'add-webhook-configuration',
        label: parentType === ParentType.Site ? 'Add site-level webhook configuration' : 'Add webhook configuration',
        buttonProps: {
          icon: 'plus',
        },
        disabled: isDisabled,
        hidden: webhookConfigurationLoading,
        onAction: handleAdd,
      },
      {
        key: 'delete-webhook-configuration',
        label: 'Delete',
        buttonProps: {
          icon: 'delete',
        },
        hidden: webhookConfigurationLoading || !isDisabled,
        onAction: handleDelete,
        confirm: {
          title: 'Warning',
          explanation:
            'Are you sure you want to delete this webhook configuration? This action cannot be undone.',
          type: 'warning',
        },
      },
    ];
    return actions;
  }, [webhookConfiguration, parentType, webhookConfigurationLoading, handleAdd, handleDelete]);

  return (
    <>
      <DescriptionParagraph>
        This configuration is to be used to generate a GUID to allow webhook subscriptions to the activity feed via hookdeck.
        Any GUID here will need to be manually entered into hookdeck to set up the subscriptions.
      </DescriptionParagraph>
      <DescriptionParagraph>
        There is only one GUID allowed for a partner or site. If a GUID is set for a site and its partner, the site&apos;s
        GUID will take precedence. If there is only a GUID set for a partner, the partner&apos;s children sites will inherit
        that patner&apos;s GUID.
      </DescriptionParagraph>
      <WebhookConfigurationsEditorContentTable
        parentType={parentType}
        dataSource={dataSource}
        loading={webhookConfigurationLoading}
        title={(parentType === ParentType.Site && 'Site-Level Webhook Configuration') || undefined}
        emptyText="No webhook configuration."
        topActions={topActions}
        hideTopBackground
      />
    </>
  );
};
