import { Button, Col, FormSection, Input, Row, Select, useFieldArray } from '@mortgagehippo/ds';
import { isPresent } from '@mortgagehippo/util';
import { type MouseEvent, useEffect } from 'react';

import { LogicField } from '$components/logic-field';

export interface IValidationEditorProps {
  name: string;
}

export interface IValidationRowProps {
  name: string;
  onChange: (index: number) => void;
  onDelete: (e: MouseEvent<any>) => void;
}

const ValidationRow = (props: IValidationRowProps) => {
  const { name, onChange, onDelete } = props;

  return (
    <Row>
      <Col sm={6}>
        <Select.Box
          label="Level"
          name={`${name}.level`}
          onChange={onChange}
          initialValue="error"
          options={[
            { value: 'error', label: 'Error' },
            { value: 'warning', label: 'Warning' },
          ]}
        />
      </Col>
      <Col sm={8}>
        <LogicField.Box
          label="Condition"
          name={`${name}.condition`}
          onChange={onChange}
          placeholder="Label"
        />
      </Col>
      <Col sm={8}>
        <Input.Box
          label="Message"
          name={`${name}.message`}
          onChange={onChange}
          placeholder="Value"
        />
      </Col>
      <Col sm={1} style={{ paddingTop: 36 }}>
        <Button
          icon="delete"
          iconButton
          size="xs"
          type="danger"
          importance="tertiary"
          onClick={onDelete}
        />
      </Col>
    </Row>
  );
};

export const ValidationsEditor = (props: IValidationEditorProps) => {
  const { name } = props;
  const { fields } = useFieldArray(name, {
    format(value: any) {
      return value;
    },
    formatOnBlur: true,
  });
  const len = (fields.value || []).length;

  useEffect(() => {
    if (len < 1) {
      fields.push({ level: '', condition: '', message: '' });
    }
  }, [fields, len]);

  const handleChange = (index: number) => () => {
    const isLast = !fields.length || fields.length - 1 === index;

    if (!isLast) {
      return;
    }

    const changedItem = fields.value[index];

    const shouldCreateNewRow =
      isPresent(changedItem.level) ||
      isPresent(changedItem.condition) ||
      isPresent(changedItem.message);

    if (!shouldCreateNewRow) {
      return;
    }

    fields.push({ level: '', condition: '', message: '' });
  };

  const handleDelete = (index: number) => () => {
    fields.remove(index);
  };

  return (
    <FormSection title="Custom Validations">
      {fields.map((itemName: string, i: number) => (
        <ValidationRow
          key={itemName}
          name={itemName}
          onChange={handleChange(i)}
          onDelete={handleDelete(i)}
        />
      ))}
    </FormSection>
  );
};
