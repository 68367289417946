import { Input, Select, Url } from '@mortgagehippo/ds';

import { CodeEditorField } from '$components/code-editor';
import { EditorField } from '$components/editor-field';

import { type IPageComponentFieldsProps } from './types';
import { generateColumnOptions } from './util';

const columnOptions = generateColumnOptions();

export const JustShoppingFields = (props: IPageComponentFieldsProps) => {
  const { languageId } = props;

  return (
    <>
      <Select.Box name="config.columns" label="Columns" options={columnOptions} />
      <EditorField.Box name={`resources.${languageId}.content.title`} label="Title" extended />
      <Input.Box
        name={`resources.${languageId}.text.shopping_button_text`}
        label="Shopping Button Text"
      />
      <Url.Box name="config.shoppingPageUrl" label="Shopping Page URL" />
      <CodeEditorField.Box mode="scss" name="config.css" label="Custom SCSS" />
    </>
  );
};
