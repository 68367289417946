import { borderRadius, palette, shadow, spacing } from '@mortgagehippo/ds';
import {
  type ILandingPage,
  type ILandingPageComponentProps,
  LandingPage,
  LandingPageComponent,
} from '@mortgagehippo/tasks';
import styled from 'styled-components';

import { LandingPageEditorPreviewEmpty } from './landing-page-editor-preview-empty';
import { PageComponentFrame } from './page-components';

const FakePageContainer = styled.div`
  border: 4px solid ${palette('warning100')};
  border-radius: ${borderRadius(3)};
  box-shadow: ${shadow(3)};
  margin-top: ${spacing(4)};
`;

interface ILandingPageEditorPreviewProps {
  landingPage: ILandingPage;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => Promise<any>;
  onMoveUp?: (id: string) => Promise<any>;
  onMoveDown?: (id: string) => Promise<any>;
}

export const LandingPageEditorPreview = (props: ILandingPageEditorPreviewProps) => {
  const { landingPage, ...rest } = props;
  const lastPageComponent =
    landingPage.pageComponents.items[landingPage.pageComponents.items.length - 1];

  const renderPageComponent = (renderProps: ILandingPageComponentProps) => {
    const { pageComponent } = renderProps;
    const moveUpDisabled = pageComponent.position === 1;
    const moveDownDisabled = lastPageComponent && lastPageComponent.id === pageComponent.id;

    return (
      <PageComponentFrame
        pageComponentId={pageComponent.id}
        moveDownDisabled={moveDownDisabled}
        moveUpDisabled={moveUpDisabled}
        {...rest}
      >
        <LandingPageComponent {...renderProps} editMode />
      </PageComponentFrame>
    );
  };

  if (landingPage.pageComponents.items.length === 0) {
    return <LandingPageEditorPreviewEmpty />;
  }
  return (
    <FakePageContainer className="body">
      <LandingPage landingPage={landingPage} renderPageComponent={renderPageComponent} />
    </FakePageContainer>
  );
};
