import { Input, type ISelectOption, Select, Title, YesNo } from '@mortgagehippo/ds';

const LoanProductAdvisorPartyTypeOptions: ISelectOption[] = [
  {
    label: 'Loan Seller',
    value: 'LoanSeller',
  },
  {
    label: 'Correspondent Lender',
    value: 'CorrespondentLender',
  },
  {
    label: 'Housing Counseling Agency',
    value: 'HousingCounselingAgency',
  },
  {
    label: 'Mortgage Broker',
    value: 'MortgageBroker',
  },
  {
    label: 'Service Provider',
    value: 'ServiceProvider',
  },
  {
    label: 'Submitting Party',
    value: 'SubmittingParty',
  },
];

const LoanProductAdvisorTechnicalAffiliateOptions: ISelectOption[] = [
  {
    label: 'None',
    value: undefined,
  },
  {
    label: 'Meridian Link - Advantage Credit Inc',
    value: '226',
  },
  {
    label: 'Meridian Link - Advantage Plus Credit Reporting',
    value: '138',
  },
  {
    label: 'Meridian Link - Alliance 2020, Inc./CIS',
    value: '275',
  },
  {
    label: 'Meridian Link - American Reporting Company LLC',
    value: '016',
  },
  {
    label: 'Meridian Link - Birchwood Credit Services, Inc',
    value: '076',
  },
  {
    label: 'Meridian Link - Certified Credit Reporting, Inc.',
    value: '071',
  },
  {
    label: 'Meridian Link - CIC Credit',
    value: '128',
  },
  {
    label: 'Meridian Link - CIS',
    value: '022',
  },
  {
    label: 'Meridian Link - CISCO Credit',
    value: '086',
  },
  {
    label: 'Meridian Link - Credit Link LLC',
    value: '024',
  },
  {
    label: 'Meridian Link - Credit Plus, Inc',
    value: '017',
  },
  {
    label: 'Meridian Link - Credit Reporting Services, LLC',
    value: '330',
  },
  {
    label: 'Meridian Link - Credit Technologies, Inc',
    value: '181',
  },
  {
    label: 'Meridian Link - Credit Technology, Inc',
    value: '161',
  },
  {
    label: 'Meridian Link - Information Searching Company',
    value: '058',
  },
  {
    label: 'Meridian Link - KCB Credit',
    value: '309',
  },
  {
    label: 'Meridian Link - MeridianLink API',
    value: '322',
  },
  {
    label: 'Meridian Link - Merchants Credit Bureau',
    value: '078',
  },
  {
    label: 'Meridian Link - Merchants Credit Bureau (SAV',
    value: '321',
  },
  {
    label: 'Meridian Link - Premium Credit Bureau',
    value: '294',
  },
  {
    label: 'Meridian Link - Premium Credit Bureau Data',
    value: '323',
  },
  {
    label: 'Meridian Link - Professional Credit Services',
    value: '324',
  },
  {
    label: 'Meridian Link - SARMA',
    value: '302',
  },
  {
    label: 'Meridian Link - Service 1st Information Solutions LLC',
    value: '229',
  },
  {
    label: 'Meridian Link - SettlementOne Credit Corp.',
    value: '290',
  },
  {
    label: 'Meridian Link - SIR/Data Facts Inc.',
    value: '304',
  },
  {
    label: 'Meridian Link - Universal Credit Services',
    value: '127',
  },
];

export const LpaFields = () => (
  <>
    <sup>Note: Client ID and Client Secret are now system environment variables.</sup>
    <Input.Box name="user_name" label="Username" required />
    <Input.Box name="password" label="Password" required />
    <YesNo.Box name="production_mode" label="Production Mode" required />
    <Input.Box name="seller_number" label="Seller Number" />
    <Select.Box
      name="submitting_party_role"
      label="Submitting Party Role"
      options={LoanProductAdvisorPartyTypeOptions}
    />
    <Title level={3}>Credit Provider</Title>
    <Select.Box
      name="technical_affiliate"
      label="Technical Affiliate"
      options={LoanProductAdvisorTechnicalAffiliateOptions}
    />
    <Title level={3}>Government</Title>
    <Input.Box name="government.lender_identifier" label="FHA Lender Identifier" />
    <Input.Box name="government.sponsor_identifier" label="FHA Sponsor Identifier" />
  </>
);
