import {
  FORM_ERROR,
  Format,
  type ISelectOption,
  type ITableActions,
  type ITableCol,
  notifications,
  Select,
  Table,
  useModal,
  useModalConfirm,
} from '@mortgagehippo/ds';
import { lowerCase, upperFirst } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { type VerticalType } from '../../../apollo/graphql';
import {
  ExternalMilestoneKeyModal,
  type IExternalMilestoneKeyFormValues,
} from './external-milestone-key-modal';
import { useAddExternalMilestoneKey } from './use-add-external-milestone-key';
import { useDeleteExternalMilestoneKey } from './use-delete-milestone-external-key';
import { useEditExternalMilestoneKey } from './use-edit-milestone-external-key';
import {
  type IExternalMilestoneKey,
  useExternalMilestoneKeys,
} from './use-external-milestone-keys';

const TopContent = styled.div`
  max-width: 300px;
`;

const columns: Array<ITableCol<IExternalMilestoneKey>> = [
  {
    title: 'External Milestone Keys',
    key: 'external-milestone-key',
    render: (t) => t.name,
  },
  {
    title: 'Ignore',
    key: 'ignore',
    render: ({ ignore }) => (ignore ? 'Yes' : 'No'),
  },
  {
    title: 'Last Updated',
    key: 'last-updated',
    render: ({ updatedAt }) => <Format.Date format="fromnow" value={updatedAt} />,
  },
];

interface IExternalMilestoneKeysTableProps {
  partnerId: string;
  verticals: VerticalType[];
  loading: boolean;
}

export const ExternalMilestoneKeysTable = (props: IExternalMilestoneKeysTableProps) => {
  const { partnerId, verticals, loading } = props;
  const [isOpen, openModal, closeModal] = useModal(false);
  const [openExternalKeyInUseDialog, externalKeyInUseDialog] = useModalConfirm(
    'This External Milestone Key cannot be deleted. It is still likely mapped to one or more Borrower and/or Lender Milestones. Please remove those mappings first.',
    {
      type: 'error',
      cancelButtonLabel: 'Close',
      hideOkButton: true,
      disableOverlayClickClose: true,
    }
  );

  const [selectedExternalKey, setSelectedExternalKey] = useState<IExternalMilestoneKey | undefined>(
    undefined
  );
  const [selectedVertical, setSelectedVertical] = useState<VerticalType | null>(null);

  const [{ externalKeys }, externalKeysLoading, refetchExternalMilestoneKeys] =
    useExternalMilestoneKeys(partnerId, selectedVertical, loading || !selectedVertical);
  const addExternalKey = useAddExternalMilestoneKey();
  const editExternalKey = useEditExternalMilestoneKey();
  const deleteExternalKey = useDeleteExternalMilestoneKey();

  const handleVerticalChange = useCallback(
    (nextVertical: string) => {
      setSelectedVertical(nextVertical as VerticalType);
    },
    [setSelectedVertical]
  );

  const handleRowClick = useCallback(
    (record: IExternalMilestoneKey) => {
      if (!record) {
        return;
      }
      setSelectedExternalKey(record);
      openModal();
    },
    [openModal]
  );

  const handleRowAction = useCallback(
    (_actionName: string, record?: IExternalMilestoneKey) => {
      if (!record) {
        return;
      }
      setSelectedExternalKey(record);
      openModal();
    },
    [openModal]
  );

  const handleDeleteKey = useCallback(async () => {
    if (!selectedExternalKey) {
      return;
    }

    try {
      await deleteExternalKey(selectedExternalKey.id);
    } catch (e: unknown) {
      await openExternalKeyInUseDialog();
    }

    notifications.success({ message: 'Successfully deleted external key.' });

    await refetchExternalMilestoneKeys();

    closeModal(() => {
      setSelectedExternalKey(undefined);
    });
  }, [
    closeModal,
    deleteExternalKey,
    openExternalKeyInUseDialog,
    refetchExternalMilestoneKeys,
    selectedExternalKey,
  ]);

  const handleModalClose = useCallback(() => {
    closeModal(() => {
      setSelectedExternalKey(undefined);
    });
  }, [closeModal]);

  const handleModalSubmit = useCallback(
    async (values: IExternalMilestoneKeyFormValues) => {
      const { id, name, ignore } = values;

      if (!name || !selectedVertical) {
        return { [FORM_ERROR]: 'No key or selected vertical.' };
      }

      try {
        if (id) {
          await editExternalKey(id, name, ignore!);
        } else {
          await addExternalKey(partnerId, selectedVertical, name, ignore!);
        }
      } catch (e: unknown) {
        return {
          [FORM_ERROR]:
            'Error adding external key! It is likely that this external key already exists.',
        };
      }

      notifications.success({ message: `Successfully ${id ? 'saved' : 'added'} external key.` });

      await refetchExternalMilestoneKeys();

      closeModal(() => {
        setSelectedExternalKey(undefined);
      });

      return undefined;
    },
    [
      addExternalKey,
      closeModal,
      editExternalKey,
      partnerId,
      refetchExternalMilestoneKeys,
      selectedVertical,
    ]
  );

  const topActions: ITableActions = useMemo(() => {
    const newActions: ITableActions = [
      {
        key: 'add-external-milestone-key',
        label: 'Add external milestone key',
        buttonProps: {
          icon: 'plus',
        },
        onAction: (_actionKey) => {
          openModal();
        },
      },
    ];
    return newActions;
  }, [openModal]);

  const rowActions: ITableActions<IExternalMilestoneKey> = useMemo(() => {
    const actions: ITableActions<IExternalMilestoneKey> = [
      {
        key: 'edit',
        label: 'Edit',
        buttonProps: {
          icon: 'edit',
          iconButton: false,
          iconLocation: 'left',
        },
        onAction: handleRowAction,
      },
    ];
    return actions;
  }, [handleRowAction]);

  const topContent = useMemo(() => {
    const verticalOptions: ISelectOption[] = verticals.map((vertical) => ({
      label: upperFirst(lowerCase(vertical)),
      value: vertical,
    }));

    return (
      <TopContent>
        <Select.Input
          name="vertical"
          options={verticalOptions}
          value={selectedVertical}
          onChange={handleVerticalChange}
          aria-label="Select vertical to see its external keys"
          placeholder="Select vertical to see its external keys"
          size="sm"
          compact
        />
      </TopContent>
    );
  }, [handleVerticalChange, selectedVertical, verticals]);

  useEffect(() => {
    if (!loading && !externalKeysLoading && !selectedVertical && verticals.length) {
      setSelectedVertical(verticals[0]!);
    }
  }, [externalKeysLoading, loading, selectedVertical, setSelectedVertical, verticals]);

  const emptyText = `This Partner has no External Milestone Keys for vertical ${selectedVertical}`;

  return (
    <>
      <Table<IExternalMilestoneKey>
        caption="External Milestone Keys"
        rowKey={(item) => item.id}
        data={externalKeys || []}
        cols={columns}
        columnTitleCasing="uppercase"
        size="sm"
        topActions={topActions}
        rowActions={rowActions}
        topContent={topContent}
        onRowClick={handleRowClick}
        loading={loading || externalKeysLoading}
        emptyText={emptyText}
      />
      <ExternalMilestoneKeyModal
        isOpen={isOpen}
        onSubmit={handleModalSubmit}
        onCancel={handleModalClose}
        onDelete={handleDeleteKey}
        loading={loading || externalKeysLoading}
        initialValues={selectedExternalKey}
      />
      {externalKeyInUseDialog}
    </>
  );
};
