import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo/gql';
import { type ApplicationFileStatusInput } from '../../apollo/graphql';

export const MUpdateApplicationStatus = graphql(`
  mutation UpdateApplicationStatus($statusId: ID!, $data: ApplicationFileStatusInput!) {
    updateApplicationFileStatus(statusId: $statusId, data: $data) {
      ...ApplicationFileStatusFragment
    }
  }
`);

export const useUpdateApplicationStatus = () => {
  const fn = useMutation(MUpdateApplicationStatus);

  return useCallback(
    async (statusId: string, data: ApplicationFileStatusInput) => {
      const result = await fn({ statusId, data });

      return result.data!.updateApplicationFileStatus;
    },
    [fn]
  );
};
