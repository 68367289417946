import { Route } from 'react-router';

import { GeneralEditorTab } from './tabs/general-editor-tab';
import { ImagesTab } from './tabs/images-tab';
import { ThemeTab } from './tabs/theme-tab';
import { type ICustomizationsEditorTabProps } from './types';

interface ICustomizationRoutes extends ICustomizationsEditorTabProps {
  basePath: string;
  unsavedChangesCount: number;
  setUnsavedChangesCount: (count: number) => void;
}

export const CustomizationsRoutes = (props: ICustomizationRoutes) => {
  const { basePath, unsavedChangesCount, setUnsavedChangesCount, ...rest } = props;

  return (
    <>
      <Route
        path={`${basePath}/namespaces`}
        render={() => (
          <GeneralEditorTab
            unsavedChangesCount={unsavedChangesCount}
            setUnsavedChangesCount={setUnsavedChangesCount}
            {...rest}
          />
        )}
        exact
      />
      <Route
        path={`${basePath}/`}
        render={() => (
          <GeneralEditorTab
            unsavedChangesCount={unsavedChangesCount}
            setUnsavedChangesCount={setUnsavedChangesCount}
            {...rest}
          />
        )}
        exact
      />

      <Route path={`${basePath}/images`} render={() => <ImagesTab {...rest} />} exact />
      <Route path={`${basePath}/colors`} render={() => <ThemeTab {...rest} />} exact />
    </>
  );
};
