import { useMutation } from '@mortgagehippo/apollo-hooks';
import { notifications, type UploadHandler } from '@mortgagehippo/ds';
import axios, { type AxiosProgressEvent } from 'axios';
import { uniqueId } from 'lodash-es';
import { useCallback } from 'react';

import { MCreateCustomizationResourceUploadUrl, MSetCustomizationValues } from '../../queries';
import { ImagesModal } from './images-modal';

interface IImagesModalContainerProps {
  resourceId: string;
  partnerId: number;
  domain?: string | null;
  language: string;
  onRequestClose: () => void;
  onSubmit: () => void;
}

export const ImagesModalContainer = (props: IImagesModalContainerProps) => {
  const { partnerId, domain, resourceId, language, onRequestClose, onSubmit } = props;

  const createPostUrl = useMutation(MCreateCustomizationResourceUploadUrl);

  const setCustomizationValues = useMutation(MSetCustomizationValues);

  const handleUpload: UploadHandler = useCallback(
    async (file, meta, progress) => {
      try {
        const createPostUrlInput = {
          partnerId,
          domain,
          language,
          filename: meta.filename,
        };

        const response = await createPostUrl({
          resourceId,
          data: createPostUrlInput,
        });

        const { downloadUrl, uploadUrl } = response.data!.createCustomizationResourceUploadUrl;

        await axios.put(uploadUrl, file, {
          headers: {
            'Content-Type': meta.mime,
          },
          onUploadProgress: (e: AxiosProgressEvent) => {
            if (!e.total) return;
            const percent = Math.round((e.loaded * 100) / e.total);
            progress(percent);
          },
        });

        const setCustomizationValuesInput = {
          resourceId,
          partnerId,
          domain,
          language,
          value: downloadUrl,
        };

        await setCustomizationValues({ data: [setCustomizationValuesInput] });
        onSubmit();

        return { id: uniqueId() };
      } catch (e) {
        notifications.error({ message: 'An unexpected error occurred while uploading your file.' });
        throw e;
      }
    },
    [createPostUrl, language, onSubmit, partnerId, resourceId, setCustomizationValues, domain]
  );

  return <ImagesModal onRequestClose={onRequestClose} onUpload={handleUpload} />;
};
