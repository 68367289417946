import { type CreatePartnerInput } from '../../../apollo/graphql';

export const toServerInput = (values: any): CreatePartnerInput => {
  const { partner, auth, agent, site, sourceId, sourceSites } = values;
  const {
    name: partnerName,
    admin_domain: adminDomain,
    is_billable: isBillable,
    admin_inactivity_timeout: adminInactivityTimeout,
    timezone,
  } = partner;
  const {
    auth0_domain: auth0Domain,
    auth0_api_identifier: auth0ApiIdentifier,
    auth0_management_application_client_id: auth0ManagementApplicationClientId,
    auth0_management_application_client_secret: auth0ManagementApplicationClientSecret,
  } = auth;
  const { vertical_name: verticalName, name: siteName, domain, live_site: liveSite } = site;
  const {
    email,
    password,
    name: agentName,
    license,
    phone,
    secondary_email: secondaryEmail = [],
  } = agent;

  const secondaryEmailInput = secondaryEmail.reduce((accumulator: string[], item: any) => {
    if (item.email) {
      accumulator.push(item.email);
    }

    return accumulator;
  }, []);

  const siteData = sourceSites?.length ? {} : {
    site: {
      name: siteName,
      verticalName,
      domain,
      liveSite,
    }
  };

  const sourceSitesData = sourceSites?.length ? {
    sourceSites: sourceSites?.map((sourceSite: any) => {
      const { id, params } = sourceSite;

      return {
        id,
        params: {
          name: params?.name,
          verticalName: params?.vertical_name,
          domain: params?.domain,
          liveSite: params?.live_site,
        }
      }
    })
  } : {};

  const sourceIdData = sourceId && sourceId !== "none" ? {
    sourceId: Number(sourceId)
  } : {};

  return {
    ...sourceIdData,
    ...sourceSitesData,
    partner: {
      name: partnerName,
      adminDomain,
      isBillable,
      adminInactivityTimeout,
      timezone,
    },
    auth: {
      auth0Domain,
      auth0ApiIdentifier,
      auth0ManagementApplicationClientId,
      auth0ManagementApplicationClientSecret,
    },
    ...siteData,
    agent: {
      name: agentName,
      email,
      password,
      license,
      phone,
      secondaryEmail: secondaryEmailInput,
    },
  };
};
