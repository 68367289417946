import { HellosignTask, type IHellosignTask } from '@mortgagehippo/tasks';
import { type ReactNode } from 'react';

import { HellosignSignatureStatus } from '../../../apollo/graphql';
import { TaskPreviewWrapper } from '../task-preview-wrapper';

export interface IHellosignTaskPreviewProps {
  task: IHellosignTask;
  onComplete?: () => Promise<any>;
  renderComplete?: () => ReactNode;
}

const eSignRequests = [
  {
    id: '1',
    title: null,
    clientId: 'test',
    documents: [],
    signatures: [
      {
        id: '1',
        referenceId: '1',
        signerName: 'Borrower 1',
        status: HellosignSignatureStatus.awaiting_signature,
        signable: false,
        signedAt: null,
      },
    ],
  },
];

export const HellosignTaskPreview = (props: IHellosignTaskPreviewProps) => {
  const { task, renderComplete, onComplete } = props;

  return (
    <TaskPreviewWrapper task={task}>
      {({ nextTask, frame }) => (
        <HellosignTask
          task={nextTask as IHellosignTask}
          applicationFileId="1"
          applicantId="1"
          eSignRequests={eSignRequests}
          onComplete={onComplete}
          frame={frame}
          renderComplete={renderComplete}
        />
      )}
    </TaskPreviewWrapper>
  );
};
