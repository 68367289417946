import {
  type IApplicationFileApplicant,
  type IInviteApplicantsTask,
  InviteApplicantsTask,
} from '@mortgagehippo/tasks';
import { type ReactNode } from 'react';

import { TaskPreviewWrapper } from '../task-preview-wrapper';

export interface IInviteApplicantsTaskPreviewProps {
  task: IInviteApplicantsTask;
  onComplete?: () => Promise<any>;
  renderComplete?: () => ReactNode;
}

const applicants: IApplicationFileApplicant[] = [
  {
    id: '1',
    email: 'editor@mortgagehippo.com',
    firstName: null,
    lastName: null,
    name: null,
    phoneNumber: null,
    position: 1,
    unregistered: false,
    answers: {},
    sharingEmail: false,
  },
];

export const InviteApplicantsTaskPreview = (props: IInviteApplicantsTaskPreviewProps) => {
  const { task, onComplete: _onComplete, renderComplete } = props;

  return (
    <TaskPreviewWrapper task={task}>
      {({ nextTask, frame }) => (
        <InviteApplicantsTask
          task={nextTask as IInviteApplicantsTask}
          initialValue={null}
          applicantId="1"
          applicants={applicants}
          frame={frame}
          renderComplete={renderComplete}
        />
      )}
    </TaskPreviewWrapper>
  );
};
