import { type FieldSize, type ISelectOption, Select } from '@mortgagehippo/ds';

import { useLanguages } from '../../hooks/use-languages';

export interface ILanguageSelectProps {
  name: string;
  onChange: (value: string) => void;
  value: string;
  label: string;
  size?: FieldSize;
  suspend?: boolean;
}

export const LanguageSelect = (props: ILanguageSelectProps) => {
  const { suspend, label, ...rest } = props;

  const [languages, loading] = useLanguages(!!suspend);

  const options: ISelectOption[] = languages ? [...languages] : [];

  return (
    <Select.Input
      options={options}
      size="sm"
      loading={loading}
      {...rest}
      aria-label={label}
      compact
    />
  );
};
