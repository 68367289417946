import { type FormSubmitHandler, Input, ModalForm, Slug } from '@mortgagehippo/ds';

interface IEditLandingPageModalProps {
  initialValues?: any;
  onSubmit: FormSubmitHandler;
  onRequestClose: () => void;
  isOpen: boolean;
  loading: boolean;
}

export const EditLandingPageModal = (props: IEditLandingPageModalProps) => (
  <ModalForm title="Edit Landing Page" {...props}>
    <Input.Box name="title" label="Title" />
    <Slug.Box name="path" label="Path" />
  </ModalForm>
);
