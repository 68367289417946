import { Format } from '@mortgagehippo/ds';
import { type IQueryTableColumns } from '@mortgagehippo/query-components';

import {
  type GetRepeatableServiceJobsQuery as GetRepeatableServiceJobs,
  type GetServiceJobsQuery as GetServiceJobs,
} from '../../apollo/graphql';

export const SERVICE_JOB_COLUMNS: IQueryTableColumns<GetServiceJobs['getServiceJobs']['items'][0]> =
  [
    {
      key: 'job_type',
      title: 'Job Type',
      render: ({ jobType }) => jobType,
    },
    {
      key: 'job_status',
      title: 'Job Status',
      render: ({ jobStatus }) => jobStatus,
    },
    {
      key: 'model',
      title: 'Model',
      render: ({ model }) => model,
    },
    {
      key: 'partnerId',
      title: 'Partner ID',
      render: ({ partnerId }) => partnerId,
    },
    {
      key: 'progress',
      title: 'Job/Scan Progress',
      render: ({ progress }) => `${progress}%`,
    },
    {
      key: 'current_batch_count',
      title: 'Current Batch Count',
      render: ({ currentBatchCount }) => currentBatchCount,
    },
    {
      key: 'total_batch_count',
      title: 'Total Batch Count',
      render: ({ totalBatchCount }) => totalBatchCount,
    },
    {
      key: 'start_date',
      title: 'Start Date',
      render: ({ startDate }) =>
        !!startDate && <Format.Date value={startDate} format="date-med-time" />,
    },
  ];

export const REPEATABLE_JOB_COLUMNS: IQueryTableColumns<
  GetRepeatableServiceJobs['getRepeatableServiceJobs']['items'][0]
> = [
  {
    key: 'job_type',
    title: 'Job Type',
    render: ({ jobType }) => jobType,
  },
  {
    key: 'model',
    title: 'Model',
    render: ({ model }) => model,
  },
  {
    key: 'partnerId',
    title: 'Partner ID',
    render: ({ partnerId }) => partnerId,
  },
  {
    key: 'cron_schedule',
    title: 'Cron Schedule',
    render: ({ cronSchedule }) => cronSchedule,
  },
  {
    key: 'repeat_schedule',
    title: 'Repeat Interval',
    render: ({ repeatMillisInterval }) =>
      !!repeatMillisInterval && `${Math.round(repeatMillisInterval / (1000 * 60))} minutes`,
  },
  {
    key: 'start_date',
    title: 'Start Date',
    render: ({ startDate }) =>
      !!startDate && <Format.Date value={startDate} format="date-med-time" />,
  },
  {
    key: 'last_ran',
    title: 'Last Ran',
    render: ({ lastRan }) => !!lastRan && <Format.Date value={lastRan} format="date-med-time" />,
  },
  {
    key: 'last_status',
    title: 'Last Status',
    render: ({ lastStatus }) => lastStatus,
  },
];
