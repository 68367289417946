import { Input, Numeric, Tabs, YesNo } from '@mortgagehippo/ds';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { FieldEditorFrame } from './field-editor-frame';
import { NumericValidationEditor } from './numeric-validation-editor';

export const NumericEditor = ({ name }: { name: string }) => (
  <FieldEditorFrame name={name} validations={() => <NumericValidationEditor name={name} />}>
    {() => (
      <Tabs.Tab id="numeric" label="Numeric Options">
        <EditorTabWrapper size="md">
          <Input.Box name={`${name}.placeholder`} label="Placeholder" />
          <YesNo.Box name={`${name}.allowNegative`} label="Allow Negative Values" />
          <Input.Box name={`${name}.prefix`} label="Prefix" />
          <Input.Box name={`${name}.suffix`} label="Suffix" />
          <Numeric.Box name={`${name}.decimals`} label="Decimals" />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
  </FieldEditorFrame>
);
