import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';
import { type ApolloQueryResult } from 'apollo-client';

import {
  type LenderMilestonesQuery,
  type LenderMilestonesQueryVariables,
  type VerticalType,
} from '../../../apollo/graphql';
import { type ILenderMilestone, QLenderMilestones } from './queries';

export type IUseLenderMilestoneResult = Readonly<
  [
    result: {
      lenderMilestones: ILenderMilestone[];
    },
    loading: boolean,
    refetch: () => Promise<ApolloQueryResult<LenderMilestonesQuery>>,
  ]
>;

export const useLenderMilestones = (
  partnerId: string,
  vertical: VerticalType | undefined,
  skip?: boolean
): IUseLenderMilestoneResult => {
  const [data, loading, , { refetch }] = useQuery<
    LenderMilestonesQuery,
    LenderMilestonesQueryVariables
  >(
    QLenderMilestones,
    { partnerId, vertical: vertical || null },
    {
      suspend: false,
      fetchPolicy: 'network-only',
      skip: skip || !vertical,
    }
  );

  const handleRefresh = useSafeCallback(() => refetch({ partnerId, vertical: vertical || null }));

  const lenderMilestones = data?.partner?.lenderMilestones || [];

  return [{ lenderMilestones }, loading, handleRefresh] as const;
};
