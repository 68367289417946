import { Input, Numeric } from '@mortgagehippo/ds';

import { CodeEditorField } from '$components/code-editor';

export const IFrameFields = () => (
  <>
    <Numeric.Box name="config.height" label="Height" />
    <Input.Box name="config.url" label="URL" />
    <CodeEditorField.Box mode="scss" name="config.css" label="Custom SCSS" />
  </>
);
