import { type UploadHandler, UploadInput } from '@mortgagehippo/ds';
import { ACCEPTED_DOCUMENT_MIME_TYPES } from '@mortgagehippo/tasks';

interface ITemplatesFormDocusignTaskFieldsProps {
  onUploadDocument: UploadHandler;
}

export const TemplatesFormDocusignTaskFields = (props: ITemplatesFormDocusignTaskFieldsProps) => {
  const { onUploadDocument } = props;

  return (
    <UploadInput.Box
      name="data.documents"
      label="Documents"
      description="Partner level documents will be created from the uploaded files immediately"
      onUpload={onUploadDocument}
      maxItems={10}
      accept={ACCEPTED_DOCUMENT_MIME_TYPES}
      fileTypeDescription="document"
    />
  );
};
