import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo/gql';

export const MCancelServiceJob = graphql(`
  mutation CancelServiceJob($id: ID!) {
    cancelServiceJob(id: $id)
  }
`);

export const MCancelRepeatableServiceJob = graphql(`
  mutation CancelRepeatableServiceJob($id: ID!) {
    cancelRepeatableServiceJob(id: $id)
  }
`);

export const MPauseServiceJob = graphql(`
  mutation PauseServiceJob($id: ID!) {
    pauseServiceJob(id: $id)
  }
`);

export const MResumeServiceJob = graphql(`
  mutation ResumeServiceJob($id: ID!) {
    resumeServiceJob(id: $id)
  }
`);

export const useCancelServiceJob = () => {
  const cancelServiceJob = useMutation(MCancelServiceJob, undefined);
  return useCallback(
    async (id: string): Promise<any> => {
      const response = await cancelServiceJob({
        id,
      });
      return response.data;
    },
    [cancelServiceJob]
  );
};

export const useCancelRepeatableServiceJob = () => {
  const cancelRepeatableServiceJob = useMutation(MCancelRepeatableServiceJob, undefined);
  return useCallback(
    async (id: string): Promise<any> => {
      const response = await cancelRepeatableServiceJob({
        id,
      });
      return response.data;
    },
    [cancelRepeatableServiceJob]
  );
};

export const usePauseServiceJob = () => {
  const pauseServiceJob = useMutation(MPauseServiceJob, undefined);
  return useCallback(
    async (id: string) => {
      const response = await pauseServiceJob({
        id,
      });
      return response.data!.pauseServiceJob;
    },
    [pauseServiceJob]
  );
};

export const useResumeServiceJob = () => {
  const resumeServiceJob = useMutation(MResumeServiceJob, undefined);
  return useCallback(
    async (id: string) => {
      const response = await resumeServiceJob({
        id,
      });
      return response.data!.resumeServiceJob;
    },
    [resumeServiceJob]
  );
};
