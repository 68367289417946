import { Choice } from '@mortgagehippo/ds';
import styled from 'styled-components';

import { type IValueEditorProps } from './types';

const BoolContainer = styled.div`
  max-width: 400px;
`;

export const BoolEditor = (props: IValueEditorProps) => {
  const { onChange, value, labelId, disabled } = props;

  return (
    <BoolContainer>
      <Choice.Input
        name="customization_value"
        aria-labelledby={labelId}
        columns={2}
        value={value}
        onChange={onChange}
        disabled={disabled}
        options={[
          { label: 'On', value: '1' },
          { label: 'Off', value: '0' },
        ]}
      />
    </BoolContainer>
  );
};
