import { type ComponentType } from 'react';
import { type Dictionary } from 'ts-essentials';

import { LandingPageType, PageComponentType } from '../../../apollo/graphql';
import {
  AgentDetailsFields,
  ContentFields,
  ContentLeftFields,
  ContentRightFields,
  CssFields,
  FeaturedRatesFields,
  HeaderFields,
  IFrameFields,
  type IPageComponentFieldsProps,
  JustShoppingFields,
  LogoBarFields,
  QuickQuoteFields,
  StartApplicationFields,
} from './fields';

export interface IPageComponentEditorConfig {
  name: string;
  type: PageComponentType;
  component: ComponentType<IPageComponentFieldsProps>;
  allowedPages: LandingPageType[];
}

export const PageComponentEditorConfig: Dictionary<IPageComponentEditorConfig, PageComponentType> =
  {
    [PageComponentType.LogoBar]: {
      name: 'Logo bar',
      type: PageComponentType.LogoBar,
      component: LogoBarFields,
      allowedPages: [LandingPageType.AgentLandingPage, LandingPageType.LandingPage],
    },
    [PageComponentType.Header]: {
      name: 'Header',
      type: PageComponentType.Header,
      component: HeaderFields,
      allowedPages: [LandingPageType.AgentLandingPage, LandingPageType.LandingPage],
    },
    [PageComponentType.Content]: {
      name: 'Content',
      type: PageComponentType.Content,
      component: ContentFields,
      allowedPages: [LandingPageType.AgentLandingPage, LandingPageType.LandingPage],
    },
    [PageComponentType.ContentRight]: {
      name: 'Content - right',
      type: PageComponentType.ContentRight,
      component: ContentRightFields,
      allowedPages: [LandingPageType.AgentLandingPage, LandingPageType.LandingPage],
    },
    [PageComponentType.ContentLeft]: {
      name: 'Content - left',
      type: PageComponentType.ContentLeft,
      component: ContentLeftFields,
      allowedPages: [LandingPageType.AgentLandingPage, LandingPageType.LandingPage],
    },
    [PageComponentType.Iframe]: {
      name: 'IFrame',
      type: PageComponentType.Iframe,
      component: IFrameFields,
      allowedPages: [LandingPageType.AgentLandingPage, LandingPageType.LandingPage],
    },
    [PageComponentType.AgentDetails]: {
      name: 'Agent details',
      type: PageComponentType.AgentDetails,
      component: AgentDetailsFields,
      allowedPages: [LandingPageType.AgentLandingPage],
    },
    [PageComponentType.StartApplication]: {
      name: 'Start application',
      type: PageComponentType.StartApplication,
      component: StartApplicationFields,
      allowedPages: [LandingPageType.AgentLandingPage, LandingPageType.LandingPage],
    },
    [PageComponentType.QuickQuote]: {
      name: 'Quick quote',
      type: PageComponentType.QuickQuote,
      component: QuickQuoteFields,
      allowedPages: [LandingPageType.AgentLandingPage, LandingPageType.LandingPage],
    },
    [PageComponentType.FeaturedRates]: {
      name: 'Featured rates',
      type: PageComponentType.FeaturedRates,
      component: FeaturedRatesFields,
      allowedPages: [LandingPageType.AgentLandingPage, LandingPageType.LandingPage],
    },
    [PageComponentType.Css]: {
      name: 'Page SASS',
      type: PageComponentType.Css,
      component: CssFields,
      allowedPages: [LandingPageType.AgentLandingPage, LandingPageType.LandingPage],
    },
    [PageComponentType.JustShopping]: {
      name: 'Just shopping',
      type: PageComponentType.JustShopping,
      component: JustShoppingFields,
      allowedPages: [LandingPageType.AgentLandingPage, LandingPageType.LandingPage],
    },
  };
