import { Choice, Input, TextArea, useFormValue } from '@mortgagehippo/ds';

enum AuthType {
  PASSWORD = 'PASSWORD',
  SSH = 'SSH',
}

export const ExternalSftpFields = () => {
  const [authType] = useFormValue<string | undefined>('auth_type');

  return (
    <>
      <Input.Box name="hostname" label="Host" required />
      <Input.Box name="username" label="Username" required />
      <Choice.Box
        name="auth_type"
        label="Auth Type"
        options={[
          { label: 'Password', value: AuthType.PASSWORD },
          { label: 'SSH Key', value: AuthType.SSH },
        ]}
        required
      />
      {authType === AuthType.PASSWORD && <Input.Box name="password" label="Password" required />}
      {authType === AuthType.SSH && (
        <>
          <TextArea.Box name="public_key" label="Public Key (RSA only - not open-ssh)" required />
          <TextArea.Box name="private_key" label="Private Key (RSA only - not open-ssh)" required />
        </>
      )}
    </>
  );
};
