import {
  DocutechDisclosuresTask,
  type IDocutechDisclosuresPackage,
  type IDocutechDisclosuresTask,
} from '@mortgagehippo/tasks';
import { type ReactNode } from 'react';

import { TaskPreviewWrapper } from '../task-preview-wrapper';

export interface IDocutechDisclosuresTaskPreviewProps {
  task: IDocutechDisclosuresTask;
  onComplete?: () => Promise<any>;
  renderComplete?: () => ReactNode;
}

const disclosuresPackage: IDocutechDisclosuresPackage = {
  id: '1',
  createdAt: '2019-09-06 18:08:24.507855',
  referenceId: '1111111-1111-1111-1111-111111111',
  documents: [],
  signable: true,
  signedAt: null,
};

export const DocutechDisclosuresTaskPreview = (props: IDocutechDisclosuresTaskPreviewProps) => {
  const { task, renderComplete, onComplete } = props;

  return (
    <TaskPreviewWrapper task={task}>
      {({ nextTask, frame }) => (
        <DocutechDisclosuresTask
          task={nextTask as IDocutechDisclosuresTask}
          disclosuresPackage={disclosuresPackage}
          applicationFileId="1"
          applicantId="1"
          onComplete={onComplete}
          frame={frame}
          renderComplete={renderComplete}
        />
      )}
    </TaskPreviewWrapper>
  );
};
