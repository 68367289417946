import { graphql } from '../../apollo/gql';

export const QPartnerSitesList = graphql(`
  query PartnerSitesList(
    $partnerId: ID!
    $page: Int!
    $perPage: Int!
    $criteria: [SearchCriteria!]
    $orderBy: JSON
  ) {
    partner(id: $partnerId) {
      id
      sites(criteria: $criteria, options: { page: $page, perPage: $perPage, orderBy: $orderBy }) {
        itemsTotal
        itemsCount
        pageNumber
        pageCount
        items {
          ...SiteFragment
        }
      }
    }
  }
`);
