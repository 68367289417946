import { type IPaymentTask } from '@mortgagehippo/tasks';

import { TaskPreviewWrapper } from '../task-preview-wrapper';
import { type ITaskPreviewProps } from '../types';

export const PaymentTaskPreview = (props: ITaskPreviewProps<IPaymentTask>) => {
  const { task } = props;

  return <TaskPreviewWrapper task={task}>{() => null}</TaskPreviewWrapper>;
};
