import { Button } from '@mortgagehippo/ds';
import { useCallback, useState } from 'react';

import { type PaletteGroup } from '../types';
import { PaletteImportModalContainer } from './palette-import-modal-container';

interface IPaletteImportProps {
  group: PaletteGroup;
  onSubmit: (values: any) => Promise<boolean>;
}

export const PaletteImport = (props: IPaletteImportProps) => {
  const { group, onSubmit } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = useCallback(
    async (values: any) => {
      const result = await onSubmit(values);

      if (result) {
        handleClose();
      }

      return result;
    },
    [onSubmit]
  );

  return (
    <>
      <Button
        type="neutral"
        icon="upload"
        importance="tertiary"
        size="normal"
        iconButton
        iconButtonRound
        iconButtonHumble
        compact
        onClick={handleOpen}
      />
      <PaletteImportModalContainer
        onRequestClose={handleClose}
        isOpen={isOpen}
        group={group}
        onSubmit={handleSubmit}
      />
    </>
  );
};
