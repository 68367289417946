import { Modal } from '@mortgagehippo/ds';

import { TotalExpertCustomFieldsContent } from './total-expert-custom-fields-content';

interface TotalExpertCustomFieldsModal {
  partnerId: string;
  siteId?: string;
  onRequestClose: () => void;
  onOk: () => void;
  isOpen: boolean;
}

export const TotalExpertCustomFieldsModal = (props: TotalExpertCustomFieldsModal) => {
  const { partnerId, siteId, ...rest } = props;

  return (
    <Modal
      title="Total Expert Custom Fields"
      width={1300}
      compact
      hideCancelButton
      hideOkButton
      {...rest}
    >
      <TotalExpertCustomFieldsContent partnerId={partnerId} siteId={siteId} />
    </Modal>
  );
};
