import { Input, Select } from '@mortgagehippo/ds';
import { FieldType } from '@mortgagehippo/tasks';
import { map } from 'lodash-es';

import { FieldKeyEditor } from './field-key-editor';

export interface IFieldEditorDetailsProps {
  name: string;
  isEntity?: boolean;
  hasDiscriminator?: boolean;
  keySuffixes?: string[];
}

const labels: Record<FieldType, string> = {
  [FieldType.APR]: 'APR',
  [FieldType.ARRAY]: 'Array',
  [FieldType.BOOLEAN]: 'Yes/No',
  [FieldType.BORROW_AMOUNT]: 'Borrow Amount',
  [FieldType.CHECKBOX]: 'Checkbox',
  [FieldType.CHOICE]: 'Choice',
  [FieldType.CONSENT_JORNAYA]: 'Jornaya Consent',
  [FieldType.CONSTANT]: 'Constant',
  [FieldType.CONTENT]: 'Content',
  [FieldType.COUNTRY]: 'Country',
  [FieldType.CURRENCY]: 'Currency',
  [FieldType.DATE]: 'Date',
  [FieldType.DATE_PICKER]: 'Date Picker',
  [FieldType.DIVIDER]: 'Divider',
  [FieldType.DURATION]: 'Duration',
  [FieldType.DURATION_DATE]: 'Duration Date',
  [FieldType.EMAIL]: 'Email',
  [FieldType.ENTITY]: 'Entity',
  [FieldType.INCOME]: 'Income',
  [FieldType.MONTH]: 'Month Picker',
  [FieldType.NUMERIC]: 'Number',
  [FieldType.PHONE]: 'Phone',
  [FieldType.PROPERTY_VALUE]: 'Property Value',
  [FieldType.SELECT]: 'Select',
  [FieldType.SSN]: 'SSN',
  [FieldType.SWITCH_ARRAY]: 'Switch Array',
  [FieldType.TEXT]: 'Text',
  [FieldType.TEXTAREA]: 'Textarea',
  [FieldType.USADDRESS]: 'US Address',
  [FieldType.USSTATE]: 'US State',
  [FieldType.USZIPCODE]: 'US Zip Code',
  [FieldType.YEAR]: 'Year',
};

const options = map(labels, (label, value) => ({
  value,
  label,
}));

export const FieldEditorDetails = ({
  name,
  isEntity,
  hasDiscriminator,
  keySuffixes,
}: IFieldEditorDetailsProps) => (
  <>
    <Select.Box
      label="Field Type"
      name={`${name}.type_name`}
      options={options}
      required
      searchable
    />
    <FieldKeyEditor name={name} keySuffixes={keySuffixes} />
    {isEntity ? <Input.Box name={`${name}.object_type`} label="Object Type" /> : null}
    {hasDiscriminator ? (
      <>
        <Input.Box name={`${name}.discriminator_key`} label="Discriminator key" required />
        <Input.Box name={`${name}.discriminator_value`} label="Discriminator value" required />
      </>
    ) : null}
  </>
);
