import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { type VerticalType } from '../../../apollo/graphql';
import { MAddExternalMilestoneKeyMutation } from './queries';

export const useAddExternalMilestoneKey = () => {
  const addExternalKey = useMutation(MAddExternalMilestoneKeyMutation);

  return useCallback(
    async (partnerId: string, vertical: VerticalType, name: string, ignore: boolean) => {
      await addExternalKey(
        {
          partnerId,
          vertical,
          name,
          ignore,
        },
        { fetchPolicy: 'no-cache' }
      );
    },
    [addExternalKey]
  );
};
