import { notifications } from '@mortgagehippo/ds';
import { isPromise } from '@mortgagehippo/util';
import { useCallback, useMemo } from 'react';

import { ApplicationStatusModal } from './application-status-modal';
import { useApplicationStatus } from './use-application-status';
import { useCreateApplicationStatus } from './use-create-application-status';
import { useUpdateApplicationStatus } from './use-update-application-status';

interface IApplicationStatusModalContainerProps {
  partnerId: string;
  statusId?: string;
  onRequestClose: () => void;
  onSubmit: (() => Promise<any>) | (() => void);
  isOpen: boolean;
}

export const ApplicationStatusModalContainer = (props: IApplicationStatusModalContainerProps) => {
  const { partnerId, statusId, isOpen, onSubmit, onRequestClose } = props;
  const editing = statusId !== undefined;

  const [applicationStatus, loading] = useApplicationStatus(partnerId, statusId!, !editing);

  const createApplicationStatus = useCreateApplicationStatus();
  const updateApplicationStatus = useUpdateApplicationStatus();

  const handleSubmit = useCallback(
    async (values: any) => {
      let successMsg = '';

      const { name, color, default: isDefault } = values;
      const data = { name, color, default: isDefault };

      try {
        if (statusId) {
          await updateApplicationStatus(statusId, data);
          successMsg = 'The application status was successfully updated.';
        } else {
          await createApplicationStatus(partnerId, data);
          successMsg = 'The application status was successfully created.';
        }

        notifications.success({ message: successMsg });

        const result = onSubmit();

        if (isPromise(result)) {
          await result;
        }
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [createApplicationStatus, onSubmit, partnerId, statusId, updateApplicationStatus]
  );

  const initialValues = useMemo(() => {
    if (!applicationStatus) {
      return {};
    }

    const { name, color, default: isDefault } = applicationStatus;

    return {
      name,
      color,
      default: isDefault,
    };
  }, [applicationStatus]);

  const title = !editing ? 'Add Application Status' : 'Edit Application Status';

  return (
    <ApplicationStatusModal
      title={title}
      loading={loading}
      onSubmit={handleSubmit}
      onRequestClose={onRequestClose}
      initialValues={initialValues}
      isOpen={isOpen}
    />
  );
};
