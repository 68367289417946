import { Choice, createField, type IFieldInputProps, type ISelectOption } from '@mortgagehippo/ds';
import { useMemo } from 'react';

export enum Bureus {
  equifax = 'equifax',
  experian = 'experian',
  transunion = 'trans_union',
}

const bureauOptions: ISelectOption[] = [
  {
    label: 'Equifax',
    value: Bureus.equifax,
  },
  {
    label: 'Experian',
    value: Bureus.experian,
  },
  {
    label: 'Transunion',
    value: Bureus.transunion,
  },
];

export const CreditPullFieldsBureusInput = (props: IFieldInputProps) => {
  const { name, value, onChange, ...rest } = props;

  const innerValue = useMemo(() => {
    const array: string[] = [];

    if (value?.[Bureus.equifax]) {
      array.push(Bureus.equifax);
    }

    if (value?.[Bureus.experian]) {
      array.push(Bureus.experian);
    }

    if (value?.[Bureus.transunion]) {
      array.push(Bureus.transunion);
    }

    return array;
  }, [value]);

  const handleChange = (strings: string[]) => {
    const outerValue = {
      [Bureus.equifax]: strings.includes(Bureus.equifax),
      [Bureus.experian]: strings.includes(Bureus.experian),
      [Bureus.transunion]: strings.includes(Bureus.transunion),
    };

    onChange(outerValue);
  };

  return (
    <Choice.Input
      {...rest}
      name={name}
      onChange={handleChange}
      value={innerValue}
      options={bureauOptions}
      multiple
    />
  );
};

export const CreditPullFieldsBureus = createField<IFieldInputProps>(CreditPullFieldsBureusInput);
