import { FormSection, MAX_DURATION_VALUE, Numeric, Tabs, useFormValue } from '@mortgagehippo/ds';
import { useDebouncedEffect } from '@mortgagehippo/util';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { FieldEditorFrame } from './field-editor-frame';

export const DurationEditor = ({ name }: { name: string }) => {
  const maxMonthsName = `${name}.maxMonths`;
  const minMonthsName = `${name}.minMonths`;
  const [maxMonthsValue, setMaxMonthsValue] = useFormValue(maxMonthsName);
  const [minMonthsValue, setMinMonthsValue] = useFormValue(minMonthsName);

  useDebouncedEffect(
    () => {
      if (minMonthsValue && maxMonthsValue < minMonthsValue) {
        setMaxMonthsValue(minMonthsValue);
        return;
      }

      if (maxMonthsValue && minMonthsValue > maxMonthsValue) {
        setMinMonthsValue(maxMonthsValue);
      }
    },
    { wait: 200 },
    [maxMonthsValue, minMonthsValue, setMaxMonthsValue, setMinMonthsValue]
  );

  return (
    <FieldEditorFrame name={name}>
      {() => [
        <Tabs.Tab id="durationOptions" label="Duration Options">
          <EditorTabWrapper size="md">
            <FormSection title="Duration Options" description="Additional configuration options">
              <Numeric.Box
                name={maxMonthsName}
                label="Max Duration (in months)"
                validate={(value) =>
                  value > MAX_DURATION_VALUE
                    ? `Max value cannot be above ${MAX_DURATION_VALUE} months`
                    : undefined
                }
              />
              <Numeric.Box name={minMonthsName} label="Min Duration (in months)" />
            </FormSection>
          </EditorTabWrapper>
        </Tabs.Tab>,
      ]}
    </FieldEditorFrame>
  );
};
