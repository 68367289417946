import { Modal, palette, spacing } from '@mortgagehippo/ds';
import { UnreachableCaseError } from '@mortgagehippo/util';
import styled from 'styled-components';

import { LandingPageType } from '../../apollo/graphql';

const PLACEHOLDER = '...';

const StyledPre = styled.pre`
  background-color: ${palette('neutral50')};
  padding: ${spacing(3)};
`;

interface IPageComponentEditModalProps {
  landingPageType: LandingPageType;
  onRequestClose?: () => void;
  isOpen: boolean;
}

export const LandingPageEditorVariablesModal = (props: IPageComponentEditModalProps) => {
  const { landingPageType, onRequestClose, isOpen } = props;

  let content;
  switch (landingPageType) {
    case LandingPageType.LandingPage:
      content = {
        site: {
          name: PLACEHOLDER,
        },
      };
      break;
    case LandingPageType.AgentLandingPage:
      content = {
        site: {
          name: PLACEHOLDER,
        },
        agent: {
          name: PLACEHOLDER,
          email: PLACEHOLDER,
          phone: PLACEHOLDER,
          license: PLACEHOLDER,
          licensed_states: PLACEHOLDER,
          bio: PLACEHOLDER,
          job_title: PLACEHOLDER,
          facebook_url: PLACEHOLDER,
          linkedin_url: PLACEHOLDER,
          twitter_url: PLACEHOLDER,
          avatar_url: PLACEHOLDER,
        },
      };
      break;
    default:
      throw new UnreachableCaseError(landingPageType);
  }

  return (
    <Modal
      title="Available Variables"
      width={800}
      hideCancelButton
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onOk={onRequestClose}
    >
      <StyledPre>{JSON.stringify(content, null, 4)}</StyledPre>
    </Modal>
  );
};
