import { useMutation } from '@mortgagehippo/apollo-hooks';
import { borderRadius, Empty, notifications, palette, spacing } from '@mortgagehippo/ds';
import { Affix } from 'antd';
import { forOwn, isPlainObject, omit } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { type SetCustomizationValueInput } from '../../../../apollo/graphql';
import { FixedSubmitBar } from '../../../fixed-submit-bar';
import { useCustomizationEditorResources } from '../../hooks/use-customization-editor-resources';
import { MDeleteCustomizationValue, MSetCustomizationValues } from '../../queries';
import {
  type ICustomizationsEditorResource,
  type ICustomizationsEditorTabProps,
} from '../../types';
import { Loading } from './loading';
import { ResourcesList } from './resources-list';
import { ValueEditor } from './value-editor';

const Col = styled.div``;

const Row = styled.div`
  display: flex;

  ${Col} {
    margin: 0 ${spacing(1)};
  }

  ${Col}:first-child {
    flex: 0 0 420px;
    max-width: 420px;
    margin-left: 0;
  }

  ${Col}:last-child {
    flex: 1;
    margin-right: 0;
    background: ${palette('white')};
    padding: ${spacing(3)};
    border-radius: ${borderRadius(3)};
  }
`;

interface IGeneralEditorProps extends ICustomizationsEditorTabProps {
  namespace?: string;
  keyPathFilter?: string;
  searchFilter?: string;
  setUnsavedChangesCount: (count: number) => void;
}

export const GeneralEditor = (props: IGeneralEditorProps) => {
  const {
    projectId,
    partnerId,
    domain,
    language,
    overriddenOnly,
    namespace,
    keyPathFilter,
    searchFilter,
    setUnsavedChangesCount,
  } = props;

  const [editingResource, setEditingResource] = useState<ICustomizationsEditorResource | null>(
    null
  );
  const [changes, setChanges] = useState<{
    [index: string]: string;
  }>({});

  useEffect(() => {
    setEditingResource(null);
  }, [projectId]);

  const [customizationsData, customizationsDataLoading, refetch] = useCustomizationEditorResources({
    projectId,
    partnerId,
    domain,
    language,
    overriddenOnly,
    notifyOnNetworkStatusChange: false,
    namespace,
    key: keyPathFilter,
  });

  const setCustomizationValues = useMutation(MSetCustomizationValues);

  const deleteCustomizationValue = useMutation(MDeleteCustomizationValue);

  const handleItemClick = useCallback((resource: ICustomizationsEditorResource) => {
    setEditingResource(resource);
  }, []);

  const handleItemDelete = useCallback(
    async (resource: ICustomizationsEditorResource) => {
      try {
        if (resource.value) {
          await deleteCustomizationValue({ id: resource.value.id });

          await refetch();
        }
      } catch (error) {
        notifications.error({
          message: 'There was an error processing your request, please try again later',
        });
      }
    },
    [deleteCustomizationValue, refetch]
  );

  const handleValueChange = useCallback(
    (value: any) => {
      if (!editingResource) {
        return;
      }

      const hasChanges = typeof changes[editingResource.id] !== 'undefined';

      if (hasChanges) {
        const targetResource = customizationsData.find((r) => r.id === editingResource.id);

        if (!targetResource) {
          return;
        }

        const comparisonValue = isPlainObject(value) ? JSON.stringify(value) : value;

        if (
          (targetResource.value && targetResource.value.value === comparisonValue) ||
          (!targetResource.value && !value)
        ) {
          // we are returning the value to what it already was
          const newChanges = omit(changes, [editingResource.id]);
          setChanges(newChanges);
          return;
        }
      }

      const newChanges = { ...changes };
      newChanges[editingResource.id] = value !== undefined ? value : '';
      setChanges(newChanges);
    },
    [editingResource, customizationsData, changes]
  );

  useEffect(() => {
    setUnsavedChangesCount(Object.keys(changes).length);
  }, [changes, setUnsavedChangesCount]);

  const handleReset = useCallback(() => {
    if (editingResource) {
      const newChanges = omit(changes, [editingResource.id]);
      setChanges(newChanges);
    }
  }, [changes, editingResource]);

  const handleSubmit = useCallback(async () => {
    try {
      const submitData: SetCustomizationValueInput[] = [];

      forOwn(changes, (value, id) => {
        const targetResource = customizationsData.find((r) => r.id === id);

        if (targetResource) {
          const submitValue = isPlainObject(value) ? JSON.stringify(value) : value;

          submitData.push({
            resourceId: targetResource.id,
            partnerId,
            domain,
            language,
            value: submitValue,
          });
        }
      });

      if (submitData.length > 0) {
        await setCustomizationValues({ data: submitData });
        await refetch();
      }

      setChanges({});
      notifications.success({ message: 'Your changes have been saved.' });
    } catch (e) {
      notifications.error({
        message: 'There was an error processing your request, please try again later',
      });
    }
  }, [changes, customizationsData, domain, language, partnerId, refetch, setCustomizationValues]);

  useEffect(() => {
    setChanges({});
  }, [domain, language, partnerId, namespace, keyPathFilter]);

  useEffect(() => {
    if (
      customizationsData.length > 0 &&
      (!editingResource || !customizationsData.find((r) => r.id === editingResource.id))
    ) {
      setEditingResource(customizationsData[0]!);
    }
  }, [editingResource, customizationsData]);

  if (customizationsDataLoading) {
    return <Loading />;
  }

  if (!customizationsDataLoading && customizationsData.length === 0) {
    return <Empty />;
  }

  let editorValue = '';
  let editorHasChanges = false;

  if (editingResource) {
    const stateValue = changes[editingResource.id];
    editorHasChanges = typeof stateValue !== 'undefined';

    if (!editorHasChanges) {
      const targetResource = customizationsData.find((r) => r.id === editingResource.id);

      if (targetResource?.value) {
        editorValue = targetResource.value.value;
      }
    } else {
      editorValue = stateValue!;
    }
  }

  return (
    <>
      <Row>
        <Col>
          <ResourcesList>
            {customizationsData.map((resource) => {
              if (
                searchFilter &&
                !resource.key.toLowerCase().includes(searchFilter.toLowerCase())
              ) {
                /*
                 * use the search filter to only hide rendered resources
                 * while keeping the data intact not to lose changes
                 * only changing the namespace or keypath should result in
                 * re-rendering the data and losing changes
                 */
                return null;
              }

              const stateValue = changes[resource.id];
              const hasChanges = typeof stateValue !== 'undefined';

              return (
                <ResourcesList.Item
                  key={resource.id}
                  resource={resource}
                  edited={hasChanges}
                  onClick={handleItemClick}
                  onDelete={handleItemDelete}
                  selected={!!(editingResource && editingResource.id === resource.id)}
                />
              );
            })}
          </ResourcesList>
        </Col>
        <Col>
          <Affix offsetTop={20}>
            {(editingResource && (
              <ValueEditor
                showReset={editorHasChanges}
                onReset={handleReset}
                value={editorValue}
                onChange={handleValueChange}
                disabled={!editingResource}
                resource={editingResource}
                partnerId={partnerId}
                domain={domain}
                language={language}
                projectId={projectId}
              />
            )) || <div>Please select a resource on the left to edit it.</div>}
          </Affix>
        </Col>
      </Row>
      <FixedSubmitBar unsavedChanges={Object.keys(changes).length} onSubmit={handleSubmit} />
    </>
  );
};
