import { type VerticalType } from '../../../apollo/graphql';
import { MilestonesTable } from './milestones-table';

interface IMilestonesPageContentProps {
  partnerId: string;
  siteId: string;
  loading: boolean;
  verticalName: VerticalType | undefined;
}

export const MilestonesPageContent = (props: IMilestonesPageContentProps) => {
  const { partnerId, siteId, loading, verticalName } = props;

  return (
    <MilestonesTable
      partnerId={partnerId}
      siteId={siteId}
      vertical={verticalName}
      loading={loading}
    />
  );
};
