import { spacing, Spinner } from '@mortgagehippo/ds';
import styled from 'styled-components';

const Container = styled.div`
  padding: ${spacing(7)};
  text-align: center;
`;

export const LandingPageEditorLoading = () => (
  <Container>
    <Spinner size="xxxl" />
  </Container>
);
