import { Tag, Text } from '@mortgagehippo/ds';

interface IAnswersDocumentationEntityProps {
  entity: any;
}

export const AnswersDocumentationEntity = (props: IAnswersDocumentationEntityProps) => {
  const { entity } = props;

  const tag = entity.item_type === 'Parent' ? 'Array' : entity.type;

  return (
    <li>
      <strong>{entity.name} </strong>
      {tag ? <Tag>{tag}</Tag> : null} <br />
      <Text variant="secondary">{entity.description}</Text>
      {entity.items ? (
        <ul>
          {entity.items.map((subEntity: any) => (
            <AnswersDocumentationEntity key={subEntity.name} entity={subEntity} />
          ))}
        </ul>
      ) : null}
    </li>
  );
};
