import { type ISignDisclosuresTask, SignDisclosuresTask } from '@mortgagehippo/tasks';
import { type ReactNode } from 'react';

import { TaskPreviewWrapper } from '../task-preview-wrapper';

export interface ISignDisclosuresTaskPreviewProps {
  task: ISignDisclosuresTask;
  onComplete?: () => Promise<any>;
  renderComplete?: () => ReactNode;
}

export const SignDisclosuresTaskPreview = (props: ISignDisclosuresTaskPreviewProps) => {
  const { task, renderComplete, onComplete } = props;

  return (
    <TaskPreviewWrapper task={task}>
      {({ nextTask, frame }) => (
        <SignDisclosuresTask
          task={nextTask as ISignDisclosuresTask}
          disclosuresPackages={[]}
          applicants={[]}
          applicantId="1"
          onComplete={onComplete}
          frame={frame}
          renderComplete={renderComplete}
        />
      )}
    </TaskPreviewWrapper>
  );
};
