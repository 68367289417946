import { DEFAULT_THEME, type ISelectOption, Select } from '@mortgagehippo/ds';

import { type IValueEditorProps } from './types';

export const FontWeightEditor = (props: IValueEditorProps) => {
  const { onChange, value, labelId, disabled } = props;

  const fontWeightOptions: ISelectOption[] = Object.keys(
    DEFAULT_THEME.fonts.variants.primary.weights
  ).map((weight) => ({
    label: weight,
    value: weight,
  }));

  return (
    <Select.Input
      name="customization_value"
      aria-labelledby={labelId}
      value={value}
      onChange={onChange}
      placeholder="select font weight"
      disabled={disabled}
      options={fontWeightOptions}
    />
  );
};
