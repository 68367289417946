import {
  FieldArrayList,
  FieldError,
  FormSection,
  type FormSubmitHandler,
  Icon,
  type ITableCol,
  ModalForm,
  Table,
} from '@mortgagehippo/ds';
import { startCase, uniq } from 'lodash-es';
import { useCallback, useMemo } from 'react';

import { type IEditorSaga, type IEditorSagaArgument } from '../types';
import { ArgumentsModalItemForm } from './arguments-modal-item-form';
import { ArgumentsModalItemLabel } from './arguments-modal-item-label';

interface IArgumentsModalProps {
  saga: IEditorSaga;
  onRequestClose?: () => void;
  onSubmit?: FormSubmitHandler;
  isOpen: boolean;
}

const cols: Array<ITableCol<IEditorSagaArgument>> = [
  {
    title: 'Name',
    key: 'name',
    render: (record) => record.name,
  },
  {
    title: 'Type',
    key: 'type',
    render: (record) => record.type,
  },
  {
    title: 'Required',
    key: 'required',
    align: 'center',
    render: ({ required }) => (required ? <Icon name="check" /> : ''),
  },
];

export const ArgumentsModal = (props: IArgumentsModalProps) => {
  const { saga, ...rest } = props;
  const hasSystemArguments = saga.systemArguments.length > 0;

  const handleValidateItem = useCallback(
    (itemValue: IEditorSagaArgument, arrayValues: IEditorSagaArgument[]) => {
      const systemNames = saga.systemArguments.map((a) => a.name);
      const customNames = arrayValues.map((a) => a.name);
      const hasDuplicates =
        !!systemNames.find((n) => n === itemValue.name) ||
        uniq(customNames).length !== arrayValues.length;

      if (hasDuplicates) {
        return {
          name: 'Name must be unique.',
        };
      }

      return undefined;
    },
    [saga.systemArguments]
  );

  const initialValues = useMemo(
    () => ({
      arguments: saga.currentArguments,
    }),
    [saga]
  );

  const title = startCase(saga.name);
  const systemArgumentsLabel = 'System Arguments';
  const customArgumentsLabel = hasSystemArguments ? 'Custom Arguments' : 'Arguments';

  return (
    <ModalForm title={title} disableOverlayClickClose initialValues={initialValues} {...rest}>
      {hasSystemArguments ? (
        <FormSection title={systemArgumentsLabel}>
          <Table<IEditorSagaArgument>
            caption="System Arguments"
            ariaLabel={systemArgumentsLabel}
            rowKey={(record) => record.name}
            cols={cols}
            data={saga.systemArguments}
          />
        </FormSection>
      ) : null}
      <FormSection title={customArgumentsLabel}>
        <FieldArrayList
          name="arguments"
          addButtonLabel="Add an argument"
          renderItemLabel={(item: IEditorSagaArgument) => <ArgumentsModalItemLabel item={item} />}
          renderForm={() => <ArgumentsModalItemForm />}
          sortable={false}
          aria-label={customArgumentsLabel}
          validateItem={handleValidateItem}
        />
        <FieldError name="arguments" />
      </FormSection>
    </ModalForm>
  );
};
