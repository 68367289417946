import { Choice, FieldBox, FormSection, Input, Tabs } from '@mortgagehippo/ds';
import { type ISwitchArrayFieldCase } from '@mortgagehippo/tasks';

import { LogicField } from '$components/logic-field';

import { EditorArray } from '../editor-array';
import { EditorTabWrapper } from '../editor-tab-wrapper';
import { ArrayModeEditor } from './array-mode-editor';
import { ArrayTitleEditor } from './array-title-editor';
import { ArrayValidationEditor } from './array-validation-editor';
import { FieldEditorFrame } from './field-editor-frame';
import { GroupsEditor } from './groups-editor';
import { SwitchArrayLabelsEditor } from './switch-array-labels-editor';

export const SwitchArrayEditor = ({ name }: { name: string }) => (
  <FieldEditorFrame name={name} isEntity validations={() => <ArrayValidationEditor name={name} />}>
    {() => [
      <Tabs.Tab id="array" label="Array Options">
        <EditorTabWrapper size="md">
          <FormSection
            title="Array Mode"
            description="Determines the behavior of adding array items. ALWAYS use 'Modal', unless the form is 1 to 4 fields tall in which case you can 'Embedded'. If you use 'Panel' this array must be ONLY field of the ONLY panel of this task (or UX will suffer)."
          >
            <ArrayModeEditor name={name} />
          </FormSection>

          <FormSection
            title="Array Item Title"
            description="When adding an element. This is going to be shown as the title of the sub-form only works for embedded and modal options"
          >
            <ArrayTitleEditor name={name} />
          </FormSection>

          <FormSection title="Array Control Labels" description="Labels for switch arrays items">
            <SwitchArrayLabelsEditor name={name} />
          </FormSection>

          <FormSection title="Array Sorting" description="Rules for sorting the items">
            <LogicField.Box name={`${name}.sortBy`} label="Sort Expression" />

            <Choice.Box
              name={`${name}.sortDescending`}
              label="Sort Direction"
              options={[
                { label: 'Ascending', value: false },
                { label: 'Descending', value: true },
              ]}
            />
          </FormSection>
        </EditorTabWrapper>
      </Tabs.Tab>,
      <Tabs.Tab id="variants" label="Variants">
        <EditorTabWrapper size="md">
          <Input.Box name={`${name}.discriminator_key`} label="Discriminator key" required />

          <FieldBox name={`${name}.commonGroups`} label="Common Groups">
            <GroupsEditor name={`${name}.commonGroups`} />
          </FieldBox>

          <FieldBox name={`${name}.cases`} label="Variants">
            <EditorArray
              name={`${name}.cases`}
              addButtonLabel="Add another variant"
              renderItemLabel={(v: ISwitchArrayFieldCase) => v.title || 'Unnamed'}
            />
          </FieldBox>
        </EditorTabWrapper>
      </Tabs.Tab>,
    ]}
  </FieldEditorFrame>
);
