import { Tabs, YesNo } from '@mortgagehippo/ds';

import { EditorTabWrapper } from '../editor-tab-wrapper';
import { TaskEditorFrame } from '../task-editor-frame';

export const DocumentSubmissionTaskEditor = ({ name }: { name: string }) => (
  <TaskEditorFrame name={name}>
    {() => (
      <Tabs.Tab id="options" label="Options">
        <EditorTabWrapper size="md">
          <YesNo.Box name={`${name}.data.required`} label="Required" initialValue={false} />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
  </TaskEditorFrame>
);
