import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../apollo/gql';
import { type RegisteredIntegrationConnectionInput } from '../apollo/graphql';

export const MUpdateRegisteredIntegrationConnection = graphql(`
  mutation UpdateRegisteredIntegrationConnection(
    $connectionId: ID!
    $data: RegisteredIntegrationConnectionInput!
  ) {
    updateRegisteredIntegrationConnection(connectionId: $connectionId, data: $data) {
      id
    }
  }
`);

export const useUpdateRegisteredIntegrationConnection = () => {
  const fn = useMutation(MUpdateRegisteredIntegrationConnection);

  return useCallback(
    async (connectionId: string, data: RegisteredIntegrationConnectionInput) => {
      const result = await fn({ connectionId, data });
      return result.data!.updateRegisteredIntegrationConnection!.id;
    },
    [fn]
  );
};
