import { Button } from '@mortgagehippo/ds';
import { useLandingPage } from '@mortgagehippo/tasks';
import { NotFoundError } from '@mortgagehippo/util';
import { type ReactNode, useCallback } from 'react';
import { type RouteComponentProps } from 'react-router';

import { ActionType, useDispatchAction } from '$components/actions';
import { LandingPageEditor } from '$components/landing-page-editor';

import { useSite } from '../../hooks/use-site';
import { Content, Layout } from '../../layouts/main';
import { LandingPageLoading } from './landing-page-loading';

interface ILandingPagePageRouteParams {
  partnerId: string;
  siteId: string;
  landingPageId: string;
}

type ILandingPagePageProps = RouteComponentProps<ILandingPagePageRouteParams>;

export const LandingPagePage = (props: ILandingPagePageProps) => {
  const { match } = props;
  const { params } = match;
  const { siteId, landingPageId } = params;

  const [site, siteLoading] = useSite(siteId);
  const [{ data: landingPage, loading: landingPageLoading }] = useLandingPage(
    siteId,
    landingPageId
  );

  const dispatch = useDispatchAction();

  const handleEdit = useCallback(() => {
    dispatch({
      type: ActionType.EDIT_LANDING_PAGE,
      siteId,
      landingPageId,
    });
  }, [dispatch, landingPageId, siteId]);

  const loading = siteLoading || landingPageLoading;

  let actionsEl: ReactNode = null;
  let contentEl: ReactNode = null;
  if (loading) {
    contentEl = <LandingPageLoading />;
  } else {
    if (!site) {
      throw new NotFoundError('Site not found');
    }

    if (!landingPage) {
      throw new NotFoundError('Landing page not found');
    }

    actionsEl = (
      <Button icon="edit" type="default" importance="secondary" size="sm" onClick={handleEdit}>
        Edit
      </Button>
    );

    contentEl = (
      <LandingPageEditor
        landingPage={landingPage}
        domain={site.domain}
        partnerId={site.partnerId}
      />
    );
  }

  const siteName = (!loading && site?.name) || '\u00A0';
  const landingPageName = (!loading && landingPage?.title) || '\u00A0';

  return (
    <Layout>
      <Content title={landingPageName} subTitle={siteName} actions={actionsEl}>
        {contentEl}
      </Content>
    </Layout>
  );
};
