import { useMutation } from '@mortgagehippo/apollo-hooks';

import { graphql } from '../../../apollo/gql';
import { type LandingPageInput } from '../../../apollo/graphql';

export const MUpdateLandingPageMutation = graphql(`
  mutation UpdateLandingPage($landingPageId: ID!, $data: LandingPageInput!) {
    updateLandingPage(landingPageId: $landingPageId, data: $data) {
      ...LandingPageFragment
    }
  }
`);

export const useUpdateLandingPage = () => {
  const updateLandingPage = useMutation(MUpdateLandingPageMutation, undefined);

  return async (landingPageId: string, data: LandingPageInput) => {
    await updateLandingPage({ landingPageId, data });
  };
};
