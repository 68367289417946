import {
  borderRadius,
  Button,
  type IButtonProps,
  paletteAlpha,
  Popover,
  shadow,
  spacing,
} from '@mortgagehippo/ds';
import { type ReactNode, useCallback, useState } from 'react';
import styled from 'styled-components';

const deleteButtonProps: IButtonProps = {
  iconButton: true,
  icon: 'delete',
  iconButtonHumble: true,
  size: 'sm',
  importance: 'tertiary',
  type: 'neutral',
  compact: true,
};

const Actions = styled.div`
  position: absolute;
  top: 7px;
  right: 7px;
  z-index: 1;

  background: ${paletteAlpha('white', 2)};
  border-radius: ${borderRadius(2)};
  padding: ${spacing(1)};
  box-shadow: ${shadow(3)};
`;

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const PageComponentWrapper = styled.div`
  pointer-events: none;
  height: 100%;
`;

interface IPageComponentFrameProps {
  pageComponentId: string;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => Promise<any>;
  onMoveUp?: (id: string) => Promise<any>;
  onMoveDown?: (id: string) => Promise<any>;
  moveUpDisabled?: boolean;
  moveDownDisabled?: boolean;
  children?: ReactNode;
}

export const PageComponentFrame = (props: IPageComponentFrameProps) => {
  const {
    pageComponentId,
    onEdit,
    onDelete,
    onMoveUp,
    onMoveDown,
    moveUpDisabled = false,
    moveDownDisabled = false,
    children,
  } = props;
  const [loading, setLoading] = useState(false);

  const handleEdit = useCallback(() => {
    if (onEdit) {
      onEdit(pageComponentId);
    }
  }, [onEdit, pageComponentId]);

  const handleDelete = useCallback(async () => {
    if (onDelete) {
      setLoading(true);

      await onDelete(pageComponentId);

      setLoading(false);
    }
  }, [onDelete, pageComponentId]);

  const handleMoveUp = useCallback(async () => {
    if (onMoveUp) {
      setLoading(true);

      await onMoveUp(pageComponentId);

      setLoading(false);
    }
  }, [onMoveUp, pageComponentId]);

  const handleMoveDown = useCallback(async () => {
    if (onMoveDown) {
      setLoading(true);

      await onMoveDown(pageComponentId);

      setLoading(false);
    }
  }, [onMoveDown, pageComponentId]);

  const buttonProps: IButtonProps = {
    ...deleteButtonProps,
    disabled: loading,
    loading,
  };

  return (
    <Container>
      <Actions>
        <Button
          iconButton
          icon="up-arrow"
          iconButtonHumble
          size="sm"
          importance="tertiary"
          type="neutral"
          onClick={handleMoveUp}
          compact
          disabled={moveUpDisabled || loading}
        >
          Move Up
        </Button>
        <Button
          iconButton
          icon="down-arrow"
          iconButtonHumble
          size="sm"
          importance="tertiary"
          type="neutral"
          onClick={handleMoveDown}
          compact
          disabled={moveDownDisabled || loading}
        >
          Move Down
        </Button>
        <Button
          iconButton
          icon="edit"
          iconButtonHumble
          size="sm"
          importance="tertiary"
          type="neutral"
          onClick={handleEdit}
          compact
          disabled={loading}
        >
          Edit
        </Button>
        <Popover
          content="This action cannot be undone."
          title="Are you sure?"
          confirm
          onConfirm={handleDelete}
          buttonProps={buttonProps}
          widthCSS="300px"
        >
          Delete
        </Popover>
      </Actions>
      <PageComponentWrapper>{children}</PageComponentWrapper>
    </Container>
  );
};
