import { DEFAULT_THEME, type ISelectOption, Select } from '@mortgagehippo/ds';

import { type IValueEditorProps } from './types';

export const FontSizeEditor = (props: IValueEditorProps) => {
  const { onChange, value, labelId, disabled } = props;

  const fontSizeOptions: ISelectOption[] = Object.keys(DEFAULT_THEME.fonts.sizes).map((size) => ({
    label: size,
    value: size,
  }));

  return (
    <Select.Input
      name="customization_value"
      aria-labelledby={labelId}
      value={value}
      onChange={onChange}
      placeholder="select font size"
      disabled={disabled}
      options={fontSizeOptions}
    />
  );
};
