export const PALETTE_KEY_PREFIX = 'palette.';

export const PALETTE_GROUPS = [
  'primary',
  'secondary',
  'neutral',
  'success',
  'warning',
  'danger',
] as const;

export const PALETTE_LEVELS = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900] as const;
