import { Button, Empty, palette, spacing } from '@mortgagehippo/ds';
import { isPromise } from '@mortgagehippo/util';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: ${spacing(7)};
  border: 1px solid ${palette('neutral200')};
  text-align: center;
`;

const Actions = styled.div`
  margin-top: ${spacing(4)};
`;

interface IAgentLandingPageEmptyProps {
  onCreate?: () => Promise<any>;
}

export const AgentLandingPageEmpty = (props: IAgentLandingPageEmptyProps) => {
  const { onCreate } = props;
  const [loading, setLoading] = useState(false);

  const handleCreate = useCallback(async () => {
    if (!onCreate) {
      return;
    }

    const result = onCreate();

    if (isPromise(result)) {
      setLoading(true);

      await result;

      setLoading(false);
    }
  }, [onCreate]);

  return (
    <Container>
      <Empty>You haven&apos;t created a template for this site yet.</Empty>
      <Actions>
        <Button
          type="default"
          importance="primary"
          onClick={handleCreate}
          loading={loading}
          size="sm"
        >
          Create template
        </Button>
      </Actions>
    </Container>
  );
};
