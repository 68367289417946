import { Input, type ISelectOption, Numeric, Select, Title } from '@mortgagehippo/ds';

import { CodeEditorField } from '$components/code-editor';

enum MortgageFlexFileTypes {
  fannie_mae_32 = 'fannie_mae_32',
  mismo_34 = 'mismo_34',
}

const fileOptions: ISelectOption[] = [
  {
    label: 'Fannie Mae File 3.2',
    value: MortgageFlexFileTypes.fannie_mae_32,
  },
  {
    label: 'MISMO File 3.4',
    value: MortgageFlexFileTypes.mismo_34,
  },
];

export const MortgageFlexFields = () => (
  <>
    <Title level={3}>Access Info</Title>
    <Input.Box name="access_info.url" label="URL" required />
    <Input.Box name="access_info.user_name" label="Username" required />
    <Input.Box name="access_info.password" label="Password" required />
    <Title level={3}>MortgageFlex</Title>
    <Input.Box name="mortgage_flex_data.client_service_code" label="Client Service Code" required />
    <Input.Box name="mortgage_flex_data.account_id_prefix" label="Account ID Prefix" />
    <Input.Box name="mortgage_flex_data.credit_vendor" label="Credit Vendor" />
    <Numeric.Box
      name="mortgage_flex_data.timeout"
      label="MortgageFlex Timeout"
      description="Format: Seconds"
    />
    <Select.Box
      name="mortgage_flex_data.file_type"
      label="File type to be used"
      options={fileOptions}
      required
    />
    <CodeEditorField.Box mode="json" name="custom_json" label="Custom JSON" />
  </>
);
