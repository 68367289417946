import { createField, type IFieldInputProps } from '@mortgagehippo/ds';
import { isPresent } from '@mortgagehippo/util';

import { CodeEditor, type CodeEditorMode } from './code-editor';

export interface ICodeEditorFieldProps extends IFieldInputProps {
  mode: CodeEditorMode;
  height?: string;
  wrap?: boolean;
}

const CodeEditorFieldInput = (props: ICodeEditorFieldProps) => {
  const { name, value, mode, onChange, height, wrap } = props;
  return (
    <CodeEditor
      mode={mode}
      key={name}
      value={value}
      onChange={onChange}
      height={height}
      wrap={wrap}
    />
  );
};

export const CodeEditorField = createField<ICodeEditorFieldProps>(CodeEditorFieldInput, undefined, {
  validate: (value, _allValues, props) => {
    if (!isPresent(value)) return undefined;

    if (props.mode !== 'json') {
      return undefined;
    }

    try {
      JSON.parse(value);
    } catch (e) {
      return 'Invalid JSON';
    }

    return undefined;
  },
});
