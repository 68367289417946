import {
  Button,
  Checkbox,
  Domain,
  Email,
  FieldBox,
  type FormSubmitHandler,
  Input,
  ModalWizard,
  Numeric,
  Phone,
  SimpleFieldAutoArray,
  Timezone,
} from '@mortgagehippo/ds';
import { useState } from 'react';
import styled from 'styled-components';

import { PartnerSelect } from '$components/partner-select';
import { SiteSelect } from '$components/site-select';

const ArrayRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ArrayCol = styled.div<{ flex?: number }>`
  flex: ${({ flex }) => (flex === undefined ? 1 : flex)};
  flex-basis: auto;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

interface ICreatePartnerWizardProps {
  initialValues?: any;
  onRequestClose: () => void;
  onComplete: FormSubmitHandler;
  isOpen: boolean;
}

export const CreatePartnerWizard = (props: ICreatePartnerWizardProps) => {
  const [selectedPartner, setSelectedPartner] = useState<string | undefined>();

  const handlePartnerSelect = (newValue: string): void => {
    setSelectedPartner(newValue)
  }

  return (
    <ModalWizard title="Create Partner" {...props}>
      <ModalWizard.Panel title="Partner Details">
        <PartnerSelect
          name="sourceId"
          label="Select partner to clone"
          onChange={handlePartnerSelect}
          labelTooltip="Cloning a partner will copy almost all settings, such as namespace customizations, access levels, blueprints, notifications, etc. Credentials will not be copied over."
        />
        <Input.Box name="partner.name" label="Name" required />
        <Domain.Box name="partner.admin_domain" label="Admin Domain" required validateSane={false} />
        <Numeric.Box name="partner.admin_inactivity_timeout" label="Admin Inactivity Timeout (ms)" />
        <Input.Box name="auth.auth0_domain" label="Auth0 Domain" required />
        <Input.Box name="auth.auth0_api_identifier" label="Auth0 API Identifier" required />
        <Input.Box
          name="auth.auth0_management_application_client_id"
          label="Auth0 Management Application Client ID"
          required
        />
        <Input.Box
          name="auth.auth0_management_application_client_secret"
          label="Auth0 Management Application Client Secret"
          required
        />
        <Timezone.Box name="partner.timezone" label="Timezone" required />
        <Checkbox.Box
          label="Partner billable"
          labelInvisible
          name="partner.is_billable"
          initialValue={false}
        >
          Is billable?
        </Checkbox.Box>
      </ModalWizard.Panel>
      <ModalWizard.Panel>
        <SiteSelect
          partnerId={selectedPartner}
          name="sourceSiteIds"
          label="Select all the sites you would like to copy"
          labelTooltip="Select 1 or more sites that you would like to copy over. Everything, including the Agent and Landing Pages will be copied over. To create a blank site, do not select any."
          multiple
        />
      </ModalWizard.Panel>
      <ModalWizard.Panel title="Agent Details">
        <Email.Box name="agent.email" label="Email" required />
        <Input.Box name="agent.password" label="Maxwell Password" required />
        <Input.Box name="agent.name" label="Name" required />
        <Input.Box name="agent.license" label="License" />
        <Phone.Box name="agent.phone" label="Phone" />
        <FieldBox name="secondary_email" label="Secondary Email (cc'd on email notifications)">
          <SimpleFieldAutoArray
            name="agent.secondary_email"
            presentFields={['email']}
            render={({ name: itemName, error, onRemove, active }) => (
              <ArrayRow>
                <ArrayCol>
                  <Email
                    name={`${itemName}.email`}
                    placeholder="Email"
                    compact
                    aria-label="Email"
                    error={!active && !!error}
                  />
                </ArrayCol>
                <ArrayCol flex={0}>
                  <Button
                    icon="delete"
                    iconButton
                    size="xs"
                    type="danger"
                    importance="tertiary"
                    onClick={onRemove}
                    compact
                  />
                </ArrayCol>
              </ArrayRow>
            )}
          />
        </FieldBox>
      </ModalWizard.Panel>
    </ModalWizard>
  );
};
