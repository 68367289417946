import { Checkbox, Numeric, Select, UploadInput } from '@mortgagehippo/ds';

import { CodeEditorField } from '$components/code-editor';
import { EditorField } from '$components/editor-field';

import { type IPageComponentFieldsProps } from './types';
import { generateColumnOptions } from './util';

const IMAGE_MIME_TYPES = ['image/png', 'image/jpeg'];

const columnOptions = generateColumnOptions();

export const HeaderFields = (props: IPageComponentFieldsProps) => {
  const { languageId, onUpload } = props;

  return (
    <>
      <Select.Box name="config.columns" label="Columns" options={columnOptions} />
      <Numeric.Box name="config.height" label="Height" />
      <EditorField.Box name={`resources.${languageId}.content.title`} label="Title" extended />
      <EditorField.Box
        name={`resources.${languageId}.content.subtitle`}
        label="Subtitle"
        extended
      />
      <UploadInput.Box
        name={`resources.${languageId}.image.background_image`}
        accept={IMAGE_MIME_TYPES}
        maxItems={1}
        label="Background Image"
        onUpload={onUpload}
        fileTypeDescription="image"
      />
      <Checkbox.Box label="Invert colors" labelInvisible name="config.inverted">
        Inverted colors
      </Checkbox.Box>
      <CodeEditorField.Box mode="scss" name="config.css" label="Custom SCSS" />
    </>
  );
};
