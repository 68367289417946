import { borderRadius, Icon, palette, shadow, spacing, Tag } from '@mortgagehippo/ds';
import { sortBy, startCase } from 'lodash-es';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { type IEditorSaga } from './types';
import { isChanged } from './util';

const List = styled.div`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const ListItem = styled.div<{ selected?: boolean }>`
  position: relative;
  z-index: 0;

  border-top: 1px solid ${palette('neutral200')};

  &:first-child {
    border-top-color: transparent;
  }

  &:not(.selected):hover {
    background: ${palette('neutral100')};
    border-radius: ${borderRadius(2)};
    border-top-color: transparent;
  }

  &:hover + & {
    border-top-color: transparent;
  }

  &.selected + & {
    border-top-color: transparent;
  }

  ${({ selected }) =>
    selected &&
    css`
      background: ${palette('white')};
      border-radius: ${borderRadius(2)};
      border-top-color: transparent;
      box-shadow: ${shadow(2)};
      z-index: 200;

      &:after,
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: -13px;
        height: 0;
        width: 0;
        border: 16px solid transparent;
        border-left: 16px solid ${palette('white')};
        border-right: none;
        z-index: 1;
        bottom: 0;
        margin: auto;
      }

      &:before {
        z-index: 0;
        border-left-color: ${palette('neutral900')};
        opacity: 0.13;
        filter: blur(1px);
        right: -16px;
        top: 3px;
      }
    `}
`;

const ItemButton = styled.div`
  display: flex;
  padding: ${spacing(3)} ${spacing(2)};
  cursor: pointer;
  align-items: center;
  outline: 0;
`;

const ItemLabel = styled.div`
  flex: 1 1 auto;
  padding-right: ${spacing(1)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ItemStatus = styled.div`
  display: inline-block;
  margin-right: ${spacing(2)};
`;

const ItemTag = styled.div`
  flex: 0 0 auto;
`;

interface ISagasPageContentMenuProps {
  sagas: IEditorSaga[];
  onSelect?: (key: string) => void;
  activeKey?: string;
}

export const SagasPageContentMenu = forwardRef<any, ISagasPageContentMenuProps>(
  (props, selectedItemRef) => {
    const { sagas, onSelect, activeKey } = props;

    const orderedSagas = sortBy(sagas, ['name']);

    const handleSelect = (key: string) => () => {
      if (!onSelect) {
        return;
      }

      onSelect(key);
    };

    return (
      <List>
        {orderedSagas.map((saga) => {
          const key = saga.name;
          const selected = key === activeKey;

          return (
            <ListItem key={key} selected={selected} ref={selected ? selectedItemRef : undefined}>
              <ItemButton
                role="button"
                tabIndex={0}
                onClick={handleSelect(key)}
                onKeyDown={handleSelect(key)}
              >
                <ItemLabel>
                  {isChanged(saga) && (
                    <ItemStatus>
                      <Icon name="edit" />
                    </ItemStatus>
                  )}
                  {startCase(saga.name)}
                </ItemLabel>
                {saga.system ? (
                  <ItemTag>
                    <Tag size="xs">System</Tag>
                  </ItemTag>
                ) : null}
              </ItemButton>
            </ListItem>
          );
        })}
      </List>
    );
  }
);
