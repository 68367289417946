import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo/gql';

export const MSetDefaultApplicationStatus = graphql(`
  mutation SetDefaultApplicationStatus($statusId: ID!) {
    setDefaultApplicationFileStatus(statusId: $statusId) {
      ...ApplicationFileStatusFragment
    }
  }
`);

export const useSetDefaultApplicationStatus = () => {
  const fn = useMutation(MSetDefaultApplicationStatus);

  return useCallback(
    async (statusId: string) => {
      const result = await fn({ statusId });

      return result.data!.setDefaultApplicationFileStatus;
    },
    [fn]
  );
};
