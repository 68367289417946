import { useModal } from '@mortgagehippo/ds';
import { useSagaSubmitHandler } from '@mortgagehippo/tasks';
import { useCallback } from 'react';

import { type IActionProps, type IEditPartnerAction } from '../types';
import { EditPartnerModalContainer } from './edit-partner-modal-container';
import { useUpdatePartner } from './use-update-partner';

export const EditPartnerAction = (props: IActionProps<IEditPartnerAction>) => {
  const { action, onDone } = props;
  const { partnerId } = action;
  const [isOpen, , closeModal] = useModal(true);
  const updatePartner = useUpdatePartner();

  const [handleSubmit] = useSagaSubmitHandler({
    infoMessage: 'Updating partner',
    successMessage: 'Successfully updated partner.',
    errorMessage: 'There was an unexpected error updating the partner.',
    async onSubmit(values: any, setAsyncId) {
      const {
        name,
        admin_domain: adminDomain,
        is_billable: isBillable,
        admin_inactivity_timeout: adminInactivityTimeout,
        timezone,
      } = values;

      const data = {
        name,
        adminDomain,
        isBillable,
        adminInactivityTimeout,
        timezone,
      };

      const nextAsyncId = await updatePartner(partnerId, data);

      closeModal(() => {
        setAsyncId(nextAsyncId);
      });
    },
    async onDone() {
      closeModal(onDone);
    },
  });

  const handleCloseModal = useCallback(() => {
    closeModal(onDone);
  }, [closeModal, onDone]);

  return (
    <EditPartnerModalContainer
      isOpen={isOpen}
      partnerId={partnerId}
      onSubmit={handleSubmit}
      onRequestClose={handleCloseModal}
    />
  );
};
