import { Input } from '@mortgagehippo/ds';

export const SalesforceFields = () => (
  <>
    <Input.Box name="instance_url" label="Instance URL" />
    <Input.Box name="username" label="Username" />
    <Input.Box name="password" label="Password" />
    <Input.Box name="client_id" label="Client ID" />
    <Input.Box name="client_secret" label="Client Secret" />
    <Input.Box name="security_token" label="Security Token" />
    <Input.Box name="api_version" label="API Version (e.g. 41.0)" />
    <Input.Box name="host" label="Host (for Sandbox authentication - e.g. bank123--uat.my.com)" />
    <Input.Box name="oauth_token" label="OAuth Token" />
    <Input.Box name="jwt_key" label="JWT Key" />
  </>
);
