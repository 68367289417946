import { graphql } from '../../apollo/gql';
import {
  type PartnerCascadeConfigurationsQuery,
  type SiteCascadeConfigurationsQuery,
} from '../../apollo/graphql';

type ICascadePartnerConfiguration = NonNullable<
  PartnerCascadeConfigurationsQuery['partner']
>['cascadeConfigurations']['items'][0];

type ICascadeSiteConfiguration = NonNullable<
  NonNullable<SiteCascadeConfigurationsQuery['partner']>['site']
>['cascadeConfigurations']['items'][0];

export type ICascadeConfiguration = ICascadePartnerConfiguration | ICascadeSiteConfiguration;

export const QPartnerCascadeConfigurations = graphql(`
  query PartnerCascadeConfigurations(
    $partnerId: ID!
    $cursor: String
    $perPage: Int!
    $orderBy: JSON
  ) {
    partner(id: $partnerId) {
      id
      cascadeConfigurations(options: { perPage: $perPage, cursor: $cursor, orderBy: $orderBy }) {
        items {
          id
          name
          cascadeType
          cascadeOwnerType
          version
          partnerId
          siteId
          createdAt
          actions {
            id
            actionType
            position
            startCondition
            options
            timeout
          }
        }
        total
        nextCursor
        previousCursor
      }
    }
  }
`);

export const QSiteCascadeConfigurations = graphql(`
  query SiteCascadeConfigurations(
    $partnerId: ID!
    $siteId: ID!
    $cursor: String
    $perPage: Int!
    $orderBy: JSON
  ) {
    partner(id: $partnerId) {
      id
      site(id: $siteId) {
        id
        cascadeConfigurations(options: { perPage: $perPage, cursor: $cursor, orderBy: $orderBy }) {
          items {
            id
            name
            cascadeType
            cascadeOwnerType
            version
            partnerId
            siteId
            createdAt
            actions {
              id
              actionType
              position
              startCondition
              options
              timeout
            }
          }
          total
          nextCursor
          previousCursor
        }
      }
    }
  }
`);

export const MSaveCascadeConfigurationMutation = graphql(`
  mutation SaveCascadeConfiguration($input: SaveCascadeConfigurationInput!) {
    saveCascadeConfiguration(input: $input) {
      id
    }
  }
`);
