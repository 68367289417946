import { FieldError, Tabs } from '@mortgagehippo/ds';

import { EditorArray } from '../editor-array';
import { EditorTabWrapper } from '../editor-tab-wrapper';
import { TaskEditorFrame } from '../task-editor-frame';

export const DataGatheringTaskEditor = ({ name }: { name: string }) => (
  <TaskEditorFrame name={name} panel={false}>
    {() => (
      <Tabs.Tab id="panels" label="Panels">
        <EditorTabWrapper size="md">
          <EditorArray
            name={`${name}.data.panels`}
            addButtonLabel="Add a panel"
            addMoreButtonLabel="Add another panel"
            renderItemLabel={(itemValue: any) => itemValue.title}
          />
          <FieldError name={`${name}.data.panels`} />
        </EditorTabWrapper>
      </Tabs.Tab>
    )}
  </TaskEditorFrame>
);
