import { graphql } from '../../apollo/gql';

// eslint-disable-next-line unused-imports/no-unused-vars
const FPartnerFragment = graphql(`
  fragment PartnerFragment on Partner {
    id
    name
    isBillable
    adminDomain
  }
`);

// Get the list of partners
export const QPartnersList = graphql(`
  query PartnersList($page: Int!, $perPage: Int!, $criteria: [SearchCriteria!], $orderBy: JSON) {
    partners(criteria: $criteria, options: { page: $page, perPage: $perPage, orderBy: $orderBy }) {
      itemsTotal
      itemsCount
      pageNumber
      pageCount
      items {
        ...PartnerFragment
      }
    }
  }
`);
