import { useQuery } from '@mortgagehippo/apollo-hooks';
import { useSafeCallback } from '@mortgagehippo/util';
import { type ApolloQueryResult } from 'apollo-client';

import {
  type ExternalMilestoneKeysQuery,
  type ExternalMilestoneKeysQueryVariables,
  type VerticalType,
} from '../../../apollo/graphql';
import { QExternalMilestoneKeys } from './queries';

export type IExternalMilestoneKey = NonNullable<
  ExternalMilestoneKeysQuery['partner']
>['externalMilestoneKeys'][0];

export type IUseExternalMilestoneKeysResult = Readonly<
  [
    result: {
      externalKeys: IExternalMilestoneKey[];
    },
    loading: boolean,
    refetch: () => Promise<ApolloQueryResult<ExternalMilestoneKeysQuery>>,
  ]
>;

export const useExternalMilestoneKeys = (
  partnerId: string,
  vertical: VerticalType | null,
  skip?: boolean,
  ignore?: boolean
): IUseExternalMilestoneKeysResult => {
  const [data, loading, , { refetch }] = useQuery<
    ExternalMilestoneKeysQuery,
    ExternalMilestoneKeysQueryVariables
  >(
    QExternalMilestoneKeys,
    { partnerId, vertical, ignore },
    {
      suspend: false,
      fetchPolicy: 'network-only',
      skip: skip || !vertical,
    }
  );

  const handleRefresh = useSafeCallback(() => refetch({ partnerId }));

  const externalKeys = data?.partner?.externalMilestoneKeys || [];

  return [{ externalKeys }, loading, handleRefresh] as const;
};
