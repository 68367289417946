import { ButtonLink, Icon } from '@mortgagehippo/ds';
import { MenuGroup, MenuItem } from '@mortgagehippo/menu';

import { usePartner } from '../../hooks/use-partner';
import { useSite } from '../../hooks/use-site';
import { MenuTitle } from './menu-title';

interface IPartnerNavigation {
  partnerId: string;
  siteId: string;
}

export const SiteNavigation = (props: IPartnerNavigation) => {
  const { partnerId, siteId } = props;

  const [partner, partnerDataLoading] = usePartner(partnerId);

  const [site, loading] = useSite(siteId);

  if (!site || !partner || loading || partnerDataLoading) {
    return null;
  }

  return (
    <>
      <MenuTitle>
        <span>
          <ButtonLink to="/partners">Partners</ButtonLink>
        </span>{' '}
        <span>
          <ButtonLink to={`/partners/${partnerId}`}>{partner.name}</ButtonLink>
        </span>{' '}
        <strong>{site.name}</strong>
      </MenuTitle>
      <MenuItem label="Site Details" to={`/partners/${partnerId}/sites/${siteId}`} exact />
      <MenuItem
        label="Credentials"
        to={`/partners/${partnerId}/sites/${siteId}/credentials`}
        exact
      />
      <MenuItem
        label="Notifications"
        to={`/partners/${partnerId}/sites/${siteId}/notifications`}
        exact
      />
      <MenuItem label="Milestones" to={`/partners/${partnerId}/sites/${siteId}/milestones`} exact />
      <MenuItem
        label="Agent Page"
        to={`/partners/${partnerId}/sites/${siteId}/agentLandingPage`}
        exact
      />
      <MenuItem
        label="Landing Pages"
        to={`/partners/${partnerId}/sites/${siteId}/landingPages`}
        exact
      />

      <MenuGroup label="Site Customizations" expandIconPosition="left" compact>
        <MenuItem
          label="Namespaces"
          to={`/partners/${partnerId}/sites/${siteId}/customizations/namespaces`}
          exact
        />
        <MenuItem
          label="Images"
          to={`/partners/${partnerId}/sites/${siteId}/customizations/images`}
          exact
        />
        <MenuItem
          label="Colors"
          to={`/partners/${partnerId}/sites/${siteId}/customizations/colors`}
          exact
        />
      </MenuGroup>
      <MenuGroup label="Advanced" expandIconPosition="left" compact>
        <MenuItem label="Sagas" to={`/partners/${partnerId}/sites/${siteId}/sagas`} exact />
        <MenuItem label="Cascades" to={`/partners/${partnerId}/sites/${siteId}/cascades`} exact />
        <MenuItem
          label="Webhook Configurations"
          to={`/partners/${partnerId}/sites/${siteId}/webhook-configurations`}
          exact
        />
      </MenuGroup>
      <MenuGroup label="Other Settings" expandIconPosition="left" compact>
        <MenuItem
          label="Unassigned Application Notifications Contacts"
          to={`/partners/${partnerId}/sites/${siteId}/unassigned-application-notifications-contacts`}
          exact
        />
        <MenuItem
          label="Pre-Qualification Letter"
          to={`/partners/${partnerId}/sites/${siteId}/pre-qualification-letter`}
          exact
        />
      </MenuGroup>
      <MenuItem
        label={
          <>
            Open Borrower Hub <Icon name="open-in-new" size="sm" />
          </>
        }
        to={`https://${site.domain}`}
        asNonRouterLink
        nonRouterLinkTarget={site.domain}
      />
    </>
  );
};
