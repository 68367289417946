import { useQuery } from '@mortgagehippo/apollo-hooks';
import { type FetchPolicy } from 'apollo-client';
import { useCallback } from 'react';

import { graphql } from '../apollo/gql';
import { type SiteDataQuery } from '../apollo/graphql';

export type ISite = NonNullable<SiteDataQuery['site']>;

export const QSiteData = graphql(`
  query SiteData($siteId: ID!) {
    site(id: $siteId) {
      ...SiteFragment
      blueprint {
        id
      }
      applicationBlueprint {
        id
      }
    }
  }
`);

type IUseSiteResult = [ISite | null | undefined, boolean, () => Promise<any>];

export const useSite = (
  siteId: string,
  fetchPolicy: FetchPolicy = 'cache-first'
): IUseSiteResult => {
  const [data, loading, , { refetch }] = useQuery(
    QSiteData,
    {
      siteId,
    },
    { fetchPolicy }
  );

  const handleRefresh = useCallback(async () => {
    await refetch({ siteId });
  }, [siteId, refetch]);

  const site = !loading && data ? data.site : undefined;

  return [site, loading, handleRefresh];
};
