import { useMutation } from '@mortgagehippo/apollo-hooks';
import { useCallback } from 'react';

import { graphql } from '../../apollo/gql';

export const MMoveApplicationStatusDown = graphql(`
  mutation MoveApplicationStatusDown($statusId: ID!) {
    moveApplicationFileStatusDown(statusId: $statusId) {
      ...ApplicationFileStatusFragment
    }
  }
`);

export const useMoveApplicationStatusDown = () => {
  const fn = useMutation(MMoveApplicationStatusDown);

  return useCallback(
    async (statusId: string) => {
      const result = await fn({ statusId });

      return result.data!.moveApplicationFileStatusDown;
    },
    [fn]
  );
};
